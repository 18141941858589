import React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CircularProgress, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { templateAccessListApi } from "src/DAL/WebsitePages/templateConfig";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { client_Url, s3baseUrl } from "src/config/config";
import { detailPageApi } from "src/DAL/WebsitePages/WebsitePages";
import { rewardListing } from "src/DAL/Programme/Programme";
import { memberDetailApi, rewardAccessApi } from "src/DAL/member/member";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageRewardAccess() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [consultantData, setConsultantData] = useState([]);
  const [userInfo, setUserInfo] = useState("");
  const [selected, setSelected] = useState([]);
  const classes = useStyles();
  const { state } = useLocation();

  const get_member_detail = async () => {
    const result = await memberDetailApi(params.member_id);
    if (result.code == 200) {
      if (result.member?.reward) {
        setSelected(result.member.reward);
      }
      setUserInfo(result.member);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "title", label: "Title", alignRight: false },
    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
      className: "p-0",
    },
    {
      id: "status",
      label: "Status",
      type: "row_status",
      alignRight: false,
    },
  ];

  const getRewardsListing = async () => {
    const result = await rewardListing();
    if (result.code == 200) {
      let consultant_array = [];
      result.reward.map((reward) => {
        consultant_array.push({
          ...reward,
          table_avatar: {
            src: s3baseUrl + reward.icon_with_border.thumbnail_1,
            alt: reward.title,
          },
        });
      });
      setConsultantData(consultant_array);
      if (state) {
        setIsLoading(false);
      } else {
        get_member_detail();
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let selectedMember = [];
    selected.map((member) => {
      selectedMember.push({
        _id: member._id,
      });
    });

    let rewardObject = {
      reward: selectedMember,
    };

    const result = await rewardAccessApi(params.member_id, rewardObject);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleNavigateBack();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (state) {
      setSelected(state.reward);
      setUserInfo(state);
    }
    getRewardsListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex  mb-4">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={handleNavigateBack}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">
            {userInfo.first_name +
              " " +
              userInfo.last_name +
              " (" +
              userInfo.email +
              ")"}
          </span>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-6">
          <h2>Manage Rewards Access</h2>
        </div>
      </div>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={consultantData}
        selected={selected}
        setSelected={setSelected}
        checkbox_selection={true}
        pagePagination={true}
      />
      <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
