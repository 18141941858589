import { invokeApi } from "../../bl_libs/invokeApi";
export const RecordingListingApi = async () => {
  const requestObj = {
    path: `/api/delegate_recording/all_recording_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddRecording = async (data) => {
  const requestObj = {
    path: `/api/delegate_recording/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const ManageEngageAPi = async (data) => {
  const requestObj = {
    path: `/short_links/get_short_links/stats`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditRecordingApi = async (recording_slug, data) => {
  const requestObj = {
    path: `/api/delegate_recording/${recording_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteRecording = async (recoding_slug) => {
  const requestObj = {
    path: `/api/delegate_recording/${recoding_slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
