import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { Icon } from "@iconify/react";
import {
  CircularProgress,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { get_root_value } from "src/utils/domUtils";
import { programmeListingForDelegate } from "src/DAL/Programme/Programme";
import { useEffect } from "react";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SubscribersFilter({
  filterData,
  searchSubmitFilter,
  handleChangeOthers,
  handleClearFilter,
  plansList,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  let role_array = plansList.map((role) => {
    return {
      ...role,
      chip_label: role.plan_title,
      chip_value: role._id,
    };
  });
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid new-memories">
      <form onSubmit={searchSubmitFilter}>
        <div className="row">
          <div className="col-12 mt-3">
            <MUIAutocomplete
              inputLabel="Choose Plan"
              selectedOption={filterData.plan}
              setSelectedOption={(e) => {
                handleChangeOthers("plan", e);
              }}
              optionsList={role_array}
              name="plan_title"
            />
          </div>
          <FormControlLabel
            className="mt-2"
            control={
              <Checkbox
                checked={filterData.is_date_range}
                onChange={(e) => {
                  handleChangeOthers("is_date_range", e.target.checked);
                }}
              />
            }
            label="Search By Subcription Date Range"
          />
          {filterData.is_date_range && (
            <>
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      label="Start  Date"
                      inputFormat="dd-MM-yyyy"
                      name="start_date"
                      value={filterData.start_date}
                      onChange={(e) => {
                        handleChangeOthers("start_date", e);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} required />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </div>
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      label="End  Date"
                      inputFormat="dd-MM-yyyy"
                      name="end_date"
                      value={filterData.end_date}
                      onChange={(e) => {
                        handleChangeOthers("end_date", e);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} required />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </div>
            </>
          )}

          <div className="col-12">
            <Box sx={{ py: 2 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                color="inherit"
                variant="outlined"
                startIcon={
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: 5 }}
                  >
                    <path
                      d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                      fill={get_root_value("--portal-theme-primary")}
                    />
                  </svg>
                }
              >
                Filter
              </Button>
            </Box>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              onClick={handleClearFilter}
              startIcon={<Icon icon={roundClearAll} />}
            >
              Clear All
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
