import { invokeApi } from "../../bl_libs/invokeApi";

export const productCategoryListApi = async (data) => {
  const requestObj = {
    path: `/api/product_category/product_category_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_Order_list = async (page, limit, search, formData) => {
  const requestObj = {
    path: `/admin_users/get_shop_orders_for_admin?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: formData,
  };
  return invokeApi(requestObj);
};
export const orderShopDetailList = async (id) => {
  const requestObj = {
    path: `/admin_users/get_shop_order_detail_for_admin/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const productCategoryDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/product_category/product_category_detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addProductCategory = async (data) => {
  const requestObj = {
    path: `/api/product_category/add_product_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editProductCategory = async (data, slug) => {
  const requestObj = {
    path: `/api/product_category/update_product_category/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const productCategoryDeleteApi = async (id) => {
  const requestObj = {
    path: `/api/product_category/delete_product_category/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const affirmationCategoryAddApi = async (data) => {
  const requestObj = {
    path: `/api/general_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const affirmationCategoryEditApi = async (data, id) => {
  const requestObj = {
    path: `/api/general_category/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
