import { Tooltip } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { dd_date_format, show_proper_words } from "src/utils/constant";
import MemberProgramPopOverForHistory from "../memberProgramPopOverForHistory";
import MemberEventPopOverHistory from "./MemberEventPopOverHistory";

function ResourceHistory({ tabData }) {
  const [totalBadges, setTotalBadges] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [program, setprogram] = useState([]);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [memberEvent, setMemberEvent] = useState([]);

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickEventPopUP = (event, row) => {
    setMemberEvent(row.resource_info.dynamite_events);
    setAnchorEl1(event.currentTarget);
  };

  const handleClickPopUP = (event, row) => {
    setprogram(row.resource_info.programs);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseEventPopUp = () => {
    setAnchorEl1(null);
  };

  const get_badges_list = async () => {
    let tab_data = [];
    if (tabData.length > 0) {
      tab_data = tabData.map((data) => {
        return {
          ...data,
          title: data.challange_info?.challange_title,
          completed_challenges: data.badge_configration?.number_of_challenges,
          bonus_content_categories:
            data.resource_info?.bonus_content_categories,
        };
      });
    }
    setTotalBadges(tab_data);
  };

  let TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "action_source",
      label: "Action Source",
      renderData: (row) => {
        return (
          <>
            <p>{show_proper_words(row.action_source)}</p>
          </>
        );
      },
    },
    {
      id: "action_info",
      label: "Action Info",
      renderData: (row) => {
        let action_info = "";
        if (row.action_source == "admin_portal") {
          if (row.action_info && row.action_info.name) {
            action_info =
              row.action_info.name + "(" + row.action_info.email + ")";
          }
        } else if (row.action_source == "payment_request") {
          if (row.action_info && row.action_info.request_title) {
            action_info =
              row.action_info.request_title +
              " " +
              "(" +
              row.action_info.request_type +
              ")";
          }
        } else if (row.action_source == "plan_subscription") {
          if (row.action_info && row.action_info.plan_title) {
            let plan_type = "";
            if (row.action_info.plan_type == "") {
              plan_type = "Free";
            } else {
              plan_type = row.action_info.plan_type;
            }

            action_info =
              row.action_info.page_title +
              " " +
              "(" +
              row.action_info.plan_title +
              " " +
              "|" +
              " " +
              plan_type +
              ")";
          }
        }
        return (
          <>
            <p>{action_info}</p>
          </>
        );
      },
    },
    {
      id: "program",
      label: "Programmes",
      renderData: (row) => {
        const firstFiveprograms = row.resource_info.programs?.slice(0, 5);
        return firstFiveprograms && firstFiveprograms.length > 0 ? (
          firstFiveprograms?.map((program, i) => {
            return (
              <>
                <Tooltip
                  key={i}
                  title={
                    program.expiry_date ? (
                      <div>
                        Start Date:{" "}
                        {dd_date_format(program?.purchase_date_time)}
                        <br />
                        Expiry: {dd_date_format(program?.expiry_date)}
                        <br />
                        Program access :{program.program_access_type}
                      </div>
                    ) : (
                      <div>
                        Start Date:{" "}
                        {dd_date_format(program?.purchase_date_time)}
                        <br />
                        No Expiry
                        <br />
                        Program access :{program.program_access_type}
                      </div>
                    )
                  }
                  arrow
                >
                  <p className="mb-1 program-hover">{program.title}</p>
                </Tooltip>
                {i == 4 && (
                  <p
                    className="mb-1 mt-1 view-more"
                    aria-describedby={id}
                    variant="contained"
                    onClick={(e) => handleClickPopUP(e, row)}
                  >
                    view more
                  </p>
                )}
              </>
            );
          })
        ) : (
          <p>N/A</p>
        );
      },
      alignRight: false,
    },
    {
      id: "portals",
      label: "Portals",
      renderData: (row) => {
        const firstFiveprograms = row.resource_info.dynamite_events?.slice(
          0,
          5
        );
        return firstFiveprograms && firstFiveprograms.length > 0 ? (
          firstFiveprograms?.map((program, i) => {
            return (
              <>
                <Tooltip
                  key={i}
                  title={
                    program.expiry_date ? (
                      <div>
                        Start Date: {dd_date_format(program?.start_date)}
                        <br />
                        Expiry: {dd_date_format(program?.expiry_date)}
                      </div>
                    ) : (
                      <div>
                        Start Date: {dd_date_format(program?.start_date)}
                        <br />
                        No Expiry
                      </div>
                    )
                  }
                  arrow
                >
                  <p className="mb-1 program-hover">{program.title}</p>
                </Tooltip>
                {i == 4 && (
                  <p
                    className="mb-1 mt-1 view-more"
                    aria-describedby={id}
                    variant="contained"
                    onClick={(e) => handleClickEventPopUP(e, row)}
                  >
                    view more
                  </p>
                )}
              </>
            );
          })
        ) : (
          <p>N/A</p>
        );
      },
      alignRight: false,
    },
    {
      id: "bonus_content_categories",
      label: "Bonus Content",
      renderData: (row) => {
        let bonus_content_categories = row.bonus_content_categories;
        return bonus_content_categories &&
          bonus_content_categories.length > 0 ? (
          bonus_content_categories?.map((category, i) => {
            return (
              <>
                <Tooltip
                  key={i}
                  title={
                    category.expiry_date ? (
                      <div>
                        Start Date: {dd_date_format(category?.start_date)}
                        <br />
                        Expiry: {dd_date_format(category?.expiry_date)}
                      </div>
                    ) : (
                      <div>
                        Start Date: {dd_date_format(category?.start_date)}
                        <br />
                        No Expiry
                      </div>
                    )
                  }
                  arrow
                >
                  <p className="mb-1 program-hover">{category.title}</p>
                </Tooltip>
              </>
            );
          })
        ) : (
          <p>N/A</p>
        );
      },
      alignRight: false,
    },
    {
      id: "Membership",
      label: "Membership",
      renderData: (row) => {
        let membership = "--";
        if (
          row.resource_info &&
          row.resource_info.membership_info &&
          row.resource_info.membership_info.membership_allowed
        ) {
          membership =
            "From" +
            " " +
            dd_date_format(row.resource_info.membership_info.start_date) +
            " To" +
            " " +
            dd_date_format(row.resource_info.membership_info.expiry_date);
        }

        return (
          <>
            <p>{membership}</p>
          </>
        );
      },
    },
    {
      id: "created_at",
      label: "Action Date",
      renderData: (row) => {
        let date = "N/A";
        if (row.createdAt) {
          date = dd_date_format(row.createdAt);
        }
        return (
          <>
            <p>{date}</p>
          </>
        );
      },
    },
  ];
  const handleClosePopUp = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    get_badges_list();
  }, [tabData]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="mb-3 mt-4">
            <h2>Resource History</h2>
          </div>
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={totalBadges}
              className="card-with-background"
              hide_search={true}
              is_hide={true}
            />
          </div>
        </div>
      </div>
      <MemberProgramPopOverForHistory
        handleClick={handleClickPopUP}
        handleClose={handleClosePopUp}
        anchorEl={anchorEl}
        id={id}
        open={open}
        program={program}
      />
      <MemberEventPopOverHistory
        handleClick={handleClickEventPopUP}
        handleClose={handleCloseEventPopUp}
        anchorEl={anchorEl1}
        id={id1}
        open={open1}
        event={memberEvent}
      />
    </>
  );
}

export default ResourceHistory;
