import React from "react";
import { useEffect, useState } from "react";
import {
  Link as RouterLink,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Chip, CircularProgress } from "@mui/material";
import { s3baseUrl } from "../../config/config";
import { useSnackbar } from "notistack";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { get_wheel_of_life_members_api } from "src/DAL/QuestionSurvey/QuestionSurvey";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { get_Order_list } from "src/DAL/ShopProductCategoryList/ProductCategory";
import { convertCurrencyToSign } from "src/utils/constant";
import ActiveLastBreadcrumb from "../GeneralComponents/BreadCrums";
import { tracking_histiry_api } from "src/DAL/consultant/consultant";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function TrackingHistory() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const params = useParams();
  const [UserName, setUserName] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);
  const [leadStatusData, setleadStatusData] = useState({});
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const show_history = (value) => {
    console.log(value, "valuevaluevalue");
    setleadStatusData(value);
    setChangeStatusOpen(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMemberListing();
  };
  const handleCopyReferralId = async (e) => {
    await navigator.clipboard.writeText(e.affliliate?.affiliate_url_name);
    enqueueSnackbar("Referral Id Copied to clipboard", { variant: "success" });
  };

  const getMemberListing = async () => {
    setIsLoading(true);
    let search_keyword =
      localStorage.getItem("wheel_of_life_search_text") == null
        ? searchText
        : localStorage.getItem("wheel_of_life_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const result = await tracking_histiry_api(params.id, page, rowsPerPage);
    if (result.code == 200) {
      console.log(result, "resultresult");
      setUserName(result.user_data);
      const members = result.user_activity_list.map((member) => {
        return {
          ...member,

          checkin_date_time: member.checkin_date_time
            ? moment(member.checkin_date_time).format("DD-MM-YYYY hh:mm A")
            : "N/A",
          checkout_date_time: member.checkout_date_time
            ? moment(member.checkout_date_time).format("DD-MM-YYYY hh:mm A")
            : "N/A",
          createdAt: moment(member.createdAt).format("DD-MM-YYYY"),
          MENU_OPTIONS: handleMenu(member),
        };
      });
      setData(members);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const handleMenu = (row) => {
    let MENU_OPTIONS = [
      {
        label: "View Detail",
        icon: "akar-icons:edit",
        handleClick: show_history,
      },
    ];

    return MENU_OPTIONS;
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "checkin_date_time", label: "Check In Time" },
    { id: "checkout_date_time", label: "Check Out Time" },
  ];

  useEffect(() => {
    getMemberListing();
  }, [rowsPerPage, page]);

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title: UserName
        ? UserName?.first_name +
          " " +
          UserName?.last_name +
          " (" +
          UserName.email +
          ")"
        : "Tracking History",
      active: true,
    },
  ];

  useEffect(() => {
    localStorage.setItem("wheel_of_life_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("wheel_of_life_search_text", "");
    } else {
      localStorage.setItem("wheel_of_life_search_text", searchText);
    }
  }, [searchText]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-12 mb-3">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-12 col-sm-12 mb-5">
            <h2>Tracking History</h2>
          </div>
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={data}
            className="card-with-background"
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            is_hide={true}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
