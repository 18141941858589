import { invokeApi } from "../../bl_libs/invokeApi";

export const book_call_type_list_api = async (data) => {
  const requestObj = {
    path: `/api/book_call_type/list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const book_call_type_detail_api = async (id) => {
  const requestObj = {
    path: `/api/book_call_type/detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_book_call_type_api = async (id) => {
  const requestObj = {
    path: `/api/book_call_type/delete/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const add_book_call_type_api = async (data) => {
  const requestObj = {
    path: `/api/book_call_type/add/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_book_call_type_api = async (data, id) => {
  const requestObj = {
    path: `/api/book_call_type/update/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
