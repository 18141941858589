import * as React from "react";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import { getDetailQuestion } from "src/DAL/goalstatement/goalStatement";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useEffect } from "react";
import {
  memberDetailApi,
  wheelOfLifeMemberDetail,
} from "src/DAL/member/member";
import WheelOfLifeQuestionsList from "./WheelOfLifeQuestions";
import WheelOfLifeGraphQuestions from "./WheelOfLifeGraphQuestions";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function WheelOfLifeMemberDetailQuestion() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [groups, setGroups] = useState([]);
  const [members, setMembers] = useState([]);
  const [goalMembers, setGoalMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [questionStatement, setQuestionStatement] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [userData, setUserData] = useState();
  const [intentionStatement, setintentionStatement] = useState();
  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const DetailQuestion = async () => {
    const result = await wheelOfLifeMemberDetail(params.id);
    if (result.code === 200) {
      setintentionStatement(result.member.intention_statement);

      setUserData(result?.member);

      const member_list = result?.member.assessment.map((member) => {
        return {
          ...member,
          // name: member._id.first_name + " " + member._id.last_name,
          // table_avatar: {
          //   src: s3baseUrl + member._id.profile_image,
          //   alt: member._id.first_name,
          // },
        };
      });
      setGoalMembers(member_list);
      const wheelOfLifeQuestion = result.member.wheel_of_life.map((group) => {
        return {
          ...group,
          // members_count: group.member.length,
        };
      });

      setGroups(wheelOfLifeQuestion);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "question_statement",
      label: "Question Statement",
      alignRight: false,
    },
    { id: "answer", label: "Answer", alignRight: false },
  ];

  const GROUP_TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "question_statement",
      label: "Question Statement",
      alignRight: false,
    },
    // { id: "answer", label: "Answer", alignRight: false },
    { id: "scaling_main_heading", label: "Main Heading", alignRight: false },
    { id: "scaling_min", label: "Scaling Min", alignRight: false },
    { id: "scaling_max", label: "Scaling Max", alignRight: false },
  ];

  useEffect(() => {
    DetailQuestion();
  }, []);

  const TABS_OPTIONS = [
    {
      title: "Assessment Questions",
      component: (
        <WheelOfLifeQuestionsList
          goalMembers={goalMembers}
          finalData={userData}
        />
      ),
    },
    {
      title: "Wheel Of Life Questions",
      component: (
        <WheelOfLifeGraphQuestions goalMembers={groups} finalData={userData} />
      ),
    },
  ];

  let breadCrumbMenu = [
    {
      title: "Wheel Of Life Member",
      navigation: `/wheel-of-life-member-list`,
      active: false,
    },
    {
      title:
        userData?.first_name +
        " " +
        userData?.last_name +
        " (" +
        userData?.email +
        ")",
      active: true,
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-12 text-center pt-4">
          {/* {intentionStatement && <h2>{intentionStatement}</h2>} */}
          {intentionStatement && (
            <TextField
              id="filled-multiline-flexible"
              fullWidth
              multiline
              InputLabelProps={{
                style: { color: "#f6bd4b" },
              }}
              rows={3}
              disabled
              label="Intention Statement"
              className={(classes.dense, "textarea-color")}
              value={intentionStatement}
              onChange={(e) => handleChange(index, e)}
              variant="outlined"
              name="message"
            />
          )}
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-4">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
