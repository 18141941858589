import React, { useEffect, useMemo, useState } from "react";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";
import { CircularProgress, FormHelperText, inputClasses } from "@mui/material";
import { get_root_value } from "src/utils/domUtils";
import { s3baseUrl } from "src/config/config";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { editProfileApi } from "src/DAL/EditProfile/EditProfile";
import { htmlDecode } from "src/utils/convertHtml";
import { usePGIMode } from "src/Hooks/PGIModeContext";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

function EditProfile(props) {
  const classes = useStyles();
  //   const { userInfo, setUserInfo, handleSetUserInfo } = useContentSetting();
  const { mode, handleChangeMode, setProfileNewImage, profileNewImage } =
    usePGIMode();
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apimage, setApiImage] = useState("");
  const [file, setProfileImage] = React.useState({});
  const [imageStatus, setImageStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [oldProfile, setOldProfile] = useState();
  const [adminData, setAdminData] = useState({
    first_name: "",
    image: "",
  });

  // const options = useMemo(() => countryList().getData(), []);
  // const user_profile = _get_user_from_localStorage();

  const consultantProfile = async () => {
    const result = await "";
    if (result.code === 200) {
      localStorage.setItem("admin_time_zone", JSON.stringify(result.time_zone));
      setUserProfile(result.consultant);
      handleSetUserInfo(result.consultant);
      setMemberData(result.consultant);
      // setUserInfo(result.consultant);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();

    formData.append("name", adminData.first_name);

    if (imageStatus === true) {
      formData.append("image", file);
    }
    const result = await editProfileApi(formData);
    if (result.code === 200) {
      localStorage.setItem(`name`, result?.admin?.name);
      if (imageStatus === true) {
        localStorage.setItem(`image`, result?.admin.image?.thumbnail_1);
        setProfileNewImage(result?.admin.image?.thumbnail_1);
      }
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoading(false);
  };
  const handleUploadFile = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAdminData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleUpload = (event) => {
    setImageStatus(true);
    // setImage(event.target.files[0]);
    setProfileImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  useEffect(() => {
    setOldProfile(localStorage.getItem("image"));
    setAdminData({
      ...adminData,
      ["first_name"]: htmlDecode(localStorage.getItem("name")),
    });
  }, []);
  useEffect(() => {
    consultantProfile();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid ">
      <form onSubmit={handleUpdate}>
        <div className="mt-4 mb-4 d-flex justify-content-center">
          <div className="cards-edit p-4">
            <div className=" image d-flex flex-column justify-content-center align-items-center">
              <div className="edit-profile-icon">
                <img
                  src={previews ? previews : s3baseUrl + oldProfile}
                  // src="https://hinakhan-app-bucket.s3.amazonaws.com/member/53e82445-09b9-4e64-8af8-628908f4b36c.jpg"
                  height="100"
                  width="100"
                />
                <label htmlFor="icon-button-file">
                  <Input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    name="profile_image"
                    onChange={(e) => handleUpload(e)}
                  />
                  <IconButton
                    className="edit-profile-icon-position"
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
              </div>
              <div className="row w-100 mt-3">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      name="first_name"
                      value={adminData.first_name}
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-2 ms-auto">
                <button className="small-contained-button mt-4 ">Update</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditProfile;
