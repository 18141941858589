import { invokeApi } from "../../bl_libs/invokeApi";

export const shopProductListApi = async (slug) => {
  const requestObj = {
    path: `/api/dynamite_product/dynamite_product_list_by_catgory/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const dynamite_product_list_filter = async (page, limit, data) => {
  const requestObj = {
    path: `/api/dynamite_product/list/filter?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const UploadShopImageOnS3 = async (data) => {
  const requestObj = {
    path: `/app/upload_shop_image_on_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const productCategoryDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/product_category/product_category_detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteProductApi = async (id) => {
  const requestObj = {
    path: `/api/dynamite_product/delete_dynamite_product/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addProductApi = async (data) => {
  const requestObj = {
    path: `/api/dynamite_product/add_dynamite_product`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editProductApi = async (data, id) => {
  const requestObj = {
    path: `/api/dynamite_product/update_dynamite_product/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editProductCategory = async (data, slug) => {
  const requestObj = {
    path: `/api/product_category/update_product_category/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const productCategoryDeleteApi = async (id) => {
  const requestObj = {
    path: `/api/product_category/delete_product_category/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const affirmationCategoryAddApi = async (data) => {
  const requestObj = {
    path: `/api/general_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const affirmationCategoryEditApi = async (data, id) => {
  const requestObj = {
    path: `/api/general_category/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
