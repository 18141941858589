import { invokeApi } from "src/bl_libs/invokeApi";

export const help_videos_list_api = async (slug, type) => {
  const requestObj = {
    path: `/api/help_video/help_video_list_by_help_video_category_and_type?help_video_category=${slug}&type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_video_api = async (slug) => {
  const requestObj = {
    path: `/api/help_video/delete_help_video/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_video_api = async (data) => {
  const requestObj = {
    path: `/api/help_video/add_help_video`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_video_api = async (data, slug) => {
  const requestObj = {
    path: `/api/help_video/help_video_update/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const category_video_api = async (slug) => {
  const requestObj = {
    path: `/api/help_video/help_video_detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const active_category_list_api = async () => {
  const requestObj = {
    path: `/api/help_video_category/list_help_video_category_active`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
