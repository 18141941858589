import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  daily_message_list_api,
  daily_message_list_api_with_pagination,
  delete_daily_message_api,
} from "src/DAL/Community/DailyMessages";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  { id: "note_for_admin", label: "Admin Text Box", alignRight: false },
  { id: "message_date", label: "Start Date", alignRight: false },
  { id: "message_end_date", label: "End Date", alignRight: false },
  // { id: "is_show_on_feed", label: "Show On Feed", alignRight: false },
  {
    id: "type",
    label: "Type",
    alignRight: false,
    className: "text-capitalize",
  },
  {
    id: "status",
    label: "Status",
    type: "row_status",
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DailyMessagesDataType({ tabValue, tabName }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [messagesData, setMessagesData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const getMessagesListing = async (search) => {
    setIsLoading(true);
    let postData = {
      tab: tabName,
      search_text: search ? search : searchText,
    };
    console.log(rowsPerPage, "rowsPerPagerowsPerPage");
    const result = await daily_message_list_api_with_pagination(
      page,
      rowsPerPage,
      postData
    );
    if (result.code == 200) {
      console.log(result, "resultresult");
      const messages = result.message_list.map((message) => {
        return {
          ...message,
          is_show_on_feed: message.show_on_feed == true ? "Yes" : "No",
          message_date: message.message_date ? message.message_date : "N/A",
          message_end_date: message.message_end_date
            ? message.message_end_date
            : "N/A",
          note_for_admin: message.note_for_admin
            ? message.note_for_admin
            : "N/A",
        };
      });
      setMessagesData(messages);
      setTotalCount(result.total_message_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_daily_message_api(deleteDoc._id);
    if (result.code === 200) {
      setMessagesData((messagesData) => {
        return messagesData.filter((data) => data._id !== deleteDoc._id);
      });
      setgratitudeData((messagesData) => {
        return messagesData.filter((data) => data._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const searchFunction = (e) => {
    if (e) {
      e.preventDefault();
    }
    setPage(0);
    setPageCount(1);
    localStorage.setItem(tabName + "_search", searchText);
    getMessagesListing();
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("Reminder_number", newPage);
  };
  const handleEdit = (value) => {
    navigate(`/daily-messages/edit-message/${value._id}`, {
      state: { ...value, tabValue: tabValue },
    });
  };

  useEffect(() => {
    let search = localStorage.getItem(tabName + "_search");
    if (search) {
      setSearchText(search);
    }
    getMessagesListing(search);
  }, [rowsPerPage, page, tabName]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this message?"}
        handleAgree={handleDelete}
      />

      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={messagesData}
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          className="card-with-background"
          pagePagination={true}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      </div>
    </>
  );
}
