import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useRef, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { makeStyles } from "@mui/styles";

import {
  _add_wheel_of_life,
  _delete_wheel_of_life,
  _get_wheel_of_life,
  _update_wheel_of_life,
} from "src/DAL/QuestionSurvey/QuestionSurvey";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import RecordNotFound from "src/components/RecordNotFound";
import {
  addLessonQuestionApi,
  deleteLessonQuestion,
  editLessonQuestionApi,
  lessonQuestionListingApi,
} from "src/DAL/GeneralQuestions/GeneralQuestions";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  DeleteSelfImageReplyApi,
  memberAndGroupsListApi,
} from "src/DAL/member/member";
import AddKimsReply from "../Members/AddKimReply";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import SlefImageReplies from "src/components/GeneralComponents/GeneralQuestions/SlefImageReplies";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const GeneralQuestions = ({
  created_for,
  show_back_button,
  question_page_title,
  allowed_users,
}) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteReply, setOpenDeleteReply] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [formAction, setFormAction] = useState("ADD");
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputList, setInputList] = useState([{ option: "", checked: false }]);
  const classes = useStyles();
  const created_for_id = new URLSearchParams(location.search).get(
    "created_for_id"
  );
  const is_self_image = window.location.pathname.includes(
    "self-image-questions"
  );
  // for users and groups
  const [groupListing, setGroupListing] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [excludeMembers, setExcludeMembers] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [videoData, setVideoData] = useState({});
  const [member, setMember] = useState([]);
  const [selectedExclude, setSelectedExclude] = useState([]);
  const [serchText, setSerchText] = useState("");
  const [excludeText, setExcludeText] = useState("");
  const titleRef = React.useRef();
  const [replies, setReplies] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState("");
  const [inputs, setInputs] = useState({
    question_type: "mcq",
    scaling_min: "",
    status: true,
    is_document_allowed: false,
    is_required: false,
    scaling_max: "",
    question_statement: "",
    question_placeholder: "",
    scaling_color: "#000",
    question_input_field_type: "text",
    image: "",
  });

  const is_website_pages = window.location.pathname.includes("website-pages");

  const page_name = is_website_pages ? `/website-pages` : `/template-pages`;

  const handleClickToggle = () => {
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleAgreeDeleteReply = (value) => {
    setDeleteId(value._id);
    setOpenDeleteReply(true);
  };

  const handleClick = () => {
    setIsOpenForm(true);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (inputList.length < 1) {
      setInputList([{ option: "", checked: false }]);
    }
  };
  // handle click event of the Add button
  const handleAddClick = (index) => {
    setInputList([
      ...inputList.slice(0, index),
      {
        option: "",
        checked: false,
      },
      ...inputList.slice(index),
    ]);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const expandArea = (index) => {
    let temp_state = [...questionsList];
    let temp_element = { ...temp_state[index] };
    temp_element.isExpanded = !temp_element.isExpanded;
    temp_state[index] = temp_element;
    setQuestionsList(temp_state);
  };
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveValues = () => {
    setInputs({
      question_type: "mcq",
      scaling_min: "",
      status: true,
      is_document_allowed: false,
      is_required: false,
      scaling_max: "",
      question_statement: "",
      question_placeholder: "",
      scaling_color: "#000",
      question_input_field_type: "text",
      image: "",
    });
    setInputList([{ option: "", checked: false }]);
    setFormAction("ADD");
    setMember([]);
    setSelectedExclude([]);
    setGroupsName([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputs.question_statement) {
      enqueueSnackbar("Question Statement is required", { variant: "error" });
      return;
    }
    setIsSubmitting(true);
    let error = "";
    const formData = new FormData();
    formData.append("status", inputs.status);
    formData.append("question_statement", inputs.question_statement);
    formData.append("question_placeholder", inputs.question_placeholder);
    formData.append("question_type", inputs.question_type);
    formData.append("is_required", inputs.is_required);
    if (created_for_id) {
      formData.append("created_for_id", created_for_id);
    }

    formData.append("is_document_allowed", inputs.is_document_allowed);
    formData.append("created_for", created_for);

    if (inputs.question_type == "scaling") {
      formData.append("scaling_max", inputs.scaling_max);
      formData.append("scaling_min", inputs.scaling_min);
      // formData.append("scaling_color", inputs.scaling_color);
      // formData.append("scaling_main_heading", inputs.scaling_main_heading);
      //
    } else {
      let options = [];
      inputList.map((item, index) => {
        if (
          (inputs.question_type == "mcq" ||
            inputs.question_type == "checkbox") &&
          item.option === ""
        ) {
          error = `Option ${index + 1} is required`;
          return;
        }
        options.push(item.option);
      });
      formData.append("options", JSON.stringify(options));
    }
    if (allowed_users) {
      let group_array = [];
      if (groupsName.length > 0) {
        group_array = groupsName.map((group) => {
          return {
            group_slug: group.group_slug,
          };
        });
      }
      // groupsName.map((group) => {
      //   group_array.push({
      //     group_slug: group,
      //   });
      // });
      let selected_member_array = [];
      member.map((member) => {
        if (member._id !== null) {
          selected_member_array.push({
            member_id: member?._id,
          });
        }
      });

      let exclude_member_array = [];
      let exclude_member_object = {};
      selectedExclude?.map((member) => {
        exclude_member_object = {
          _id: member._id,
        };
        exclude_member_array.push(exclude_member_object);
      });

      formData.append("group", JSON.stringify(group_array));
      formData.append("member", JSON.stringify(selected_member_array));
      formData.append("exclude_members", JSON.stringify(exclude_member_array));
    }

    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      setIsSubmitting(false);
    } else {
      const result =
        formAction === "ADD"
          ? await addLessonQuestionApi(formData)
          : await editLessonQuestionApi(formData, selectedQuestion._id);
      if (result.code === 200) {
        get_questions_list();
        handleRemoveValues();
        setIsSubmitting(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsSubmitting(false);
      }
    }
  };

  const get_questions_list = async () => {
    setIsLoading(true);
    const result = await lessonQuestionListingApi(created_for, created_for_id);
    if (result.code === 200) {
      let questionsArray = [];
      result.questionnaire.map((value, index) => {
        let isExpanded = false;
        if (index === 0) {
          isExpanded = true;
        }
        questionsArray.push({
          ...value,
          isExpanded: isExpanded,
        });
      });
      setVideoData(result.details);
      setQuestionsList(questionsArray);
      setReplies(result?.self_image_replies);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setSelectedQuestion(value);
    setOpenDelete(true);
  };
  const handleUsersDetail = (question) => {
    navigate(`${window.location.pathname}/users-detail/${question._id}`);
  };

  const handleEdit = (value) => {
    setSelectedQuestion(value);
    handleClick();
    setFormAction("EDIT");
    setInputs(value);
    setInputList(() => {
      return value.options.map((option) => {
        return {
          option: option,
          checked: false,
        };
      });
    });
    let member_array = [];
    if (value.member.length > 0) {
      value.member.map((member) => {
        if (member._id !== null) {
          member_array.push(member._id);
        }
      });
    }
    setMember(member_array);

    let exclude_members_array = [];
    if (value.exclude_members.length > 0) {
      value.exclude_members.map((member) => {
        if (member._id !== null) {
          exclude_members_array.push(member._id);
        }
      });
    }
    setSelectedExclude(exclude_members_array);

    let group_array = [];
    if (value.group.length > 0) {
      value.group.map((group) => {
        if (group._id) {
          group_array.push(group._id);
        }
      });
    }
    setGroupsName(group_array);
  };

  const handleCancel = () => {
    handleRemoveValues();
  };

  const handleChangeGroup = (value) => {
    setGroupsName(value);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  const handleExcludeSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setExcludeText(value);
  };

  const getGroupsAndMembers = async () => {
    const result = await memberAndGroupsListApi(serchText);
    if (result.code === 200) {
      if (!serchText.length) {
        setGroupListing(result.group);
        setExcludeMembers(result.members);
      }
      setPersonName(result.members);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getSearchExcludeAndMembers = async () => {
    const result = await memberAndGroupsListApi(excludeText);
    if (result.code === 200) {
      setExcludeMembers(result.members);
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setOpenDeleteReply(false);
    let postData = {
      message_id: deleteId,
      created_for: "self_image",
    };
    const result = await deleteLessonQuestion(selectedQuestion._id);
    if (result.code === 200) {
      get_questions_list();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleDeleteReply = async () => {
    setOpenDelete(false);
    setOpenDeleteReply(false);
    let postData = {
      message_id: deleteId,
      created_for: "self_image",
    };
    const result =
      is_self_image && created_for == "self_image"
        ? await DeleteSelfImageReplyApi(postData)
        : "";
    if (result.code === 200) {
      get_questions_list();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleCloseForm = () => {
    setIsOpenForm(false);
    handleRemoveValues();
  };
  let program_title = localStorage.getItem("program_title");
  let program_slug_local = localStorage.getItem("program_slug");
  let lesoon_title = localStorage.getItem("lesson_title");
  let lesoon_slug = localStorage.getItem("lesson_slug");

  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },

    {
      title: program_title ? program_title : "",
      navigation: `/programmes/programmes-detail/${program_slug_local}`,
      active: false,
    },
    {
      title: question_page_title ? question_page_title : "Questions",
      navigation: ``,
      active: true,
    },
  ];
  if (created_for == "lesson") {
    let lesson_object = {
      title: "Lessons",
      navigation: `/lessons/${program_slug_local}`,
      active: false,
    };
    let lesson_detail_object = {
      title: lesoon_title,
      navigation: `/lessons/lesson-detail/${lesoon_slug}`,
      active: false,
    };
    breadCrumbMenu.splice(2, 0, lesson_object);
    breadCrumbMenu.splice(3, 0, lesson_detail_object);
  }

  let event_id = localStorage.getItem("event_id");
  let event_video_id = localStorage.getItem("event_video_id");
  let event_vidoe_title = localStorage.getItem("event_vidoe_title");
  let event_title = localStorage.getItem("event_title");

  let breadCrumbMenuNew = [
    {
      title: event_title,
      navigation: `/dynamite-events`,
      active: false,
    },
    {
      title: event_vidoe_title,
      navigation: `/dynamite-event-categories/${event_id}`,
      active: false,
    },
    {
      title: videoData.title,
      navigation: `/dynamite-event-videos/${event_video_id}`,
      active: false,
    },
    {
      title: "Dynamite Event Video Questions",
      navigation: ``,
      active: true,
    },
  ];

  let breadCrumbMenuPage = [
    {
      title: location?.state?.sale_page_title
        ? location?.state?.sale_page_title
        : is_website_pages
        ? "Pages"
        : "Template Pages",
      navigation: page_name,
      active: false,
    },
    {
      title: "Website Page Questions",
      navigation: ``,
      active: true,
    },
  ];

  let breadCrumbQuarter = [
    {
      title: "Quarters",
      navigation: `/quarters`,
      active: false,
    },
    {
      title: "Quarters Questions",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    get_questions_list();
    if (allowed_users) {
      getGroupsAndMembers();
    }
  }, [created_for]);

  useEffect(() => {
    getGroupsAndMembers();
  }, [serchText]);

  useEffect(() => {
    setSerchText("");
  }, [member]);

  useEffect(() => {
    setExcludeText("");
  }, [selectedExclude]);

  useEffect(() => {
    getSearchExcludeAndMembers();
  }, [excludeText]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      {show_back_button !== false && (
        <div className="row">
          <div className="col-12 mb-3">
            {created_for == "programme" || created_for == "lesson" ? (
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            ) : created_for == "quarter" ? (
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbQuarter} />
              </span>
            ) : created_for == "page" ? (
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenuPage} />
              </span>
            ) : (
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenuNew} />
              </span>
            )}
          </div>
        </div>
      )}
      <div className="row mb-4">
        <div className="col-12 col-md-8 col-lg-8">
          <h1>{question_page_title ? question_page_title : "Questions"}</h1>
        </div>
        <div className="col-12 col-md-4 col-lg-4">
          <button
            className="small-contained-button float-end mt-1 ms-2"
            onClick={handleClick}
          >
            Add Question
          </button>
          {is_self_image && (
            <a
              className="small-contained-button float-end mt-1 anchor-style"
              // onClick={() => navigate(`/programmes/addreview/`)}
              // href="#dynamite-reply"

              onClick={handleClickToggle}
            >
              {/* {userInfo?.first_name && userInfo.first_name + "'s" + " Reply"} */}
              {"Dynamite's" + " Reply"}
            </a>
          )}
        </div>
      </div>

      {questionsList.length < 1 ? (
        <RecordNotFound title="Questions" />
      ) : (
        questionsList.map((questions, index) => {
          let groups = "";
          let members = "";
          let exclude_members = "";
          if (questions.group?.length > 0) {
            questions.group.map((group, index) => {
              groups += group?._id?.title;
              if (index + 1 !== questions.group?.length) {
                //don't add , with last one
                groups += ", ";
              }
            });
          }
          if (questions.member?.length > 0) {
            questions.member.map((member, index) => {
              let _member = member?._id;
              if (_member) {
                members +=
                  _member.first_name +
                  " " +
                  _member.last_name +
                  " (" +
                  _member.email +
                  ")";
                if (index + 1 !== questions.member?.length) {
                  //don't add , with last one
                  members += ", ";
                }
              }
            });
          }
          if (questions.exclude_members?.length > 0) {
            questions.exclude_members.map((member, index) => {
              let _exclude_members = member?._id;
              if (_exclude_members) {
                exclude_members +=
                  _exclude_members.first_name +
                  " " +
                  _exclude_members.last_name +
                  " (" +
                  _exclude_members.email +
                  ")";
                if (index + 1 !== questions.exclude_members?.length) {
                  //don't add , with last one
                  exclude_members += ", ";
                }
              }
            });
          }

          return (
            <div className="row mb-2" key={index}>
              <div className="main-accourdian">
                <div className="accordian-summery mb-3">
                  <div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12 col-md-9 col-sm-12 mt-4 me-3 new-questions-statement">
                          <h3>Question Statement</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: questions.question_statement,
                            }}
                          ></div>
                        </div>
                        <div className="col-lg-6 col-sm-12 mt-4">
                          <div className="custom-text-field">
                            {questions.question_placeholder && (
                              <span className="lable">
                                Question Placeholder
                              </span>
                            )}
                            {questions.question_placeholder
                              ? questions.question_placeholder
                              : "Question Placeholder"}
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 mt-4">
                          <div className="custom-text-field">
                            <span className="lable">Question Type</span>
                            {questions.question_type === "mcq"
                              ? "Single Selection"
                              : questions.question_type === "checkbox"
                              ? "Multiple Selection"
                              : questions.question_type == "editor"
                              ? "Editor"
                              : questions.question_type == "textarea"
                              ? "Text Area"
                              : "Scaling"}
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 mt-4">
                          <div className="custom-text-field">
                            <span className="lable">Status</span>
                            {questions.status === true ? "Active" : "Inactive"}
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 mt-4">
                          <div className="custom-text-field">
                            <span className="lable">Is Document Allowed *</span>
                            {questions.is_document_allowed === true
                              ? "Yes"
                              : "No"}
                            <ArrowDropDownIcon />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 mt-4">
                          <div className="custom-text-field">
                            <span className="lable">Is Required *</span>
                            {questions?.is_required === true ? "Yes" : "No"}
                            <ArrowDropDownIcon />
                          </div>
                        </div>
                        {groups && (
                          <div className="col-lg-6 col-sm-12 mt-4">
                            <div className="custom-text-field">
                              <span className="lable">Groups </span>
                              <div
                                style={{ height: "100px", overflowY: "auto" }}
                              >
                                <span>{groups}</span>
                              </div>
                            </div>
                          </div>
                        )}

                        {members && (
                          <div className="col-lg-6 col-sm-12 mt-4">
                            <div className="custom-text-field">
                              <span className="lable">Members </span>
                              <div
                                style={{ height: "100px", overflowY: "auto" }}
                              >
                                <span>{members}</span>
                              </div>
                            </div>
                          </div>
                        )}
                        {exclude_members && (
                          <div className="col-lg-6 col-sm-12 mt-4">
                            <div className="custom-text-field">
                              <span className="lable">Excluded Members </span>
                              <div
                                style={{ height: "100px", overflowY: "auto" }}
                              >
                                <span>{exclude_members}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    {questions.isExpanded ? (
                      <span style={{ cursor: "pointer" }}>
                        <ExpandLessIcon onClick={() => expandArea(index)} />
                      </span>
                    ) : (
                      <span style={{ cursor: "pointer" }}>
                        <ExpandMoreIcon onClick={() => expandArea(index)} />
                      </span>
                    )}
                  </div>
                </div>

                {questions.isExpanded && (
                  <div
                    className={`accordion-content ${
                      questions.isExpanded
                        ? " expanded-content"
                        : "collapsed-content"
                    }`}
                  >
                    <div className="mt-5 mb-3">
                      <>
                        {questions.question_type == "mcq" ||
                        questions.question_type === "checkbox" ? (
                          <>
                            {questions.options.map((option, i) => {
                              return (
                                <div
                                  className="col-lg-12 col-md-12 col-sm-12 mb-4 d-flex"
                                  key={i}
                                >
                                  <div className="col-1">
                                    <Radio
                                      checked={true}
                                      className="mt-2"
                                      name="checked"
                                    />
                                  </div>
                                  <div className="col-11">
                                    <div className="custom-text-field">
                                      <span className="lable">
                                        Enter an answer choice
                                      </span>
                                      {option}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : questions.question_type == "scaling" ? (
                          <div className="container-fluid">
                            <h3 className="scale-limit-heading">Scale Limit</h3>
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                                <div className="custom-text-field">
                                  <span className="lable">Min</span>
                                  {questions.scaling_min}
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                                <div className="custom-text-field">
                                  <span className="lable">Max</span>
                                  {questions.scaling_max}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="col-12 mt-4 text-end">
                          <button
                            className="small-contained-button"
                            onClick={() => {
                              handleEdit(questions);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="small-contained-button ms-2"
                            onClick={() => {
                              handleAgreeDelete(questions);
                            }}
                          >
                            Delete
                          </button>
                          {allowed_users && (
                            <button
                              className="small-contained-button ms-2"
                              onClick={() => {
                                handleUsersDetail(questions);
                              }}
                            >
                              View Detail
                            </button>
                          )}
                        </div>
                      </>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })
      )}
      {isOpenForm && (
        <form ref={ref} onSubmit={handleSubmit}>
          <div className="row question-background mt-5 pb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <h4>Question Statement *</h4>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                name="question_statement"
                editorHeight={320}
              />
            </div>
            <div className="col-lg-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Question Placeholder"
                variant="outlined"
                fullWidth
                name="question_placeholder"
                value={inputs.question_placeholder}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Question Type *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="question_type"
                  value={inputs.question_type}
                  label="Question Type *"
                  onChange={handleChange}
                  disabled={Boolean(formAction === "EDIT")}
                >
                  <MenuItem value="mcq">Single Selection</MenuItem>
                  <MenuItem value="checkbox">Multiple Selection</MenuItem>
                  <MenuItem value="scaling">Scaling</MenuItem>
                  <MenuItem value="textarea">Text Area</MenuItem>
                  {/* <MenuItem value="editor">Editor</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-4 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">status *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  label="status *"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-lg-4 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Is Document Allowed *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_document_allowed"
                  value={inputs.is_document_allowed}
                  label="Is Document Allowed *"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-4 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Is Required *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_required"
                  value={inputs.is_required}
                  label="Is Required *"
                  onChange={handleChange}
                >
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </Select>
              </FormControl>
            </div>
            {allowed_users && (
              <>
                <div className="col-6 mt-3">
                  <MUIAutocomplete
                    inputLabel="Groups"
                    selectedOption={groupsName}
                    setSelectedOption={handleChangeGroup}
                    optionsList={groupListing}
                    multiple
                    name="title"
                  />
                </div>
                <div className="col-md-6">
                  <FormControl className="mt-3" fullWidth>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={personName}
                      clearOnBlur={true}
                      getOptionLabel={(option) =>
                        option?.first_name + " (" + option?.email + ")"
                      }
                      filterSelectedOptions
                      value={member}
                      onChange={(event, newValue) => {
                        setMember(newValue);
                      }}
                      className="mui-autocomplete"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Members"
                          placeholder="Members"
                          onChange={handleSearch}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="col-12 mt-4">
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={excludeMembers}
                    getOptionLabel={(option) =>
                      option.first_name + " (" + option.email + ")"
                    }
                    filterSelectedOptions
                    value={selectedExclude}
                    onChange={(event, newValue) => {
                      setSelectedExclude(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Exclude Members"
                        placeholder="Exclude Members"
                        onChange={handleExcludeSearch}
                      />
                    )}
                  />
                </div>
              </>
            )}
            {inputs.question_type == "checkbox" && (
              <>
                {inputList.map((x, i) => {
                  return (
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4 d-flex">
                      <div className="col-1">
                        <Radio checked={true} className="mt-2" name="checked" />
                      </div>
                      <div className="col-9">
                        <TextField
                          id="outlined-basic"
                          label="Enter an answer choice"
                          variant="outlined"
                          fullWidth
                          required
                          name="option"
                          value={x.option}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </div>
                      <div className="col-2">
                        <AddCircleOutlineIcon
                          className="question-survey"
                          onClick={() => handleAddClick(i + 1)}
                        />
                        {inputList.length !== 1 && (
                          <RemoveCircleOutlineIcon
                            className="question-survey"
                            onClick={() => handleRemoveClick(i)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {inputs.question_type == "mcq" && (
              <>
                {inputList.map((x, i) => {
                  return (
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4 d-flex">
                      <div className="col-1">
                        <Radio checked={true} className="mt-2" name="checked" />
                      </div>
                      <div className="col-9">
                        <TextField
                          id="outlined-basic"
                          label="Enter an answer choice"
                          variant="outlined"
                          fullWidth
                          required
                          name="option"
                          value={x.option}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </div>
                      <div className="col-2">
                        <AddCircleOutlineIcon
                          className="question-survey"
                          onClick={() => handleAddClick(i + 1)}
                        />
                        {inputList.length !== 1 && (
                          <RemoveCircleOutlineIcon
                            className="question-survey"
                            onClick={() => handleRemoveClick(i)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {inputs.question_type == "scaling" && (
              <>
                <h3 className="mt-4">Scale Limit</h3>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Min"
                    variant="outlined"
                    fullWidth
                    type="number"
                    required
                    name="scaling_min"
                    value={inputs.scaling_min}
                    onChange={handleChange}
                    inputProps={{ min: 1 }}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Max"
                    variant="outlined"
                    fullWidth
                    type="number"
                    required
                    inputProps={{ min: 1 }}
                    name="scaling_max"
                    value={inputs.scaling_max}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
            <div className="col-12 mt-4 text-end">
              <button className="small-contained-button">
                {formAction === "ADD"
                  ? isSubmitting
                    ? "Submitting"
                    : "Submit"
                  : isSubmitting
                  ? "Updating"
                  : "Update"}
              </button>
              {formAction === "EDIT" && (
                <button
                  className="small-contained-button ms-2"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              )}
              <button
                className="small-contained-button ms-2"
                onClick={handleCloseForm}
              >
                Close
              </button>
            </div>
          </div>
        </form>
      )}
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openDeleteReply}
        setOpen={setOpenDeleteReply}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDeleteReply}
      />
      {is_self_image && created_for == "self_image" && (
        <>
          <div className="row mt-3">
            <div className="col-12">
              <h4 ref={titleRef} className="mb-4">
                {replies.length == 0 ? "" : "Admin's" + " Reply"}
              </h4>
            </div>
            <SlefImageReplies
              replies={replies}
              created_for={created_for}
              get_questions_list={get_questions_list}
            />
          </div>
          <AddKimsReply
            goalDetail={get_questions_list}
            is_self_image={is_self_image}
          />
        </>
      )}
    </div>
  );
};
