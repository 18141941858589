import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import { DeletePodsApi, pods_list_api_v1 } from "src/DAL/Pods/pods";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import { dd_date_format, show_proper_words } from "src/utils/constant";
import GroupsPopOver from "./GroupsPopOver";
import PodsFilter from "./PodsFilter";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import moment from "moment";
import FilteredChip from "src/components/FilteredChip";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CalendarPodsListCallPods() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [program, setprogram] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const opens = Boolean(anchorEl);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const id = opens ? "simple-popover" : undefined;

  const EMPTY_FILTER = {
    action_by: "admin_user",
    community_level: "all",
    search_text: "",
  };

  const ALTER_FILTER = {
    action_by: "all",
    community_level: "all",
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleClickPopUP = (event, row) => {
    setprogram(row.group);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const getConsultantListing = async (filter_data) => {
    setIsLoading(true);
    let postData = { ...filter_data };
    const result = await pods_list_api_v1(
      page,
      rowsPerPage,
      "booking",
      postData
    );
    if (result.code === 200) {
      let chipData = { ...filter_data };
      if (chipData.action_by == "all") {
        delete chipData.action_by;
      }
      if (chipData.community_level == "all") {
        delete chipData.community_level;
      }
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      setFilterStateUpdated(chipData);
      const data = result.room.map((room) => {
        let data_time_start = "N/A";

        if (room.start_date) {
          data_time_start = `${room.start_date} (${moment(
            room.start_time,
            "HH:mm"
          ).format("hh:mm A")}) `;
        }

        let startTime = moment(room?.start_time, "HH:mm");
        let start_date_and_time = moment.duration({
          hours: room?.duration_hour,
          minutes: room?.duration_minute,
        });

        let endTime = startTime.add(start_date_and_time);
        let formattedEndTime = endTime.format("hh:mm A");

        let start_date_end_date = "N/A";
        if (room.end_date) {
          start_date_end_date = `${dd_date_format(
            room.start_date
          )} to ${dd_date_format(room.end_date)} `;
        } else {
          start_date_end_date = `${dd_date_format(
            room.start_date
          )} to ${dd_date_format(room.start_date)} `;
        }

        let start_time_end_time = "N/A";
        if (room.start_time) {
          start_time_end_time = `${moment(room.start_time, "HH:mm").format(
            "hh:mm A"
          )} to ${formattedEndTime} `;
        }

        return {
          ...room,
          table_avatar: {
            src: s3baseUrl + room?.room_image?.thumbnail_1,
            alt: room.title,
          },
          start_date_end_date,
          start_time_end_time,
        };
      });
      setUserList(data);
      setTotalPages(result.total_pages);
      setTotalCount(result.room_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (value) => {
    navigate(`/book-call-pods/edit-pod/${value.room_slug}`, {
      state: { editValues: value.object },
    });
  };

  const handleViewDetail = (value) => {
    navigate(`/book-call-pods/pods-detail/${value.room_slug}`, {
      state: { ...value, pods_name: "Book Call Pods" },
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeletePodsApi(deleteDoc.room_slug);
    if (result.code === 200) {
      getConsultantListing(filterData);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getConsultantListing(filterData);
    localStorage.removeItem("pods_bookcall_filter_data");
    localStorage.setItem(
      "pods_bookcall_filter_data",
      JSON.stringify(filterData)
    );
    handleCloseFilterDrawer();
  };

  const handleClearFilter = () => {
    setFilterData(ALTER_FILTER);
    getConsultantListing(ALTER_FILTER);
    setFilterDrawerState(false);
    localStorage.removeItem("pods_bookcall_filter_data");
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    data.search_text = searchText;
    getConsultantListing(data);
    localStorage.removeItem("pods_bookcall_filter_data", JSON.stringify(data));
    setFilterData(data);
    handleCloseFilterDrawer();
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: "Name",
      className: "pointer",
      handleClick: handleViewDetail,
    },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    {
      id: "room_user",
      label: "Member With Booked Calls",
      renderData: (row) => {
        return (
          <>
            {row.room_user.length > 0 &&
              row.room_user.map((item) => {
                let room_name = "N/A";
                if (item._id) {
                  room_name =
                    item._id.first_name +
                    " " +
                    item._id.last_name +
                    " (" +
                    item._id.email +
                    ")";
                }
                return room_name;
              })}
          </>
        );
      },
    },
    { id: "action_by_info", label: "Consultant In Booked Calls" },
    {
      id: "zoom_link",
      label: "Zoom Link",
      renderData: (row) => {
        return (
          <>
            {row.zoom_link == "" ? (
              "N/A"
            ) : (
              <a href={row.zoom_link} target="_blank" className="anchor-style">
                View Zoom Link
              </a>
            )}
          </>
        );
      },
    },
    {
      id: "type",
      label: "Type",
      renderData: (row) => {
        return <p className="mb-0">{show_proper_words(row.room_type)}</p>;
      },
    },
    {
      id: "community_level",
      label: "Pods Level",
      renderData: (row) => {
        return <p className="mb-0">{show_proper_words(row.community_level)}</p>;
      },
    },
    {
      id: "start_date_end_date",
      label: "Start Date | End Date",
    },
    {
      id: "start_time_end_time",
      label: "Start Time | End Time",
    },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("pods_bookcall_filter_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setFilterStateUpdated(filter_data);
    getConsultantListing(filter_data);
  }, [rowsPerPage, page]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleViewDetail,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <h2>Book Call Pods</h2>
          </div>

          <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
            <div>
              <button
                className="small-contained-button me-2 mt-1 mb-4"
                onClick={handleOpenFilterDrawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
            </div>
          </div>
          <FilteredChip
            data={filterStateUpdated}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            ALTER_FILTER={ALTER_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        </div>
        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete ?"}
          handleAgree={handleDelete}
        />
        <div className="mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            MENU_OPTIONS={MENU_OPTIONS}
            data={userList}
            className="card-with-background"
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            pagePagination={true}
          />
        </div>
        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filters"
          componentToPassDown={
            <PodsFilter
              filterData={filterData}
              handleChange={handleChange}
              setFilterData={setFilterData}
              searchSubmitFilter={searchFunction}
              handleClearFilter={handleClearFilter}
            />
          }
        />
        <GroupsPopOver
          handleClick={handleClickPopUP}
          handleClose={handleClosePopUp}
          anchorEl={anchorEl}
          id={id}
          open={opens}
          program={program}
          navigatePage={true}
        />
      </div>
    </>
  );
}
