import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import { infoImages } from "src/assets";

export default function StatusField(props) {
  const { item, handleChange, heading } = props;
  const [isField, setIsField] = useState(false);

  return (
    <div>
      {isField ? (
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Status"
            name="status"
            autoFocus
            open={isField}
            onClose={() => setIsField(false)}
            value={item?.status}
            onChange={handleChange}
          >
            <MenuItem value={false}>Inactive</MenuItem>
            <MenuItem value={true}>Active</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <div
          className="static-textfield cursor-pointer"
          onClick={() => setIsField(true)}
        >
          <div className="static-textfield-label px-1">Status</div>
          <div className="field-value">
            {item?.status ? "Active" : "Inactive"}
          </div>
          <div className="dropdown-arrow">
            <ArrowDropDownIcon />
          </div>
        </div>
      )}
    </div>
  );
}
