import * as React from "react";
import { useState } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import { AddKimsReplyApi } from "src/DAL/member/member";
import { project_name } from "src/config/config";
import TinyEditor from "src/components/ckeditor/Ckeditor";

const Input = styled("input")({
  display: "none",
});

export default function AddKimsReply({ setReplies }) {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [audioFile, setAudio] = useState();
  const [detailDescriptionCk, setDetailDescriptionCk] = useState("");

  const audioFileChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handldeDeleteAudio = () => {
    setAudio();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("member_id", params.id);
    formData.append("message", detailDescriptionCk);
    if (audioFile) {
      formData.append("audio_file", audioFile);
    }
    const result = await AddKimsReplyApi(formData);
    if (result.code === 200) {
      setReplies(result.data);
      setDetailDescriptionCk("");
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12 mt-2">
        <h4>
          {project_name == "basic-dev"
            ? "Kim's Reply"
            : project_name == "dynamite-lifestyle-dev"
            ? "Kim's Reply"
            : project_name == "danielle-dev"
            ? "Danielle's Reply"
            : project_name == "hina-khan-dev"
            ? "Hina's Reply"
            : project_name == "dev-feature"
            ? "Kim's Reply"
            : project_name == "pgi-dev"
            ? "Kim's Reply"
            : "Kim's Reply"}
        </h4>
        <TinyEditor
          setDetailDescription={setDetailDescriptionCk}
          detailDescriptionCk={detailDescriptionCk}
          editorHeight={350}
        />
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div className="row w-100 div-style ms-0 pt-0">
          <div className="col-5">
            <p className="">Upload Audio</p>
            <FormHelperText className="pt-0">
              Audio mp3 (max 200mb)
            </FormHelperText>
          </div>
          <div className="col-2">
            {audioFile && (
              <DeleteIcon
                onClick={handldeDeleteAudio}
                className="mt-3 icon-color"
              />
            )}
          </div>
          <div className="col-5 text-end pt-2">
            <label htmlFor="audio">
              <Input
                accept="audio/mp3,audio/*;capture=microphone"
                id="audio"
                multiple
                name="audio"
                type="file"
                onChange={audioFileChange}
              />

              <Button
                className="small-contained-button"
                startIcon={<FileUploadIcon />}
                component="span"
              >
                Upload
              </Button>
            </label>
          </div>
        </div>
        <p className="text-secondary">{audioFile && audioFile.name}</p>
      </div>

      <div className="text-end mt-4">
        <button onClick={handleSubmit} className="small-contained-button">
          {isLoading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
}
