import { invokeApi } from "../../bl_libs/invokeApi";

export const _update_attitude_accessment_setting = async (data) => {
  const requestObj = {
    path: `/api/attitude_assessment_question/assessment_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_attitude_accessment_setting_v1 = async (data) => {
  const requestObj = {
    path: `/api/attitude_assessment_question/assessment_setting/update`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_attitude_accessment_setting = async (type) => {
  const requestObj = {
    path: `/api/attitude_assessment_question/assessment_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _get_monthaly_setting = async () => {
  const requestObj = {
    path: `/api/default_setting/get/delegate_report_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _get_website_cookie_setting = async (type) => {
  const requestObj = {
    path: `/api/content_setting/get_website_cockie_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _get_store_house_setting = async () => {
  const requestObj = {
    path: `/api/default_setting/get/storehouse_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
