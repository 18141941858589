import { invokeApi } from "../../bl_libs/invokeApi";

export const delegateGroupListingApi = async (data) => {
  const requestObj = {
    path: `/api/delegate_group/all_group_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delegateAddDataApi = async (data) => {
  const requestObj = {
    path: `/api/delegate_group/delegates_and_programs_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const groupDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/delegate_group/group_detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddDelegateGroupApi = async (data) => {
  const requestObj = {
    path: `/api/delegate_group/add_delegate_group`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateDelegateGroupApi = async (data, slug) => {
  const requestObj = {
    path: `/api/delegate_group/update_group/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditGroupApi = async (data, slug) => {
  const requestObj = {
    path: `/api/group/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteDelegateGroupApi = async (slug) => {
  const requestObj = {
    path: `/api/delegate_group/delete_group/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
