import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { getTransactionDetailApi } from "src/DAL/Transaction/transactionApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  { id: "first_name", label: "First Name" },
  { id: "last_name", label: "Last Name" },
  { id: "email", label: "Email" },
  { id: "contact_number", label: "Phone" },
  { id: "venue_title", label: "Venue" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ViewTicketsList() {
  const classes = useStyles();
  const [ticketsList, setTicketsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { transaction_id } = useParams();
  const navigate = useNavigate();

  const getTicketsListing = async () => {
    setIsLoading(true);
    const result = await getTransactionDetailApi(transaction_id);
    if (result.code === 200) {
      let data = [];
      if (result.transaction.tickets.length > 0) {
        data = result.transaction.tickets.map((user) => {
          return {
            ...user,
            venue_title: user.venue?.title,
          };
        });
      }
      setTicketsList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTicketsListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mobile-margin display-flex">
          <div className="col-12 d-flex mb-4">
            <IconButton
              className="back-screen-button "
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <h2 className="ms-3">Tickets List</h2>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={ticketsList}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
