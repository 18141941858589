import { invokeApi } from "../../bl_libs/invokeApi";

export const questionListingApi = async () => {
  const requestObj = {
    path: `/api/goal_statement_question`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getDetailQuestion = async (id, type, page, limmit, search) => {
  const requestObj = {
    path: `/api/goal_statement_question/${id}?type=${type}&page=${page}&limit=${limmit}&search_text=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddQuestion = async (data) => {
  const requestObj = {
    path: `/api/goal_statement_question`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddCommentOnQuestion = async (data) => {
  const requestObj = {
    path: `/api/goal_statement_question/add_comment_on_goal_statement_question`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddCommentOnSelfImageQuestion = async (data) => {
  const requestObj = {
    path: `/api/questionnaire/add_comment_on_member_replies`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editCommentOnQuestion = async (data, id) => {
  const requestObj = {
    path: `/api/goal_statement_question/comment/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editCommentOnSelfImageQuestion = async (data) => {
  const requestObj = {
    path: `/api/questionnaire/edit_reply_by/admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditQuestion = async (data, id) => {
  // console.log(id, "params.id");
  const requestObj = {
    path: `/api/goal_statement_question/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteQuestion = async (id) => {
  const requestObj = {
    path: `/api/goal_statement_question/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_goal_statement_unlock_settings_api = async () => {
  const requestObj = {
    path: `/api/default_setting/get_goal_statement_unlock_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_goal_statement_unlock_settings_api = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update_goal_statement_unlock_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const self_image_responded_users = async (page, limit, search_text) => {
  const requestObj = {
    path: `/api/member/history/list?page=${page}&limit=${limit}&search_text=${search_text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_history_detail_api = async (user_id) => {
  const requestObj = {
    path: `/api/member/history/detail/${user_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
