import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Chip, Tooltip } from "@mui/material";
import Label from "src/components/Label";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import { consultantMembersList } from "src/DAL/consultant/consultant";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import MemberProgramPopover from "../MembersList/memberProgramPopOver";
import { dd_date_format } from "src/utils/constant";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function MembersListConsultantNew() {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [program, setprogram] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [member, setMember] = useState([]);
  const [useInfo, setuseInfo] = useState();
  const [UserId, setUserId] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const getMemberListing = async () => {
    try {
      setIsLoading(true);
      const result = await consultantMembersList(params.id);
      if (result.code === 200) {
        setMember(result.member);
        setIsLoading(false);
        setuseInfo(result?.consultant);
        const data = result.member.map((member) => {
          let name =
            member.first_name +
            " " +
            member.last_name +
            "(" +
            member.email +
            ")";
          return {
            id: member._id,
            name: member.first_name,
            lastName: member.last_name,
            fullName: name,
            status: member.status,
            email: member.email,
            profile: member.profile_image,
            table_avatar: {
              src: s3baseUrl + member.profile_image,
              alt: member.first_name,
            },
            program: member.program,
            goal: member.goal_statement_status,
            contact_number: member.contact_number,
            street: member.street,
            city: member.city,
            zip_code: member.zip_code,
            state: member.state,
            selectedCountry: member.country,
            consultant_id: member?.consultant?._id,
            time_zone: member.time_zone,
            member_biography: member.member_biography,
            profile_image: member.profile_image,
            first_payment_date: member.first_payment_date,
            selected_country: member.country,
          };
        });
        setUserList(data);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleEdit = (value) => {
    navigate(`/member/edit-member/${value.id}`, {
      state: value,
    });
  };
  const handleProfile = (value) => {
    navigate(`/consultant/member-profile/${value.id}`, {
      state: value,
    });
  };
  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
    },
    { id: "fullName", label: "Name", alignRight: false },

    {
      id: "program",
      label: "Programmes",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        return firstFiveprograms && firstFiveprograms.length > 0 ? (
          firstFiveprograms?.map((program, i) => {
            return (
              <>
                <Tooltip
                  key={i}
                  title={
                    program.expiry_date
                      ? " Expiry: " + dd_date_format(program.expiry_date)
                      : "No Expiry"
                  }
                  arrow
                >
                  <p className="mb-1 program-hover">{program._id.title}</p>
                </Tooltip>
                {i == 4 && (
                  <p
                    className="mb-1 mt-1 view-more"
                    aria-describedby={id}
                    variant="contained"
                    onClick={(e) => handleClickPopUP(e, row)}
                  >
                    view more
                  </p>
                )}
              </>
            );
          })
        ) : (
          <p>N/A</p>
        );
      },
      alignRight: false,
    },

    {
      id: "goal",
      label: "Goal",
      alignRight: false,
      renderData: (row) => {
        return (
          <Label
            variant="ghost"
            color={row.goal === false ? "error" : "success"}
          >
            {row.goal === false ? "Lock" : "Unlock"}
          </Label>
        );
      },
    },

    {
      id: "status",
      label: "Status",
      alignRight: false,
      renderData: (row) => {
        return (
          <Label
            variant="ghost"
            color={row.status === false ? "error" : "success"}
          >
            {row.status === false ? "Inactive" : "Active"}
          </Label>
        );
      },
    },
  ];
  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopUp = () => {
    setAnchorEl(null);
  };
  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;

  useEffect(() => {
    getMemberListing();
  }, []);
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "View Profile",
      icon: "iconoir:profile-circled",
      handleClick: handleProfile,
    },
  ];

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: `/consultant`,
      active: false,
    },

    {
      title:
        useInfo?.first_name +
        " " +
        useInfo?.last_name +
        " (" +
        useInfo?.email +
        ")",
      active: true,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-3">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <h2>Members</h2>
          </div>
        </div>
        <div className="mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={userList}
            MENU_OPTIONS={MENU_OPTIONS}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
        <MemberProgramPopover
          handleClick={handleClickPopUP}
          handleClose={handleClosePopUp}
          navigatePage={true}
          anchorEl={anchorEl}
          id={id}
          open={opens}
          program={program}
        />
      </div>
    </>
  );
}
