import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { get_root_value } from "src/utils/domUtils";

import { VaultActiveListing } from "src/DAL/Vault/vault";
import {
  AddRecording,
  EditRecordingApi,
  recording_detail_api,
} from "src/DAL/Recording/RecordingsApi";
import { urlPatternValidation } from "src/utils/constant";
import { useEffect } from "react";
import { programmeActiveListing } from "src/DAL/Programme/Programme";
import { s3baseUrl } from "src/config/config";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  button: {
    backgroundColor: get_root_value("--button-background-color"),
    color: get_root_value("--button-text-color"),
    "&:hover": {
      backgroundColor: get_root_value("--button-background-color"),
      color: get_root_value("--button-text-color"),
    },
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateRecordings() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [file, setProfileImage] = useState();
  const [audioFile, setAudio] = useState();
  const [programList, setProgramList] = useState([]);
  const [vaultList, setVaultList] = useState([]);
  const [date, setDate] = useState(new Date());
  const [programName, setProgramName] = useState("");
  const [categorySlug, setCategorySlug] = useState("");
  const [formType, setFormType] = useState("ADD");
  const [oldImage, setOldImage] = useState("");
  const [oldAudio, setOldAudio] = React.useState();
  const [inputs, setInputs] = useState({
    title: "",
    zoomLink: "",
    status: true,
    program: "",
    image: {},
    password: "",
    areaCode: "",
    video_url: "",
    groups: [],
    members: [],
    short_description: "",
    detailed_description: "",
  });

  const getVaultList = async () => {
    const result = await VaultActiveListing();
    if (result.code === 200) {
      const categoryData = result.vault_category;
      categoryData.unshift({ _id: "", vault_slug: "", vault_title: "None" });
      setVaultList(categoryData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getProgramListing = async () => {
    const result = await programmeActiveListing();
    if (result.code === 200) {
      setProgramList(result.program);
      if (!params.recording_slug) {
        setProgramName(result.program[0].program_slug);
      }
      getVaultList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleFormatData = (data) => {
    setFormType("EDIT");
    setInputs({
      ...data,
      detailed_description: data.detailed_description ?? "",
    });
    if (data.vault_category?.vault_slug) {
      setCategorySlug(data.vault_category.vault_slug);
    }
    if (data.program?.program_slug) {
      setProgramName(data.program.program_slug);
    }
    setOldImage(data.recording_image.thumbnail_2);
    if (data.audio_recording) {
      setOldAudio(data.audio_recording);
    }
    getProgramListing();
    window.history.replaceState({}, document.title);
  };

  const get_recording_detail = async () => {
    let result = await recording_detail_api(params.recording_slug);
    if (result.code == 200) {
      handleFormatData(result.recording);
    } else {
      navigate(`/recording-list`);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const audioFileChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handldeDeleteAudio = () => {
    setAudio();
  };

  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (urlPatternValidation(inputs.video_url) === false) {
      enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("title", inputs.title);
      formData.append("short_description", inputs.short_description);
      formData.append("status", inputs.status);
      formData.append("video_url", inputs.video_url);
      formData.append("program_slug", programName);
      formData.append("detailed_description", inputs.detailed_description);
      formData.append("recording_date", moment(date).format("YYYY-MM-DD"));
      formData.append("vault_category_slug", categorySlug);
      if (audioFile) {
        formData.append("audio_file", audioFile);
      }

      if (formType === "EDIT") {
        if (file) {
          formData.append("image", inputs.image);
        }
      } else {
        formData.append("image", inputs.image);
      }

      const result =
        formType === "ADD"
          ? await AddRecording(formData)
          : await EditRecordingApi(params.recording_slug, formData);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(`/recording-list`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };
  const handleChangeProgram = (e) => {
    setProgramName(e.target.value);
  };
  const handleChangeVault = (event) => {
    setCategorySlug(event.target.value);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (params && params.recording_slug) {
      if (state) {
        handleFormatData(state);
      } else {
        get_recording_detail();
      }
    } else {
      getProgramListing();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex ">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/recording-list`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Recording`}</h2>
        </div>
      </div>

      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Recording Title "
            variant="outlined"
            fullWidth
            name="title"
            value={inputs.title}
            required
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="Recording Date"
                inputFormat="dd-MM-yyyy"
                value={date}
                onChange={handleChangeDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Video Url"
            variant="outlined"
            fullWidth
            type="url"
            name="video_url"
            required
            value={inputs.video_url}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Recording Status *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Recording Status *"
              onChange={handleChange}
            >
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Programme</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={programName}
              label="Programme"
              onChange={handleChangeProgram}
              required
            >
              {programList.map((program, i) => {
                return (
                  <MenuItem key={i} value={program.program_slug}>
                    {program.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Vault Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={categorySlug}
              label="Vault Category"
              onChange={handleChangeVault}
            >
              {vaultList.map((vault, i) => {
                return (
                  <MenuItem value={vault.vault_slug} key={i}>
                    {vault.vault_title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 ">
            <div className="col-lg-7 col-md-5 col-sm-12">
              <p className="">Upload Audio</p>
              <FormHelperText className="pt-0">
                Audio mp3 (max 200mb)
              </FormHelperText>
            </div>
            <div className="col-4 col-md-2">
              {audioFile && (
                <DeleteIcon
                  onClick={handldeDeleteAudio}
                  className="mt-3 icon-color"
                />
              )}
            </div>
            <div className="col-lg-3 col-md-5 col-8 text-end pt-2 ps-0">
              <label htmlFor="audio">
                <Input
                  accept="audio/mp3,audio/*;capture=microphone"
                  id="audio"
                  multiple
                  name="audio"
                  type="file"
                  onChange={audioFileChange}
                />
                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          <div className="col-12 mt-2">
            {oldAudio && (
              <audio className="w-100" src={s3baseUrl + oldAudio} controls />
            )}
          </div>
          <p className="text-secondary">{audioFile && audioFile.name}</p>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image *</p>
              <FormHelperText className="pt-0">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {file ? (
                <img src={file} height="50" />
              ) : (
                oldImage && <img src={s3baseUrl + oldImage} height="50" />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image?.name}</p>
          )}
        </div>

        <div className="col-12 mt-5">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description"
              multiline
              rows={6}
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <div className="col-12 mt-4">
          <h4>Detail Description </h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="detailed_description"
            editorHeight={320}
          />
        </div>

        <div className="text-end mt-4">
          <button className="small-contained-button">
            {formType == "ADD" ? "Submit" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
