import React from "react";
import { get_kmb_number } from "src/utils/formatNumber";
import { show_proper_words } from "src/utils/constant";
import CallIcon from "@mui/icons-material/Call";
import { makeStyles } from "@mui/styles";

export default function CallStatsCard({ stat }) {
  const { count, title, text_color, background_color } = stat;

  const useStyles = makeStyles({
    icon: {
      color: text_color,
      fill: text_color,
    },
  });
  const classes = useStyles();

  function getRGBColor(hex) {
    hex = hex.replace(/^#/, "");
    var bigint = parseInt(hex, 16);
    var red = (bigint >> 16) & 255;
    var green = (bigint >> 8) & 255;
    var blue = bigint & 255;

    const color1 = `rgb(${red}, ${green}, ${blue}, 0)`;
    const color2 = `rgb(${red}, ${green}, ${blue}, 0.24)`;
    return `linear-gradient(135deg, ${color1} 0%, ${color2} 100%)`;
  }

  return (
    <div className={`col-12 col-md-6 col-lg-3 mb-4`}>
      <div className="stats-card">
        <div
          className="stats-icon-box"
          style={{
            backgroundImage: getRGBColor(background_color),
          }}
        >
          <CallIcon className={classes.icon} />
        </div>
        <h3>{get_kmb_number(count)}</h3>
        <h6>{show_proper_words(title)}</h6>
      </div>
    </div>
  );
}
