import { useEffect, useState } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import {
  convertCurrencyToSign,
  dd_date_format,
  show_proper_words,
} from "src/utils/constant";
import { subscription_list_with_page_and_plan } from "src/DAL/WebsitePages/WebsitePages";
import moment from "moment";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import SubscriptionListFilter from "./SubscriptionListFilter";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import Label from "src/components/Label";
import FilteredChip from "src/components/FilteredChip";
import { project_mode } from "src/config/config";
import SubscriptionDetail from "./SubscriptionDetail";

const useStyles = makeStyles(() => ({
  loadingSection: {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%",
    padding: 0,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SubscriptionList() {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoadingSection, setIsLoadingSection] = useState(true);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [showPop, setshowPop] = useState(false);
  const [value, setValue] = useState({});

  const EMPTY_FILTER = {
    payment_request: [],
    transection_type: "all",
    sale_page: null,
    payment_plan: null,
    expired: "active",
    expiry_in: "",
    start_date: new Date(),
    end_date: new Date(),
    filter: project_mode == "dev" ? "sandBox" : "live",
    expiry_name: null,
    search_text: "",
  };

  let ALTER_FILTER = {
    expired: "",
  };

  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleChangeOther = (name, value) => {
    setFilterState((values) => ({ ...values, [name]: value }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const getMembersList = async (filterData) => {
    setIsLoadingSection(true);
    handleCloseFilterDrawer();
    let postData = { ...filterData };
    if (postData.sale_page) {
      postData.sale_page = postData.sale_page._id;
    } else {
      postData.sale_page = "";
    }
    if (postData.payment_plan) {
      postData.payment_plan = postData.payment_plan._id;
    } else {
      postData.payment_plan = "";
    }

    if (postData.expired == "expired") {
      postData.expired = true;
    } else if (postData.expired == "active") {
      postData.expired = false;
    } else {
      postData.expired = "all";
    }

    if (postData.transection_type == "payment_request") {
      postData.payment_request = postData.payment_request.map((item) => {
        return item._id;
      });
    }

    if (postData.expiry_in) {
      postData.start_date = moment(postData?.start_date).format("YYYY-MM-DD");
      postData.end_date = moment(postData?.end_date).format("YYYY-MM-DD");
      if (postData.expiry_in !== "custom") {
        postData.end_date = moment(postData.start_date, "YYYY-MM-DD")
          .add(postData.expiry_in, "days")
          .format("YYYY-MM-DD");
      }
    } else {
      delete postData.start_date;
      delete postData.end_date;
    }
    delete postData.expiry_name;
    const result = await subscription_list_with_page_and_plan(
      page,
      rowsPerPage,
      postData
    );
    if (result.code == 200) {
      let chipData = { ...filterData };
      if (chipData.transection_type !== "sale_page") {
        delete chipData.sale_page;
        delete chipData.payment_plan;
      }
      if (chipData.transection_type !== "payment_request") {
        delete chipData.payment_request;
      }
      if (postData.expired == false) {
        let expiry_name = "";
        if (chipData.expiry_in !== "custom") {
          expiry_name = `Expiry In ${chipData.expiry_in} Days`;
        } else {
          expiry_name = `Start Date : ${dd_date_format(
            chipData.start_date
          )} End Date : ${dd_date_format(chipData.end_date)}`;
        }
        chipData.expiry_name = expiry_name;
        setFilterState(() => {
          return {
            ...filterData,
            expiry_name,
          };
        });
      }

      if (chipData.expiry_in == "") {
        delete chipData.expiry_name;
      }
      if (!chipData.expired) {
        delete chipData.expiry_in;
      }

      delete chipData.start_date;
      delete chipData.end_date;
      delete chipData.search_text;

      setFilterStateUpdated(chipData);
      let subscription_list = [];
      if (result.subscription.length > 0) {
        subscription_list = result.subscription.map((event, index) => {
          let member_name = "N/A";
          if (event.member && event.member.first_name) {
            member_name =
              event.member.first_name +
              " " +
              event.member.last_name +
              " (" +
              event.member.email +
              ")";
          }
          let event_plan = event.plan;
          let plan_title = "N/A";
          if (event_plan?.plan_title) {
            plan_title = event_plan?.plan_title;
          }
          let payment_access = event_plan?.payment_access;
          if (plan_title != "N/A") {
            if (event_plan?.is_plan_free) {
              plan_title = plan_title + " (Free)";
            } else if (payment_access == "recursion") {
              plan_title = plan_title + " (Recurring)";
            } else if (payment_access == "onetime") {
              plan_title = plan_title + " (OneTime)";
            } else {
              plan_title = plan_title;
            }
          }

          let product = "N/A";
          if (event.sale_page) {
            product = `Sale Page (${event.sale_page.sale_page_title} | ${event?.plan?.plan_title})`;
          } else {
            if (event.payment_request_id) {
              product = `Payment Request (${show_proper_words(
                event.payment_request_id.request_title +
                  " | " +
                  event.payment_request_id.request_type
              )})`;
            }
          }
          return {
            ...event,
            product,
            member_name: member_name,
            page_name: event.sale_page?.sale_page_title,
            created_at: dd_date_format(event.createdAt),
            invoice_date: event.next_invoice_date
              ? dd_date_format(event.next_invoice_date)
              : "N/A",
            plan_title: plan_title,
            statusDate: event.subscription_status,
          };
        });
      }

      setSubscriptionsData(subscription_list);
      setTotalCount(result.total_subscription_count);
      setTotalPages(result.total_pages);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setIsLoadingSection(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterState.search_text = searchText;
    getMembersList(filterState);
    localStorage.setItem("subscription_list_data", JSON.stringify(filterState));
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);

    if (data.sale_page == null) {
      data.payment_plan = null;
    }
    if (!data.expiry_name) {
      data.expiry_in = "";
      data.expired = "";
      data.start_date = new Date();
      data.end_date = new Date();
    }
    getMembersList(data);
    setFilterState(data);
    localStorage.setItem("subscription_list_data", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    let clear_data = {
      ...EMPTY_FILTER,
      expired: "",
      filter: "",
    };
    setPage(0);
    setPageCount(1);
    setFilterState(clear_data);
    getMembersList(clear_data);
    localStorage.removeItem("subscription_list_data");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeDate = (name, value) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleOpenPopup = (row) => {
    setValue(row);
    setshowPop(true);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "member_name",
      label: "Member",
      renderData: (row) => {
        return (
          <>
            <span
              className={`${
                row.stripe_subscription_id ? "hover_div pointer_cursor" : ""
              }`}
              onClick={() => {
                if (!!row.stripe_subscription_id) {
                  handleOpenPopup(row);
                }
              }}
            >
              {row.member_name}
            </span>
          </>
        );
      },
    },
    { id: "product", label: "Product" },
    {
      id: "upsell_plans",
      label: "Upsell Products",
      renderData: (row) => {
        let Currency = convertCurrencyToSign(row?.currency);
        return (
          <div>
            {row?.upsell_plans?.length > 0
              ? row?.upsell_plans?.map((item) => {
                  let plan_price = item?.plan_info?.plan_price
                    ? item?.plan_info?.plan_price
                    : 0;
                  return (
                    <Tooltip
                      title={
                        <>
                          <div>{`Plan Price: ${Currency + plan_price}`}</div>
                        </>
                      }
                    >
                      <div className="pointer">
                        <span>
                          {item?.plan_info?.plan_title}
                          {row?.upsell_plans?.length > 1 ? "," : ""}
                        </span>
                      </div>
                    </Tooltip>
                  );
                })
              : "N/A"}
          </div>
        );
      },
    },
    {
      id: "upsell_plans",
      label: "Upsell Plans Amount",
      renderData: (row) => {
        return (
          <div>
            {row?.upsell_plans_amount ? (
              <>
                {convertCurrencyToSign(row?.currency) +
                  row?.upsell_plans_amount}
              </>
            ) : (
              <>{convertCurrencyToSign(row?.currency) + 0}</>
            )}
          </div>
        );
      },
    },
    {
      id: "discount_info",
      label: "Discount Information",
      renderData: (row) => {
        let Currency = convertCurrencyToSign(row?.currency);
        return (
          <>
            {row.discount_info ? (
              <>
                <div>
                  <span className="main-menu-heading fw-normal">
                    Original Amount:{" "}
                  </span>
                  <span>{Currency + row?.discount_info?.original_amount}</span>
                </div>
                <div>
                  <span className="main-menu-heading fw-normal">
                    Discount:{" "}
                  </span>
                  <span>{Currency + row?.discount_info?.discount_amount}</span>
                </div>
                <div>
                  <span className="main-menu-heading fw-normal">
                    Discount Code:{" "}
                  </span>
                  <span>{row?.discount_info?.id}</span>
                </div>
              </>
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },
    {
      id: "subscription_created_by",
      label: "Created By",
      className: "text-capitalize",
      renderData: (row) => {
        return (
          <p className="mb-0">
            {show_proper_words(`${row.subscription_created_by}`)}{" "}
          </p>
        );
      },
    },
    { id: "stripe_mode", label: "Subscription Mode" },
    {
      id: "created_at",
      label: "Subscription Date",
    },
    { id: "invoice_date", label: "Next Invoice Date" },
    {
      id: "statusDate",
      label: "Status",

      renderData: (row) => {
        return (
          <Label
            variant="ghost"
            color={row.statusDate === false ? "error" : "success"}
          >
            {row.statusDate === false ? "Expired" : "Active"}
          </Label>
        );
      },
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("subscription_list_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterState(filter_data);
    setFilterStateUpdated(filter_data);
    getMembersList(filter_data);
  }, [rowsPerPage, page]);

  if (isLoadingSection == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 mb-3">
            <h2>Subscriptions List</h2>
          </div>
          <div className="col-6 mb-3 text-end">
            <button
              className="small-contained-button"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        <FilteredChip
          data={filterStateUpdated}
          tempState={filterState}
          EMPTY_FILTER={EMPTY_FILTER}
          ALTER_FILTER={ALTER_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <div className="row mt-3">
          {isLoadingSection ? (
            <CircularProgress
              className={classes.loadingSection}
              color="primary"
            />
          ) : (
            <div className="col-12">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={subscriptionsData}
                className="card-with-background"
                custom_pagination={{
                  total_count: totalCount,
                  rows_per_page: rowsPerPage,
                  page: page,
                  handleChangePage: handleChangePage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
                custom_search={{
                  searchText: searchText,
                  setSearchText: setSearchText,
                  handleSubmit: searchFunction,
                }}
                pageCount={pageCount}
                totalPages={totalPages}
                handleChangePages={handleChangePages}
                pagePagination={true}
              />
            </div>
          )}
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <SubscriptionListFilter
            filterState={filterState}
            handleChange={handleChange}
            handleChangeOther={handleChangeOther}
            handleChangeDate={handleChangeDate}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
          />
        }
      />
      <CustomPopover
        isOpenPop={showPop}
        isClosePop={setshowPop}
        heigh="200px"
        title={value?.member_name ?? "detail"}
        componentToPassDown={<SubscriptionDetail value={value} />}
      />
    </>
  );
}
