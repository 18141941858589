import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { CircularProgress, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import ConsultantMemberList from "./ConsultantMemberList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import MemberGroupsList from "../Members/MembersGroupList";
import CalendarDetailGroupList from "../CalendarPods/GroupList";
import CalendarDetailMemberList from "../CalendarPods/MemberList";
import CalendarDetailRoomUserList from "../CalendarPods/RoomUserList";
import { podsDetailApi } from "src/DAL/Pods/pods";
import ZoomDetail from "../CalendarPods/ZoomDetail";
import { challengesDetailApi } from "src/DAL/challenges/challenges";
import ChallengeMemberList from "../CalendarPods/MemberListChallenge";
// import CalendarGroupProgrammeList from "./ProgrammeList";
// import CalendarMemberList from "./MemberList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function ChallengeDetail() {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const params = useParams();
  const [groupList, setGroupList] = React.useState([]);
  const [memberList, setMemberList] = React.useState([]);
  const [roomUsers, setRoomUsers] = React.useState([]);
  const type = new URLSearchParams(search).get("type");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.removeItem("pods_search_text");
  };

  const handleNavigateAddUser = () => {
    navigate(`/calendar-pods/add-room-user/${state.room_slug}`, {
      state: state,
    });
  };

  const getDetail = async () => {
    setIsLoading(true);
    const result = await challengesDetailApi(params.challenge_id);
    setGroupList(result.challenge_groups);
    setMemberList(result.challenge_members);
    setRoomUsers(result?.challenge?.challenge_users);
    setIsLoading(false);
  };
  React.useEffect(() => {
    setMemberList(state?.member);

    getDetail();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="">
      <div className="row">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() =>
              navigate(`/challenge?type=${type}`, {
                state: state,
              })
            }
          >
            <ArrowBackIcon />
          </IconButton>

          {state?.room_type == "automated" && (
            <button
              className="small-contained-button float-end mt-1"
              onClick={handleNavigateAddUser}
            >
              Add Room User
            </button>
          )}
        </div>
        <div className="col-12">
          <h2>{state?.title}</h2>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label=" Group List" {...a11yProps(0)} />
              <Tab label="Member List" {...a11yProps(1)} />
              <Tab label="Excluded Users" {...a11yProps(2)} />
              <Tab label="Challenge Users" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <CalendarDetailGroupList groupList={groupList} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChallengeMemberList memberList={memberList} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CalendarDetailRoomUserList
              roomUsers={roomUsers}
              type="excluded"
              checkbox={false}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <CalendarDetailRoomUserList
              roomUsers={roomUsers}
              type="all"
              checkbox={true}
            />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
