import * as React from "react";
import {
  challengesListingApi,
  DeleteChallengeApi,
} from "src/DAL/challenges/challenges";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useEffect } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useState } from "react";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useSnackbar } from "notistack";

import FreeCoinConfigurations from "./coinConfigurationFree";
import CoinConfigurations from "./CoinConfiguration";
import { COMMUNITY_LEVELS } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CoinConfigurationTabs() {
  const [value, setValue] = useState("1");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [challengeListVisioneering, setChallengeListVisioneering] = useState(
    []
  );
  const [challengeListMeditation, setChallengeListMeditation] = useState([]);
  const [challengeListLifeScript, setChallengeListLifeScript] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getChallengeLists = async () => {
    setIsLoading(true);
    const result = await challengesListingApi();
    if (result.code === 200) {
      setChallengeListVisioneering(result?.visioneering);
      setChallengeListMeditation(result?.mediation);
      setChallengeListLifeScript(result?.lifeScript);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/challenge/add-challenge`);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "title", label: "Title", alignRight: false },
    // {
    //   id: "table_avatar",
    //   label: "Image",
    //   alignRight: false,
    //   type: "thumbnail",
    // },
    {
      id: "challange_intro_video_url",
      label: "Intro Video Url",
      alignRight: false,
    },
    // { id: "challange_type", label: "Type", alignRight: false },
    { id: "community_level", label: "Community Level", alignRight: false },
    { id: "challenge_enable_coins_count", label: "Coins", alignRight: false },
    { id: "order", label: "Order", alignRight: false },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
    { id: "action", label: "Action", alignRight: false, type: "action" },
  ];

  const handleEdit = (value) => {
    navigate(`/challenge/edit-challenge/${value.challenge_slug}`, {
      state: value,
    });
  };

  const handleViewDetail = (value) => {
    navigate(`/challenge/challenge-detail/${value?.challenge_slug}`, {
      state: value,
    });
  };
  const handleCoinConfig = (value) => {
    navigate(`/challenge/coin-configuration/${value?.challenge_slug}`, {
      state: value,
    });
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
    // console.log("okokok");
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteChallengeApi(deleteDoc.challenge_slug);
    if (result.code === 200) {
      getChallengeLists();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Details",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleViewDetail,
    },
    {
      label: "Coins Configurtion",
      icon: "akar-icons:edit",
      handleClick: handleCoinConfig,
    },
  ];

  useEffect(() => {
    getChallengeLists();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABS_OPTIONS = [
    {
      title: "Free",
      component: <FreeCoinConfigurations level="free_coins_configuration" />,
    },
    // {
    //   title: "Dynamite",
    //   component: <CoinConfigurations level="dynamite_coins_configuration" />,
    // },
    // {
    //   title: "Ignite",
    //   component: <CoinConfigurations level="elite_coins_configuration" />,
    // },
    // {
    //   title: "Mastery",
    //   component: <CoinConfigurations level="mastery_coins_configuration" />,
    // },
  ];
  COMMUNITY_LEVELS.map((level) => {
    TABS_OPTIONS.push({
      title: level.title,
      component: (
        <CoinConfigurations level={`${level.name}_coins_configuration`} />
      ),
    });
  });

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="row mb-4">
        <div className="col-lg-8 col-sm-12">
          <h2>{"Coin Configuration"}</h2>
        </div>
        {/* <div className="col-lg-4 col-sm-12 text-end">
          <button onClick={handleNavigate} className="small-contained-button">
            {"Add Challenge"}
          </button>
        </div> */}
      </div>
      <MUICustomTabs
        data={TABS_OPTIONS}
        value={tabValue}
        handleChange={handleChange}
      />
    </>
  );
}
