import { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { CircularProgress, Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { makeStyles } from "@mui/styles";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { adminInitApi } from "src/DAL/Login/Login";
import { full_page_popup_routes } from "src/utils/constant";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 65;
const SIDEBAR_WIDTH = 280;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const FullPaeStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
}));

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const handle_add_google_analytic_key = (key) => {
  var head = document.head;
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `https://www.googletagmanager.com/gtag/js?id=${key}`;
  head.appendChild(script);
};
const handle_append_pixel_code_header = (scriptContent) => {
  try {
    // Create a temporary container to hold the script content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = scriptContent;

    // Loop through the child nodes of tempDiv and append them to the head
    Array.from(tempDiv.childNodes).forEach((node) => {
      if (node.nodeType === Node.COMMENT_NODE) {
        // If it's a comment node, append it as a comment
        const comment = document.createComment(node.nodeValue);
        document.head.appendChild(comment);
      } else if (
        node.nodeType === Node.ELEMENT_NODE &&
        node.tagName === "SCRIPT"
      ) {
        // If it's a script node, create a new script element
        const scriptTag = document.createElement("script");

        // Copy all attributes (like "async" and "src")
        Array.from(node.attributes).forEach((attr) =>
          scriptTag.setAttribute(attr.name, attr.value)
        );

        // If the script has inline JavaScript content, set it
        if (node.innerHTML) {
          scriptTag.innerHTML = node.innerHTML;
        }

        // Append the script to the head
        document.head.appendChild(scriptTag);
      }
    });
  } catch (error) {
    console.error("Failed to append pixel code:", error);
  }
};
const handle_append_pixel_code_body = (noscriptContent) => {
  try {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = noscriptContent;

    Array.from(tempDiv.childNodes)
      .reverse()
      .forEach((node) => {
        document.body.insertBefore(node, document.body.firstChild);
      });
  } catch (error) {
    console.error("Failed to append noscript content:", error);
  }
};
export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState(SIDEBAR_WIDTH);
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const {
    setNavlist,
    setWellcomeMessage,
    setDefaultTimeZone,
    setUserInfo,
    setWindowWidth,
    windowWidth,
  } = usePGIMode();
  const classes = useStyles();
  const { pathname } = useLocation();

  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }

  const adminInIt = async () => {
    const result = await adminInitApi();
    if (result.code === 200) {
      setNavlist(result.admin_menu_items);
      setWellcomeMessage(result.welcome_msg_for_admin);
      if (result.pixel_code_header) {
        handle_append_pixel_code_header(result.pixel_code_header);
      }
      if (result.pixel_code_body) {
        handle_append_pixel_code_body(result.pixel_code_body);
      }
      if (result.analytic_google_code) {
        handle_add_google_analytic_key(result.analytic_google_code);
      }
      setDefaultTimeZone(result.time_zone);
      setUserInfo(result.admin_user);
      setisLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setisLoading(false);
    }
  };

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
  };

  function handleScroll() {
    const isScrolled =
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop) > window.innerHeight;
    setShowScrollArrow(isScrolled);
  }

  const handlePermanentDrawer = () => {
    if (drawerWidth) {
      setDrawerWidth(0);
    } else {
      setDrawerWidth(SIDEBAR_WIDTH);
    }
  };

  useEffect(() => {
    adminInIt();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setWindowWidth(!windowWidth);
  }, [drawerWidth]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let is_calendar_page = full_page_popup_routes.some((route) =>
    pathname.startsWith(route)
  );

  return (
    <RootStyle>
      {is_calendar_page ? (
        <>
          <FullPaeStyle>
            <Outlet />
          </FullPaeStyle>
        </>
      ) : (
        <>
          <DashboardNavbar
            onOpenSidebar={() => setOpen(true)}
            drawerWidth={drawerWidth}
            handlePermanentDrawer={handlePermanentDrawer}
          />
          <DashboardSidebar
            drawerWidth={drawerWidth}
            isOpenSidebar={open}
            SIDEBAR_WIDTH={SIDEBAR_WIDTH}
            onCloseSidebar={() => setOpen(false)}
          />
          <MainStyle>
            <Outlet />
            <div
              className="scroll-top-community"
              style={{
                bottom: showScrollArrow ? "50px" : "-80px",
              }}
              onClick={handleScrollTop}
            >
              <Tooltip title="Scroll to top">
                <div>
                  <ArrowUpwardIcon />
                </div>
              </Tooltip>
            </div>
          </MainStyle>
        </>
      )}
    </RootStyle>
  );
}
