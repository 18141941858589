import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import {
  delete_source_session_recording_api,
  source_session_recording_listing_api,
} from "src/DAL/SourceSessions/SourceSessionRecordings";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import SourceSessionFilter from "./SourceSessionFilter";
import FilteredChip from "src/components/FilteredChip";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  {
    id: "table_avatar",
    label: "Image",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "source_session_title", label: "Source Session", alignRight: false },
  { id: "order", label: "Order", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SourceSessionsRecordings() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recordingData, setRecordingData] = useState([]);
  const classes = useStyles();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const EMPTY_FILTER = {
    source_session: [],
  };

  const [selectedSession, setSelectedSession] = useState(EMPTY_FILTER);
  const [updateSessionsData, setUpdateSessionsData] = useState(EMPTY_FILTER);

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setPageCount(1);
  };

  const getRecordingListing = async (data) => {
    setIsLoading(true);
    let postData = {
      ...data,
    };

    if (postData.source_session) {
      postData.source_session = data.source_session.map((item) => {
        return item._id;
      });
    }

    const result = await source_session_recording_listing_api(
      postData,
      searchText,
      page,
      rowsPerPage
    );
    if (result.code == 200) {
      setTotalCount(result?.total_source_session_count);
      setTotalPages(result?.total_pages);
      const recordings = result?.source_session_recording.map((recording) => {
        return {
          ...recording,
          source_session_title: recording.source_session?.title
            ? recording.source_session?.title
            : "N/A",
          table_avatar: {
            src: s3baseUrl + recording.session_recording_images.thumbnail_2,
            alt: recording.title,
          },
        };
      });

      setRecordingData(recordings);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleEdit = (value) => {
    navigate(
      `/source-session-recordings/edit-source-session-recording/${value.source_session_recording_slug}`,
      {
        state: value,
      }
    );
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    handleCloseFilterDrawer();
    getRecordingListing(selectedSession);
    setUpdateSessionsData(selectedSession);
    localStorage.setItem(
      "source_sessions_data",
      JSON.stringify(selectedSession)
    );
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    getRecordingListing(data);
    setUpdateSessionsData(data);
    localStorage.setItem("source_sessions_data", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    setSelectedSession(EMPTY_FILTER);
    setUpdateSessionsData(EMPTY_FILTER);
    getRecordingListing(EMPTY_FILTER);
    handleCloseFilterDrawer();
    localStorage.removeItem("source_sessions_data");
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_source_session_recording_api(
      deleteDoc.source_session_recording_slug
    );
    if (result.code === 200) {
      setRecordingData((prev) => {
        return prev.filter(
          (data) =>
            data.source_session_recording_slug !==
            deleteDoc.source_session_recording_slug
        );
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("source_sessions_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setSelectedSession(filter_data);
    setUpdateSessionsData(filter_data);
    getRecordingListing(filter_data);
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 mb-3">
            <h2>Source Sessions Recording</h2>
          </div>
          <div className="col-md-6 mb-3 text-end">
            <button
              className="small-contained-button mt-1"
              onClick={() =>
                navigate(
                  `/source-session-recordings/add-source-session-recording`
                )
              }
            >
              Add Source Sessions Recording
            </button>
            <button
              className="small-contained-button ms-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        <FilteredChip
          data={updateSessionsData}
          tempState={selectedSession}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          data={recordingData}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <SourceSessionFilter
            selectedSession={selectedSession}
            setSelectedSession={setSelectedSession}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
