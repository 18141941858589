import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { Button, Divider, IconButton, Tooltip } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import AvatarGroup from "@mui/material/AvatarGroup";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import { Popover } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import { useSnackbar } from "notistack";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopoverSales from "./MenuOption/CustomPopoverSectionSales";
import CustomPopoverSectionForSaleSection from "./MenuOption/CustomPopoverSectionForSaleSection";
import CustomPopover from "./MenuOption/CustomPopover";
import { clickAbleUrl } from "src/utils/constant";
import moment from "moment";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "./ModalPopover/Confirmation";
import {
  addAssociateApi,
  deleteSectionLeadApi,
  saleLeadDateChange,
  saleLeadMarkCompleteApi,
  saleLeadMoveToOtherSectionApi,
  saleSectionMoveApi,
} from "src/DAL/SaleSectionLeads/saleSectionLeadsApi";
import RecordNotFound from "./RecordNotFound";
import LeadChangePopover from "src/pages/SaleSection/CustomPopover";
import CustomPopoverForLeads from "./MenuOption/CustomPopoverForLeads";
import CustomPopoverAssociates from "src/pages/SaleSection/CustomPopoverAssociates";
import { consultantActiveListing } from "src/DAL/consultant/consultant";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CustomMuiDatePicker from "./CustomMuiDatePicker";
import Popper from "@mui/material/Popper";
// import { addOrUpdateQuestionsAnswers } from "src/DAL/DelegatePrograms/delegateProgramApi";
const useStyles = makeStyles((theme) => ({
  dense: {
    marginTop: theme.spacing(0),
  },
}));

const MessageeForAllSales = ({
  replies,
  data,
  dataReload,
  lastBookElementRef,
  loadMoreData,
  isLoadingMore,
  totlePages,
  sectionInfo,
  setPageNumber,
  SectionCount,
  pageNumber,
  goalStatementCompletedStatus,
  goalStatementCompletedDate,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [responceDrawer, setResponceDrawer] = useState(false);
  const [repliesDrawer, setRepliesDrawer] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [isAllAnswered, setIsAllAnswered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questionMessages, setQuestionMessages] = useState([]);
  const [comments, setComments] = useState([]);
  const [value, setValue] = useState(new Date());
  const [usersToShow, setusersToShow] = useState(3);
  const [question, setQuestion] = useState("");
  const [deleteLeadId, setDeleteLeadId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openSectionOrder, setOpenSectionOrder] = useState(false);
  const [filteredSection, setFilteredSection] = useState();
  const [associateList, setAssociateList] = useState([]);
  const [selectedLead, setSelectedLead] = useState();
  const [associateType, setAssociateType] = useState("");
  const [opendate, setOpendate] = React.useState(false);
  const [toShow, settoShow] = useState(false);
  const handleOpen = () => setOpendate(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [LeadId, setLeadId] = useState("");
  const handleClick = (event, lead) => {
    setLeadId(lead._id);
    setAnchorEl(event.currentTarget);
  };

  const handleDateChange = (date) => {
    const dateData = moment(date).format("YYYY-DD-MM ");
    setSelectedDate(dateData);
    handleSubmitLeadDate(date);
  };
  const handleSubmitLeadDate = async (date) => {
    let postData = {
      lead_id: LeadId,
      due_date: moment(date).format("YYYY-MM-DD"),
    };

    const result = await saleLeadDateChange(postData);
    if (result.code == 200) {
      handleClose();
      enqueueSnackbar(result.message, { variant: "success" });
      dataReload("filters");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleClosedate = () => setOpendate(false);
  const [sectionData, setSectionData] = useState({
    section_id: "",
    order: "",
  });
  const [associateData, setAssociateData] = useState({
    lead_id: "",
    associate_type: "",
    associate_id: "",
    action_for: "",
  });
  const [moveLeadData, setMoveLeadData] = useState({
    lead_id: "",
    sales_section: "",
  });

  // popover states
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [mainAssociateAnchorEl, setMainAssociateAnchorEl] =
    React.useState(null);
  // associate popup work
  const handleClickAssociatePopup = (event, lead) => {
    setMainAssociateAnchorEl(event.currentTarget);
    setSelectedLead(lead);
    setAssociateType("main");
    setAssociateData((prevState) => ({
      ...prevState,
      ["lead_id"]: lead._id,
      ["associate_type"]: "main",
      ["action_for"]: "add",
    }));
  };
  const handleClickOtherAssociatePopup = (event, lead) => {
    setSelectedLead(lead);
    setMainAssociateAnchorEl(event.currentTarget);
    setAssociateType("other");
    setAssociateData((prevState) => ({
      ...prevState,
      ["lead_id"]: lead._id,
      ["associate_type"]: "other",
    }));
  };
  const handleCloseAssociatePopup = () => {
    setMainAssociateAnchorEl(null);
  };
  const handleChangeAssociate = async (value) => {
    if (selectedLead) {
      if (selectedLead?.other_associates.length > 0) {
        selectedLead?.other_associates.map((associate) => {
          if (associate._id == value._id) {
            setAssociateData((prevState) => ({
              ...prevState,
              ["associate_id"]: value._id,
              ["action_for"]: "remove",
            }));
            handleCloseAssociatePopup();
          } else {
            setAssociateData((prevState) => ({
              ...prevState,
              ["associate_id"]: value._id,
              ["action_for"]: "add",
            }));
            handleCloseAssociatePopup();
          }
        });
      } else {
        setAssociateData((prevState) => ({
          ...prevState,
          ["associate_id"]: value._id,
          ["action_for"]: "add",
        }));
        handleCloseAssociatePopup();
      }
    } else {
      setAssociateData((prevState) => ({
        ...prevState,
        ["associate_id"]: value._id,
      }));
      handleCloseAssociatePopup();
    }

    // setAssociateData((prevState) => ({
    //   ...prevState,
    //   ["associate_id"]: value._id,
    // }));
    // handleCloseAssociatePopup();
  };

  const handleSubmitMainAssociate = async () => {
    const result = await addAssociateApi(associateData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setAssociateData({
        lead_id: "",
        associate_type: "",
        associate_id: "",
        action_for: "",
      });
      setSelectedLead("");
      dataReload("filters");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  // associate popup work end

  const handleClickLeadChangePopup = (event, data) => {
    setAnchorEl(event.currentTarget);

    let filterData = questionMessages.filter(
      (value) => value._id !== data.sales_section
    );
    setFilteredSection(filterData);
    setMoveLeadData((prevState) => ({
      ...prevState,
      ["lead_id"]: data._id,
    }));
  };
  const handleSubmitLeadChange = async () => {
    const result = await saleLeadMoveToOtherSectionApi(moveLeadData);
    if (result.code == 200) {
      handleClose();
      enqueueSnackbar(result.message, { variant: "success" });
      dataReload("filters");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeSection = async (id) => {
    setMoveLeadData((prevState) => ({
      ...prevState,
      ["sales_section"]: id,
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openAssociate = Boolean(mainAssociateAnchorEl);
  const idAssociate = openAssociate ? "simple-popover" : undefined;

  // const { contentSettingData, userInfo, adminTimeZone } = useContentSetting();

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handelAssociateList = async () => {
    const result = await consultantActiveListing();
    if (result.code == 200) {
      setAssociateList(result.consultant);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleOpenDrawer = () => {
    setResponceDrawer(true);
  };

  const handleCloseDrawer = () => {
    setResponceDrawer(false);
  };

  const handleOpenRepliesDrawer = () => {
    setRepliesDrawer(true);
  };
  const handleChangeLeadCheckBox = async (e, value) => {
    let postData = {
      lead_id: value._id,
      completed_status: !value.completed_status,
    };

    const result = await saleLeadMarkCompleteApi(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      dataReload("filters");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleCloseRepliesDrawer = () => {
    setRepliesDrawer(false);
  };
  const handleSectionChangeUpConfirmation = (value) => {
    if (value.order == 1) {
      enqueueSnackbar("You are already on first order !", { variant: "info" });
      return;
    }
    setSectionData((prevState) => ({
      ...prevState,
      ["section_id"]: value._id,
      ["order"]: value.order - 1,
    }));
    setOpenSectionOrder(true);
  };

  const handleSectionChangeDownConfirmation = (value) => {
    if (value.order >= data.length) {
      enqueueSnackbar("You are already on Maximum order !", {
        variant: "info",
      });
      return;
    }
    setSectionData((prevState) => ({
      ...prevState,
      ["section_id"]: value._id,
      ["order"]: value.order + 1,
    }));
    setOpenSectionOrder(true);
  };
  const handleAgreeSectionChange = async (value) => {
    const result = await saleSectionMoveApi(sectionData);
    if (result.code == 200) {
      setOpenSectionOrder(false);
      enqueueSnackbar(result.message, { variant: "success" });
      dataReload("filters");
    } else {
      setOpenSectionOrder(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleOpenDelete = (value) => {
    setDeleteLeadId(value._id);
    setOpenDelete(true);
  };

  const handleAgreeDelete = async () => {
    setOpenDelete(false);

    const result = await deleteSectionLeadApi(deleteLeadId);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      dataReload("filters");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const expandArea = (index, sectionData) => {
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    if (temp_element.isExpanded === false) {
      temp_element.isExpanded = true;
    } else {
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
    // console.log(sectionData, "sectionData");
    // dataReload(sectionData._id, "true");
  };

  const handleSubmit = async (e, index) => {
    e.preventDefault();
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
  ];
  const MENU_OPTIONS_MOVE = [
    {
      label: "Move Up",
      icon: "mdi:arrow-up",
      handleClick: handleSectionChangeUpConfirmation,
    },
    {
      label: "Move Down",
      icon: "ic:outline-arrow-downward",
      handleClick: handleSectionChangeDownConfirmation,
    },
  ];
  const checkIfAllAnswered = (data) => {
    data.map((value, index) => {
      if (value.answer === "") {
        setIsAllAnswered(false);
        return;
      } else {
        setIsAllAnswered(true);
      }
    });
  };
  const handleshow = (lead, toShow, i) => {
    const data = [...updatedQuestionMessages];
    data[i].showAvtar = !toShow;
    updatedQuestionMessages = data;
    setQuestionMessages(updatedQuestionMessages);
    settoShow(!toShow);
    // if (toShow) {
    //   setusersToShow(questionMessages[i].other_associates.length, i);
    // } else setusersToShow(3);
  };
  useEffect(() => {
    let questionsArray = [];
    data.map((value, index) => {
      if (index === 0) {
        questionsArray.push({
          ...value,
          isExpanded: true,
          isLoading: false,
        });
      } else {
        questionsArray.push({
          ...value,
          isExpanded: false,
          isLoading: false,
        });
      }
      setQuestionMessages(questionsArray);
    });
    checkIfAllAnswered(data);
  }, [data]);

  useEffect(() => {
    if (moveLeadData.sales_section) {
      handleSubmitLeadChange();
    }
  }, [moveLeadData.sales_section]);
  useEffect(() => {
    if (associateData.associate_id) {
      handleSubmitMainAssociate();
    }
  }, [associateData.associate_id]);
  let updatedQuestionMessages = questionMessages.map((item) => ({
    ...item,
    showAvtar: false,
  }));

  useEffect(() => {
    handelAssociateList();
  }, []);

  return (
    <>
      <CustomConfirmation
        open={openSectionOrder}
        setOpen={setOpenSectionOrder}
        title={"Are you sure you want to change section order ?"}
        handleAgree={handleAgreeSectionChange}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete lead?"}
        handleAgree={handleAgreeDelete}
      />
      <LeadChangePopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClickLeadChangePopup={handleClickLeadChangePopup}
        handleClose={handleClose}
        id={id}
        handleChangeSection={handleChangeSection}
        data={filteredSection}
        open={open}
      />
      <CustomPopoverAssociates
        anchorEl={mainAssociateAnchorEl}
        setAnchorEl={setMainAssociateAnchorEl}
        handleClickLeadChangePopup={handleClickAssociatePopup}
        handleClose={handleCloseAssociatePopup}
        handleChangeSection={handleChangeAssociate}
        id={idAssociate}
        selectedData={selectedLead}
        data={associateList}
        associateType={associateType}
        open={openAssociate}
      />

      {questionMessages?.length > 0 ? (
        <div className="card mb-2 ">
          <form
            onSubmit={(e) => {
              handleSubmit(e, index);
            }}
          >
            <Accordion
              expanded={questionMessages[0].isExpanded}
              className="question-background"
            >
              <div className="d-flex">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => expandArea(0, questionMessages[0])}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="svg-color text-white"
                  sx={{ width: "100%" }}
                >
                  <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
                    <Typography>
                      {sectionInfo.title
                        ? sectionInfo.title
                        : sectionInfo[0].title
                        ? sectionInfo[0].title
                        : ""}
                    </Typography>
                  </div>
                  <span class="def-sale-section-count">
                    {SectionCount ? SectionCount : ""}
                  </span>
                </AccordionSummary>
                <div className="d-flex manu-place">
                  {/* <CustomPopover
                    menu={MENU_OPTIONS_MOVE}
                    data={questionMessages[0]}
                  /> */}
                </div>
                {/* <div className="d-flex manu-place">
                        <span class="def-sale-section-count">
                          {message?.leads?.length}
                        </span>
                      </div> */}
              </div>

              <div className="">
                <AccordionDetails>
                  {questionMessages?.length > 0
                    ? questionMessages.map((lead, i) => {
                        return (
                          <>
                            <div className="card shadow mb-3">
                              <div className="d-flex set-optoin top-pedding">
                                <div>
                                  <Checkbox
                                    {...label}
                                    onChange={(e) =>
                                      handleChangeLeadCheckBox(e, lead)
                                    }
                                    checked={
                                      lead.completed_status == true
                                        ? "checked"
                                        : ""
                                    }
                                  />
                                  <span className="span-color">
                                    {lead.user_info.first_name +
                                      " " +
                                      lead.user_info.last_name +
                                      " "}
                                    {
                                      <a
                                        target="_blank"
                                        href={`mailto:${lead.user_info.email}`}
                                        className="sale-email-link"
                                      >
                                        {lead.user_info.email}
                                      </a>
                                    }
                                  </span>
                                </div>
                                <CustomPopoverForLeads
                                  menu={MENU_OPTIONS}
                                  data={lead}
                                  popUp={handleClickLeadChangePopup}
                                />
                              </div>

                              <div className="date-Container">
                                <div className="d-flex avtar-setting">
                                  <div className="date-setting">
                                    <Tooltip title="Created at">
                                      <span className="span-color span-decoration ">
                                        {moment(lead.due_date).format(
                                          "DD MM YYYY"
                                        )}
                                      </span>
                                    </Tooltip>
                                    <Tooltip title="Due Date">
                                      <span
                                        className="span-color span-decoration email-color"
                                        onClick={(e) => handleClick(e, lead)}
                                      >
                                        select date
                                      </span>
                                    </Tooltip>
                                  </div>
                                  <div>
                                    <span>
                                      <Button
                                        // aria-describedby={id}
                                        variant="contained"
                                        onClick={(e) =>
                                          handleClickAssociatePopup(e, lead)
                                        }
                                        className="button-style-remove ps-0"
                                      >
                                        <Tooltip
                                          title={
                                            lead.main_associate
                                              ? lead.main_associate.first_name +
                                                " " +
                                                lead.main_associate.last_name
                                              : " no main associate"
                                          }
                                        >
                                          <Stack direction="row" spacing={2}>
                                            <Avatar
                                              alt="Travis Howard"
                                              // src="/static/images/avatar/2.jpg"
                                              src={
                                                lead.main_associate
                                                  ? s3baseUrl +
                                                    lead.main_associate.image
                                                      .thumbnail_1
                                                  : "/static/images/avatar/2.jpg"
                                              }
                                              sx={{
                                                width: "30px",
                                                height: "30px",
                                              }}
                                            />
                                          </Stack>
                                        </Tooltip>
                                      </Button>
                                    </span>
                                  </div>
                                </div>
                                <div className="folder-avtar-setting-container">
                                  <Stack direction="row" spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                      <span
                                        className="d-flex"
                                        style={{ marginTop: "0.3rem" }}
                                      >
                                        <Tooltip title="Update Other Associates">
                                          <Button
                                            // aria-describedby={id}
                                            variant="contained"
                                            onClick={(e) =>
                                              handleClickOtherAssociatePopup(
                                                e,
                                                lead
                                              )
                                            }
                                            className="button-style-remove ps-0 pt-0"
                                          >
                                            {
                                              <Avatar
                                                style={{
                                                  width: "31px",
                                                  height: "31px",
                                                  fontSize: "27px",
                                                  paddingLeft: "1px",
                                                }}
                                              >
                                                +
                                              </Avatar>
                                            }
                                          </Button>
                                        </Tooltip>
                                        <AvatarGroup
                                          onClick={(e) =>
                                            handleshow(lead, toShow, i)
                                          }
                                          max={
                                            lead.showAvtar
                                              ? lead.other_associates.length
                                              : usersToShow
                                          }
                                          sx={{
                                            "& .MuiAvatar-root": {
                                              width: 28,
                                              height: 28,
                                              fontSize: 15,
                                              cursor: "pointer",
                                            },
                                          }}
                                        >
                                          {lead?.other_associates.length > 0 ? (
                                            lead?.other_associates.map(
                                              (associate, associate_index) => {
                                                return (
                                                  <span>
                                                    {associate.first_name && (
                                                      <Tooltip
                                                        title={
                                                          associate.first_name +
                                                          " " +
                                                          associate.last_name
                                                        }
                                                      >
                                                        <Avatar
                                                          alt={
                                                            questionMessages[0]
                                                              ?.title
                                                          }
                                                          src={
                                                            associate?.image
                                                              ?.thumbnail_1
                                                              ? s3baseUrl +
                                                                associate?.image
                                                                  ?.thumbnail_1
                                                              : "/static/images/avatar/2.jpg"
                                                          }
                                                          sx={{
                                                            width: "31px",
                                                            height: "31px",
                                                          }}
                                                        />
                                                      </Tooltip>
                                                    )}
                                                  </span>
                                                );
                                              }
                                            )
                                          ) : (
                                            <span className="">
                                              <Button
                                                // aria-describedby={id}
                                                variant="contained"
                                                onClick={(e) =>
                                                  handleClickOtherAssociatePopup(
                                                    e,
                                                    lead
                                                  )
                                                }
                                                className="button-style-remove ps-0 pt-0"
                                              >
                                                {/* <Avatar>+</Avatar> */}
                                              </Button>
                                            </span>
                                          )}
                                        </AvatarGroup>
                                      </span>
                                    </Stack>

                                    {/* <Tooltip title="Select Sale Type">
                                      <Avatar
                                        sx={{
                                          width: "25px",
                                          height: "25px",
                                          // fontSize: "10px",
                                        }}
                                      >
                                        <FolderIcon
                                          sx={{
                                            width: "15px",
                                            height: "15px",
                                          }}
                                        />
                                      </Avatar>
                                    </Tooltip> */}
                                  </Stack>
                                  <div>
                                    <Tooltip title="Product Name">
                                      <span className="span-color small-contained-button">
                                        {lead?.sale_page_id?.meta_title}
                                      </span>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : ""}
                  {totlePages > pageNumber ? (
                    <div className="col-12 text-center favourite-buttton-box">
                      <button
                        ref={lastBookElementRef}
                        className="small-contained-button mt-3"
                        onClick={loadMoreData}
                        id="load-more-feed"
                      >
                        {isLoadingMore ? "Loading..." : "Loading..."}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </AccordionDetails>
              </div>
            </Accordion>
          </form>
        </div>
      ) : (
        <div className="mt-5">
          <RecordNotFound title="Leads" />
        </div>
      )}
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        style={{
          boxShadow: "none",
          boxShadow: "none",
        }}
      >
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            style={{
              visibility: "hidden",
              boxShadow: "none",
            }}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <DatePicker
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              value={selectedDate}
              style={{
                visibility: "hidden",
                boxShadow: "none",
              }}
              className="calender-styyle"
              onChange={handleDateChange}
              onClose={handleClose}
              renderInput={(params) => (
                <TextField
                  style={{ visibility: "hidden" }}
                  {...params}
                  variant="standard"
                />
              )}
            />
          </Popover>
        </Popper>
      </LocalizationProvider>
    </>
  );
};

export default MessageeForAllSales;
