import * as React from "react";
import { useState } from "react";
import { memberDetailApi } from "src/DAL/member/member";
import { useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import ChartJs from "src/components/charts/ChartToDo";
import { useEffect } from "react";

const ToDoTracker = ({ tabData }) => {
  const params = useParams();
  const [days, setDays] = useState("");
  const [earningList, setEarningList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [chartDynamicData, setChartDynamicData] = useState([]);
  const [tabsData, setTabsData] = useState([]);
  const [TargetToAchieve, setTargetToAchieve] = useState("");
  const [totalAmount, setTotalAmount] = useState("");

  const dateDifference = (startDate, lastDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(lastDate);
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    const NinetyDay = Difference_In_Days;
    return NinetyDay;
  };

  const dataForGraph = async () => {
    const result1 = await memberDetailApi(params.id);
    if (result1.code == 200) {
      setEarningList(result1.memberEarning);
      setTargetToAchieve(result1.target_amount);
      setTotalAmount(result1.total_earning);
      setStartDate(
        moment(result1.ninteen_day_vision_start_date).format("MM/DD/YYYY")
      );
      const start_date = moment(result1.ninteen_day_vision_start_date).format(
        "MM/DD/YYYY"
      );
      // chart dynamic value
      let counting = 0;
      const array_of_earning = [];
      const ChartPoints = [];
      const array_of_days = [];
      const reverseEarning = [];
      const demoXYValues = [];
      let LastDateObject = "";
      if (result1.memberEarning.length > 0) {
        LastDateObject = result1.memberEarning[0].date;
      }

      if (LastDateObject > startDate) {
        result1.memberEarning.reverse().forEach((earning) => {
          reverseEarning.push(earning.earning); // needs to reverse
          const date = moment(earning.date).format("MM/DD/YYYY");
          counting = counting + earning.earning;
          const dateCheck = dateDifference(start_date, date);

          // array_of_earning.push(earning.earning);
          array_of_days.push(dateDifference(start_date, date));
          array_of_earning.push(counting);
          ChartPoints.push(dateDifference(start_date, date));
          demoXYValues.push({
            x: dateCheck < 0 ? 1 : dateCheck + 1,
            y: counting,
          });
        });
      } else {
      }
      setChartDynamicData(demoXYValues);
      // chart dynamic value end
    }
  };

  const gat_tab_data = () => {
    setTabsData(tabData);
  };

  useEffect(() => {
    let days = [];
    for (let index = 1; index <= 90; index++) {
      days.push(index);
    }
    setDays(days);
  }, []);

  useEffect(() => {
    dataForGraph();
  }, []);
  useEffect(() => {
    gat_tab_data();
  }, [tabData]);

  return (
    <div className="row mt-3">
      <div className="col-12 mb-5">
        <ChartJs
          days={days}
          ChartDataList={earningList}
          chartDynamicData={chartDynamicData}
          TargetToAchieve={TargetToAchieve}
          totalAmount={totalAmount}
        />
      </div>
      <div className="col-12">
        <h2 className="mb-4">90 Day Formula</h2>
      </div>
      {tabsData?.map((question) => {
        return (
          <>
            <div className="col-12 ">
              <h4>
                <b>{question?.question_statement}</b>
              </h4>
            </div>
            <div className="col-12">
              <div
                dangerouslySetInnerHTML={{
                  __html: question?.description,
                }}
              ></div>
            </div>
            <div className="col-12 mt-1 mb-3">
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-flexible"
                  label={question?.answer == "" ? "Did Not Answer" : "Answer"}
                  multiline
                  rows={2}
                  name="Answer"
                  disabled
                  value={question?.answer}
                />
              </FormControl>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default ToDoTracker;
