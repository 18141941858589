// const DynamiteDiarySetting = () => {
//   return <>dynamite</>;
// };

// export default DynamiteDiarySetting;
import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress, InputAdornment } from "@mui/material";
import {
  clientSettingApi,
  UpdateDynamiteDiarySetting,
  UpdateMeditationSetting,
  UpdateMOnthalyReoprt,
  updateWheelOfLifeSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  _get_attitude_accessment_setting,
  _get_monthaly_setting,
} from "src/DAL/AttitudeAccessmentQuestions/AttitudeAccessmentSetting";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MonthlyReportSettings() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = React.useState({
    main_heading: "",
    intentions_heading: "",
    performance_heading: "",
    attitude_text: "",
    focus_text: "",
    desires_text: "",
    discipline_text: "",
    win_text: "",
  });

  const clientDetail = async () => {
    setIsLoading(true);
    const result = await _get_monthaly_setting();
    if (result.code == 200) {
      console.log(result, "kokoko");
      if (Object.keys(result.delegate_report_setting).length > 0) {
        setInputs(result.delegate_report_setting);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let data_obj = inputs;
    let delegate_report_setting = {
      delegate_report_setting: data_obj,
    };
    const result = await UpdateMOnthalyReoprt(delegate_report_setting);
    if (result.code === 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    clientDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-6">
          <h2>Monthly Report Setting</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div class="col-12 wheel-of-life-setting-section-heading">
            {/* <h2 className="mt-4">Assessment</h2> */}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Main Heading"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="main_heading"
              value={inputs?.main_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Intentions Heading"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="intentions_heading"
              value={inputs?.intentions_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-12 wheel-of-life-setting-section-heading">
            <h3 className="mt-4">Monthly Report Graph Settings </h3>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Performance Heading"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="performance_heading"
              value={inputs?.performance_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Attitude Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="attitude_text"
              value={inputs?.attitude_text}
              onChange={handleChange}
              InputProps={{
                style: {
                  fontSize: "13px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#f6bd4b",
                        borderRadius: "2px",
                      }}
                    ></div>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Focus Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="focus_text"
              value={inputs?.focus_text}
              onChange={handleChange}
              InputProps={{
                style: {
                  fontSize: "13px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#5ab834",
                        borderRadius: "2px",
                      }}
                    ></div>
                  </InputAdornment>
                ),
              }}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Desires Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="desires_text"
              value={inputs?.desires_text}
              onChange={handleChange}
              InputProps={{
                style: {
                  fontSize: "13px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#a020f0",
                        borderRadius: "2px",
                      }}
                    ></div>
                  </InputAdornment>
                ),
              }}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Discipline Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="discipline_text"
              value={inputs?.discipline_text}
              onChange={handleChange}
              InputProps={{
                style: {
                  fontSize: "13px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#00f",
                        borderRadius: "2px",
                      }}
                    ></div>
                  </InputAdornment>
                ),
              }}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Win Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="win_text"
              value={inputs?.win_text}
              onChange={handleChange}
              InputProps={{
                style: {
                  fontSize: "13px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#c70039",
                        borderRadius: "2px",
                      }}
                    ></div>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
