import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import moment from "moment";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  Autocomplete,
  Tooltip,
  OutlinedInput,
} from "@mui/material";
import { useEffect } from "react";
import { consultant_list_with_search } from "src/DAL/consultant/consultant";
import {
  add_book_call_type_api,
  book_call_type_detail_api,
  update_book_call_type_api,
} from "src/DAL/booking/book_call_types";
import faker from "faker";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateBookCallType() {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState("ADD");
  const [serchText, setSerchText] = useState("");
  const [delegatesList, setDelegatesList] = useState([]);
  const [selectedDelegate, setSelectedDelegate] = useState([]);
  const [selectedDelegateUpdates, setSelectedDelegateUpdates] = useState([]);
  const [weekName, setWeekName] = React.useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [value, setValue] = useState();
  const [data, setData] = useState({
    title: "",
    order: 0,
    status: true,
    image: {},
    short_description: "",
    detailed_description: "",
  });
  const [inputs, setInputs] = useState([
    {
      interval_id: faker.datatype.uuid(),
      // appointment_configration_name: "",
      slot_duration: "",
      slot_type: "",
      start_date: new Date(),
      end_date: new Date(),
      days: [],
      slots: [
        {
          slot_id: faker.datatype.uuid(),
          start_time: "00:00",
          end_time: "00:00",
        },
      ],
    },
  ]);
  console.log(selectedDelegate, "selectedDelegateselectedDelegate");
  const handleDelete = async () => {
    setOpenDelete(false);

    const list = [...inputs];
    list.splice(value, 1);

    setInputs(list);
  };

  // add child new object

  const handleFormatData = (state) => {
    setFormType("EDIT");
    setData(state);
    let delegates = [];
    if (state.master_user.length > 0) {
      state.master_user.map((item, index) => {
        if (item._id !== null) {
          delegates.push(item._id);
        }
      });
    }
    setSelectedDelegate(delegates);
    window.history.replaceState({}, document.title);
  };

  const getDelegatesList = async (serchText) => {
    const result = await consultant_list_with_search(serchText);
    if (result.code === 200) {
      setDelegatesList(result.consultant_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getBookCallTypeData = async () => {
    setIsLoading(true);
    let result = await book_call_type_detail_api(params.book_call_type_id);
    if (result.code == 200) {
      handleFormatData(result.call_type);
    } else {
      navigate(`/book-call-types`);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(inputs, "data");
    let delegateArray = [];
    if (selectedDelegate.length > 0) {
      selectedDelegate.map((item, index) => {
        delegateArray.push({ _id: item._id });
      });
    }
    console.log(delegateArray, "delegateArraydelegateArray");
    setIsLoading(true);

    let postData = {
      title: data.title,
      status: data.status,
      short_description: data.short_description,
      master_user: delegateArray,
    };

    if (delegateArray.length == 0) {
      enqueueSnackbar("please select atleast one master user for call ", {
        variant: "error",
      });
      setIsLoading(false);
    } else {
      const result =
        formType === "ADD"
          ? await add_book_call_type_api(postData)
          : await update_book_call_type_api(postData, params.book_call_type_id);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(`/book-call-types`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData((values) => ({ ...values, [name]: value }));
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };
  let finalArray = [];
  for (let i = 0; i < delegatesList.length; i++) {
    let count = 0;
    for (let j = 0; j < selectedDelegate.length; j++) {
      if (delegatesList[i]._id == selectedDelegate[j]._id) {
        count++;
      }
    }
    if (count == 0) {
      finalArray.push(delegatesList[i]);
    }
  }
  useEffect(() => {
    if (params && params.book_call_type_id) {
      if (state) {
        handleFormatData(state);
      } else {
        getBookCallTypeData();
      }
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDelegatesList(serchText);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [serchText]);
  React.useEffect(() => {
    getDelegatesList("");
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/book-call-types`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Call Type`}</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={data.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                required
                value={data.status}
                label="Status *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <Autocomplete
              multiple
              id="tags-outlined"
              options={finalArray}
              getOptionLabel={(option) =>
                option.first_name +
                " " +
                option.last_name +
                " (" +
                option.email +
                ")"
              }
              filterSelectedOptions
              value={selectedDelegate}
              onChange={(event, newValue) => {
                setSelectedDelegate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Master User For Call *"
                  placeholder="Master User For Call*"
                  onChange={handleSearch}
                />
              )}
            />
          </div>

          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                multiline
                rows={6}
                name="short_description"
                value={data.short_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>

          <div className="text-end mt-4 " id="fixedbutton">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this ?"}
        handleAgree={handleDelete}
      />
    </div>
  );
}
