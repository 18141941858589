import * as React from "react";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useEffect } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { questionare_detail_self_image_api } from "src/DAL/GeneralQuestions/GeneralQuestions";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "15%",
    marginBottom: "15%",
  },
}));

let tab_type = ["individual", "group", "all"];

export default function GeneralQuestionUsers() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const { question_id } = useParams();
  const [groups, setGroups] = useState([]);
  const [members, setMembers] = useState([]);
  const [questionMembers, setQuestionMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [questionDetail, setQuestionDetail] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [isTabLoading, setIsTabLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState("");
  //Changing tab values
  const handleChange = (event, newValue) => {
    localStorage.removeItem("general_search_text");
    setPage(0);
    setPageCount(1);
    setSearchText("");
    setTabValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //Changing tab values

  const DetailQuestion = async () => {
    setIsTabLoading(true);
    let type = tab_type[tabValue];
    let search_keyword =
      localStorage.getItem("general_search_text") == null
        ? searchText
        : localStorage.getItem("general_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const result = await questionare_detail_self_image_api(
      question_id,
      type,
      page,
      rowsPerPage,
      search_keyword
    );
    if (result.code === 200) {
      const member_list = [];
      result?.questionare_user?.map((member) => {
        if (member) {
          member_list.push({
            ...member,
            name: member?.first_name + " " + member?.last_name,
            table_avatar: {
              src: s3baseUrl + member?.profile_image,
              alt: member?.first_name,
            },
          });
        }
      });
      setQuestionMembers(member_list);
      setMembers(member_list);
      const groups_list = result?.questionare?.group.map((group) => {
        return {
          ...group._id,
          members_count: 1,
        };
      });
      setGroups(groups_list);

      setQuestionDetail(result.questionare);
      setTotalCount(result?.total_count);
      setTotalPages(result?.total_pages);
      setIsLoading(false);
      setIsTabLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsTabLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    DetailQuestion();
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Profile",
      alignRight: false,
      type: "thumbnail",
    },
    { id: "name", label: "Name", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
  ];

  const GROUP_TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "title", label: "Name", alignRight: false },
    { id: "members_count", label: "Members", alignRight: false },
  ];

  useEffect(() => {
    DetailQuestion();
  }, [tabValue, page, rowsPerPage]);

  useEffect(() => {
    localStorage.setItem("general_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("general_search_text", "");
    } else {
      localStorage.setItem("general_search_text", searchText);
    }
  }, [searchText]);

  const TABS_OPTIONS = [
    {
      title: "Member List",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={questionMembers}
          className="card-with-background mt-3"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      ),
    },
    {
      title: "Group List",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CustomMUITable
          TABLE_HEAD={GROUP_TABLE_HEAD}
          data={groups}
          className="card-with-background mt-3"
          pagePagination={true}
        />
      ),
    },
    {
      title: "All Members",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={members}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          className="card-with-background mt-3"
          pagePagination={true}
        />
      ),
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="col-12 text-center">
          <div
            dangerouslySetInnerHTML={{
              __html: questionDetail.question_statement,
            }}
          ></div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-4">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
