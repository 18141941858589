import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  add_transaction_internal_note,
  delete_transaction_internal_note,
  edit_transaction_internal_note,
} from "src/DAL/Transaction/transactionApi";
import CustomPopover from "src/components/MenuOption/CustomPopover";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import moment from "moment";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddInternalNotes({
  orderDeatail,
  getBookingStatusList,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({});
  const [oldImage, setOldImage] = useState();
  const [noteId, setNoteId] = useState("");
  const [formType, setFormType] = useState("ADD");
  const [openDelete, setOpenDelete] = useState(false);

  const [inputs, setInputs] = useState({
    internal_note: "",
    image: {},
  });

  const fileChangedHandler = (e) => {
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleAgreeDelete = (comment) => {
    setNoteId(comment);
    setOpenDelete(true);
  };

  const handleEdit = (comment) => {
    setInputs({
      ...inputs,
      ["internal_note"]: comment.internal_note,
    });
    if (comment?.image) {
      setOldImage(comment?.image.thumbnail_1);
    }
    setNoteId(comment);
    setFormType("EDIT");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("internal_note", inputs.internal_note);
    formData.append("transaction_id", params?.id);
    if (image.length > 0) {
      formData.append("image", inputs.image);
    }
    const result = await add_transaction_internal_note(formData);
    if (result.code === 200) {
      setInputs({
        ...inputs,
        ["internal_note"]: "",
        ["image"]: "",
      });
      getBookingStatusList();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("internal_note", inputs.internal_note);
    formData.append("internal_note_id", noteId?._id);
    if (image.length > 0) {
      formData.append("image", inputs.image);
    }
    const result = await edit_transaction_internal_note(params?.id, formData);
    if (result.code === 200) {
      setFormType("ADD");
      setInputs({
        ...inputs,
        ["internal_note"]: "",
        ["image"]: "",
      });
      getBookingStatusList();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await delete_transaction_internal_note(
      params?.id,
      noteId?._id
    );
    if (result.code === 200) {
      getBookingStatusList();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this comment?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid  bg-internalcard-color">
        <div className="row">
          <div className="col-12">
            <Card className="chat-main-card">
              <div className="mt-4 mb-4">
                {orderDeatail?.internal_note?.map((item) => {
                  const currentTime = moment(item?.note_date_time);
                  const oneHourAhead = currentTime.format("DD-MM-YYYY hh:mm A");
                  return (
                    <>
                      <div className="incoming-message w-100 ps-2 pe-3">
                        <div className="chat-message-body pt-1 pb-4 mt-2">
                          <div className="d-flex ">
                            <Avatar
                              alt={item?.action_user_info?.action_name}
                              src={
                                s3baseUrl +
                                item?.action_user_info?.profile_image
                              }
                            />
                            <div className="description w-100 custom-popover-box ps-2">
                              <div className="set-title-width d-flex justify-content-between w-100 pe-3">
                                <div>
                                  <p className="card-title  mb-0">
                                    {htmlDecode(
                                      item.action_user_info?.action_name
                                    )}
                                  </p>
                                  <span className="date-color pt-2 mb-0">
                                    {oneHourAhead}
                                  </span>
                                </div>
                              </div>
                              <p className="mt-2 pe-3">
                                {
                                  <div
                                    className="dashboard_description"
                                    dangerouslySetInnerHTML={{
                                      __html: item?.internal_note,
                                    }}
                                  ></div>
                                }
                              </p>
                              {item?.image && (
                                <span className="preview mt-3">
                                  <a
                                    href={s3baseUrl + item?.image?.thumbnail_1}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={s3baseUrl + item?.image?.thumbnail_1}
                                    />
                                  </a>
                                </span>
                              )}
                            </div>
                            <CustomPopover menu={MENU_OPTIONS} data={item} />
                          </div>
                        </div>
                        <hr />
                      </div>
                    </>
                  );
                })}
              </div>
              <form
                onSubmit={formType === "ADD" ? handleSubmit : handleSubmitEdit}
              >
                <div className="ms-2 mb-1 mt-4">
                  <h4>Enter Message *</h4>
                </div>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  name="internal_note"
                  editorHeight={320}
                />
                <div className="col-lg-12 col-md-12 col-sm-12 mt-4 mb-4">
                  <div className="row w-100 div-style ms-0 pt-0">
                    <div className="col-5">
                      <p className="">Upload Image</p>
                      <FormHelperText className="pt-0">
                        Image Size(1000 x 670) ("JPG", "JPEG", "PNG","WEBP")
                      </FormHelperText>
                    </div>
                    <div className="col-2">
                      {image.length > 0 ? (
                        <img src={image} height="50" />
                      ) : (
                        oldImage && (
                          <img src={s3baseUrl + oldImage} height="50" />
                        )
                      )}
                    </div>
                    <div className="col-5 text-end pt-2">
                      <label htmlFor="contained-button-file-logo">
                        <Input
                          accept="image/*"
                          id="contained-button-file-logo"
                          multiple
                          type="file"
                          name="logo"
                          hidden
                          onChange={fileChangedHandler}
                        />

                        <Button
                          className="small-contained-button"
                          startIcon={<FileUploadIcon />}
                          component="span"
                        >
                          Upload
                        </Button>
                      </label>
                    </div>
                  </div>
                  {inputs?.image?.name == "" ? (
                    ""
                  ) : (
                    <p className="text-secondary ms-3 ju">
                      {inputs?.image?.name}
                    </p>
                  )}
                </div>
                <div className="text-end mx-3 mt-4 mb-3">
                  <button className="small-contained-button">Submit</button>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
