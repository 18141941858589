import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import { show_proper_words } from "src/utils/constant";
import {
  deleteAssetApi,
  digitalAssetsListingApi,
} from "src/DAL/digitalAssetsApi/assets";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DigitalAssetsList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState({});
  const [openDelete, setOpenDelete] = useState(false);

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await deleteAssetApi(deleteDoc._id);
    if (result.code === 200) {
      setUserList((old) => old.filter((item) => item._id !== deleteDoc._id));
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const getConsultantListing = async () => {
    const result = await digitalAssetsListingApi();
    if (result.code === 200) {
      const data = result.assets.map((room) => {
        let category_title = "N/A";
        if (room.category) {
          category_title = room?.category?.title;
        }
        return { ...room, category_title, object: room };
      });
      setUserList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate(`/digital-assets/edit-asset-document/${value._id}`, {
      state: value,
    });
  };

  const handleViewDetail = (value) => {
    navigate(`/digital-assets/detail/${value._id}`, {
      state: value.object,
    });
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: " Title",
      renderData: (row) => {
        return (
          <Tooltip title={"View Detail"}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleViewDetail(row)}
            >
              {row.title}
            </span>
          </Tooltip>
        );
      },
    },
    { id: "category_title", label: "Category", alignRight: false },
    {
      id: "file_type",
      label: "Type",
      renderData: (row) => show_proper_words(row.file_type),
    },
    {
      id: "file_type",
      label: "View",
      renderData: (row) => {
        return (
          <div>
            <a
              href={
                row.file_type == "url"
                  ? row?.video_url
                  : row.file_type == "image"
                  ? s3baseUrl + row?.assets_images_url?.thumbnail_1
                  : row.file_type == "audio"
                  ? s3baseUrl + row?.assets_file_url
                  : row.file_type == "other_assets"
                  ? s3baseUrl + row?.assets_file_url
                  : ""
              }
              target="_blank"
              className="view"
            >
              view
            </a>
          </div>
        );
      },
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  useEffect(() => {
    getConsultantListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "iconoir:page-search",
      handleClick: handleViewDetail,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <h2>Digital Assets</h2>
          </div>
          <div className="col-6">
            <button
              onClick={() => navigate(`/digital-assets/add-asset-document`)}
              className="small-contained-button float-end mt-1"
            >
              Add Assets
            </button>
          </div>
        </div>
        <div className="mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            MENU_OPTIONS={MENU_OPTIONS}
            data={userList}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
