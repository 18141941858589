import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { updateCoinConfiguration } from "src/DAL/ClientSetting/ClientSetting";
import { _get_attitude_accessment_setting } from "src/DAL/AttitudeAccessmentQuestions/AttitudeAccessmentSetting";
import { makeStyles } from "@mui/styles";
import ManageNavItems from "./ManageNavItems";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const FreeCoinConfigurations = () => {
  const [configArray, setConfigArray] = useState([{ nav_items: [] }]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isReaload, setIsReaload] = useState(false);
  const classes = useStyles();

  const get_accessment_setting = async () => {
    setIsReaload(true);
    const result = await _get_attitude_accessment_setting();
    if (result.code == 200) {
      if (result.assessment_setting?.free_coins_configuration.length > 0) {
        const config_array =
          result.assessment_setting?.free_coins_configuration.map(
            (config, i) => ({ ...config, id: "coin" + i })
          );
        setConfigArray(config_array);
      }
      setIsReaload(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsReaload(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      free_coins_configuration: configArray,
    };
    const result = await updateCoinConfiguration(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_accessment_setting();
  }, []);

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <ManageNavItems
          selected_items={configArray[0].nav_items}
          index={0}
          config_array={configArray}
          setConfigArray={setConfigArray}
        />
        <div className="text-end mt-4">
          <button className="small-contained-button" onClick={handleSubmit}>
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default FreeCoinConfigurations;
