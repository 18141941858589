import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  IconButton,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { delete_dynamite_event_member } from "src/DAL/DynamiteEvents/EventMembers/EventMembers";
import {
  calendar_event_members_api,
  event_iteration_members_api,
} from "src/DAL/Events/events";
import {
  DeleteMultiMemberApi,
  memberAndGroupsListApi,
} from "src/DAL/member/member";
import PerformActionOn from "src/components/ModalPopover/PerformActionOn";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  {
    id: "table_avatar",
    label: "Image",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "name", label: " Name", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CalendarEventMembers() {
  const navigate = useNavigate();
  const { iteration_id, event_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selected, setSelected] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [membersData, setMembersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [pageCount, setPageCount] = useState(1);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [openMultiDelete, setOpenMultiDelete] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [eventUsers, setEventUsers] = useState([]);
  const [member, setMember] = useState([]);
  const [serchText, setSerchText] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const getMembersList = async () => {
    setIsLoading(true);
    let postData = {
      iteration_id: iteration_id,
      event_slug: event_slug,
    };
    const result = await calendar_event_members_api(
      page,
      rowsPerPage,
      searchText,
      postData
    );
    if (result.code == 200) {
      const members = result.member_users?.map((member, index) => {
        return {
          ...member,
          name:
            member.first_name +
            " " +
            member.last_name +
            " (" +
            member.email +
            ")",
          table_avatar: {
            src: s3baseUrl + member.profile_image,
            alt: member.first_name,
          },
        };
      });

      setMembersData(members);
      setEventUsers(result.users);
      setTotalCount(result.total_member_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const getGroupsAndMembers = async () => {
    const result = await memberAndGroupsListApi(serchText);
    if (result.code === 200) {
      let unique_array = [];
      if (result.members.length > 0) {
        result.members.map((member) => {
          let find = eventUsers.find((user) => user == member._id);
          if (!find) {
            unique_array.push(member);
          }
        });
      }
      setPersonName(unique_array);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMembersList();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleAgreeMultiDelete = () => {
    setOpenMultiDelete(true);
  };

  const callActionApi = async (formData) => {
    const result = await event_iteration_members_api(formData);
    if (result.code === 200) {
      setOpenAction(false);
      getMembersList();
      setMember([]);
      setSelected([]);
      setSearchText("");
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDelete = async (value) => {
    setOpenDelete(false);
    setIsLoading(true);
    const formData = {
      event_slug,
      iteration_id,
      members: [deleteDoc._id],
      action_type: "remove_user",
      update_type: value,
    };
    callActionApi(formData);
  };

  const hanldeSubmitForm = () => {
    setOpenAction(true);
  };

  const handleSubmit = (value) => {
    setIsLoading(true);
    let users_array = [];
    if (member.length > 0) {
      member.map((user) => {
        users_array.push(user._id);
      });
    }
    const formData = {
      event_slug,
      iteration_id,
      members: users_array,
      action_type: "add_user",
      update_type: value,
    };
    callActionApi(formData);
  };

  const handleMultipleDelete = async (value) => {
    setOpenMultiDelete(false);
    setIsLoading(true);
    let users_array = [];
    if (selected.length > 0) {
      selected.map((user) => {
        users_array.push(user._id);
      });
    }
    const formData = {
      event_slug,
      iteration_id,
      members: users_array,
      action_type: "remove_user",
      update_type: value,
    };
    callActionApi(formData);
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  useEffect(() => {
    getMembersList();
  }, [rowsPerPage, page]);

  useEffect(() => {
    getGroupsAndMembers();
  }, []);

  useEffect(() => {
    if (serchText.length > 2) {
      getGroupsAndMembers();
    }
  }, [serchText]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <PerformActionOn
        open={openAction}
        setOpen={setOpenAction}
        title={"Perform this action On?"}
        handleAgree={handleSubmit}
      />
      <PerformActionOn
        open={openDelete}
        setOpen={setOpenDelete}
        title={"From where you want to delete this user?"}
        handleAgree={handleDelete}
      />
      <PerformActionOn
        open={openMultiDelete}
        setOpen={setOpenMultiDelete}
        title={"From where you want to delete these users?"}
        handleAgree={handleMultipleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/calendar-events`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-lg-12 col-sm-12 mb-3">
            <h2>Events Members</h2>
          </div>
          <div className="col-lg-8 col-md-9 col-sm-12 mb-4">
            <Autocomplete
              multiple
              id="tags-outlined"
              options={personName}
              clearOnBlur={true}
              getOptionLabel={(option) =>
                option.first_name + " (" + option.email + ")"
              }
              filterSelectedOptions
              value={member}
              onChange={(event, newValue) => {
                setMember(newValue);
              }}
              required
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Members"
                  placeholder="Members"
                  onChange={handleSearch}
                  required
                />
              )}
            />
          </div>
          <div className="col-lg-4 col-md-3 col-sm-12 mb-4 text-end">
            <button class="small-contained-button" onClick={hanldeSubmitForm}>
              Add Member
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {selected.length > 0 && (
              <div class="text-end mt-4" id="fixedbutton">
                <button
                  class="small-delete-button"
                  onClick={handleAgreeMultiDelete}
                >
                  Delete
                </button>
              </div>
            )}
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              MENU_OPTIONS={MENU_OPTIONS}
              data={membersData}
              selected={selected}
              setSelected={setSelected}
              checkbox_selection={true}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
