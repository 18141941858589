import { invokeApi } from "../../bl_libs/invokeApi";

export const addTax = async (data) => {
  const requestObj = {
    path: `/api/tex_rate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateTaxApi = async (id, data) => {
  const requestObj = {
    path: `/api/tex_rate/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteTaxApi = async (id) => {
  const requestObj = {
    path: `/api/tex_rate/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_List_Tax_Api = async () => {
  const requestObj = {
    path: `/api/tex_rate`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const tax_rate_and_stripe_readers_api = async () => {
  const requestObj = {
    path: `/api/tex_rate/tax_rate_and_stripe_readers`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const teamList = async (data) => {
  const requestObj = {
    path: `api/team/team_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const teamDetail = async (data) => {
  const requestObj = {
    path: `api/team/team_detail.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editTeam = async (data) => {
  const requestObj = {
    path: `api/team/edit_team.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const teamDelete = async (data) => {
  const requestObj = {
    path: `api/team/delete_team.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const teamChangePassword = async (data) => {
  const requestObj = {
    path: `api/update_password/team_change_password_by_manager.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
