import * as React from "react";
import { useState } from "react";

import {
  Card,
  CircularProgress,
  createTheme,
  IconButton,
  Slider,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { makeStyles, ThemeProvider } from "@mui/styles";
import { useSnackbar } from "notistack";
import { project_name } from "../../config/config";
import {
  getNinetyDayQuestionApi,
  getNinetyDayQuestionApiother,
} from "../NinetyDayQuestion/NinetyDayQuestion";
import FormControl from "@mui/material/FormControl";
import ChartJs from "src/components/charts/ChartToDo";
import { LocalizationProvider } from "@mui/lab";
import { get_root_value } from "src/utils/domUtils";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const theme = createTheme({});
const DelegateToDoTrackerList = () => {
  const params = useParams();

  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [gallery, setGallery] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [chartDay, setChartDay] = useState([]);
  const [replies, setReplies] = React.useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteDoc, setDeleteDoc] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [days, setDays] = React.useState("");
  const [sliderDay, setSliderDay] = useState(1);
  const [vissionDate, setvissionDate] = useState(new Date());
  const [Tagated, setTagated] = useState("");
  const [date, setDate] = useState(new Date());
  const [earningList, setEarningList] = React.useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [chartDynamicData, setChartDynamicData] = useState([]);
  const [TargetToAchieve, setTargetToAchieve] = useState("");
  const [TargetAmount, setTargetAmount] = useState([]);
  const [UserName, setUserName] = useState("");
  const [lastEarningDate, setLastEarningdate] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const goalDetailother = async () => {
    setIsLoading(true);
    const result = await getNinetyDayQuestionApiother(params.id);
    if (result.code == 200) {
      setUserName(result?.consultant);
      const get_answer = (_id) => {
        return result?.ansewers.filter((answer) => answer._id == _id)[0]
          ?.answer_statement;
      };
      const data = result.delegate_question.map((question, index) => {
        return {
          ...question,
          answer: get_answer(question._id),
        };
      });

      setQuestions(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const marks = [
    {
      value: 1,
      label: "Day 1",
    },

    {
      value: 90,
      label: "Day 90",
    },
  ];

  const addDays = (date) => {
    var result = new Date(date);
    result.setDate(result.getDate() + 89);
    return moment(result).format("DD MMM YYYY");
  };
  const dataForGraph = async () => {
    setIsLoading(true);
    const result1 = await getNinetyDayQuestionApiother(params.id);
    if (result1.code == 200) {
      setIsLoading(false);
      const earningList = result1.DelegateEarning;
      const lastEarning = earningList[0];
      if (earningList.length === 0) {
        setSliderDay(1);
      }
      const targetedDate = moment(result1.ninteen_day_vision_start_date).format(
        "DD MMM YYYY"
      );
      setvissionDate(targetedDate);
      if (
        result1.ninteen_day_vision_start_date !== "" &&
        result1.start_date !== "Invalid date"
      ) {
        setDate(result1.ninteen_day_vision_start_date);
      }
      setTagated(result1.target_amount);
      setEarningList(result1.DelegateEarning);
      setTargetToAchieve(result1.target_amount);
      setTotalAmount(result1.total_earning);

      setStartDate(
        moment(result1.ninteen_day_vision_start_date).format("MM/DD/YYYY")
      );
      const start_date = moment(result1.ninteen_day_vision_start_date).format(
        "MM/DD/YYYY"
      );
      let counting = 0;
      const array_of_earning = [];
      const ChartPoints = [];
      const array_of_days = [];
      const reverseEarning = [];
      const demoXYValues = [];
      let LastDateObject = "";
      if (result1.DelegateEarning.length > 0) {
        LastDateObject = result1.DelegateEarning[0].date;
      }
      if (LastDateObject > startDate) {
        result1.DelegateEarning.reverse().forEach((earning) => {
          reverseEarning.push(earning.earning); // needs to reverse
          const date = moment(earning.date).format("MM/DD/YYYY");
          counting = counting + earning.earning;
          const dateCheck = dateDifference(start_date, date);

          // array_of_earning.push(earning.earning);
          array_of_days.push(dateDifference(start_date, date));
          array_of_earning.push(counting);
          ChartPoints.push(dateDifference(start_date, date));
          demoXYValues.push({
            x: dateCheck < 0 ? 1 : dateCheck + 1,
            y: counting,
          });
        });
      } else {
      }
      const target_amount_achieved = result1.target_amount;
      setChartDynamicData(demoXYValues);
      let days = [];
      for (let index = 1; index <= 90; index++) {
        days.push(index);
      }
      setChartDay(ChartPoints);

      const target_earning = [];
      target_earning.push({
        value: target_amount_achieved,
        label: `£ ${target_amount_achieved.toLocaleString()}`,
      });
      const lastDateEarning = moment(lastEarning?.date).format("MM/DD/YYYY");
      // console.log(
      //   lastEarning,
      //   "lastEarning",
      //   lastDateEarning,
      //   "lastDateEarning"
      // );
      setChartDynamicData(demoXYValues);
      setEarningList(array_of_earning);
      setDays(days);
      setTargetAmount(target_earning);
      setLastEarningdate(lastDateEarning);
      setStartDate(
        moment(result1.ninteen_day_vision_start_date).format("MM/DD/YYYY")
      );
    }
  };

  const dateDifference = (startDate, lastDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(lastDate);
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    const NinetyDay = Difference_In_Days;
    return NinetyDay;
  };

  let day = 0;
  day = dateDifference(startDate.toString(), lastEarningDate.toString());

  React.useEffect(() => {
    const day = dateDifference(
      startDate.toString(),
      lastEarningDate.toString()
    );
    if (day > 0) {
      setSliderDay(day + 1); //differnce between 2 dates is giving less count
    } else {
      setSliderDay(1);
    }
  }, [day, totalAmount]);
  React.useEffect(() => {
    goalDetailother();
    // goalDetail();
    let days = [];
    for (let index = 1; index <= 90; index++) {
      days.push(index);
    }
    setDays(days);
  }, []);
  React.useEffect(() => {
    dataForGraph();
  }, []);

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title: UserName
        ? UserName?.first_name +
          " " +
          UserName?.last_name +
          " (" +
          UserName.email +
          ")"
        : "To Do Tracker",
      active: true,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="mb-5">
          <h2>To Do Tracker</h2>
        </div>
        <div className="col-12 mb-5">
          <ChartJs
            days={days}
            // TargetToAchieve={state?.target_amount}
            // totalAmount={state?.total_earning}
            ChartDataList={earningList}
            chartDynamicData={chartDynamicData}
            TargetToAchieve={TargetToAchieve}
            totalAmount={totalAmount}
          />
        </div>
        <Card className="pt-5 pb-3 mb-5  programm-card h-100 secondary-background">
          <form>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <TextField
                  id="outlined-basic"
                  label="90 Day Starting Date"
                  variant="outlined"
                  fullWidth
                  name="question7"
                  // value={vissionDate==isNaN?" ":vissionDate}

                  // value={vissionDate ? vissionDate : ""}
                  value={vissionDate == "Invalid date" ? "" : vissionDate}
                  disabled
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                <TextField
                  id="outlined-basic"
                  label="Targeted Amount"
                  variant="outlined"
                  fullWidth
                  name="question7"
                  value={`£ ${Tagated}`}
                  disabled
                />
              </div>
              <div className="col-sm-12 col-md-8 col-lg-12 mt-4 text-center goal_achieved_white goal_achieved_upper_text">
                {vissionDate == "Invalid date" ? (
                  ""
                ) : (
                  <p>
                    DELEGATE 90 DAYS GOAL WILL BE ACHIEVED BY :&nbsp;
                    <span id="date_text_string">
                      {date ? addDays(date) : addDays(new Date())}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </form>
        </Card>
        <div className="col-lg-6 col-md-6 col-sm-12 ps-4  mb-5 range-slider">
          <ThemeProvider theme={theme}>
            <Slider
              className={`w-75 ms-4 mt-3 ${classes.slider}`}
              style={{ color: get_root_value("--portal-theme-primary") }}
              aria-label="Always visible"
              value={sliderDay}
              disabled
              step={1}
              min={1}
              max={90}
              marks={marks}
              valueLabelFormat={`Day ${sliderDay}`}
              valueLabelDisplay="on"
            />
          </ThemeProvider>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 ps-4  range-slider">
          <Slider
            className="w-75 ms-4 mt-3"
            style={{ color: get_root_value("--portal-theme-primary") }}
            value={Number(totalAmount)}
            classes={{
              valueLabel: classes.valueLabel,
            }}
            disabled
            step={1}
            min={0}
            max={TargetToAchieve}
            marks={TargetAmount}
            valueLabelFormat={`
              £ ${totalAmount.toLocaleString()}`}
            valueLabelDisplay="on"
          />
        </div>

        <div className="col-12">
          <h2 className="mb-4">90 Day Formula</h2>
        </div>
        {questions.map((question) => {
          return (
            <>
              <div className="col-12 ">
                <h4>
                  <b>{question.question_statement}</b>
                </h4>
              </div>
              {/* <div className="col-12">
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.question_placeholder,
                  }}
                ></div>
              </div> */}
              <div className="col-12 mt-1 mb-3">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-multiline-flexible"
                    label={question.answer == "" ? "Did Not Answer" : "Answer"}
                    multiline
                    name="Answer"
                    disabled
                    value={question.answer}
                  />
                </FormControl>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default DelegateToDoTrackerList;
