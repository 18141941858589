import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";
const CustomMuiDatePicker = ({ setOpen, open, handleOpen, handleClose }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="date"
        name="date"
        inputFormat="dd-MM-yyyy"
        // value={value}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        // onChange={setValue}
        // minDate={startDateMin}
        // onChange={(e) => {
        //   handleChangeDate("start_date", e);
        // }}
        format="YYYY-MM-DD"
        renderInput={(params) => (
          <TextField
            {...params}
            className="inputs-fields"
            required={true}
            fullWidth
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomMuiDatePicker;
