import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import {
  booking_status,
  update_booking_status_api,
} from "src/DAL/booking/book_status";

export default function AddOrUpdateStatus({
  formType,
  handleCloseDrawer,
  editData,
  getBookingStatusList,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    title: "",
    order: 0,
    status: true,
    text_color: "#ffffff",
    background_color: "#000",
    status_type: "general",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      title: data.title,
      status: data.status,
      text_color: data.text_color,
      background_color: data.background_color,
      status_type: data.status_type,
    };
    if (formType === "EDIT") {
      postData.order = data.order;
    }
    const result =
      formType === "ADD"
        ? await booking_status(postData)
        : await update_booking_status_api(postData, editData._id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleCloseDrawer();
      getBookingStatusList();
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (formType == "EDIT") {
      setData(editData);
    }
  }, []);

  return (
    <div className="container-fluid new-memories">
      <form onSubmit={handleSubmit}>
        <TextField
          className="mt-4"
          id="outlined-basic"
          label="Title"
          variant="outlined"
          name="title"
          value={data.title}
          required={true}
          onChange={(e) => handleChange(e)}
        />
        <FormControl variant="outlined" className="mt-3">
          <InputLabel id="demo-simple-select-outlined-label">
            Status *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={data.status}
            onChange={(e) => handleChange(e)}
            label="Status"
            name="status"
            required={true}
            className="svg-color"
          >
            <MenuItem value="true">Active</MenuItem>
            <MenuItem value="false">Inactive</MenuItem>
          </Select>
        </FormControl>{" "}
        <FormControl variant="outlined" className="mt-3">
          <InputLabel id="demo-simple-select-outlined-label">
            Booking Status Type *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={data.status_type}
            onChange={(e) => handleChange(e)}
            label="Booking Status Type"
            name="status_type"
            required={true}
            className="svg-color"
          >
            <MenuItem value="general">General</MenuItem>
            <MenuItem value="default">Default</MenuItem>
            <MenuItem value="cancel">Cancel</MenuItem>
          </Select>
        </FormControl>
        {formType === "EDIT" && (
          <TextField
            className="mt-3"
            id="outlined-basic"
            label="Order"
            variant="outlined"
            fullWidth
            required
            name="order"
            value={data.order}
            onChange={handleChange}
          />
        )}
        <TextField
          id="color"
          type="color"
          label="Text Color "
          name="text_color"
          required={true}
          className="mt-3"
          variant="outlined"
          value={data.text_color}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          id="color"
          type="color"
          label="Background Color "
          name="background_color"
          className="mt-3"
          required={true}
          variant="outlined"
          value={data.background_color}
          onChange={(e) => handleChange(e)}
        />
        <div className="text-end mt-3">
          <button className="small-contained-button">
            {formType == "ADD" ? (
              <>{isLoading ? "Submitting..." : "Submit"}</>
            ) : (
              <>{isLoading ? "Updating..." : "Update"}</>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
