import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useNavigate } from "react-router-dom";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  delete_quarter_api,
  quarter_duplicate_api,
  quarters_list_api,
} from "src/DAL/QuartersList/QuartersList";
import { dd_date_format } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function QuartersList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [quartersList, setQuartersList] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchText, setSearchText] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleNavigate = () => {
    navigate(`/quarters/add-quarter`);
  };

  const getQuartersList = async (limit, page, search_text) => {
    setIsLoading(true);
    const result = await quarters_list_api(page, limit, search_text);
    if (result.code == 200) {
      let data = result.quarter.map((item) => {
        return {
          ...item,
          startDate: dd_date_format(item.start_date),
        };
      });
      setQuartersList(data);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(`/quarters/edit-quarter/${value.quarter_slug}`, {
      state: value,
    });
  };

  const handleOpenDelete = (value) => {
    setSelectedObject(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_quarter_api(selectedObject.quarter_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setQuartersList((old) => {
        return old.filter((data) => data._id !== selectedObject._id);
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleQuestionsList = (row) => {
    navigate(`/quarters/quarter-questions?created_for_id=${row._id}`);
  };

  const handleQuestionsAnswers = (row) => {
    navigate(`/quarters/quarter-questions/answers?created_for_id=${row._id}`, {
      state: row,
    });
  };

  const handleAgreeDuplicate = (value) => {
    setSelectedObject(value);
    setOpenDuplicate(true);
  };

  const handleDuplicate = async () => {
    setOpenDuplicate(false);
    const result = await quarter_duplicate_api(selectedObject.quarter_slug);
    if (result.code === 200) {
      let new_quarter = result.quarter;
      new_quarter.startDate = dd_date_format(new_quarter.start_date);
      setQuartersList([...quartersList, new_quarter]);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  let child_menu_options = [
    {
      label: "Questions List",
      icon: "akar-icons:edit",
      handleClick: handleQuestionsList,
    },
    {
      label: "Questions Answers",
      icon: "akar-icons:edit",
      handleClick: handleQuestionsAnswers,
    },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
    {
      label: "Manage Questions",
      icon: "akar-icons:edit",
      child_options: child_menu_options,
    },
    {
      label: "Duplicate Quarter",
      icon: "akar-icons:edit",
      handleClick: handleAgreeDuplicate,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getQuartersList(rowsPerPage, page, searchText);
    localStorage.setItem(
      "quarter_filter",
      JSON.stringify({
        page: page,
        rowsPerPage: rowsPerPage,
        search_text: searchText,
      })
    );
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem(
      "quarter_filter",
      JSON.stringify({
        page: page,
        rowsPerPage: rowsPerPage,
        search_text: searchText,
      })
    );
  };

  useEffect(() => {
    let search_text = searchText;
    let find_filter = localStorage.getItem("quarter_filter");
    if (find_filter) {
      let filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        search_text = filter_data.search_text;
        setSearchText(filter_data.search_text);
      }
    }
    getQuartersList(rowsPerPage, page, search_text);
  }, [rowsPerPage, page]);

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title" },
    { id: "startDate", label: "Start Date" },
    { id: "order", label: "Order" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this quarter?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openDuplicate}
        setOpen={setOpenDuplicate}
        title={"Are you sure you want to duplicate this quarter?"}
        handleAgree={handleDuplicate}
      />
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-lg-8 col-sm-8 ">
            <h2>Quarters List</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Quarter
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={quartersList}
          className="card-with-background"
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
}
