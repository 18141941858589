import { invokeApi } from "../../bl_libs/invokeApi";

export const podsDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/room/detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const pods_detail_room_api = async (
  slug,
  page,
  limit,
  search_text,
  value
) => {
  const requestObj = {
    path: `/api/room/room_users_list/${slug}?page=${page}&limit=${limit}&search_text=${search_text}&type=${value}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Challenge_user_api = async (
  slug,
  page,
  limit,
  search_text,
  value
) => {
  const requestObj = {
    path: `/api/challenge/challenge_users/${slug}?page=${page}&limit=${limit}&search_text=${search_text}&type=${value}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const podsListingApi = async (data) => {
  const requestObj = {
    path: `/api/room`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const podsListingApiNew = async (
  page,
  rowsPerPage,
  search_keyword,
  type
) => {
  const requestObj = {
    path: `/api/room/room_list_with/type/search?page=${page}&limit=${rowsPerPage}&search=${search_keyword}&room_type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const pods_list_api_v1 = async (page, rowsPerPage, type, data) => {
  const requestObj = {
    path: `/api/room/room_list_with/type/search/v1?page=${page}&limit=${rowsPerPage}&room_type=${type}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddRoomUserApi = async (data) => {
  const requestObj = {
    path: `/api/room/add_room_user`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditRoomUserApi = async (data) => {
  const requestObj = {
    path: `/api/room/update_room_user`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddPodsApi = async (data) => {
  const requestObj = {
    path: `/api/room`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddPodsNewApi = async (data) => {
  const requestObj = {
    path: `/api/room/add_room_new`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditPodsApi = async (data, slug) => {
  const requestObj = {
    path: `/api/room/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const EditNewPodsApi = async (data, slug) => {
  const requestObj = {
    path: `/api/room/update_room_new/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeletePodsApi = async (slug) => {
  const requestObj = {
    path: `/api/room/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeletePodsRoomUserApi = async (roomSlug, id) => {
  const requestObj = {
    path: `/api/room/${roomSlug}/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
