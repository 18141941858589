import CircleIcon from "@mui/icons-material/Circle";
import { useEffect, useState } from "react";
import { Typography, Tooltip } from "@mui/material";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import { s3baseUrl } from "src/config/config";
import { convertCurrencyToSign, show_proper_words } from "src/utils/constant";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

export default function CommissionHistory({ data }) {
  const {
    userData,
    total_count,
    rows_per_page,
    page,
    handleChangePage,
    onRowsPerPageChange,
    searchText,
    setSearchText,
    handleSubmit,
    pageCount,
    totalPages,
    handleChangePages,
  } = data;
  const [userList, setUserList] = useState([]);

  const getConsultantListing = (value) => {
    if (value) {
      const data = value?.transaction?.map((transaction) => {
        let transaction_title = "N/A";
        let transaction_amount = "N/A";
        let commission_amount = "N/A";
        let transactionMode = "N/A";
        let transactionDate = "N/A";

        if (transaction.transaction_type == "payment_request") {
          transaction_title = transaction?.payment_request_info
            ? "Payment Request (" +
              transaction.payment_request_info?.request_title +
              " | "
            : "N/A";
        } else if (transaction.transaction_type == "payment_plan") {
          transaction_title = transaction.sale_page_info
            ? "Sale Page (" +
              transaction.sale_page_info?.sale_page_title +
              " | " +
              transaction.plan_info?.plan_title +
              " | "
            : "";
        }
        if (transaction.transaction_request_type === "onetime") {
          transaction_title = `${
            transaction_title ? transaction_title + "OneTime)" : "N/A"
          } `;
        } else {
          transaction_title = `${
            transaction_title ? transaction_title + "Recurring)" : "N/A"
          } `;
        }

        if (transaction.amount) {
          transaction_amount =
            convertCurrencyToSign(transaction?.currency) + transaction.amount;
        } else {
          transaction_amount = convertCurrencyToSign(transaction?.currency) + 0;
        }

        if (transaction.referral_commission) {
          commission_amount =
            convertCurrencyToSign(transaction?.currency) +
            transaction.referral_commission;
        } else {
          commission_amount = convertCurrencyToSign(transaction?.currency) + 0;
        }

        if (transaction.transaction_mode) {
          transactionMode = show_proper_words(transaction.transaction_mode);
        }

        if (transaction.transaction_date) {
          transactionDate = moment(transaction?.transaction_date).format(
            "DD-MM-YYYY"
          );
        }

        return {
          ...transaction,
          transaction_amount,
          transaction_title,
          transactionDate,
          transactionMode,
          commission_amount,
        };
      });
      setUserList(data);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "name",
      label: "Name",
      renderData: (row, i) => {
        return (
          <Tooltip
            key={i}
            title={
              row?.transaction_status === "succeeded" ? "Succeeded" : "Failed"
            }
            arrow
            className="anchor-style"
          >
            <Typography variant="subtitle2" noWrap>
              <CircleIcon
                className={`${
                  row?.transaction_status === "succeeded"
                    ? "Nurture_online"
                    : "support_notification_sidebar_page"
                }`}
              />
              {htmlDecode(
                row?.member_info
                  ? row?.member_info?.first_name +
                      " " +
                      row?.member_info?.last_name
                  : "N/A"
              )}
            </Typography>
          </Tooltip>
        );
      },
    },
    { id: "transaction_amount", label: "Amount" },
    { id: "transaction_title", label: "Transaction" },
    { id: "commission_amount", label: "Commission Amount" },
    { id: "transactionMode", label: "Transaction Mode" },
    {
      id: "agrement_pdf_url",
      label: "Agreement PDF",
      renderData: (row) => {
        return (
          <>
            {row.agrement_pdf_url ? (
              <a
                href={s3baseUrl + row.agrement_pdf_url}
                target="_blank"
                className="anchor-style"
              >
                Preview
              </a>
            ) : (
              <div>N/A</div>
            )}
          </>
        );
      },
    },
    { id: "transactionDate", label: "Date" },
  ];

  useEffect(() => {
    getConsultantListing(userData);
  }, [userData]);

  return (
    <div className="container-fluid">
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          className="card-with-background"
          custom_pagination={{
            total_count: total_count,
            rows_per_page: rows_per_page,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: onRowsPerPageChange,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: handleSubmit,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </div>
  );
}
