import { invokeApi } from "../../bl_libs/invokeApi";

export const _detail_page_pixel_events = async (id, type) => {
  const requestObj = {
    path: `/api/sale_page/detail_page_pixel_events/${id}${
      type ? "?type=plan" : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_page_pixel_events = async (data, id) => {
  const requestObj = {
    path: `/api/sale_page/update_page_pixel_events/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
