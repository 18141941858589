import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Tooltip from "@mui/material/Tooltip";
import "@fullcalendar/daygrid/main.css";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import {
  TIME_ZONE_CONVERSION,
  get_date_with_admin_time_zone,
  get_view_dates,
  handle_downgrade_month,
  handle_upgrade_month,
} from "src/utils/constant";
import DayCalendar from "./DayCalendar";
import WeekCalendar from "./WeekCalendar";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EventDetail from "./GeneralQuestions/EventDetail";
import DetailPopUpModel from "./DetailPopUpModel";
import { delete_event_api } from "src/DAL/Events/events";
import PerformActionOn from "../ModalPopover/PerformActionOn";
import { useSnackbar } from "notistack";
import { usePGIMode } from "src/Hooks/PGIModeContext";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function GeneralCalendar({
  eventList,
  setCurrentDate,
  showAddEvent,
  time_zone,
  currentDate,
  setIsLoading,
  isLoading,
  user_type,
  reloadList,
  calendar_title,
  hideArrowsAndButtons,
  created_for,
  notreload,
}) {
  const { windowWidth } = usePGIMode();
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const [eventDetailData, setEventDetailData] = useState({});
  const [popupState, setPopupState] = useState(false);
  const [selectedTool, setSelectedTool] = useState("month");
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const get_dates = (tool) => {
    if (tool == "month") {
      return get_view_dates(tool);
    } else {
      return {
        start_date: moment().startOf(tool).toDate(),
        end_date: moment().endOf(tool).toDate(),
      };
    }
  };

  const handleOpenDrawer = () => {
    let path = `/calendar-events`;
    if (created_for == "consultant_user") {
      path = `/delegate-events`;
    }
    navigate(`${path}/add-event`);
  };

  const handleOpenDrawerItration = () => {
    let path = `/calendar-events`;
    if (created_for == "consultant_user") {
      path = `/delegate-events`;
    }
    navigate(
      `${path}/edit-iteration/${eventDetailData.event_slug}?iteration_id=${eventDetailData._id}`
    );
  };

  const handleUpdate = () => {
    let path = `/calendar-events`;
    if (created_for == "consultant_user") {
      path = `/delegate-events`;
    }
    navigate(`${path}/edit-event/${eventDetailData.event_slug}`);
  };

  const handleNavigateListEvent = () => {
    navigate(
      `${
        created_for
          ? "/delegate-events/event-list"
          : "/calendar-events/event-list"
      }`
    );
  };

  const eventDetail = (event) => {
    setEventDetailData(event);
    setPopupState(true);
  };
  const handleAgreeDelete = () => {
    setPopupState(false);
    setOpenDelete(true);
  };

  const handleDelete = async (value) => {
    setOpenDelete(false);
    let postData = {
      event_slug: eventDetailData.event_slug,
      iteration_id: eventDetailData._id,
      update_type: value,
    };
    const result = await delete_event_api(postData);
    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      reloadList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_start_date_end_time = (date) => {
    return TIME_ZONE_CONVERSION(date, "YYYY-MM-DD HH:mm", time_zone, time_zone);
  };

  const get_event_date = (date) => {
    return get_date_with_admin_time_zone(date, "YYYY-MM-DD", time_zone);
  };

  const get_start_end_time = (date) => {
    return get_date_with_admin_time_zone(date, "HH:mm", time_zone);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <Tooltip title={eventInfo.event.title}>
        <div
          className="d-flex"
          onClick={() => eventDetail(eventInfo.event._def.extendedProps)}
        >
          <div
            className="event-title-dot"
            style={{
              backgroundColor: eventInfo.event._def.extendedProps.event_color,
            }}
          ></div>
          <div className="calendar-event-title"></div>
          <div className="calendar-event-title">
            <span className="start_time_for_popup">
              {eventInfo.event._def.extendedProps.start_time_for_popup}
            </span>
            &nbsp;&nbsp;
            {eventInfo.event.title.length > 15
              ? `${eventInfo.event.title.substring(0, 15)}...`
              : eventInfo.event.title}
          </div>
        </div>
      </Tooltip>
    );
  };

  const getEventListing = async (event) => {
    let all_events = [];
    if (event.length > 0) {
      all_events = event.map((event) => {
        return {
          ...event,
          title: event?.title,
          color: event?.color,
          event_color: event?.color,
          event_title: event?.title,
          status: event?.status,
          date: get_event_date(event?.start_date_time),
          start_date: moment(event?.start_date_time).format("YYYY-MM-DD"),
          end_date: moment(event?.end_date_time).format("YYYY-MM-DD"),
          start: get_start_date_end_time(event?.start_date_time),
          end: get_start_date_end_time(event?.end_date_time),
          start_time: get_start_end_time(event?.start_date_time),
          end_time: get_start_end_time(event?.end_date_time),
        };
      });
    }
    setEvents(all_events);
  };

  const TOOL_BUTTONS = [
    {
      text: "month",
    },
    {
      text: "week",
    },
    {
      text: "day",
    },
  ];

  const handleChangeTools = (tool) => {
    setCurrentMonth(new Date());
    let dates = get_dates(tool);
    setCurrentDate(dates);
    setSelectedTool(tool);
  };

  const getCenteredText = () => {
    let text = "";
    if (selectedTool == "month") {
      text = moment(currentMonth).format("MMM YYYY");
    } else if (selectedTool == "week") {
      let month = moment(currentDate.start_date).format("MMM");
      let start_date = moment(currentDate.start_date).format("DD");
      let end_date = moment(currentDate.end_date).format("DD");
      let year = moment(currentDate.start_date).format("YYYY");
      text = `${month} ${start_date} - ${end_date} ${year}`;
    } else {
      text = moment(currentDate.start_date).format("MMM DD, YYYY");
    }
    return text;
  };

  const hanldeChangeDates = (value) => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;
    let new_object = {};
    if (value == "next") {
      if (selectedTool == "month") {
        let data = handle_upgrade_month(currentMonth);
        new_object = {
          start_date: data.start_date,
          end_date: data.end_date,
        };
        setCurrentMonth(data.currentMonth);
      } else {
        new_object = {
          start_date: moment(start_date)
            .add(1, selectedTool)
            .startOf(selectedTool)
            .toDate(),
          end_date: moment(end_date)
            .add(1, selectedTool)
            .endOf(selectedTool)
            .toDate(),
        };
      }
    } else {
      if (selectedTool == "month") {
        let data = handle_downgrade_month(currentMonth);
        new_object = {
          start_date: data.start_date,
          end_date: data.end_date,
        };
        setCurrentMonth(data.currentMonth);
      } else {
        new_object = {
          start_date: moment(start_date)
            .subtract(1, selectedTool)
            .startOf(selectedTool)
            .toDate(),
          end_date: moment(end_date)
            .subtract(1, selectedTool)
            .endOf(selectedTool)
            .toDate(),
        };
      }
    }
    setCurrentDate(new_object);
  };

  const handleChangeNextPrev = (value) => {
    if (selectedTool == "month") {
      let classToClick = "fc-next-button";
      if (value == "prev") {
        classToClick = "fc-prev-button";
      }
      let click_element = document.getElementsByClassName(classToClick)[0];
      click_element.click();
    }
    hanldeChangeDates(value);
  };

  const handleClickDate = (event) => {
    setSelectedTool("day");
    setCurrentDate({
      start_date: event.dateStr,
      end_date: event.dateStr,
    });
  };

  useEffect(() => {
    getEventListing(eventList, time_zone);
  }, [eventList, time_zone]);

  useEffect(() => {
    if (!notreload) {
      reloadList();
    }
  }, [windowWidth, notreload]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-4 col-md-6">
          <h2>{calendar_title}</h2>
        </div>
        <div className="col-8 col-md-6">
          {showAddEvent && (
            <>
              {user_type !== "delegate" && (
                <button
                  className="small-contained-button float-end mt-1"
                  onClick={handleOpenDrawer}
                >
                  Add Event
                </button>
              )}
              <button
                className="small-contained-button float-end mt-1 me-2"
                onClick={handleNavigateListEvent}
              >
                List Event
              </button>
            </>
          )}
        </div>
        <div className="col-lg-4 col-sm-12 text-center text-lg-start full-calendar-toolbar mt-4">
          {!hideArrowsAndButtons && (
            <div class="btn-group toollbar-group-buttons">
              <button
                className={`small-contained-button`}
                onClick={() => {
                  handleChangeNextPrev("prev");
                }}
              >
                <ArrowBackIosIcon />
              </button>
              <button
                className={`small-contained-button`}
                onClick={() => {
                  handleChangeNextPrev("next");
                }}
              >
                <ArrowForwardIosIcon />
              </button>
            </div>
          )}
        </div>
        <div className="col-lg-4 col-sm-12 text-center full-calendar-toolbar mt-4">
          <h2>{getCenteredText()}</h2>
        </div>
        <div className="col-lg-4 col-sm-12 text-center text-lg-end mt-4">
          <div class="btn-group toollbar-group-buttons">
            {TOOL_BUTTONS.map((button) => {
              return (
                <button
                  className={`small-contained-button ${
                    button.text == selectedTool ? "selected-button" : ""
                  }`}
                  onClick={() => {
                    handleChangeTools(button.text);
                  }}
                >
                  {button.text}
                </button>
              );
            })}
          </div>
        </div>

        <div className="col-md-12 full-calendar full-calendar-new-flow mt-3 order-4">
          {selectedTool == "month" ? (
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "today prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              className="fc-day-sun fc-toolbar-chunk fc eventimage fc-col-header-cell-cushion fc-today-button fc-right fc-prev-button fc-right fc-next-button fc-dayGridMonth-button fc-timeGridWeek-button fc-timeGridDay-button fc-daygrid-day-number fc-daygrid-day-top"
              eventContent={renderEventContent}
              events={events}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={
                window.screen.width > 768
                  ? 3
                  : window.screen.width > 575
                  ? 2
                  : 1
              }
              weekends={true}
              fixedWeekCount={false}
              moreLinkContent={function (args) {
                return "+" + args.num;
              }}
              dateClick={function (args) {
                handleClickDate(args);
              }}
              // moreLinkClick={function (args) {
              //   handleClickDate(args);
              // }}
            />
          ) : selectedTool == "week" ? (
            <WeekCalendar
              events={events}
              handleClick={eventDetail}
              currentDate={currentDate}
              isLoading={isLoading}
            />
          ) : (
            <DayCalendar
              events={events}
              handleClick={eventDetail}
              currentDate={currentDate}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
      <DetailPopUpModel
        open={popupState}
        setOpen={setPopupState}
        handleAgreeDelete={handleAgreeDelete}
        handleEdit={handleUpdate}
        handleOpenDrawerItration={handleOpenDrawerItration}
        title={"Event Detail"}
        eventDetailData={eventDetailData}
        hideArrowsAndButtons={hideArrowsAndButtons}
        user_type={user_type}
        componentToPassDown={<EventDetail eventDetailData={eventDetailData} />}
      />

      <PerformActionOn
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Delete recurring event?"}
        handleAgree={handleDelete}
      />
    </div>
  );
}

export default GeneralCalendar;
