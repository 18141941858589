import * as React from "react";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, IconButton } from "@mui/material";
import { getDetailQuestion } from "src/DAL/goalstatement/goalStatement";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useEffect } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "15%",
    marginBottom: "15%",
  },
}));

let tab_type = ["individual", "group", "all"];

export default function DetailQuestion() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [groups, setGroups] = useState([]);
  const [members, setMembers] = useState([]);
  const [goalMembers, setGoalMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTabLoading, setIsTabLoading] = useState(false);
  const [questionStatement, setQuestionStatement] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState("");
  //Changing tab values
  const handleChange = (event, newValue) => {
    localStorage.removeItem("detail_question_search_text");
    setPage(0);
    setPageCount(1);
    setSearchText("");
    setTabValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const DetailQuestion = async () => {
    setIsTabLoading(true);
    let type = tab_type[tabValue];
    let search_keyword =
      localStorage.getItem("detail_question_search_text") == null
        ? searchText
        : localStorage.getItem("detail_question_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const result = await getDetailQuestion(
      params.id,
      type,
      page,
      rowsPerPage,
      search_keyword
    );
    if (result.code === 200) {
      const member_list = result?.goal_statement_members.map((member) => {
        return {
          ...member,
          name: member.first_name + " " + member.last_name,
          table_avatar: {
            src: s3baseUrl + member.profile_image,
            alt: member.first_name,
          },
        };
      });
      const groups_list = result?.goal_statement_groups.map((group) => {
        return {
          ...group,
          members_count: group.member.length,
        };
      });
      setGroups(groups_list);
      setGoalMembers(member_list);
      setMembers(member_list);
      setTotalCount(result?.total_count);
      setTotalPages(result?.total_pages);
      setQuestionStatement(result?.goal_statement);
      setIsLoading(false);
      setIsTabLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsTabLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    DetailQuestion();
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Profile",

      type: "thumbnail",
    },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
  ];

  const GROUP_TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Name" },
    { id: "members_count", label: "Members" },
  ];

  useEffect(() => {
    DetailQuestion();
  }, [tabValue, page, rowsPerPage]);

  useEffect(() => {
    localStorage.setItem("detail_question_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("detail_question_search_text", "");
    } else {
      localStorage.setItem("detail_question_search_text", searchText);
    }
  }, [searchText]);

  const settings = {
    pagePagination: true,
    className: "card-with-background mt-3",
  };

  const TABS_OPTIONS = [
    {
      title: "Member List",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={goalMembers}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          {...settings}
        />
      ),
    },
    {
      title: "Group List",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CustomMUITable
          TABLE_HEAD={GROUP_TABLE_HEAD}
          data={groups}
          {...settings}
        />
      ),
    },
    {
      title: "All Members",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          data={members}
          {...settings}
        />
      ),
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h3 className="ms-1 mt-2 ">
            {questionStatement?.question_statement}
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-4">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
