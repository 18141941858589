import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { dd_date_format } from "src/utils/constant";
export default function GroupsPopOver({
  handleClick,
  handleClose,
  anchorEl,
  id,
  open,
  program,
  navigatePage,
}) {
  const navigate = useNavigate();

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ heigth: 100 }}
      >
        <div className="sticky-top card">
          <p className="heading_programs">Groups</p>
          <span className="icon-container" onClick={() => handleClose()}>
            <CancelOutlinedIcon fontSize="small" />
          </span>
        </div>
        {program?.map((item, index) => {
          console.log(item, "itemitemitem");
          return (
            <p
              className="mb-1 program-hover"
              style={{ padding: "2px 5px 2px 8px" }}
            >
              {item?._id?.title}
            </p>
          );
        })}
      </Popover>
    </div>
  );
}
