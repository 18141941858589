import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { clientPortalUrl, s3baseUrl } from "src/config/config";
import { member_login_api } from "src/DAL/member/member";
import { dd_date_format } from "src/utils/constant";

import roundFilterList from "@iconify/icons-ic/round-filter-list";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import { Icon } from "@iconify/react";

import {
  DeletePageSubscriberMemberApi,
  delete_multiple_subscribers,
  pageSubscriberListingApiNew,
} from "src/DAL/WebsitePages/WebsitePages";

import { download_csv_file } from "src/utils/convertHtml";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import DelegateFilterDateBase from "src/pages/consultant/ConsultantFilterDateBase";
import SubscribersFilter from "./SubscribersFilter";
import moment from "moment";
import FilteredChip from "src/components/FilteredChip";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import GeneralRequestForCsv from "src/pages/MembersList/GeneralRequestForCsv";

const TABLE_HEAD = [
  { id: "action", label: "Action", type: "action" },
  { id: "number", label: "#", type: "number" },
  {
    id: "table_avatar",
    label: "Profile",
    type: "thumbnail",
  },
  { id: "user_name", label: "User Name" },
  { id: "plan_title", label: "Plan Title" },
  { id: "mainPlan", label: "Main Plan" },
  {
    id: "affiliate_name",
    label: "Referral User",
  },
  {
    id: "created_at",
    label: "Subscribe Date",
  },
  {
    id: "register_url",
    label: "Register Url",
  },

  {
    id: "preview_link_data",
    label: "Agreement PDF",
    type: "link",
  },
];
const headers = [
  { key: "fullName", label: "User Name" },
  { key: "email", label: "Email" },
  { key: "plan_title", label: "Plan Title" },
  { key: "pdf_link", label: "Agreement PDF" },

  { key: "created_at", label: "Subscription Date" },
];

const subcribers_csv = [
  {
    label: "First Name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Last Name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true, is_disabled: true },
  {
    label: "Contact Number",
    value: "contact_number",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Plan_title", value: "plan_title", is_checked: false },
  { label: "Page_title", value: "page_title", is_checked: false },
  { label: "Referral_user", value: "referral_user", is_checked: false },
  { label: "aggrement_pdf_url", value: "aggrement_pdf_url", is_checked: false },
  {
    label: "Subscription_date",
    value: "subscription_date",
    is_checked: false,
  },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loadingSection: {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%",
    padding: 0,
  },
}));

export default function PageSubscribers() {
  const navigate = useNavigate();
  const { page_id } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSection, setIsLoadingSection] = useState(false);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [pageCount, setPageCount] = useState(1);
  const [deleteDoc, setDeleteDoc] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [plansList, setPlansList] = useState([]);
  const [editDrawerStateDep, setEditDrawerStateDep] = useState(false);
  const [openMultiDelete, setOpenMultiDelete] = useState(false);
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };
  const EMPTY_FILTER = {
    is_date_range: false,
    plan: null,
    start_date: moment(Date.now()).format("YYYY-MM-DD"),
    end_date: moment(Date.now()).format("YYYY-MM-DD"),
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterUpdated, setFilterUpdated] = useState(EMPTY_FILTER);
  const websitePages = window.location.pathname.includes("/website-pages");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  // -----------------filter start
  const handleOpenEditDrawerDep = () => {
    setEditDrawerStateDep(true);
  };

  const handleChangeOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleCloseEditDrawerDep = () => {
    setEditDrawerStateDep(false);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    let clear_data = {
      ...EMPTY_FILTER,
    };
    setPage(0);
    setPageCount(1);
    getSubscribersList(clear_data);
    setFilterData(clear_data);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    if (!data.date) {
      delete data.date;
      data.start_date = null;
      data.is_date_range = false;
      data.end_date = null;
    }
    getSubscribersList(data);
    setFilterData(data);
  };

  const handleAgreeMultiDelete = () => {
    setOpenMultiDelete(true);
  };

  const handle_chips_data = (flterData, formData) => {
    let chipData = { ...flterData };
    if (chipData.is_date_range) {
      let date = `Start Date : ${dd_date_format(
        chipData.start_date
      )} - End Date :  ${dd_date_format(chipData.end_date)}`;
      chipData.date = {
        chip_label: date,
        chip_value: date,
      };
    }

    setFilterData({ ...chipData });

    delete chipData.start_date;
    delete chipData.end_date;

    setFilterUpdated(chipData);
  };
  // ------------------end

  const getSubscribersList = async (filter_data) => {
    let postData = { ...filter_data };
    setEditDrawerStateDep(false);
    if (postData.plan) {
      postData.plan = postData.plan._id;
    }
    if (postData.is_date_range) {
      if (postData.start_date) {
        postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
      }
      if (postData.end_date) {
        postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
      }
    } else {
      postData.start_date = "";
      postData.end_date = "";
    }

    setIsLoadingSection(true);
    const result = await pageSubscriberListingApiNew(
      page_id,
      page,
      rowsPerPage,
      searchText,
      postData
    );

    if (result.code == 200) {
      const members = result.event_subscriber?.map((event, index) => {
        let event_plan = event.plan_info;
        let user_name = "N/A";
        let fullName = "N/A";
        let email = "N/A";
        let plan_title = "N/A";
        let mainPlan = "N/A";
        let register_url = "N/A";
        if (event?.register_url) {
          register_url = event.register_url;
        }
        if (event_plan?.plan_title) {
          plan_title = event_plan?.plan_title;
        }
        let affiliate_name = "N/A";
        if (
          event?.affiliate_info &&
          event?.affiliate_info.affiliate_user_info
        ) {
          affiliate_name =
            event?.affiliate_info.affiliate_user_info.first_name +
            " " +
            event?.affiliate_info.affiliate_user_info.last_name +
            "(" +
            event?.affiliate_info.affiliate_url_name +
            ")";
        }

        let member = event.member_info;
        if (member?.first_name) {
          user_name =
            member.first_name +
            " " +
            member.last_name +
            " (" +
            member.email +
            ")";
        }
        if (member?.first_name) {
          email = member.email;
        }
        if (member?.first_name) {
          fullName = member.first_name + " " + member.last_name;
        }

        if (event?.main_plan) {
          let title = "";
          if (event.main_plan.is_plan_free === true) {
            title = "Free";
          } else if (event.main_plan.payment_access === "recursion") {
            title = "Recurring";
          } else if (event.main_plan.payment_access === "onetime") {
            title = "OneTime";
          }
          mainPlan = event.main_plan?.plan_title + " (" + title + ")";
        }

        let payment_access = event_plan?.payment_access;
        if (plan_title != "N/A") {
          if (event_plan?.is_plan_free) {
            plan_title = plan_title + " (Free)";
          } else if (payment_access == "recursion") {
            plan_title = plan_title + " (Recurring)";
          } else {
            plan_title = plan_title + " (OneTime)";
          }
        }

        return {
          ...event,
          mainPlan,
          user_name: user_name,
          fullName: fullName,
          email: email,
          affiliate_name: affiliate_name,
          register_url: register_url,
          created_at: dd_date_format(event.createdAt),
          plan_title: plan_title,
          pdf_link: event.aggrement_pdf_url
            ? s3baseUrl + event.aggrement_pdf_url
            : "N/A",
          preview_link_data: {
            to: s3baseUrl + event.aggrement_pdf_url, // yaha pr check lgaana ha agr page landing ha to slug ni attach krna
            target: "_blank",
            show_text: event.aggrement_pdf_url ? "Preview" : "",
            className: "anchor-style",
          },
          table_avatar: {
            src: s3baseUrl + member?.profile_image,
            alt: member?.first_name,
          },
        };
      });

      handle_chips_data(filter_data);
      setSubscriptionsData(members);
      setTotalCount(result.total_event_subscriber_count);
      setTotalPages(result.total_pages);
      setPageTitle(result.page_title);
      setPlansList(result.payment_plans);
      setFilterQuery(result.query);
      setIsLoading(false);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingSection(false);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    getSubscribersList(filterData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeletePageSubscriberMemberApi(deleteDoc._id);
    if (result.code === 200) {
      getSubscribersList(filterData);
      setSubscriptionsData((prev) => {
        return prev.filter((data) => data._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const LoginAsMember = async (value) => {
    console.log(value, "valuevalue");
    setIsLoading(true);
    const data = {
      email: value.member_info.email,
      user_type: "client",
    };
    const result = await member_login_api(data);
    if (result.code === 200) {
      window.open(`${clientPortalUrl}login-by-admin/${result.token}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleMultipleDelete = async () => {
    setOpenMultiDelete(false);
    const subscriber_id = [];
    selected.map((subscribe) => {
      subscriber_id.push(subscribe._id);
    });

    const postDate = {
      subscriber_ids: subscriber_id,
    };

    const result = await delete_multiple_subscribers(postDate);
    if (result.code === 200) {
      setSelected([]);
      getSubscribersList(filterData);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  let MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Login As Member",
      icon: "ant-design:lock",
      handleClick: LoginAsMember,
    },
  ];

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleExportClick = () => {
    download_csv_file(headers, subscriptionsData);
  };

  let MENU_OPTIONS_MAIN = [
    {
      label: "Request For Csv",
      icon: "ic:round-cloud-download",
      handleClick: showPopUPcsv,
    },
  ];
  useEffect(() => {
    getSubscribersList(filterData);
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let breadCrumbMenu = [
    {
      title: websitePages ? "Pages" : "Template Pages",
      navigation: `${websitePages ? `/website-pages` : `/template-pages`}`,
      active: false,
    },
    {
      title: pageTitle,
      active: true,
    },
  ];
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this subscription?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-3">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-4 col-sm-12 mb-3">
            <h2>Page Subscribers</h2>
          </div>
          <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
            <div className="d-flex justify-content-end mb-3 page-subscribers-filter">
              <button
                className="small-contained-button me-2"
                onClick={showPopUPcsv}
              >
                Request For Csv&nbsp;&nbsp;{" "}
                <Icon icon={arrowCircleDownFill} height={20} />
              </button>
              <button
                className="small-contained-button me-2"
                onClick={handleOpenEditDrawerDep}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
            </div>
            {/* <CustomPopoverSection menu={MENU_OPTIONS_MAIN} /> */}
          </div>
        </div>
        <FilteredChip
          data={filterUpdated}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <div className="row">
          {isLoadingSection ? (
            <CircularProgress
              className={classes.loadingSection}
              color="primary"
            />
          ) : (
            <div className="col-12">
              {selected.length > 0 && (
                <div className="page_del_btn">
                  <button
                    className="small-contained-button"
                    onClick={handleAgreeMultiDelete}
                  >
                    Delete
                  </button>
                </div>
              )}
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                MENU_OPTIONS={MENU_OPTIONS}
                data={subscriptionsData}
                selected={selected}
                setSelected={setSelected}
                checkbox_selection={true}
                className="card-with-background"
                custom_pagination={{
                  total_count: totalCount,
                  rows_per_page: rowsPerPage,
                  page: page,
                  handleChangePage: handleChangePage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
                custom_search={{
                  searchText: searchText,
                  setSearchText: setSearchText,
                  handleSubmit: searchFunction,
                }}
                pageCount={pageCount}
                totalPages={totalPages}
                handleChangePages={handleChangePages}
                pagePagination={true}
              />
            </div>
          )}
          <CustomDrawer
            isOpenDrawer={editDrawerStateDep}
            onOpenDrawer={handleOpenEditDrawerDep}
            onCloseDrawer={handleCloseEditDrawerDep}
            pageTitle="Filters"
            componentToPassDown={
              <SubscribersFilter
                filterData={filterData}
                setFilterData={setFilterData}
                searchSubmitFilter={searchFunction}
                handleChangeOthers={handleChangeOthers}
                plansList={plansList}
                handleClearFilter={handleClearFilter}
              />
            }
          />
          <CustomConfirmation
            open={openMultiDelete}
            setOpen={setOpenMultiDelete}
            title={"Are you sure you want to delete these subscribers?"}
            handleAgree={handleMultipleDelete}
          />
          <GeneralPopUpModel
            open={showExportcsvFile}
            setOpen={setShowExportcsvFile}
            title={"Export CSV file request  "}
            componentToPassDown={
              <GeneralRequestForCsv
                options_array={subcribers_csv}
                collection_name={"page_subscribers"}
                filter_data={filterQuery}
                setShowExportcsvFile={setShowExportcsvFile}
              />
            }
          />
        </div>
      </div>
    </>
  );
}
