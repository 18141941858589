import { invokeApi } from "../../bl_libs/invokeApi";
export const _add_attitude_accessment_questions = async (data) => {
  const requestObj = {
    path: `/api/attitude_assessment_question`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_attitude_accessment_questions = async (id, data) => {
  const requestObj = {
    path: `/api/attitude_assessment_question/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_attitude_accessment_questions_by_type = async (type) => {
  const requestObj = {
    path: `/api/attitude_assessment_question?type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_attitude_accessment_questions_by_type_v1 = async (data) => {
  const requestObj = {
    path: `/api/attitude_assessment_question/list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_attitude_accessment_questions = async (id) => {
  const requestObj = {
    path: `/api/attitude_assessment_question/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
