import React from "react";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageRewardAccess({ tabData }) {
  const [isLoading, setIsLoading] = useState(true);
  const [consultantData, setConsultantData] = useState([]);
  const classes = useStyles();

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
      className: "p-0",
    },
    { id: "title", label: "Title", alignRight: false },
    {
      id: "status",
      label: "Status",
      type: "row_status",
      alignRight: false,
    },
  ];

  const getRewardsListing = () => {
    let reward_data = tabData?.map((reward) => {
      return {
        ...reward,
        table_avatar: {
          src: s3baseUrl + reward?.icon_with_border?.thumbnail_1,
          alt: reward?.title,
        },
      };
    });
    setConsultantData(reward_data);
    setIsLoading(false);
  };

  useEffect(() => {
    getRewardsListing();
  }, [tabData]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={consultantData}
          className="card-with-background"
        />
      </div>
    </>
  );
}
