import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import "react-tagsinput/react-tagsinput.css";
import TagsInput from "react-tagsinput";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  detailEmailTemplateApi,
  editEmailTemplateApi,
} from "src/DAL/SiteSetting/siteSetting";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EditEmailTemplate() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const params = useParams();
  const [selected, setSelected] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    status: "",
    email_subject: "",
    email_message: "",
    cc_emails: [],
    main_email: "",
    template_title: "",
  });

  const emailTemplateDetail = async () => {
    setIsLoading(true);
    const result = await detailEmailTemplateApi(params.id);
    if (result.code == 200) {
      setInputs(result.email_template);

      let Array_selected = [];
      result?.email_template?.cc_emails?.map((item, index) => {
        Array_selected?.push(item.email);
      });
      setSelected(Array_selected);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let cc_email_array = [];
    let cc_words_email_array = [];
    selected?.map((item, index) => {
      var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const check = pattern.test(item);
      if (check == true) {
        const email_object = { email: item };
        cc_email_array.push(email_object);
      } else {
        cc_words_email_array.push(item);
      }
    });
    if (cc_email_array.length == selected.length) {
      let postData = {
        title: inputs.title,
        email_subject: inputs.email_subject,
        email_message: inputs.email_message,
        status: inputs.status,
        cc_emails: cc_email_array,
        main_email: inputs.main_email,
        template_title: inputs.template_title,
      };
      const result = await editEmailTemplateApi(postData, params.id);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      const wrappedWords = cc_words_email_array?.map((word) => `"${word}"`);
      const paragraph = wrappedWords.join(" ");
      enqueueSnackbar(`${paragraph} is must be a valid Email in CC Emails`, {
        variant: "error",
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (state) {
      setInputs(state);
      let Array_selected = [];
      state?.cc_emails?.map((item, index) => {
        Array_selected?.push(item.email);
      });
      setSelected(Array_selected);
    } else {
      emailTemplateDetail();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Edit Template</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Template Title"
              variant="outlined"
              fullWidth
              required
              name="template_title"
              value={inputs?.template_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Email Body Heading"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs?.title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Email Subject"
              variant="outlined"
              fullWidth
              required
              name="email_subject"
              value={inputs?.email_subject}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Main Email"
              variant="outlined"
              fullWidth
              name="main_email"
              value={inputs.main_email}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Status </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label=" Status "
                onChange={handleChange}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TagsInput
              value={selected}
              onChange={setSelected}
              inputProps={{
                placeholder: "CC Emails",
              }}
              name="cc_emails"
              type="email"
              label="cc_emails"
            />
          </div>

          <div className="col-12 mt-5">
            <h4>Email Body Message *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="email_message"
              editorHeight={320}
            />
          </div>

          <div className="text-end mt-4">
            <button type="submit" className="small-contained-button">
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
