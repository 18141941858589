import { invokeApi } from "../../../bl_libs/invokeApi";

export const programme_document_list_api = async (data) => {
  const requestObj = {
    path: `/api/program_document/document_list_by_program/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_programme_document_api = async (data) => {
  const requestObj = {
    path: `/api/program_document`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const programme_document_detail_api = async (slug) => {
  const requestObj = {
    path: `/api/program_document/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const edit_programme_document_api = async (data, slug) => {
  const requestObj = {
    path: `/api/program_document/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_programme_document_api = async (slug) => {
  const requestObj = {
    path: `/api/program_document/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
