import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";

import { s3baseUrl } from "../../config/config";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  DeleteRecording,
  RecordingListingApi,
} from "src/DAL/DelegatesRecordings/DelegatesRecordings";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function DelegatesRecordings() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recordingData, serRecordingData] = useState([]);
  const classes = useStyles();
  const [deleteDoc, setDeleteDoc] = useState("");

  const getMemberListing = async () => {
    setIsLoading(true);
    const result = await RecordingListingApi();
    if (result.code == 200) {
      const members = result?.recording?.map((recording, index) => {
        return {
          ...recording,
          table_avatar: {
            src: s3baseUrl + recording.recording_image.thumbnail_2,
            alt: recording.title,
          },
          program_name: recording.program_info.title,
        };
      });

      serRecordingData(members);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleEdit = (value) => {
    navigate(`/delegate-recordings/edit-recording/${value._id}`, {
      state: value,
    });
  };
  const handleDetail = (value) => {
    navigate(`/delegate-recordings/recording-detail/${value.recording_slug}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteRecording(deleteDoc.recording_slug);
    if (result.code === 200) {
      getMemberListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Detail",
      icon: "akar-icons:eye-open",
      handleClick: handleDetail,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
    },
    {
      id: "title",
      label: " Name",
      renderData: (row) => {
        return (
          <Tooltip title={"View Detail"}>
            <span
              className="mb-0"
              style={{ cursor: "pointer" }}
              onClick={() => handleDetail(row)}
            >
              {row.title}
            </span>
          </Tooltip>
        );
      },
    },
    { id: "program_name", label: "Programme", alignRight: false },
    { id: "recording_date", label: "Date", alignRight: false },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];
  useEffect(() => {
    getMemberListing();
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 mb-4">
            <h2>Delegates Recordings</h2>
          </div>
          <div className="col-6 mb-4">
            <button
              className="small-contained-button float-end mt-1"
              onClick={() => navigate(`/delegate-recordings/add-recording`)}
            >
              Add New Recording
            </button>
          </div>
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          data={recordingData}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
