// const DynamiteDiarySetting = () => {
//   return <>dynamite</>;
// };

// export default DynamiteDiarySetting;
import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  clientSettingApi,
  UpdateCookieSetting,
  UpdateDynamiteDiarySetting,
  UpdateMeditationSetting,
  updateWheelOfLifeSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  _get_attitude_accessment_setting,
  _get_website_cookie_setting,
} from "src/DAL/AttitudeAccessmentQuestions/AttitudeAccessmentSetting";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function WebsiteCockieSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = React.useState({
    is_show_cookie: false,
    cookie_policy_content: "",
  });

  const clientDetail = async () => {
    setIsLoading(true);
    const result = await _get_website_cookie_setting();
    if (result.code == 200) {
      console.log(result, "kokoko");
      setInputs(result.website_cockie_setting);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.is_show_cookie && inputs.cookie_policy_content.length <= 0) {
      enqueueSnackbar("Please add cookie Policy content !", {
        variant: "error",
      });
      setIsLoadingForm(false);
    } else {
      setIsLoadingForm(true);
      let data_obj = inputs;
      let mediation_settings = {
        website_cockie_setting: data_obj,
      };
      const result = await UpdateCookieSetting(mediation_settings);
      if (result.code === 200) {
        setIsLoadingForm(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingForm(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    clientDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-6">
          <h2>Website Cookie Setting</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-3 col-sm-12 mt-5">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Is Show Cookie *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_show_cookie"
                value={inputs.is_show_cookie}
                label="Is Show Cookie *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-12 mt-4">
            <h4>
              {inputs.is_show_cookie
                ? "Cookie Policy Content*"
                : "Cookie Policy Content"}
            </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              required
              name="cookie_policy_content"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
