import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  active_lead_status_api,
  update_lead_status_for_member_api,
} from "src/DAL/leadStatus/leadStatus";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { TextField } from "@mui/material";
export default function ChangeLeadStatus({
  selectedObject,
  setOpen,
  handleUpdateStatus,
}) {
  const [inputs, setInputs] = useState({
    changed_date_time: dayjs(new Date()).$d,
    income_value: 0,
    lead_status: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [leadStatus, setLeadStatus] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const handleChangeDates = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value.$d }));
  };
  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeStatus = async () => {
    setOpenStatus(false);
    setIsLoading(true);
    const postData = {
      member_id: inputs._id,
      lead_status: inputs.lead_status ? inputs.lead_status._id : "",
      changed_date_time: moment(inputs.changed_date_time).format("YYYY-MM-DD"),
      income_value: inputs.income_value,
    };
    const result = await update_lead_status_for_member_api(postData);
    if (result.code === 200) {
      handleUpdateStatus(inputs);
      setOpen(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const get_active_lead_status = async () => {
    const result = await active_lead_status_api();
    if (result.code === 200) {
      setLeadStatus(result.lead_status);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenStatus(true);
  };

  useEffect(() => {
    get_active_lead_status();
    setInputs((old) => {
      return {
        ...old,
        lead_status: selectedObject.lead_status,
        _id: selectedObject._id,
      };
    });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
            <MUIAutocomplete
              inputLabel="Lead Status"
              selectedOption={inputs.lead_status}
              setSelectedOption={(v) => {
                handleChangeOthers("lead_status", v);
              }}
              optionsList={leadStatus}
              autoComplete="new-password"
              name="title"
            />
          </div>
          <div className="col-12">
            <TextField
              fullWidth
              type="number"
              className="mt-3"
              id="outlined-basic"
              label="Income Value"
              variant="outlined"
              name="income_value"
              InputProps={{ inputProps: { min: 0 } }}
              value={inputs.income_value}
              required={true}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-2">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date"
                name="changed_date_time"
                inputFormat="DD-MM-YYYY"
                value={inputs.changed_date_time}
                format="DD-MM-YYYY"
                className="mt-2"
                onChange={(e) => handleChangeDates("changed_date_time", e)}
                renderInput={(params) => (
                  <TextField {...params} required={true} fullWidth />
                )}
              />
            </LocalizationProvider>
          </div>

          <div class="col-12 mt-3">
            <button class="small-contained-button w-100">
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
      <CustomConfirmation
        open={openStatus}
        setOpen={setOpenStatus}
        title={"Are you sure you want to update lead status?"}
        handleAgree={handleChangeStatus}
      />
    </>
  );
}
