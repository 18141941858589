import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Chip,
  Tooltip,
  Avatar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  delete_lesson_api,
  lesson_listing_api,
} from "src/DAL/Programme/Lessons/Lessons";
import { useEffect, useState } from "react";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { CircularProgress } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import GridViewIcon from "@mui/icons-material/GridView";
import CircleIcon from "@mui/icons-material/Circle";
import { makeStyles } from "@mui/styles";

import RecordNotFound from "src/components/RecordNotFound";
import { programme_detail_api } from "src/DAL/Programme/Programme";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const Progrmme_status = [
  {
    name: "Active",
    value: true,
    class: "active_bg_program",
  },
  {
    name: "InActive",
    value: false,
    class: "inactive_bg_program",
  },
];
export default function LessonListing() {
  const { programme_slug } = useParams();
  const { viewModeLessons, setViewModeLessons } = usePGIMode();
  const classes = useStyles();
  const navigate = useNavigate();
  const [lesson, setLesson] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [lessonDetailInfo, setLessonsDetailInfo] = useState();
  const [programName, setProgramName] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const toggleView = () => {
    setViewModeLessons((prevMode) => (prevMode === "grid" ? "table" : "grid"));
  };
  const handleCloseDelete = () => setOpenDelete(false);
  const { state } = useLocation();
  const getFilteredData = (query) => {
    let dataToFilter = [...lesson];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };

  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };

  const getLessonsList = async () => {
    let result = await lesson_listing_api(programme_slug);
    if (result.code == 200) {
      setIsLoading(false);
      setLesson(result.lesson);
    }
  };

  const getProgrammeDetail = async () => {
    setIsLoading(true);
    const result = await programme_detail_api(programme_slug);
    if (result.code === 200) {
      setProgramName(result?.program?.title);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const hanldeDetail = (value) => {
    navigate(`/lessons/lesson-detail/${value.lesson_slug}`, {
      state: value,
    });
  };
  const handleOpenDelete = (value) => {
    setLessonsDetailInfo(value);
    setOpenDelete(true);
  };
  const hanldeAddLesson = () => {
    navigate(`/lessons/${programme_slug}/add-lesson`);
  };
  const handleNavigateEdit = (value) => {
    navigate(
      `/lessons/edit-lesson/${value.program[0]._id.program_slug}/${value.lesson_slug}`,
      {
        state: lessonDetailInfo,
      }
    );
  };
  const handleNavigateConfiguration = (value) => {
    navigate(
      `/lessons/tab-configuration/${value.program[0]._id.program_slug}/${value.lesson_slug}`,
      {
        state: value,
      }
    );
  };

  const handleQuestionsConfiguration = (value) => {
    navigate(`/lessons/questions-configuration/${value.lesson_slug}`, {
      state: value,
    });
  };

  const handleNavigateDocs = (value) => {
    navigate(`/lessons/lesson-document/${value.lesson_slug}`, {
      state: value,
    });
  };

  const handleNavigateLessonRecordings = (value) => {
    navigate(`/lessons/lesson-recordings/${value.lesson_slug}`, {
      state: value,
    });
  };

  const handleNavigateQuestionsAnswers = (value) => {
    navigate(`/lessons/lessons-questions-answers?created_for_id=${value._id}`, {
      state: value,
    });
  };

  const handleLessonsQuestions = (value) => {
    navigate(`/lessons/lessons-questions?created_for_id=${value._id}`, {
      state: value,
    });
  };

  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },

    {
      title: state ? state?.title : programName ? programName : "",
      navigation: `/programmes/programmes-detail/${programme_slug}`,
      active: false,
    },
    {
      title: "Lessons",
      navigation: ``,
      active: true,
    },
  ];
  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },

    {
      id: "title",
      label: "Image",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            {" "}
            {
              <Avatar
                src={s3baseUrl + row.lesson_images.thumbnail_1}
                alt={row.title}
              />
            }
          </>
        );
      },
    },
    {
      id: "title",
      label: "Title",
      alignRight: false,
      renderData: (row) => {
        console.log(row, "rowrowrow");
        let find_status = Progrmme_status.find(
          (status) => status.value == row.status
        );
        const iconStyle = {
          color: "blue !important",
        };

        return (
          <div className="d-flex pointer">
            <Tooltip title={find_status?.name} className="pointer">
              <CircleIcon
                style={iconStyle}
                className={`transation-status-icon ${find_status?.class}`}
              />
            </Tooltip>
            <p
              className="mb-0 "
              style={{ cursor: "pointer" }}
              onClick={() => hanldeDetail(row)}
            >
              {" "}
              {row.title}
            </p>
          </div>
        );
      },
    },
    {
      id: "title",
      label: "Program Module",
      alignRight: false,
      renderData: (row) => {
        let program_module_title = "N/A";
        if (row.program_module) {
          program_module_title = row.program_module.title;
        }
        return (
          <>
            <span>{program_module_title}</span>
          </>
        );
      },
    },
    {
      id: "title",
      label: "Chat Enable",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <span>{row.is_chat_enable ? "Yes" : "No"}</span>
          </>
        );
      },
    },
    {
      id: "title",
      label: "Access  Days Interval Start",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <span>{row.access_after_days ? row.access_after_days : "0"}</span>
          </>
        );
      },
    },
    {
      id: "title",
      label: "Access  Days Interval End",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <span>
              {row.end_access_after_days ? row.end_access_after_days : "0"}
            </span>
          </>
        );
      },
    },

    {
      id: "lesson_duration",
      label: "Lesson Duration",
      alignRight: false,
    },
    {
      id: "view_count",
      label: "Views Count",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <span>
              {" "}
              {row.view_count ? (
                <span className=" ">{row.view_count}</span>
              ) : (
                <span> 0 </span>
              )}
            </span>
          </>
        );
      },
    },
    {
      id: "title",
      label: "Completed",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <span>
              {" "}
              {row.lesson_completion_count ? (
                <span className=" ">{row.lesson_completion_count}</span>
              ) : (
                <span> 0 </span>
              )}
            </span>
          </>
        );
      },
    },
    {
      id: "title",
      label: "Watch",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <span>
              {" "}
              {row.watch_count ? (
                <span>{row.watch_count} </span>
              ) : (
                <span> 0 </span>
              )}
            </span>
          </>
        );
      },
    },
    {
      label: "Locked Status",
      renderData: (row) => {
        return (
          <Chip
            width="280px"
            label={row.locked === false ? "Unlocked" : "Locked"}
            variant="contained"
            className={
              row.locked === false ? `manage-program-chip-success` : ""
            }
            color={row.locked === false ? "success" : "error"}
            size="small"
          />
        );
      },
      alignRight: false,
    },

    // { id: "member_count", label: "Members", alignRight: false },
  ];
  let child_menu_options = [
    {
      label: "Questions Setting",
      icon: "akar-icons:edit",
      handleClick: handleQuestionsConfiguration,
    },
    {
      label: "Manage Questions",
      icon: "akar-icons:edit",
      handleClick: handleLessonsQuestions,
    },
    {
      label: "Questions Answers",
      icon: "akar-icons:edit",
      handleClick: handleNavigateQuestionsAnswers,
    },
  ];
  let MENU_OPTIONS = [
    {
      label: "Manage Doc",
      icon: "akar-icons:edit",
      handleClick: handleNavigateDocs,
    },
    {
      label: "Manage Recording",
      icon: "akar-icons:edit",
      handleClick: handleNavigateLessonRecordings,
    },
    {
      label: "Tabs Configuration",
      icon: "akar-icons:edit",
      handleClick: handleNavigateConfiguration,
    },
    {
      label: "Questions Configuration",
      icon: "akar-icons:edit",
      child_options: child_menu_options,
    },
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
  ];

  const handleDelete = async () => {
    handleCloseDelete();
    setIsLoading(true);
    let result = await delete_lesson_api(lessonDetailInfo.lesson_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getLessonsList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    getLessonsList();
    getProgrammeDetail();
  }, []);
  useEffect(() => {
    if (programme_slug) {
      if (state) {
      } else {
        getProgrammeDetail();
      }
    }
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mobile-margin display-flex">
          <div className="col-12 mb-3">
            <span className="">
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>

          <div className="col-lg-4 col-md-8 ">
            <h2>Lessons</h2>
          </div>
          <div
            className="col-lg-8 col-md-4 text-end"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <div>
              <FormControl variant="outlined" style={{ marginTop: "2px" }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  variant="small"
                  onChange={handleChangeSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>
            <button
              onClick={hanldeAddLesson}
              className="ms-3 mt-1 small-contained-button"
            >
              Add Lesson
            </button>
            <button
              onClick={toggleView}
              className="ms-1 mt-1 small-contained-button"
            >
              {viewModeLessons == "grid" ? (
                <>
                  <GridViewIcon />
                </>
              ) : (
                <>
                  <ListIcon />
                </>
              )}
            </button>
          </div>
        </div>
        <div className="row section-space">
          <div className="col-12">{/* <h1>{state.name}</h1> */}</div>
        </div>
        {viewModeLessons == "grid" ? (
          <div className="row">
            {getFilteredData(query).length < 1 ? (
              <RecordNotFound title="Lessons" />
            ) : (
              getFilteredData(query).map((lesson, i) => {
                return (
                  <>
                    <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={i}>
                      <div className="card mt-4 pods-cards-shadow cursor h-100">
                        <img
                          src={s3baseUrl + lesson.lesson_images.thumbnail_1}
                          className="card-img-top pods-image"
                          alt="Lessons"
                          onClick={() => hanldeDetail(lesson)}
                        />
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12">
                              <h3
                                className="h2-heading"
                                onClick={() => hanldeDetail(lesson)}
                              >
                                {htmlDecode(lesson.title)}
                              </h3>
                            </div>
                          </div>
                          <p className="programme-card-desc mb-5">
                            {htmlDecode(lesson.short_description).substring(
                              0,
                              250
                            ) + "..."}
                          </p>

                          <div className="row recording-card-date">
                            <div className="col-12 card-button recording-card-date-position">
                              {/* {lesson?.program_module?.title
                              ? "Module: " + lesson?.program_module?.title
                              : ""} */}
                              {lesson?.program_module?.title ? (
                                <Chip
                                  label={lesson?.program_module?.title}
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-8 card-button recording-card-date-position">
                              <p className="pods-active-members">
                                <span>
                                  Access Days Interval:{" "}
                                  {lesson.access_after_days
                                    ? lesson.access_after_days
                                    : "0"}
                                </span>
                              </p>
                            </div>
                            <div className="col-4 text-end ms-auto">
                              {lesson.lesson_duration}
                            </div>
                          </div>
                          <div className="row recording-card-date ">
                            <div className="col-4">
                              {lesson.view_count ? (
                                <p className=" pods-active-members">
                                  {lesson.view_count} Views
                                </p>
                              ) : (
                                <p className=" pods-active-members">0 Views</p>
                              )}
                            </div>
                            <div className="col-4 ps-0 pe-0">
                              {lesson.lesson_completion_count ? (
                                <p className=" pods-active-members">
                                  {lesson.lesson_completion_count} Completed
                                </p>
                              ) : (
                                <p className=" pods-active-members">
                                  {" "}
                                  0 Completed
                                </p>
                              )}
                            </div>
                            <div className="col-4 text-end ">
                              {lesson.watch_count ? (
                                <p className=" pods-active-members">
                                  {lesson.watch_count} Watch
                                </p>
                              ) : (
                                <p className="pods-active-members"> 0 Watch</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            )}
          </div>
        ) : (
          <div className="mt-4">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              MENU_OPTIONS={MENU_OPTIONS}
              data={getFilteredData(query)}
              className="card-with-background"
              pagePagination={true}
              is_hide={true}
            />
          </div>
        )}
        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete this lesson?"}
          handleAgree={handleDelete}
        />
      </div>
    </>
  );
}
