import { CircularProgress } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  affiliate_transaction_list,
  delete_affiliate_transaction,
} from "src/DAL/AffiliateTransactions/AffiliateTransactions";
import { convertCurrencyToSign } from "src/utils/constant";
import { useSnackbar } from "notistack";
import TransactionsStatCard from "./TransactionsStatCard";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DigitalPayment({ type }) {
  const [openModel, setOpenModel] = useState(false);
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [transactionData, setTransactionData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const classes = useStyles();
  const navigate = useNavigate();
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const getTransactionListing = async () => {
    setIsLoading(true);

    let search_keyword =
      localStorage.getItem("affiliate_search_text") == null
        ? searchText
        : localStorage.getItem("affiliate_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }

    const result = await affiliate_transaction_list(
      page,
      rowsPerPage,
      search_keyword
    );
    if (result.code == 200) {
      const users = result.transaction?.map((transaction, index) => {
        return {
          ...transaction,
          transactionType:
            transaction.stripe_transfer_id == "" ? "Cash" : "Stripe",
          name:
            transaction.affiliate_user_info?.first_name +
            " " +
            transaction.affiliate_user_info?.last_name +
            " (" +
            transaction.affiliate_user_info?.email +
            ")",
          transaction_amount:
            convertCurrencyToSign(transaction.currency) +
            " " +
            transaction.amount,
        };
      });

      setTransactionData(users);
      setTotalCount(result.total_member_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_affiliate_transaction(deleteDoc._id);
    if (result.code === 200) {
      setTransactionData((transactionData) => {
        return transactionData.filter((data) => data._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getTransactionListing();
  };

  const handleEdit = (row) => {
    navigate(`/affiliate-transactions/edit-transaction/${row._id}`, {
      state: row,
    });
  };
  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      alignRight: false,
      MENU_OPTIONS: "MENU_OPTIONS",
      type: "action",
    },
    {
      id: "number",
      label: "#",
      alignRight: false,
      type: "number",
    },
    {
      id: "payTo",
      label: "Pay To",
      alignRight: false,
      className: "text-capitalize",
    },
    { id: "digitalAmount", label: "Amount", alignRight: false },
    {
      id: "paymentMethod",
      label: "Payment Method",
      alignRight: false,
      className: "text-capitalize",
    },
    { id: "date", label: "Date", alignRight: false },
  ];
  let payments = [
    {
      payTo: type,
      digitalAmount: "£100",
      currency: "USD",
      paymentMethod: "cash",
      date: "15-01-2023", // Date format: dd mm yyyy
    },
    {
      payTo: type,
      digitalAmount: "£150",
      currency: "EUR",
      paymentMethod: "bank",
      date: "20-02-2023", // Date format: dd mm yyyy
    },
    {
      payTo: type,
      digitalAmount: "£75",
      currency: "GBP",
      paymentMethod: "bank",
      date: "10-03-2023", // Date format: dd mm yyyy
    },
    {
      payTo: type,
      digitalAmount: "£200",
      currency: "USD",
      paymentMethod: "cash",
      date: "05 04 2023", // Date format: dd mm yyyy
    },
    {
      payTo: type,
      digitalAmount: "£120",
      currency: "EUR",
      paymentMethod: "bank",
      date: "12-05-2023", // Date format: dd mm yyyy
    },
  ];

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    // {
    //     label: "Edit",
    //     icon: "akar-icons:edit",
    //     handleClick: handleEdit,
    // },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleNavigate = () => {
    navigate(`/dynamite-digital-payment/add-digital-payment`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    localStorage.setItem("affiliate_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("affiliate_search_text", "");
    } else {
      localStorage.setItem("affiliate_search_text", searchText);
    }
  }, [searchText]);

  useEffect(() => {
    getTransactionListing();
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-8 col-sm-12">
            <h2>{type}</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add {type}
            </button>
          </div>
        </div>
        <div className="row ">
          <TransactionsStatCard
            count={10}
            title={`Remaining ${type}`}
            className="col-lg-4"
          />{" "}
          <TransactionsStatCard
            count={10}
            title={`Paid ${type}`}
            className="col-lg-4"
          />
          <TransactionsStatCard
            count={20}
            title={`Total ${type}`}
            className="col-lg-4"
          />
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={payments}
          className="card-with-background"
          MENU_OPTIONS={MENU_OPTIONS}
          // custom_pagination={{
          //   total_count: totalCount,
          //   rows_per_page: rowsPerPage,
          //   page: page,
          //   handleChangePage: handleChangePage,
          //   onRowsPerPageChange: handleChangeRowsPerPage,
          // }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          // pageCount={pageCount}
          // totalPages={totalPages}
          // handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
}
