import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect } from "react";
import {
  CommissionConfigurationTemplateApi,
  get_list_list_without_team,
} from "src/DAL/PaymentRequest/paymentRequestApi";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  ReminderConfigapi,
  programmeDetailReminderapi,
} from "src/DAL/Programme/Programme";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ProgramReminderConfiguration() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { state } = useLocation();
  const params = useParams();
  const [userList, setUserList] = useState([]);
  const [serchText, setSerchText] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [requestType, setrequestType] = useState("onetime");
  const [inputs, setInputs] = useState({
    timer_bottom_description: "",
    timer_top_description: "",
    reminder_text_for_page: "",
    button_text: "",
    button_link: "",
    reminder_show_days: 0,
    show_reminder_for_active_membership_user: false,
  });
  const [requestObject, setrRequestObject] = useState({});

  console.log(state, "statestatestatestate");

  const handleSubmit = async (e) => {
    setIsLoading(true);
    let postData = {
      timer_bottom_description: inputs.timer_bottom_description,
      timer_top_description: inputs.timer_top_description,
      reminder_text_for_page: inputs.reminder_text_for_page,
      button_text: inputs.button_text,
      button_link: inputs.button_link,
      reminder_show_days: inputs.reminder_show_days,
      show_reminder_for_active_membership_user:
        inputs.show_reminder_for_active_membership_user,
    };
    const result = await ReminderConfigapi(postData, state?.program_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getList = async () => {
    const result = await programmeDetailReminderapi(state?.program_slug);
    if (result.code === 200) {
      setInputs(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    getList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let breadCrumbMenu = [
    {
      title: "Programme",
      navigation: `/programmes`,
      active: false,
    },
    {
      title: state?.title,
      navigation: `/programmes/programmes-detail/${state?.program_slug}`,
      active: false,
    },
    {
      title: "Reminder Configuration",
      active: true,
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-12 mt-3">
          <h2>Reminder Configuraion</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Do You want To Show This To Active Membership User
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_reminder_for_active_membership_user"
                value={inputs.show_reminder_for_active_membership_user}
                label="Do You want To Show This To Active Membership User"
                onChange={handleChange}
                required
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Upgrade Button Text"
              variant="outlined"
              fullWidth
              name="button_text"
              value={inputs.button_text == undefined ? "" : inputs.button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Upgrade Button Link"
              variant="outlined"
              fullWidth
              type="url"
              name="button_link"
              value={inputs.button_link == undefined ? "" : inputs.button_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Reminder Days"
              variant="outlined"
              fullWidth
              type="number"
              name="reminder_show_days"
              value={inputs.reminder_show_days}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Reminder Box For Client Dashboard</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="reminder_text_for_page"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Timer Top Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="timer_top_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Timer Bottom Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="timer_bottom_description"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
