import React from "react";
import { get_kmb_number } from "src/utils/formatNumber";
import { Tooltip } from "@mui/material";
export default function MemberCardsState({
  count,
  title,
  value,
  ViewAllMembers,
  icon,
  className,
}) {
  console.log(count, title);

  return (
    <div className={`col-12 col-md-6 col-lg-3 ${className ?? ""}`}>
      <div className="stats-card">
        <div className="stats-icon-box-new">
          <Tooltip title={title}>
            <img src={icon} alt="icon" style={{ width: "45px" }} />
          </Tooltip>
        </div>
        <h3>{get_kmb_number(count)}</h3>
        <span className="view-all-link" onClick={() => ViewAllMembers(value)}>
          View All {title} Members
        </span>
      </div>
    </div>
  );
}
