import React from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { add_users_by_csv } from "src/DAL/member/member";
import { useSnackbar } from "notistack";
import GeneralPopUpModel from "./GeneralPopUpModel";
import ChangeBookingStatus from "src/pages/Booking/ChangeBookingStatus";
import { useState } from "react";
import ImportCsvWithOptions from "./ImportCsvWithOptions";

export default function ImportCSVButtonFOrEvents({
  postData,
  setIsLoading,
  button_classes,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedBooking, setSelectedBooking] = useState({});
  const [changeStatus, setChangeStatus] = useState(false);
  const handleChangeStatus = (value) => {
    setChangeStatus(true);
    setSelectedBooking(value);
  };
  const fileChangedHandler = async (e) => {
    const formData = new FormData();
    formData.append("csv", e.target.files[0]);
    Object.keys(postData).forEach(function (key) {
      formData.append(key, postData[key]);
    });
    handleChangeStatus(formData);
  };

  return (
    <>
      <span className={`mb-1`}>
        <input
          color="primary"
          accept=".xlsx,.xls,.csv"
          type="file"
          id="csv-button-file"
          style={{ display: "none" }}
          onChange={(e) => fileChangedHandler(e)}
        />
        <label
          className={`small-contained-button me-2 mt-1 cursor-pointer ${button_classes}`}
          htmlFor="csv-button-file"
        >
          Import Csv &nbsp;&nbsp; <CloudUploadIcon />
        </label>
      </span>
      <GeneralPopUpModel
        open={changeStatus}
        setOpen={setChangeStatus}
        title={"Import Csv"}
        componentToPassDown={
          <ImportCsvWithOptions
            selectedObject={selectedBooking}
            setOpen={setChangeStatus}
          />
        }
      />
    </>
  );
}
