import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { delegate_Url, s3baseUrl } from "src/config/config";
import {
  all_questions_listing_api,
  sale_team_change_status,
  sale_team_on_status,
} from "src/DAL/GeneralQuestions/GeneralQuestions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { replace_created_for } from "src/utils/constant";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";

import { download_csv_file } from "src/utils/convertHtml";
import FilteredChip from "src/components/FilteredChip";
import QuestionAnswerFilter from "../Questions/QuestionAnswerFilter";
import SalesTeamFilter from "./SalesTeamFilter";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { member_login_api } from "src/DAL/member/member";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SalesTeam({ user_type, heading, is_approved }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [answersData, setAnswersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState();
  const [selectedValue, setSelectedValue] = useState({});
  const [selected, setSelected] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [searchText, setSearchText] = useState("");

  const EMPTY_FILTER = {
    delegate_id: null,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [upadatedFilterData, setUpadatedFilterData] = useState(EMPTY_FILTER);
  let filter_name = "request";
  if (user_type == "delegate") {
    filter_name = `delegate_${filter_name}`;
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };
  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleClearFilter = () => {
    localStorage.removeItem(filter_name);
    EMPTY_FILTER.search_text = searchText;
    setFilterData(EMPTY_FILTER);
    getAnswersListing(EMPTY_FILTER);
    setFilterDrawerState(false);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getAnswersListing(data);
    setFilterData(data);
    handleCloseFilterDrawer();
    localStorage.setItem(filter_name, JSON.stringify(data));
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getAnswersListing(filterData);
    setUpadatedFilterData(filterData);
    localStorage.setItem(filter_name, JSON.stringify(filterData));
    handleCloseFilterDrawer();
  };

  const getAnswersListing = async (data) => {
    setIsLoading(true);
    let postData = { ...data };
    if (postData.delegate_id) {
      postData.delegate_id = postData.delegate_id._id;
    }
    postData.is_approved = is_approved;
    console.log(postData, "postDatapostData");
    const result = await sale_team_on_status(
      page,
      rowsPerPage,
      user_type,
      postData
    );

    if (result.code == 200) {
      if (data.search_text) {
        delete data.search_text;
      }
      setUpadatedFilterData(data);
      const team_member = result.sales_team.map((item) => {
        let name = item.first_name + " " + item.last_name;
        let referral_user = "N/A";
        if (!!item.referral_info) {
          referral_user =
            item.referral_info.first_name +
            " " +
            item.referral_info.last_name +
            "(" +
            item.referral_info.email +
            ")";
        }
        return {
          ...item,
          name,
          referral_user,
          contact_no: "+" + item.contact_number,
          table_avatar: {
            src: s3baseUrl + item.image.thumbnail_1,
            alt: item.first_name,
          },
          status: item.status,
        };
      });

      setAnswersData(team_member);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handlePermission = (value) => {
    setOpen(true);
    setSelectedValue(value._id);
  };
  const LoginAsDelegate = async (value) => {
    setIsLoading(true);
    const data = {
      email: value.email,
      user_type: "consultant",
    };
    const result = await member_login_api(data);
    if (result.code === 200) {
      window.open(`${delegate_Url}login-by-admin/${result.token}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleManageProgramAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/sub-team/programme-access/${value._id}`, {
      state: value,
    });
  };
  const ManageAccessDelegates = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/sub-team/manage-access/${value._id}`, {
      state: value,
    });
  };
  const handleChangePassword = (value) => {
    navigate(`/sub-team/change-password/${value._id}`, {
      state: value,
    });
  };
  const handleManageEventAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/sub-team/event-access/${value._id}`, {
      state: value,
    });
  };
  const handleManageEventTemplateAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/sub-team/template-event-access/${value._id}`, {
      state: value,
    });
  };
  const MENU_OPTIONS = [
    {
      label: `${is_approved ? "Disapprove" : "Approve"} team member`,
      icon: "akar-icons:edit",
      handleClick: handlePermission,
    },
  ];
  if (is_approved) {
    MENU_OPTIONS.push(
      {
        label: "Login As Sales Team",
        icon: "ant-design:lock",
        handleClick: LoginAsDelegate,
      },
      {
        label: "Manage Main Portal Program Access",
        icon: "akar-icons:edit",
        handleClick: handleManageProgramAccess,
      },
      {
        label: "Manage Access Settings",
        icon: "akar-icons:edit",
        handleClick: ManageAccessDelegates,
      },
      {
        label: "Change Password",
        icon: "ant-design:lock",
        handleClick: handleChangePassword,
      },
      {
        label: "Manage Event Access",
        icon: "akar-icons:edit",
        handleClick: handleManageEventAccess,
      },
      {
        label: "Manage  Event Template Access",
        icon: "akar-icons:edit",
        handleClick: handleManageEventTemplateAccess,
      }
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeCreatedFor = (value) => {
    setFilterData((values) => ({ ...values, created_for: value }));
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const handleGivePermission = async () => {
    setOpen(false);
    let postData = {
      is_approved: !is_approved,
    };
    const result = await sale_team_change_status(selectedValue, postData);
    if (result.code === 200) {
      setAnswersData((prev) => {
        return prev.filter((data) => data._id !== selectedValue);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    localStorage.removeItem(filter_name);
    setPage(0);
    setPageCount(1);
    setSearchText("");
  }, [is_approved]);

  useEffect(() => {
    setSelected([]);
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem(filter_name);
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setUpadatedFilterData(filter_data);
    getAnswersListing(filter_data);
  }, [rowsPerPage, page, is_approved]);

  const headers = [
    { key: "created_for_string", label: "Questions Created For" },
    { key: "title", label: "Module Title" },
    { key: "user_name", label: "User Name" },
    { key: "user_email", label: "User Email" },

    { key: "answered_date", label: "Answered Date" },
  ];
  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "table_avatar", label: "Profile", type: "thumbnail" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "contact_no", label: "Phone" },
    { id: "referral_user", label: "Team Delegate" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container-fluid">
        <CustomConfirmation
          open={open}
          setOpen={setOpen}
          title={`Are you sure you want to ${
            is_approved ? "disapprove" : "approve"
          } this team?`}
          handleAgree={handleGivePermission}
        />
        <div className="row mt-3">
          <div className="col-12 col-md-6 mb-3">
            {/* <h2>{heading ? heading : "Questions Answers List"}</h2> */}
          </div>
          <div className="col-12 col-md-6 mb-3 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        <FilteredChip
          data={upadatedFilterData}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={answersData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <SalesTeamFilter
            filterData={filterData}
            user_type={user_type}
            handleChange={handleChangeCreatedFor}
            searchSubmitFilter={searchFunction}
            handleChangeOthers={handleChangeOthers}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
