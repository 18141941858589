import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { CircularProgress } from "@mui/material";
import { event_detail_list_api_v1 } from "src/DAL/Events/events";
import { s3baseUrl } from "src/config/config";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import MemberProgramPopover from "../MembersList/memberProgramPopOver";
import PopOverViewMore from "src/components/PopOverViewMore";
import { show_proper_words } from "src/utils/constant";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { add_challenge_exclude_member } from "src/DAL/consultant/consultant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const event_type = ["group", "individual", "excluded", "all"];

const EventDetailList = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isTabLoading, setIsTabLoading] = useState(false);
  const params = useParams();
  const [openExclude, setOpenExclude] = useState(false);
  const [eventGroups, setEventGroups] = useState([]);
  const [allMember, setAllMember] = useState([]);
  const [useInfo, setuseInfo] = useState();
  const [program, setprogram] = useState([]);
  const [event, setEvent] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElEvnet, setAnchorElEvnet] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState([]);
  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;
  const opensEvent = Boolean(anchorElEvnet);
  const idevent = opensEvent ? "simple-popover" : undefined;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    setPage(0);
    setPageCount(1);
    setSearchText("");
    setRowsPerPage(50);
    setSelected([]);
    setTabValue(newValue);
  };

  const DetailEvent = async () => {
    setIsTabLoading(true);
    let tab_value = event_type[tabValue];
    const result = await event_detail_list_api_v1(
      params.event_slug,
      tab_value,
      page,
      rowsPerPage,
      searchText
    );
    if (result.code === 200) {
      setEventGroups(result.event_groups);
      setAllMember(
        result.event_members.map((item) => {
          let find_name = item.first_name + " " + item.last_name;
          return {
            ...item,
            find_name,
            table_avatar: {
              src: s3baseUrl + item?.profile_image,
              alt: item?.first_name,
            },
          };
        })
      );
      setuseInfo(result?.event.title);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
      setIsTabLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsTabLoading(false);
    }
  };

  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };

  const handleClickPopUPEvent = (event, row) => {
    setEvent(row.event);
    setAnchorElEvnet(event.currentTarget);
  };
  const handleClosePopUpEvent = () => {
    setAnchorElEvnet(null);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    DetailEvent(searchText);
  };

  const other_values = {
    custom_pagination: {
      total_count: totalCount,
      rows_per_page: rowsPerPage,
      page: page,
      handleChangePage: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
    custom_search: {
      searchText: searchText,
      setSearchText: setSearchText,
      handleSubmit: searchFunction,
    },
    pageCount: pageCount,
    totalPages: totalPages,
    handleChangePages: handleChangePages,
  };

  const handleSubmitExclude = async () => {
    let members = selected.map((data) => {
      return { _id: data._id };
    });
    let memberIds = members.map((member) => member._id);
    let postData = {
      slug: params.event_slug,
      members: members,
      type: "excluded",
    };
    setOpenExclude(false);
    const result = await add_challenge_exclude_member(postData);
    if (result.code === 200) {
      setAllMember((prev) => {
        return prev.filter((data) => !memberIds.includes(data._id));
      });
      setSelected([]);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = () => {
    setOpenExclude(true);
  };

  useEffect(() => {
    DetailEvent();
  }, [page, rowsPerPage, tabValue]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title" },
    {
      id: "group_by",
      label: "Group By",
      renderData: (row) => {
        return show_proper_words(row.group_by);
      },
    },
    {
      id: "program",
      label: "Programme / Event",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        const firstFiveEvent = row.event?.slice(0, 5);
        return (
          <>
            {firstFiveprograms.length > 0
              ? firstFiveprograms.map((program, i) => {
                  if (program._id !== null) {
                    return (
                      <>
                        <div key={i}>
                          <p>{program?._id?.title + ","}</p>
                        </div>
                        {i == 4 && (
                          <p
                            className="mb-1 mt-1 view-more"
                            aria-describedby={id}
                            variant="contained"
                            onClick={(e) => handleClickPopUP(e, row)}
                          >
                            view more
                          </p>
                        )}
                      </>
                    );
                  }
                })
              : firstFiveEvent.length > 0 &&
                firstFiveEvent.map((event, i) => {
                  if (event._id !== null) {
                    return (
                      <>
                        <div key={i}>
                          <p>{event._id?.title + ","}</p>
                        </div>
                        {i == 4 && (
                          <p
                            className="mb-1 mt-1 view-more"
                            aria-describedby={idevent}
                            variant="contained"
                            onClick={(e) => handleClickPopUPEvent(e, row)}
                          >
                            view more
                          </p>
                        )}
                      </>
                    );
                  }
                })}
          </>
        );
      },
    },
    { id: "group_type", label: "Group Type" },
  ];

  const TABLE_HEAD_MEMBER = [
    { id: "number", label: "#", type: "number" },
    { id: "find_name", label: "Name" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
    },
    { id: "email", label: "Email" },
    {
      id: "program",
      label: "Programme",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        return (
          <div>
            {firstFiveprograms.length > 0 &&
              firstFiveprograms.map((item, i) => {
                if (item._id !== null) {
                  return (
                    <>
                      <div key={i}>
                        {item?._id && <p>{item?._id?.title + ","}</p>}
                      </div>
                      {i == 4 && (
                        <p
                          className="mb-1 mt-1 view-more"
                          aria-describedby={id}
                          variant="contained"
                          onClick={(e) => handleClickPopUP(e, row)}
                        >
                          view more
                        </p>
                      )}
                    </>
                  );
                }
              })}
          </div>
        );
      },
    },
  ];

  const TABLE_HEAD_Event = [
    { id: "number", label: "#", type: "number" },
    { id: "find_name", label: "Name" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
    },
    { id: "email", label: "Email" },
  ];

  let settings = {
    className: "card-with-background",
  };

  let breadCrumbMenu = [
    {
      title: "Calendar",
      navigation: "/calendar-events",
      active: false,
    },
    {
      title: "Events List",
      navigation: "/calendar-events/event-list",
      active: false,
    },
    {
      title: useInfo,
      active: true,
    },
  ];

  const TABS_OPTIONS = [
    {
      title: "Group List",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={eventGroups}
          {...settings}
          pagePagination={true}
        />
      ),
    },
    {
      title: "event individual member",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD_MEMBER}
          data={allMember}
          pagePagination={true}
          {...settings}
          {...other_values}
        />
      ),
    },
    {
      title: "Excluded Member",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD_Event}
          data={allMember}
          pagePagination={true}
          {...settings}
          {...other_values}
        />
      ),
    },
    {
      title: "Event Member",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD_Event}
          selected={selected}
          setSelected={setSelected}
          checkbox_selection={true}
          data={allMember}
          pagePagination={true}
          {...settings}
          {...other_values}
        />
      ),
    },
  ];

  return (
    <>
      <CustomConfirmation
        open={openExclude}
        setOpen={setOpenExclude}
        title={"Are you sure you want to exclude this members ?"}
        handleAgree={handleSubmitExclude}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-3">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>
          <div className="col-lg-8 mb-4">
            <h2>Calendar Event Detail</h2>
          </div>
        </div>
        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
        <MemberProgramPopover
          handleClick={handleClickPopUP}
          handleClose={handleClosePopUp}
          anchorEl={anchorEl}
          id={id}
          navigatePage={true}
          open={opens}
          program={program}
        />
        <PopOverViewMore
          handleClick={handleClickPopUPEvent}
          handleClose={handleClosePopUpEvent}
          anchorEl={anchorElEvnet}
          id={idevent}
          open={opensEvent}
          program={event}
          title="Event"
        />
        {selected.length > 0 && (
          <div class="text-end mt-4" id="fixedbutton">
            <button class="small-contained-button" onClick={handleEdit}>
              Exclude This Members
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default EventDetailList;
