import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  get_comparison_chart_info,
  updateTemplatePagePlanChart,
} from "src/DAL/WebsitePages/WebsitePages";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const column_object = {
  column_heading: "",
  button_text: "",
  button_link: "",
};

const row_object = (columns) => {
  return columns.map(() => ({ value: "", type: "text" }));
};

export default function PlanChart() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { page_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [columnArray, setColumnArray] = useState([]);
  const [numColumns, setNumColumns] = useState(0);
  const [rowArray, setRowArray] = useState([]);

  const handleSetColumns = () => {
    const columns = Array.from({ length: numColumns }, () => ({
      ...column_object,
    }));
    if (numColumns > columnArray.length) {
      setColumnArray((prevColumnArray) => [
        ...prevColumnArray,
        ...columns.slice(prevColumnArray.length),
      ]);
    } else {
      setColumnArray((prevColumnArray) => prevColumnArray.slice(0, numColumns));
    }
    setRowArray((prevRowArray) =>
      prevRowArray.map((row) =>
        numColumns > columnArray.length
          ? [...row, ...row_object(columns).slice(columnArray.length)]
          : row.slice(0, numColumns)
      )
    );
  };

  const handleChangeColumn = (event, index) => {
    const { name, value } = event.target;
    setColumnArray((prevColumnArray) => {
      const newColumnArray = prevColumnArray.map((column, i) =>
        i === index ? { ...column, [name]: value } : column
      );
      return newColumnArray;
    });
  };

  const handleAddColumn = () => {
    setColumnArray((oldArray) => [...oldArray, { ...column_object }]);
    setNumColumns((prevNum) => parseInt(prevNum) + 1);
    setRowArray((prevRowArray) =>
      prevRowArray.map((row) => [...row, { value: "", type: "text" }])
    );
  };

  const handleRemoveColumn = (index) => {
    setColumnArray((prevColumns) =>
      prevColumns.filter((_, colIndex) => colIndex !== index)
    );
    setNumColumns((prevNum) => prevNum - 1);
    setRowArray((prevRowArray) =>
      prevRowArray.map((row) => row.filter((_, colIndex) => colIndex !== index))
    );
  };

  const handleAddRow = () => {
    const newRow = row_object(columnArray);
    setRowArray((oldArray) => [...oldArray, newRow]);
  };

  const handleRemoveRow = (index) => {
    setRowArray((prevRowArray) =>
      prevRowArray.filter((_, rowIndex) => rowIndex !== index)
    );
  };

  const handleChangeRow = (event, rowIndex, colIndex) => {
    const { name, value } = event.target;
    setRowArray((prevRowArray) =>
      prevRowArray.map((row, rIndex) =>
        rIndex === rowIndex
          ? row.map((cell, cIndex) =>
              cIndex === colIndex ? { ...cell, [name]: value } : cell
            )
          : row
      )
    );
  };

  const getPageDetail = async () => {
    setIsLoading(true);
    const result = await get_comparison_chart_info(page_id);
    if (result.code === 200) {
      if (result?.sale_page?.comparison_chart_info) {
        setColumnArray(result?.sale_page?.comparison_chart_info?.column_array);
        setRowArray(result?.sale_page?.comparison_chart_info?.row_array);
        setNumColumns(
          result?.sale_page?.comparison_chart_info?.column_array?.length
        );
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let postData = {
      comparison_chart_info: {
        column_array: columnArray,
        row_array: rowArray,
      },
    };
    const result = await updateTemplatePagePlanChart(postData, page_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };
  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.index === source.index) return;
    setRowArray((prevRowArray) => {
      const newRowArray = [...prevRowArray];
      const [movedItem] = newRowArray.splice(source.index, 1);
      newRowArray.splice(destination.index, 0, movedItem);
      return newRowArray;
    });
  };

  useEffect(() => {
    getPageDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(rowArray, "rowArray");
  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-12 d-flex">
          <IconButton
            className="back-screen-button me-3"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2>Feature Chart Settings</h2>
        </div>
      </div>
      <div className="row mb-3 ">
        <div className="col-12 col-md-4">
          <TextField
            label="Number of Columns"
            type="number"
            variant="outlined"
            fullWidth
            size="small"
            value={numColumns}
            onChange={(e) => setNumColumns(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-2">
          <Button variant="outlined" color="primary" onClick={handleSetColumns}>
            Add Columns
          </Button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        {columnArray.length > 0 && (
          <>
            <div className="row mb-3 setting-card px-0 px-md-3 pb-md-4">
              {columnArray.map((column, colIndex) => {
                return (
                  <>
                    <div key={colIndex} className="col-10 col-md-4 mt-3">
                      <TextField
                        label={`Column ${colIndex + 1} Heading`}
                        size="small"
                        variant="outlined"
                        name="column_heading"
                        value={column.column_heading}
                        onChange={(e) => handleChangeColumn(e, colIndex)}
                      />
                    </div>
                    <div className="col-10 col-md-3 mt-3">
                      <TextField
                        label={`Column ${colIndex + 1} Button Text`}
                        size="small"
                        variant="outlined"
                        name="button_text"
                        value={column.button_text}
                        onChange={(e) => handleChangeColumn(e, colIndex)}
                      />
                    </div>
                    <div className="col-10 col-md-4 mt-3">
                      <TextField
                        label={`Column ${colIndex + 1} Button Link`}
                        size="small"
                        type="url"
                        variant="outlined"
                        name="button_link"
                        value={column.button_link}
                        onChange={(e) => handleChangeColumn(e, colIndex)}
                      />
                    </div>
                    <div className="col-2 col-md-1 text-end mt-3">
                      <AddCircleOutlineIcon
                        onClick={handleAddColumn}
                        className="duplicate-icon me-2"
                      />
                      <RemoveCircleOutlineIcon
                        onClick={() => handleRemoveColumn(colIndex)}
                        style={{ fill: "#ff0000" }}
                        className="duplicate-icon"
                      />
                    </div>
                  </>
                );
              })}
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="parents" type="PARENT">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {rowArray.map((row, rowIndex) => (
                      <Draggable
                        key={rowIndex}
                        draggableId={`parent-${rowIndex}`}
                        index={rowIndex}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="row mb-3 setting-card px-0 px-md-3 pb-md-4"
                            key={rowIndex}
                          >
                            <div
                              className="col-12 text-end pointer_cursor"
                          
                            >
                              <span    className="pointer_cursor"   onClick={() => handleRemoveRow(rowIndex)}>

                              <RemoveCircleOutlineIcon
                                style={{ fill: "#ff0000" }}
                                className="duplicate-icon"
                                />
                                <span>

                              Remove Row
                                </span>
                                </span>
                              <span
                                style={{
                                  marginLeft: "30px",
                                  marginTop: "15px",
                                }}
                                {...provided.dragHandleProps}
                              >
                                <DragIndicatorIcon />
                              </span>
                            </div>
                            <div className="col-12">
                              <div className="row">
                                {row.map((cell, colIndex) => (
                                  <>
                                    <div className="col-12 col-md-3 mt-3">
                                      <FormControl
                                        fullWidth
                                        required
                                        size="small"
                                      >
                                        <InputLabel id="demo-simple-select-label">
                                          {`Column ${colIndex + 1} Row ${
                                            rowIndex + 1
                                          } Type`}
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          name="type"
                                          label={`Column ${colIndex + 1} Row ${
                                            rowIndex + 1
                                          } Type`}
                                          value={cell.type}
                                          onChange={(e) => {
                                            handleChangeRow(
                                              e,
                                              rowIndex,
                                              colIndex
                                            );
                                          }}
                                        >
                                          <MenuItem value="text">Text</MenuItem>
                                          <MenuItem value="show_tick">
                                            Show Tick
                                          </MenuItem>
                                          <MenuItem value="show_cross">
                                            Show Cross
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                    <div className="col-12 col-md-3 mt-3">
                                      {cell.type === "text" ? (
                                        <TextField
                                          label={`Column ${colIndex + 1} Row ${
                                            rowIndex + 1
                                          } Value`}
                                          required
                                          size="small"
                                          name="value"
                                          variant="outlined"
                                          value={cell.value}
                                          onChange={(e) =>
                                            handleChangeRow(
                                              e,
                                              rowIndex,
                                              colIndex
                                            )
                                          }
                                        />
                                      ) : cell.type === "show_tick" ? (
                                        <div className="text-center">✔️</div>
                                      ) : (
                                        <div className="text-center">❌</div>
                                      )}
                                    </div>
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="row mb-3">
              <div
                className="col-12 text-end pointer_cursor"
                onClick={handleAddRow}
              >
                <AddCircleOutlineIcon className="duplicate-icon me-1" /> Add Row
                Data
              </div>
            </div>
          </>
        )}
        <div className="row mb-3">
          <div className="col-12 text-end">
            <button className="small-contained-button" id="fixedbutton">
              {isLoadingForm ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
