import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  lesson_detail_api,
  question_configuration,
} from "src/DAL/Programme/Lessons/Lessons";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function QuestionConfigurationForLesson() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const { programme_slug, lesson_slug } = useParams();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [lessonName, setlessonName] = useState("");
  const [lessonDetailInfo, setLessonsDetailInfo] = useState();
  const [inputs, setInputs] = useState({
    show_question: false,
    is_show_qestion_all_time: false,
    is_show_thank_you_page: true,
    button_text: "",
    question_show_after: 1,
    questions_top_description: "",
    thank_you_page_description: "",
  });
  let programName = lessonDetailInfo?.program[0]?._id?.title;
  let program_slug_navgation = lessonDetailInfo?.program[0]?._id?.program_slug;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      inputs.show_question &&
      inputs.is_show_thank_you_page &&
      !inputs.thank_you_page_description
    ) {
      enqueueSnackbar("Thank You Page Description is required", {
        variant: "error",
      });
      return;
    }

    setIsLoadingForm(true);
    let question_configrations = {
      question_configration: inputs,
    };
    const result = await question_configuration(
      question_configrations,
      params.id
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getLessonsList = async () => {
    setIsLoading(true);
    let result = await lesson_detail_api(params.id);
    if (result.code == 200) {
      if (result.lesson?.question_configration) {
        setInputs(result.lesson?.question_configration);
      }
      setIsLoading(false);
    }
  };
  const handleDetail = async () => {
    setIsLoading(true);
    let result = await lesson_detail_api(params.id);
    if (result.code === 200) {
      setlessonName(result.lesson.title);
      setLessonsDetailInfo(result.lesson);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },
    {
      title: programName,
      navigation: `/programmes/programmes-detail/${program_slug_navgation}`,
      active: false,
    },
    {
      title: "Lessons",
      navigation: `/lessons/${program_slug_navgation}`,
      active: false,
    },
    {
      title: lessonDetailInfo?.title,
      navigation: `/lessons/lesson-detail/${params.id}`,
      active: false,
    },
    {
      title: "Questions Configuration",
      navigation: ``,
      active: true,
    },
  ];
  useEffect(() => {
    if (state?.question_configration) {
      setInputs(state.question_configration);
      window.history.replaceState({}, document.title);
    } else {
      getLessonsList();
    }
  }, []);

  useEffect(() => {
    if (state) {
      setLessonsDetailInfo(state);
    } else {
      handleDetail();
    }
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 mb-3">
          <span className="mt-2">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Questions Configuration</h2>
        </div>
      </div>

      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Show Questions
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="show_question"
              value={inputs.show_question}
              label="Show Questions"
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        {inputs.show_question == true && (
          <>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Button Text"
                variant="outlined"
                fullWidth
                name="button_text"
                value={inputs.button_text}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Is Questions Show All The Time
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_show_qestion_all_time"
                  value={inputs.is_show_qestion_all_time}
                  label="Is Questions Show All The Time "
                  onChange={handleChange}
                  required
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Show Thank You Page
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_show_thank_you_page"
                  value={inputs.is_show_thank_you_page}
                  label="Show Thank You Page"
                  onChange={handleChange}
                >
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </Select>
              </FormControl>
            </div>
            {inputs.is_show_qestion_all_time == false && (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Show Questions After Minutes"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="question_show_after"
                  value={inputs.question_show_after}
                  onChange={handleChange}
                  inputProps={{ min: 1 }}
                  required
                />
              </div>
            )}
          </>
        )}
        <div className="col-12 mt-4">
          <h4>Questions Top Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="questions_top_description"
            editorHeight={320}
          />
        </div>
        {inputs.show_question && inputs.is_show_thank_you_page && (
          <div className="col-12 mt-4">
            <h4>Thank You Page Description *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="thank_you_page_description"
              editorHeight={320}
            />
          </div>
        )}
        <div className="text-end mt-4">
          <button className="small-contained-button">
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
