import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import "react-tagsinput/react-tagsinput.css";
import TagsInput from "react-tagsinput";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Icon } from "@iconify/react";
import TextareaAutosize from "react-textarea-autosize";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import LinkPopup from "./LinkPopup";
import {
  AddUpdateNotificationActionApi,
  EmailTemplateList,
  NotificationActionListingApi,
} from "src/DAL/NotificationAction/NotificationSetting";
import { show_proper_words } from "src/utils/constant";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function WhatsappNotifySetting({
  onCloseDrawer,
  data,
  listing,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [openFormatter, setOpenFormatter] = useState(false);
  const [message, setMessage] = useState("");
  const [openLink, setOpenLink] = useState(false);
  const [array, setarray] = useState([]);
  const [inputs, setInputs] = useState({
    type: "message",
    template: "",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    handleEditorInit();
  };
  const handleGetSelectedText = () => {
    const inputField = document.getElementById("inputField");
    if (inputField) {
      const selectedText = inputField.value.substring(
        inputField.selectionStart,
        inputField.selectionEnd
      );
      return selectedText;
    }
  };
  const handleOnBold = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "**" + seleted_text + "**"
      );
      setMessage(newText);
    }
  };
  const handleOpenLinkPopup = () => {
    setOpenLink(true);
  };
  const handleOnItalic = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "*" + seleted_text + "*"
      );
      setMessage(newText);
    }
  };
  const handleAddLink = (values) => {
    let make_link = ` [${values.display_text}](${values.link_address}) `;
    const inputField = document.getElementById("inputField");
    const start = inputField.selectionStart;
    const end = inputField.selectionEnd;
    // Insert the text at the cursor position
    setMessage(message.slice(0, start) + make_link + message.slice(end));
    // Move the cursor after the inserted text
    inputField.setSelectionRange(
      start + make_link.length,
      start + make_link.length
    );
    setOpenLink(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {
      action: "whatsapp_notification_access",
      action_object: {
        type: inputs.type,
      },
    };
    if (inputs.type == "message") {
      postData.action_object.whatsappMessage = message;
    } else {
      postData.action_object.template = inputs.template;
    }
    const result = await AddUpdateNotificationActionApi(
      data.notification_action_slug,
      postData
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      listing();
      onCloseDrawer();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  function handleEditorInit() {
    setTimeout(() => {
      let containerWarning = document.getElementById("inputField");
      console.log(containerWarning, "containerWarningcontainerWarning");
      if (containerWarning) {
        containerWarning.style.height = "150px";
      }
    }, 100);
  }
  const getNotificationActionListing = async () => {
    const result = await EmailTemplateList();
    if (result.code == 200) {
      setarray(result.templates);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleEditorInit();
  }, []);

  useEffect(() => {
    if (data.whatsapp_notification_info) {
      const { type, whatsappMessage, template } =
        data.whatsapp_notification_info;
      if (whatsappMessage) {
        setMessage(whatsappMessage);
      }

      if (type) {
        let input_obj = { type };
        if (template) {
          input_obj.template = template;
        }
        setInputs(input_obj);
      }
    }
    handleEditorInit();
  }, [data]);

  useEffect(() => {
    getNotificationActionListing();
  }, []);

  return (
    <div className="container-fluid new-memories">
      <GeneralPopUpModel
        open={openLink}
        setOpen={setOpenLink}
        title={"Insert Link"}
        componentToPassDown={<LinkPopup handleAddLink={handleAddLink} />}
      />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 mt-3">
            <FormControl fullWidth required className="mt-3">
              <InputLabel id="demo-simple-select-label">
                Notification type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={inputs?.type}
                name="type"
                label="Member Status"
                onChange={handleChange}
              >
                <MenuItem value="template">Template</MenuItem>;
                <MenuItem value={"message"}>Message</MenuItem>;
              </Select>
            </FormControl>
          </div>
          {inputs.type == "template" ? (
            <div className="col-12 mt-2">
              <FormControl fullWidth required className="mt-3">
                <InputLabel id="demo-simple-select-label">Template</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inputs?.template}
                  name="template"
                  label="Template"
                  onChange={handleChange}
                >
                  {array.length > 0 &&
                    array.map((item) => {
                      console.log(item, "itemitemitemitemitemitem");
                      return (
                        <MenuItem value={item.name}>
                          {show_proper_words(item.name)}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          ) : (
            <div className="col-12 mt-3">
              <div className="w-100">
                <h4>Whatsapp Message*</h4>
                <div
                  className={`chat-formatters-box chat-formatters-border d-flex ${
                    openFormatter ? "chat-formatters-border" : ""
                  }`}
                  style={{
                    backgroundColor: "var(--background-primary-color)",
                    height: "35px",
                  }}
                >
                  <>
                    <div className="formatter-icon" onClick={handleOnBold}>
                      <Icon
                        fontSize="15"
                        style={{ color: "var(--portal-theme-primary)" }}
                        icon="octicon:bold-24"
                      />
                    </div>
                    <div className="formatter-icon" onClick={handleOnItalic}>
                      <Icon
                        fontSize="15"
                        style={{ color: "var(--portal-theme-primary)" }}
                        icon="fe:italic"
                      />
                    </div>
                    <div
                      className="formatter-icon"
                      onClick={handleOpenLinkPopup}
                    >
                      <Icon
                        fontSize="15"
                        style={{ color: "var(--portal-theme-primary)" }}
                        icon="ion:link"
                      />
                    </div>
                  </>
                </div>

                <div className="position-relative parent">
                  <TextareaAutosize
                    id="inputField"
                    className={`chat-send-input chat-formatters-border `}
                    style={{
                      height: "300px !important",
                      backgroundColor: "var(--background-secondary-color)",
                    }}
                    type="text"
                    value={message}
                    maxRows={6}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Write your message..."
                    required
                    autoFocus
                  />
                </div>
              </div>
            </div>
          )}

          <div className="text-end mt-4">
            <button type="submit" className="small-contained-button">
              {isLoading ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
