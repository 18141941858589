import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { convertCurrencyToSign } from "src/utils/constant";
import moment from "moment";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import {
  pending_commission_list_detail,
  pending_commission_list_detail_team_diego,
} from "src/DAL/PendingCommissions/PendingCommissions";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PaidCommissions({ type_paid }) {
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userList, setUserList] = useState([]);
  const [userInfo, setUserInfo] = useState("");
  console.log(state, "state");
  const pendingCommissionList = async () => {
    setIsLoading(true);
    let postData = { type: "paid" };
    if (state.type == "team_diego") {
      postData.user_type = state.type;
      postData.affiliate_id = state.user_id ?? "";
    }
    const result =
      state.type == "team_diego"
        ? await pending_commission_list_detail_team_diego(params?.id, postData)
        : await pending_commission_list_detail(params?.id, postData);

    if (result.code === 200) {
      let data = result.transaction.map((transaction) => {
        let date = "N/A";
        if (transaction.transaction_date) {
          date = moment(transaction.transaction_date).format("DD-MM-YYYY");
        }

        let transaction_title = "";
        if (transaction.transaction_type == "payment_request") {
          transaction.transaction_title = transaction.payment_request_info
            ? "Payment Request ( " +
              transaction.payment_request_info.request_title +
              " )"
            : "( N/A )";
        } else if (transaction.transaction_type == "payment_plan") {
          transaction_title = transaction.sale_page_info
            ? "Sale Page ( " +
              transaction.sale_page_info.sale_page_title +
              " | " +
              transaction.plan_info?.plan_title +
              " )"
            : "( N/A )";
        }

        return {
          ...transaction,
          transaction_title,
          date,
        };
      });
      setUserList(data);
      setUserInfo(result.consultant);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "amount",
      label: "Paid Amount",
      renderData: (row) => {
        return <>{convertCurrencyToSign(row.currency) + row.amount}</>;
      },
    },
    { id: "date", label: "Date" },
  ];

  console.log(userInfo, "userInfouserInfo");

  let breadCrumbMenu = [
    {
      title: type_paid ? "Paid Commissions" : "Pending Commissions",
      navigation: type_paid ? `/paid-commissions` : `/pending-commissions`,
      active: false,
    },

    {
      title:
        state?.type == "team_diego"
          ? "Team Diego"
          : userInfo?.first_name +
            " " +
            userInfo?.last_name +
            " " +
            "(" +
            userInfo?.email +
            ")",
      active: true,
    },
  ];

  useEffect(() => {
    pendingCommissionList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-12 mb-3">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-9 col-sm-12">
            <h2>Paid Commission</h2>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          className="card-with-background"
          is_hide={true}
          pagePagination={true}
        />
      </div>
    </>
  );
}
