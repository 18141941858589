import { CircularProgress, IconButton, Radio } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { memberLevelListing } from "src/DAL/Programme/Programme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import { LevelAccessApi } from "src/DAL/member/member";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageLevelAccess() {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [memberDetail, setMemberDetail] = useState({});

  const getProgramsList = async () => {
    setIsLoading(true);
    const result = await memberLevelListing(params.member_id);
    if (result.code == 200) {
      setMemberDetail(result.member);
      setSelected(result.member.road_map_levels);
      var programArray = result.road_map_levels.map((item) => {
        return {
          ...item,
          table_avatar: {
            src: s3baseUrl + item.icon,
            alt: item.title,
          },
        };
      });

      setProgramsList(programArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);

    let road_map_levels = selected.map((item) => {
      return {
        _id: item._id,
        alias_title: item.alias_title,
        is_default: item.is_default,
      };
    });
    let programObject = {
      road_map_levels: road_map_levels,
    };

    const result = await LevelAccessApi(params.member_id, programObject);
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleClick = (e, row) => {
    setSelected((old) =>
      old.map((item) => {
        if (item._id == row._id) {
          return {
            ...item,
            is_default: !item.is_default,
          };
        } else {
          return {
            ...item,
            is_default: false,
          };
        }
      })
    );
  };

  const TABLE_HEAD = [
    { id: "title", label: "Title", alignRight: false },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    {
      id: "_id",
      label: "Default",
      renderData: (row) => {
        let find = selected.find((select) => select._id == row._id);
        if (find) {
          return (
            <Radio
              checked={Boolean(find.is_default)}
              onClick={(e) => {
                handleClick(e, row);
              }}
            />
          );
        }
      },
    },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];

  useEffect(() => {
    getProgramsList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3 reply-anchor">
          <IconButton
            className="back-screen-button "
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">
            {memberDetail.first_name +
              " " +
              memberDetail.last_name +
              " (" +
              memberDetail.email +
              ")"}
          </span>
        </div>
        <div className="col-6">
          <h2>Manage Level Access</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={programsList}
            selected={selected}
            setSelected={setSelected}
            checkbox_selection={true}
            pagePagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
