import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import {
  detailPaymentPlanApi,
  email_notification_sequence_api,
} from "src/DAL/WebsitePages/paymentPlan";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { get_root_value } from "src/utils/domUtils";
import FullPagePopupForTitle from "src/pages/GeneralSetting/FullPagePopupForTitle";
import EmailNotifySetting from "src/components/GeneralComponents/CalendarList/EmailNotifySetting";
import { NOTIFICATIONS_ARRAY_WITH_SMS } from "src/utils/constant";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import MessageSetting from "src/components/GeneralComponents/CalendarList/MessageSetting";
import WhatsappNotifySetting from "src/components/GeneralComponents/CalendarList/WhatsappNotifySetting";
import PushNotifySetting from "src/components/GeneralComponents/CalendarList/PushNotifySetting";
import SMSSetting from "src/components/GeneralComponents/CalendarList/SMSSetting";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const notification_object = {
  notify_after_unit: "minutes",
  notify_after_time: 30,
  email_notification_info: {},
  email_notification_access: false,
  notification_send_type: [],
};

export default function ManageNotificationSequence() {
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [planDetail, setPlanDetail] = useState({});
  const [selectedObject, setSelectedObject] = useState({});
  const [drawerState, setDrawerState] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [EmailNotification, setEmailNotification] = useState(false);
  const [notificationArray, setNotificationArray] = useState([
    notification_object,
  ]);

  const handleAddNotification = () => {
    setNotificationArray((old_array) => [...old_array, notification_object]);
  };

  const handleRemoveNotification = (index) => {
    const updatedItems = [...notificationArray];
    updatedItems.splice(index, 1);
    setNotificationArray(updatedItems);
  };
  const handleChangeNewNotification = (event, value, index) => {
    let checked = event.target.checked;
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[index] };
    if (checked) {
      temp_element.notification_send_type = [
        ...temp_element.notification_send_type,
        value,
      ];
    } else {
      temp_element.notification_send_type =
        temp_element.notification_send_type.filter(
          (item) => item.name !== value.name
        );
    }
    temp_state[index] = temp_element;
    setNotificationArray(temp_state);
  };

  const handleChangeNotification = (event, index) => {
    const { name, value } = event.target;
    const currentNotification = notificationArray[index];
    let updatedNotification;
    if (name === "notify_after_unit") {
      if (value === "days") {
        updatedNotification = {
          ...currentNotification,
          [name]: value,
          notify_after_time: 1,
        };
      } else if (value === "hours") {
        updatedNotification = {
          ...currentNotification,
          [name]: value,
          notify_after_time: 12,
        };
      } else {
        updatedNotification = {
          ...currentNotification,
          [name]: value,
          notify_after_time: 30,
        };
      }
    } else {
      updatedNotification = {
        ...currentNotification,
        [name]: value,
      };
    }
    let temp_state = [...notificationArray];
    temp_state[index] = updatedNotification;
    setNotificationArray(temp_state);
  };

  const handleOpen = (data, index) => {
    setSelectedObject(data);
    setSelectedIndex(index);
    if (data.name == "email_notification_access") {
      setEmailNotification(true);
    } else {
      setDrawerState(data.name);
    }
  };

  const handleClose = (selected_obj, drawer_state) => {
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[selectedIndex] };
    let selected_types = temp_element.notification_send_type;
    if (selected_types.length > 0) {
      selected_types = selected_types.map((item) => {
        if (item.name == drawer_state) {
          item = selected_obj ? selected_obj : selectedObject;
        }
        return item;
      });
    }
    temp_element.notification_send_type = selected_types;
    temp_state[selectedIndex] = temp_element;

    setNotificationArray(temp_state);
    setDrawerState("");
    setSelectedObject({});
    setEmailNotification(false);
    setSelectedIndex(null);
  };
  const handleClosePop = () => {
    setEmailNotification(false);
  };

  const getPageDetail = async () => {
    const result = await detailPaymentPlanApi(params.plan_id);
    if (result.code == 200) {
      setPlanDetail(result.payment_plan);
      let notifications = result?.payment_plan?.email_notification_sequence;
      if (notifications?.length > 0) {
        setNotificationArray(notifications);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let postData = {
      email_notification_sequence: notificationArray,
    };
    const result = await email_notification_sequence_api(
      postData,
      params.plan_id
    );
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getPageDetail();
  }, []);

  let drawer_data = {
    onCloseDrawer: handleClose,
    data: selectedObject,
    drawerState: drawerState,
  };

  const handle_drawer = () => {
    if (drawerState === "message_notification_access") {
      return {
        label: "Message Notification Setting",
        component: <MessageSetting {...drawer_data} />,
      };
    } else if (drawerState === "whatsapp_notification_access") {
      return {
        label: "Whatsapp Notification Setting",
        component: <WhatsappNotifySetting {...drawer_data} />,
      };
    } else if (drawerState === "push_notification_access") {
      return {
        label: "Push Notification Setting",
        component: <PushNotifySetting {...drawer_data} />,
      };
    } else if (drawerState === "sms_notification_access") {
      return {
        label: "SMS Notification Setting",
        component: <SMSSetting {...drawer_data} />,
      };
    }
  };

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const websitePages = window.location.pathname.includes("/website-pages");

  let breadCrumbMenu = [
    {
      title: websitePages ? "Pages" : "Template Pages",
      navigation: `${websitePages ? `/website-pages` : `/template-pages`}`,
      active: false,
    },
    {
      title: "Payment Plans",
      navigation: `${
        websitePages
          ? `/website-pages/${params?.page_id}/payment-plans`
          : `/template-pages/${params?.page_id}/payment-plans`
      }`,
      active: false,
    },
    {
      title: planDetail?.plan_title,
      active: true,
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-12 col-md-8">
          <h2>Manage Notifications Sequence</h2>
        </div>
        <div className="col-12 col-md-4 text-end mt-2">
          <Button
            variant="outlined"
            onClick={handleAddNotification}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add Notification
          </Button>
        </div>
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            {notificationArray.length > 0 &&
              notificationArray.map((notification, index) => {
                return (
                  <div className="row setting-card mt-3">
                    <div className="col-11 col-md-12 col-lg-7 mt-4">
                      {NOTIFICATIONS_ARRAY_WITH_SMS.map((notify) => {
                        let find_obj = null;
                        if (notification.notification_send_type?.length > 0) {
                          find_obj = notification.notification_send_type.find(
                            (item) => item.name == notify.name
                          );
                        }

                        return (
                          <span className="position-relative color-white">
                            {find_obj && (
                              <Icon
                                fontSize="18"
                                style={{
                                  color: "var(--portal-theme-primary)",
                                }}
                                className="me-2 setIconPosition"
                                icon="circum:edit"
                                onClick={() => handleOpen(find_obj, index)}
                              />
                            )}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleChangeNewNotification(
                                      e,
                                      notify,
                                      index
                                    )
                                  }
                                  checked={Boolean(find_obj)}
                                  className="p-1"
                                />
                              }
                              label={notify.label}
                              className="me-3"
                            />
                          </span>
                        );
                      })}
                    </div>
                    <div className="col-11 col-md-6 col-lg-2 ">
                      <FormControl variant="outlined" className="mt-3">
                        <InputLabel id="demo-simple-select-outlined-label">
                          Notify After
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={notification.notify_after_unit}
                          onChange={(e) => {
                            handleChangeNotification(e, index);
                          }}
                          label="Notify After"
                          name="notify_after_unit"
                          className="inputs-fields svg-color"
                          MenuProps={{
                            classes: {
                              paper: classes.paper,
                            },
                          }}
                          sx={{
                            color: get_root_value("--input-text-color"),
                          }}
                        >
                          <MenuItem value="days">Days</MenuItem>
                          <MenuItem value="hours">Hours</MenuItem>
                          <MenuItem value="minutes">Minutes</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-11 col-md-5 col-lg-2">
                      <TextField
                        className="mt-3"
                        id="outlined-basic"
                        label={`${
                          notification.notify_after_unit == "days"
                            ? "Days"
                            : notification.notify_after_unit == "hours"
                            ? "Hours"
                            : "Minutes"
                        }`}
                        variant="outlined"
                        name="notify_after_time"
                        type="number"
                        value={notification.notify_after_time}
                        required={true}
                        onChange={(e) => {
                          handleChangeNotification(e, index);
                        }}
                        inputProps={{
                          min: 0,
                          max:
                            notification.notify_after_unit == "days"
                              ? 100
                              : notification.notify_after_unit == "hours"
                              ? 24
                              : 60,
                        }}
                      />
                    </div>
                    <div className="col-1 p-0 mt-4">
                      <Tooltip title="Remove">
                        <RemoveCircleOutlineIcon
                          onClick={() => handleRemoveNotification(index)}
                          className="diary-icon-remove"
                        />
                      </Tooltip>
                    </div>
                  </div>
                );
              })}
            <div className="text-end mt-4">
              <button className="small-contained-button">
                {isLoadingForm ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <FullPagePopupForTitle
        open={EmailNotification}
        setOpen={setEmailNotification}
        handleClosePopup={handleClosePop}
        title={selectedObject}
        componentToPassDown={
          <EmailNotifySetting
            onCloseDrawer={handleClose}
            data={selectedObject}
            open={EmailNotification}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={Boolean(drawerState)}
        onCloseDrawer={handleClose}
        pageTitle={handle_drawer()?.label}
        componentToPassDown={handle_drawer()?.component}
      />
    </div>
  );
}
