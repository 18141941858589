import { Icon } from "@iconify/react";
import windowsFilled from "@iconify/icons-ant-design/windows-filled";
// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import { get_root_value } from "src/utils/domUtils";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(2, 0),
  color: get_root_value("--input-text-color"),
  backgroundColor: get_root_value("--sidebars-background-color"),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.warning.dark,
    0
  )} 0%, ${alpha(theme.palette.warning.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

const TOTAL = 172;

export default function AppItemOrders({ programs, title }) {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <AccountTreeIcon />
      </IconWrapperStyle>
      <Typography variant="h3">{programs}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title ? title : "Programme"}
      </Typography>
    </RootStyle>
  );
}
