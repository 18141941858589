import { invokeApi } from "../../bl_libs/invokeApi";

export const bookingListApi = async (page, limit, data) => {
  const requestObj = {
    path: `/admin_users/all_bookings/list?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const bookingListStatApi = async (page, limit, data, search_keyword) => {
  const requestObj = {
    path: `/admin_users/get_bookings_with_stats/list?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const bookingPageList = async (filterName) => {
  const requestObj = {
    path: `/admin_users/booking_pages_and_members?search_text=${
      filterName ? filterName : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const update_booking_status_api = async (id, data) => {
  const requestObj = {
    path: `/api/consultant/update_booking_status/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const consultantSearchListApi = async (search) => {
  const requestObj = {
    path: `/api/consultant/consultant_list_with/search?search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const SalePageSearchListApi = async (search, type) => {
  const requestObj = {
    path: `/api/sale_page/get_template_pages_list/filter?search_text=${
      search ? search : ""
    }&&type_of_template=${type ? type : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantAndPagesSearchListApi = async (search, id) => {
  const requestObj = {
    path: `/admin_users/get_booking_pages_with_consultants?search_text=${
      search ? search : ""
    }&consultant_id=${id ? id : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantSearch = async (search) => {
  const requestObj = {
    path: `/admin_users/users_with_search?search_text=${search ? search : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantSearchNewTransactionRefferal = async (search, type) => {
  const requestObj = {
    path: `/admin_users/refferals_search_with/type?type=${type}&search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Detailbooking = async (id) => {
  const requestObj = {
    path: `/api/consultant/booking/detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const booking_slots_by_delegate_and_date = async (data) => {
  const requestObj = {
    path: `/api/consultant/slots_by_delegate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const consultantUpdateForBooking = async (id, data) => {
  const requestObj = {
    path: `/admin_users/booking/update/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_booking = async (id, data) => {
  const requestObj = {
    path: `/api/consultant/pass_booking/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteBookingApi = async (id) => {
  const requestObj = {
    path: `/api/consultant//booking/delete/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const active_booking_status_api = async (booking_id) => {
  const requestObj = {
    path: `/api/booking_status/active_booking_status`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
