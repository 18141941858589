import React, { useEffect, useState } from "react";
import { Chip, CircularProgress } from "@mui/material";
import { s3baseUrl } from "../../../config/config";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { deleteFeedApi, feedsListing } from "src/DAL/feeds/feeds";
import { feedFeatureApi } from "src/DAL/feeds/feeds";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import { FeedFilter } from "./FeedFilter";
import { get_root_value } from "src/utils/domUtils";
import FilteredChip from "src/components/FilteredChip";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function FeedsList() {
  const { enqueueSnackbar } = useSnackbar();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [feedsData, setFeedsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openFeature, setOpenFeature] = useState(false);
  const [featureFeedDetail, setFeatureFeedDetail] = useState();
  const [featureFeed, setFeatureFeed] = useState("");
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [drawerState, setDrawerState] = useState(false);

  const EMPTY_FILTER = {
    is_cosmos: false,
    is_source: false,
    is_event: false,
    is_all_event: true,
    dynamite_event: [],
  };
  const [inputs, setInputs] = useState(EMPTY_FILTER);
  console.log(inputs, "inputsinputs");
  const [filterUpdated, setFilterUpdated] = useState(EMPTY_FILTER);

  const handleClearFilter = () => {
    setPage(0);
    setPageCount(1);
    getMainFeedListing(EMPTY_FILTER);
    setFilterUpdated(EMPTY_FILTER);
    setInputs(EMPTY_FILTER);
    localStorage.removeItem("feed_list_data");
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMainFeedListing(inputs);
    setFilterUpdated(inputs);
    localStorage.setItem("feed_list_data", JSON.stringify(inputs));
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    setInputs(data);
    setFilterUpdated(data);
    getMainFeedListing(data);
    localStorage.setItem("feed_list_data", JSON.stringify(data));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const getMainFeedListing = async (data) => {
    let postData = { ...data };

    if (postData.is_all_event == false) {
      postData.dynamite_event = postData?.dynamite_event?.map((item) => {
        return item._id;
      });
    }
    if (postData.dynamite_event.length == 0) {
      postData.is_all_event = true;
    }
    setIsLoading(true);
    handleCloseDrawer();
    const result = await feedsListing(page, rowsPerPage, postData);
    if (result.code == 200) {
      let chipData = { ...data };
      if (chipData.is_event === false) {
        delete chipData.is_all_event;
      }

      setFilterUpdated(chipData);
      const feeds = result?.feeds?.map((feed, index) => {
        return {
          ...feed,
          name: feed?.action_info?.name,
          feeds_description:
            feed.description.length > 50
              ? feed.description.substring(0, 50) + "..."
              : feed.description,
          is_pin: feed.is_feature == false ? "PIN" : "UNPIN",
          set_feed_created_for:
            feed.feed_created_for == "general"
              ? "The Source"
              : feed.feed_created_for == "delegate"
              ? "The Cosmos"
              : feed.feed_created_for == "event"
              ? "Event"
              : "",
          table_avatar: {
            src: feed?.action_info?.profile_image
              ? s3baseUrl + feed.action_info?.profile_image
              : "",
            alt: feed.action_info.name,
          },
        };
      });

      setFeedsData(feeds);
      setTotalCount(result.total_feed_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const featureUpdate = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("feed", featureFeedDetail._id);
    if (
      featureFeedDetail.is_feature === false ||
      featureFeedDetail.is_feature === "false" ||
      featureFeedDetail.is_feature === undefined
    ) {
      formData.append("action", "feature");
    } else {
      formData.append("action", "unfeature");
    }
    const result = await feedFeatureApi(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getMainFeedListing(inputs);
      setOpenFeature(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteFeedApi(deleteDoc._id);
    if (result.code === 200) {
      getMainFeedListing(inputs);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem(`feed_list_data`);
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setInputs(filter_data);
    setFilterUpdated(filter_data);
    getMainFeedListing(filter_data);
  }, [rowsPerPage, page]);

  const handleFeatureFeed = (data) => {
    setFeatureFeed(data.is_feature);
    setFeatureFeedDetail(data);
    setOpenFeature(true);
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
    },
    { id: "name", label: "Name", alignRight: false },
    { id: "feeds_description", label: "Description", alignRight: false },
    { id: "set_feed_created_for", label: "Feed For", alignRight: false },
    {
      id: "feed_appear_by",
      label: "Appear by",
      alignRight: false,
      className: "text-capitalize",
    },
    {
      id: "is_pin",
      label: "Pin Feed Top",
      alignRight: false,
      className: "feeds-pin-button",
      handleClick: handleFeatureFeed,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <FeedFilter
            inputs={inputs}
            setInputs={setInputs}
            handleClearFilter={handleClearFilter}
            searchFunction={searchFunction}
          />
        }
      />
      <CustomConfirmation
        openDelete={openFeature}
        setOpenDelete={setOpenFeature}
        title={
          featureFeed === false || featureFeed === "false"
            ? "Are you sure you want to Pin this feed to top?"
            : "Are you sure you want to unPin this feed from top?"
        }
        handleAgree={featureUpdate}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 col-sm-8 mb-3">
            <h2>Feeds</h2>
          </div>
          <div className="col-lg-4 col-sm-4 mb-3 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        <FilteredChip
          data={filterUpdated}
          tempState={inputs}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={feedsData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
}
