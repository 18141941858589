import { invokeApi } from "../../bl_libs/invokeApi";

export const digitalAssetsListingApi = async () => {
  const requestObj = {
    path: `/api/digital_asset/assets_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddAssetDocumentApi = async (data) => {
  const requestObj = {
    path: `/api/digital_asset/add_digitial_asset`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteAssetApi = async (slug) => {
  const requestObj = {
    path: `/api/digital_asset/delete_asset/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const EditAssetApi = async (data, slug) => {
  const requestObj = {
    path: `/api/digital_asset/update_asset/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditAssetScreenApiDetail = async (data) => {
  const requestObj = {
    path: `/api/digital_asset/asset_detail/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const digital_asset_detail_api = async (data) => {
  const requestObj = {
    path: `/api/digital_asset/detail/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AssetsDetailListing = async (id, page, limit, search, data) => {
  const requestObj = {
    path: `/api/digital_asset/asset_detail_list/${id}?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,

    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
