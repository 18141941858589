import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import MemberProgramPopover from "../MembersList/memberProgramPopOver";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function GroupListing({ groups }) {
  const [groupList, setGroupList] = useState([]);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [program, setprogram] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;

  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const MemberValue = () => {
    setIsLoading(true);
    setGroupList(groups);
    setIsLoading(false);
  };

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Name" },
    {
      id: "program",
      label: "Programmes",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        return (
          <div>
            {firstFiveprograms?.map((program, i) => {
              return (
                <>
                  <div key={i}>
                    <p>
                      {program?._id?.title ? program?._id?.title + "," : ""}
                    </p>
                  </div>
                  {i == 4 && (
                    <p
                      className="mb-1 mt-1 view-more"
                      aria-describedby={id}
                      variant="contained"
                      onClick={(e) => handleClickPopUP(e, row)}
                    >
                      view more
                    </p>
                  )}
                </>
              );
            })}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    MemberValue();
  }, [groups]);

  return (
    <>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={groupList}
        className="card-with-background"
        pagePagination={true}
      />
      <MemberProgramPopover
        handleClick={handleClickPopUP}
        handleClose={handleClosePopUp}
        anchorEl={anchorEl}
        id={id}
        open={opens}
        program={program}
        navigatePage={true}
      />
    </>
  );
}
