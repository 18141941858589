import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function AffliateConfirmationPopUp({
  open,
  setOpenDelete,
  handleAgree,
  value,
  setOpen,
  handleChange,
  setAffiliateData,
  affiliateData,
  inputs,
}) {
  const handleCloseDialog = () => {
    setOpen(false);
  };
  // console.log(value.first_name + value.first_name, "value");
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        {
          <DialogTitle>
            {/* {title ? title : "Are you sure you want to take this action?"} */}

            <div
              class="cross-icons"
              onClick={() => {
                setOpen(false);
              }}
            >
              x
            </div>
            <Typography
              className="text-center"
              id="spring-modal-title"
              variant="h6"
              component="h2"
            >
              <h3
                className="affliate-id"
                style={{ fontSize: "22px", marginBottom: "0px" }}
              >
                Change Affiliate ID
              </h3>
            </Typography>
            <div className="row">
              <div className="col-lg-12 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  name="name"
                  disabled
                  required
                  value={inputs.name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-12 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Affiliate Id"
                  variant="outlined"
                  fullWidth
                  name="affiliate_url_name"
                  required
                  value={inputs.affiliate_url_name}
                  onChange={handleChange}
                />
              </div>
            </div>
          </DialogTitle>
        }

        <DialogActions>
          <Button className="model-button-hover" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button
            className="model-button-hover"
            onClick={handleAgree}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* New to solve delete issue  */}
    </>
  );
}

export default AffliateConfirmationPopUp;
