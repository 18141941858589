import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useSnackbar } from "notistack";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  _auto_responded_message,
  delete_auto_responded_message,
} from "src/DAL/AutoRespondedMessage/AutoRespondedMessage";

// import {
//   DeleteLeadStatusHistory,
//   LeadStatusListing,
// } from "src/DAL/member/Member";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { dd_date_format } from "src/utils/constant";
import {
  Users_api,
  active_Users_api,
  historyListingApi,
} from "src/DAL/member/member";
import { s3baseUrl } from "src/config/config";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AcceptedCompletedUser({ challenge }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [first, setfirst] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [leadStatusHistory, setleadStatusHistory] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    LeadStatusList();
  };

  const LeadStatusList = async () => {
    setIsLoading(true);
    const result = await active_Users_api(
      challenge._id,
      page,
      rowsPerPage,
      searchText
    );
    if (result.code == 200) {
      console.log(result, "resultresultresult");

      const data = result.people_doing.map((item) => {
        return {
          ...item,
          table_avatar: {
            src: s3baseUrl + item.profile_image,
            alt: item.first_name,
          },
          title: item.first_name + " " + item.last_name,
          email: item.email,
          is_complete: item.is_complete ? "Yes" : "No",
          change_time: item.challange_completed_date
            ? dd_date_format(item.challange_completed_date)
            : "N/A",
        };
      });
      setleadStatusHistory(data);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setPage(0);
    setPageCount(1);
    setSearchText();
  }, [challenge]);
  useEffect(() => {
    LeadStatusList();
  }, [page, rowsPerPage]);
  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
    },
    {
      id: "title",
      label: "Name",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "is_complete",
      label: "Is Completed",
    },

    {
      id: "change_time",
      label: "Challange Completed Date",
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div style={{ zIndex: "0" }}>
      <div className="container-fluid" style={{ zIndex: "0" }}>
        <div className="row mt-4">
          <div className="col-12 d-flex">
            <div className="set-heading">
              <h4 style={{ color: "#f6bd4b" }}>
                {challenge?.title ? challenge?.title : " "}
              </h4>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-12 ">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={leadStatusHistory}
              className="card-with-background mt-3"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pagePagination={true}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
