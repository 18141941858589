import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  delete_dynamite_event_api,
  duplicate_dynamite_event_api,
  dynamite_events_list_api_v1,
  make_template_dynamite_event_api,
} from "src/DAL/DynamiteEvents/DynamiteEvents";
import { Icon } from "@iconify/react";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import MemberImportFromProgram from "../Members/MemberImportFromProgram";
import { dd_date_format } from "src/utils/constant";
import DynamiteFilter from "./DynamiteFilter";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import FilteredChip from "src/components/FilteredChip";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DynamiteEventsList({ type }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [eventUserSampleFile, setEventUserSampleFile] = useState("");
  const [eventsName, setEventsName] = React.useState(null);
  const [programsSelected, setProgramsSelected] = React.useState(null);
  const [importMemberDrawerState, setImportMemberDrawerState] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const EMPTY_FILTER = {
    action_by: "all",
    action_id: null,
    event_type: type,
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleOpenImportDrawer = (value) => {
    // console.log(value);
    setEventsName(value._id);
    setImportMemberDrawerState(true);
  };
  const handleCloseImportDrawer = () => {
    setImportMemberDrawerState(false);
  };

  const getEventsListing = async (filter_data) => {
    setIsLoading(true);
    let postData = { ...filter_data };

    if (postData.action_id) {
      postData.action_id = filter_data.action_id._id;
    } else {
      postData.action_id = "";
    }

    let search_keyword =
      localStorage.getItem("dynamite_event_search_text") == null
        ? searchText
        : localStorage.getItem("dynamite_event_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    handleCloseFilterDrawer();
    const result = await dynamite_events_list_api_v1(
      page,
      rowsPerPage,
      postData,
      search_keyword
    );
    if (result.code == 200) {
      let chipData = { ...filter_data };
      if (chipData.action_by !== "consultant_user") {
        delete chipData.action_id;
      }
      if (chipData.action_by == "consultant_user") {
        chipData.action_by = "Delegates";
      }
      if (chipData.action_by == "admin_user") {
        chipData.action_by = "Admin";
      }

      setUpdateFilterData(chipData);
      const events = result.dynamite_event.map((event) => {
        let start_date_event = "N/A";
        if (event.start_date) {
          start_date_event = dd_date_format(event.start_date);
        }
        return {
          ...event,
          table_avatar: {
            src: s3baseUrl + event.images.thumbnail_2,
            alt: event.title,
          },
          created_by:
            event?.action_info?.name +
            " " +
            "(" +
            event?.action_info?.email +
            " " +
            "| " +
            event?.action_info?.action_by +
            ")",
          event_status: event.status,
          show_on_list: event.is_show_on_list ? "Yes" : "No",
          start_date_event,
        };
      });

      setEventsData(events);
      setTotalCount(result.total_count);
      setTotalPages(result.total_page);
      setEventUserSampleFile(result.import_event_user_sample_file);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleAgreeDuplicate = (value) => {
    setDeleteDoc(value);
    setOpenDuplicate(true);
  };
  const handleAgreeTemplate = (value) => {
    setDeleteDoc(value);
    setOpenTemplate(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await delete_dynamite_event_api(deleteDoc.event_slug);
    if (result.code === 200) {
      setEventsData((eventsData) => {
        return eventsData.filter((data) => data._id !== deleteDoc._id);
      });
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleDuplicate = async () => {
    setOpenDuplicate(false);
    const result = await duplicate_dynamite_event_api(deleteDoc._id);
    if (result.code === 200) {
      getEventsListing(filterData);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleTemplate = async () => {
    setIsLoading(true);
    setOpenTemplate(false);
    const result = await make_template_dynamite_event_api(deleteDoc._id);
    if (result.code === 200) {
      getEventsListing(filterData);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(`/dynamite-events/edit-event/${value.event_slug}`, {
      state: value,
    });
  };
  const handleChangeStartDate = (value) => {
    // console.log(value, "okokoko value");
    navigate(`/dynamite-events/change-start-date/${value._id}`, {
      state: value,
    });
  };
  const handleChangeExpiryDate = (value) => {
    navigate(`/dynamite-events/change-expiry-date/${value._id}`, {
      state: value,
    });
  };
  const handleChangeLockContent = (value) => {
    navigate(`/dynamite-events/lock-event-content/${value.event_slug}`, {
      state: value,
    });
  };
  const handleChangeEventConfig = (value) => {
    navigate(`/dynamite-events/configuration/${value.event_slug}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/dynamite-events/add-event`);
  };

  const handleChangCategories = (value) => {
    navigate(`/dynamite-event-categories/${value._id}`);
  };

  const handleEvntMembers = (value) => {
    navigate(`/dynamite-events/${value._id}/members`);
  };
  const handleEventDelegate = (value) => {
    navigate(`/dynamite-events/access/${value._id}`);
  };
  const handleEventDelegateTemplate = (value) => {
    navigate(`/dynamite-events/template-access/${value._id}`);
  };

  const handleGeneralEvntMembers = (value) => {
    navigate(`/dynamite-events/${value._id}/general-events`);
  };

  const handleRegisterEvents = () => {
    navigate(`/dynamite-events/register-events`);
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "table_avatar", label: "Image", type: "thumbnail" },
    {
      id: "title",
      label: "Title",
      handleClick: handleGeneralEvntMembers,
      className: "pointer",
    },
    { id: "show_on_list", label: "Show on List" },
    { id: "start_date_event", label: "Start Date" },
    { id: "no_of_days", label: "No of Days" },
    { id: "created_by", label: "Created by" },
    { id: "order", label: "Order" },
    {
      id: "event_status",
      label: "Status",
      type: "row_status",
    },
  ];

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getEventsListing(filterData);
    localStorage.setItem("dynamite_filter_data", JSON.stringify(filterData));
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    getEventsListing(EMPTY_FILTER);
    localStorage.removeItem("dynamite_filter_data");
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    if (data.action_by !== "consultant_user") {
      data.action_id = null;
    }
    setFilterData(data);
    getEventsListing(data);
    localStorage.setItem("dynamite_filter_data", JSON.stringify(data));
  };

  useEffect(() => {
    localStorage.setItem("dynamite_event_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("dynamite_event_search_text", "");
    } else {
      localStorage.setItem("dynamite_event_search_text", searchText);
    }
  }, [searchText]);

  useEffect(() => {
    if (type !== "template") {
      let filter_data = EMPTY_FILTER;
      let find_filter = localStorage.getItem("dynamite_filter_data");
      if (find_filter) {
        filter_data = JSON.parse(find_filter);
      }
      setFilterData(filter_data);
      setUpdateFilterData(filter_data);
      getEventsListing(filter_data);
    } else {
      getEventsListing(EMPTY_FILTER);
    }
  }, [page, rowsPerPage]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Lock Event Content",
      icon: "akar-icons:edit",
      handleClick: handleChangeLockContent,
    },
    {
      label: "Event Timer Configuration",
      icon: "akar-icons:edit",
      handleClick: handleChangeEventConfig,
    },
    {
      label: "Import Member From Program",
      icon: "akar-icons:edit",
      handleClick: handleOpenImportDrawer,
    },
    {
      label: "Change Start Date",
      icon: "akar-icons:edit",
      handleClick: handleChangeStartDate,
    },

    {
      label: "Change Expiry Date",
      icon: "akar-icons:edit",
      handleClick: handleChangeExpiryDate,
    },
    {
      label: "Members",
      icon: "iconoir:profile-circled",
      handleClick: handleEvntMembers,
    },

    {
      label: "Make Event as Template",
      icon: "akar-icons:edit",
      handleClick: handleAgreeTemplate,
    },
    {
      label: "Categories",
      icon: "iconoir:profile-circled",
      handleClick: handleChangCategories,
    },

    {
      label: "Events",
      icon: "iconoir:profile-circled",
      handleClick: handleGeneralEvntMembers,
    },
    {
      label: "Duplicate",
      icon: "akar-icons:edit",
      handleClick: handleAgreeDuplicate,
    },
    {
      label: "Delegate Event Access",
      icon: "iconoir:profile-circled",
      handleClick: handleEventDelegate,
    },
  ];
  if (type === "template") {
    MENU_OPTIONS.splice(4, 5);
  }

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this event?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openDuplicate}
        setOpen={setOpenDuplicate}
        title={"Are you sure you want to duplicate this event?"}
        handleAgree={handleDuplicate}
      />{" "}
      <CustomConfirmation
        open={openTemplate}
        setOpen={setOpenTemplate}
        title={"Are you sure you want to make event as template?"}
        handleAgree={handleTemplate}
      />
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col-lg-12 col-sm-12 text-end ">
            {type !== "template" && (
              <>
                <button
                  className="small-contained-button me-2 mt-1 mb-2"
                  onClick={handleOpenFilterDrawer}
                >
                  Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
                </button>
                <a href={s3baseUrl + eventUserSampleFile}>
                  <button className="small-contained-button me-2 mt-1 mb-2">
                    Event Sample File &nbsp;&nbsp;
                    <Icon icon={arrowCircleDownFill} height={20} />
                  </button>
                </a>
                <button
                  onClick={handleRegisterEvents}
                  className="small-contained-button me-2"
                >
                  Import Events Users
                </button>

                <button
                  onClick={handleNavigate}
                  className={`small-contained-button ${
                    type == "template" ? "mb-3 " : ""
                  }`}
                >
                  {type == "template" ? "Add Template" : "Add Event"}
                </button>
              </>
            )}
          </div>
        </div>
        {type !== "template" && (
          <FilteredChip
            data={updateFilterData}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        )}
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={eventsData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          localSearchName="dynamite_events_list_search"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <DynamiteFilter
            filterData={filterData}
            handleSelectOther={handleSelectOther}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={importMemberDrawerState}
        onOpenDrawer={handleOpenImportDrawer}
        onCloseDrawer={handleCloseImportDrawer}
        pageTitle="Import Members"
        componentToPassDown={
          <MemberImportFromProgram
            setSelectedProgram={setProgramsSelected}
            selectedProgram={programsSelected}
            onCloseDrawer={handleCloseImportDrawer}
            dataList={getEventsListing}
            eventsName={eventsName}
            setEventsName={setEventsName}
          />
        }
      />
    </>
  );
}
