import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Input,
  TextField,
  Tooltip,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import {
  addWebsitePlanPackege,
  addWebsiteTestimonial,
  detailWebsitePlanPackege,
  detailWebsiteTestimonialApi,
  editWebsitePlanPackege,
  editWebsiteTestimonialApi,
} from "src/DAL/WebsitePages/testimonial";
import {
  handleImageExtensions,
  urlPatternValidation,
} from "src/utils/constant";
import { useEffect } from "react";
import {
  page_detail_by_id,
  page_detail_forPackage,
} from "src/DAL/WebsitePages/WebsitePages";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { infoImages } from "src/assets";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const AddOrUpdateWebPagePlanPackage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [file, setProfileImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [moduleData, setModuleData] = useState({});
  const [moduleSizes, setModuleSizes] = useState({});
  const [pageData, setPageData] = useState({});
  const module_actual_name = "testimonial";
  const [formType, setFormType] = useState("ADD");
  const [oldImage, setOldImage] = useState("");
  const [selected, setSelected] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [rowPerPage, setrowPerPage] = useState("");
  const [inputs, setInputs] = useState({
    package_name: "",
    package_description: "",
    status: "true",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getPageDetail = async () => {
    setIsLoading(true);
    const result = await page_detail_by_id(params.page_id);
    if (result.code == 200) {
      handlePageDetail(result.sale_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getPageDetailForPlan = async () => {
    setIsLoading(true);
    const result = await page_detail_forPackage(params.page_id);
    console.log(
      result,
      "getPageDetailForPlangetPageDetailForPlangetPageDetailForPlan"
    );
    if (result.code == 200) {
      var programArray = [];
      result.data.map((item) => {
        item.is_default = false;
        programArray.push(item);
      });
      setPlansList(result.data);
      setrowPerPage(result.data.length);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handlePageDetail = (data) => {
    setPageData(data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {
      package_name: inputs.package_name,
      package_description: inputs.package_description,
      status: inputs.status,
      sale_page: params.page_id,
      package_plans: selected,
    };
    if (formType == "EDIT") {
      postData.order = inputs.order;
    }
    const result =
      formType == "ADD"
        ? await addWebsitePlanPackege(postData)
        : await editWebsitePlanPackege(postData, params.testimonial_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getTestimonialDetailData = async () => {
    setIsLoading(true);
    const result = await detailWebsitePlanPackege(params.testimonial_id);
    if (result.code === 200) {
      setInputs(result.data);
      setSelected(result.data.package_plans);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeType = (event, name, id) => {
    const value = event.target.value;
    setPlansList((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        if (name == "is_default") {
          return { ...obj, [name]: false };
        }
      })
    );
    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        if (name == "is_default") {
          return { ...obj, [name]: false };
        }
      })
    );
  };
  const TABLE_HEAD = [
    {
      id: "Plan ",
      label: (
        <div className="d-flex">
          <span className="tableheadng">Plan</span>
        </div>
      ),
      renderData: (row) => {
        let plan_title;
        if (row.is_plan_free === false) {
          plan_title =
            row.plan_title + "(" + "Paid" + " /" + row.payment_access + ")";
        } else {
          plan_title = row.plan_title + "(" + "free" + ")";
        }
        return (
          <>
            <span>{plan_title}</span>
          </>
        );
      },
    },
    {
      id: "is_default",
      label: (
        <div className="d-flex" style={{ alignItems: "center" }}>
          <span className="tableheadng">Default Plan</span>
          <span>
            <Tooltip title="You can see an option to set any plan as the default in front of each plan. This feature allows you to set one plan as default in a package. The plan you set as the default will be displayed on the sales page, while the remaining plans will be shown on the payment page.">
              <img src={infoImages} className="info-imagess mt-0 ms-2" />
            </Tooltip>
          </span>
        </div>
      ),
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);
        let is_default =
          is_selected && is_selected.is_default
            ? is_selected.is_default
            : row.is_default;

        return (
          <div className="col-12">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_default"
                value={is_default}
                label=""
                onChange={(e) => {
                  handleChangeType(e, "is_default", row._id);
                }}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getPageDetailForPlan();
    if (params && params.testimonial_id) {
      if (state) {
        if (params.page_id) {
          handlePageDetail(state.page_info);
        }
        setFormType("EDIT");
        console.log(state, "---");
        setInputs(state.data);
        setSelected(state.data.package_plans);
      } else {
        getTestimonialDetailData();
        if (params.page_id) {
          getPageDetail();
        }
      }
    } else {
      if (params.page_id) {
        if (state) {
          handlePageDetail(state);
        } else {
          getPageDetail();
        }
      }
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 display-flex mb-4">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">{pageData.sale_page_title}</span>
        </div>
        <div className="col-6">
          <h2>{`${formType === "ADD" ? "Add" : "Edit"} Packege Plan`}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Package Name"
              variant="outlined"
              fullWidth
              required
              name="package_name"
              value={inputs.package_name}
              onChange={handleChange}
            />
          </div>

          {formType !== "ADD" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status"
                required
                onChange={handleChange}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-12 mt-3">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Description "
                multiline
                rows={6}
                name="package_description"
                value={inputs.package_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>

          <div className="col-12 mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={plansList}
              selected={selected}
              setSelected={setSelected}
              checkbox_selection={true}
              pagePagination={false}
              is_hide={true}
              rows_per_page_count={rowPerPage}
              hide_footer_pagination={true}
            />
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddOrUpdateWebPagePlanPackage;
