import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { TextareaAutosize } from "@mui/material";
import { update_transaction_status_api } from "src/DAL/Transaction/transactionApi";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

export default function VerifyNote({
  getTransactionsList,
  verifyTransaction,
  setChangeStatus,
  filterData,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    verification_note: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.verification_note.length == 0) {
      enqueueSnackbar("verification note is not allowed to be empty", {
        variant: "error",
      });
    } else {
      let postData = {
        verification_note: inputs.verification_note,
      };

      const result = await update_transaction_status_api(
        verifyTransaction._id,
        postData
      );

      if (result.code === 200) {
        setChangeStatus(false);
        enqueueSnackbar(result.message, { variant: "success" });
        getTransactionsList(filterData);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    setInputs({
      ...inputs,
      ["verification_note"]: verifyTransaction?.verification_note,
    });
  }, []);

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit}>
        <div className="row notification-statement">
          <div className="col-12 ">
            <p className="tracking_code_heading text-white">Note *</p>

            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="verification_note"
              editorHeight={310}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
