import { invokeApi } from "../../bl_libs/invokeApi";

export const programmeListing = async (id) => {
  const requestObj = {
    path: `/api/program/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programmeListingForDelegate = async (id) => {
  const requestObj = {
    path: `/api/delegate_group/delegates_and_programs_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DelegatesNurtureList = async (Search, type) => {
  const requestObj = {
    path: `/api/consultant/active_consultant_list_for/selection?type=${type}&&search_text=${
      Search ? Search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programmeViewCountIncrease = async (type, data) => {
  const requestObj = {
    path: `/api/program/view_or_watch/count/${data}?type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programmeMemberListing = async (id, page, limit, search) => {
  const requestObj = {
    path: `/api/program/list_program_members/${id}?page=${page}&limit=${limit}&search_text=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const bulkMemberListing = async (page, limit, search) => {
  const requestObj = {
    path: `/api/program/list_members_for_program/selection?page=${page}&limit=${limit}&search_text=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const list_members_for_program_api = async (data) => {
  const requestObj = {
    path: `/api/program/list_members_for_program/selection/v1`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const paymentRequestMemberProgramsTemplateProductListApi = async (
  search
) => {
  const requestObj = {
    path: `/api/member/members_programs_template_product/list?search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberProgrammeListing = async (id) => {
  const requestObj = {
    path: `/api/member/get_client_programs?member_id=${id ? id : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberLevelListing = async (id) => {
  const requestObj = {
    path: `/api/member/detail/with_levels_list/${id ? id : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programmeDelegateListing = async (id) => {
  const requestObj = {
    path: `/api/consultant/program_list_for_delegate/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programmeMainPortalListing = async (id) => {
  const requestObj = {
    path: `/api/consultant/main_portal_program_list_delegate/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const eventDelegateListing = async (id) => {
  const requestObj = {
    path: `/api/consultant/dynamite_event/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const eventTemplateDelegateListing = async (id) => {
  const requestObj = {
    path: `/api/consultant/event_template_list_for_delegate/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const detail_with_call_type = async (id) => {
  const requestObj = {
    path: `/api/consultant/delegate/detail_with_call_type/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const rewardListing = async (data) => {
  const requestObj = {
    path: `/api/rewards/priority`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programmeActiveListing = async (data) => {
  const requestObj = {
    path: `/api/program/active_program_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programmeDetail = async (data) => {
  const requestObj = {
    path: `/api/program/get_program/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programmeDetailReminderapi = async (data) => {
  const requestObj = {
    path: `/api/program/get_program_reminder/configuration/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const productDetai = async (data) => {
  const requestObj = {
    path: `/api/dynamite_product/dynamite_product_detail/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programme_detail_api = async (data) => {
  const requestObj = {
    path: `/api/program/get_program/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const deleteAudioApi = async (data, slug) => {
  const requestObj = {
    path: `/api/program/${slug}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const ReminderConfigapi = async (data, slug) => {
  const requestObj = {
    path: `/api/program/update_program_reminder_configuration/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const measurementConfigapi = async (data, slug) => {
  const requestObj = {
    path: `/api/dynamite_product/update_product/measurements/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_programme_api = async (data) => {
  const requestObj = {
    path: `/api/program`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_programme_bulk_access_api = async (data) => {
  const requestObj = {
    path: `/api/member/remove_program_bulk/access`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_programme_api = async (data, slug) => {
  const requestObj = {
    path: `/api/program/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_programme_config_api = async (data, slug) => {
  const requestObj = {
    path: `/api/program/update_program_tabs_configration/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteProgramme = async (slug) => {
  const requestObj = {
    path: `/api/program/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
// locked programme info

export const locked_program_info_api = async (data, slug) => {
  const requestObj = {
    path: `/api/program/update_locked_program_info/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// program section=================================================

export const programmeSectionList = async (data) => {
  const requestObj = {
    path: `/api/program_section/section_list_by_program/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddProgrammeSectionApi = async (data) => {
  const requestObj = {
    path: `/api/program_section/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditProgrammeSectionApi = async (data, slug) => {
  const requestObj = {
    path: `/api/program_section/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteProgrammeSectionApi = async (slug) => {
  const requestObj = {
    path: `/api/program_section/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
// Automated Group==============================================

export const programmeAutoGroupList = async (data) => {
  const requestObj = {
    path: `/api/group/automated_group_list/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddProgrammeAutoGroupApi = async (data) => {
  const requestObj = {
    path: `/api/group/add_automated_group/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditProgrammeAutoGroupApi = async (data, slug) => {
  const requestObj = {
    path: `/api/group/update_automated_group/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteProgrammeAutoGroupApi = async (slug) => {
  const requestObj = {
    path: `/api/group/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
