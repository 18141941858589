import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { dd_date_format } from "src/utils/constant";
export default function MemberProgramPopover({
  handleClick,
  handleClose,
  anchorEl,
  id,
  open,
  program,
  navigatePage,
}) {
  const navigate = useNavigate();
  const handleChangeProgramDetail = (value) => {
    navigate(`/programmes/programmes-detail/${value._id.program_slug}`, {
      state: value._id,
    });
  };

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ heigth: 100 }}
      >
        <div className="sticky-top card">
          <p className="heading_programs">Programmes</p>
          <span className="icon-container" onClick={() => handleClose()}>
            <CancelOutlinedIcon fontSize="small" />
          </span>
        </div>
        {program?.map((item, index) => {
          return (
            <Tooltip
              key={index}
              title={
                item.expiry_date ? (
                  <div>
                    Start Date: {dd_date_format(item?.purchase_date_time)}
                    <br />
                    Expiry: {dd_date_format(item?.expiry_date)}
                    <br />
                    Status: {item?._id?.status === true ? "Active" : "Inactive"}
                  </div>
                ) : (
                  <div>
                    Start Date: {dd_date_format(item?.purchase_date_time)}
                    <br />
                    No Expiry
                    <br />
                    Status:
                    {item?._id?.status === true ? "Active" : "Inactive"}
                  </div>
                )
              }
              arrow
            >
              <p
                className="mb-1 program-hover"
                onClick={
                  navigatePage ? "" : () => handleChangeProgramDetail(item)
                }
                style={{ padding: "2px 5px 2px 8px" }}
              >
                {item?._id?.title}
              </p>
            </Tooltip>
          );
        })}
      </Popover>
    </div>
  );
}
