import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress, OutlinedInput } from "@mui/material";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  activeSupportTeamListApi,
  activeSupportTeamListApiNew,
  addDepartmentApi,
  department_detail_api,
  editDepartmentApi,
} from "src/DAL/Department/Department";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddOrUpdateDepartment() {
  const navigate = useNavigate();
  const { department_id } = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [supportTeam, setSupportTeam] = useState([]);
  const [supportTeamList, setSupportTeamList] = useState([]);
  const [inputs, setInputs] = useState({
    title: "",
    order: 0,
    status: true,
    created_for: "both",
    member: null,
    short_description: "",
    detailed_description: "",
    created_for_user_type: "all",
  });

  const getSupportTeamList = async () => {
    const result = await activeSupportTeamListApiNew();
    if (result.code === 200) {
      setSupportTeamList(
        result.consultant.map((item) => {
          let find_user_name =
            item.first_name +
            " " +
            item.last_name +
            " (" +
            item.email +
            ")" +
            "/" +
            show_proper_words(item.team_type);
          return {
            ...item,
            find_user_name,
          };
        })
      );
      if (!department_id) {
        if (result.consultant && result.consultant.length > 0) {
          const first_index = result.consultant[0];
          const find_user_name =
            first_index.first_name +
            " " +
            first_index.last_name +
            " (" +
            first_index.email +
            ")" +
            "/" +
            show_proper_words(first_index.team_type);
          setInputs({
            ...inputs,
            ["member"]: {
              ...first_index,
              find_user_name,
            },
          });
        }
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getDepartmentData = async () => {
    let result = await department_detail_api(department_id);
    if (result.code == 200) {
      handleFormat(result.department);
    } else {
      handleNavigate();
    }
  };

  const handleChangeSupportTeam = (value) => {
    setSupportTeam(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let member_array = [];
    if (supportTeam.length > 0) {
      member_array = supportTeam.map((group) => {
        return {
          team_id: group._id,
        };
      });
    }
    let postData = {
      title: inputs.title,
      short_description: inputs.short_description,
      detailed_description: inputs.detailed_description,
      support_team_id: inputs.member._id,
      support_team: member_array,
      status: inputs.status,
      created_for: inputs.created_for,
    };

    if (formType === "EDIT") {
      postData.order = inputs.order;
    }
    if (inputs.created_for == "delegate") {
      postData.created_for_user_type = inputs.created_for_user_type;
    }
    const result =
      formType === "ADD"
        ? await addDepartmentApi(postData)
        : await editDepartmentApi(postData, department_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleNavigate();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/departments`);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeDepartment = (value) => {
    setInputs((values) => ({ ...values, member: value }));
  };

  const handleFormat = (data) => {
    let head_member = data.support_team_id;
    let team_array = [];

    console.log(data.support_team, "support_team");

    if (data.support_team.length > 0) {
      team_array = data?.support_team?.map((team) => {
        console.log(team, "teamteamteam");
        let find_user_name =
          team?._id?.first_name +
          " " +
          team?._id?.last_name +
          " (" +
          team?._id?.email +
          ")" +
          "/" +
          show_proper_words(team?._id?.team_type);
        return {
          ...team._id,
          find_user_name,
        };
      });
    }
    if (head_member) {
      head_member.find_user_name =
        head_member.first_name +
        " " +
        head_member.last_name +
        " (" +
        head_member.email +
        ")" +
        "/" +
        show_proper_words(head_member.team_type);
    }
    setFormType("EDIT");
    setSupportTeam(team_array);
    setInputs({
      ...data,
      member: head_member,
      created_for_user_type: data.created_for_user_type
        ? data.created_for_user_type
        : "all",
    });
    getSupportTeamList();
  };

  useEffect(() => {
    if (department_id) {
      if (state) {
        handleFormat(state);
      } else {
        getDepartmentData();
      }
    } else {
      getSupportTeamList();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={handleNavigate}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Department`}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-6"></div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Head of Department"
              selectedOption={inputs.member}
              setSelectedOption={handleChangeDepartment}
              optionsList={supportTeamList}
              name="find_user_name"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {formType == "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label2">
                Created For *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="created_for"
                value={inputs.created_for}
                label="Created For *"
                onChange={handleChange}
              >
                <MenuItem value="client">Client</MenuItem>
                <MenuItem value="delegate">Mission Control</MenuItem>
                <MenuItem value="both">Both</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.created_for == "delegate" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label2">
                  User Type *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="created_for_user_type"
                  value={inputs.created_for_user_type}
                  label="User Type *"
                  onChange={handleChange}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="inner_circle">Inner Circle</MenuItem>
                  <MenuItem value="delegate">Delegate</MenuItem>
                  <MenuItem value="consultant">Consultant</MenuItem>
                  <MenuItem value="marketing">Markeitng Team</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel=" Support Team"
              selectedOption={supportTeam}
              setSelectedOption={handleChangeSupportTeam}
              optionsList={supportTeamList}
              multiple
              name="find_user_name"
            />
          </div>
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description *"
                multiline
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
              <FormHelperText>Maximum limit 500 characters</FormHelperText>
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <h4>Detail Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detailed_description"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
