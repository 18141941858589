import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function ChangeStatusPopUp({
  open,
  setOpenDelete,
  handleAgree,
  value,
  setOpen,
  handleChange,
  setAffiliateData,
  affiliateData,
  inputs,
}) {
  const handleCloseDialog = () => {
    setOpen(false);
  };
  // console.log(value.first_name + value.first_name, "value");
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        {
          <DialogTitle>
            {/* {title ? title : "Are you sure you want to take this action?"} */}
            <div className="row">
              <div className="col-lg-12 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  fullWidth
                  name="title"
                  disabled
                  required
                  value={inputs.title}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-12 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Sale Type*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="section_status"
                    label="Sale Type"
                    required
                    value={inputs.section_status}
                    onChange={handleChange}
                  >
                    <MenuItem value="free">Free</MenuItem>
                    <MenuItem value="paid">Paid</MenuItem>
                    <MenuItem value="normal">Normal</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </DialogTitle>
        }
        <DialogActions>
          <Button className="model-button-hover" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button
            className="model-button-hover"
            onClick={handleAgree}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* New to solve delete issue  */}
    </>
  );
}

export default ChangeStatusPopUp;
