import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import DynamiteEventsList from "./DynamiteEventsList";
// ----------------------------------------------------------------------

export default function DynamiteEventsMain() {
  const { state } = useLocation();

  const [tabValue, setTabValue] = useState(0);
  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    sessionStorage.setItem("tab", newValue);
  };

  const TABS_OPTIONS = [
    {
      title: "General Events",
      component: <DynamiteEventsList type="general" />,
    },
    {
      title: "Template Events",
      component: <DynamiteEventsList type="template" />,
    },
  ];
  useEffect(() => {
    let tabValue = sessionStorage.getItem("tab");
    if (tabValue == "1") {
      setTabValue(1);
    } else {
      setTabValue(0);
    }
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-lg-6 col-sm-12">
            <h2>Dynamite Events</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <MUICustomTabs
              data={TABS_OPTIONS}
              value={tabValue}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}
