import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import {
  programme_list_with_plan_api,
  update_poduct_for_plan_api,
  update_porgrams_for_plan_api,
} from "src/DAL/WebsitePages/paymentPlan";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ProductAccessPaymentPlan() {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [planDetail, setPlanDetail] = useState({});
  const [isAllFiltered, setIsAllFiltered] = useState(false);

  const getProgramsList = async () => {
    const result = await programme_list_with_plan_api(params.plan_id);
    if (result.code == 200) {
      setPlanDetail(result.payment_plan);
      let selected_programs = [];
      result.payment_plan.dynamite_products.map((prog) => {
        let fild;
        selected_programs.push({
          ...prog,
          _id: prog.product_id,
          already_exist: true,
        });
      });
      if (
        result.payment_plan.dynamite_products.length ==
        result.dynamite_products.length
      ) {
        setIsAllFiltered(true);
      }
      setSelected(selected_programs);
      var programArray = [];
      result.dynamite_products.map((item) => {
        let is_exist = result.payment_plan.dynamite_products.find(
          (object) => object.product_id == item._id
        );
        if (is_exist) {
          item.quantity = is_exist.quantity;
        }
        item.table_avatar = {
          src: s3baseUrl + item.image[0].image,
          alt: item.name,
        };
        item.is_exist = Boolean(is_exist);

        programArray.push(item);
      });
      setProgramsList(programArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    let dynamite_products = [];
    programsList.map((item) => {
      if (item.is_exist) {
        var program_object = {
          product_id: item._id,
          name: item.name,
          quantity: item.quantity,
        };
        dynamite_products.push(program_object);
      }
    });

    let programObject = {
      dynamite_products: dynamite_products,
    };

    const result = await update_poduct_for_plan_api(
      params.plan_id,
      programObject
    );
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event, name) => {
    const value = event.target.value;
    const program_id = event.target.name;

    setProgramsList((data) =>
      data.map((obj) => {
        if (obj._id === program_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );

    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === program_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };

  const handleClick = (e, row, index) => {
    let find = selected.find((item) => item._id == row._id);
    setProgramsList((old) =>
      old.map((select) => {
        if (select._id == row._id) {
          select.is_exist = !select.is_exist;
          // select.quantity = 1;
          select.quantity = find
            ? find.quantity
            : select.remaining_quantity <= 0
            ? 0
            : 1;
        }
        return select;
      })
    );
  };

  const handleChangeAll = async (e, row, index) => {
    let is_checked = e.target.checked;
    setIsAllFiltered(is_checked);
    setProgramsList((old) =>
      old.map((obj) => {
        return { ...obj, is_exist: is_checked, quantity: 1 };
      })
    );
  };

  const TABLE_HEAD = [
    {
      id: "is_exist",
      label: (
        <FormControlLabel
          control={<Checkbox checked={isAllFiltered} />}
          label=""
          onChange={handleChangeAll}
          className="px-2"
        />
      ),
      type: "checkbox",
      handleClick: handleClick,
    },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "p-0",
    },
    { id: "name", label: "Product Title", alignRight: false },
    { id: "product_sku", label: "Product Sku", alignRight: false },
    {
      id: "name",
      label: "Product Catagory",
      renderData: (row) => {
        let product_catagory = "N/A";
        if (row.product_category) {
          product_catagory = row.product_category.title;
        }
        return <p className="mb-0">{product_catagory}</p>;
      },
    },
    {
      id: "availableQuantity",
      label: "Available Quantity",
      renderData: (row) => {
        let availableQuantitys = row.remaining_quantity;

        return (
          <p className="mb-0">
            {availableQuantitys <= 0 ? 0 : availableQuantitys}
          </p>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      type: "row_status",    
    },

    {
      id: "no_of_limited_days",
      label: "Quantity",
      renderData: (row) => {
        let is_selected = selected.find((object) => row._id === object._id);
        let quantity;
        if (is_selected && is_selected.already_exist) {
          quantity = is_selected.quantity;
        } else {
          quantity = 1;
        }

        return (
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            required
            style={{ minWidth: "150px" }}
            disabled={Boolean(!row.is_exist)}
            name={row._id}
            className="default_consultant_commission_input"
            value={
              row.is_exist ? row.quantity : row.remaining_quantity <= 0 ? 0 : 1
            }
            sx={{
              "& fieldset": { border: "none" },
            }}
            onChange={(e) => {
              handleChange(e, "quantity");
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    getProgramsList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const websitePages = window.location.pathname.includes("/website-pages");

  let breadCrumbMenu = [
    {
      title: websitePages ? "Pages" : "Template Pages",
      navigation: `${websitePages ? `/website-pages` : `/template-pages`}`,
      active: false,
    },
    {
      title: "Payment Plans",
      navigation: `${
        websitePages
          ? `/website-pages/${params?.page_id}/payment-plans`
          : `/template-pages/${params?.page_id}/payment-plans`
      }`,
      active: false,
    },
    {
      title: planDetail?.plan_title,
      active: true,
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-6">
          <h2>Manage Product Access</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={programsList}
            // selected={selected}
            // setSelected={setSelected}
            // checkbox_selection={true}
            pagePagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
