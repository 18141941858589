import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { call_reminder_setting } from "src/DAL/WebsitePages/WebsitePages";
import { useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { clientSettingApi } from "src/DAL/ClientSetting/ClientSetting";
import { get_root_value } from "src/utils/domUtils";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const notification_object = {
  notify_before_unit: "minutes",
  notify_before_time: 30,
};

export default function CallReminderSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [notificationArray, setNotificationArray] = useState([
    notification_object,
  ]);

  const handleAddNotification = () => {
    setNotificationArray((old_array) => [...old_array, notification_object]);
  };

  const handleRemoveNotification = (index) => {
    const updatedItems = [...notificationArray];
    updatedItems.splice(index, 1);
    setNotificationArray(updatedItems);
  };
  const handleChangeNotification = (event, index) => {
    const { name, value } = event.target;
    const currentNotification = notificationArray[index];
    let updatedNotification;
    if (name === "notify_before_unit") {
      if (value === "days") {
        updatedNotification = {
          ...currentNotification,
          [name]: value,
          notify_before_time: 1,
        };
      } else if (value === "hours") {
        updatedNotification = {
          ...currentNotification,
          [name]: value,
          notify_before_time: 12,
        };
      } else {
        updatedNotification = {
          ...currentNotification,
          [name]: value,
          notify_before_time: 30,
        };
      }
    } else {
      updatedNotification = {
        ...currentNotification,
        [name]: value,
      };
    }
    let temp_state = [...notificationArray];
    temp_state[index] = updatedNotification;
    setNotificationArray(temp_state);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoadingForm(true);
    let postData = {
      call_reminder_setting: notificationArray,
    };

    const result = await call_reminder_setting(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const clientDetail = async () => {
    setIsLoading(true);
    const result = await clientSettingApi();
    if (result.code == 200) {
      if (result?.content_setting?.call_reminder_setting) {
        setNotificationArray(result?.content_setting?.call_reminder_setting);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    clientDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-12 col-md-6">
          <h2>Booking Call Reminder Setting</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        {notificationArray.length > 0 &&
          notificationArray.map((questions, index) => {
            return (
              <>
                <div className="row mb-3 setting-card px-0 px-md-3">
                  <div className="col-11">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <FormControl fullWidth required>
                          <InputLabel id="demo-simple-select-label">
                            Notify Before
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="notify_before_unit"
                            label="Notify Before"
                            value={questions.notify_before_unit}
                            onChange={(e) => {
                              handleChangeNotification(e, index);
                            }}
                          >
                            <MenuItem value="days">Days</MenuItem>
                            <MenuItem value="hours">Hours</MenuItem>
                            <MenuItem value="minutes">Minutes</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-12 col-md-6 mt-3 mt-md-0">
                        <TextField
                          className="w-100"
                          id="outlined-basic"
                          label={`${
                            questions.notify_before_unit == "days"
                              ? "Days"
                              : questions.notify_before_unit == "hours"
                              ? "Hours"
                              : "Minutes"
                          }`}
                          variant="outlined"
                          name="notify_before_time"
                          type="number"
                          value={questions.notify_before_time}
                          required={true}
                          onChange={(e) => {
                            handleChangeNotification(e, index);
                          }}
                          inputProps={{
                            min: 0,
                            max:
                              questions.notify_before_unit == "days"
                                ? 100
                                : questions.notify_before_unit == "hours"
                                ? 24
                                : 60,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-1 duplicate-icon-box">
                    <AddCircleOutlineIcon
                      className="duplicate-icon mb-2"
                      onClick={handleAddNotification}
                    />
                    {notificationArray.length !== 1 && (
                      <RemoveCircleOutlineIcon
                        className="duplicate-icon"
                        onClick={() => handleRemoveNotification(index)}
                      />
                    )}
                  </div>
                </div>
              </>
            );
          })}
        <div className="text-end mt-4">
          <button className="small-contained-button">
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
