import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ConsultantProgrammeList from "./ProgrammeList";
import { consultantGroupDetail } from "src/DAL/consultant/consultant";
import { useParams } from "react-router-dom";
import { CircularProgress, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ConsultantMemberList from "./ConsultantMemberList";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ConsultantGroupDetail() {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [groupPrograms, setGroupPrograms] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [useInfo, setUseInfo] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const groupDetail = async () => {
    setIsLoading(true);
    const result = await consultantGroupDetail(params.id);
    if (result.code == 200) {
      setGroupPrograms(result.group_programs);
      setGroupMembers(result.group_members);
      setUseInfo(result.group);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    groupDetail();
  }, []);

  let get_group_id = localStorage.getItem("consultant_group_id");

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: `/consultant`,
      active: false,
    },
    {
      title: "Delegates Groups",
      navigation: `/consultant/groups/${get_group_id}`,
      active: false,
    },
    {
      title: useInfo?.title,
      active: true,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-12 mb-3">
          <h2>Delegate Group Detail</h2>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label=" Program List" {...a11yProps(0)} />
              <Tab label="Member List" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ConsultantProgrammeList groupPrograms={groupPrograms} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ConsultantMemberList groupMembers={groupMembers} />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
