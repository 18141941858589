import { filter } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import {
  delegatePodsListingApi,
  deleteDelegatePodsApi,
} from "src/DAL/delegatesPods/delegatePods";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DelegatePodsList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getConsultantListing = async () => {
    setIsLoading(true);
    const result = await delegatePodsListingApi();
    if (result.code === 200) {
      const data = result.delegate_room.map((room) => {
        return {
          ...room,
          table_avatar: {
            src: s3baseUrl + room?.room_image?.thumbnail_1,
            alt: room.title,
          },
          roomUser: room.room_user.length,
          object: room,
        };
      });
      setUserList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (value) => {
    navigate(`/delegate-pods/edit-delegate-pod/${value.room_slug}`, {
      state: { editValues: value.object },
    });
  };

  const handleViewDetail = (value) => {
    navigate(`/delegate-pods/pods-detail/${value.room_slug}`, {
      state: value.object,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteDelegatePodsApi(deleteDoc.room_slug);
    if (result.code === 200) {
      getConsultantListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/delegate-pods/add-delegate-pod`);
  };

  useEffect(() => {
    getConsultantListing();
  }, []);

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: " Title",
      renderData: (row) => {
        return (
          <Tooltip title={"View Detail"}>
            <span
              className="mb-0"
              style={{ cursor: "pointer" }}
              onClick={() => handleViewDetail(row)}
            >
              {row.title}
            </span>
          </Tooltip>
        );
      },
    },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    {
      id: "zoom_link",
      label: "Zoom Link",
      renderData: (row) => {
        return (
          <>
            {row.zoom_link == "" ? (
              "N/A"
            ) : (
              <a href={row.zoom_link} target="_blank" className="anchor-style">
                View Zoom Link
              </a>
            )}
          </>
        );
      },
    },
    { id: "roomUser", label: "Room Users" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleViewDetail,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h2>Delegate Pods</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Delegate Pods
            </button>
          </div>
        </div>
        <div className="mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            MENU_OPTIONS={MENU_OPTIONS}
            data={userList}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
