import React from "react";
import { CircularProgress } from "@mui/material";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Events({ tabData }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [programsList, setProgramsList] = useState([]);

  const getProgramsList = () => {
    setIsLoading(true);
    let data = tabData?.map((item) => {
      let date_expiry = "";
      if (item.expiry_date) {
        date_expiry = moment(item.expiry_date).format("DD-MM-YYYY");
      }
      let purchase_date = "";
      if (item.start_date) {
        purchase_date = moment(item.start_date).format("DD-MM-YYYY");
      }
      return {
        ...item,
        table_avatar: {
          src: s3baseUrl + item?.images?.thumbnail_1,
          alt: item?.title,
        },
        purchase_date,
        date_expiry,
        access_type_all: item.access_type ? item.access_type : "N/A",
      };
    });
    setProgramsList(data);
    setIsLoading(false);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title" },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    {
      id: "access_type_all",
      label: "Access Type",
      className: "text-capitalize",
    },
    { id: "purchase_date", label: "Start Date" },
    { id: "date_expiry", label: "Expiry Date" },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];

  useEffect(() => {
    getProgramsList();
  }, [tabData]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={programsList}
          className="card-with-background"
        />
      </div>
    </>
  );
}
