import { invokeApi } from "../../bl_libs/invokeApi";

export const paymentRequestListApi = async (page, limit, data, search) => {
  const requestObj = {
    path: `/api/payment_request/all_payment_request_list?page=${page}&limit=${limit}&type=${
      data.type
    }&payment_status=${data.sortBy}&id=${
      data.id == undefined ? "" : data.id
    }&search=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_payment_request_api = async (data) => {
  const requestObj = {
    path: `/api/payment_request`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const change_status_payment_request_api = async (data) => {
  const requestObj = {
    path: `/api/payment_request/change_one_time_payment_status/paid/canceled`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_payment_request_api = async (data, id) => {
  const requestObj = {
    path: `/api/payment_request/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const EditPaymentRequestApi = async (data, id) => {
  const requestObj = {
    path: `/api/payment_request/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const CommissionConfigurationPaymentRequestApi = async (data, id) => {
  const requestObj = {
    path: `/api/payment_request/commission_info/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const CommissionConfigurationTemplateApi = async (data, id) => {
  const requestObj = {
    path: `/api/payment_template/commission_info/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_list_list_without_team = async (search_text) => {
  const requestObj = {
    path: `/api/consultant/consultant_list_without_team?search_text=${search_text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const agreementConfigurationPaymentRequestApi = async (data, id) => {
  const requestObj = {
    path: `/api/payment_request/agreement_config/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const PaymentRequestDetail = async (id) => {
  const requestObj = {
    path: `/api/payment_request/detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const payment_request_detail_api = async (slug) => {
  const requestObj = {
    path: `/api/payment_request/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const PaymenttemplateDetail = async (id) => {
  const requestObj = {
    path: `/api/payment_template/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const agreementConfigurationPaymentTemplateApi = async (data, id) => {
  const requestObj = {
    path: `/api/payment_template/agreement_config/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeletePaymentRequestApi = async (id) => {
  const requestObj = {
    path: `/api/payment_request/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
