import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { delete_dynamite_event_member } from "src/DAL/DynamiteEvents/EventMembers/EventMembers";
import moment from "moment";
import { programmeMemberListing } from "src/DAL/Programme/Programme";
import {
  add_delegate_event_access_api,
  dynamite_events_delegate_list_api,
} from "src/DAL/DynamiteEvents/DynamiteEvents";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EventBulkDelegateAccess({ reload }) {
  const navigate = useNavigate();
  const { event_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(500);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [membersData, setMembersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [pageCount, setPageCount] = useState(1);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState([]);
  const [eventDetail, setEventDetail] = useState({});
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const getMembersList = async (val) => {
    if (val == "changed") {
    } else {
      setIsLoading(true);
    }

    const result = await dynamite_events_delegate_list_api(
      event_id,
      page,
      rowsPerPage,
      searchText
    );
    if (result.code == 200) {
      setEventDetail(result.dynamite_event);
      const members = result?.consultant_list?.map((member, index) => {
        if (event_id) {
          let exist = member?.dynamite_event?.find(
            (val) => val?._id === event_id
          );
          if (exist) {
            selected.push({
              ...member,
              scheduled_feed: Boolean(exist.scheduled_feed),
            });
          }
        }
        return {
          ...member,
          scheduled_feed: false,
          name:
            member.first_name +
            " " +
            member.last_name +
            " (" +
            member.email +
            ")" +
            " " +
            "/" +
            " " +
            show_proper_words(member.team_type),

          table_avatar: {
            src: s3baseUrl + member.profile_image,
            alt: member.first_name,
          },
          // expiry_date: moment(member.event_expiry_date).format("DD-MM-YYYY"),
          // start_date: moment(member.event_start_date).format("DD-MM-YYYY"),
        };
      });

      setMembersData(members);
      setTotalCount(result.total_records);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handle_update_values = (name, value, id) => {
    setMembersData((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };

  const handleChangeType = (event, name, id) => {
    const value = event.target.value;
    handle_update_values(name, value, id);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    { id: "name", label: " Name" },
    {
      id: "scheduled_feed",
      label: "Enable Scheduled Feed",
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);
        let scheduled_feed = row.scheduled_feed;
        if (is_selected) {
          scheduled_feed = is_selected.scheduled_feed;
        }

        return (
          <div>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="scheduled_feed"
                className="bonus_type_input"
                value={scheduled_feed}
                label=""
                onChange={(e) => {
                  handleChangeType(e, "scheduled_feed", row._id);
                }}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingSubmit(true);

    const selected_data = selected.map((val) => {
      return {
        _id: val._id,
        scheduled_feed: val.scheduled_feed,
      };
    });
    let postData = {
      dynamite_event: event_id,
      consultant: selected_data,
    };

    const result = await add_delegate_event_access_api(postData);
    if (result.code === 200) {
      //   navigate(-1);
      // getMembersList("changed");
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingSubmit(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingSubmit(false);
    }
  };
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMembersList();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (value) => {
    navigate(`/dynamite-events/${event_id}/edit-member/${value._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const formData = new FormData();
    formData.append("member", deleteDoc._id);
    formData.append("dynamite_event", event_id);

    const result = await delete_dynamite_event_member(formData);
    if (result.code === 200) {
      setMembersData((prev) => {
        return prev.filter((data) => data._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    getMembersList();
  }, [rowsPerPage, page, reload]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="">
        <div className="row mb-4">
          <div className="col-12">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(`/dynamite-events`)}
            >
              <ArrowBackIcon />
            </IconButton>
            <span className="sale-page-title">{eventDetail?.title}</span>
          </div>

          <div className="col-12">
            <h2>Delegate Event Access</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              MENU_OPTIONS={MENU_OPTIONS}
              data={membersData}
              selected={selected}
              setSelected={setSelected}
              checkbox_selection={true}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
              rows_per_page_count={rowsPerPage}
            />
          </div>
        </div>
        <div className="text-end mt-4" id="fixedbutton">
          <button className="small-contained-button" onClick={handleSubmit}>
            {isLoadingSubmit ? "Please Wait..." : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
}
