import { CircularProgress, IconButton } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { programmeMainPortalListing } from "src/DAL/Programme/Programme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import { manageMainPortalProgrammeAccess } from "src/DAL/consultant/consultant";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageMainPorTalProgrammeAccess({ type }) {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [memberDetail, setMemberDetail] = useState({});

  console.log(selected, "selectedselectedselectedselected");

  // const getting_expiry_date = (object, delegate) => {
  //   // if program has already expiry date for this user
  //   let purchased_program = delegate.program.find(
  //     (old_program) => old_program._id._id === object._id
  //   );
  //   if (purchased_program?.expiry_date) {
  //     return purchased_program.expiry_date;
  //   }

  //   let result = new Date();
  //   result.setDate(result.getDate() + object.no_of_limited_days);
  //   return result;
  // };

  // const getting_start_date = (object, delegate) => {
  //   // if program has already purchase date for this user
  //   let purchased_program = delegate.program.find(
  //     (old_program) => old_program._id._id === object._id
  //   );
  //   if (purchased_program?.assign_date_time) {
  //     return purchased_program.assign_date_time;
  //   }
  //   let result = new Date();
  //   result.setDate(result.getDate() + object.no_of_start_days);
  //   return result;
  // };

  const getProgramsList = async () => {
    setIsLoading(true);
    const result = await programmeMainPortalListing(params.id);
    if (result.code == 200) {
      setMemberDetail(result.delegate);

      let final_array = [];
      result.delegate.main_portal_program.map((item) => {
        console.log(item._id, "itemitemitemitem");
        if (!!item?._id) {
          final_array.push(item?._id);
        }
      });
      setSelected(final_array);
      var programArray = [];
      result.programs.map((item) => {
        // item.expiry = getting_expiry_date(item, result.delegate);
        // item.purchased_date = getting_start_date(item, result.delegate);
        item.table_avatar = {
          src: s3baseUrl + item.program_images.thumbnail_3,
          alt: item.title,
        };
        // item.is_show_celendar =
        //   item.no_of_limited_days > 0
        //     ? true
        //     : item.program_access_type == "limited"
        //     ? true
        //     : false;
        programArray.push(item);
      });
      setProgramsList(programArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    let programme = [];
    selected.map((item) => {
      var program_object = {
        _id: item?._id,
      };
      // let find_prog = programsList.find((prog) => prog._id == item._id);
      // if (find_prog && find_prog.program_access_type == "limited") {
      //   program_object.expiry_date = moment(find_prog.expiry).format(
      //     "YYYY MM DD"
      //   );
      //   program_object.assign_date_time = moment(
      //     find_prog.purchased_date
      //   ).format("YYYY MM DD");
      // }
      programme.push(program_object);
    });
    let programObject = {
      program: programme,
    };
    const result = await manageMainPortalProgrammeAccess(
      params.id,
      programObject
    );
    if (result.code == 200) {
      navigate(-1);
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  // const handleChangeDate = (date, index, row) => {
  //   let temp_state = [...programsList];
  //   let temp_element = { ...temp_state[index] };
  //   temp_element.expiry = date;
  //   temp_state[index] = temp_element;
  //   setProgramsList(temp_state);
  // };

  // const handleChangeStartDate = (date, index, row) => {
  //   let temp_state = [...programsList];
  //   let temp_element = { ...temp_state[index] };
  //   temp_element.purchased_date = date;
  //   temp_state[index] = temp_element;
  //   setProgramsList(temp_state);
  // };
  const TABLE_HEAD = [
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "p-0",
    },
    { id: "title", label: "Programme Title", alignRight: false },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];

  let breadCrumbMenu = [
    {
      title: type == "sub Team" ? "Sub Team" : "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title: memberDetail
        ? memberDetail.first_name +
          " " +
          memberDetail.last_name +
          " (" +
          memberDetail.email +
          ")"
        : "Manage Main Portal Program Access",
      active: true,
    },
  ];

  useEffect(() => {
    getProgramsList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div>
          <h2>Manage Main Portal Program Access</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={programsList}
            selected={selected}
            setSelected={setSelected}
            checkbox_selection={true}
            pagePagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
