import { invokeApi } from "../../bl_libs/invokeApi";

export const communityListing = async (data) => {
  const requestObj = {
    path: `/api/rewards`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const ManageProductAccess = async (plan_id) => {
  const requestObj = {
    path: `/api/rewards/get_reward_gift_info/${plan_id ? plan_id : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const update_poduct_for_reward_api = async (plan_id, data) => {
  const requestObj = {
    path: `/api/rewards/add_reward_gift_info/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const reward_deatil_api = async (id) => {
  const requestObj = {
    path: `/api/rewards/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editCommunityListing = async (data, id) => {
  const requestObj = {
    path: `/api/rewards/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addCommunityRewardApi = async (data) => {
  const requestObj = {
    path: `/api/rewards`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteCommunityRewardApi = async (id) => {
  const requestObj = {
    path: `/api/rewards/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
