import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { programmeListing } from "src/DAL/Programme/Programme";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { useEffect } from "react";
import { COMMUNITY_LEVELS } from "src/utils/constant";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";
import { countries } from "src/utils/country";

export default function MemberFilter({
  filterData,
  handleChange,
  handleIsDateRange,
  handleChangeOthers,
  searchSubmitFilter,
  handleClearFilter,
  setFilterData,
  handleIsCountry,
}) {
  const [programsList, setProgramsList] = useState([]);
  const [leadStatusList, setLeadStatusList] = useState([]);
  const [delegateList, setDelegateList] = useState([]);
  const getProgrammesList = async () => {
    let result = await programmeListing();
    if (result.code == 200) {
      let programs = result.program.map((program) => {
        return {
          ...program,
          chip_label: program.title,
          chip_value: program._id,
        };
      });
      let leadStatus = result.lead_status.map((item, index) => {
        return {
          ...item,
          chip_label: item.title,
          chip_value: item._id,
        };
      });
      let delegate = result.consultants.map((item) => {
        return {
          ...item,
          chip_label:
            item.first_name + " " + item.last_name + " (" + item.email + ") ",
          chip_value: item._id,
        };
      });
      let no_lead_status = {
        _id: "no_lead_status",
        title: "No Lead Status",
        chip_label: "No Lead Status",
        chip_value: "no_lead_status",
      };
      setLeadStatusList([no_lead_status, ...leadStatus]);
      setProgramsList(programs);
      setDelegateList(delegate);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const handleChangeCommunity = (value) => {
    setFilterData((values) => ({ ...values, community: value }));
  };

  const goalsLevel = [
    {
      name: "None",
      value: "",
    },
    {
      name: "Complete",
      value: "complete",
    },
    {
      name: "Incomplete",
      value: "incomplete",
    },
    {
      name: "Responded",
      value: "responded",
    },
  ];
  const downloadedApp = [
    {
      name: "Downloaded",
      value: "yes",
    },
    {
      name: "Not Downloaded",
      value: "no",
    },
  ];
  const addressVerified = [
    {
      name: "All",
      value: "all",
      chip_label: "",
      chip_value: "all",
    },
    {
      name: "Verified",
      value: "true",
      chip_label: "Verified",
      chip_value: "true",
    },
    {
      name: "Not Verified",
      value: "false",
      chip_label: "Not Verified",
      chip_value: "false",
    },
  ];
  let downloadedApp_array = downloadedApp.map((item) => {
    return {
      ...item,
      chip_label: item.name,
      chip_value: item.value,
    };
  });

  let community_array = COMMUNITY_LEVELS.map((level) => {
    return {
      ...level,
      chip_label: level.title,
      chip_value: level.name,
    };
  });

  let countries_array = countries.map((item) => {
    return {
      ...item,
      chip_label: item.label,
      chip_value: item.code,
    };
  });

  useEffect(() => {
    getProgrammesList();
  }, []);
  return (
    <>
      <div className="container-fluid new-memories">
        <div className="mt-3">
          <MUIAutocomplete
            inputLabel="Programme"
            selectedOption={filterData.program}
            setSelectedOption={(e) => {
              handleChangeOthers(e, "program");
            }}
            optionsList={programsList}
            autoComplete="new-password"
            name="title"
            multiple
          />
        </div>

        {filterData.program.length > 0 && (
          <div className="col-12 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Program Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="program_status"
                value={filterData.program_status}
                label="Program Status"
                onChange={handleChange}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="expired">Expired</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        <div className="mt-3">
          <MUIAutocomplete
            inputLabel="Levels"
            selectedOption={filterData.community}
            setSelectedOption={handleChangeCommunity}
            optionsList={community_array}
            multiple
            name="title"
          />
        </div>
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-simple-select-label">Goal Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterData.goal_statement}
            name="goal_statement"
            label="Goal Status"
            onChange={handleChange}
          >
            {goalsLevel?.map((goal) => {
              return (
                <MenuItem value={goal.value} key={goal.value}>
                  {goal.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-simple-select-label">
            Source Member Ship
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterData.member_user_type}
            name="member_user_type"
            label="Source Member Ship"
            onChange={handleChange}
          >
            <MenuItem value="">None</MenuItem>;
            <MenuItem value="free">Free Source Member Ship</MenuItem>;
            <MenuItem value="paid">Paid Source Member Ship</MenuItem>;
          </Select>
        </FormControl>
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-simple-select-label">Member Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterData.status}
            name="status"
            label="Member Status"
            onChange={handleChange}
          >
            <MenuItem value="">None</MenuItem>;
            <MenuItem value={true}>Active</MenuItem>;
            <MenuItem value={false}>Inactive</MenuItem>;
          </Select>
        </FormControl>
        <div className="mt-3">
          <MUIAutocomplete
            inputLabel="Lead Status"
            selectedOption={filterData.lead_status}
            setSelectedOption={(e) => {
              handleChangeOthers(e, "lead_status");
            }}
            optionsList={leadStatusList}
            autoComplete="new-password"
            name="title"
            multiple
          />
        </div>
        <div className="mt-3">
          <MUIAutocomplete
            inputLabel="Select Delegates"
            selectedOption={filterData.delegates}
            setSelectedOption={(e) => {
              handleChangeOthers(e, "delegates");
            }}
            optionsList={delegateList}
            autoComplete="new-password"
            name="chip_label"
            multiple
          />
        </div>
        <div className="mt-3">
          <MUIAutocomplete
            inputLabel="Select Nurtures"
            selectedOption={filterData.nurtures}
            setSelectedOption={(e) => {
              handleChangeOthers(e, "nurtures");
            }}
            optionsList={delegateList}
            autoComplete="new-password"
            name="chip_label"
            multiple
          />
        </div>
        <div className="col-12 mt-3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Registered Platform
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="member_created_by_platform"
              value={filterData.member_created_by_platform}
              label="Registered Platform"
              onChange={handleChange}
            >
              <MenuItem value="admin_portal">Admin Portal</MenuItem>
              <MenuItem value="website">Website</MenuItem>
              <MenuItem value="ios">IOS</MenuItem>
              <MenuItem value="android">Android</MenuItem>
              <MenuItem value=" Facebook Lead">Facebook Lead</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="mt-3">
          <MUIAutocomplete
            inputLabel="App download status"
            selectedOption={filterData.downloaded_app}
            setSelectedOption={(e) => {
              handleChangeOthers(e, "downloaded_app");
            }}
            optionsList={downloadedApp_array}
            autoComplete="new-password"
            name="name"
          />
        </div>
        <div className="mt-3">
          <MUIAutocomplete
            inputLabel="Address Verified Status"
            selectedOption={filterData.is_address_verified}
            setSelectedOption={(e) => {
              handleChangeOthers(e, "is_address_verified");
            }}
            optionsList={addressVerified}
            autoComplete="new-password"
            name="name"
          />
        </div>
        <div className="col-12 mt-3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Membership Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="member_ship_expiry"
              value={filterData.member_ship_expiry}
              label="Membership Status"
              onChange={handleChange}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="expired">Expired</MenuItem>
              <MenuItem value="not_expired">Active</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 mt-3">
          <Autocomplete
            id="country-select-demo"
            options={countries_array}
            autoHighlight
            value={filterData.country ? filterData.country : null}
            onChange={(event, newValue) => {
              handleIsCountry(newValue);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose  Country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </div>
        {filterData.member_ship_expiry == "not_expired" && (
          <div className="col-12 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Expiry In</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="expiry_in"
                value={filterData.expiry_in}
                label="Expiry In"
                onChange={handleChange}
              >
                <MenuItem value={3}>3 Days</MenuItem>
                <MenuItem value={7}>7 Days</MenuItem>
                <MenuItem value={15}>15 Days</MenuItem>
                <MenuItem value={30}>30 Days</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        {filterData.member_ship_expiry === "not_expired" &&
          filterData.expiry_in === "custom" && (
            <>
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Membership expiry Start Date"
                    name="membership_purchase_expiry_from"
                    value={filterData.membership_purchase_expiry_from}
                    format="YYYY-MM-DD"
                    inputFormat="dd-MM-yyyy"
                    o
                    onChange={(e) =>
                      handleChangeOthers(e, "membership_purchase_expiry_from")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields w-100"
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Membership expiry End Date"
                    name="membership_purchase_expiry_to"
                    value={filterData.membership_purchase_expiry_to}
                    format="YYYY-MM-DD"
                    inputFormat="dd-MM-yyyy"
                    onChange={(e) =>
                      handleChangeOthers(e, "membership_purchase_expiry_to")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields w-100"
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
        <FormControlLabel
          className="mt-2"
          control={
            <Checkbox
              defaultChecked
              checked={filterData.is_date_range}
              onChange={handleIsDateRange}
            />
          }
          label="Search By Register  Date "
        />
        {filterData.is_date_range && (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                name="from_date"
                value={filterData.from_date}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(e) => handleChangeOthers(e, "from_date")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mt-3 inputs-fields"
                    required={true}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                name="to_date"
                value={filterData.to_date}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(e) => handleChangeOthers(e, "to_date")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mt-3 inputs-fields"
                    required={true}
                  />
                )}
              />
            </LocalizationProvider>
          </>
        )}
        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={searchSubmitFilter}
            startIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                  fill={get_root_value("--portal-theme-primary")}
                />
              </svg>
            }
          >
            Filter
          </Button>
        </Box>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          className="mb-3"
          onClick={handleClearFilter}
          startIcon={<Icon icon={roundClearAll} />}
        >
          Clear All
        </Button>
      </div>
    </>
  );
}
