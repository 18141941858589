import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AffiliateTransactions from "./AffiliateTransactions";

export default function CommissionTabs({ value, setValue, tabData }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="ps-4 pe-4">
      <Box sx={{ width: "100%", typography: "body1", marginTop: "10px" }}>
        <div className="mb-3 mt-4">
          <h2>Commission Detail</h2>
        </div>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Credit" value="1" />
              <Tab label="Paid" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <AffiliateTransactions type="credit" tabData={tabData} />
          </TabPanel>
          <TabPanel value="2">
            <AffiliateTransactions type="paid" tabData={tabData} />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
