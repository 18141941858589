import { invokeApi } from "../../bl_libs/invokeApi";

export const digital_assets_categories_api = async (data) => {
  const requestObj = {
    path: `/api/assest_category`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const assest_category_detail_api = async (category_slug) => {
  const requestObj = {
    path: `/api/assest_category/${category_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_digital_assets_category_api = async (data) => {
  const requestObj = {
    path: `/api/assest_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_digital_assets_category_api = async (data, slug) => {
  const requestObj = {
    path: `/api/assest_category/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_digital_assets_api = async (id) => {
  const requestObj = {
    path: `/api/assest_category/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delegates_and_groups_and_assest_categories = async (data) => {
  const requestObj = {
    path: `/api/delegates_room/delegates_and_groups_list/with_categories`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
