import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CalendarDetailGroupList({ groupList }) {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getConsultantListing = async () => {
    setIsLoading(true);
    const data = groupList.map((group) => {
      return {
        ...group,
        id: group._id,
        delegate: group.delegate.length,
      };
    });
    setUserList(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getConsultantListing();
  }, []);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: " Name" },
    { id: "delegate", label: "Delegates" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={userList}
        className="card-with-background"
        pagePagination={true}
      />
    </>
  );
}
