import { invokeApi } from "../../bl_libs/invokeApi";

export const consultantListing = async (data) => {
  const requestObj = {
    path: `/api/consultant`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantListingForSalePage = async (data) => {
  const requestObj = {
    path: `/api/consultant/consultant_list/for_page_access`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const consultantSubscriberListingApi = async (data, page, limit) => {
  const requestObj = {
    path: `/api/event_subscriber/subscriber_list_with_delegate_id_v1?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteConsultantSubscriberApi = async (id) => {
  const requestObj = {
    path: `/api/event_subscriber/delete_event_subscriber/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_page_plan_filter_list_delegate = async (id) => {
  const requestObj = {
    path: `/app/list_page_with/plan`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const consultantNavItems = async (data) => {
  const requestObj = {
    path: `/api/consultant/portal/nav_items`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantDetail = async (id) => {
  const requestObj = {
    path: `/api/consultant/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantListingForSalePageWithId = async (id) => {
  const requestObj = {
    path: `/api/consultant/consultant_list_for_page_access_with_page/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantActiveListing = async (data) => {
  const requestObj = {
    path: `/api/consultant/active_consultant_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delegateQuestionsListing = async (id) => {
  const requestObj = {
    path: `/api/consultant/delegate_90_day_question_answer_list/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delegateAssessmentQuestionsListing = async (id) => {
  const requestObj = {
    path: `/api/delegates_question/delegate_question_and_replay_list/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const tracking_histiry_api = async (id, page, limit) => {
  const requestObj = {
    path: `/admin_users/user_activity_list/${id}?limit=${limit}&page=${page}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const manageConsultantProgrammeAccess = async (id, data) => {
  const requestObj = {
    path: `/api/consultant/add_program_against_consultant/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const manageMainPortalProgrammeAccess = async (id, data) => {
  const requestObj = {
    path: `/api/consultant/manage_main_portal_progarm/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const manageConsultantEventAccess = async (id, data) => {
  const requestObj = {
    path: `/api/consultant/assign_dynamite_event_consultant/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_manage_call_types_api = async (id, data) => {
  const requestObj = {
    path: `/api/consultant/manage_call_types/delegate/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const consultantGroupListingApi = async (id) => {
  const requestObj = {
    path: `/api/consultant/get_consultant_groups/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantPodApi = async (id) => {
  const requestObj = {
    path: `/api/consultant/get_consultant_rooms/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantPodsDetailApi = async (id) => {
  const requestObj = {
    path: `/api/room/detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const transactionListApiNew = async (id, page, rowsPerPage, data) => {
  const requestObj = {
    path: `/api/consultant/delegate_transaction_list_with_filter_and_search/${id}?page=${page}&limit=${rowsPerPage}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delegate_other_transaction_list = async (
  id,
  page,
  limit,
  data
) => {
  const requestObj = {
    path: `/api/consultant/delegate_other_transaction_list_with_filter_and_search/${id}?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const consultantRecordingListing = async (id) => {
  const requestObj = {
    path: `/api/consultant/get_programme_recording/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantGroupDetail = async (slug) => {
  const requestObj = {
    path: `/api/group/detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_exclude_member_api = async (data) => {
  const requestObj = {
    path: `/api/group/exclude/member`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_room_exclude_member = async (data) => {
  const requestObj = {
    path: `/api/room/exclude/member`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_challenge_exclude_member = async (data) => {
  const requestObj = {
    path: `/api/challenge/exclude/member`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const consultantMembersList = async (id) => {
  const requestObj = {
    path: `/api/consultant/get_consultant_members/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantGoalStatementListApi = async (id) => {
  const requestObj = {
    path: `/api/consultant/get_consultant_goal_statement/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantGoalStatementDetailApi = async (id) => {
  const requestObj = {
    path: `/api/goal_statement_question/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddConsultantApi = async (data) => {
  const requestObj = {
    path: `/api/consultant`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const consultantChangePasswordApi = async (data) => {
  const requestObj = {
    path: `/api/consultant/consultant_change_password`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditConsultantApi = async (data, id) => {
  const requestObj = {
    path: `/api/consultant/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const consultant_detail_api = async (id) => {
  const requestObj = {
    path: `/api/consultant/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const consultant_detail_with_page_api = async (id) => {
  const requestObj = {
    path: `/api/consultant/consultant_detail_with_page/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteConsultantApi = async (id) => {
  const requestObj = {
    path: `/api/consultant/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const syncCommissionConsultantApi = async (id) => {
  const requestObj = {
    path: `/app/delegate_commission_sink/specific/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteGoalStatementQuestionApi = async (id) => {
  const requestObj = {
    path: `/api/goal_statement_question/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteConsultantGroupApi = async (slug) => {
  const requestObj = {
    path: `/api/group/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteConsultantRecordingApi = async (id) => {
  const requestObj = {
    path: `/api/program_recording/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteRoomUserApi = async (slug, id) => {
  const requestObj = {
    path: `/api/room/${slug}/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const manage_template_access_api = async (id, data) => {
  const requestObj = {
    path: `/api/consultant/manage_template_access_for_consultant/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const member_listing_by_consultant = async (page, limit, search, id) => {
  const requestObj = {
    path: `/api/consultant/member_list_with_consultasnt_id/${id}?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const all_group_members_api = async (
  slug,
  page,
  limit,
  search,
  type
) => {
  const requestObj = {
    path: `/api/group/all_group_members/${slug}?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }&type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const consultant_listing_with_pagination = async (
  page,
  limit,
  search,
  data
) => {
  const requestObj = {
    path: `/api/consultant/consultant_list/with_pagination_for_admin?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const consultant_listing_support_team_with_pagination = async (
  page,
  limit,
  search,
  data
) => {
  const requestObj = {
    path: `/api/consultant/support_team_list/with_pagination_for_admin?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const consultant_listing_with_filter = async (
  page,
  limit,
  search,
  data
) => {
  const requestObj = {
    path: `/api/consultant/consultant_list/with_pagination_for_admin/v1?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const consultant_listing_with_filter_new = async (
  page,
  limit,
  search,
  data
) => {
  const requestObj = {
    path: `/api/consultant/list_dynamite_lifestyle_team/type?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const consultant_list_for_commission_api = async (
  page,
  limit,
  search,
  data
) => {
  const requestObj = {
    path: `/api/consultant/list/with_pagination_for_commission?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const event_listing_by_consultant = async (start_date, end_date, id) => {
  const requestObj = {
    path: `/api/consultant/get_consultant_events/${id}?start_date=${start_date}&end_date=${end_date}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const commission_sync = async () => {
  const requestObj = {
    path: `/app/delegate_commission_sink`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const change_nurture_to_seld = async (data) => {
  const requestObj = {
    path: `/api/consultant/change_nurture_to_self`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const consultant_list_with_search = async (search_text) => {
  const requestObj = {
    path: `/api/consultant/consultant_list_with/search?search_text=${search_text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_multiple_transaction = async (data) => {
  const requestObj = {
    path: `/api/affiliate_transaction/add_multiple/transaction`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
