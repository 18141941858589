import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import {
  addNinetyDayQuestionApi,
  getNinetyDayQuestionApi,
} from "src/DAL/NinetyDayQuestion/NinetyDayQuestion";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function NinetyDayQuestion() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = React.useState({
    rinse_and_repeat_question_description: "",
    rinse_and_repeat_question: "",
    create_winning_formula_question: "",
    create_winning_formula_question_description: "",
    take_action_question_description: "",
    take_action_question: "",
    analyse_your_activities_question_description: "",
    analyse_your_activities_question: "",
    self_image_attainment_question_description: "",
    self_image_attainment_question: "",
    bullet_proof_attitide_question_description: "",
    bullet_proof_attitide_question: "",
    list_habit_or_belife_question_description: "",
    list_habit_or_belife_question: "",
    list_achive_goal_question_description: "",
    list_achive_goal_question: "",
    achive_goal_question_description: "",
    achive_goal_question: "",
    aim_question_description: "",
    aim_question: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getQuestion = async () => {
    setIsLoading(true);
    const result = await getNinetyDayQuestionApi();
    if (result.code == 200) {
      delete result.Questions._id;
      setIsLoading(false);
      setInputs(result.Questions);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    const result = await addNinetyDayQuestionApi(inputs);
    if (result.code == 200) {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      getQuestion();
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getQuestion();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <h2>90 day Vision Add Questions</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Rinse And Repeat Question Statement"
            variant="outlined"
            required
            fullWidth
            name="question1"
            value={inputs.rinse_and_repeat_question}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Create Winning Formula Question Statement"
            variant="outlined"
            required
            fullWidth
            name="question2"
            value={inputs.create_winning_formula_question}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Take Action Question Statement"
            variant="outlined"
            required
            fullWidth
            name="question3"
            value={inputs.take_action_question}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Analyze Your Activities Question Statement "
            variant="outlined"
            required
            fullWidth
            name="question4"
            value={inputs.analyse_your_activities_question}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Self Image Attainment Question Statement "
            variant="outlined"
            required
            fullWidth
            name="question5"
            value={inputs.self_image_attainment_question}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Bullet Proof Attitude Question Statement"
            variant="outlined"
            required
            fullWidth
            name="question6"
            value={inputs.bullet_proof_attitide_question}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="List Habit or Belief Question Statement"
            variant="outlined"
            required
            fullWidth
            name="question7"
            value={inputs.list_habit_or_belife_question}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="List Achieve Goal Question Statement"
            variant="outlined"
            required
            fullWidth
            name="question8"
            value={inputs.list_achive_goal_question}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="List Achieve Goal Question Statement"
            variant="outlined"
            required
            fullWidth
            name="question9"
            value={inputs.achive_goal_question}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Aim Question Statement"
            variant="outlined"
            required
            fullWidth
            name="question10"
            value={inputs.aim_question}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>Rinse and Repeat Question Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="rinse_and_repeat_question_description"
            editorHeight={320}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>Create a Winning Formula Question Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="create_winning_formula_question_description"
            editorHeight={320}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>Take action Question Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="take_action_question_description"
            editorHeight={320}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>Analyse your activities Question Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="analyse_your_activities_question_description"
            editorHeight={320}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>
            Describe the self image of this attainment Question Description *
          </h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="self_image_attainment_question_description"
            editorHeight={320}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>Create a bullet proof attitude Question Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="bullet_proof_attitide_question_description"
            editorHeight={320}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>List Habit or Belief Question Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="list_habit_or_belife_question_description"
            editorHeight={320}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>List Achieve Goal Question Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="list_achive_goal_question_description"
            editorHeight={320}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>Achieve Goal Question Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="achive_goal_question_description"
            editorHeight={320}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>Aim Question Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="aim_question_description"
            editorHeight={320}
          />
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
