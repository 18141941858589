import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ConsultantGoalMemberList({ goalMembers }) {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getConsultantListing = async () => {
    setIsLoading(true);
    const data = goalMembers?.map((members) => {
      return {
        ...members,
        name: members?.first_name,
        table_avatar: {
          src: s3baseUrl + members?.profile_image,
          alt: members?.first_name,
        },
        image: members.profile_image,
      };
    });
    setUserList(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getConsultantListing();
  }, []);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "name", label: " Name" },
    { id: "table_avatar", label: "Profile", type: "thumbnail" },
    { id: "email", label: "Email " },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={userList}
        className="card-with-background"
        pagePagination={true}
      />
    </>
  );
}
