import React from "react";
import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Pagination,
} from "@mui/material";
import SearchNotFound from "src/components/NotFound/SearchNotFound";
import Label from "src/components/Label";
import { UserListHead, UserListToolbar } from "src/components/_dashboard/user";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import {
  DeleteStripeListApi,
  StripeListApi,
} from "src/DAL/StripeProduct/stripeProductApi";
import moment from "moment";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import StripeProductFilter from "./StripeProductFilter";
import FilteredChip from "src/components/FilteredChip";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false },
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: " Name", alignRight: false },
  { id: "action", label: "Action By", alignRight: false },
  { id: "stripeId", label: "Stripe Product Id", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.stripe_id.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.createdAt.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function StripeProductsList() {
  const navigate = useNavigate();
  const params = useLocation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);

  const EMPTY_FILTER = {
    type: "all",
    id: null,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getStripeProductListing(filterData);
    setUpdateFilterData(filterData);
    localStorage.setItem("Stripe_product_data", JSON.stringify(filterData));
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    if (data.type !== "consultant") {
      data.id = null;
    }
    setUpdateFilterData(data);
    setFilterData(data);
    getStripeProductListing(data);
    localStorage.setItem("Stripe_product_data", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    getStripeProductListing(EMPTY_FILTER);
    localStorage.removeItem("Stripe_product_data");
  };

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const getStripeProductListing = async (filter_data) => {
    setIsLoading(true);
    let postData = { ...filter_data };

    if (postData.id) {
      postData.sale_page = filter_data.id._id;
    } else {
      postData.sale_page = "";
    }

    handleCloseFilterDrawer();
    setIsLoading(true);
    const result = await StripeListApi(page, rowsPerPage, postData);
    if (result.code === 200) {
      let chipData = { ...filter_data };
      if (chipData.type !== "consultant") {
        delete chipData.id;
      }
      setUpdateFilterData(chipData);
      setIsLoading(false);
      setTotalCount(result.total_product_count);
      const data = result.product.map((product, index) => {
        return {
          ...product,
          id: product._id,
          name: product?.name,
          actionBy: product?.action_info,
          stripe_id: product?.stripe_product_id,
          createdAt: product?.createdAt,
          product_slug: product?.product_slug,
          description: product?.description,
          show_on_consultant: product?.show_on_consultant,
          StripeProductObject: product,
          count: index + 1 + rowsPerPage * page,
        };
      });
      setUserList(data);
      setIsLoading(false);
      setTotalPages(result.total_pages);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleEdit = (value) => {
    navigate(`/stripe-products/edit-product/${value.product_slug}`, {
      state: value,
    });
  };

  const handleChangePassword = (value) => {
    navigate(`/consultant/change-password/${value.id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteStripeListApi(deleteDoc.product_slug);
    if (result.code === 200) {
      getStripeProductListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleNavigate = () => {
    navigate(`/stripe-products/add-product`);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("Stripe_product_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getStripeProductListing(filter_data);
  }, [rowsPerPage, page]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const MENU_OPTIONS1 = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Change Password",
      icon: "ant-design:lock",
      handleClick: handleChangePassword,
    },
  ];
  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h2>Stripe Products</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>

            <button onClick={handleNavigate} className="small-contained-button">
              Add Product
            </button>
          </div>
        </div>
        <FilteredChip
          data={updateFilterData}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <Card>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column align-items-start">
              <TablePagination
                rowsPerPageOptions={[5, 50, 100, 150]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Pagination
                count={totalPages + 1}
                page={pageCount}
                defaultPage={0}
                onChange={handleChangePages}
                className="pagination-style"
              />
            </div>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </div>

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
              />
              <TableBody>
                {filteredUsers.map((row, i) => {
                  const {
                    id,
                    name,
                    stripe_id,
                    createdAt,
                    status,
                    actionBy,
                    type,
                  } = row;
                  const isItemSelected = selected.indexOf(name) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell>
                        {type === "team" ? (
                          <CustomPopoverSection
                            menu={MENU_OPTIONS1}
                            data={row}
                          />
                        ) : (
                          <CustomPopoverSection
                            menu={MENU_OPTIONS}
                            data={row}
                          />
                        )}
                      </TableCell>
                      <TableCell align="left">{i + 1}</TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle2">
                          {htmlDecode(name)}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {htmlDecode(
                          actionBy?.type == "admin_user"
                            ? "Admin" + " (" + actionBy?.name + ")"
                            : actionBy?.type == "consultant_user"
                            ? "Delegate" + " (" + actionBy?.name + ")"
                            : "N/A"
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {Capitalize(htmlDecode(stripe_id))}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {createdAt
                          ? moment(createdAt).format("DD-MM-YYYY")
                          : "N/A"}
                      </TableCell>

                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={status === false ? "error" : "success"}
                        >
                          {status === false ? "InActive" : "Active"}
                        </Label>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {filteredUsers > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 50, 100, 150]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Pagination
            count={totalPages + 1}
            page={pageCount}
            defaultPage={0}
            onChange={handleChangePages}
            className="pagination-style"
          />
        </Card>
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <StripeProductFilter
            filterData={filterData}
            handleSelectOther={handleSelectOther}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
