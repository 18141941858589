import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ barData }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 20,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: true,
          barHeight: "30%", // Decreased bar height
          colors: {
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
          },
        },
      },
      colors: ["#f6bd4b"], // Default color, can be updated dynamically
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [], // Will be updated with dynamic data
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#FFFFFF",
          },
        },
      },
      tooltip: {
        theme: "dark",
      },
    },
  });

  useEffect(() => {
    if (barData) {
      setChartData({
        series: [
          {
            data: barData.map((item) => item.views), // Assuming barData is an array of objects with 'value' for bar values
          },
        ],
        options: {
          ...chartData.options,
          xaxis: {
            ...chartData.options.xaxis,
            categories: barData.map((item) => item.title), // Assuming barData has 'name' for categories
          },
          colors: barData.map((item) => item.color || "#f6bd4b"), // Use provided color or default
        },
      });
    }
  }, [barData]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={440}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default BarChart;
