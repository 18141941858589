import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import {
  Requested_csv_files_api,
  adminUserDeleteApi,
  adminUserList,
} from "src/DAL/AdminUserApi/AdminUserApi";
import moment from "moment";
import { dd_date_format, show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const RequestedCsvFIle = () => {
  const classes = useStyles();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const getProductCategories = async () => {
    setIsLoading(true);
    const result = await Requested_csv_files_api();
    console.log(result, "resultresult");
    if (result.code == 200) {
      const ListData = result.data.map((items) => {
        return {
          ...items,
          request_initiated_date: items.request_initiated_date
            ? dd_date_format(items.request_initiated_date)
            : "N/A",
          request_completed_date: items.request_completed_date
            ? dd_date_format(items.request_initiated_date)
            : "N/A",
          status: items.status,
          collection_name: show_proper_words(items.collection_name),
        };
      });
      setCategoryList(ListData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handle_privacy_click = (path) => {
    window.open(`${s3baseUrl + path}`, "_blank");
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },

    { id: "csv_name", label: "File Name", className: "text-capitalize" },
    {
      id: "collection_name",
      label: "Collection Name",
      className: "text-capitalize",
    },
    { id: "request_initiated_date", label: "CSV Request  Date" },
    { id: "request_completed_date", label: "CSV Request Completed Date" },
    {
      id: "Tracking Id",
      label: "Download Url",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            {row.csv_download_url !== "" ? (
              <Tooltip title="Click here to Download file">
                <span
                  onClick={() => handle_privacy_click(row.csv_download_url)}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#f6bd4b",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  Click To Download
                </span>
              </Tooltip>
            ) : (
              <Tooltip
                title={`${`Please wait, Your CSV request is currently  ${row.request_status}`}`}
              >
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#6c757d",
                    fontSize: "14px",
                    cursor: "not-allowed",
                  }}
                >
                  Click To Download
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      alignRight: false,
      renderData: (row) => {
        let chipColor;
        let chipTextcolor;
        switch (row.request_status) {
          case "pending":
            chipColor = "#ff9800";
            chipTextcolor = "#fff";
            break;

          case "completed":
            chipColor = "#4caf50";
            chipTextcolor = "#fff";
            break;

          default:
            chipColor = "#ff9800";
            chipTextcolor = "#fff";

            break;
        }
        return (
          <Chip
            label={row.request_status}
            style={{
              backgroundColor: chipColor,
              color: chipTextcolor,
              textTransform: "capitalize",
            }}
          ></Chip>
        );
      },
    },
  ];

  useEffect(() => {
    getProductCategories();
  }, []);
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      {" "}
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-8 col-sm-12">
            <h2>Requested CSV Files</h2>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoryList}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
};

export default RequestedCsvFIle;
