import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import {
  DeleteCommentApi,
  memberGoalStatementListApi,
} from "src/DAL/member/member";
import { useLocation, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { CircularProgress, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
// import AddKimsReply from "./AddKimReply";
import { project_name } from "../../config/config";
import TextField from "@mui/material/TextField";
// import GoalGallerySlider from "./GoalGallerySlider";
import { useState } from "react";
import { AnswerHistory } from "./AnswerHistory";
import { QuestionsReply } from "./QuestionsReply";
// import {
//   getGoalStatementQuestion,
//   questionDetailApi,
//   DeleteCommentApi,
//   getDetailQuestion,
// } from "src/DAL/GoalStatement/GoalStatement";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddKimsReply from "./AddKimReply";
import { stubTrue } from "lodash";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import GoalGallerySlider from "./GoalGallerySlider";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const MemberGoalstatementList = () => {
  const params = useParams();
  const classes = useStyles();

  const navigate = useNavigate();
  const { state } = useLocation();
  const titleRef = React.useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [gallery, setGallery] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [replies, setReplies] = React.useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteDoc, setDeleteDoc] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [openAnswerHistory, setOpenAnswerHistory] = useState(false);
  const [openReply, setOpenReply] = useState(false);
  const [answerHistoryData, setAnswerHistoryData] = useState();
  const [replyData, setReplyData] = useState();
  const [expanded, setExpanded] = React.useState(0);
  const [userInfo, setUserInfo] = React.useState("");
  const memberInformation = state?.member;

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    //console.log(panel, "panel");
    setExpanded(newExpanded ? panel : false);
  };
  const handleClick = () => {
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleOpenAnswerDrawer = (value) => {
    setAnswerHistoryData(value);
    setOpenAnswerHistory(true);
  };

  const handleCloseAnswerDrawer = () => {
    setOpenAnswerHistory(false);
  };
  const handleOpenReplyDrawer = (value) => {
    setReplyData(value);
    setOpenReply(true);
  };
  const handleCloseReplyDrawer = () => {
    setOpenReply(false);
  };
  // console.log(state, "statestatestate");

  const detailQuestionHistory = async () => {
    setIsLoading(true);
    const result = await memberGoalStatementListApi(params.id);
    if (result.code == 200) {
      // console.log(result, "Question detail");
      setGallery(result.goal_statement_gallery);
      setQuestions(result.goal_statement_question);
      setReplies(result.reply);

      let galleryArray = [];
      let galleryObject = {};
      result.goal_statement_gallery.map((gallery, index) => {
        galleryObject = {
          original: s3baseUrl + gallery.thumbnail_1,
          thumbnail: s3baseUrl + gallery.thumbnail_2,
        };
        galleryArray.push(galleryObject);
      });

      setGallery(galleryArray);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      //console.log("api is not working");
    }
  };

  const handleDeleteReply = (value) => {
    //console.log(value, "handleDeleteReply");
  };
  const handleAgreeDelete = (value) => {
    //console.log(value, "delete it ");
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    //console.log(deleteDoc, "delete function run");
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteCommentApi(deleteDoc._id);
    if (result.code === 200) {
      detailQuestionHistory();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleReply = (value) => {
    //console.log(value, "handleReply");
    navigate(`/member-goalstatement/question-reply/${params.id}`, {
      state: value,
    });
  };
  const handleAnswerHistory = (value) => {
    //console.log(value, "handleReply");
    navigate(`/member-goalstatement/answer-history/${params.id}`, {
      state: value,
    });
  };
  React.useEffect(() => {
    detailQuestionHistory();
  }, []);
  React.useEffect(() => {
    const userName = localStorage.getItem("name");

    setUserInfo(userName);
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(replies, "repliesreplies");
  return (
    <div className="container-fluid">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="row">
        <div className="col-6 mb-3 reply-anchor d-flex">
          <IconButton
            className="back-screen-button mb-4 me-3"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>

          <p className="mt-2">
            {memberInformation?.first_name &&
              memberInformation?.first_name +
                " " +
                memberInformation?.last_name +
                " (" +
                memberInformation?.email +
                ")"}
          </p>
        </div>
        <div className="col-6">
          <a
            className="small-contained-button float-end mt-1 anchor-style"
            // onClick={() => navigate(`/programmes/addreview/`)}
            // href="#dynamite-reply"

            onClick={handleClick}
          >
            {/* {userInfo?.first_name && userInfo.first_name + "'s" + " Reply"} */}
            {"Dynamite's" + " Reply"}
          </a>
        </div>
        <div className="col-12 mb-5 ">
          {
            <>
              <h2 className="mb-5">Goal Statement Gallery</h2>
              {gallery.length == 0 ? (
                <div className="goal-gallery p-3">
                  Member has not added any goal statement gallery yet
                </div>
              ) : (
                <GoalGallerySlider data={gallery} />
              )}
            </>
          }
        </div>

        {/* <div className="col-12">
          <h2 className="mb-4">Goal Statement Questions</h2>
        </div> */}
        {questions.map((question, index) => {
          //console.log(question, "indexindexindexindex");
          return (
            <div className="col-12 mb-1">
              <Accordion
                expanded={expanded === index}
                onChange={handleChangeAccordion(index)}
                className="question-background"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="goal-statement-question">
                    {question.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="">
                      <div className="row">
                        <div className="col-12 mb-4 text-muted">
                          {question.answer == "" ? "" : question.answer}
                        </div>
                        <div className="col-6 mb-3 text-muted text-start">
                          <button
                            className="small-contained-button"
                            onClick={() => {
                              handleOpenAnswerDrawer(question);
                            }}
                          >
                            Answer History
                          </button>
                        </div>
                        <div className="col-6 mb-3 text-muted text-end">
                          <button
                            className="small-contained-button me-auto"
                            onClick={() => handleOpenReplyDrawer(question)}
                          >
                            Reply {" (" + question.comment.length + ")"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <h4 ref={titleRef} className="mb-4">
            {replies.length == 0 ? "" : userInfo && userInfo + "'s" + " Reply"}
          </h4>
        </div>

        {replies?.map((reply) => {
          return (
            <>
              <div className="col-12 mb-3 d-flex">
                <CircleIcon />
                <div
                  className="ms-2"
                  dangerouslySetInnerHTML={{
                    __html: reply.message,
                  }}
                ></div>
                {/* <CircleIcon /> {reply?.message} */}
              </div>
              <div className="col-12 mb-1">
                {reply?.audio_file && (
                  <audio
                    className="w-100"
                    src={s3baseUrl + reply?.audio_file}
                    controls
                  />
                )}
              </div>
              <div className="col-6 mb-5 text-muted">
                {moment(reply.createdAt).format("DD-MM-YYYY")}
              </div>
              <div className="col-6 text-end">
                <button
                  className="small-contained-button"
                  onClick={() => handleAgreeDelete(reply)}
                >
                  Delete
                </button>
              </div>
            </>
          );
        })}
      </div>

      <AddKimsReply goalDetail={detailQuestionHistory} />

      <CustomDrawer
        isOpenDrawer={openAnswerHistory}
        onOpenDrawer={handleOpenAnswerDrawer}
        onCloseDrawer={handleCloseAnswerDrawer}
        pageTitle={`Answer History`}
        componentToPassDown={
          <AnswerHistory
            data={answerHistoryData}
            member_id={params.id}
            detailQuestionHistory={detailQuestionHistory}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={openReply}
        onOpenDrawer={handleOpenReplyDrawer}
        onCloseDrawer={handleCloseReplyDrawer}
        pageTitle={`Replies`}
        name={state?.name}
        componentToPassDown={
          <QuestionsReply
            data={replyData}
            detailQuestionHistory={detailQuestionHistory}
            onCloseDrawer={handleCloseReplyDrawer}
          />
        }
      />
    </div>
  );
};

export default MemberGoalstatementList;
