import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { htmlDecode } from "src/utils/convertHtml";

const useStyles = makeStyles((theme) => ({
  dense: {
    marginTop: theme.spacing(0),
  },
}));

const Message = ({ data }) => {
  const classes = useStyles();

  const [questionMessages, setQuestionMessages] = useState([]);

  const handleChange = (index, event) => {
    let value = event.target.value;
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    temp_element.answer = value;
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  const expandArea = (index) => {
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    if (temp_element.isExpanded === false) {
      temp_element.isExpanded = true;
    } else {
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  useEffect(() => {
    let questionsArray = [];
    data.map((value, index) => {
      if (index === 0) {
        questionsArray.push({
          ...value,
          isExpanded: true,
          isLoading: false,
        });
      } else {
        questionsArray.push({
          ...value,
          isExpanded: false,
          isLoading: false,
        });
      }
      setQuestionMessages(questionsArray);
    });
  }, [data]);

  return (
    <>
      <div className="row goal-statement-messages">
        <div className="col">
          {questionMessages.length > 0
            ? questionMessages.map((message, index) => {
                return (
                  <div className="card mb-2 " key={message.id}>
                    <form>
                      <Accordion
                        expanded={message.isExpanded}
                        className="question-background"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          onClick={() => expandArea(index)}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="svg-color text-white"
                        >
                          <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
                            <Typography>
                              {htmlDecode(message.question_statement)}
                            </Typography>
                          </div>
                        </AccordionSummary>
                        <div className="">
                          <AccordionDetails>
                            {message.question_type == "textarea" ? (
                              <TextField
                                id="filled-multiline-flexible"
                                fullWidth
                                multiline
                                rows={5}
                                disabled
                                label={
                                  message?.question_placeholder
                                    ? htmlDecode(message.question_placeholder)
                                    : " "
                                }
                                className={(classes.dense, "textarea-color")}
                                value={
                                  message?.answer
                                    ? htmlDecode(message.answer)
                                    : ""
                                }
                                onChange={(e) => handleChange(index, e)}
                                variant="outlined"
                                name="message"
                              />
                            ) : (
                              <TextField
                                id="filled-multiline-flexible"
                                fullWidth
                                disabled
                                label={
                                  message?.question_placeholder
                                    ? htmlDecode(message.question_placeholder)
                                    : " "
                                }
                                className={(classes.dense, "textarea-color")}
                                value={
                                  message.answer
                                    ? htmlDecode(message.answer)
                                    : ""
                                }
                                onChange={(e) => handleChange(index, e)}
                                variant="outlined"
                                name="message"
                              />
                            )}
                          </AccordionDetails>
                        </div>
                      </Accordion>
                    </form>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
};

export default Message;
