import * as React from "react";
import { useState } from "react";
import "react-tagsinput/react-tagsinput.css";
import TextareaAutosize from "react-textarea-autosize";
import { useEffect } from "react";

export default function SMSSetting(props) {
  const { onCloseDrawer, data, drawerState } = props;
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = {
      ...data,
      sms_notification_info: {
        message: message,
      },
    };
    onCloseDrawer(obj, drawerState);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setMessage(value);
  };

  useEffect(() => {
    if (data.sms_notification_info) {
      setMessage(data.sms_notification_info.message);
    }
  }, [drawerState]);

  return (
    <div className="container-fluid new-memories">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-12 mt-3">
          <div className="w-100">
            <h4>Write SMS Message*</h4>
            <div className="position-relative parent">
              <TextareaAutosize
                id="inputField"
                className={`chat-send-input chat-formatters-border `}
                style={{
                  backgroundColor: "var(--background-secondary-color)",
                }}
                type="text"
                value={message}
                maxRows={6}
                minRows={6}
                onChange={handleChange}
                placeholder="Write Your SMS Message..."
                required
                autoFocus
              />
            </div>
          </div>
        </div>
        <div className="text-end mt-4">
          <button type="submit" className="small-contained-button">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
