import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import {
  IconButton,
  Divider,
  CircularProgress,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import {
  _add_Bonus_road_map,
  _edit_bonus_Api,
} from "src/DAL/BonusRoadMAp/BonusRoadMApApi";
import { s3baseUrl } from "src/config/config";
import { COMMUNITY_LEVELS } from "src/utils/constant";
import { useEffect } from "react";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function EditBonusRoadMap() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const { state, search } = useLocation();
  const level = new URLSearchParams(search).get("level");
  const [previews, setPreviews] = useState();
  const [oldImage, setOldImage] = useState();
  const [imageWithoutBorder, setImageWithoutBorder] = useState({});
  const [oldImageWithoutBorder, setOldImageWithoutBorder] = useState();
  const [image, setImage] = useState({});
  const [previewsWithoutBorder, setPreviewsWithoutBorder] = useState();
  const [fileNew, setProfileImageNew] = useState(false);
  const [fileNewWithoutBorder, setProfileImageNewWithoutBorder] =
    useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    coins: "",
    icon_title: "",
    notes_for_admin: "",
    status: "true",
    order: "",
    community_level: "dynamite",
    image: {},
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("coins", inputs.coins);
    formData.append("order", inputs.order);
    formData.append("status", inputs.status);
    formData.append("icon_title", inputs.icon_title);
    formData.append("notes_for_admin", inputs.notes_for_admin);
    formData.append("community_level", inputs.community_level);
    if (fileNew == true) {
      formData.append("image", image);
    }
    if (fileNewWithoutBorder == true) {
      formData.append("without_border_icon", imageWithoutBorder);
    }
    // console.log(...formData);
    setIsLoading(true);
    const result = await _edit_bonus_Api(formData, state.editValues._id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      hanldeNavigate();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const fileChangedHandler = (event) => {
    setImage(event.target.files[0]);
    setPreviews(URL.createObjectURL(event.target.files[0]));
    setProfileImageNew(true);
  };
  const fileChangedHandlerwithout = (event) => {
    setImageWithoutBorder(event.target.files[0]);
    setPreviewsWithoutBorder(URL.createObjectURL(event.target.files[0]));
    setProfileImageNewWithoutBorder(true);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const hanldeNavigate = () => {
    let path = "/bonus-road-map";
    if (level) {
      path = `${path}?level=${level}`;
    }
    navigate(path);
  };

  useEffect(() => {
    setOldImage(state.editValues?.table_avatar?.src);
    setOldImageWithoutBorder(state.editValues?.without_border_icon?.src);
    setInputs({
      ...inputs,
      ["title"]: state.editValues?.title,
      ["coins"]: state.editValues?.coins,
      ["status"]: state.editValues?.status,
      ["icon_title"]: state.editValues?.icon_title,
      ["notes_for_admin"]: state.editValues?.notes_for_admin,
      ["order"]: state.editValues?.order,
      ["community_level"]: state.editValues?.community_level,
    });
  }, []);
  // console.log(state, "state");
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={hanldeNavigate}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-1">Edit Bonus Road Map</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            fullWidth
            required
            name="title"
            value={inputs.title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Coins"
            variant="outlined"
            fullWidth
            required
            name="coins"
            value={inputs.coins}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Title To Show Under Icons"
            variant="outlined"
            fullWidth
            name="icon_title"
            value={inputs.icon_title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Order"
            variant="outlined"
            fullWidth
            name="order"
            value={inputs.order}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Community Level
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="community_level"
              required
              value={inputs.community_level}
              label="Community Level"
              onChange={handleChange}
            >
              {COMMUNITY_LEVELS.map((level) => {
                return <MenuItem value={level.name}>{level.title}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status *</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="status *"
              onChange={handleChange}
            >
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="row w-100 div-style ms-0 pt-4">
          <div className="col-5">
            <p className="">Reached Status *</p>
            <FormHelperText className="pt-0">
              Icon Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP","GIF")
            </FormHelperText>
          </div>
          <div className="col-2">
            {previews ? (
              <img src={previews} height={50} />
            ) : (
              oldImage && <img src={oldImage} height={50} />
            )}
          </div>
          <div className="col-5 text-end pt-2">
            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                name="image"
                onChange={fileChangedHandler}
              />

              <Button
                id="contained-button-file"
                className="small-contained-button"
                startIcon={<FileUploadIcon />}
                component="span"
              >
                Upload
              </Button>
            </label>
          </div>
        </div>
        <div className="row w-100 div-style ms-0 pt-4">
          <div className="col-5">
            <p className="">Before Status *</p>
            <FormHelperText className="pt-0">
              Icon Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP","GIF")
            </FormHelperText>
          </div>
          <div className="col-2">
            {previewsWithoutBorder ? (
              <img src={previewsWithoutBorder} height={50} />
            ) : (
              oldImageWithoutBorder && (
                <img src={oldImageWithoutBorder} height={50} />
              )
            )}
          </div>
          <div className="col-5 text-end pt-2">
            <label htmlFor="contained-button-file-1">
              <Input
                accept="image/*"
                id="contained-button-file-1"
                multiple
                type="file"
                name="image"
                onChange={fileChangedHandlerwithout}
              />

              <Button
                id="contained-button-file-1"
                className="small-contained-button"
                startIcon={<FileUploadIcon />}
                component="span"
              >
                Upload
              </Button>
            </label>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Notes For Admin For This Status"
            variant="outlined"
            fullWidth
            multiline
            rows={5}
            name="notes_for_admin"
            value={inputs.notes_for_admin}
            onChange={handleChange}
          />
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
