import { invokeApi } from "src/bl_libs/invokeApi";

export const lesson_document_list_api = async (id) => {
  const requestObj = {
    path: `/api/lesson_document/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const lesson_document_detail_api = async (slug) => {
  const requestObj = {
    path: `/api/lesson_document/lesson_document_detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const documentListApi = async (data) => {
  const requestObj = {
    path: `/api/lesson_document/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_lesson_document_api = async (data) => {
  const requestObj = {
    path: `/api/lesson_document/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_lesson_document_api = async (data, slug) => {
  const requestObj = {
    path: `/api/lesson_document/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delete_lesson_document_api = async (slug) => {
  const requestObj = {
    path: `/api/lesson_document/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
