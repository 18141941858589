import * as React from "react";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useParams, useLocation } from "react-router-dom";
import { Checkbox, CircularProgress } from "@mui/material";

import {
  add_programme_bulk_access_api,
  list_members_for_program_api,
} from "src/DAL/Programme/Programme";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useEffect } from "react";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import MemberFilter from "src/components/ProgrammesList/MemberFilter";
import { s3baseUrl } from "src/config/config";
import { api_date_format, dd_date_format } from "src/utils/constant";
import FilteredChip from "src/components/FilteredChip";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_FILTER = {
  community: [],
  lead_status: [],
  is_date_range: false,
  from_date: null,
  to_date: null,
  date: null,
  goal_statement: "",
  expiry_in: 3,
  member_ship_expiry: "",
  member_user_type: "",
  member_created_by_platform: "",
  membership_purchase_expiry_from: new Date(),
  membership_purchase_expiry_to: new Date(),
  status: "",
  search: "",
  country: null,
  change_for: "all",
  downloaded_app: null,
  delegates: [],
  nurtures: [],
};

const member_name = (member) => {
  return member.first_name + " " + member.last_name + " (" + member.email + ")";
};

export default function ProgrammeBulkAccess() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const { access_type, programme_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [selectedCount, setSelectedCount] = useState(0);
  const [membersData, setMembersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);
  const [isAllChecked, setIsAllChecked] = useState(null);
  const [allUsersChecked, setAllUsersChecked] = useState([]);
  const [isAfterRemove, setIsAfterRemove] = useState([]);
  const [selectedCountFinal, setSelectedCountFinal] = useState(0);
  const handleCheckAll = (e) => {
    let is_checked = e.target.checked;
    let count = 0;
    if (is_checked) {
      count = totalCount;
      setSelectedCountFinal(totalCount);
    } else {
      count = 0;
      setSelectedCountFinal(0);
    }
    setSelectedCount(count);
    setIsSelectedAll(is_checked);
    setIsAllChecked(is_checked ? "all" : "remove");
    setIsAfterRemove([]);
    if (is_checked) {
      setAllUsersChecked(membersData.map((member) => member._id));
    } else {
      setAllUsersChecked([]);
    }
  };
  const handle_click_checkbox = async (e, row) => {
    let is_checked = e.target.checked;
    setAllUsersChecked((old) => {
      if (is_checked) {
        return [...old, row._id];
      }
      return old.filter((id) => id !== row._id);
    });
    if (!is_checked) {
      setIsAfterRemove((old) => [...old, row._id]);
      setSelectedCountFinal((prevCount) => prevCount - 1);
    } else {
      setIsAfterRemove((old) => old.filter((id) => id !== row._id));
      setSelectedCountFinal((prevCount) => prevCount + 1);
    }
  };

  const TABLE_HEAD = [
    {
      id: "is_member_checked",
      label: <Checkbox checked={isSelectedAll} onChange={handleCheckAll} />,

      renderData: (row) => (
        <Checkbox
          checked={
            allUsersChecked.includes(row._id) &&
            !isAfterRemove.includes(row._id)
          }
          onChange={(e) => handle_click_checkbox(e, row)}
        />
      ),
    },
    { id: "number", label: "#", type: "number" },
    { id: "table_avatar", label: "Image", type: "thumbnail" },
    { id: "name", label: " Name", alignRight: false },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
  ];

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleIsDateRange = (event) => {
    const value = event.target.checked;
    setFilterData((values) => ({ ...values, is_date_range: value }));
  };
  const handleIsCountry = (event) => {
    setFilterData((values) => ({ ...values, country: event }));
  };

  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };

  const handleClearFilter = (e) => {
    if (e) e.preventDefault();
    setPage(0);
    setPageCount(1);
    let postData = { ...EMPTY_FILTER, change_for: "specific" };
    setFilterData(postData);
    getMembersList(postData);
    setFilterDrawerState(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }

    let filter_data = { ...filterData };
    filter_data.page = newPage + 1;
    getMembersList(filter_data);
  };

  const handleChangeRowsPerPage = (event) => {
    let selected_limit = parseInt(event.target.value, 10);
    setRowsPerPage(selected_limit);
    setPage(0);
    let filter_data = { ...filterData };
    filter_data.page = 0;
    filter_data.limit = selected_limit;
    getMembersList(filter_data);
  };

  const handle_filter_data = (filterData) => {
    let filter_data = { ...filterData };
    delete filter_data.change_for;
    delete filter_data.date;

    if (filter_data.downloaded_app) {
      filter_data.downloaded_app =
        filter_data.downloaded_app.value == "all"
          ? ""
          : filter_data.downloaded_app.value;
    }
    if (filter_data.community.length > 0) {
      filter_data.community = filter_data.community.map((community) => {
        return community.name;
      });
    }
    if (filter_data.lead_status.length > 0) {
      filter_data.lead_status = filter_data.lead_status.map((item) => {
        return item._id;
      });
    }
    if (filter_data.is_date_range) {
      if (filter_data.from_date) {
        filter_data.from_date = moment(filter_data.from_date).format(
          "YYYY-MM-DD"
        );
      }
      if (filter_data.to_date) {
        filter_data.to_date = moment(filter_data.to_date).format("YYYY-MM-DD");
      }
    } else {
      delete filter_data.from_date;
      delete filter_data.to_date;
    }
    if (filter_data.country) {
      filter_data.country = filter_data.country.code;
    }
    if (filter_data.member_ship_expiry == "not_expired") {
      filter_data.membership_purchase_expiry_from = api_date_format(
        filter_data.membership_purchase_expiry_from
      );
      filter_data.membership_purchase_expiry_to = api_date_format(
        filter_data.membership_purchase_expiry_to
      );
      if (filter_data.expiry_in !== "custom") {
        filter_data.membership_purchase_expiry_to = moment(
          filter_data.membership_purchase_expiry_from,
          "YYYY-MM-DD"
        )
          .add(filter_data.expiry_in, "days")
          .format("YYYY-MM-DD");
      }
    }
    if (filter_data.delegates.length > 0) {
      filter_data.delegates = filter_data.delegates.map((delegate) => {
        return delegate._id;
      });
    }
    if (filter_data.nurtures.length > 0) {
      filter_data.nurtures = filter_data.nurtures.map((nurture) => {
        return nurture._id;
      });
    }
    filter_data.limit = filter_data.limit || rowsPerPage;
    filter_data.program_id = programme_id;
    filter_data.action_type = access_type;

    let page_number = page;
    if (filter_data.page || filter_data.page == 0) {
      page_number = filter_data.page;
    }
    filter_data.page = page_number;
    return filter_data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      type: filterData.change_for,
      specific_filter_type: "specific",
      program: programme_id,
      program_access_type: access_type,
    };
    if (filterData.change_for == "specific") {
      postData.member = allUsersChecked;
      if (isSelectedAll) {
        let filter_data = handle_filter_data(filterData);
        postData.specific_filter_type = "all";
        postData.exclude_member = isAfterRemove;
        postData.filter_data = filter_data;
      }
    }

    const result = await add_programme_bulk_access_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getMembersList = async (filterData) => {
    setIsLoading(true);
    let postData = handle_filter_data({ ...filterData });
    const result = await list_members_for_program_api(postData);
    if (result.code == 200) {
      let selected_count = 0;
      const members = result.members.map((member) => {
        let exist = member.program.find((prog) => prog._id === programme_id);
        let is_member_checked = Boolean(exist) && access_type === "add";
        if (isAllChecked === "all") {
          setAllUsersChecked((old) => {
            if (!old.includes(member._id)) {
              return [...old, member._id];
            }
            return old;
          });
          setSelectedCountFinal(
            isAfterRemove.length > 0
              ? result.total_member_count - isAfterRemove.length
              : result.total_member_count
          );
        } else if (isAllChecked === "remove" && access_type === "add") {
          is_member_checked = false;
        } else if (access_type === "add") {
          setAllUsersChecked((old) => {
            if (exist && !old.includes(member._id)) {
              return [...old, member._id];
            }
            return old;
          });
          setSelectedCountFinal(
            isAfterRemove.length > 0
              ? result.total_assigned_members - isAfterRemove.length
              : result.total_assigned_members
          );
        }
        return {
          ...member,
          name: member_name(member),
          is_member_checked,
          table_avatar: {
            src: s3baseUrl + member.profile_image,
            alt: member.first_name,
          },
          expiry_date: moment(member.event_expiry_date).format("DD-MM-YYYY"),
          start_date: moment(member.event_start_date).format("DD-MM-YYYY"),
        };
      });

      setMembersData(members);
      setTotalCount(result.total_member_count);

      setTotalPages(result.total_pages);
      let chipData = { ...filterData };
      console.log(chipData, "chipData");
      delete chipData.change_for;
      if (chipData.member_ship_expiry == "not_expired") {
        let membership_date = "";
        if (chipData.expiry_in !== "custom") {
          membership_date = `Expiry in ${chipData.expiry_in} days`;
        } else {
          membership_date = `Membership Expiry Start Date : ${dd_date_format(
            chipData.membership_purchase_expiry_from
          )} - Membership Expiry End Date :  ${moment(
            chipData.membership_purchase_expiry_to,
            "YYYY-MM-DD"
          ).format("DD-MM-YYYY")}`;
        }

        chipData.membership_expiry = {
          chip_label: membership_date,
          chip_value: membership_date,
        };
      }

      delete chipData.membership_purchase_expiry_from;
      delete chipData.membership_purchase_expiry_to;

      if (chipData.from_date) {
        let date = `Start Date : ${dd_date_format(
          chipData.from_date
        )} End Date :  ${dd_date_format(chipData.to_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
      }
      setFilterData({ ...chipData, change_for: "specific" });

      delete chipData.from_date;
      delete chipData.is_date_range;
      delete chipData.to_date;
      if (chipData.search) {
        delete chipData.search;
      }

      setFilterStateUpdated(chipData);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setFilterDrawerState(false);
    setIsAllChecked(null);
    setPage(0);
    setPageCount(1);
    let filter_data = { ...filterData };
    filter_data.page = 0;
    getMembersList(filter_data);
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    let filter_data = { ...filterData };
    filter_data.page = newPage - 1;
    getMembersList(filter_data);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    if (!data.date) {
      data.from_date = null;
      data.is_date_range = false;
      data.to_date = null;
    }
    if (!data.membership_expiry) {
      delete data.membership_expiry;
      data.membership_purchase_expiry_from = new Date();
      data.membership_purchase_expiry_to = new Date();
      data.expiry_in = 3;
      data.member_ship_expiry = "";
    }
    getMembersList(data);
    setFilterData(data);
    handleCloseFilterDrawer();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  let program_title = localStorage.getItem("program_title");
  let program_slug_local = localStorage.getItem("program_slug");
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },

    {
      title: state ? state?.title : program_title ? program_title : "",
      navigation: `/programmes/programmes-detail/${program_slug_local}`,
      active: false,
    },
    {
      title:
        access_type == "add"
          ? "Add Member Programme Access"
          : "Remove Member Programme Access",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    if (filterData.change_for === "all") return;
    getMembersList(filterData);
  }, [filterData.change_for]);
  useEffect(() => {
    if (isAllChecked === "all") {
      setAllUsersChecked((old) => [
        ...old,
        ...membersData
          .filter((member) => !old.includes(member._id))
          .map((member) => member._id),
      ]);
    }
  }, [isAllChecked]);

  return (
    <>
      <div className="container-fluid">
        <div className="row mobile-margin display-flex">
          <div className="col-12">
            <div className="col-12 mb-3">
              <span className="">
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2>
              {access_type == "add"
                ? "Add Member Programme Access"
                : "Remove Member Programme Access"}
            </h2>
          </div>
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="col-7 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Programme Access For
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="change_for"
                  value={filterData.change_for}
                  label="Programme Access For"
                  onChange={handleChange}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="specific">Specific</MenuItem>
                </Select>
              </FormControl>
            </div>
            {filterData.change_for == "specific" && (
              <div className="col-5 col-md-6 text-end mt-4">
                <button
                  className="small-contained-button mt-1"
                  onClick={handleOpenFilterDrawer}
                >
                  Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
                </button>
              </div>
            )}
            <div
              className="text-end mt-4"
              id={`${filterData.change_for == "specific" ? "fixedbutton" : ""}`}
            >
              <button className="small-contained-button" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
        {filterData.change_for == "specific" && (
          <>
            {isLoading ? (
              <CircularProgress className={classes.loading} color="primary" />
            ) : (
              <div className="row">
                <FilteredChip
                  data={filterStateUpdated}
                  tempState={filterData}
                  EMPTY_FILTER={EMPTY_FILTER}
                  onDeleteChip={handleDeleteChip}
                  onClear={handleClearFilter}
                />
                <div className="col-12">
                  <CustomMUITable
                    TABLE_HEAD={TABLE_HEAD}
                    data={membersData}
                    // selectedCount={selectedCountFinal}
                    className="card-with-background"
                    custom_pagination={{
                      total_count: totalCount,
                      rows_per_page: rowsPerPage,
                      page: page,
                      handleChangePage: handleChangePage,
                      onRowsPerPageChange: handleChangeRowsPerPage,
                    }}
                    custom_search={{
                      searchText: filterData.search,
                      setSearchText: (search) =>
                        handleChangeOthers(search, "search"),
                      handleSubmit: searchFunction,
                    }}
                    pageCount={pageCount}
                    totalPages={totalPages}
                    handleChangePages={handleChangePages}
                    pagePagination={true}
                    rows_per_page_count={rowsPerPage}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <MemberFilter
            filterData={filterData}
            handleChange={handleChange}
            setFilterData={setFilterData}
            handleIsDateRange={handleIsDateRange}
            handleChangeOthers={handleChangeOthers}
            handleIsCountry={handleIsCountry}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
            filterDrawerState={filterDrawerState}
          />
        }
      />
    </>
  );
}
