import { invokeApi } from "../../bl_libs/invokeApi";

export const add_stripe_reader = async (data, id) => {
  const requestObj = {
    path: `/api/stripe_reader/add_stripe_reader`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const list_stripe_reader = async (data, id) => {
  const requestObj = {
    path: `/api/stripe_reader/list_stripe_reader`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_stripe_reader = async (id) => {
  const requestObj = {
    path: `/api/stripe_reader/delete_stripe_reader/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_stripe_reader = async (data, id) => {
  const requestObj = {
    path: `/api/stripe_reader/update_stripe_reader/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
