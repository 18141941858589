import { invokeApi } from "../../bl_libs/invokeApi";

export const add_road_map_levels_api = async (data) => {
  const requestObj = {
    path: `/api/road_map_levels`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const history_api_api = async (data, searchText, page, rowsPerPage) => {
  const requestObj = {
    path: `/app/portal_history_stats?page=${page}&limit=${rowsPerPage}&search_text=${
      searchText ? searchText : ""
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const road_map_levels_list_api = async (data, id) => {
  const requestObj = {
    path: `/api/road_map_levels`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_road_map_levels_api = async (id) => {
  const requestObj = {
    path: `/api/road_map_levels/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const road_map_level_detail_api = async (id) => {
  const requestObj = {
    path: `/api/road_map_levels/road_map_level_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const road_map_levels_update = async (data, id) => {
  const requestObj = {
    path: `/api/road_map_levels/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
