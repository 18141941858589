import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { update_mc_page_settings_api } from "src/DAL/WebsitePages/WebsitePages";

import GpsSetting from "src/components/MissionControlSettingPages/GpsSetting";
import { useEffect } from "react";
import { detailPaymentPlanApi } from "src/DAL/WebsitePages/paymentPlan";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

let EMPTY_VALUES = {};
let gps_values = {
  show_setting: false,
  event_portal_text: "",
  event_portal_link: "",
  download_app_text: "",
  download_app_link: "",
  button_bg_color: "#f6bd4b",
  button_text_color: "#FFFFFF",
  banner_description: "",
  video_description: "",
  event_schedule_description: "",
  number_of_views: 0,
};

EMPTY_VALUES = { ...EMPTY_VALUES, ...gps_values };

export default function MissionControlSetting() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const { plan_id, page_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [pageName, setPageTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState(EMPTY_VALUES);
  const is_website_pages = window.location.pathname.includes("website-pages");

  let route_name = "/template-pages";
  if (is_website_pages) {
    route_name = "/website-pages";
  }

  let back_link = `${route_name}/${page_id}/payment-plans`;

  const getPlanDetail = async () => {
    setIsLoading(true);
    const result = await detailPaymentPlanApi(plan_id);
    if (result.code == 200) {
      handleFormat(result?.payment_plan);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(back_link);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data_obj = {
      mission_control_setting: inputs,
    };

    setIsLoading(true);
    const result = await update_mc_page_settings_api(data_obj, plan_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(back_link);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFormat = (data) => {
    if (
      data.mission_control_setting &&
      Object.keys(data.mission_control_setting).length > 0
    ) {
      setInputs(data.mission_control_setting);
    }
    setPageTitle(data.plan_title);
    window.history.replaceState({}, document.title);
    setIsLoading(false);
  };

  let breadCrumbMenu = [
    {
      title: is_website_pages ? "Pages" : "Template Pages",
      navigation: route_name,
      active: false,
    },
    {
      title: "Payment Plans",
      navigation: `${route_name}/${page_id}/payment-plans`,
      active: false,
    },
    {
      title: pageName,
      active: true,
    },
  ];

  useEffect(() => {
    if (state) {
      handleFormat(state.data);
    } else {
      getPlanDetail();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-12">
          <h2>Mission Control Setting</h2>
        </div>
      </div>
      <GpsSetting
        handleSubmit={handleSubmit}
        inputs={inputs}
        handleChange={handleChange}
        setInputs={setInputs}
      />
    </div>
  );
}
