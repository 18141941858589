import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { Button, CircularProgress, FormHelperText } from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { s3baseUrl } from "src/config/config";
import { NOTIFICATIONS_LIST } from "src/utils/constant";
import { update_notification_setting_api } from "src/DAL/Notification/Notification";
import { clientSettingApi } from "src/DAL/ClientSetting/ClientSetting";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function NotificationsSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  let notification_states = {};
  NOTIFICATIONS_LIST.map((level) => {
    let description_name = `${level.name}_description`;
    let icon_name = `${level.name}_icon`;
    notification_states[description_name] = "";
    notification_states[icon_name] = "";
  });

  const [notificationData, setNotificationData] = useState({
    ...notification_states,
  });

  const getSetting = async () => {
    setIsLoading(true);
    const result = await clientSettingApi();
    if (result.code == 200) {
      let notification_settings =
        result.content_setting?.client_general_setting?.notification_setting;
      if (notification_settings?.length > 0) {
        let fields = {};
        NOTIFICATIONS_LIST.map((notification) => {
          let find_answer = notification_settings.find(
            (answer) => answer.notification_for == notification.name
          );
          fields[`${notification.name}_description`] = find_answer?.description;
          fields[`${notification.name}_icon`] = find_answer?.icon;
        });
        setNotificationData(fields);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let postArray = [];

    NOTIFICATIONS_LIST.map((notification) => {
      postArray.push({
        title: notification.title,
        notification_for: notification.name,
        description: notificationData[`${notification.name}_description`],
        icon: notificationData[`${notification.name}_icon`],
      });
    });

    let postData = { notification_setting: postArray };
    const result = await update_notification_setting_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const fileChangedHandler = async (e) => {
    const { name, files } = e.target;
    const formData = new FormData();
    formData.append("image", files[0]);
    formData.append("width", "300");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setNotificationData((old) => {
        return {
          ...old,
          [name]: imageUpload.image_path,
        };
      });
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid notification-setting">
      <div className="row mobile-margin">
        <div className="col-6">
          <h2>Notifications</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {NOTIFICATIONS_LIST.map((notification) => {
            return (
              <>
                <h3 className="mt-4">{notification.title}</h3>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                  <div className="row w-100 div-style ms-0 pt-0 mb-2">
                    <div className="col-5 ps-0">
                      <p className="">Notification Icon</p>
                      <FormHelperText className="pt-0">
                        Recommended Size (1000 X 670) ("JPG", "JPEG",
                        "PNG","WEBP")
                      </FormHelperText>
                    </div>
                    <div className="col-2 ps-0">
                      <img
                        src={
                          notificationData[`${notification.name}_icon`]
                            ? s3baseUrl +
                              notificationData[`${notification.name}_icon`]
                            : ""
                        }
                        height="50"
                      />
                    </div>
                    <div className="col-5 text-end pt-2">
                      <label htmlFor={`${notification.name}_icon`}>
                        <Input
                          accept="image/*"
                          id={`${notification.name}_icon`}
                          multiple
                          type="file"
                          name={`${notification.name}_icon`}
                          onChange={fileChangedHandler}
                        />

                        <Button
                          className="small-contained-button"
                          startIcon={<FileUploadIcon />}
                          component="span"
                        >
                          Upload
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <h4>Notification Description</h4>
                  <GeneralCkeditor
                    setInputs={setNotificationData}
                    inputs={notificationData}
                    name={`${notification.name}_description`}
                    editorHeight={320}
                  />
                </div>
              </>
            );
          })}

          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
