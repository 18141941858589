import React from "react";
import no_data_found from "../assets/images/no_data_found.png";

export default function RecordNotFound({ title, heading }) {
  return (
    <>
      <div className="no-access-string">
        <img className="mx-auto" src={no_data_found} />
        <p className="mt-3 not-found-text">{`${
          heading ? heading : title + " Not Found"
        }`}</p>
      </div>
    </>
  );
}
