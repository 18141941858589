import { invokeApi } from "src/bl_libs/invokeApi";

export const daily_message_list_api = async (page, limit) => {
  const requestObj = {
    path: `/api/daily_message`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const daily_message_list_api_with_pagination = async (
  page,
  rowsPerPage,
  data
) => {
  const requestObj = {
    path: `/api/daily_message/list_with_pagination?page=${page}&limit=${rowsPerPage}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_daily_message_api = async (slug) => {
  const requestObj = {
    path: `/api/daily_message/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_daily_message_api = async (data) => {
  const requestObj = {
    path: `/api/daily_message`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_daily_message_api = async (data, slug) => {
  const requestObj = {
    path: `/api/daily_message/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _add_automated_responded_message = async (data) => {
  const requestObj = {
    path: `/api/auto_responder_message`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const departmentListAPIs = async (data) => {
  const requestObj = {
    path: `/api/department/list_active_department`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const daily_message_detail_api = async (slug) => {
  const requestObj = {
    path: `/api/daily_message/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
