import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import ReactVideoPlayer from "src/components/ReactVideoPlayer/ReactVideoPlayer";
import { recording_detail_api } from "src/DAL/Recording/RecordingsApi";
import { useState } from "react";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function DelegateRecordingDetail() {
  const params = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [recordingDetail, setRecordingDetail] = useState();

  const get_recording_detail = async () => {
    let result = await recording_detail_api(params.recording_slug);
    if (result.code == 200) {
      setRecordingDetail(result.recording);
      setIsLoading(false);
    } else {
      navigate(`/recording-list`);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  let breadCrumbMenu = [
    {
      title: "Delegate Recording",
      navigation: `/delegate-recordings`,
      active: false,
    },
    {
      title: recordingDetail?.title,
      active: true,
    },
  ];

  useEffect(() => {
    if (state) {
      setRecordingDetail(state);
      setIsLoading(false);
      window.history.replaceState({}, document.title);
    } else {
      get_recording_detail();
    }
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-8">
          <h2>{recordingDetail.title}</h2>
        </div>
      </div>

      <div className="row media-margin">
        <div className="col-12">
          {recordingDetail.video_url == "" ? (
            <img
              src={s3baseUrl + recordingDetail.recording_image.thumbnail_1}
            />
          ) : (
            <ReactVideoPlayer url={recordingDetail.video_url} />
          )}
        </div>
        <div className="col-12 section-space">
          {recordingDetail.audio_recording && (
            <audio
              className="w-100"
              src={s3baseUrl + recordingDetail.audio_recording}
              controls
            />
          )}
        </div>
        <div className="col-12 section-space">
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: recordingDetail.short_description,
              }}
            ></div>
          </p>
        </div>
      </div>
    </div>
  );
}
export default DelegateRecordingDetail;
