import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import "react-tagsinput/react-tagsinput.css";
import TagsInput from "react-tagsinput";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";

export default function EmailNotifySetting(props) {
  const { onCloseDrawer, data, open } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState([]);
  const [inputs, setInputs] = useState({
    email_message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let cc_email_array = [];
    let cc_words_email_array = [];
    selected?.map((item, index) => {
      var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const check = pattern.test(item);
      if (check == true) {
        const email_object = { email: item };
        cc_email_array.push(email_object);
      } else {
        cc_words_email_array.push(item);
      }
    });
    if (!inputs.email_message) {
      enqueueSnackbar(` Email Messages Is required`, {
        variant: "error",
      });
    } else {
      if (cc_email_array.length == selected.length) {
        let selected_obj = {
          ...data,
          email_notification_info: {
            ...inputs,
            cc_emails: cc_email_array,
            email_message: inputs.email_message,
          },
        };
        onCloseDrawer(selected_obj, "email_notification_access");
      } else {
        const wrappedWords = cc_words_email_array?.map((word) => `"${word}"`);
        const paragraph = wrappedWords.join(" ");
        enqueueSnackbar(`${paragraph} is must be a valid Email in CC Emails`, {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (open && data.email_notification_info) {
      setInputs(data.email_notification_info);
      let Array_selected = [];
      data?.email_notification_info?.cc_emails?.map((item, index) => {
        Array_selected?.push(item.email);
      });
      setSelected(Array_selected);
    }
  }, [open]);

  return (
    <div className="container-fluid new-memories">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div class="col-12 col-md-6 mb-4">
            <h2
              style={{
                color: "white",
                marginBottom: 0,
              }}
            >
              Email Notification Configuration
            </h2>
          </div>
          <div class="col-12 col-md-6 text-end mb-4">
            <button class="small-contained-button event-submit-button">
              Save
            </button>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Email Subject"
              variant="outlined"
              fullWidth
              required
              name="email_subject"
              value={inputs.email_subject}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Email Body Heading"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Main Email"
              variant="outlined"
              type="email"
              fullWidth
              name="main_email"
              value={inputs.main_email}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
            <TagsInput
              value={selected}
              onChange={setSelected}
              inputProps={{
                placeholder: "CC Emails",
              }}
              name="cc_emails"
              type="email"
              label="cc_emails"
            />
          </div>

          <div className="col-12 mt-3">
            <h4 style={{ color: "white" }}>Email Body Message *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="email_message"
              editorHeight={320}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
