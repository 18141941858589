import { invokeApi } from "../../bl_libs/invokeApi";

export const list_funnel_report = async (data) => {
  const requestObj = {
    path: `/app/list_funnel_report`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const detail_funnel_report = async (id) => {
  const requestObj = {
    path: `/app/detail_funnel_report/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const ga4_real_time_report = async () => {
  const requestObj = {
    path: `/app/ga4_real_time_report`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_funnel_api = async (data) => {
  const requestObj = {
    path: `/app/create_funnel_report`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_funnel_api = async (data, id) => {
  const requestObj = {
    path: `/app/update_funnel_report/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_funnel_report = async (id) => {
  const requestObj = {
    path: `/app/delete_funnel_report/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
