import * as React from "react";
import { useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { programmeListing } from "src/DAL/Programme/Programme";
import { paymentTemplateListApi, productListApi } from "src/DAL/member/member";
import { consultantListing } from "src/DAL/consultant/consultant";
import { EditPaymentTemplateApi } from "src/DAL/PaymentTemplate/paymentTemplateApi";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageCommissionPaymentTemplate() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [consultantValue, setConsultantValue] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [products, setProducts] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  const [installmentAmount, setInstallmentAmount] = useState("");

  const [inputs, setInputs] = useState({
    commission_info: {
      main_refferal_commission: 0,
      public_user_commission_amount: 0,
      public_user_commission_amount_for_first_transaction: 0,
      public_user_commission_amount_for_next_transaction: 0,
      associate_user_commission_amount: 0,
      associate_user_commission_amount_for_first_transaction: 0,
      associate_user_commission_amount_for_next_transaction: 0,
      delegate_user_commission_amount: 0,
      delegate_user_commission_amount_for_first_transaction: 0,
      delegate_user_commission_amount_for_next_transaction: 0,
      dynamite_digital_user_commission_amount: 0,
      dynamite_digital_user_commission_amount_for_first_transaction: 0,
      dynamite_digital_user_commission_amount_for_next_transaction: 0,
      main_refferal_commission_amount_for_first_transaction: 0,
      main_refferal_commission_amount_for_next_transaction: 0,
    },
  });

  const consultantListData = async () => {
    setIsLoading(true);
    let result = await consultantListing();
    if (result.code == 200) {
      setConsultantValue(result.consultant_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const paymentTemplates = async () => {
    setIsLoading(true);
    let result = await paymentTemplateListApi();
    if (result.code == 200) {
      setTemplates(result.payment_template);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };
  const productList = async () => {
    setIsLoading(true);
    let result = await productListApi();
    if (result.code == 200) {
      setProducts(result.product);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };
  const programmeList = async () => {
    setIsLoading(true);
    let result = await programmeListing();
    if (result.code == 200) {
      setProgrammes(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const handleChangeCommission = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({
      ...values,
      commission_info: { ...values.commission_info, [name]: value },
    }));
  };
  const ChangeCommissonAmount = () => {
    if (inputs.payment_access === "onetime") {
      setInputs((values) => ({
        ...values,
        commission_info: {
          ...values.commission_info,
          public_user_commission_amount_for_first_transaction: 0,
          public_user_commission_amount_for_next_transaction: 0,
          associate_user_commission_amount_for_first_transaction: 0,
          associate_user_commission_amount_for_next_transaction: 0,
          delegate_user_commission_amount_for_first_transaction: 0,
          delegate_user_commission_amount_for_next_transaction: 0,
          dynamite_digital_user_commission_amount_for_next_transaction: 0,
          dynamite_digital_user_commission_amount_for_first_transaction: 0,
        },
      }));
    } else {
      setInputs((values) => ({
        ...values,
        commission_info: {
          ...values.commission_info,
          public_user_commission_amount: 0,
          associate_user_commission_amount: 0,
          delegate_user_commission_amount: 0,
          dynamite_digital_user_commission_amount: 0,
        },
      }));
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let postData = {};
    const set_commisson = await ChangeCommissonAmount();
    if (set_commisson) {
      if (inputs.payment_request_type === "onetime") {
        postData = {
          title: inputs.title,
          template_type: inputs.payment_request_type,
          total_amount: inputs.total_amount,
          //   initial_amount: inputs.initial_amount,
          currency: inputs.currency,
          //   no_of_installment: inputs.no_of_installments,
          vat_number: inputs.vat_number,
          short_description: inputs.transaction_note,
          program: inputs.programme._id,
          product: inputs.product._id,
          show_on_consultant: inputs.showOnConsultant,
          commission_info: inputs.commission_info,
        };
      } else if (inputs.payment_request_type === "recurring") {
        postData = {
          title: inputs.title,
          interval_type: inputs.plan_type,
          template_type: inputs.payment_request_type,
          total_amount: inputs.total_amount,
          initial_amount: inputs.initial_amount,
          currency: inputs.currency,
          no_of_installment: inputs.month,
          vat_number: inputs.vat_number,
          short_description: inputs.transaction_note,
          program: inputs.programme._id,
          product: inputs.product._id,
          show_on_consultant: inputs.showOnConsultant,
          commission_info: inputs.commission_info,
        };
        if (inputs.plan_type == "custom") {
          postData.number_of_days =
            inputs.number_of_days == undefined ? 0 : inputs.number_of_days;
        }
      }
    }

    setIsLoading(true);
    const result = await EditPaymentTemplateApi(postData, params.id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeProduct = (value) => {
    setInputs((values) => ({ ...values, product: value }));
  };

  const handleChangeProgramme = (value) => {
    setInputs((values) => ({ ...values, programme: value }));
  };

  useEffect(() => {
    setInstallmentAmount(state.installment_amount);
    setInputs({
      ...inputs,
      ["title"]: state.name,
      ["currency"]: state.currency,
      ["vat_number"]: state.vat_number,
      ["programme"]: state.program,
      ["product"]: state.product,
      ["payment_request_type"]: state.template_type,
      ["total_amount"]: state.total_amount,
      ["transaction_note"]: state.description,
      ["installment_amount"]: state.installment_amount,
      ["initial_amount"]: state.initial_amount,
      ["month"]: state.no_of_installment,
      ["plan_type"]: state.interval_type,
      ["number_of_days"]: state.number_of_days,
      ["showOnConsultant"]: state.show_on_consultant,
      ["commission_info"]: state.commission_info
        ? state.commission_info
        : {
            public_user_commission_amount: 0,
            public_user_commission_amount_for_first_transaction: 0,
            public_user_commission_amount_for_next_transaction: 0,
            associate_user_commission_amount: 0,
            associate_user_commission_amount_for_first_transaction: 0,
            associate_user_commission_amount_for_next_transaction: 0,
            delegate_user_commission_amount: 0,
            delegate_user_commission_amount_for_first_transaction: 0,
            delegate_user_commission_amount_for_next_transaction: 0,
            dynamite_digital_user_commission_amount: 0,
            dynamite_digital_user_commission_amount_for_first_transaction: 0,
            dynamite_digital_user_commission_amount_for_next_transaction: 0,
          },
    });
  }, [state]);

  useEffect(() => {
    consultantListData();
    paymentTemplates();
    productList();
    programmeList();
  }, []);

  useEffect(() => {
    let installment =
      (inputs.total_amount - inputs.initial_amount) / inputs.month;
    setInstallmentAmount(installment);
  }, [inputs.total_amount, inputs.month, inputs.initial_amount]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Manage Commission</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            fullWidth
            required
            name="title"
            value={inputs.title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Currency *</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="currency"
              value={inputs.currency}
              label="currency"
              onChange={handleChange}
            >
              <MenuItem value="usd">Dollar</MenuItem>
              <MenuItem value="gbp">UK Pounds</MenuItem>
              <MenuItem value="eur">Euro</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Delegates Access *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="showOnConsultant"
              value={inputs.showOnConsultant}
              label="Delegates Access *"
              onChange={handleChange}
            >
              <MenuItem value="no">No Access</MenuItem>
              <MenuItem value="list">Listing Access</MenuItem>
              <MenuItem value="all">All Access</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Product"
            selectedOption={inputs.product}
            setSelectedOption={handleChangeProduct}
            optionsList={products}
            name="name"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Programme"
            selectedOption={inputs.programme}
            setSelectedOption={handleChangeProgramme}
            optionsList={programmes}
            name="title"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Payment request Type*
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="payment_request_type"
              value={inputs.payment_request_type}
              label="Payment Request Type"
              onChange={handleChange}
            >
              <MenuItem value="onetime">One Time</MenuItem>
              <MenuItem value="recurring">Recurring</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Total Amount"
            variant="outlined"
            fullWidth
            type="number"
            required
            name="total_amount"
            value={inputs.total_amount}
            onChange={handleChange}
          />
        </div>
        {inputs.payment_request_type === "recurring" ? (
          <>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label1">
                  Plan Payment Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label1"
                  id="demo-simple-select"
                  name="plan_type"
                  value={inputs.plan_type ? inputs.plan_type : "month"}
                  label="Plan Payment Type"
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="month">Monthly</MenuItem>
                  <MenuItem value="week">Weekly</MenuItem>
                  <MenuItem value="year">Yearly</MenuItem>
                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
              </FormControl>
            </div>
            {inputs.plan_type == "custom" && (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="No of days"
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  name="number_of_days"
                  value={inputs.number_of_days}
                  onChange={handleChange}
                />
              </div>
            )}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Initial Amount"
                variant="outlined"
                fullWidth
                type="number"
                required
                name="initial_amount"
                value={inputs.initial_amount}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="No of Installments"
                variant="outlined"
                fullWidth
                type="number"
                required
                name="month"
                value={inputs.month}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Installment Amount"
                variant="outlined"
                fullWidth
                type="number"
                required
                aria-readonly
                name="installment_amount"
                value={installmentAmount}
                // onChange={handleChange}
              />
            </div>
            {/* commission */}
            <div className="col-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Public User Commission Amount for first recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="public_user_commission_amount_for_first_transaction"
                    value={
                      inputs.commission_info
                        .public_user_commission_amount_for_first_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Public User Commission Amount for next recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="public_user_commission_amount_for_next_transaction"
                    value={
                      inputs.commission_info
                        .public_user_commission_amount_for_next_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Associate User Commission Amount for first recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="associate_user_commission_amount_for_first_transaction"
                    value={
                      inputs.commission_info
                        .associate_user_commission_amount_for_first_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Associate User Commission Amount for next recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="associate_user_commission_amount_for_next_transaction"
                    value={
                      inputs.commission_info
                        .associate_user_commission_amount_for_next_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Delegate User Commission Amount for first recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="delegate_user_commission_amount_for_first_transaction"
                    value={
                      inputs.commission_info
                        .delegate_user_commission_amount_for_first_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Delegate User Commission Amount for next recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="delegate_user_commission_amount_for_next_transaction"
                    value={
                      inputs.commission_info
                        .delegate_user_commission_amount_for_next_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Team Diego  Commission Amount for first recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="dynamite_digital_user_commission_amount_for_first_transaction"
                    value={
                      inputs.commission_info
                        .dynamite_digital_user_commission_amount_for_first_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Team Diego  Commission Amount for next recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="dynamite_digital_user_commission_amount_for_next_transaction"
                    value={
                      inputs.commission_info
                        .dynamite_digital_user_commission_amount_for_next_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
              </div>
            </div>
            {/* commission end */}
          </>
        ) : (
          <>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4"></div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Public User Commission Amount"
                variant="outlined"
                fullWidth
                type="number"
                name="public_user_commission_amount"
                value={inputs.commission_info.public_user_commission_amount}
                onChange={handleChangeCommission}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Associate User Commission Amount"
                variant="outlined"
                fullWidth
                type="number"
                name="associate_user_commission_amount"
                value={inputs.commission_info.associate_user_commission_amount}
                onChange={handleChangeCommission}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Delegate User Commission Amount"
                variant="outlined"
                fullWidth
                type="number"
                name="delegate_user_commission_amount"
                value={inputs.commission_info.delegate_user_commission_amount}
                onChange={handleChangeCommission}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Team Diego  Commission Amount"
                variant="outlined"
                fullWidth
                type="number"
                name="dynamite_digital_user_commission_amount"
                value={
                  inputs.commission_info.dynamite_digital_user_commission_amount
                }
                onChange={handleChangeCommission}
              />
            </div>
          </>
        )}

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Vat Number"
            variant="outlined"
            fullWidth
            // type="number"
            name="vat_number"
            value={inputs.vat_number}
            onChange={handleChange}
          />
        </div>

        <div className="col-12 mt-5">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description"
              multiline
              rows={6}
              name="transaction_note"
              value={inputs.transaction_note}
              onChange={handleChange}
            />
          </FormControl>
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
