import { invokeApi } from "../../bl_libs/invokeApi";
export const _add_Bonus_road_map = async (data) => {
  const requestObj = {
    path: `/api/bonus_road_map`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _edit_bonus_Api = async (postData, _id) => {
  const requestObj = {
    path: `/api/bonus_road_map/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const _bonus_listApi = async (data) => {
  const requestObj = {
    path: `/api/bonus_road_map`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const road_Delete_Api = async (id) => {
  const requestObj = {
    path: `/api/bonus_road_map//${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
