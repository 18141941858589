import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import {
  add_general_gratitude_api,
  gratitude_detail_api,
  update_general_gratitude_api,
} from "src/DAL/gratitude/gratitudeApi";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateGratitude() {
  const navigate = useNavigate();
  const { gratitude_id } = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [formType, setFormType] = useState("ADD");
  const [oldImage, setOldImage] = useState("");
  const [inputs, setInputs] = useState({
    status: true,
    order: 0,
    general_gratitude_text: "",
    image: {},
  });

  const handleFormatData = (data) => {
    setFormType("EDIT");
    setInputs(data);
    setOldImage(data.general_gratitude_image.thumbnail_1);
    setIsLoading(false);
    window.history.replaceState({}, document.title);
  };

  const fileChangedHandler = (e, type) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      [e.target.name]: e.target.files[0],
    });
  };

  const getGratitudeData = async () => {
    setIsLoading(true);
    let result = await gratitude_detail_api(gratitude_id);
    if (result.code == 200) {
      handleFormatData(result.general_gratitude);
      setIsLoading(false);
    } else {
      navigate(`/gratitude`);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("general_gratitude_text", inputs.general_gratitude_text);
    formData.append("status", inputs.status);

    if (formType === "EDIT") {
      if (file) {
        formData.append("image", inputs.image);
      }
    } else {
      formData.append("image", inputs.image);
    }

    const result =
      formType === "ADD"
        ? await add_general_gratitude_api(formData)
        : await update_general_gratitude_api(formData, gratitude_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/gratitude`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (gratitude_id) {
      if (state) {
        handleFormatData(state);
      } else {
        getGratitudeData();
      }
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/gratitude`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-1">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Gratitude`}</h2>
        </div>
      </div>

      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image *</p>
              <FormHelperText className="pt-0">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {file ? (
                <img src={file} height="50" />
              ) : (
                oldImage && <img src={s3baseUrl + oldImage} height="50" />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={(e) => {
                    fileChangedHandler(e, "image");
                  }}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image?.name}</p>
          )}
        </div>
        <div className="col-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              required
              value={inputs.status}
              label="Status *"
              onChange={handleChange}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 mt-4">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description *"
              multiline
              rows={6}
              name="general_gratitude_text"
              value={inputs.general_gratitude_text}
              onChange={handleChange}
            />
          </FormControl>
        </div>

        <div className="text-end mt-4">
          <button className="small-contained-button">
            {formType == "ADD" ? "Submit" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
