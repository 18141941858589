import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { addStripeApi, siteSettingApi } from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddStripeSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    sandBox_publish_key: "Active Campaign",
    sandBox_secret_key: "",
    live_publish_key: "",
    live_secret_key: "",
    stripe_mode: "",
    sandbox_stripe_reader_registration_code: "",
    stripe_reader_registration_code: "",
  });
  const siteSettingData = async () => {
    setIsLoading(true);
    const result = await siteSettingApi();
    if (result.code == 200) {
      setInputs(result?.site_setting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    let postData = {
      sandBox_publish_key: inputs.sandBox_publish_key,
      sandBox_secret_key: inputs.sandBox_secret_key,
      live_publish_key: inputs.live_publish_key,
      live_secret_key: inputs.live_secret_key,
      stripe_mode: inputs.stripe_mode,
      stripe_reader_registration_code: inputs.stripe_reader_registration_code,
      sandbox_stripe_reader_registration_code:
        inputs.sandbox_stripe_reader_registration_code,
    };

    const result = await addStripeApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    siteSettingData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-6">
          <h2>Stripe Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Sandbox Publish Key"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="sandBox_publish_key"
              value={inputs.sandBox_publish_key}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Sandbox Secret Key "
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="sandBox_secret_key"
              value={inputs.sandBox_secret_key}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Live Publish Key"
              variant="outlined"
              fullWidth
              required
              name="live_publish_key"
              value={inputs.live_publish_key}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Live Secret Key"
              variant="outlined"
              fullWidth
              required
              name="live_secret_key"
              value={inputs.live_secret_key}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Stripe Mode *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="stripe_mode"
                value={inputs.stripe_mode}
                label="Stripe Mode *"
                onChange={handleChange}
              >
                <MenuItem value="sandBox">SandBox</MenuItem>
                <MenuItem value="live">Live</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Sandbox Stripe Reader Registration Code"
              variant="outlined"
              fullWidth
              name="sandbox_stripe_reader_registration_code"
              value={inputs.sandbox_stripe_reader_registration_code}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Stripe Reader Registration Code"
              variant="outlined"
              fullWidth
              name="stripe_reader_registration_code"
              value={inputs.stripe_reader_registration_code}
              onChange={handleChange}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
