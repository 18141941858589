import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import MemberProgramPopover from "../MembersList/memberProgramPopOver";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MemberList({ members }) {
  const [membersList, setMembersList] = useState([]);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [program, setprogram] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;

  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const MemberValue = () => {
    setIsLoading(true);
    const data = members.map((member) => {
      let full_name = "N/A";
      let user_email = "N/A";
      if (member.first_name) {
        full_name = member.first_name + " " + member.last_name;
      }
      if (member.email) {
        user_email = member.email;
      }
      return {
        ...member,
        full_name,
        user_email,
        table_avatar: {
          src: s3baseUrl + member?.image?.thumbnail_1,
          alt: member?.first_name,
        },
      };
    });
    setMembersList(data);
    setIsLoading(false);
  };

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "table_avatar", label: "Profile", type: "thumbnail" },
    { id: "full_name", label: "Name" },
    { id: "user_email", label: "Email" },
    {
      id: "program",
      label: "Programmes",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        return (
          <div>
            {firstFiveprograms?.map((program, i) => {
              if (program._id !== null) {
                return (
                  <>
                    <div
                      key={i}
                      dangerouslySetInnerHTML={{
                        __html: program?._id?.title + ",",
                      }}
                    ></div>
                    {i == 4 && (
                      <p
                        className="mb-1 mt-1 view-more"
                        aria-describedby={id}
                        variant="contained"
                        onClick={(e) => handleClickPopUP(e, row)}
                      >
                        view more
                      </p>
                    )}
                  </>
                );
              }
            })}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    MemberValue();
  }, [members]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={membersList}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
        <MemberProgramPopover
          handleClick={handleClickPopUP}
          handleClose={handleClosePopUp}
          anchorEl={anchorEl}
          id={id}
          open={opens}
          program={program}
          navigatePage={true}
        />
      </div>
    </>
  );
}
