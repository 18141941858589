import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useSnackbar } from 'notistack';

const PGIModeContext = React.createContext();

export const usePGIMode = () => useContext(PGIModeContext);
export function ContextPGIMode({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */
  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();
  const [mode, setMode] = React.useState(false);
  const [profileNewImage, setProfileNewImage] = React.useState();
  const [logo, setLogo] = React.useState();
  const [Navlist, setNavlist] = useState("");
  const [wellcomeMessage, setWellcomeMessage] = useState("");
  const [defaultTimeZone, setDefaultTimeZone] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [metaTitle, setMetaTitle] = React.useState("");
  const [windowWidth, setWindowWidth] = useState(false);
  const [viewMode, setViewMode] = useState(
    localStorage.getItem("view_mode_progamme")
  );
  const [viewModeLessons, setViewModeLessons] = useState(
    localStorage.getItem("view_mode_Lessons")
  );
  const [viewModeProgramModule, setViewModeProgramModule] = useState(
    localStorage.getItem("view_mode_program_module")
  );
  const [viewModeLessonsRecording, setViewModeLessonsRecording] = useState(
    localStorage.getItem("view_mode_lessons_recording")
  );

  /* ------------------------------------------------------
  ------------------/ Hooks Functions /--------------------
  ------------------------------------------------------- */

  const handleChangeMode = (value) => {
    //console.log(value, "change mode");
    setMode(value);
    setProfileNewImage(localStorage.getItem("image"));
  };

  useEffect(() => {
    setProfileNewImage(localStorage.getItem("image"));
  }, [localStorage.getItem("image")]);

  useEffect(() => {
    setLogo(localStorage.getItem("adminLogo"));
    //console.log(logo, "logoContext");
  }, [localStorage.getItem("adminLogo")]);
  useEffect(() => {
    setMetaTitle(localStorage.getItem("metaTitle"));
    ////console.log(metaTitle, "titleContext");
  }, [localStorage.getItem("metaTitle")]);
  useEffect(() => {
    localStorage.setItem("view_mode_progamme", viewMode);
  }, [viewMode]);
  //console.log(metaTitle, "outSidemetaTitle");
  const collection = {
    mode,
    handleChangeMode,
    profileNewImage,
    setProfileNewImage,
    logo,
    setLogo,
    setWellcomeMessage,
    setNavlist,
    setDefaultTimeZone,
    wellcomeMessage,
    defaultTimeZone,
    Navlist,
    metaTitle,
    viewMode,
    setViewMode,
    setUserInfo,
    userInfo,
    viewModeLessons,
    setViewModeLessons,
    viewModeProgramModule,
    setViewModeProgramModule,
    viewModeLessonsRecording,
    setViewModeLessonsRecording,
    windowWidth,
    setWindowWidth,
  };
  return (
    <PGIModeContext.Provider value={collection}>
      {children}
    </PGIModeContext.Provider>
  );
}
