import { Icon } from "@iconify/react";
import androidFilled from "@iconify/icons-ant-design/android-filled";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Tooltip, Typography } from "@mui/material";

// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import { get_root_value } from "src/utils/domUtils";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(2, 0),
  color: get_root_value("--input-text-color"),
  backgroundColor: get_root_value("--sidebars-background-color"),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.info.dark,
    0
  )} 0%, ${alpha(theme.palette.info.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

export default function AppIncomeSales({ total, title, icon, currency }) {
  return (
    <RootStyle sx={{ height: "100%" }}>
      <IconWrapperStyle>
        <b style={{ fontSize: "30px", color: "#f6bd4b" }}>£</b>
      </IconWrapperStyle>
      <Tooltip
        title={
          <span style={{ fontSize: "18px", padding: "20px" }}>
            £{`${total.toFixed(2)}`}
          </span>
        }
      >
        <Typography
          variant="h3"
          style={{ textTransform: "uppercase", cursor: "pointer" }}
        >
          {currency === false ? total : "£" + fShortenNumber(total)}
        </Typography>
      </Tooltip>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title ? title : "Recordings"}
      </Typography>
    </RootStyle>
  );
}
