import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import "react-tagsinput/react-tagsinput.css";
import { Icon } from "@iconify/react";
import TextareaAutosize from "react-textarea-autosize";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import LinkPopup from "./LinkPopup";
import { AddUpdateNotificationActionApi } from "src/DAL/NotificationAction/NotificationSetting";
import { useEffect } from "react";

export default function MessageSetting({ onCloseDrawer, data, listing }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openLink, setOpenLink] = useState(false);

  const handleGetSelectedText = () => {
    const inputField = document.getElementById("inputField");
    if (inputField) {
      const selectedText = inputField.value.substring(
        inputField.selectionStart,
        inputField.selectionEnd
      );
      return selectedText;
    }
  };
  const handleOnBold = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "**" + seleted_text + "**"
      );
      setMessage(newText);
    }
  };
  const handleOpenLinkPopup = () => {
    setOpenLink(true);
  };
  const handleOnItalic = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "*" + seleted_text + "*"
      );
      setMessage(newText);
    }
  };
  const handleAddLink = (values) => {
    let make_link = ` [${values.display_text}](${values.link_address}) `;
    const inputField = document.getElementById("inputField");
    const start = inputField.selectionStart;
    const end = inputField.selectionEnd;
    // Insert the text at the cursor position
    setMessage(message.slice(0, start) + make_link + message.slice(end));
    // Move the cursor after the inserted text
    inputField.setSelectionRange(
      start + make_link.length,
      start + make_link.length
    );
    setOpenLink(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {
      action: "message_notification_access",
      action_object: {
        message: message,
      },
    };

    const result = await AddUpdateNotificationActionApi(
      data.notification_action_slug,
      postData
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      listing();
      onCloseDrawer();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  function handleEditorInit() {
    setTimeout(() => {
      let containerWarning = document.getElementById("inputField");
      if (containerWarning) {
        containerWarning.style.height = "150px";
      }
    }, 100);
  }

  useEffect(() => {
    handleEditorInit();
  }, []);

  useEffect(() => {
    const message = data?.message_notification_info?.message;
    if (message) {
      setMessage(message);
    }
    handleEditorInit();
  }, [data]);

  return (
    <div className="container-fluid new-memories">
      <GeneralPopUpModel
        open={openLink}
        setOpen={setOpenLink}
        title={"Insert Link"}
        componentToPassDown={<LinkPopup handleAddLink={handleAddLink} />}
      />
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-12 mt-3">
          <div className="w-100">
            <h4>Write Message*</h4>
            <div
              className={`chat-formatters-box chat-formatters-border d-flex`}
              style={{
                backgroundColor: "var(--background-primary-color)",
                height: "35px",
              }}
            >
              <>
                <div className="formatter-icon" onClick={handleOnBold}>
                  <Icon
                    fontSize="15"
                    style={{ color: "var(--portal-theme-primary)" }}
                    icon="octicon:bold-24"
                  />
                </div>
                <div className="formatter-icon" onClick={handleOnItalic}>
                  <Icon
                    fontSize="15"
                    style={{ color: "var(--portal-theme-primary)" }}
                    icon="fe:italic"
                  />
                </div>
                <div className="formatter-icon" onClick={handleOpenLinkPopup}>
                  <Icon
                    fontSize="15"
                    style={{ color: "var(--portal-theme-primary)" }}
                    icon="ion:link"
                  />
                </div>
              </>
            </div>

            <div className="position-relative parent">
              <TextareaAutosize
                id="inputField"
                className={`chat-send-input chat-formatters-border `}
                style={{
                  height: "300px !important",
                  backgroundColor: "var(--background-secondary-color)",
                }}
                type="text"
                value={message}
                maxRows={6}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Write your message..."
                required
                autoFocus
              />
            </div>
          </div>
        </div>
        <div className="text-end mt-4">
          <button type="submit" className="small-contained-button">
            {isLoading ? "Updating" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
