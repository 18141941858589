import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { menuList } from "src/DAL/Menu/Menu";
import {
  add_programme_api,
  deleteAudioApi,
  update_programme_api,
  programme_detail_api,
  update_programme_config_api,
} from "src/DAL/Programme/Programme";
import { s3baseUrl } from "../../config/config";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { urlPatternValidation } from "src/utils/constant";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ProgrammeTabConfiguration() {
  const navigate = useNavigate();
  const { programme_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { state } = useLocation();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [audioFile, setAudio] = useState();
  const [oldAudio, setOldAudio] = useState("");
  const [menuLists, setMenuList] = useState([]);
  const [navItems, setNavitems] = useState([]);
  const [formType, setFormType] = useState("ADD");
  const [programName, setProgramName] = useState("");
  const [images, setImages] = useState({
    main_image: "",
    program_lock_icon: "",
  });

  const [oldImages, setOldImages] = useState({
    main_image: "",
    program_lock_icon: "",
  });
  const [inputs, setInputs] = useState([
    {
      document_tab_title: "resources",
      document_tab_status: true,
      order: 1,
    },

    {
      recording_tab_title: "recordings",
      recording_tab_status: true,
      order: 2,
    },
    {
      lesson_tab_title: "lessons",
      lesson_tab_status: true,
      order: 3,
    },
    {
      module_tab_title: "program modules",
      module_tab_status: true,
      order: 4,
    },
    {
      reviews_tab_title: "reviews",
      reviews_tab_status: true,
      order: 5,
    },
    {
      section_tab_title: "section",
      section_tab_status: true,
      order: 6,
    },
  ]);

  const getProgrammeDetail = async () => {
    setIsLoading(true);
    const result = await programme_detail_api(programme_slug);
    if (result.code === 200) {
      handleFormat(result.program);
      setProgramName(result?.program?.title);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  function checkOrderNumbers(postData) {
    const orderNumbers = new Set();

    for (const data of postData) {
      if (orderNumbers.has(data.order)) {
        return false; // Found a duplicate order number
      }
      orderNumbers.add(data.order);
    }

    return true; // No duplicate order numbers found
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let postData = [
      {
        document_tab_title: inputs[0].document_tab_title,
        document_tab_status: inputs[0].document_tab_status,
        order: +inputs[0].order,
      },

      {
        recording_tab_title: inputs[1].recording_tab_title,
        recording_tab_status: inputs[1].recording_tab_status,
        order: +inputs[1].order,
      },
      {
        lesson_tab_title: inputs[2].lesson_tab_title,
        lesson_tab_status: inputs[2].lesson_tab_status,
        order: +inputs[2].order,
      },
      {
        module_tab_title: inputs[3].module_tab_title,
        module_tab_status: inputs[3].module_tab_status,
        order: +inputs[3].order,
      },
      {
        reviews_tab_title: inputs[4].reviews_tab_title,
        reviews_tab_status: inputs[4].reviews_tab_status,
        order: +inputs[4].order,
      },
      {
        section_tab_title: inputs[5].section_tab_title,
        section_tab_status: inputs[5].section_tab_status,
        order: +inputs[5].order,
      },
    ];
    const isOrderValid = checkOrderNumbers(postData);
    if (!isOrderValid) {
      enqueueSnackbar("Order numbers cannot be the same", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }
    let isValidOrder = true;

    postData.forEach((file, index) => {
      const currentOrder = file.order;

      if (currentOrder < 1 || currentOrder > 6) {
        // console.error(`Error: Invalid order value found at index ${index}!`);
        isValidOrder = false;
        enqueueSnackbar("Order cannot be less then 1 or greater then 6", {
          variant: "error",
        });
        setIsLoading(false);
        return;
      }
    });
    if (isValidOrder) {
      let data = {
        program_configration: postData,
      };
      // console.log(data, "i am data");
      const result = await update_programme_config_api(data, programme_slug);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        handleNavigate();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event, index) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      const inputToUpdate = updatedInputs[index];
      inputToUpdate[name] = value;
      return updatedInputs;
    });
  };
  const handleFormat = (data) => {
    // console.log(data, "program_configration");
    if (data?.program_configration.length > 0) {
      setInputs(data?.program_configration);
    }

    setFormType("EDIT");
    setOldImages({
      ...oldImages,
      ["main_image"]: data.program_images.thumbnail_2,
      ["program_lock_icon"]: data.program_lock_icon,
    });
    setOldAudio(data.audio_file);
    setNavitems(data.nav_items);
    setIsLoading(false);
  };

  const handleNavigate = () => {
    if (formType == "ADD") {
      navigate(`/programmes`);
    } else {
      navigate(`/programmes/programmes-detail/${programme_slug}`);
    }
  };
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },

    {
      title: state ? state?.title : programName ? programName : "",
      navigation: `/programmes/programmes-detail/${programme_slug}`,
      active: false,
    },
    {
      title: "Tabs Configuration",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    if (programme_slug) {
      if (state) {
        handleFormat(state);
      } else {
        getProgrammeDetail();
      }
    }
  }, []);
  useEffect(() => {
    getProgrammeDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <span className="mb-3">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <h2>Tabs Configuration</h2>
        </div>
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Document Tab Title"
            variant="outlined"
            fullWidth
            name="document_tab_title"
            value={inputs[0].document_tab_title}
            onChange={(event) => handleChange(event, 0)}
            required
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Document Tab Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="document_tab_status"
              value={inputs[0].document_tab_status}
              label="document tab status"
              onChange={(event) => handleChange(event, 0)}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label=" Document Tab Order"
            variant="outlined"
            fullWidth
            required
            name="order"
            type="number"
            value={inputs[0].order}
            onChange={(event) => handleChange(event, 0)}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Recording Tab Title"
            variant="outlined"
            fullWidth
            name="recording_tab_title"
            value={inputs[1].recording_tab_title}
            onChange={(event) => handleChange(event, 1)}
            required
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Recording Tab Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="recording_tab_status"
              value={inputs[1].recording_tab_status}
              label="recording tab status"
              onChange={(event) => handleChange(event, 1)}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label=" Recording Tab Order"
            variant="outlined"
            fullWidth
            required
            name="order"
            type="number"
            value={inputs[1].order}
            onChange={(event) => handleChange(event, 1)}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Lesson Tab Title"
            variant="outlined"
            fullWidth
            name="lesson_tab_title"
            value={inputs[2].lesson_tab_title}
            onChange={(event) => handleChange(event, 2)}
            required
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Lesson Tab Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="lesson_tab_status"
              value={inputs[2].lesson_tab_status}
              label="lesson tab status"
              onChange={(event) => handleChange(event, 2)}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label=" Lesson Tab Order"
            variant="outlined"
            fullWidth
            required
            name="order"
            type="number"
            value={inputs[2].order}
            onChange={(event) => handleChange(event, 2)}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Module Tab Title"
            variant="outlined"
            fullWidth
            name="module_tab_title"
            value={inputs[3].module_tab_title}
            onChange={(event) => handleChange(event, 3)}
            required
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Module Tab Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="module_tab_status"
              label="Module tab status"
              value={inputs[3].module_tab_status}
              onChange={(event) => handleChange(event, 3)}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label=" Module Tab Order"
            variant="outlined"
            fullWidth
            required
            name="order"
            type="number"
            value={inputs[3].order}
            onChange={(event) => handleChange(event, 3)}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Reviews Tab Title"
            variant="outlined"
            fullWidth
            name="reviews_tab_title"
            value={inputs[4].reviews_tab_title}
            onChange={(event) => handleChange(event, 4)}
            required
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Reviews Tab Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="reviews_tab_status"
              label="Module tab status"
              value={inputs[4].reviews_tab_status}
              onChange={(event) => handleChange(event, 4)}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label=" Reviews Tab Order"
            variant="outlined"
            fullWidth
            required
            name="order"
            type="number"
            value={inputs[4].order}
            onChange={(event) => handleChange(event, 4)}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Section Tab Title"
            variant="outlined"
            fullWidth
            name="section_tab_title"
            value={inputs[5].section_tab_title}
            onChange={(event) => handleChange(event, 5)}
            required
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Section Tab Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="section_tab_status"
              value={inputs[5].section_tab_status}
              onChange={(event) => handleChange(event, 5)}
              label="section tab status"
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label=" Section Tab Order"
            variant="outlined"
            fullWidth
            required
            name="order"
            type="number"
            value={inputs[5].order}
            onChange={(event) => handleChange(event, 5)}
          />
        </div>
        <div className="text-end mt-4">
          <button className="small-contained-button">Submit</button>
        </div>
      </form>
    </div>
  );
}
