import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { AssetsDetailListing } from "src/DAL/digitalAssetsApi/assets";
import { s3baseUrl } from "src/config/config";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  { id: "name", label: "Name" },
  { id: "delegate", label: "Delegate" },
];

const TABLE_HEAD2 = [
  { id: "number", label: "#", type: "number" },
  { id: "name", label: "Name" },
  {
    id: "profile",
    label: "Profile",
    type: "thumbnail",
  },
  { id: "email", label: "Email" },
];

export default function DigitalAssestsDetail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [groupsList, setGroupsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [allUser, setallUser] = useState([]);
  const [consultant, setConsultant] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleNavigateAddUser = () => {
    navigate(`/calendar-pods/add-room-user/${state.room_slug}`, {
      state: state,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDetail = async () => {
    setIsLoading(true);
    let search_keyword =
      localStorage.getItem("assests_search_text") == null
        ? searchText
        : localStorage.getItem("assests_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const result = await AssetsDetailListing(
      params.id,
      page,
      rowsPerPage,
      search_keyword
    );
    const groups = [];
    result.asset.group?.map((group) => {
      let group_ = group._id;
      groups.push({
        name: group_.title,
        delegate: group_.delegate.length,
      });
    });
    setGroupsList(groups);

    const consultant = [];
    result.asset.consultant?.map((user) => {
      let member = user._id;
      if (member) {
        consultant.push({
          name: member.first_name + " " + member.last_name,
          profile: {
            src: s3baseUrl + member.image.thumbnail_1,
            alt: member.first_name,
          },
          email: member.email,
        });
      }
    });
    setConsultant(consultant);

    const allUser = result.asset_user?.map((member) => {
      return {
        name: member.first_name + " " + member.last_name,
        profile: {
          src: s3baseUrl + member.image.thumbnail_1,
          alt: member.first_name,
        },
        email: member.email,
      };
    });
    setallUser(allUser);
    setTotalCount(result.total_count);
    setTotalPages(result.total_pages);
    setIsLoading(false);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getDetail();
  };

  useEffect(() => {
    localStorage.setItem("assests_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("assests_search_text", "");
    } else {
      localStorage.setItem("assests_search_text", searchText);
    }
  }, [searchText]);

  useEffect(() => {
    getDetail();
  }, [rowsPerPage, page]);

  const TABS_OPTIONS = [
    {
      title: "Group list",
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={groupsList}
          className="card-with-background mt-3"
          pagePagination={true}
        />
      ),
    },
    {
      title: "Individual Delegate",
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD2}
          data={consultant}
          className="card-with-background mt-3"
          pagePagination={true}
        />
      ),
    },
    {
      title: "All Delegate",
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD2}
          data={allUser}
          className="card-with-background mt-3"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      ),
    },
  ];

  let breadCrumbMenu = [
    {
      title: "Digital Assets",
      navigation: `/digital-assets`,
      active: false,
    },
    {
      title: "Digital Assets Detail",
      navigation: `/digital-assets`,
      active: false,
    },
    {
      title: state?.title,
      active: true,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>

          {state?.room_type == "automated" && (
            <button
              className="small-contained-button float-end mt-1"
              onClick={handleNavigateAddUser}
            >
              Add Room User
            </button>
          )}
        </div>
        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
    </div>
  );
}
