import { invokeApi } from "../../bl_libs/invokeApi";

export const saleSectionLeadsListApi = async (data, loadMore) => {
  const requestObj = {
    path: `${loadMore ? loadMore : "/api/sales_section/all_leads"}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data ? data : "all",
  };
  return invokeApi(requestObj);
};
export const saleSectionMoveApi = async (data) => {
  const requestObj = {
    path: `/api/sales_section/change_section_order`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const saleLeadMoveToOtherSectionApi = async (data) => {
  const requestObj = {
    path: `/api/sales_section/move_leads_to_other_section`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const saleLeadDateChange = async (postData) => {
  const requestObj = {
    path: `/api/sales_section/update_lead_date`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const addAssociateApi = async (data) => {
  const requestObj = {
    path: `/api/sales_section/add_other_associate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const saleLeadMarkCompleteApi = async (data) => {
  const requestObj = {
    path: `/api/sales_section/change_lead_status`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteSectionLeadApi = async (id) => {
  const requestObj = {
    path: `/api/sales_section/delete_lead/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
