import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { emailTemplateApi } from "src/DAL/SiteSetting/siteSetting";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EmailTemplates() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [templatesList, setTemplatesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getEmailTemplates = async () => {
    setIsLoading(true);
    const result = await emailTemplateApi();
    if (result.code === 200) {
      const data = result.email_templates.map((template) => {
        return {
          ...template,
          preview: "Preview",
          template_title: template.template_title
            ? template.template_title
            : "N/A",
        };
      });
      setTemplatesList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (value) => {
    navigate(
      `/email-templates/edit-temail-emplate/${value.email_template_slug}`,
      {
        state: value,
      }
    );
  };

  const handleNavigatePreview = (object, index) => {
    navigate(`/email-templates/preview/${object.email_template_slug}`);
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "template_title", label: "Template Title", alignRight: false },
    {
      id: "preview",
      label: "Preview",
      alignRight: false,
      handleClick: handleNavigatePreview,
      className: "show-preview-template",
    },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
  ];

  useEffect(() => {
    getEmailTemplates();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
  ];
  const handleNavigate = () => {
    navigate(`/email-templates/add-temail-emplate`);
  };
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Email Template</h2>
          </div>
          {/* <div className="col-lg-6 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Email Template
            </button>
          </div> */}
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={templatesList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
