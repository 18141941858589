import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { detailContentPageApi } from "src/DAL/WebsitePages/WebsitePages";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { free_plan_popup_setting_api } from "src/DAL/SaleSections/saleSection";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function FreePlanPopupSetting() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { page_slug } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [pageData, setPageData] = useState();

  const [inputs, setInputs] = useState({
    login_description: "",
    login_button_text: "",
    logged_in_user_text: "",
    logout_button_text: "",
    login_popup_description: "",
    email_verification_description: "",
    pin_code_verification_description: "",
    change_password_description: "",
    submit_form_button_text: "",
    popup_background_color: "#FFFFFF",
    popup_text_color: "#000",
    popup_theme_color: "#ccbf8e",
    form_description: "",
    form_checkbox_text: "",
  });

  const getPageDetail = async () => {
    const result = await detailContentPageApi(page_slug);
    if (result.code == 200) {
      handleFormatData(result.sale_page);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let postData = {
      free_plan_popup_setting: inputs,
    };
    const result = await free_plan_popup_setting_api(postData, page_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFormatData = (data) => {
    setPageData(data);
    if (data.free_plan_popup_setting) {
      setInputs(data.free_plan_popup_setting);
    }
    setIsLoading();
  };

  useEffect(() => {
    if (state) {
      handleFormatData(state);
    } else {
      getPageDetail();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 display-flex mb-4">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">{pageData.sale_page_title}</span>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <h2>Free Plan Popup Setting</h2>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Already have an account text"
              variant="outlined"
              fullWidth
              name="login_description"
              value={inputs.login_description}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Login Button Text"
              variant="outlined"
              fullWidth
              name="login_button_text"
              value={inputs.login_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Logged In User Text"
              variant="outlined"
              fullWidth
              name="logged_in_user_text"
              value={inputs.logged_in_user_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Logout Button Text"
              variant="outlined"
              fullWidth
              name="logout_button_text"
              value={inputs.logout_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Submit Form Button Text"
              variant="outlined"
              fullWidth
              name="submit_form_button_text"
              value={inputs.submit_form_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Form Checkbox Text"
              variant="outlined"
              fullWidth
              name="form_checkbox_text"
              value={inputs.form_checkbox_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              type="color"
              label="Popup Background Color"
              variant="outlined"
              fullWidth
              name="popup_background_color"
              value={inputs.popup_background_color}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              type="color"
              label="Popup Text Color"
              variant="outlined"
              fullWidth
              name="popup_text_color"
              value={inputs.popup_text_color}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              type="color"
              label="Popup Theme Color"
              variant="outlined"
              fullWidth
              name="popup_theme_color"
              value={inputs.popup_theme_color}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-5">
            <h4>Signup Form Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="form_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-5">
            <h4>Login Form Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="login_popup_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-5">
            <h4>Email Verification Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="email_verification_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-5">
            <h4>Pin Code Verification Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="pin_code_verification_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-5">
            <h4>Change Password Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="change_password_description"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button" disabled={isLoadingForm}>
              {isLoadingForm ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
