import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import AddOrUpdateStripe from "./AddOrUpdateStripe";
import {
  delete_stripe_reader,
  list_stripe_reader,
} from "src/DAL/StripeReader/StripeReader";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "location_name", label: "Location Name", alignRight: false },
  { id: "location_id", label: "Location Id", alignRight: false },
  { id: "reader_title", label: "Reader Name", alignRight: false },
  { id: "reader_id", label: "Reader Id", alignRight: false },
  {
    id: "status",
    label: "Status",
    type: "row_status",
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function StripeReader() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [formType, setFormType] = useState("ADD");
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [drawerState, setdrawerState] = useState(false);
  const [editData, setEditData] = useState({});
  const [stripeList, setStripeList] = useState([]);

  const getStripeReader = async () => {
    setIsLoading(true);
    const result = await list_stripe_reader();
    if (result.code == 200) {
      console.log(result, "result__result");
      setStripeList(result.stripeReader);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_stripe_reader(deleteDoc._id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getStripeReader();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleOpenDrawer = () => {
    setdrawerState(true);
  };

  const handleCloseDrawer = () => {
    setdrawerState(false);
    setFormType("ADD");
  };

  const handleOpenDrawerUpdate = (value) => {
    setFormType("EDIT");
    setEditData(value);
    setdrawerState(true);
  };

  useEffect(() => {
    getStripeReader();
    setFormType("ADD");
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleOpenDrawerUpdate,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this call type?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Stripe Reader</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button
              onClick={handleOpenDrawer}
              className="small-contained-button"
            >
              Add Stripe Reader
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={stripeList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
        <CustomDrawer
          isOpenDrawer={drawerState}
          onOpenDrawer={handleOpenDrawer}
          onCloseDrawer={handleCloseDrawer}
          pageTitle={`${formType === "ADD" ? "Add" : "Edit"} Stripe Reader`}
          componentToPassDown={
            <AddOrUpdateStripe
              handleCloseDrawer={handleCloseDrawer}
              getStripeReader={getStripeReader}
              formType={formType}
              editData={editData}
            />
          }
        />
      </div>
    </>
  );
}
