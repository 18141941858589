// const DynamiteDiarySetting = () => {
//   return <>dynamite</>;
// };

// export default DynamiteDiarySetting;
import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import {
  clientSettingApi,
  UpdateDynamiteDiarySetting,
  UpdateMeditationSetting,
  updateWheelOfLifeSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { _get_attitude_accessment_setting } from "src/DAL/AttitudeAccessmentQuestions/AttitudeAccessmentSetting";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MeditationSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = React.useState({
    title: "",
    journal_text: "",
    coin_count: "",
    video_url: "",
    video_text: "",
    description: "",
    notes_placeholder_1: "",
    notes_placeholder_2: "",
  });

  const clientDetail = async () => {
    setIsLoading(true);
    const result = await _get_attitude_accessment_setting();
    if (result.code == 200) {
      // console.log(result, "kokoko");
      setInputs((inputs) => ({
        ...inputs,
        ["title"]: result.assessment_setting.mediation_settings?.diary_title,
        ["journal_text"]:
          result.assessment_setting.mediation_settings?.diary_journal_text,
        ["coin_count"]:
          result.assessment_setting.mediation_settings?.coins_count,
        ["video_url"]:
          result.assessment_setting.mediation_settings?.diary_video_url,
        ["video_text"]:
          result.assessment_setting.mediation_settings?.diary_dont_show_text,
        ["description"]:
          result.assessment_setting.mediation_settings?.diary_video_description,
        ["notes_placeholder_1"]:
          result.assessment_setting.mediation_settings?.notes_placeholder_1,
        ["notes_placeholder_2"]:
          result.assessment_setting.mediation_settings?.notes_placeholder_2,
      }));
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let data_obj = {
      diary_title: inputs.title,
      diary_journal_text: inputs.journal_text,
      coins_count: inputs.coin_count,
      diary_video_url: inputs.video_url,
      diary_dont_show_text: inputs.video_text,
      diary_video_description: inputs.description,
      notes_placeholder_1: inputs.notes_placeholder_1,
      notes_placeholder_2: inputs.notes_placeholder_2,
    };
    let mediation_settings = {
      mediation_settings: data_obj,
    };
    const result = await UpdateMeditationSetting(mediation_settings);
    if (result.code === 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    clientDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-6">
          <h2>Meditation Setting</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div class="col-12 wheel-of-life-setting-section-heading">
            {/* <h2 className="mt-4">Assessment</h2> */}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="title"
              value={inputs?.title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Coin Count"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="coin_count"
              value={inputs?.coin_count}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Video Url"
              variant="outlined"
              type="url"
              fullWidth
              required
              name="video_url"
              value={inputs?.video_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Dont Show Video Text"
              variant="outlined"
              fullWidth
              required
              name="video_text"
              value={inputs?.video_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Notes Placeholder 1"
              variant="outlined"
              fullWidth
              required
              name="notes_placeholder_1"
              value={inputs?.notes_placeholder_1}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Notes Placeholder 2"
              variant="outlined"
              fullWidth
              required
              name="notes_placeholder_2"
              value={inputs?.notes_placeholder_2}
              onChange={handleChange}
            />
          </div>

          <div className="col-12 mt-4">
            <h4>Journal Text</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              required
              name="journal_text"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Video Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              required
              name="description"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
