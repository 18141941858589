import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  delete_road_map_levels_api,
  road_map_levels_list_api,
} from "src/DAL/RoadMapLevelsApi/RoadMapLevelsApi";
import { s3baseUrl } from "src/config/config";
import { email_logs_list_api } from "src/DAL/EmailLogs/EmailLogs";
import EmailLogsPopUp from "./EmailLogsPopUp";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EmailLogs() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [emailBody, setEmailBody] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const [emailLogList, setEmailLogList] = useState([]);
  const EmailLogListList = async () => {
    setIsLoading(true);
    let search_keyword =
      localStorage.getItem("email_logs_search_text") == null
        ? searchText
        : localStorage.getItem("email_logs_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const result = await email_logs_list_api(page, rowsPerPage, search_keyword);
    if (result.code == 200) {
      const emailLogsList = result.email_logs.map((item, index) => {
        return {
          ...item,
          email: item.email ? item.email : "N/A",
        };
      });
      setEmailLogList(emailLogsList);
      setTotalCount(result.total_email_logs_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleNavigatePreview = (object, index) => {
    setOpenDelete(true);
    setEmailBody(object);
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("Email logs", newPage);
  };
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);

    EmailLogListList();
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };
  useEffect(() => {
    EmailLogListList();
  }, [rowsPerPage, page]);
  useEffect(() => {
    localStorage.setItem("email_logs_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("email_logs_search_text", "");
    } else {
      localStorage.setItem("email_logs_search_text", searchText);
    }
  }, [searchText]);
  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "email", label: "Email", alignRight: false },
    { id: "subject", label: "Subject", alignRight: false },

    {
      id: "body",
      label: "Preview",
      alignRight: false,
      renderData: (row) => {
        return (
          <p
            className="show-preview-template"
            style={{ cursor: "pointer" }}
            onClick={() => handleNavigatePreview(row.body)}
          >
            Preview
          </p>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      alignRight: false,
      renderData: (row) => {
        return row.status == "success" ? (
          <Chip label={row.status} color="success" />
        ) : (
          <Chip label={row.status} color="error" />
        );
      },
    },
  ];
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Email Logs </h2>
          </div>
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={emailLogList}
          // MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
          className="card-with-background"
        />
      </div>
      <EmailLogsPopUp
        open={openDelete}
        setOpen={setOpenDelete}
        emailBody={emailBody}
      />
    </>
  );
}
