import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { memberAndGroupsListApi } from "src/DAL/member/member";
import {
  AddQuestion,
  EditQuestion,
  getDetailQuestion,
} from "src/DAL/goalstatement/goalStatement";
import { useEffect } from "react";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddOrUpdateQuestion() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [personName, setPersonName] = useState([]);
  const [selectedExclude, setSelectedExclude] = useState([]);
  const [member, setMember] = useState([]);
  const [excludeMembers, setExcludeMembers] = useState([]);
  const [formType, setFormType] = useState("ADD");
  const [groupListing, setGroupListing] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [serchText, setSerchText] = useState("");
  const [excludeText, setExcludeText] = useState("");

  const [inputs, setInputs] = useState({
    question_statement: "",
    question_type: "textbox",
    question_placeholder: "",
    status: true,
    question_order: 0,
    groups: [],
    members: [],
  });

  const getGroupsAndMembers = async () => {
    const result = await memberAndGroupsListApi();
    if (result.code === 200) {
      setGroupListing(result.group);
      setPersonName(result.members);
      setExcludeMembers(result.members);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getSearchGroupsAndMembers = async () => {
    const result = await memberAndGroupsListApi(serchText);
    if (result.code === 200) {
      setPersonName(result.members);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getSearchExcludeAndMembers = async () => {
    const result = await memberAndGroupsListApi(excludeText);
    if (result.code === 200) {
      setExcludeMembers(result.members);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  const handleExcludeSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setExcludeText(value);
  };

  const handleChangeGroup = (value) => {
    setGroupsName(value);
  };

  const questionDetail = async () => {
    setIsLoading(true);
    const result = await getDetailQuestion(params.question_slug);
    if (result.code === 200) {
      setFormType("EDIT");
      setInputs(result.goal_statement);
      let member_object = [];
      result.goal_statement.member.map((member) => {
        member_object.push(member._id);
      });
      setMember(member_object);

      let exclude_members = [];
      result.goal_statement.exclude_members.map((member) => {
        exclude_members.push(member._id);
      });
      setSelectedExclude(exclude_members);

      let selected_group = [];
      result.goal_statement.group.map((group) => {
        selected_group.push(group._id);
      });
      setGroupsName(selected_group);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let group_array = [];
    if (groupsName.length > 0) {
      group_array = groupsName.map((group) => {
        return {
          group_slug: group.group_slug,
        };
      });
    }

    let selected_member_array = [];
    let selected_member_object = {};
    member.map((member) => {
      selected_member_object = {
        member_id: member._id,
      };
      selected_member_array.push(selected_member_object);
    });

    let exclude_member_array = [];
    let exclude_member_object = {};
    selectedExclude?.map((member) => {
      exclude_member_object = {
        _id: member._id,
      };
      exclude_member_array.push(exclude_member_object);
    });

    // member id getting end
    const postData = {
      question_statement: inputs.question_statement,
      question_type: inputs.question_type,
      question_placeholder: inputs.question_placeholder,
      status: inputs.status,
      group: group_array,
      member: selected_member_array,
      exclude_members: exclude_member_array,
    };

    if (formType === "EDIT") {
      postData.order = inputs.question_order;
    }

    const result =
      formType === "ADD"
        ? await AddQuestion(postData)
        : await EditQuestion(postData, params.question_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/goal-statement`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    getGroupsAndMembers();
  }, [serchText.length == 0]);

  useEffect(() => {
    getSearchGroupsAndMembers();
  }, [serchText]);

  useEffect(() => {
    getSearchExcludeAndMembers();
  }, [excludeText]);

  useEffect(() => {
    setSerchText("");
  }, [member]);

  useEffect(() => {
    setExcludeText("");
  }, [selectedExclude]);

  useEffect(() => {
    if (params && params.question_slug) {
      if (state) {
        setFormType("EDIT");
        setInputs(state);
        let selected_groups = [];
        state.group.map((group) => {
          if (group._id) {
            selected_groups.push(group._id);
          }
        });
        setGroupsName(selected_groups);

        let selected_members = [];
        state.member.map((member) => {
          if (member._id) {
            selected_members.push(member._id);
          }
        });
        setMember(selected_members);

        let exclude_members = [];
        state.exclude_members.map((member) => {
          if (member._id) {
            exclude_members.push(member._id);
          }
        });
        setSelectedExclude(exclude_members);
      } else {
        questionDetail();
      }
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/goal-statement`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Question`}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12"></div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Question"
              variant="outlined"
              required
              fullWidth
              name="question_statement"
              value={inputs.question_statement}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Question Placeholder"
              variant="outlined"
              fullWidth
              name="question_placeholder"
              value={inputs.question_placeholder}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Question Type *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="question_type"
                value={inputs.question_type}
                label="Question Type *"
                onChange={handleChange}
              >
                <MenuItem value="textbox">Textbox</MenuItem>
                <MenuItem value="textarea">Textarea</MenuItem>
              </Select>
            </FormControl>
          </div>
          {formType === "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Question question_order*"
                variant="outlined"
                fullWidth
                name="question_order"
                value={inputs.question_order}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Question Status *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Question Status *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="mt-4">
              <MUIAutocomplete
                inputLabel="Groups"
                selectedOption={groupsName}
                setSelectedOption={handleChangeGroup}
                optionsList={groupListing}
                multiple
                name="title"
              />
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="mt-4">
              <Autocomplete
                multiple
                id="tags-outlined"
                options={personName}
                getOptionLabel={(option) =>
                  option.first_name +
                  " " +
                  option.last_name +
                  " (" +
                  option.email +
                  ")"
                }
                filterSelectedOptions
                value={member}
                onChange={(event, newValue) => {
                  setMember(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Members"
                    placeholder="Members"
                    onChange={handleSearch}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <Autocomplete
              multiple
              id="tags-outlined"
              options={excludeMembers}
              getOptionLabel={(option) =>
                option.first_name + " (" + option.email + ")"
              }
              filterSelectedOptions
              value={selectedExclude}
              onChange={(event, newValue) => {
                setSelectedExclude(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Exclude Members"
                  placeholder="Exclude Members"
                  onChange={handleExcludeSearch}
                />
              )}
            />
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
