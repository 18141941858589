import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { add_challenge_exclude_member } from "src/DAL/consultant/consultant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AllMemberList = ({
  totalCount,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  pageCount,
  totalPages,
  handleChangePages,
  TABLE_HEAD_MEMBER_ALL,
  isAllLoading,
  groupAllMembers,
  searchText,
  setSearchText,
  searchFunction,
  setGroupAllMembers,
  checkbox,
}) => {
  const classes = useStyles();
  const params = useParams();
  const [openExclude, setOpenExclude] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState([]);

  let settings = {
    className: "card-with-background mt-3 ",
  };

  const handleEdit = () => {
    setOpenExclude(true);
  };

  const handleSubmitExclude = async () => {
    let members = selected.map((data) => {
      return { _id: data._id };
    });
    let memberIds = members.map((member) => member._id);
    let postData = {
      slug: params.id,
      members: members,
      type: "group",
    };
    setOpenExclude(false);
    const result = await add_challenge_exclude_member(postData);
    if (result.code === 200) {
      setGroupAllMembers((prev) => {
        return prev.filter((data) => !memberIds.includes(data._id));
      });
      setSelected([]);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  if (isAllLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div>
      <CustomConfirmation
        open={openExclude}
        setOpen={setOpenExclude}
        title={"Are you sure you want to exclude this members ?"}
        handleAgree={handleSubmitExclude}
      />
      <CustomMUITable
        {...settings}
        TABLE_HEAD={TABLE_HEAD_MEMBER_ALL}
        setSelected={setSelected}
        selected={selected}
        checkbox_selection={checkbox}
        data={groupAllMembers}
        custom_pagination={{
          total_count: totalCount,
          rows_per_page: rowsPerPage,
          page: page,
          handleChangePage: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage,
        }}
        custom_search={{
          searchText: searchText,
          setSearchText: setSearchText,
          handleSubmit: searchFunction,
        }}
        pageCount={pageCount}
        totalPages={totalPages}
        handleChangePages={handleChangePages}
        pagePagination={true}
      />
      {selected.length > 0 && (
        <div class="text-end mt-4" id="fixedbutton">
          <button class="small-contained-button" onClick={handleEdit}>
            Exclude This Members
          </button>
        </div>
      )}
    </div>
  );
};

export default AllMemberList;
