import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Card, Chip, IconButton } from "@mui/material";
import { convertCurrencyToSign, dd_date_format } from "src/utils/constant";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import { memberBillingDetailApi } from "src/DAL/member/member";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "total_amount_", label: "Total Amount", alignRight: false },
  { id: "note", label: "Transaction Note", alignRight: false },
  { id: "date", label: "Transaction Date", alignRight: false },
  // { id: "currency", label: "Currency", alignRight: false },
  {
    id: "status",
    label: "Status",
    renderData: (row) => {
      return (
        <Chip
          className="text-center px-2"
          size="small"
          variant="contained"
          color={
            row.status === "" || row.status === false ? "error" : "success"
          }
          label={row.status === false ? "Unsuccessful" : "successful"}
        />
      );
    },
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ViewBillingDetail() {
  const navigate = useNavigate();
  const params = useLocation();
  const { billing_id } = useParams();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [billingList, setBillingList] = useState([]);
  const [transactionsList, setTransactionsList] = useState([]);

  const {
    request_title,
    date,
    currency,
    initial_amount,
    number_of_days,
    month,
    request_iteration_type,
    request_type,
    total_amount,
  } = params.state;

  console.log(params.state, "paramsparamsparamsparamsparams");
  const getMemberListing = async () => {
    setIsLoading(true);
    const result = await memberBillingDetailApi(billing_id);
    if (result.code === 200) {
      setBillingList(result.payment_request_info);
      setIsLoading(false);
      const data = {
        id: result.payment_request_info._id,
        total_amount_:
          convertCurrencyToSign(result.payment_request_info.currency) +
          result.payment_request_info.total_amount,
        date: dd_date_format(result.payment_request_info.createdAt),
        currency: result.payment_request_info.currency,
        transaction_note: result.payment_request_info.transaction_note,
        status: result.payment_request_info.status,
      };
      let bill = [];
      bill.push(data);
      setTransactionsList(bill);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMemberListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-lg-8 col-sm-12">
            <h2>Billing Detail</h2>
          </div>
        </div>
        <Card className="payment-card mb-3">
          <div className="row p-3">
            <div className="col-12">
              <div className="mb-3">
                <span>{htmlDecode(request_title)}</span>
              </div>
              <div className="mb-3 d-flex align-items-center svg-color date-color">
                <Icon icon="uiw:date" />
                <span className="ms-2">
                  {moment(date).format("DD MMM YYYY")}
                </span>
              </div>
              <div className="row">
                <div className="col-8 col-md-6 col-lg-3 ">Request Type:</div>
                <div className="col-4 col-md-6 col-lg-3 text-muted">
                  {request_type === "onetime" ? "OneTime" : "Recurring"}
                </div>
                <div className="col-8 col-md-6 col-lg-3 mt-3 mt-lg-0">
                  Total Programme Amount:
                </div>
                <div className="col-4 col-md-6 col-lg-3  mt-3 mt-lg-0 text-muted">
                  {convertCurrencyToSign(currency)}
                  {total_amount.toLocaleString()}
                </div>

                <>
                  <div className="col-8 col-md-6 col-lg-3  mt-3 ">
                    Initial Deposit Amount:
                  </div>
                  <div className="col-4 col-md-6 col-lg-3  mt-3 text-muted">
                    {convertCurrencyToSign(currency)}
                    {initial_amount.toLocaleString()}
                  </div>
                  <div className="col-8 col-md-6 col-lg-3  mt-3">
                    Total Installments:
                  </div>
                  <div className="col-4 col-md-6 col-lg-3  mt-3 text-muted">
                    {month.toLocaleString()}
                  </div>
                  <div className="col-8 col-md-6 col-lg-3  mt-3">
                    Installments Plan:
                  </div>
                  <div className="col-4 col-md-6 col-lg-3 mt-3 text-muted">
                    {request_iteration_type == "custom" ? (
                      <>
                        {number_of_days.toLocaleString() +
                          " Days " +
                          `(${request_iteration_type.toLowerCase()})`}
                      </>
                    ) : (
                      <>
                        {month.toLocaleString() +
                          " " +
                          request_iteration_type.toLocaleString()}
                      </>
                    )}
                  </div>
                </>
              </div>
            </div>
          </div>
        </Card>

        <div className="col-12 mt-5">
          <div className="mb-2">
            <h2>Transactions List</h2>
          </div>
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={transactionsList}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
