import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { delegate_Url, s3baseUrl } from "src/config/config";
import ReactVideoPlayer from "src/components/ReactVideoPlayer/ReactVideoPlayer";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { deleteProgramme, programmeDetail } from "src/DAL/Programme/Programme";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import { useState } from "react";
import {
  delete_program_module_api,
  program_module_detail_api,
} from "src/DAL/Programme/Lessons/ProgramModule";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ProgramModuleDetail() {
  const params = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [programmeDetailInfo, setProgrammeDetailInfo] = useState();
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const handleCopyReferralId = async (e) => {
    await navigator.clipboard.writeText(
      delegate_Url + "delegate-training/" + programmeDetailInfo.program_slug
    );
    enqueueSnackbar("Delegate Training Url Copied", { variant: "success" });
  };

  const handleNavigateEdit = () => {
    console.log(programmeDetailInfo, "programmeDetailInfo");
    navigate(
      `/programmes/modules/${params.programme_slug}/edit/${params.module_slug}`,
      {
        state: programmeDetailInfo,
      }
    );
  };

  const handleManageLessons = () => {
    navigate(`/lessons/module/${params.module_slug}`, {
      state: programmeDetailInfo,
    });
  };
  const handleManageProgramModule = () => {
    navigate(`/programmes/modules/${params.programme_slug}`, {
      state: programmeDetailInfo,
    });
  };
  const handleManageMember = () => {
    navigate(`/programmes/members/${programmeDetailInfo._id}`, {
      state: programmeDetailInfo,
    });
  };
  const handleNavigateQuestions = () => {
    navigate(
      `/programmes/delegate-questions?created_for_id=${programmeDetailInfo._id}`,
      {
        state: programmeDetailInfo,
      }
    );
  };
  const handleNavigateAnswers = () => {
    navigate(
      `/programmes/delegate-questions/answers?created_for_id=${programmeDetailInfo._id}`,
      {
        state: programmeDetailInfo,
      }
    );
  };

  const handleDelete = async () => {
    handleCloseDelete();
    let result = await delete_program_module_api(params.module_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDetail = async () => {
    setIsLoading(true);
    let result = await program_module_detail_api(params.module_slug);
    if (result.code === 200) {
      console.log(result, "2367890");
      setProgrammeDetailInfo(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (state) {
      setProgrammeDetailInfo(state);
    } else {
      handleDetail();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const delegate_questions_child_options = [
    {
      label: "Questions List",
      icon: "akar-icons:edit",
      handleClick: handleNavigateQuestions,
    },
    {
      label: "Question Answers",
      icon: "akar-icons:edit",
      handleClick: handleNavigateAnswers,
    },
  ];

  let MENU_OPTIONS = [
    // {
    //   label: "Manage Lessons",
    //   icon: "akar-icons:edit",
    //   handleClick: handleManageLessons,
    // },
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
  ];

  return (
    <div className="container-fluid">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this programme?"}
        handleAgree={handleDelete}
      />
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12 d-flex justify-content-between">
          <h2>{programmeDetailInfo?.title}</h2>
          <CustomPopoverSection
            menu={MENU_OPTIONS}
            data={programmeDetailInfo}
          />
        </div>
      </div>

      <div className="row media-margin">
        <div className="col-12">
          {programmeDetailInfo?.video_url == "" ? (
            <img
              src={
                programmeDetailInfo?.program_images
                  ? s3baseUrl + programmeDetailInfo.program_images.thumbnail_1
                  : ""
              }
            />
          ) : (
            <ReactVideoPlayer url={programmeDetailInfo?.video_url} />
          )}
        </div>
        <div className="col-12 section-space">
          {programmeDetailInfo?.audio_file == "" ? (
            ""
          ) : (
            <audio
              className="w-100"
              src={
                programmeDetailInfo?.audio_file
                  ? s3baseUrl + programmeDetailInfo?.audio_file
                  : ""
              }
              controls
            />
          )}
        </div>
        <div className="col-12 section-space">
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: programmeDetailInfo?.detailed_description,
              }}
            ></div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProgramModuleDetail;
