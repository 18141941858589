import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import Label from "src/components/Label";
import { memberDetailApi } from "src/DAL/member/member";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "src/components/RecordNotFound";
import { dummyImage } from "src/assets";
import ProgrammeListMemberProfile from "../Members/ProgrammeListMemberProfile";
import PodsListMemberProfile from "../Members/PodsListMemberProfile";
import GeneralCalendar from "src/components/GeneralComponents/GeneralCalendar";
import {
  difference_between_two_dates,
  get_view_dates,
} from "src/utils/constant";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const MemberProfile = () => {
  const member_id = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [pods, setPods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventList] = useState([]);
  const [memberNote, setMemberNote] = useState({});
  const [memberDetail, setMemberDetail] = useState({});
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [defaultTimeValue, setDefaultTimeValue] = useState("");
  const [programmesList, setProgrammesList] = useState([]);
  const [isCalenderLoading, setIsCalenderLoading] = useState(true);
  const memberDetailInfo = async () => {
    let apiData = {
      start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
      end_date: moment(currentDate.end_date).format("YYYY-MM-DD"),
    };
    const difference = difference_between_two_dates(
      apiData.start_date,
      apiData.end_date,
      "days"
    );

    if (difference < 10) {
      setIsCalenderLoading(true);
    }
    const result = await memberDetailApi(member_id.id, apiData);
    if (result.code == 200) {
      setDefaultTimeValue(result.admin_time_zone);
      setEventList(result.event);
      setMemberNote(result.member);
      setPods(result.rooms);
      setMemberDetail(result);
      setProgrammesList(result.member.program);
      setIsLoading(false);
      setIsCalenderLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsCalenderLoading(false);
    }
  };

  const handleGroupNavigate = () => {
    navigate(`/member/member-groups/${member_id.id}`);
  };

  const handleNotesNavigate = () => {
    navigate(`/member/member-personal-notes/${member_id.id}`, {
      state: memberNote,
    });
  };

  const handleGoalStatementNavigate = () => {
    navigate(`/member/member-goal-statement/${member_id.id}`, {
      state: memberDetail,
    });
  };

  const handleToDo = () => {
    navigate(`/member/to-do-tracker/${member_id.id}`, {
      state: memberDetail,
    });
  };

  useEffect(() => {
    memberDetailInfo();
  }, [currentDate]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="col-12">
        <IconButton
          className="back-screen-button mb-4"
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
        </IconButton>
      </div>
      <div className="row member-profile">
        <div className="col-12 mb-4 ">
          <h2>Member Profile</h2>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3 member-profile-image text-center mb-5">
          <img
            className="rounded-circle ms-auto me-auto"
            height="200px"
            width="200px"
            src={
              memberDetail.member.profile_image === undefined ||
              memberDetail.member.profile_image === ""
                ? dummyImage
                : s3baseUrl + memberDetail.member.profile_image
            }
          />
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3 text-start d-flex mb-5">
          <div className="ms-auto me-auto text-left">
            <Button
              variant="contained"
              className="mt-2 text-dark"
              onClick={() => handleGroupNavigate()}
            >
              View Groups
            </Button>
            <br />
            <Button
              variant="contained"
              className="mt-2 text-dark"
              onClick={() => handleNotesNavigate()}
            >
              View Client Notes
            </Button>
            <br />
            <Button
              variant="contained"
              className="mt-2 text-dark"
              onClick={() => handleGoalStatementNavigate()}
            >
              View GoalStatement
            </Button>
            <br />
            <Button
              variant="contained"
              className="mt-2 text-dark"
              onClick={() => handleToDo()}
            >
              To Do Tracker
            </Button>
            <br />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-5">
          <div className="row d-flex">
            <div className="col-6 text-center">
              <h6 className="mb-3">
                <b>Name:</b>
              </h6>
              <h6 className="mb-3">
                <b>Status:</b>
              </h6>
              <h6 className="mb-3">
                <b>Email:</b>
              </h6>
              <h6 className="mb-3">
                <b>Address:</b>
              </h6>
            </div>
            <div className="col-6">
              <h6 className="mb-3">
                {memberDetail.member.first_name +
                  " " +
                  memberDetail.member.last_name}
              </h6>
              <h6 className="mb-3">
                <Label
                  variant="ghost"
                  color={memberDetail.member === false ? "error" : "success"}
                >
                  {memberDetail.member === false ? "InActive" : "Active"}
                </Label>
              </h6>
              <h6 className="mb-3">{memberDetail.member.email}</h6>
              <h6 className="mb-3">{memberDetail.member.street}</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-4">
          <GeneralCalendar
            eventList={eventsList}
            setCurrentDate={setCurrentDate}
            showAddEvent={false}
            time_zone={defaultTimeValue}
            currentDate={currentDate}
            isLoading={isCalenderLoading}
            reloadList={memberDetailInfo}
            calendar_title="Calendar"
          />
        </div>
        <div className="col-12">
          {programmesList.length < 1 ? (
            <>
              <h2>Programmes</h2>
              <RecordNotFound title="Programmes" />
            </>
          ) : (
            <ProgrammeListMemberProfile programmes={programmesList} />
          )}
        </div>
        <div className="col-12">
          {pods.length < 1 ? (
            <>
              <h2>Pods</h2>
              <RecordNotFound title="Pods" />
            </>
          ) : (
            <PodsListMemberProfile pods={pods} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberProfile;
