import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import {
  add_multiple_transaction,
  consultant_list_for_commission_api,
} from "src/DAL/consultant/consultant";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import DelegateFilterDateBase from "./ConsultantFilterDateBase";
import moment from "moment";
import FilteredChip from "src/components/FilteredChip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PayCommission() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(500);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const EMPTY_FILTER = {
    program: [],
    start_date: null,
    end_date: null,
    role: null,
    commission_text: null,
    search_by_commission: false,
    commission_from: 0,
    commission_to: 0,
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterUpdated, setFilterUpdated] = useState(EMPTY_FILTER);

  const handleChangeOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleClearFilter = () => {
    getConsultantListingFilter(EMPTY_FILTER);
    localStorage.removeItem("delegate_py_commission_filter");
    setFilterDrawerState(false);
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    getConsultantListingFilter(filterData);
    localStorage.setItem(
      "delegate_py_commission_filter",
      JSON.stringify(filterData)
    );
    handleCloseFilterDrawer();
  };
  const getConsultantListingFilter = async (filter_data) => {
    setIsLoading(true);
    let postData = { ...filter_data };
    postData.commission_from = +postData.commission_from;
    postData.commission_to = +postData.commission_to;

    if (postData.program.length > 0) {
      postData.program = postData.program.map((item) => item._id);
    }
    if (postData.role) {
      postData.role = postData.role.value;
    }
    if (postData.start_date) {
      postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
    }
    if (postData.end_date) {
      postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
    }

    let search_keyword =
      localStorage.getItem("consultant_search_text_pay_commission") == null
        ? searchText
        : localStorage.getItem("consultant_search_text_pay_commission");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }

    const result = await consultant_list_for_commission_api(
      page,
      rowsPerPage,
      search_keyword,
      postData
    );
    if (result.code === 200) {
      setTotalPages(result.total_pages);
      const data = result.consultant_list.map((consultant) => {
        let due_amount = consultant.commission_due.toFixed(2);
        return {
          ...consultant,
          name:
            consultant.first_name +
            " " +
            consultant.last_name +
            " (" +
            consultant.email +
            ")",
          table_avatar: {
            src: s3baseUrl + consultant.image.thumbnail_1,
            alt: consultant.first_name,
          },
          due_amount: due_amount,
        };
      });

      const chipData = { ...filter_data };
      let commission_text = "";
      if (chipData.search_by_commission) {
        commission_text = `Due Commission From ${chipData.commission_from} To ${chipData.commission_to}`;
        chipData.commission_text = commission_text;
        delete chipData.search_by_commission;
        delete chipData.commission_from;
        delete chipData.commission_to;
      }
      setFilterData(() => {
        return {
          ...filter_data,
          commission_text,
        };
      });

      setFilterUpdated(chipData);
      setUserList(data);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDeleteChip = (data) => {
    if (!data.commission_text) {
      data.commission_from = 0;
      data.commission_to = 0;
      data.search_by_commission = false;
    }
    setPage(0);
    setPageCount(1);
    getConsultantListingFilter(data);
    handleCloseFilterDrawer();
    localStorage.setItem("delegate_py_commission_filter", JSON.stringify(data));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("delegate_py_commission_filter");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setFilterUpdated(filter_data);
    getConsultantListingFilter(filter_data);
  }, [rowsPerPage, page]);

  useEffect(() => {
    localStorage.setItem("consultant_search_text_pay_commission", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("consultant_search_text_pay_commission", "");
    } else {
      localStorage.setItem("consultant_search_text_pay_commission", searchText);
    }
  }, [searchText]);

  const handleChange = (event, parameter) => {
    const value = event.target.value;
    const name = event.target.name;

    setSelected((old) =>
      old.map((obj) => {
        if (obj._id === name) {
          return { ...obj, [parameter]: value };
        }
        return obj;
      })
    );
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "name", label: " Name" },
    { id: "due_amount", label: "Due Commission", alignRight: false },
    {
      label: "Payable Commission",
      renderData: (row) => {
        let is_selected = selected.find((object) => row._id === object._id);
        if (is_selected && is_selected.due_amount > 0) {
          return (
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              size="small"
              type="number"
              required
              name={row._id}
              className="default_consultant_commission_input"
              value={is_selected?.payable_commission ?? 0}
              sx={{
                "& fieldset": { border: "none" },
              }}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: is_selected.due_amount,
                },
              }}
              onChange={(e) => {
                handleChange(e, "payable_commission");
              }}
            />
          );
        }
      },
    },
    {
      label: "Payment Method",
      renderData: (row) => {
        let is_selected = selected.find((object) => row._id === object._id);
        if (is_selected) {
          return (
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-simple-select-label">Method</InputLabel>
              <Select
                label="Method"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name={row._id}
                value={is_selected?.payment_method ?? ""}
                onChange={(e) => {
                  handleChange(e, "payment_method");
                }}
                sx={{
                  "& fieldset": { border: "none" },
                }}
              >
                <MenuItem value="cash">Cash</MenuItem>
                {is_selected.connected_stripe_account_id && (
                  <MenuItem value="stripe">Stripe</MenuItem>
                )}
              </Select>
            </FormControl>
          );
        }
      },
    },
  ];

  const handlePayCommission = async (e) => {
    e.preventDefault();
    if (selected.length < 1) {
      enqueueSnackbar("Please select delegate to pay commission", {
        variant: "error",
      });
      return;
    }

    setIsLoading(true);
    const member_ids = [];
    selected.map((member) => {
      member_ids.push({
        user_id: member._id,
        amount: +member.payable_commission,
        method: member.payment_method,
      });
    });

    let postData = {
      users: member_ids,
    };

    const result = await add_multiple_transaction(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  console.log(filterData, "filterDatafilterDatafilterData");
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-lg-4 col-sm-12">
            <h2>Pay Commission</h2>
          </div>
          <div className="col-lg-8 col-sm-12 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        <FilteredChip
          data={filterUpdated}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />

        {selected.length > 0 && (
          <div class="text-end mt-4" id="fixedbutton">
            <button
              class="small-contained-button"
              onClick={handlePayCommission}
            >
              Pay Now
            </button>
          </div>
        )}
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
          selected={selected}
          setSelected={setSelected}
          checkbox_selection={true}
        />
      </div>

      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <DelegateFilterDateBase
            filterData={filterData}
            setFilterData={setFilterData}
            searchSubmitFilter={searchFunction}
            handleChangeOthers={handleChangeOthers}
            handleClearFilter={handleClearFilter}
            setProgramsList={setProgramsList}
            programsList={programsList}
          />
        }
      />
    </>
  );
}
