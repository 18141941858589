import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import MemberProgramPopover from "../MembersList/memberProgramPopOver";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ConsultantMemberList({ groupMembers }) {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [program, setprogram] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;

  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const getConsultantListing = async () => {
    setIsLoading(true);
    const data = groupMembers?.map((group) => {
      return {
        ...group,
        table_avatar: {
          src: s3baseUrl + group?.profile_image,
          alt: group?.first_name,
        },
        id: group._id,
        name: group?.first_name + " " + group?.last_name,
        image: group.profile_image,
        email: group.email,
        program: group.program,
      };
    });
    setUserList(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getConsultantListing();
  }, []);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "name", label: " Name" },
    { id: "table_avatar", label: "Profile", type: "thumbnail" },
    { id: "email", label: "Email " },
    {
      id: "program",
      label: "Programme",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        return (
          <div>
            {firstFiveprograms?.length > 0 &&
              firstFiveprograms?.map((program, i) => {
                if (program._id !== null) {
                  return (
                    <>
                      <div key={i}>
                        <p>{program?._id?.title + ","}</p>
                      </div>
                      {i == 4 && (
                        <p
                          className="mb-1 mt-1 view-more"
                          aria-describedby={id}
                          variant="contained"
                          onClick={(e) => handleClickPopUP(e, row)}
                        >
                          view more
                        </p>
                      )}
                    </>
                  );
                }
              })}
          </div>
        );
      },
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={userList}
        className="card-with-background"
        pagePagination={true}
      />
      <MemberProgramPopover
        handleClick={handleClickPopUP}
        handleClose={handleClosePopUp}
        anchorEl={anchorEl}
        id={id}
        open={opens}
        program={program}
        navigatePage={true}
      />
    </>
  );
}
