import React from "react";
import { Button, Box, Autocomplete, TextField } from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { CREATED_FOR } from "src/utils/constant";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { useState } from "react";
import { useEffect } from "react";
import { delegate_search } from "src/DAL/GeneralQuestions/GeneralQuestions";

export default function SalesTeamFilter({
  filterData,
  handleChange,
  searchSubmitFilter,
  handleClearFilter,
  handleChangeOthers,
  user_type,
}) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [serchText, setSerchText] = useState("");
  const [userList, setUserList] = useState([]);

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };
  const getUserList = async () => {
    const result = await delegate_search(serchText);
    if (result.code === 200) {
      console.log(result, "resultresult");
      let delegates = result.consultant_list.map((delegate) => {
        let name =
          delegate.first_name +
          " " +
          delegate.last_name +
          "(" +
          delegate.email +
          ")";
        return {
          ...delegate,
          name: name,
          chip_label: name,
          chip_value: delegate._id,
        };
      });
      setUserList(delegates);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    if (serchText.length % 2 === 0) {
      getUserList();
    }
  }, [serchText]);
  return (
    <>
      <div className="container-fluid new-memories mt-4">
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <Autocomplete
            loading
            id="tags-outlined"
            options={userList}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            value={filterData.delegate_id}
            onChange={(event, newValue) => {
              handleChangeOthers(newValue, "delegate_id");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`Choose  Delegate User`}
                placeholder={`Choose  Delegate User`}
                onChange={handleSearch}
              />
            )}
          />
        </div>
        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={searchSubmitFilter}
            startIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                  fill={get_root_value("--portal-theme-primary")}
                />
              </svg>
            }
          >
            Filter
          </Button>
        </Box>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          className="mb-3"
          onClick={handleClearFilter}
          startIcon={<Icon icon={roundClearAll} />}
        >
          Clear Filter
        </Button>
      </div>
    </>
  );
}
