import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import moment from "moment";
import { replace_created_for } from "src/utils/constant";
import { useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function QuestionsAnswersForUser({ tabData }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const [answersData, setAnswersData] = useState([]);
  const classes = useStyles();

  const getAnswersListing = async () => {
    setIsLoading(true);
    const answers = tabData?.map((answer) => {
      return {
        ...answer,
        title: answer?.created_for_id?.title,
        answered_date: moment(answer.reply_date).format("DD-MM-YYYY"),
        created_for_string: replace_created_for(answer.created_for),
      };
    });
    setAnswersData(answers);
    setIsLoading(false);
  };

  const handleNavigate = (value) => {
    let created_for_id = "";
    if (value.created_for_id?._id) {
      created_for_id = value.created_for_id._id;
    }

    let check_user = false;
    if (value.created_for == "self_image") {
      check_user = true;
    }

    navigate(
      `/member/member-profile/answer-detail?member_id=${params.id}&created_for_id=${created_for_id}&created_for=${value.created_for}&check_user=${check_user}`
    );
  };

  useEffect(() => {
    getAnswersListing();
  }, [tabData]);

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "created_for_string",
      label: "Questions Created For",
      alignRight: false,
      className: "text-capitalize cursor-pointer",
      handleClick: handleNavigate,
    },
    { id: "title", label: "Module Title", alignRight: false },
    {
      id: "answered_date",
      label: "Answered Date",
      calignRight: false,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="mt-3">
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={answersData}
        className="card-with-background"
      />
    </div>
  );
}
