import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import "react-tagsinput/react-tagsinput.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Icon } from "@iconify/react";
import TextareaAutosize from "react-textarea-autosize";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import { EmailTemplateList } from "src/DAL/NotificationAction/NotificationSetting";
import { show_proper_words } from "src/utils/constant";
import { useEffect } from "react";
import LinkPopup from "../../../pages/GeneralSetting/LinkPopup";

export default function WhatsappNotifySetting(props) {
  const { onCloseDrawer, data, drawerState } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState("");
  const [openLink, setOpenLink] = useState(false);
  const [array, setarray] = useState([]);

  const [inputs, setInputs] = useState({
    type: "message",
    template: "",
  });

  const handleGetSelectedText = () => {
    const inputField = document.getElementById("inputField");
    if (inputField) {
      const selectedText = inputField.value.substring(
        inputField.selectionStart,
        inputField.selectionEnd
      );
      return selectedText;
    }
  };
  const handleOnBold = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "**" + seleted_text + "**"
      );
      setMessage(newText);
    }
  };

  const handleOpenLinkPopup = () => {
    setOpenLink(true);
  };

  const handleOnItalic = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "*" + seleted_text + "*"
      );
      setMessage(newText);
    }
  };

  const handleAddLink = (values) => {
    let make_link = ` [${values.display_text}](${values.link_address}) `;
    const inputField = document.getElementById("inputField");
    const start = inputField.selectionStart;
    const end = inputField.selectionEnd;
    // Insert the text at the cursor position
    setMessage(message.slice(0, start) + make_link + message.slice(end));
    // Move the cursor after the inserted text
    inputField.setSelectionRange(
      start + make_link.length,
      start + make_link.length
    );
    setOpenLink(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let whatsapp_notification_info = { ...inputs };
    whatsapp_notification_info.whatsappMessage = message;
    let obj = { ...data, whatsapp_notification_info };
    onCloseDrawer(obj, drawerState);
  };

  const getNotificationActionListing = async () => {
    const result = await EmailTemplateList();
    if (result.code == 200) {
      setarray(result.templates);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getNotificationActionListing();
  }, []);

  useEffect(() => {
    if (data.whatsapp_notification_info) {
      setInputs(data.whatsapp_notification_info);
      setMessage(data.whatsapp_notification_info.whatsappMessage);
    }
  }, [drawerState]);

  return (
    <div className="container-fluid new-memories">
      <GeneralPopUpModel
        open={openLink}
        setOpen={setOpenLink}
        title={"Insert Link"}
        componentToPassDown={<LinkPopup handleAddLink={handleAddLink} />}
      />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 mt-3">
            <FormControl fullWidth required className="mt-3">
              <InputLabel id="demo-simple-select-label1">
                Notification type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label1"
                id="demo-simple-select1"
                value={inputs.type}
                name="type"
                label="Notification type"
                onChange={handleChange}
              >
                <MenuItem value="template">Template</MenuItem>
                <MenuItem value="message">Message</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.type == "template" ? (
            <div className="col-12 mt-2">
              <FormControl fullWidth required className="mt-3">
                <InputLabel id="demo-simple-select-label">Template</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inputs.template}
                  name="template"
                  label="Template"
                  onChange={handleChange}
                >
                  {array.length > 0 &&
                    array.map((item) => {
                      return (
                        <MenuItem value={item.name} key={`abs${item}`}>
                          {show_proper_words(item.name)}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          ) : (
            <div className="col-12 mt-3">
              <div className="w-100">
                <h4>Whatsapp Message*</h4>
                <div
                  className={`chat-formatters-box chat-formatters-border d-flex`}
                  style={{
                    backgroundColor: "var(--background-primary-color)",
                    height: "35px",
                  }}
                >
                  <>
                    <div className="formatter-icon" onClick={handleOnBold}>
                      <Icon
                        fontSize="15"
                        style={{ color: "var(--portal-theme-primary)" }}
                        icon="octicon:bold-24"
                      />
                    </div>
                    <div className="formatter-icon" onClick={handleOnItalic}>
                      <Icon
                        fontSize="15"
                        style={{ color: "var(--portal-theme-primary)" }}
                        icon="fe:italic"
                      />
                    </div>
                    <div
                      className="formatter-icon"
                      onClick={handleOpenLinkPopup}
                    >
                      <Icon
                        fontSize="15"
                        style={{ color: "var(--portal-theme-primary)" }}
                        icon="ion:link"
                      />
                    </div>
                  </>
                </div>

                <div className="position-relative parent">
                  <TextareaAutosize
                    id="inputField"
                    className={`chat-send-input chat-formatters-border `}
                    style={{
                      backgroundColor: "var(--background-secondary-color)",
                    }}
                    name="message"
                    type="text"
                    value={message}
                    maxRows={6}
                    minRows={6}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Write your message..."
                    required
                    autoFocus
                  />
                </div>
              </div>
            </div>
          )}

          <div className="text-end mt-4">
            <button type="submit" className="small-contained-button">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
