import * as React from "react";
import { useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { TimeZones } from "src/utils/constant";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, CircularProgress, Paper } from "@mui/material";
import {
  consultant_list_with_search,
  consultantDetail,
  EditConsultantApi,
} from "src/DAL/consultant/consultant";
import { s3baseUrl } from "src/config/config";
import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function EditConsultant({ heading }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const [oldImage, setOldImage] = useState();
  const [imageStatus, setImageStatus] = useState(false);
  const [timeZoneValue, setTimeZoneValue] = useState("Europe/Dublin");
  const [inputValue, setInputValue] = useState("");
  const [serchText, setSerchText] = useState("");
  const [selectedDelegate, setSelectedDelegate] = useState([]);
  const [delegatesList, setDelegatesList] = React.useState([]);
  const [associateForCall, setassociateForCall] = useState([]);
  const [phoneData, setPhoneData] = useState();
  const [inputs, setInputs] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    contact_number: "",
    goal_status: "",
    start_date: null,
    consultant_status: "",
    is_show_on_leaderboard: "true",
    street: "",
    city: "",
    state: "Ireland",
    first_payment_date: "",
    member_biography: "",
    image: {},
    short_description: "",
    time_zone: "Europe/Dublin",
    team_type: "team",
    main_heading: "",
    is_super_delegate: false,
    is_show_on_streak_leaderboard: false,
    zoom_api_key: "",
    connected_stripe_account_id: "",
    zoom_api_secret: "",
    zoom_account_id: "",
    is_chat_allow: false,
    control_by: "self",
    show_in_sales_performance: false,
  });

  const getDetailList = async () => {
    setIsLoading(true);
    const result = await consultantDetail(state._id);
    if (result.code === 200) {
      setInputs(result.consultant);
      setPhoneData(result.consultant.contact_number);
      let start_date = null;
      if (result.consultant.start_date) {
        start_date = result.consultant.start_date;
      }

      console.log(start_date, "start_datestart_datestart_date");

      setInputs((inputs) => ({
        ...inputs,
        ["first_name"]: result.consultant.first_name,
        ["show_in_sales_performance"]:
          result.consultant.show_in_sales_performance,
        ["last_name"]: result.consultant.last_name,
        ["email"]: result.consultant.email,
        ["contact_number"]: result.consultant.contact_number,
        ["city"]: result.consultant.city,
        ["state"]: result.consultant.state,
        ["main_heading"]: result.consultant.main_heading,
        ["street"]: result.consultant.selected_address,
        ["consultant_status"]: result.consultant.status,
        ["team_type"]: result.consultant.team_type,
        ["time_zone"]: result.consultant.time_zone,
        ["short_description"]: result.consultant.biography,
        ["zoom_api_key"]: result.consultant.zoom_api_key,
        ["control_by"]: result.consultant.control_by,
        ["start_date"]: start_date,
        ["connected_stripe_account_id"]:
          result.consultant.connected_stripe_account_id,
        ["zoom_api_secret"]: result.consultant.zoom_api_secret,
        ["zoom_account_id"]: result.consultant.zoom_account_id,

        ["consultant_status"]: result.consultant.status,
        ["street"]: result.consultant.address,
        ["is_show_on_leaderboard"]: result.consultant.is_show_on_leaderboard,
        ["is_super_delegate"]: result.consultant.is_super_delegate
          ? result.consultant.is_super_delegate
          : false,
        ["is_show_on_streak_leaderboard"]:
          result?.consultant?.is_show_on_streak_leaderboard,

        ["is_chat_allow"]: result.consultant.is_chat_allow
          ? result.consultant.is_chat_allow
          : false,
      }));
      setTimeZoneValue(result.consultant.time_zone);
      setassociateForCall(result.consultant.associates_for_call);
      let nurture_members_array = [];
      if (result.consultant.associates_for_call.length > 0) {
        result.consultant.associates_for_call.map((member) => {
          nurture_members_array.push({
            _id: member._id._id,
            first_name: member._id.first_name,
            last_name: member._id.last_name,
            email: member._id.email,
          });
        });
      }
      setSelectedDelegate(nurture_members_array);
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setImageStatus(true);
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let new_array = [];
    if (selectedDelegate.length > 0) {
      selectedDelegate.map((items, index) => {
        new_array.push({ _id: items });
      });
      if (inputs.days < 0) {
        enqueueSnackbar("No of days must be positive number ", {
          variant: "error",
        });
      } else {
        setIsLoading(true);

        const formData = new FormData();
        formData.append("first_name", inputs.first_name);
        formData.append("last_name", inputs.last_name);
        formData.append("biography", inputs.short_description);
        formData.append("email", inputs.email);
        formData.append("contact_number", inputs.contact_number);
        formData.append("main_heading", inputs.main_heading);
        formData.append("status", inputs.consultant_status);
        formData.append("address", inputs.street);
        formData.append("city", inputs.city);
        formData.append("control_by", inputs.control_by);
        formData.append(
          "is_show_on_leaderboard",
          inputs.is_show_on_leaderboard
        );
        formData.append("state", inputs.state);
        formData.append("time_zone", timeZoneValue);
        formData.append("team_type", inputs.team_type);
        formData.append("zoom_api_key", inputs.zoom_api_key);
        formData.append(
          "connected_stripe_account_id",
          inputs.connected_stripe_account_id
        );
        formData.append("zoom_api_secret", inputs.zoom_api_secret);
        formData.append("zoom_account_id", inputs.zoom_account_id);
        formData.append("is_chat_allow", inputs.is_chat_allow);
        formData.append("is_super_delegate", inputs.is_super_delegate);
        formData.append(
          "show_in_sales_performance",
          inputs.show_in_sales_performance
        );
        formData.append(
          "is_show_on_streak_leaderboard",
          inputs.is_show_on_streak_leaderboard
        );
        formData.append("associates_for_call", JSON.stringify(new_array));
        if (imageStatus == true) {
          formData.append("image", inputs.image);
        }
        if (inputs.start_date) {
          formData.append(
            "start_date",
            moment(inputs.start_date).format("YYYY-MM-DD")
          );
        }
        const result = await EditConsultantApi(formData, state.id);
        if (result.code === 200) {
          enqueueSnackbar(result.message, { variant: "success" });
          navigate(-1);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      }
    } else {
      enqueueSnackbar("Please Select Nurtures", { variant: "error" });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getDelegatesList = async () => {
    const result = await consultant_list_with_search(serchText);
    if (result.code === 200) {
      setDelegatesList(result.consultant_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  const { profile } = state;

  React.useEffect(() => {
    getDetailList();

    setOldImage(profile);
    setInputs((inputs) => ({
      ...inputs,
    }));
    setSelectedDelegate(state);
    if (state.associate_for_call) {
      setSelectedDelegate(state.associate_for_call);
    }
    let nurture_members_array = [];
    setSelectedDelegate(nurture_members_array);
    let nurture_members = state.associates_for_call;
    if (state?.from == "supportTeam") {
      if (nurture_members.length > 0) {
        nurture_members.map((member) => {
          nurture_members_array.push({
            _id: member._id,
            first_name: member.first_name,
            last_name: member.last_name,
            email: member.email,
          });
        });
      }
    } else {
      if (associateForCall.length > 0) {
        associateForCall.map((member) => {
          nurture_members_array.push({
            _id: member._id._id,
            first_name: member._id.first_name,
            last_name: member._id.last_name,
            email: member._id.email,
          });
        });
      }
    }
    getDetailList();
    setSelectedDelegate(nurture_members_array);
  }, []);
  let finalArray = [];
  for (let i = 0; i < delegatesList.length; i++) {
    let count = 0;
    for (let j = 0; j < selectedDelegate.length; j++) {
      if (delegatesList[i]._id == selectedDelegate[j]._id) {
        count++;
      }
    }
    if (count == 0) {
      finalArray.push(delegatesList[i]);
    }
  }
  useEffect(() => {
    getDelegatesList();
  }, [serchText]);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex ">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">
            {state?.from == "supportTeam" ? "Edit Team" : `Edit  ${heading}`}
          </h2>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            fullWidth
            required
            name="first_name"
            value={inputs.first_name}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            fullWidth
            name="last_name"
            value={inputs.last_name}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            fullWidth
            required
            name="email"
            value={inputs.email}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Main Heading"
            variant="outlined"
            fullWidth
            required
            name="main_heading"
            value={inputs.main_heading}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4 table-phone-input">
          <PhoneInput
            country={"gb"}
            inputClass="react-phone-input"
            dropdownClass="flag-input"
            inputProps={{
              name: "contact_number",
              autoComplete: "off",
            }}
            value={phoneData}
            enableSearch
            disableSearchIcon
            countryCodeEditable={false}
            buttonStyle={{
              border: "none",
              backgroundColor: "transparent",
            }}
            onChange={(value) => {
              handleChangeOthers("contact_number", value);
            }}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Is Chat Allowed
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_chat_allow"
              value={inputs.is_chat_allow}
              label="Is Chat Allowed"
              onChange={handleChange}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Show On Leader Board
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_show_on_leaderboard"
              value={inputs.is_show_on_leaderboard}
              label="Show On Leader Board "
              onChange={handleChange}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Delegate Rank"
            variant="outlined"
            fullWidth
            name="rank"
            type="number"
            value={inputs.rank}
            onChange={handleChange}
          />
        </div> */}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Is Super Delegate
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_super_delegate"
              value={inputs.is_super_delegate}
              label="Is Super Delegate"
              onChange={handleChange}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Delegate Status *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="consultant_status"
              value={inputs.consultant_status}
              label="Delegate Status*"
              onChange={handleChange}
            >
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        {state?.from == "supportTeam" ? (
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Team Type *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="team_type"
                value={inputs.team_type}
                label="Team Type *"
                onChange={handleChange}
              >
                <MenuItem value="team">Save As Team</MenuItem>
                <MenuItem value="both">Save As All</MenuItem>
              </Select>
            </FormControl>
          </div>
        ) : (
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Team Type *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="team_type"
                value={inputs.team_type}
                label="Team Type *"
                onChange={handleChange}
              >
                <MenuItem value="consultant">Save As Consultant</MenuItem>
                <MenuItem value="delegate">Save As Delegate</MenuItem>
                <MenuItem value="inner_circle">Save As Inner Circle</MenuItem>
                <MenuItem value="marketing">Save As Marketing</MenuItem>
                {/* <MenuItem value="both">Save As All</MenuItem> */}
              </Select>
            </FormControl>
          </div>
        )}

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Address"
            variant="outlined"
            fullWidth
            required
            name="street"
            value={inputs.street}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="City"
            variant="outlined"
            fullWidth
            required
            name="city"
            value={inputs.city}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="State/County"
            variant="outlined"
            fullWidth
            required
            name="state"
            value={inputs.state}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          {/* <FormControl fullWidth>
            <InputLabel required id="demo-simple-select-label">
              Time Zone
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={inputs.time_zone}
              label="Time Zone"
              name="time_zone"
              onChange={handleChange}
            >
              {TimeZones.map((value, i) => {
                return <MenuItem value={value}>{value}</MenuItem>;
              })}
            </Select>
          </FormControl> */}
          <Autocomplete
            value={timeZoneValue}
            onChange={(event, newValue) => {
              setTimeZoneValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            PaperComponent={({ children }) => (
              <Paper style={{ background: "#1d1c1d", color: "#fff" }}>
                {children}
              </Paper>
            )}
            id="controllable-states-demo"
            options={TimeZones}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Time Zone *"
                style={{ color: "#fff" }}
              />
            )}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            label="Connected Stripe Account ID"
            variant="outlined"
            fullWidth
            name="connected_stripe_account_id"
            value={inputs.connected_stripe_account_id}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Zoom Api Public Key"
            variant="outlined"
            fullWidth
            name="zoom_api_key"
            value={inputs.zoom_api_key}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Zoom Api Secret Key"
            variant="outlined"
            fullWidth
            name="zoom_api_secret"
            value={inputs.zoom_api_secret}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Zoom Account ID"
            variant="outlined"
            fullWidth
            name="zoom_account_id"
            value={inputs.zoom_account_id}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Show On Performance Streak Leaderboard
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_show_on_streak_leaderboard"
              value={inputs.is_show_on_streak_leaderboard}
              label="Show On Performance Streak Leaderboard"
              onChange={handleChange}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="is_membership_purchase">Control By</InputLabel>
            <Select
              labelId="control_by"
              id="demo-simple-select"
              name="control_by"
              value={inputs.control_by}
              label="Control By"
              onChange={handleChange}
            >
              <MenuItem value="self">Self</MenuItem>
              <MenuItem value="dev_team">Dev Team</MenuItem>
            </Select>
          </FormControl>
        </div>
        {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <Autocomplete
            id="tags-outlined"
            options={delegatesList}
            getOptionLabel={(option) =>
              option.first_name + " (" + option.email + ")"
            }
            filterSelectedOptions
            value={selectedDelegate}
            onChange={(event, newValue) => {
              setSelectedDelegate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nurtures"
                placeholder="Nurtures"
                onChange={handleSearch}
              />
            )}
          />
        </div> */}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Show in Report
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="show_in_sales_performance"
              value={inputs.show_in_sales_performance}
              label="Show in Report"
              onChange={handleChange}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4 multiple-select">
          <Autocomplete
            multiple
            limitTags={3}
            id="multiple-limit-tags"
            options={finalArray}
            filterSelectedOptions={true}
            getOptionLabel={(option) =>
              option.first_name + " (" + option.email + ")"
            }
            value={selectedDelegate}
            onChange={(event, newValue) => {
              setSelectedDelegate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Nurtures"
                placeholder="Nurtures"
                onChange={handleSearch}
              />
            )}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Start Date"
              inputFormat="dd-MM-yyyy"
              value={inputs.start_date}
              onChange={(e) => handleChangeOthers("start_date", e)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image *</p>
              <FormHelperText className="pt-0">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {file ? (
                <img src={file} height="50" />
              ) : (
                <img src={s3baseUrl + oldImage} height="50" />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image.name}</p>
          )}
        </div>
        <div className="col-12 mt-5">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Biography "
              multiline
              rows={6}
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
            <FormHelperText>
              Biography (Maximum limit 500 characters)
            </FormHelperText>
          </FormControl>
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
