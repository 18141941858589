import * as React from "react";
import { useState } from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useEffect } from "react";
import WheelOfLifeGraphQuestions from "src/pages/WheelOfLifeMemberList.js/WheelOfLifeGraphQuestions";
import WheelOfLifeQuestionsList from "src/pages/WheelOfLifeMemberList.js/WheelOfLifeQuestions";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DetailQuestion({ tabData }) {
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [goalMembers, setGoalMembers] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [userData, setUserData] = useState();
  const [intentionStatement, setintentionStatement] = useState();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const DetailQuestion = () => {
    setintentionStatement(tabData?.member?.intention_statement);
    setUserData(tabData?.member);
    const member_list = tabData?.assessment?.map((member) => {
      return {
        ...member,
      };
    });
    setGoalMembers(member_list);
    const wheelOfLifeQuestion = tabData?.wheel_of_life?.map((group) => {
      return {
        ...group,
      };
    });
    setGroups(wheelOfLifeQuestion);
  };

  useEffect(() => {
    DetailQuestion();
  }, [tabData]);

  const TABS_OPTIONS = [
    {
      title: "Assessment Questions",
      component: (
        <WheelOfLifeQuestionsList
          goalMembers={goalMembers}
          finalData={userData}
        />
      ),
    },
    {
      title: "Wheel Of Life Questions",
      component: (
        <WheelOfLifeGraphQuestions goalMembers={groups} finalData={userData} />
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-12 text-center pt-4">
          {intentionStatement && (
            <TextField
              id="filled-multiline-flexible"
              fullWidth
              multiline
              InputLabelProps={{
                style: { color: "#f6bd4b" },
              }}
              rows={3}
              disabled
              label="Intention Statement"
              className={(classes.dense, "textarea-color")}
              value={intentionStatement}
              onChange={(e) => handleChange(index, e)}
              variant="outlined"
              name="message"
            />
          )}
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-4">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
