import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  deleteEventApi,
  events_list_api,
  events_list_api_with_pagination,
} from "src/DAL/Events/events";
import PopOverViewMore from "../../components/PopOverViewMore";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CalendarEventsList({ created_for }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDoc, setSelectedDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [program, setprogram] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);

  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;

  const getEventsListing = async (text) => {
    setIsLoading(true);
    const result = await events_list_api_with_pagination(
      page,
      rowsPerPage,
      text
    );
    if (result.code == 200) {
      const events = result.event.map((event) => {
        return {
          ...event,
          member_count: created_for
            ? event.delegate.length
            : event.member.length,
        };
      });

      setEventsData(events);
      setTotalPages(result.total_page);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };
  const handleOpenDrawer = () => {
    let path = `/calendar-events`;
    if (created_for == "consultant_user") {
      path = `/delegate-events`;
    }
    navigate(`${path}/add-event`);
  };

  const handleClickPopUP = (event, row) => {
    setprogram(created_for ? row.delegate_group : row.group);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const handleAgreeDelete = (value) => {
    setSelectedDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await deleteEventApi(selectedDoc.event_slug);
    if (result.code === 200) {
      getEventsListing();
      setEventsData((eventsData) => {
        return eventsData.filter((data) => data._id !== selectedDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    let path = `/calendar-events`;
    if (created_for == "consultant_user") {
      path = `/delegate-events`;
    }
    navigate(`${path}/edit-event/${value.event_slug}`);
  };

  const handleNavigate = () => {
    navigate(created_for ? "/delegate-events" : `/calendar-events`);
  };

  const handleDetailPage = (value) => {
    navigate(
      `${
        created_for
          ? `/delegate-events/event-detail/${value.event_slug}`
          : `/calendar-events/event-detail/${value.event_slug}`
      }`,
      {
        state: { editValues: value },
      }
    );
  };
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("event_list_search", searchText);

    getEventsListing(searchText);
  };
  useEffect(() => {
    let search_text = localStorage.getItem("event_list_search");
    if (search_text) {
      setSearchText(search_text);
      getEventsListing(search_text);
    } else {
      setSearchText("");
      getEventsListing("");
    }
  }, [page, rowsPerPage]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "eva:eye-fill",
      handleClick: handleDetailPage,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: "Title",
      renderData: (row) => {
        return (
          <div className="pointer_cursor" onClick={() => handleDetailPage(row)}>
            {row.title}
          </div>
        );
      },
    },
    {
      id: "group",
      label: "Groups",
      renderData: (row) => {
        const firstFiveGroup = created_for
          ? row?.delegate_group?.slice(0, 5)
          : row?.group?.slice(0, 5);
        return (
          <div>
            {firstFiveGroup &&
              firstFiveGroup.length > 0 &&
              firstFiveGroup.map((group, i) => {
                return (
                  <>
                    {group._id && <p className="mb-0">{group?._id?.title} </p>}
                    {i == 4 && (
                      <p
                        className="mb-1 mt-1 view-more"
                        aria-describedby={id}
                        variant="contained"
                        onClick={(e) => handleClickPopUP(e, row)}
                      >
                        view more
                      </p>
                    )}
                  </>
                );
              })}
          </div>
        );
      },
    },
    {
      id: "recurring_type",
      label: "Event Type",
      className: "text-capitalize",
    },
    { id: "start_date", label: "Start Date" },
    { id: "end_date", label: "End Date" },
    { id: "member_count", label: "Members" },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let breadCrumbMenu = [
    {
      title: created_for ? "Delegate Events" : "Calendar",
      navigation: created_for ? "/delegate-events" : "/calendar-events",
      active: false,
    },
    {
      title: "Events List",
      active: true,
    },
  ];

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this event?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-start mb-4">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <h2>{created_for ? "Delegate Events List" : "Events List"} </h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button
              className="small-contained-button mt-1 mb-4 me-2"
              onClick={handleNavigate}
            >
              Show Calendar
            </button>
            <button
              className="small-contained-button float-end mt-1 mb-4"
              onClick={handleOpenDrawer}
            >
              Add Event
            </button>
          </div>
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={eventsData}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
              pagePagination={true}
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
            />
          </div>
        </div>
      </div>
      <PopOverViewMore
        handleClick={handleClickPopUP}
        handleClose={handleClosePopUp}
        anchorEl={anchorEl}
        id={id}
        open={opens}
        program={program}
        title="Groups"
      />
    </>
  );
}
