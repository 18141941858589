import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import { useState } from "react";
import ReactVideoPlayer from "src/components/ReactVideoPlayer/ReactVideoPlayer";
import {
  delete_lesson_recording_api,
  lesson_recording_detail_api,
} from "src/DAL/Programme/Lessons/LessonRecordings/LessonRecordings";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function LessonRecordingDetail() {
  const params = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [recordingDetail, setRecordingDetail] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [lessonDetailInfo, setLessonsDetailInfo] = useState();
  const [leassonInfo, setleassonInfo] = useState();
  console.log(recordingDetail, "recordingDetailrecordingDetail");
  let programName = recordingDetail?.lesson?.program[0]?._id?.title;
  let program_slug_navgation =
    recordingDetail?.lesson?.program[0]?._id?.program_slug;
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const handleNavigateEdit = (value) => {
    navigate(
      `/lessons/lesson-recordings/edit-recording/${value.recording_slug}`,
      {
        state: recordingDetail,
      }
    );
  };

  const handleDelete = async () => {
    handleCloseDelete();
    setIsLoading(true);
    let result = await delete_lesson_recording_api(params.recording_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleNavigateBack();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDetail = async () => {
    setIsLoading(true);
    let result = await lesson_recording_detail_api(params.recording_slug);
    if (result.code === 200) {
      console.log(result.recording, "resultresult");
      setleassonInfo(result.recording.lesson);
      console.log(result.recording.lesson, "programprogram");
      setLessonsDetailInfo(result.recording.lesson.program);
      setRecordingDetail(result.recording);
      setLessonsDetailInfo(result?.lesson);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateBack = () => {
    let lesson_slug = recordingDetail.lesson.lesson_slug;
    navigate(`/lessons/lesson-recordings/${lesson_slug}`);
  };

  let lesson_slug = recordingDetail?.lesson?.lesson_slug;
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },
    {
      title: programName,
      navigation: `/programmes/programmes-detail/${program_slug_navgation}`,
      active: false,
    },
    {
      title: "Lessons",
      navigation: `/lessons/${program_slug_navgation}`,
      active: false,
    },
    {
      title: recordingDetail?.lesson?.title,
      navigation: `/lessons/lesson-detail/${lesson_slug}`,
      active: false,
    },
    {
      title: "Recordings",
      navigation: `/lessons/lesson-recordings/${lesson_slug}`,
      active: false,
    },
    {
      title: recordingDetail?.title,
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    if (state) {
      console.log(state, "statestate");
      setRecordingDetail(state);
    } else {
      handleDetail();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
  ];

  return (
    <div className="container-fluid">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this recording?"}
        handleAgree={handleDelete}
      />
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex mb-3">
          <span className="mt-2">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12 d-flex justify-content-between">
          <h2>{recordingDetail?.title}</h2>
          <CustomPopoverSection menu={MENU_OPTIONS} data={recordingDetail} />
        </div>
      </div>

      <div className="row media-margin">
        <div className="col-12">
          {recordingDetail?.video_url == "" ? (
            <img
              src={
                recordingDetail?.recording_image
                  ? s3baseUrl + recordingDetail.recording_image.thumbnail_1
                  : ""
              }
            />
          ) : (
            <ReactVideoPlayer url={recordingDetail?.video_url} />
          )}
        </div>
        <div className="col-12 section-space">
          {recordingDetail?.audio_recording == "" ? (
            ""
          ) : (
            <audio
              className="w-100"
              src={
                recordingDetail?.audio_recording
                  ? s3baseUrl + recordingDetail?.audio_recording
                  : ""
              }
              controls
            />
          )}
        </div>
        <div className="col-12 section-space">
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: recordingDetail?.detailed_description,
              }}
            ></div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LessonRecordingDetail;
