import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  delete_road_map_levels_api,
  road_map_levels_list_api,
} from "src/DAL/RoadMapLevelsApi/RoadMapLevelsApi";
import { s3baseUrl } from "src/config/config";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import Levels from "./Levels";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function RoadMapLevels() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [roadMapLevels, setRoadMapLevels] = useState([]);
  const [referredpop, setReferredpop] = useState(false);
  const [levelsArray, setLevelsArray] = useState(false);
  const handleOPen = (value) => {
    setReferredpop(true);
    setLevelsArray(value);
  };
  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Icon",
      alignRight: false,
      type: "thumbnail",
    },
    { id: "title", label: "Title", alignRight: false },

    { id: "order", label: "Order", alignRight: false },
    {
      id: "No. Of Badges",
      label: "No. Of Badges",
      renderData: (row) => {
        return (
          <>
            {row.badge_configration ? (
              <Tooltip title={"View Badges"}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOPen(row.badge_configration)}
                >
                  {row.badge_configration.length}
                </span>
              </Tooltip>
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];
  const getRoadMapList = async () => {
    const result = await road_map_levels_list_api();
    if (result.code == 200) {
      const road_map_levels = result.road_map_levels.map((road_map_levels) => {
        return {
          ...road_map_levels,
          title: road_map_levels.title,
          table_avatar: {
            src: s3baseUrl + road_map_levels.icon,
            alt: road_map_levels.title,
          },
        };
      });
      setRoadMapLevels(road_map_levels);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_road_map_levels_api(deleteDoc._id);
    if (result.code === 200) {
      getRoadMapList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(`/road-map-levels/edit-road-map-levels/${value._id}`, {
      state: value,
    });
  };
  const handleBagdeConfiguration = (value) => {
    navigate(`/road-map-levels/badge-configuration/${value._id}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/road-map-levels/add-road-map-levels`);
  };

  useEffect(() => {
    getRoadMapList();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    // {
    //   label: "Badge Configuration",
    //   icon: "akar-icons:edit",
    //   handleClick: handleBagdeConfiguration,
    // },
    // {
    //   label: "Delete",
    //   icon: "ant-design:delete-twotone",
    //   handleClick: handleAgreeDelete,
    // },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this call type?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Road Map Levels</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Road Map Levels
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={roadMapLevels}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          localSearchName="road_map_levels"
          pagePagination={true}
        />
        <WhatsappPopUpModel
          open={referredpop}
          setOpen={setReferredpop}
          title={"Badges"}
          show_date_and_income={true}
          componentToPassDown={<Levels levelsArray={levelsArray} />}
        />
      </div>
    </>
  );
}
