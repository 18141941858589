import React, { useState } from "react";
import ManageNavItemsAccessNew from "../NavItems/ManageNavItemsAccessNew";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

export default function ProgrammeNavItems() {
  const [moduleData, setModuleData] = useState({});

  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },
    {
      title: moduleData?.title,
      navigation: `/programmes/programmes-detail/${moduleData?.program_slug}`,
      active: false,
    },
    {
      title: "Manage Nav Items Access",
      active: true,
    },
  ];

  return (
    <div className="container-fluid">
      {moduleData?.title && (
        <div className="row">
          <div className="col-12 mb-3">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-12 mt-3">
            <h2>Manage Nav Items Access</h2>
          </div>
        </div>
      )}
      <div className="mt-3">
        <ManageNavItemsAccessNew type="program" setModuleData={setModuleData} />
      </div>
    </div>
  );
}
