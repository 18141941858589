import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import {
  all_group_members_api,
  consultantGroupDetail,
} from "src/DAL/consultant/consultant";
import { CircularProgress } from "@mui/material";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import AllMemberList from "./AllMemberList";
import MemberProgramPopover from "../MembersList/memberProgramPopOver";
import { show_proper_words } from "src/utils/constant";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";
import { Icon } from "@iconify/react";
import PopOverViewMore from "src/components/PopOverViewMore";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
let member_options = [
  {
    label: "First name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Last name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true, is_disabled: true },
  {
    label: "Contact Number",
    value: "contact_number",
    is_checked: true,
    is_disabled: true,
  },
];

const CalendarGroupDetail = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isAllLoading, setAllIsLoading] = useState(false);
  const params = useParams();
  const [groupPrograms, setGroupPrograms] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [groupAllMembers, setGroupAllMembers] = useState([]);
  const [groupEvents, setGroupEvents] = useState([]);
  const [groupdata, setGroupData] = useState("");
  const [groupby, setGroupby] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [program, setprogram] = useState([]);
  const [event, setEvent] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElEvnet, setAnchorElEvnet] = useState(null);
  const opens = Boolean(anchorEl);
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const id = opens ? "simple-popover" : undefined;
  const opensEvent = Boolean(anchorElEvnet);
  const idevent = opensEvent ? "simple-popover" : undefined;
  const [salePageList, setSalePageList] = useState([]);

  let selected_index = 0;
  const [tabValue, setTabValue] = useState(selected_index);

  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };

  function mapSalePagesWithPlans(salePages, plans) {
    return salePages.map((salePage) => {
      return {
        sale_page_title: salePage.sale_page_title,
        plans: plans
          .filter((plan) => plan.sale_page === salePage._id)
          .map((plan) => ({
            plan_title: plan.plan_title,
            plan_slug: plan.plan_slug,
          })),
      };
    });
  }

  const groupDetail = async () => {
    setIsLoading(true);
    const result = await consultantGroupDetail(params.id);
    if (result.code == 200) {
      setIsLoading(false);
      setGroupEvents(
        result.group_events.map((item) => {
          return {
            ...item,
            table_avatar: {
              src: s3baseUrl + item.images.thumbnail_1,
              alt: item?.title,
            },
          };
        })
      );
      setGroupPrograms(
        result.group_programs.map((item) => {
          return {
            ...item,
            table_avatar: {
              src: s3baseUrl + item.program_images.thumbnail_1,
              alt: item?.title,
            },
          };
        })
      );
      setGroupMembers(
        result.group_members.map((item) => {
          let find_name = item.first_name + " " + item.last_name;
          return {
            ...item,
            find_name,
            table_avatar: {
              src: s3baseUrl + item?.profile_image,
              alt: item?.title,
            },
          };
        })
      );
      let sale_pages_with_plans = mapSalePagesWithPlans(
        result.sale_pages,
        result.plans
      );
      if (sale_pages_with_plans.length > 0) {
        setSalePageList(sale_pages_with_plans);
      }

      setGroupData(result.group);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const handleClickPopUPEvent = (event, row) => {
    setEvent(row.event);
    setAnchorElEvnet(event.currentTarget);
  };

  const handleClosePopUpEvent = () => {
    setAnchorElEvnet(null);
  };

  const group_members_list = async () => {
    setAllIsLoading(true);
    let type = "exclude";

    if (tabValue === 4) {
      type = "all";
    }
    let search_keyword =
      localStorage.getItem("calendar_search_text") == null
        ? searchText
        : localStorage.getItem("calendar_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const result = await all_group_members_api(
      params.id,
      page,
      rowsPerPage,
      search_keyword,
      type
    );
    if (result.code == 200) {
      setAllIsLoading(false);
      setGroupAllMembers(
        result.group_members.map((item) => {
          let full_name = item.first_name + " " + item.last_name;
          return {
            ...item,
            full_name,
            table_avatar: {
              src: s3baseUrl + item?.profile_image,
              alt: item?.first_name,
            },
          };
        })
      );
      setGroupby(result);
      setFilterQuery(result.query);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setAllIsLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setPage(0);
    setPageCount(1);
    setSearchText("");
    localStorage.removeItem("calendar_search_text");
    setTabValue(newValue);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    group_members_list();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    groupDetail();
  }, []);

  useEffect(() => {
    group_members_list();
  }, [page, rowsPerPage, tabValue]);

  useEffect(() => {
    localStorage.setItem("calendar_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("calendar_search_text", "");
    } else {
      localStorage.setItem("calendar_search_text", searchText);
    }
  }, [searchText]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let breadCrumbMenu = [
    {
      title: "Groups",
      navigation: `/calendar-groups`,
      active: false,
    },

    {
      title: groupdata?.title,
      navigation: ``,
      active: true,
    },
  ];

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title" },
    {
      id: "table_avatar",
      label: "Picture",
      type: "thumbnail",
    },
    { id: "short_description", label: "Description" },
  ];

  const TABLE_HEAD_SALE_PAGE = [
    { id: "number", label: "#", type: "number" },
    { id: "sale_page_title", label: "Sale Page Title" },
    {
      id: "plans",
      label: "Payment Plans",
      renderData: (row) => {
        return (
          <div>
            {row?.plans && row?.plans.length > 0 ? (
              row?.plans?.map((plan) => {
                return <p>{plan?.plan_title + ","}</p>;
              })
            ) : (
              <span>N/A</span>
            )}
          </div>
        );
      },
    },
  ];

  const TABLE_HEAD_MEMBER = [
    { id: "number", label: "#", type: "number" },
    { id: "find_name", label: "Name" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
    },
    { id: "email", label: "Email" },
    {
      id: "group_by",
      label: "Group By",
      renderData: (row) => {
        return show_proper_words(groupdata?.group_by);
      },
    },
    {
      id: "program",
      label: "Programmes / Event",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        const firstFiveEvent = row.event?.slice(0, 5);
        return (
          <div>
            {firstFiveprograms &&
            firstFiveprograms.length > 0 &&
            groupdata?.group_by == "program"
              ? firstFiveprograms?.map((program, i) => {
                  if (program._id !== null) {
                    return (
                      <>
                        <div key={i}>
                          <p>{program?._id?.title + ","}</p>
                        </div>
                        {i == 4 && (
                          <p
                            className="mb-1 mt-1 view-more"
                            aria-describedby={id}
                            variant="contained"
                            onClick={(e) => handleClickPopUP(e, row)}
                          >
                            view more
                          </p>
                        )}
                      </>
                    );
                  }
                })
              : firstFiveEvent.map((event, i) => {
                  if (event._id !== null) {
                    return (
                      <>
                        <div key={i}>
                          <p>{event._id?.title + ","}</p>
                        </div>
                        {i == 4 && (
                          <p
                            className="mb-1 mt-1 view-more"
                            aria-describedby={idevent}
                            variant="contained"
                            onClick={(e) => handleClickPopUPEvent(e, row)}
                          >
                            view more
                          </p>
                        )}
                      </>
                    );
                  }
                })}
          </div>
        );
      },
    },
  ];

  const TABLE_HEAD_MEMBER_ALL = [
    { id: "number", label: "#", type: "number" },
    { id: "full_name", label: "Name" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
    },
    { id: "email", label: "Email" },
    {
      id: "group_by",
      label: "Group By",
      renderData: (row) => {
        return show_proper_words(groupby?.group_by);
      },
    },
    {
      id: "program",
      label: "Programmes / Event",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        const firstFiveEvent = row.event?.slice(0, 5);
        return (
          <>
            {firstFiveprograms &&
            firstFiveprograms.length > 0 &&
            groupby.group_by == "program"
              ? firstFiveprograms?.map((program, i) => {
                  if (program._id !== null) {
                    return (
                      <>
                        <div key={i}>
                          <p>{program?._id?.title + ","}</p>
                        </div>
                        {i == 4 && (
                          <p
                            className="mb-1 mt-1 view-more"
                            aria-describedby={id}
                            variant="contained"
                            onClick={(e) => handleClickPopUP(e, row)}
                          >
                            view more
                          </p>
                        )}
                      </>
                    );
                  }
                })
              : firstFiveEvent.map((event, i) => {
                  if (event._id !== null) {
                    return (
                      <>
                        <div key={i}>
                          <p>{event._id?.title + ","}</p>
                        </div>
                        {i == 4 && (
                          <p
                            className="mb-1 mt-1 view-more"
                            aria-describedby={idevent}
                            variant="contained"
                            onClick={(e) => handleClickPopUPEvent(e, row)}
                          >
                            view more
                          </p>
                        )}
                      </>
                    );
                  }
                })}
          </>
        );
      },
    },
  ];

  let settings = {
    className: "card-with-background mt-3 ",
  };

  const TABS_OPTIONS = [
    {
      title:
        groupdata?.group_by === "event" ? "Events List" : "Programmes List",
      component: (
        <div>
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={groupdata?.group_by === "event" ? groupEvents : groupPrograms}
            pagePagination={true}
            {...settings}
          />
        </div>
      ),
    },
    {
      title: "Sale Page List",
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD_SALE_PAGE}
          data={salePageList}
          pagePagination={true}
          {...settings}
        />
      ),
    },
    {
      title: "Group Individual Member List",
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD_MEMBER}
          data={groupMembers}
          pagePagination={true}
          {...settings}
        />
      ),
    },
    {
      title: "Exclude Member List",
      component: (
        <AllMemberList
          totalCount={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          TABLE_HEAD_MEMBER_ALL={TABLE_HEAD_MEMBER_ALL}
          isAllLoading={isAllLoading}
          groupAllMembers={groupAllMembers}
          searchFunction={searchFunction}
          setSearchText={setSearchText}
          searchText={searchText}
          setGroupAllMembers={setGroupAllMembers}
          checkbox={false}
        />
      ),
    },
    {
      title: "All Member List",
      component: (
        <div>
          <div className="text-end">
            <button
              className="small-contained-button me-2 mt-3"
              onClick={showPopUPcsv}
            >
              Request For Csv &nbsp;&nbsp; <Icon icon={arrowCircleDownFill} />
            </button>
          </div>
          <AllMemberList
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            TABLE_HEAD_MEMBER_ALL={TABLE_HEAD_MEMBER_ALL}
            isAllLoading={isAllLoading}
            groupAllMembers={groupAllMembers}
            searchFunction={searchFunction}
            setSearchText={setSearchText}
            searchText={searchText}
            setGroupAllMembers={setGroupAllMembers}
            checkbox={true}
          />
        </div>
      ),
    },
  ];
  if (groupdata?.group_by == "sale_page") {
    TABS_OPTIONS.splice(0, 1);
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 display-flex mb-4">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-8 col-sm-12">
            <h2>Calendar Groups Detail</h2>
          </div>
        </div>
        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
      <GeneralPopUpModel
        open={showExportcsvFile}
        setOpen={setShowExportcsvFile}
        title={"Export CSV file request  "}
        componentToPassDown={
          <GeneralRequestForCsv
            options_array={member_options}
            collection_name={"all_group_members"}
            filter_data={filterQuery}
            setShowExportcsvFile={setShowExportcsvFile}
          />
        }
      />
      <MemberProgramPopover
        handleClick={handleClickPopUP}
        handleClose={handleClosePopUp}
        anchorEl={anchorEl}
        id={id}
        open={opens}
        program={program}
      />
      <PopOverViewMore
        handleClick={handleClickPopUPEvent}
        handleClose={handleClosePopUpEvent}
        anchorEl={anchorElEvnet}
        id={idevent}
        open={opensEvent}
        program={event}
        title="Event"
      />
    </>
  );
};

export default CalendarGroupDetail;
