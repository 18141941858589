import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgress, IconButton } from "@mui/material";
import { questionReplyHistory } from "src/DAL/member/member";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import { htmlDecode } from "src/utils/convertHtml";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export const AnswerHistory = ({ data, member_id }) => {
  const id = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const params = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  //console.log(params, "params");
  //console.log(id, params.state._id, "id");
  const [replies, setReplies] = useState([]);

  const historyDetail = async () => {
    setIsLoading(true);
    let postData = {
      question_id: data._id,
      member_id,
    };
    const result = await questionReplyHistory(postData);
    if (result.code == 200) {
      //console.log(result, "resultsetIsLoading");
      setReplies(result.answer_stats);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  React.useEffect(() => {
    historyDetail();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row answer-history">
        <div className="col-12 mt-3">
          <p className="heading">Question :</p>
          <p className="mb-4">{htmlDecode(data.question)}</p>
        </div>
        {replies.length < 1 ? (
          <h6>No Answer</h6>
        ) : (
          replies.map((reply) => {
            return (
              <>
                <div className="col-12 mt-1">
                  <div className="d-flex justify-content-between">
                    <p className="heading">Answer :</p>
                    <div className="me-3 mt-1 text-muted">
                      {moment(reply.createdAt).format("YYYY-MM-DD")}
                    </div>
                  </div>
                  <p className="">{htmlDecode(reply.answer)}</p>
                </div>
                {/* <div className="col-12 mb-3">
                  <CircleIcon /> {reply.answer}
                </div>

                <div className="col-6 mb-5 text-muted">
                  {moment(reply.createdAt).format("YYYY-MM-DD")}
                </div> */}
              </>
            );
          })
        )}
      </div>
    </div>
  );
};
