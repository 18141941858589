import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { list_pages_for_billing_tickets_api } from "src/DAL/WebsitePages/WebsitePages";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Box, Button, TextField } from "@mui/material";
import { Icon } from "@iconify/react";
import { get_root_value } from "src/utils/domUtils";

const BillingFilter = ({
  handleClearFilter,
  searchFunction,
  datePickerField,
  handleSelectOther,
  filterData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [pagesList, setPagesList] = useState([]);
  const [plansList, setPlansList] = useState([]);

  const getPagesList = async () => {
    const result = await list_pages_for_billing_tickets_api();
    if (result.code === 200) {
      let page_list = result.sale_page.map((page) => {
        let page_title = page.sale_page_title;
        if (page.type == "template") {
          page_title = page.sale_page_title + " (Template)";
        }
        return {
          ...page,
          page_title: page_title,
          chip_label: page_title,
          chip_value: page._id,
        };
      });
      setPagesList(page_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSetPlans = (plans) => {
    setPlansList(
      plans.map((item) => {
        return {
          ...item,
          chip_label: item.plan_title,
          chip_value: item._id,
        };
      })
    );
  };

  const handleChangePage = (value) => {
    if (value) {
      handleSetPlans(value.plans);
    } else {
      setPlansList([]);
    }
    handleSelectOther("sale_page", value);
  };

  useEffect(() => {
    getPagesList();
    if (filterData.sale_page) {
      handleSetPlans(filterData.sale_page.plans);
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mt-3 ">
          <MUIAutocomplete
            inputLabel="Sale Page"
            selectedOption={filterData.sale_page}
            setSelectedOption={handleChangePage}
            optionsList={pagesList}
            name="page_title"
            required
          />
        </div>
        {plansList.length > 0 ? (
          <div className="col-12 mt-4">
            <MUIAutocomplete
              inputLabel="Choose Plan"
              selectedOption={filterData.plan}
              setSelectedOption={(value) => handleSelectOther("plan", value)}
              optionsList={plansList}
              name="plan_title"
            />
          </div>
        ) : (
          ""
        )}
        {!!datePickerField && (
          <div className="col-12 mt-2">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={filterData.checkin_date_time}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(value) =>
                  handleSelectOther("checkin_date_time", value)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mt-3 inputs-fields"
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        )}
        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={searchFunction}
            startIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                  fill={get_root_value("--portal-theme-primary")}
                />
              </svg>
            }
          >
            Filter
          </Button>
          <div className="mt-3">
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              onClick={handleClearFilter}
              startIcon={<Icon icon={roundClearAll} />}
            >
              Clear All
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default BillingFilter;
