import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  clientSettingApi,
  updateClientDashboardSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { s3baseUrl } from "src/config/config";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { COMMUNITY_LEVELS } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function ClientDashboardSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [backSideImage, SetbacksideImage] = useState("");

  let [inputs, setInputs] = useState({
    wealth_wallet_heading: "",
    ignite_leader_board_heading: "",
    dynamite_leader_board_heading: "",
    live_leader_board_heading: "",
    is_show_roadmap: false,
    win_leader_board_heading: "",
    win_leader_board_heading: "",
    cover_image_url: "",
    program_update_alert: "",
    plan_agreement_alrt: "",
    daily_dynamite_alrt: "",
    daily_gratitude_alrt: "",
    daily_meditation_alrt: "",
    ignite_leader_board_description: "",
    dynamite_leader_board_description: "",
    live_leader_board_description: "",
    win_leader_board_description: "",
    daily_dynamite_alert_description: "",
    daily_gratitude_alert_description: "",
    meditation_alert_description: "",
    booking_alert_description: "",
    complete_now_button_text: "",
    hours_reminder_daily_meditation: 0,
    lesson_lock_text: "",
    pick_card_button_text: "",
    after_pick_card_button_text: "",
    card_popup_heading: "",
    open_card_button_text: "",
    card_backside_image: "",
    card_description: "",
    attitude_assessment_complete_description: "",
  });

  const fileChangedHandler = async (e, i) => {
    const formData = new FormData();
    const { name } = e.target;
    formData.append("image", e.target.files[0]);
    formData.append("width", "2200");
    if (name === "card_backside_image") {
      SetbacksideImage(URL.createObjectURL(e.target.files[0]));
    }
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        [name]: imageUpload.image_path,
      });

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const clientDashboardData = async () => {
    setIsLoading(true);
    const result = await clientSettingApi();
    if (result.code == 200) {
      let setting = result.content_setting.client_dashboard_setting;
      setInputs(setting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    let postData = {
      cover_image_url: inputs.cover_image_url,
      daily_gratitude_alrt: inputs.daily_gratitude_alrt,
      daily_meditation_alrt: inputs.daily_meditation_alrt,
      daily_dynamite_alrt: inputs.daily_dynamite_alrt,
      program_update_alert: inputs.program_update_alert,
      plan_agreement_alrt: inputs.plan_agreement_alrt,
      ignite_leader_board_description: inputs.ignite_leader_board_description,
      dynamite_leader_board_description:
        inputs.dynamite_leader_board_description,
      live_leader_board_description: inputs.live_leader_board_description,
      win_leader_board_description: inputs.win_leader_board_description,
      daily_dynamite_alert_description: inputs.daily_dynamite_alert_description,
      daily_gratitude_alert_description:
        inputs.daily_gratitude_alert_description,
      meditation_alert_description: inputs.meditation_alert_description,
      booking_alert_description: inputs.booking_alert_description,
      ignite_leader_board_heading: inputs.ignite_leader_board_heading,
      dynamite_leader_board_heading: inputs.dynamite_leader_board_heading,
      live_leader_board_heading: inputs.live_leader_board_heading,
      win_leader_board_heading: inputs.win_leader_board_heading,
      welcome_video_url: inputs.welcome_video_url,
      wealth_wallet_heading: inputs.wealth_wallet_heading,
      hours_reminder_daily_gratitude: inputs.hours_reminder_daily_gratitude,
      hours_reminder_daily_dynamite: inputs.hours_reminder_daily_dynamite,
      hours_reminder_daily_meditation: inputs.hours_reminder_daily_meditation,
      lesson_lock_text: inputs.lesson_lock_text,
      is_show_roadmap: inputs.is_show_roadmap,
      pick_card_button_text: inputs.pick_card_button_text,
      open_card_button_text: inputs.open_card_button_text,
      card_backside_image: inputs.card_backside_image,
      card_description: inputs.card_description,
      after_pick_card_button_text: inputs.after_pick_card_button_text,
      card_popup_heading: inputs.card_popup_heading,
      attitude_assessment_complete_description:
        inputs.attitude_assessment_complete_description,
    };

    COMMUNITY_LEVELS.map((level) => {
      postData[`${level.name}_text`] = inputs[`${level.name}_text`];
    });

    let data = {
      client_dashboard_setting: postData,
    };

    const result = await updateClientDashboardSettingApi(data);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    clientDashboardData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-6">
          <h2>Client Dashboard</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {COMMUNITY_LEVELS.map((level) => {
            return (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label={`${level.title} Text`}
                  variant="outlined"
                  fullWidth
                  required
                  name={`${level.name}_text`}
                  value={inputs[`${level.name}_text`]}
                  onChange={handleChange}
                />
              </div>
            );
          })}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Wealth Wallet Heading "
              variant="outlined"
              fullWidth
              required
              name="wealth_wallet_heading"
              value={inputs.wealth_wallet_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Ignite Leader Board Heading"
              variant="outlined"
              fullWidth
              required
              name="ignite_leader_board_heading"
              value={inputs.ignite_leader_board_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Dynamite Leader Board Heading"
              variant="outlined"
              fullWidth
              required
              name="dynamite_leader_board_heading"
              value={inputs.dynamite_leader_board_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Live Leader Board Heading"
              variant="outlined"
              fullWidth
              required
              name="live_leader_board_heading"
              value={inputs.live_leader_board_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Win Leader Board Heading"
              variant="outlined"
              fullWidth
              required
              name="win_leader_board_heading"
              value={inputs.win_leader_board_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Welcome Video URL"
              variant="outlined"
              fullWidth
              name="welcome_video_url"
              value={inputs.welcome_video_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Hours before Reminder For Daily Dynamite"
              variant="outlined"
              fullWidth
              type="number"
              name="hours_reminder_daily_dynamite"
              value={inputs.hours_reminder_daily_dynamite}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Hours before Reminder For Daily Gratitude"
              variant="outlined"
              fullWidth
              type="number"
              name="hours_reminder_daily_gratitude"
              value={inputs.hours_reminder_daily_gratitude}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Hours before Reminder For Daily Meditation"
              variant="outlined"
              fullWidth
              type="number"
              name="hours_reminder_daily_meditation"
              value={inputs.hours_reminder_daily_meditation}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Lesson Lock Description"
              variant="outlined"
              fullWidth
              name="lesson_lock_text"
              value={inputs.lesson_lock_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Is Show Road Map ?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_show_roadmap"
                value={inputs.is_show_roadmap}
                label="Is Show Road Map ?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">The Source Banner Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(2200) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                <img
                  src={
                    inputs.cover_image_url
                      ? s3baseUrl + inputs.cover_image_url
                      : ""
                  }
                  height="50"
                />
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="cover_image_url"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>{" "}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Program Update Alert </p>
                <FormHelperText className="pt-0">
                  Image Size(600) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                <img
                  src={
                    inputs.program_update_alert
                      ? s3baseUrl + inputs.program_update_alert
                      : ""
                  }
                  height="50"
                />
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file_program">
                  <Input
                    accept="image/*"
                    id="contained-button-file_program"
                    multiple
                    type="file"
                    name="program_update_alert"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Agreement Alert </p>
                <FormHelperText className="pt-0">
                  Image Size(600) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                <img
                  src={
                    inputs.plan_agreement_alrt
                      ? s3baseUrl + inputs.plan_agreement_alrt
                      : ""
                  }
                  height="50"
                />
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-plan_agreement_alrt">
                  <Input
                    accept="image/*"
                    id="contained-button-plan_agreement_alrt"
                    multiple
                    type="file"
                    name="plan_agreement_alrt"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Daily Dynamite Alert </p>
                <FormHelperText className="pt-0">
                  Image Size(600) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                <img
                  src={
                    inputs.daily_dynamite_alrt
                      ? s3baseUrl + inputs.daily_dynamite_alrt
                      : ""
                  }
                  height="50"
                />
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-daily_dynamite_alrt">
                  <Input
                    accept="image/*"
                    id="contained-button-daily_dynamite_alrt"
                    multiple
                    type="file"
                    name="daily_dynamite_alrt"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>{" "}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Daily Gratitude Alert </p>
                <FormHelperText className="pt-0">
                  Image Size(600) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                <img
                  src={
                    inputs.daily_gratitude_alrt
                      ? s3baseUrl + inputs.daily_gratitude_alrt
                      : ""
                  }
                  height="50"
                />
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-daily_gratitude_alrt">
                  <Input
                    accept="image/*"
                    id="contained-button-daily_gratitude_alrt"
                    multiple
                    type="file"
                    name="daily_gratitude_alrt"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Meditation Alert </p>
                <FormHelperText className="pt-0">
                  Image Size(600) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                <img
                  src={
                    inputs.daily_meditation_alrt
                      ? s3baseUrl + inputs.daily_meditation_alrt
                      : ""
                  }
                  height="50"
                />
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-daily_meditation_alrt">
                  <Input
                    accept="image/*"
                    id="contained-button-daily_meditation_alrt"
                    multiple
                    type="file"
                    name="daily_meditation_alrt"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Ignite Leader Board Description"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              required
              name="ignite_leader_board_description"
              value={inputs.ignite_leader_board_description}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Dynamite Leader Board Description"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              required
              name="dynamite_leader_board_description"
              value={inputs.dynamite_leader_board_description}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Live Leader Board Description"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              required
              name="live_leader_board_description"
              value={inputs.live_leader_board_description}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Win Description"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              required
              name="win_leader_board_description"
              value={inputs.win_leader_board_description}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Daily Dynamite Alert Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="daily_dynamite_alert_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Daily Gratitude Alert Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="daily_gratitude_alert_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Meditation Alert Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="meditation_alert_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Booking Alert Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="booking_alert_description"
              editorHeight={350}
            />
          </div>
          <div class="col-12 wheel-of-life-setting-section-heading mt-4">
            <h3 className="mt-3">Card of the day settings</h3>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Pick Card Button Text"
              variant="outlined"
              fullWidth
              type="text"
              required
              name="pick_card_button_text"
              value={inputs?.pick_card_button_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="After Pick Card Button Text"
              variant="outlined"
              fullWidth
              type="text"
              required
              name="after_pick_card_button_text"
              value={inputs?.after_pick_card_button_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Card Popup Heading"
              variant="outlined"
              fullWidth
              type="text"
              required
              name="card_popup_heading"
              value={inputs?.card_popup_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Open Card Button Text"
              variant="outlined"
              fullWidth
              type="text"
              required
              name="open_card_button_text"
              value={inputs?.open_card_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Card Backside Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(530 X 800) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                <img
                  src={
                    backSideImage
                      ? backSideImage
                      : inputs.card_backside_image
                      ? s3baseUrl + inputs.card_backside_image
                      : ""
                  }
                  height="50"
                />
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file-card">
                  <Input
                    accept="image/*"
                    id="contained-button-file-card"
                    multiple
                    type="file"
                    name="card_backside_image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Card Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="card_description"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Attitude Assessment Complete Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="attitude_assessment_complete_description"
              editorHeight={350}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
