import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  daily_message_list_api,
  delete_daily_message_api,
} from "src/DAL/Community/DailyMessages";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import DailyMessagesDataType from "./DailyMessagesDataType";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  { id: "note_for_admin", label: "Admin Text Box", alignRight: false },
  { id: "message_date", label: "Start Date", alignRight: false },
  { id: "message_end_date", label: "End Date", alignRight: false },
  // { id: "is_show_on_feed", label: "Show On Feed", alignRight: false },
  {
    id: "type",
    label: "Type",
    alignRight: false,
    className: "text-capitalize",
  },
  {
    id: "status",
    label: "Status",
    type: "row_status",
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DailyMessages() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();

  const [tabValue, setTabValue] = useState(0);
  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleNavigate = () => {
    navigate(`/daily-messages/add-message`, {
      state: { tabValue: tabValue },
    });
  };

  useEffect(() => {
    if (state) {
      setTabValue(state.tabValue ? state.tabValue : 0);
    }
  }, [state]);

  const TABS_OPTIONS = [
    {
      title: "Dynamite Diary",
      component: (
        <DailyMessagesDataType tabValue={tabValue} tabName={"dynamite_diary"} />
      ),
    },
    {
      title: "Gratitue Daily",
      component: (
        <DailyMessagesDataType
          tabValue={tabValue}
          tabName={"dynamite_gratitude"}
        />
      ),
    },
    {
      title: "Meditation",
      component: (
        <DailyMessagesDataType tabValue={tabValue} tabName={"meditation"} />
      ),
    },
    {
      title: "The Source",
      component: (
        <DailyMessagesDataType tabValue={tabValue} tabName={"the_source"} />
      ),
    },
  ];
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-lg-6 col-sm-12">
            <h2>Daily Messages</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Message
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <MUICustomTabs
              data={TABS_OPTIONS}
              value={tabValue}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}
