import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import {
  get_protal_list_for_plan_api,
  update_portals_for_plan_api,
} from "src/DAL/WebsitePages/paymentPlan";
import moment from "moment";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EventAccessPaymentPlan() {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [planDetail, setPlanDetail] = useState({});
  const [rowPerPage, setrowPerPage] = useState("");

  const getting_start_date = (object, plan) => {
    let selected_event = plan.event.find(
      (old_event) => old_event.event_id == object._id
    );
    if (selected_event) {
      return selected_event.start_date;
    }
    return object.start_date;
  };

  const getEventsList = async () => {
    const result = await get_protal_list_for_plan_api(params.plan_id);
    if (result.code == 200) {
      setPlanDetail(result.payment_plan);
      let selected_events = [];
      result.payment_plan.event.map((event) => {
        selected_events.push({
          ...event,
          _id: event.event_id,
        });
      });
      setSelected(selected_events);
      var eventsArray = [];
      setrowPerPage(result.events.length);
      result.events.map((item) => {
        item.start_date = getting_start_date(item, result.payment_plan);
        item.table_avatar = {
          src: s3baseUrl + item.images.thumbnail_3,
          alt: item.title,
        };
        item.is_show_celendar = true;
        item.type = "day";
        item.access_type = result?.payment_plan?.is_plan_free ? "free" : "paid";
        item.expiry_date = new Date(item.start_date);
        eventsArray.push(item);
      });
      setEventsList(eventsArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  console.log(selected, "selectedselectedselected");
  const handleSubmit = async () => {
    setIsLoadingForm(true);
    let events_array = [];
    selected.map((item) => {
      var event_object = {
        event_id: item._id,
        title: item.title,
        start_date: moment(item.start_date).format("YYYY-MM-DD"),
        type: item.type,
        access_type: item.access_type,
      };
      if (item.type == "day") {
        event_object.no_of_days = parseInt(item.no_of_days, 10);
      } else {
        event_object.expiry_date = moment(item.expiry_date).format(
          "YYYY-MM-DD"
        );
      }
      events_array.push(event_object);
    });
    let eventObject = {
      event: events_array,
    };

    const result = await update_portals_for_plan_api(
      params.plan_id,
      eventObject
    );
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event, name) => {
    const value = event.target.value;
    const event_id = event.target.name;
    setEventsList((data) =>
      data.map((obj) => {
        if (obj._id === event_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === event_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };
  const handleChangeType = (event, name, id) => {
    const value = event.target.value;
    setEventsList((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };
  const find_and_update_start_date = (row, date, list, setList) => {
    let find_index = list.findIndex((prog) => prog._id == row._id);
    let temp_state = [...list];
    let temp_element = { ...temp_state[find_index] };
    temp_element.start_date = date;
    temp_state[find_index] = temp_element;
    setList(temp_state);
  };

  const handleChangeStartDate = (date, index, row) => {
    find_and_update_start_date(row, date, eventsList, setEventsList);
    find_and_update_start_date(row, date, selected, setSelected);
  };

  const handleChangeExpiryDate = (date, row) => {
    let find_index = eventsList.findIndex((prog) => prog._id == row._id);
    let temp_state = [...eventsList];
    let temp_element = { ...temp_state[find_index] };
    temp_element.expiry_date = date;
    temp_element.type = "date";
    temp_state[find_index] = temp_element;
    setEventsList(temp_state);

    let find_index_selected = selected.findIndex((prog) => prog._id == row._id);
    let temp_state_selected = [...selected];
    let temp_element_selected = { ...temp_state_selected[find_index_selected] };
    temp_element_selected.expiry_date = date;
    temp_state_selected[find_index_selected] = temp_element;
    setSelected(temp_state_selected);
  };

  const TABLE_HEAD = [
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "p-0",
    },
    { id: "title", label: "Event Title" },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
    {
      id: "access_type",
      label: "Access Type",
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);
        let access_type =
          is_selected && is_selected.access_type
            ? is_selected.access_type
            : row.access_type;

        return (
          <div className="col-12">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="access_type"
                value={access_type}
                className="access_type_field"
                label=""
                onChange={(e) => {
                  handleChangeType(e, "access_type", row._id);
                }}
              >
                <MenuItem value="free">Free</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      id: "start_date",
      label: "Start Date",
      type: "row_calendar",
      handleChangeDate: handleChangeStartDate,
      className: "event_calendar_box",
    },
    {
      id: "no_of_start_days",
      label: "End Access Interval Type",
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);
        let type =
          is_selected && is_selected.type ? is_selected.type : row.type;

        return (
          <div className="col-12">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="type"
                value={type}
                label=""
                onChange={(e) => {
                  handleChangeType(e, "type", row._id);
                }}
              >
                <MenuItem value="day">Days</MenuItem>
                <MenuItem value="date">Date</MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      id: "no_of_days",
      label: "No Of Days",
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);
        let no_of_days =
          is_selected && is_selected.no_of_days
            ? is_selected.no_of_days
            : row.no_of_days;
        let expiry_date =
          is_selected && is_selected.expiry_date
            ? is_selected.expiry_date
            : row.expiry_date;
        let type =
          is_selected && is_selected.type ? is_selected.type : row.type;
        return (
          <>
            {type == "day" ? (
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="small"
                type="number"
                required
                name={row._id}
                className="default_consultant_commission_input"
                value={no_of_days}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                onChange={(e) => {
                  handleChange(e, "no_of_days");
                }}
              />
            ) : (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="dd-MM-yyyy"
                  onChange={(e) => {
                    handleChangeExpiryDate(e, row);
                  }}
                  value={expiry_date ? expiry_date : new Date(row.start_date)}
                  minDate={new Date(row.start_date)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getEventsList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const websitePages = window.location.pathname.includes("/website-pages");

  let breadCrumbMenu = [
    {
      title: websitePages ? "Pages" : "Template Pages",
      navigation: `${websitePages ? `/website-pages` : `/template-pages`}`,
      active: false,
    },
    {
      title: "Payment Plans",
      navigation: `${
        websitePages
          ? `/website-pages/${params?.page_id}/payment-plans`
          : `/template-pages/${params?.page_id}/payment-plans`
      }`,
      active: false,
    },
    {
      title: planDetail?.plan_title,
      active: true,
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-6">
          <h2>Manage Events Access</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={eventsList}
            selected={selected}
            setSelected={setSelected}
            checkbox_selection={true}
            pagePagination={false}
            rows_per_page_count={rowPerPage}
            hide_footer_pagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
