import { invokeApi } from "../../bl_libs/invokeApi";

export const meditationsListing = async (data) => {
  const requestObj = {
    path: `/api/meditation_category/list_meditation_category_active`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const allMeditationsListing = async (data) => {
  const requestObj = {
    path: `/api/meditation_category/list_all_mediatation_category`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const meditationCategoryActiveListing = async (data) => {
  const requestObj = {
    path: `/api/meditation_category/list_meditation_category_active`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const meditation_category_detail = async (id) => {
  const requestObj = {
    path: `/api/meditation_category/meditation_category_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddMeditationCategoryApi = async (data) => {
  const requestObj = {
    path: `/api/meditation_category/add_meditation_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteMeditationCategoryApi = async (slug) => {
  const requestObj = {
    path: `/api/meditation_category/delete_meditation_category/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const UpdateMeditationCategoriesApi = async (data, slug) => {
  const requestObj = {
    path: `/api/meditation_category/update_meditation_category/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const meditationListingApi = async (data) => {
  const requestObj = {
    path: `/api/meditation/all_meditation_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddMeditationApi = async (data) => {
  const requestObj = {
    path: `/api/meditation/add_meditation`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UpdateMeditationApi = async (data, slug) => {
  const requestObj = {
    path: `/api/meditation/meditation_update/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteMeditationApi = async (slug) => {
  const requestObj = {
    path: `/api/meditation/delete_meditation/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
