import React, { useEffect, useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { dd_date_format } from "src/utils/constant";
import Label from "src/components/Label";

export default function ChllengesMembers({ tabData }) {
  const [membersList, setMembersList] = useState([]);

  const getMemberListing = async () => {
    const members = tabData?.map((item) => {
      let title = "N/A";
      let challange_start_date = "N/A";
      let challange_end_date = "N/A";
      let challange_completed_date = "N/A";
      if (item.challange_start_date) {
        challange_start_date = dd_date_format(item.challange_start_date);
      }
      if (item.challange_end_date) {
        challange_end_date = dd_date_format(item.challange_end_date);
      }
      if (item.challange_completed_date) {
        challange_completed_date = dd_date_format(
          item.challange_completed_date
        );
      }

      if (item._id?._id) {
        title = item._id?.title + " " + "(" + item._id?.challange_type + ")";
      }
      return {
        ...item,
        title: title,
        start_date: challange_start_date,
        end_date: challange_end_date,
        completed_date: challange_completed_date,
        status: item.status,
        is_complete: item.is_complete,
      };
    });

    setMembersList(members);
  };

  useEffect(() => {
    getMemberListing();
  }, [tabData]);
  console.log(tabData, "tabDatatabData");
  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },

    { id: "title", label: "Challenge Title", alignRight: false },
    { id: "start_date", label: "Challange Start Date", alignRight: false },
    { id: "end_date", label: "Challange End Date", alignRight: false },
    {
      id: "completed_date",
      label: "Challange Completed Date",
      alignRight: false,
    },

    {
      id: "is_complete",
      label: "Is Completed",
      renderData: (row) => {
        return (
          <Label
            variant="ghost"
            color={row.is_complete === false ? "error" : "success"}
          >
            {row.is_complete === false ? "Not Completed" : "Completed"}
          </Label>
        );
      },
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row"></div>
        <div className="row">
          <div className="col-lg-8 col-sm-8 mb-3 mt-3">
            <h2>Challenges </h2>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={membersList}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
