import { useEffect, useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { Ga4_stream_analytics_list_detail } from "src/DAL/Transaction/transactionApi";
import { makeStyles } from "@mui/styles";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";
import moment from "moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AnalaticsalReportDetail = ({ filterDataPopup }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const get_listing = async () => {
    setIsLoading(true);

    let postData = {
      start_date: moment(filterDataPopup.start_date).format("YYYY-MM-DD"),
      end_date: moment(filterDataPopup.end_date).format("YYYY-MM-DD"),
      pageTitle: filterDataPopup.page_title,
    };
    let result = await Ga4_stream_analytics_list_detail(postData);
    if (result.code === 200) {
      setTableData(result.analytics_report);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleCopiedUrl = () => {
    enqueueSnackbar("Page Url copied to clipboard", { variant: "success" });
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "pageLocation",
      label: "Page Url",
      renderData: (row) => {
        return (
          <div
            className="col-7 col-md-6 col-lg-3"
            style={{ cursor: "pointer" }}
          >
            <CopyToClipboard
              className="pointer me-2"
              text={row.pageLocation}
              onCopy={() => handleCopiedUrl(true)}
            >
              <Tooltip title="Copy Page Url">
                <ContentCopyIcon
                  style={{
                    fontSize: "20px",
                  }}
                />
              </Tooltip>
            </CopyToClipboard>
            {row.pageLocation}
          </div>
        );
      },
    },
    { id: "activeUsers", label: "Active Users" },
    { id: "newUsers", label: "New Users" },
    { id: "eventCount", label: "Event Count" },
    { id: "screenPageViews", label: " Page Views" },
  ];
  useEffect(() => {
    get_listing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="contaier-fluid"></div>
      <div className="col-12 mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={tableData}
          className="card-with-background"
          table_title={
            filterDataPopup.page_title + " " + "Detailed Analytic Report"
          }
          is_hide={true}
        />
      </div>
    </>
  );
};

export default AnalaticsalReportDetail;
