import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { programmeActiveListing } from "src/DAL/Programme/Programme";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { s3baseUrl } from "src/config/config";
import {
  AddStudySessionApi,
  study_session_detail,
  UpdateStudySessionApi,
} from "src/DAL/studySession/studySessionApi";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateSession() {
  const navigate = useNavigate();
  const { session_slug } = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [file, setProfileImage] = useState();
  const [documentFile, setDocumentFile] = useState();
  const [oldDocumentFile, setOldDocumentFile] = useState();
  const [programList, setProgramList] = useState([]);
  const [programName, setProgramName] = useState(null);
  const [formType, setFormType] = useState("ADD");
  const [oldImage, setOldImage] = useState("");
  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    is_show_sidebar: true,
    order: 0,
    short_description: "",
    detailed_description: "",
    image: {},
    document_file: {},
  });

  const getProgramListing = async () => {
    const result = await programmeActiveListing();
    setIsLoading(true);
    if (result.code === 200) {
      setProgramList(result.program);
      if (!session_slug) {
        setProgramName(result.program[0]);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleFormatData = (data) => {
    setFormType("EDIT");
    setInputs(data);
    if (data.program?.program_slug) {
      setProgramName(data.program);
    }
    setOldImage(data.image.thumbnail_2);
    setOldDocumentFile(data.document);
    window.history.replaceState({}, document.title);
    getProgramListing();
  };

  const fileChangedHandler = (e, type) => {
    if (type == "document") {
      setDocumentFile(URL.createObjectURL(e.target.files[0]));
    } else {
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
    setInputs({
      ...inputs,
      [e.target.name]: e.target.files[0],
    });
  };

  const getSessionData = async () => {
    setIsLoading(true);
    let result = await study_session_detail(session_slug);
    if (result.code == 200) {
      handleFormatData(result.study_session);
    } else {
      navigate(`/sessions`);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("short_description", inputs.short_description);
    formData.append("detailed_description", inputs.detailed_description);
    formData.append("is_show_sidebar", inputs.is_show_sidebar);
    formData.append("program_slug", programName.program_slug);
    formData.append("status", inputs.status);
    if (documentFile) {
      formData.append("document_file", inputs.document_file);
    }

    if (formType === "EDIT") {
      formData.append("order", inputs.order);
      if (file) {
        formData.append("image", inputs.image);
      }
    } else {
      formData.append("image", inputs.image);
    }

    const result =
      formType === "ADD"
        ? await AddStudySessionApi(formData)
        : await UpdateStudySessionApi(formData, session_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/sessions`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeProgram = (value) => {
    setProgramName(value);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (session_slug) {
      if (state) {
        handleFormatData(state);
      } else {
        getSessionData();
      }
    } else {
      getProgramListing();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  // console.log(inputs, "inputs.documentFile");

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/sessions`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Study Session`}</h2>
        </div>
      </div>

      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            fullWidth
            required
            name="title"
            value={inputs.title}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Programme"
            selectedOption={programName}
            setSelectedOption={handleChangeProgram}
            optionsList={programList}
            name="title"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Do you want to use in Sidebar{" "}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_show_sidebar"
              required
              value={inputs.is_show_sidebar}
              label="Do you want to use in Sidebar *"
              onChange={handleChange}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        {formType === "EDIT" && (
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Order"
              variant="outlined"
              fullWidth
              required
              name="order"
              value={inputs.order}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              required
              value={inputs.status}
              label="Status *"
              onChange={handleChange}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-7">
              <p className="">Upload Document</p>
              <FormHelperText className="pt-0">
                Other Document ("PDF", "DOC", "XLSX", "DOCX","CSV","200MB")
              </FormHelperText>
            </div>
            <div className="col-2 pt-4">
              {oldDocumentFile && (
                <a
                  href={s3baseUrl + oldDocumentFile}
                  target="_blank"
                  className="anchor-style"
                >
                  View
                </a>
              )}
            </div>
            <div className="col-3 text-end pt-2">
              <label htmlFor="contained-button-file-other">
                <Input
                  accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.png,.csv"
                  id="contained-button-file-other"
                  multiple
                  type="file"
                  name="document_file"
                  onChange={(e) => {
                    fileChangedHandler(e, "document");
                  }}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.document_file?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.document_file?.name}</p>
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image *</p>
              <FormHelperText className="pt-0">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {file ? (
                <img src={file} height="50" />
              ) : (
                oldImage && <img src={s3baseUrl + oldImage} height="50" />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={(e) => {
                    fileChangedHandler(e, "image");
                  }}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image?.name}</p>
          )}
        </div>
        <div className="col-12 mt-2">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description *"
              multiline
              rows={6}
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <div className="col-12 mt-4">
          <h4>Detail Description </h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="detailed_description"
            editorHeight={320}
          />
        </div>

        <div className="text-end mt-4">
          <button className="small-contained-button">
            {formType == "ADD" ? "Submit" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
