import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  DeleteQuestion,
  questionListingApi,
} from "src/DAL/goalstatement/goalStatement";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  delegateQuestionListingApi,
  DeleteDelegateQuestion,
} from "src/DAL/delegateQuestions/delegateQuestions";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "question_statement", label: "Question", alignRight: false },
  {
    id: "question_type",
    label: "Type",
    alignRight: false,
    className: "text-capitalize",
  },
  { id: "order", label: "Order", alignRight: false },
  // { id: "member_count", label: "Members", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ProgrammeGoalStatement() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const [questionData, setQuestionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getConsultantListing = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);
      const result = await delegateQuestionListingApi(params.id);
      if (result.code === 200) {
        setIsLoading(false);
        // console.log(result, "okokkokok");
        // const data = result.delegate_question.map((question) => {
        //   console.log(question, "questionquestionquestion");
        //   return {
        //     ...question,
        //   };
        // });
        setQuestionData(result.delegate_question);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const handleEdit = (value) => {
    navigate(
      `/programmes/programmes-detail/${params.id}/edit-goal-statement/${value._id}`,
      {
        state: value,
      }
    );
  };

  const handleViewDetail = (value) => {
    navigate(`/goal-statement/detail-question/${value._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteDelegateQuestion(deleteDoc._id);
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/programmes/programmes-detail/${params.id}/add-goal-statement`);
  };
  // console.log(params, "params");
  useEffect(() => {
    getConsultantListing();
  }, []);
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    // {
    //   label: "View Detail",
    //   icon: "akar-icons:eye-open",
    //   handleClick: handleViewDetail,
    // },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-12">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-lg-8 col-sm-12">
            <h2>Delegate Questions</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Question
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={questionData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
