import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import {
  AddDelegateQuestion,
  delegateQuestionDetailApi,
  EditDelegateQuestion,
} from "src/DAL/delegateQuestions/delegateQuestions";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ProgrammeAddOrUpdateQuestion() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState("ADD");
  const [inputs, setInputs] = useState({
    question_statement: "",
    question_type: "textbox",
    question_placeholder: "",
    status: true,
    order: 0,
    groups: [],
    members: [],
  });

  const questionDetail = async () => {
    setIsLoading(true);
    const result = await delegateQuestionDetailApi(params.question_id);
    if (result.code === 200) {
      setFormType("EDIT");
      setInputs(result.delegates_question);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const postData = {
      question_statement: inputs.question_statement,
      question_type: inputs.question_type,
      question_placeholder: inputs.question_placeholder,
      program_slug: params.id,
      status: inputs.status,
    };

    if (formType === "EDIT") {
      postData.order = inputs.order;
    }
    const result =
      formType === "ADD"
        ? await AddDelegateQuestion(postData)
        : await EditDelegateQuestion(postData, params.question_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/programmes/programmes-detail/${params?.id}/goal-statement`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (params && params.question_id) {
      if (state) {
        setFormType("EDIT");
        setInputs(state);
      } else {
        questionDetail();
      }
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>{`${formType === "ADD" ? "Add" : "Edit"} Question`}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Question"
              variant="outlined"
              required
              fullWidth
              name="question_statement"
              value={inputs.question_statement}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Question Placeholder"
              variant="outlined"
              fullWidth
              name="question_placeholder"
              value={inputs.question_placeholder}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Question Type *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="question_type"
                value={inputs.question_type}
                label="Question Type *"
                onChange={handleChange}
              >
                <MenuItem value="textbox">Textbox</MenuItem>
                <MenuItem value="textarea">Textarea</MenuItem>
              </Select>
            </FormControl>
          </div>
          {formType === "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Question order*"
                variant="outlined"
                fullWidth
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Question Status *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Question Status *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">Groups</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={groupsName}
                  onChange={handleChangeGroup}
                  input={<OutlinedInput label="Groups" />}
                  MenuProps={MenuProps}
                >
                  {groupListing.map((name) => (
                    <MenuItem
                      key={name}
                      value={name.group_slug}
                      style={getStyles(name, groupsName, theme)}
                    >
                      {name.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="mt-4">
              <Autocomplete
                multiple
                id="tags-outlined"
                options={personName}
                getOptionLabel={(option) =>
                  option.first_name + " (" + option.email + ")"
                }
                filterSelectedOptions
                value={member}
                onChange={(event, newValue) => {
                  setMember(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Members"
                    placeholder="Members"
                  />
                )}
              />
            </div>
          </div> */}

          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
