// material
import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { dashboardApi } from "src/DAL/Login/Login";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
// components
import {
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppWeeklySales,
} from "../components/_dashboard/app";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import RecentMembersTable from "src/components/_dashboard/RecentMembers";
import Page from "src/components/Page";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import GeneralCalendar from "src/components/GeneralComponents/GeneralCalendar";
import {
  difference_between_two_dates,
  get_view_dates,
} from "src/utils/constant";
import moment from "moment";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import StatisticsFilter from "src/components/_dashboard/StatsFilter";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function StatsFilter() {
  const [isLoading, setIsLoading] = useState(true);
  const [isCalenderLoading, setIsCalenderLoading] = useState(true);
  const classes = useStyles();
  const { metaTitle } = usePGIMode();
  const { enqueueSnackbar } = useSnackbar();
  const [defaultTimeValue, setDefaultTimeValue] = useState();
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [selectedDelegate, setSelectedDelegate] = useState(null);
  const [inputs, setInputs] = useState({
    member_count: "",
    program_count: "",
    lesson_count: "",
    program_recording_count: "",
    recent_lesson: [],
    recent_member: [],
    events: [],
  });
  const [filterData, setFilterData] = useState({
    program: [],
    community: [],
    is_date_range: false,
    from_date: null,
    to_date: null,
    goal_statement: "",
    status: "",
  });
  let arr1 = [
    {
      _id: "123456789",
      goal_statement_status: false,
      goal_statement_completed_date: "1970-01-01T00:00:00.000Z",
      status: true,
      first_name: "Thinking into result for ",
      last_name: "mastermind",
      sale_page: "TIR",
    },
  ];

  const searchFunction = () => {
    // setPage(0);
    // setPageCount(1);
    // filterData.search_text = searchText;
    // getMembersList(filterData);
    // localStorage.setItem("member_filter_data", JSON.stringify(filterData));
    // handleCloseFilterDrawer();
  };
  const handleClearFilter = () => {
    // setFilterData(EMPTY_FILTER);
    // localStorage.removeItem("member_filter_data");
    // getMembersList(EMPTY_FILTER);
    setFilterDrawerState(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleIsDateRange = (event) => {
    const value = event.target.checked;
    setFilterData((values) => ({ ...values, is_date_range: value }));
  };
  const handleChangeDates = (event, name) => {
    const value = moment(event).format("YYYY-MM-DD");
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const getDashboard = async () => {
    let apiData = {
      start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
      end_date: moment(currentDate.end_date).format("YYYY-MM-DD"),
    };
    const difference = difference_between_two_dates(
      apiData.start_date,
      apiData.end_date,
      "days"
    );

    if (difference < 10) {
      setIsCalenderLoading(true);
    }
    const result = await dashboardApi(apiData);
    if (result.code == 200) {
      setInputs(result);
      setDefaultTimeValue(result.admin_time_zone);
      setIsLoading(false);
      setIsCalenderLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsCalenderLoading(false);
    }
  };

  useEffect(() => {
    getDashboard();
  }, [currentDate]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <Page title={metaTitle}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
      </div>
      <Container maxWidth="xl">
        {/* <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome To Stats</Typography>
        </Box> */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={"500"}
              title="Live Streaming Tickets"
              icon={false}
              currency={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={"500"}
              title="General Event Tickets"
              icon={false}
              currency={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales total={"807000"} title="Total Sales" icon={true} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={inputs.total_dynamite_commission}
              title="Total Delegates Commission"
              icon={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={inputs.total_referral_commission}
              title="Total Referral Commission"
              icon={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers members={inputs.member_count} />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={inputs.program_recording_count}
              currency={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders programs={inputs.program_count} />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppBugReports lesson={inputs.lesson_count} />
          </Grid> */}

          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={inputs.total_user}
              currency={false}
              title="Total Users"
            />
          </Grid> */}
          <Grid item xs={12} sm={6} md={9}></Grid>
          {/* <Grid item xs={12} sm={6} md={6}>
            <RecentMembersTable
              recentMember={arr1}
              title="Payment Plan"
              type="payment_plan"
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={6}>
            <RecentMembersTable
              recentMember={inputs.recent_goal_statement_members}
              title="Recent Goal Statements"
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={12}>
            <GeneralCalendar
              eventList={inputs.events}
              setCurrentDate={setCurrentDate}
              showAddEvent={false}
              time_zone={defaultTimeValue}
              currentDate={currentDate}
              isLoading={isCalenderLoading}
            />
          </Grid> */}
        </Grid>
      </Container>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <StatisticsFilter
            filterData={filterData}
            handleChange={handleChange}
            handleIsDateRange={handleIsDateRange}
            handleChangeDates={handleChangeDates}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
            setSelectedDelegate={setSelectedDelegate}
            selectedDelegate={selectedDelegate}
          />
        }
      />
    </Page>
  );
}
