import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Chip, Tooltip } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  deletePaymentPlanApi,
  paymentPlansListApi,
} from "src/DAL/WebsitePages/paymentPlan";
import { page_detail_by_id } from "src/DAL/WebsitePages/WebsitePages";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { client_Url, s3baseUrl } from "src/config/config";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import CopyToClipboard from "react-copy-to-clipboard";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function WebPagePaymentPlans() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const classes = useStyles();
  const [moduleData, setModuleData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState([]);
  const [pageData, setPageData] = useState({});
  const is_website_pages = window.location.pathname.includes("website-pages");
  const module_actual_name = "payment_plans";

  let list_name = "/template-pages";
  if (is_website_pages) {
    list_name = "/website-pages";
  }

  const getPlansListing = async () => {
    const result = await paymentPlansListApi(
      params.page_id ? params.page_id : ""
    );
    if (result.code === 200) {
      const data = result.payment_plan.map((plan) => {
        return {
          ...plan,
          is_free:
            plan.is_plan_free == true
              ? "Free"
              : `Paid ( ${
                  plan.payment_access === "recursion"
                    ? "Recurring"
                    : plan.payment_access
                } ${
                  plan.payment_access == "onetime"
                    ? ""
                    : plan.product?.name
                    ? `| ${plan.product.name} `
                    : ""
                })`,
          productName: plan.product?.name ? plan.product.name : "N/A",
          planImage: {
            src: s3baseUrl + plan.plan_image,
            alt: plan.plan_title,
          },

          time_period_interval_param: `${plan.time_period_interval}  ${
            plan.payment_access == "recursion" && plan.plan_type
              ? plan.plan_type
              : ""
          }`,
          planPrice:
            plan.is_plan_free == true
              ? "£0"
              : plan.plan_price
              ? plan.plan_currency == "usd"
                ? "$" + plan.plan_price
                : plan.plan_currency == "gbp"
                ? "£" + plan.plan_price
                : plan.plan_currency == "eur"
                ? "€" + plan.plan_price
                : "" + plan.plan_price
              : "£0",
        };
      });
      setPaymentPlan(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleMenu = (row) => {
    const MENU_OPTIONS = [
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      },
      {
        label: "Manage Notification Sequence",
        icon: "akar-icons:edit",
        handleClick: handleManageNotification,
      },
    ];
    if (!row.is_plan_free) {
      MENU_OPTIONS.push({
        label: "Commission Configuration",
        icon: "akar-icons:edit",
        handleClick: handleManageCommissionConfiguration,
      });
    }
    MENU_OPTIONS.push(
      {
        label: "Manage Programme Access",
        icon: "akar-icons:edit",
        handleClick: handleManageProgrammeAccess,
      },
      {
        label: "Manage Bonus Content Access",
        icon: "akar-icons:edit",
        handleClick: handleManageBonusContentAccess,
      },
      {
        label: "Manage Pixel Events",
        icon: "akar-icons:edit",
        handleClick: (e) => {
          handlePaymentPageManagePixelEvents(e);
        },
      },
      // {
      //   label: "Manage Commission Access",
      //   icon: "akar-icons:edit",
      //   handleClick: handleManageCommissionAccess,
      // },
      {
        label: "Mission Control Setting",
        icon: "akar-icons:edit",
        handleClick: handleMSSetting,
      }
    );
    if (row.plan_created_for == "general") {
      MENU_OPTIONS.push(
        {
          label: "Manage Product Access",
          icon: "akar-icons:edit",
          handleClick: handleManagePrductAccess,
        },
        {
          label: "Manage Events Access",
          icon: "akar-icons:edit",
          handleClick: handleManageEventsAccess,
        },
        {
          label: "Agreement Configuration",
          icon: "akar-icons:edit",
          handleClick: handleAgreementConfig,
        },
        {
          label: "Copy Url",
          icon: "akar-icons:edit",
          handleClick: handleCopyUrl,
        },
        {
          label: "View Subscribers",
          icon: "akar-icons:edit",
          handleClick: handleSubscriber,
        }
      );
    } else {
      MENU_OPTIONS.push(
        {
          label: "Manage Events Access",
          icon: "akar-icons:edit",
          handleClick: handleManageEventsAccess,
        },
        {
          label: "Agreement Configuration",
          icon: "akar-icons:edit",
          handleClick: handleAgreementConfig,
        },
        {
          label: "Copy Url",
          icon: "akar-icons:edit",
          handleClick: handleCopyUrl,
        },
        {
          label: "View Subscribers",
          icon: "akar-icons:edit",
          handleClick: handleSubscriber,
        }
      );
    }
    return MENU_OPTIONS;
  };

  const handle_navigate = (value, path) => {
    navigate(path, {
      state: {
        data: value,
        page_info: pageData,
      },
    });
  };

  const handleSubscriber = (value) => {
    let path = `${list_name}/${params.page_id}/subscriber/${value._id}`;
    handle_navigate(value, path);
  };

  const handlePaymentPageManagePixelEvents = (value) => {
    let path = `${list_name}/${params.page_id}/payment-plans/manage-pixel-events/${value._id}`;
    handle_navigate(value, path);
  };

  const handleEdit = (value) => {
    let path = `${list_name}/${params.page_id}/payment-plans/edit-plan/${value._id}`;
    handle_navigate(value, path);
  };

  const handleMSSetting = (value) => {
    let path = `${list_name}/${params.page_id}/payment-plans/${value._id}/mission-control-setting`;
    handle_navigate(value, path);
  };

  const handleAgreementConfig = (value) => {
    let path = `${list_name}/${params.page_id}/payment-plans/${value._id}/agreement-config`;
    handle_navigate(value, path);
  };

  const handleManageProgrammeAccess = (value) => {
    let path = `${list_name}/${params.page_id}/payment-plans/${value._id}/manage-program-access`;
    handle_navigate(value, path);
  };

  const handleManageNotification = (value) => {
    let path = `${list_name}/${params.page_id}/payment-plans/${value._id}/manage-notification-sequence`;
    handle_navigate(value, path);
  };

  const handleManageBonusContentAccess = (value) => {
    let path = `${list_name}/${params.page_id}/payment-plans/${value._id}/manage-bonus-content-access`;
    handle_navigate(value, path);
  };

  const handleManagePrductAccess = (value) => {
    let path = `${list_name}/${params.page_id}/payment-plans/${value._id}/manage-product-access`;
    handle_navigate(value, path);
  };

  const handleManageEventsAccess = (value) => {
    let path = `${list_name}/${params.page_id}/payment-plans/${value._id}/manage-events-access`;
    handle_navigate(value, path);
  };
  const handleManageCommissionConfiguration = (value) => {
    let path = `${list_name}/${params.page_id}/payment-plans/${value._id}/commission-configuration`;
    handle_navigate(value, path);
  };
  const handleManageCommissionAccess = (value) => {
    let path = `${list_name}/${params.page_id}/payment-plans/${value._id}/manage-commission-access`;
    handle_navigate(value, path);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const getPageDetail = async () => {
    setIsLoading(true);
    const result = await page_detail_by_id(params.page_id);
    if (result.code == 200) {
      handlePageDetail(result.sale_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deletePaymentPlanApi(deleteDoc._id);
    if (result.code === 200) {
      getPlansListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`${list_name}/${params.page_id}/payment-plans/add-plan`, {
      state: pageData,
    });
  };

  const handlePageDetail = (data) => {
    const get_module_info = data.module_info.filter(
      (item) => item.module_actual_name == module_actual_name
    )[0];
    setModuleData(get_module_info.module_replica_info);
    setPageData(data);
  };

  const handleCopyUrl = (value) => {
    const url =
      client_Url + state.sale_page_title_slug + "/payment/" + value.plan_slug;
    navigator.clipboard.writeText(url).then(
      () => {
        enqueueSnackbar("Payment plan url copied.", { variant: "success" });
      },
      () => {
        enqueueSnackbar("Something went wrong, Please contact support!", {
          variant: "error",
        });
      }
    );
  };

  const handleCopyMessage = async (message) => {
    enqueueSnackbar(message, { variant: "success" });
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
    },
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "plan_title", label: "Title", alignRight: false },
    { id: "planImage", label: "Image", alignRight: false, type: "thumbnail" },
    {
      id: "plan_created_for",
      label: "Plan type",
      alignRight: false,
      className: "text-capitalize",
    },
    {
      id: "is_free",
      label: "Plan",
      alignRight: false,
      className: "text-capitalize",
    },
    { id: "planPrice", label: "Plan Price", alignRight: false },
    {
      id: "time_period_interval_param",
      label: "Time Period Interval",
      alignRight: false,
      className: "text-capitalize",
    },

    {
      id: "plan_status",
      label: "Status",
      alignRight: false,
      type: "row_status",
    },
  ];

  TABLE_HEAD.push({
    id: "plan_id",
    label: "Copy Plan ID",
    renderData: (row) => (
      <CopyToClipboard
        text={row._id}
        onCopy={() => handleCopyMessage("Plan ID Copied to clipboard")}
      >
        <Tooltip title="Click to copy Plan ID">
          <Chip
            label="Copy Plan ID"
            color="primary"
            className="mt-2"
            variant="outlined"
          />
        </Tooltip>
      </CopyToClipboard>
    ),
  });

  useEffect(() => {
    getPlansListing();
    if (params.page_id) {
      if (state) {
        handlePageDetail(state);
      } else {
        getPageDetail();
      }
    }
  }, [params.page_id]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let breadCrumbMenu = [
    {
      title: is_website_pages ? "Pages" : "Template Pages",
      navigation: list_name,
      active: false,
    },
    {
      title: pageData?.sale_page_title,
      active: true,
    },
  ];

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        {params.page_id && (
          <div className="row">
            <div className="col-12 mb-3">
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            </div>
          </div>
        )}
        <div className="row mb-2">
          <div className="col-lg-8 col-sm-12">
            <h2>
              {moduleData?.list_page_heading
                ? moduleData.list_page_heading
                : "Payment Plans"}
            </h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              {moduleData?.add_button_text
                ? moduleData.add_button_text
                : "Add Payment Plan"}
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={paymentPlan}
          className="card-with-background text-nowrap"
          pagePagination={true}
          handle_menus={handleMenu}
        />
      </div>
    </>
  );
}
