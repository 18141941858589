import { TextField } from "@mui/material";
import React from "react";
import { useState } from "react";

export default function LinkAndPathField(props) {
  const { item, handleChange } = props;
  const [isField, setIsField] = useState(false);
  return (
    <div>
      {isField ? (
        <TextField
          id="outlined-basic"
          label={`${item.is_link ? "Link" : "Path"}`}
          size="small"
          variant="outlined"
          fullWidth
          aria-readonly
          autoFocus
          type="text"
          name="path"
          value={item?.path}
          onChange={handleChange}
        />
      ) : (
        <div className="static-textfield" onClick={() => setIsField(true)}>
          {item?.path && (
            <div className="static-textfield-label px-1">
              {item.is_link ? "Link" : "Path"}
            </div>
          )}
          <div className="field-value">
            {item?.path ? (
              item?.path
            ) : (
              <span className="disabled_label">
                {item.is_link ? "Link" : "Path"}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
