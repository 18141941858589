import { useEffect, useState } from "react";
import {
  Card,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import jsPDF from "jspdf";
import Label from "src/components/Label";
import ReactDOMServer from "react-dom/server";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { orderDetailList } from "src/DAL/Transaction/transactionApi";
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { Icon } from "@iconify/react";
import { convertCurrencyToSign, replace_created_for } from "src/utils/constant";
import moment from "moment";
import { download_csv_file } from "src/utils/convertHtml";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import AddInternalNotes from "./AddInternalNotes";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function InternalNotes() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const [ticketsList, setTicketsList] = useState([]);
  const [ProductList, setProductList] = useState([]);
  const [ProductListDetail, setProductListDetail] = useState([]);
  const [orderDeatail, setOrderDeatail] = useState();

  const getBookingStatusList = async () => {
    setIsLoading(true);
    const result = await orderDetailList(params.id);
    if (result.code == 200) {
      setOrderDeatail(result.order_detail);
      let data = [];
      let product_data = [];
      if (
        result.order_detail.tickets &&
        result.order_detail.tickets.length > 0
      ) {
        data = result.order_detail.tickets.map((user) => {
          return {
            ...user,
            venue_title: user.venue_id ? user.venue_id.title : "N/A",
            name: user.first_name + " " + user.last_name,
          };
        });
      }
      setTicketsList(data);
      if (
        result.order_detail?.transaction_type == "dynamite_product_purchase"
      ) {
        setProductListDetail(result.order_detail.product_order_id);
        product_data = result.order_detail.product_order_id.products.map(
          (item) => {
            return {
              ...item,
              title: item.product_id.name,
              price: item.price,
              quantity: item.quantity,
              table_avatar: {
                src: s3baseUrl + item.product_id.image[0].image,
                alt: item.product_id.name,
              },
            };
          }
        );
      }
      setProductList(product_data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_transaction_type = (row) => {
    let t_type = "N/A";
    let transaction_type = row.transaction_type;
    let sale_page = row?.sale_page?.sale_page_title;
    let plan_title = row?.plan?.plan_title;
    let request_title = row?.payment_request?.request_title;

    if (transaction_type == "payment_plan" && sale_page) {
      t_type =
        "Sale Page (" +
        sale_page +
        " | " +
        plan_title +
        " | " +
        row.transaction_request_type +
        ")";
    } else if (transaction_type == "dynamite_product_purchase") {
      t_type = "Dynamite Shop";
    } else if (transaction_type == "payment_request") {
      t_type = "Payment Request ";
      if (request_title) {
        t_type = t_type + "(" + request_title + ")";
      }
    }
    return t_type;
  };
  const get_affliliate_info = (row) => {
    let affiliate_user_name = "Master Link";
    let refUser = row.affliliate;
    let affiliate_user = refUser?.affiliate_user_info;
    if (affiliate_user) {
      affiliate_user_name =
        affiliate_user.first_name + " " + affiliate_user.last_name;
    }
    return referralName(affiliate_user_name, refUser?.affiliate_url_name);
  };

  const referralName = (refferal_name, affiliate_url_name) => {
    return (
      <div>
        {`${refferal_name} `}
        {affiliate_url_name && (
          <span className="affiliate_url_name">({affiliate_url_name})</span>
        )}
      </div>
    );
  };

  let breadCrumbMenu = [
    {
      title: "Transaction",
      navigation: -1,
      active: false,
    },
    {
      title: "Internal Notes",
      active: false,
    },
    {
      title: orderDeatail?.member
        ? orderDeatail?.user_name + "(" + orderDeatail?.member?.email + ")"
        : orderDeatail?.user_name,
      active: true,
    },
  ];

  useEffect(() => {
    getBookingStatusList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12 display-flex mt-1">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
        </div>
        {orderDeatail.transaction_type == "dynamite_product_purchase" ? (
          <Card className="payment-card mb-3">
            <div className="row p-3">
              <div className="col-12">
                <div className="row">
                  <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                    Member:
                  </div>
                  {orderDeatail?.member ? (
                    <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                      {orderDeatail.user_name +
                        "(" +
                        orderDeatail?.member?.email +
                        ")"}
                    </div>
                  ) : (
                    <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                      {orderDeatail.user_name}
                    </div>
                  )}
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                    <p className="finalPrice">Total Amount:</p>
                  </div>
                  <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                    {convertCurrencyToSign(orderDeatail.currency)}
                    {orderDeatail.amount.toFixed(2)}
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                    Transaction Date:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                    <Icon icon="uiw:date" className="finalPrice" />
                    <span className="ms-1 mt-1">
                      {moment(orderDeatail.transaction_date).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </span>
                  </div>{" "}
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                    Payment Made By:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3  mt-5 mb-3 mt-lg-0 text-muted">
                    {orderDeatail.payment_made_by
                      ? orderDeatail.payment_made_by
                      : "N/A"}
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mt-5  mb-3 mt-lg-0 request-type">
                    Transaction Mode:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted">
                    {orderDeatail.transaction_mode}
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mb-3 mt-5 mt-lg-0 request-type">
                    Transaction Type:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                    {get_transaction_type(orderDeatail)}
                  </div>
                  {orderDeatail?.transection_by_name && (
                    <>
                      <div className="col-5 col-md-6 col-lg-3 mb-3 mt-5 mt-lg-0 request-type">
                        Transaction By:
                      </div>
                      <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                        {orderDeatail?.transection_by_name}
                      </div>
                    </>
                  )}
                  {orderDeatail?.transaction_note && (
                    <>
                      <div className="col-5 col-md-6 col-lg-3 mb-3 mt-5 mt-lg-0 request-type">
                        Transaction Note:
                      </div>
                      <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                        {orderDeatail?.transaction_note}
                      </div>
                    </>
                  )}
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                    Transaction Status:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 ">
                    <Label
                      variant="ghost"
                      color={
                        orderDeatail.transaction_status === "succeeded"
                          ? "success"
                          : "error"
                      }
                    >
                      {replace_created_for(orderDeatail.transaction_status)}
                    </Label>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        ) : (
          <Card className="payment-card mb-3">
            <div className="row p-3">
              <div className="col-12">
                <div className="row">
                  <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                    Member:
                  </div>
                  {orderDeatail?.member ? (
                    <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                      {orderDeatail.user_name +
                        "(" +
                        orderDeatail?.member?.email +
                        ")"}
                    </div>
                  ) : (
                    <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                      {orderDeatail.user_name}
                    </div>
                  )}
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                    <p className="finalPrice">Total Amount:</p>
                  </div>
                  <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                    {convertCurrencyToSign(orderDeatail.currency)}
                    {orderDeatail.amount.toFixed(2)}
                  </div>
                  <div className="col-5 col-md-6 col-lg-2 mb-3 request-type">
                    Transaction Date:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                    <Icon icon="uiw:date" className="finalPrice" />
                    <span className="ms-1 mt-1">
                      {" "}
                      {moment(orderDeatail.transaction_date).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </span>
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                    Payment Made By:
                  </div>
                  <div className="col-7 col-md-6 col-lg-4  mt-5 mb-3 mt-lg-0 text-muted">
                    {orderDeatail.payment_made_by
                      ? orderDeatail.payment_made_by
                      : "N/A"}
                  </div>{" "}
                  <div className="col-5 col-md-6 col-lg-2 mt-5  mb-3 mt-lg-0 request-type">
                    Transaction Mode:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted">
                    {orderDeatail.transaction_mode}
                  </div>{" "}
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                    Referral User:
                  </div>
                  <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                    {get_affliliate_info(orderDeatail)}
                  </div>{" "}
                  <div className="col-5 col-md-6 col-lg-2 mt-5 mt-lg-0 request-type">
                    Transaction Status:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 ">
                    <Label
                      variant="ghost"
                      color={
                        orderDeatail.transaction_status === "succeeded"
                          ? "success"
                          : "error"
                      }
                    >
                      {replace_created_for(orderDeatail.transaction_status)}
                    </Label>
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                    Transaction Type:
                  </div>
                  <div className="col-7 col-md-6 col-lg-9 text-muted ">
                    {get_transaction_type(orderDeatail)}
                  </div>
                  {orderDeatail?.transection_by_name && (
                    <>
                      <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                        Transaction By:
                      </div>
                      <div className="col-7 col-md-6 col-lg-9 text-muted ">
                        {orderDeatail?.transection_by_name}
                      </div>
                    </>
                  )}
                  {orderDeatail?.transaction_note && (
                    <>
                      <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                        Transaction Note:
                      </div>
                      <div className="col-7 col-md-6 col-lg-9 text-muted ">
                        {orderDeatail?.transaction_note}
                      </div>
                    </>
                  )}
                  {orderDeatail.other_info && (
                    <>
                      <div className="mt-3 mb-3">
                        <h3 className="text-center">Other Information</h3>
                      </div>
                      {orderDeatail.other_info.dessert_menu && (
                        <>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Dessert Menu :
                          </div>
                          <div className="col-7 col-md-6 col-lg-9 text-muted ">
                            {orderDeatail.other_info.dessert_menu}
                          </div>
                        </>
                      )}
                      {orderDeatail.other_info.dietary_requirements && (
                        <>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Dietary Requirements :
                          </div>
                          <div className="col-7 col-md-6 col-lg-9 text-muted ">
                            {orderDeatail.other_info.dietary_requirements}
                          </div>
                        </>
                      )}{" "}
                      {orderDeatail.other_info.main_menu && (
                        <>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Main Menu :
                          </div>
                          <div className="col-7 col-md-6 col-lg-9 text-muted ">
                            {orderDeatail.other_info.main_menu}
                          </div>
                        </>
                      )}{" "}
                      {orderDeatail.other_info.starter_menu && (
                        <>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Starter Menu :
                          </div>
                          <div className="col-7 col-md-6 col-lg-9 text-muted ">
                            {orderDeatail.other_info.starter_menu}
                          </div>
                        </>
                      )}{" "}
                      {orderDeatail.other_info.tea_and_coffee_menu && (
                        <>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Tea And Coffee Menu :
                          </div>
                          <div className="col-7 col-md-6 col-lg-9 text-muted ">
                            {orderDeatail.other_info.tea_and_coffee_menu}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Card>
        )}
        <div className="col-12 mb-3 ms-3 mt-4">
          <h3>Internal Notes</h3>
        </div>
        <div className="col-12 ">
          <AddInternalNotes
            orderDeatail={orderDeatail}
            getBookingStatusList={getBookingStatusList}
          />
        </div>
      </div>
    </>
  );
}
