import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { memberGroupListApi } from "src/DAL/member/member";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import MemberProgramPopover from "../MembersList/memberProgramPopOver";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function MemberGroupsList() {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [program, setprogram] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;

  const getConsultantListing = async () => {
    setIsLoading(true);
    const result = await memberGroupListApi(params.id);
    if (result.code === 200) {
      const data = result.member_groups.map((group) => {
        return {
          ...group,
        };
      });
      setUserList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getConsultantListing();
  }, []);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: " Name" },
    {
      id: "program",
      label: "Programme",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        return (
          <div>
            {firstFiveprograms &&
              firstFiveprograms.length > 0 &&
              firstFiveprograms?.map((program, i) => {
                if (program._id !== null) {
                  return (
                    <>
                      <div key={i}>
                        <p>{program?._id?.title + ","}</p>
                      </div>
                      {i == 4 && (
                        <p
                          className="mb-1 mt-1 view-more"
                          aria-describedby={id}
                          variant="contained"
                          onClick={(e) => handleClickPopUP(e, row)}
                        >
                          view more
                        </p>
                      )}
                    </>
                  );
                }
              })}
          </div>
        );
      },
    },
    {
      id: "group_type",
      label: "Type",
      renderData: (row) => {
        return show_proper_words(row.group_type);
      },
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-lg-8 col-sm-12">
            <h2>Member Groups</h2>
          </div>
          <div className=" col-12 mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={userList}
              className="card-with-background mt-3 "
              pagePagination={true}
            />
          </div>
          <MemberProgramPopover
            handleClick={handleClickPopUP}
            handleClose={handleClosePopUp}
            anchorEl={anchorEl}
            id={id}
            open={opens}
            program={program}
            navigatePage={true}
          />
        </div>
      </div>
    </>
  );
}
