import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  TextField,
  CircularProgress,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker } from "@mui/lab";
import {
  active_booking_status_api,
  bookingPageList,
  consultantSearchListApi,
} from "src/DAL/booking/bookingApi";
import { makeStyles } from "@mui/styles";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function BookingFilter({
  filterData,
  handleSelectOther,
  searchFunction,
  handleClearFilter,
  Filter_from,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [personName, setPersonName] = useState([]);
  const [serchText, setSerchText] = useState("");
  const [salePageList, setSalePageList] = useState([]);
  const [bookingStatus, setBookingStatus] = useState([]);

  const getPageListing = async () => {
    const result = await bookingPageList();
    if (result.code === 200) {
      setSalePageList(
        result.Sale_page.map((item) => {
          return {
            ...item,
            chip_label: item?.sale_page_title,
            chip_value: item._id,
          };
        })
      );
    }
  };

  const get_active_booking_status = async () => {
    const result = await active_booking_status_api();
    if (result.code === 200) {
      let status_array = result.active_booking_status.map((status) => {
        return {
          ...status,
          chip_label: status.title,
          chip_value: status._id,
        };
      });
      setBookingStatus(status_array);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    get_active_booking_status();
    getPageListing();
  }, []);

  const getSearchGroupsAndMembers = async () => {
    const result = await consultantSearchListApi(serchText);
    if (result.code === 200) {
      setPersonName(
        result.consultant_list.map((item) => {
          let full_name =
            item.first_name + " " + item.last_name + " (" + item.email + ")";
          return {
            ...item,
            full_name,
            chip_label: full_name,
            chip_value: item._id,
          };
        })
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSearchGroupsAndMembers();
  }, [serchText]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid new-memories transaction-filter">
      <div className="mt-4">
        <MUIAutocomplete
          inputLabel="Consultant"
          selectedOption={filterData.consultant_id}
          setSelectedOption={(e) => {
            handleSelectOther("consultant_id", e);
          }}
          optionsList={personName}
          setCustomSearch={setSerchText}
          name="full_name"
        />
      </div>
      <div className="col-12 mt-3">
        <MUIAutocomplete
          multiple
          inputLabel="Select Booking Pages"
          selectedOption={filterData.sale_page}
          setSelectedOption={(value) => {
            handleSelectOther("sale_page", value);
          }}
          optionsList={salePageList}
          name="sale_page_title"
        />
      </div>
      <div className="col-12 mt-3 mb-2">
        <MUIAutocomplete
          inputLabel="Booking Status"
          selectedOption={filterData.booking_status}
          setSelectedOption={(e) => {
            handleSelectOther("booking_status", e);
          }}
          optionsList={bookingStatus}
          name="title"
        />
      </div>
      {Filter_from == "history" ? (
        ""
      ) : (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterData.filter_by_dates}
                onChange={(e) => {
                  handleSelectOther("filter_by_dates", e.target.checked);
                }}
              />
            }
            label="Filter By Dates"
          />
          {filterData.filter_by_dates && (
            <>
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="From"
                    name="start_date"
                    inputFormat="dd-MM-yyyy"
                    value={filterData.start_date}
                    onChange={(e) => {
                      handleSelectOther("start_date", e);
                    }}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="To"
                    name="endDate"
                    value={filterData.end_date}
                    inputFormat="dd-MM-yyyy"
                    onChange={(e) => {
                      handleSelectOther("end_date", e);
                    }}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
        </>
      )}
      <Box sx={{ py: 2 }}>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={searchFunction}
          startIcon={
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 5 }}
            >
              <path
                d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                fill={get_root_value("--portal-theme-primary")}
              />
            </svg>
          }
        >
          Filter
        </Button>
      </Box>
      <Button
        fullWidth
        size="large"
        type="submit"
        color="inherit"
        variant="outlined"
        onClick={handleClearFilter}
        startIcon={<Icon icon={roundClearAll} />}
      >
        Clear All
      </Button>
    </div>
  );
}
