import { invokeApi } from "../../../bl_libs/invokeApi";

export const program_module_listing_api = async (slug) => {
  const requestObj = {
    path: `/api/program_module/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_program_module_api = async (data) => {
  const requestObj = {
    path: `/api/program_module`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_program_module_api = async (data, slug) => {
  const requestObj = {
    path: `/api/program_module/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const program_module_detail_api = async (slug) => {
  const requestObj = {
    path: `/api/program_module/get_program_module/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_program_module_api = async (slug) => {
  const requestObj = {
    path: `/api/program_module/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_lesson_data_api = async (slug) => {
  const requestObj = {
    path: `/api/lesson/delete_lesson_data/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const question_configuration = async (postData, slug) => {
  const requestObj = {
    path: `/api/lesson/question_configration/update/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
