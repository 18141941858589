import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  TextField,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { transactionListFilterPageRequestApi } from "src/DAL/Transaction/transactionApi";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker } from "@mui/lab";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { useSnackbar } from "notistack";

export default function DelegateFilter({
  setIsAllDates,
  isAllDates,
  handleClearFilter,
  filterData,
  searchSubmitFilter,
  handleChangeOthers,
}) {
  const [pagesData, setPagesData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getPagesRequestForFilter = async (page_type) => {
    let result = await transactionListFilterPageRequestApi(page_type);
    if (result.code == 200) {
      setPagesData(
        result.pages.map((page) => {
          let userType =
            page.action_by == "admin_user" ? "Admin " : "Delegate ";
          return {
            ...page,
            chip_label:
              page.type == "template"
                ? page?.sale_page_title + " " + `(${userType + "| "}Template)`
                : page?.sale_page_title + `(${userType})`,
            chip_value: page?.sale_page_title_slug,
            sale_page_title_type:
              page.type == "template"
                ? page?.sale_page_title + " " + `(${userType + "| "}Template) `
                : page?.sale_page_title + `(${userType})`,
          };
        })
      );
      setRequestData(
        result.payment_requests.map((item) => {
          return {
            ...item,
            chip_label: item?.request_title,
            chip_value: item?._id,
          };
        })
      );
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
    }
  };

  const handleChangePage = (value) => {
    if (value) {
      setPlansList(
        value.payment_plans.map((item) => {
          return {
            ...item,
            chip_label: item.plan_title,
            chip_value: item._id,
          };
        })
      );
      filterData.payment_plan = null;
    } else {
      setPlansList([]);
      filterData.payment_plan = null;
    }
    handleChangeOthers("sale_page", value);
  };

  useEffect(() => {
    if (filterData.sale_page) {
      if (pagesData.length > 0) {
        let find_id = pagesData.find(
          (page_id) => page_id._id == filterData.sale_page._id
        );

        if (find_id && find_id.payment_plans.length > 0) {
          setPlansList(find_id?.payment_plans);
          setPlansList(
            find_id?.payment_plans.map((item) => {
              return {
                ...item,
                chip_label: item.plan_title,
                chip_value: item._id,
              };
            })
          );
        }
      }
    }
  }, [pagesData]);

  useEffect(() => {
    getPagesRequestForFilter("all");
  }, []);

  return (
    <div className="container-fluid new-memories px-4 transaction-filter">
      <FormControl fullWidth className="mt-3">
        <InputLabel id="demo-multiple-name-label1">Transaction Type</InputLabel>
        <Select
          labelId="demo-multiple-name-label1"
          id="demo-multiple-name"
          name="transaction_type"
          value={filterData.transaction_type}
          onChange={(e) => {
            handleChangeOthers("transaction_type", e.target.value);
          }}
          input={<OutlinedInput label="Transaction Type" />}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="sale_page">Sale Page</MenuItem>
          <MenuItem value="payment_request">Payment Request</MenuItem>
        </Select>
      </FormControl>
      {filterData.transaction_type == "sale_page" && (
        <>
          <div className="col-12 mt-3">
            <MUIAutocomplete
              inputLabel="Sale Page"
              selectedOption={filterData.sale_page}
              setSelectedOption={handleChangePage}
              optionsList={pagesData}
              name="sale_page_title_type"
            />
          </div>
          <div className="col-12 mt-3">
            <MUIAutocomplete
              inputLabel="Choose Plan"
              selectedOption={filterData.payment_plan}
              setSelectedOption={(e) => {
                handleChangeOthers("payment_plan", e);
              }}
              optionsList={plansList}
              name="plan_title"
            />
          </div>
        </>
      )}
      {filterData.transaction_type == "payment_request" && (
        <div className="col-12 mt-3">
          {requestData && requestData.length > 0 ? (
            <MUIAutocomplete
              inputLabel="Payment Requests"
              selectedOption={filterData.payment_request}
              setSelectedOption={(e) =>
                handleChangeOthers("payment_request", e)
              }
              optionsList={requestData}
              autoComplete="new-password"
              name="request_title"
              multiple={true}
            />
          ) : (
            ""
          )}
        </div>
      )}
      <FormControlLabel
        className="mt-2"
        control={
          <Checkbox
            defaultChecked
            checked={isAllDates}
            onChange={(e) => setIsAllDates(e.target.checked)}
          />
        }
        label="Search By All Date"
      />
      {isAllDates == true && (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              name="start_date"
              value={filterData.start_date}
              format="YYYY-MM-DD"
              inputFormat="dd-MM-yyyy"
              onChange={(e) => handleChangeOthers("start_date", e)}
              renderInput={(params) => (
                <TextField {...params} className="mt-3 inputs-fields" />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="End Date"
              name="end_date"
              value={filterData.end_date}
              format="YYYY-MM-DD"
              inputFormat="dd-MM-yyyy"
              onChange={(e) => handleChangeOthers("end_date", e)}
              renderInput={(params) => (
                <TextField {...params} className="mt-3 inputs-fields" />
              )}
            />
          </LocalizationProvider>
        </>
      )}
      <Box sx={{ py: 2 }}>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={searchSubmitFilter}
          startIcon={
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 5 }}
            >
              <path
                d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                fill={get_root_value("--portal-theme-primary")}
              />
            </svg>
          }
        >
          Filter
        </Button>
      </Box>
      <Button
        className="mb-3"
        fullWidth
        size="large"
        type="submit"
        color="inherit"
        variant="outlined"
        onClick={handleClearFilter}
        startIcon={<Icon icon={roundClearAll} />}
      >
        Clear All
      </Button>
    </div>
  );
}
