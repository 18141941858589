import React from "react";
import {
  FormControl,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import {
  SalePageSearchListApi,
  consultantSearchListApi,
} from "src/DAL/booking/bookingApi";
import { useEffect } from "react";
import { useState } from "react";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

export default function TemplateFilter({
  filterData,
  handleChange,
  searchSubmitFilter,
  handleClearFilter,
  handleSelectOther,
  is_website_pages,
}) {
  const [serchText, setSerchText] = useState("");
  const [serchTextPage, setSerchTextPage] = useState("");
  const [personName, setPersonName] = useState([]);
  const [salePageList, setSalePageList] = useState([]);
  const getSearchGroupsAndMembers = async () => {
    const result = await consultantSearchListApi(serchText);
    if (result.code === 200) {
      setPersonName(
        result.consultant_list.map((item) => {
          let full_name =
            item.first_name + " " + item.last_name + " (" + item.email + ")";
          return {
            ...item,
            full_name,
            chip_label: full_name,
            chip_value: item._id,
          };
        })
      );
    } else {
    }
  };
  const getPageListing = async () => {
    const result = await SalePageSearchListApi(
      serchTextPage,
      filterData.type_of_page
    );
    if (result.code === 200) {
      console.log(result.sale_page, "result.Sale_pageresult.Sale_page");
      setSalePageList(
        result.sale_page.map((item) => {
          return {
            ...item,
            chip_label: item?.sale_page_title,
            chip_value: item._id,
          };
        })
      );
    }
  };
  useEffect(() => {
    if (filterData.list_by == "delegate") {
      getSearchGroupsAndMembers();
    }
  }, [serchText, filterData.list_by]);
  useEffect(() => {
    if (filterData.type_of_page !== "none") {
      getPageListing();
    }
  }, [serchTextPage, filterData.type_of_page]);
  return (
    <>
      <div className="container-fluid new-memories">
        {is_website_pages && (
          <>
            <FormControl fullWidth className="mt-3">
              <InputLabel id="demo-simple-select-label">Created By</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterData.list_by}
                name="list_by"
                label="List By"
                onChange={handleChange}
              >
                <MenuItem value="none">All</MenuItem>
                <MenuItem value="admin">Created By Admin</MenuItem>
                <MenuItem value="delegate">Created By Delegate</MenuItem>
              </Select>
            </FormControl>
            {filterData.list_by == "delegate" && (
              <div className="mt-3">
                <MUIAutocomplete
                  inputLabel="Consultant"
                  selectedOption={filterData.delegate_id}
                  setSelectedOption={(e) => {
                    handleSelectOther("delegate_id", e);
                  }}
                  optionsList={personName}
                  setCustomSearch={setSerchText}
                  name="full_name"
                />
              </div>
            )}
          </>
        )}
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-simple-select-label">Type Of Page</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterData.type_of_page}
            name="type_of_page"
            label="Type Of Page"
            onChange={handleChange}
          >
            <MenuItem value="none">All</MenuItem>
            <MenuItem value="sale_page">Sale Pages</MenuItem>
            <MenuItem value="book_a_call_page">Book Call Pages</MenuItem>
          </Select>
        </FormControl>{" "}
        {is_website_pages && filterData.type_of_page !== "none" && (
          <div className="col-12 mt-3">
            <MUIAutocomplete
              inputLabel="Select Sale Pages"
              selectedOption={filterData.template_id}
              setSelectedOption={(value) => {
                handleSelectOther("template_id", value);
              }}
              setCustomSearch={setSerchTextPage}
              optionsList={salePageList}
              name="sale_page_title"
            />
          </div>
        )}
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-simple-select-label">Payment Mode</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterData.payment_mode}
            name="payment_mode"
            label="Payment Mode"
            onChange={handleChange}
          >
            <MenuItem value="none">All</MenuItem>
            <MenuItem value="sandBox">sandBox</MenuItem>
            <MenuItem value="live">Live</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={searchSubmitFilter}
            startIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                  fill={get_root_value("--portal-theme-primary")}
                />
              </svg>
            }
          >
            Filter
          </Button>
        </Box>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          className="mb-3"
          onClick={handleClearFilter}
          startIcon={<Icon icon={roundClearAll} />}
        >
          Clear All
        </Button>
      </div>
    </>
  );
}
