import { invokeApi } from "../../bl_libs/invokeApi";

export const uploadImageOns3 = async (data) => {
  const requestObj = {
    path: `/app/update_image_on_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const uploadFileOns3 = async (data) => {
  const requestObj = {
    path: `/app/updload_file_on_s3_for_chat`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const upload_image_for_editor = async (data) => {
  const requestObj = {
    path: `/app/upload_image/for_editor`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_image_on_s3 = async (data) => {
  const requestObj = {
    path: `/app/delete_image_on_s3_for_chat`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteCommonApiForImageAudio = async (data) => {
  const requestObj = {
    path: `/api/delegate_recording/delete_recording/audio`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const uploadSupportImageOns3 = async (data) => {
  const requestObj = {
    path: `/api/support_ticket/upload_support_ticket_comment_images`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
