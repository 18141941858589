import { invokeApi } from "../../bl_libs/invokeApi";

export const addNinetyDayQuestionApi = async (data) => {
  const requestObj = {
    path: `/api/ninteen_day_vision_questions/add_question`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const getNinetyDayQuestionApi = async (data) => {
  const requestObj = {
    path: `/api/ninteen_day_vision_questions/get_question`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getNinetyDayQuestionApiother = async (id) => {
  const requestObj = {
    path: `/api/delegate_earning/delegate_nineteen_days/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_ninety_day_content_settings_api = async () => {
  const requestObj = {
    path: `/api/content_setting/get_ninety_day_content_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_ninety_day_content_setting_api = async (data) => {
  const requestObj = {
    path: `/api/content_setting/update_ninety_day_content_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
