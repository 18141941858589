import * as React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";

import RecordNotFound from "src/components/RecordNotFound";
import { countries } from "src/utils/country";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import countryList from "react-select-country-list";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  add_order_api,
  add_order_api_mintsoft,
  calculate_custom_duty_api,
  dynamite_products_list_api,
} from "src/DAL/order/order";
import { useState } from "react";
import { s3baseUrl } from "src/config/config";
import { convertCurrencyToSign } from "src/utils/constant";
import { tax_rate_and_stripe_readers_api } from "src/DAL/Tax/TaxApi";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import PhoneInput from "react-phone-input-2";
import { memberInCalendarGroupsListApi } from "src/DAL/member/member";
import GPlacesAutocomplete from "src/components/GeneralComponents/GeneralQuestions/GPlacesAutocomplete";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const get_user_name = (member) => {
  return member.first_name + " " + member.last_name + " (" + member.email + ")";
};

const default_country = {
  code: "GB",
  label: "United Kingdom",
  phone: "44",
};

export default function AddOrder() {
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFirst, setIsLoadingFirst] = useState(true);
  const [productsList, setProductsList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [taxRates, setTaxRates] = useState([]);
  const [selectedTaxRates, setSelectedTaxRates] = useState([]);
  const [readersList, setReadersList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [searchText, setSearchText] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedReader, setSelectedReader] = useState(null);
  const [defaultMember, setDefaultMember] = useState(null);
  const [country, setCountry] = useState(default_country);
  const [sameShipping, setSameShipping] = useState(true);
  const options = useMemo(() => countryList().getData(), []);
  const [isCalculating, setIsCalculating] = useState(false);
  const [customDutyTax, setCustomDutyTax] = useState(0);
  const navigate = useNavigate();
  const [phoneData, setPhoneData] = useState();
  const textFieldRef = React.useRef(null);

  const EMPTY_VALUES = {
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    selected_tax: null,
    user_type: "",
    payment_method: "",
    selected_reader: selectedReader,
    zip_code: "",
    street: "",
    city: "",
    country: null,
    state: "",
    currency: "",
    is_create_mint_soft_order: false,
    is_create_transaction: false,
  };

  const [billingObject, setBillingObject] = useState({
    street: "",
    city: "",
    state: "",
    zip_code: "",
    country: null,
  });

  const [inputs, setInputs] = useState(EMPTY_VALUES);

  const handleRemoveValues = () => {
    setInputs(EMPTY_VALUES);
    setSelectedProducts([]);
    setSelectedMember(defaultMember);
    setCountry(default_country);
  };

  const handleRemove = (itemId) => {
    setSelectedProducts((data) => data.filter((item) => item._id !== itemId));
  };

  const get_dynamite_products_list = async () => {
    let postData = {
      search_text: searchQuery,
    };
    const result = await dynamite_products_list_api(postData);
    if (result.code == 200) {
      setProductsList(result.product);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_list_text_rates = async () => {
    const result = await tax_rate_and_stripe_readers_api();
    if (result.code == 200) {
      setTaxRates(result.data);
      setReadersList(result.stripe_readers);

      if (result.assigned_stripe_readers.length > 0) {
        const idSet = new Set(
          result.assigned_stripe_readers.map((obj) => obj._id)
        );
        const find_reader = result.stripe_readers.find((obj) =>
          idSet.has(obj._id)
        );
        if (find_reader) {
          setSelectedReader(find_reader);
          setInputs((old) => {
            return { ...old, selected_reader: find_reader };
          });
        }
      }

      if (inputs.country) {
        let find_tax = result.data.filter(
          (tax) => tax.country == inputs.country?.label && tax.status == true
        );
        setSelectedTaxRates(find_tax);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const hanldeAddProduct = (value) => {
    let new_obj = {
      ...value,
      selected_quantity: 1,
    };
    setSelectedProducts((old) => [new_obj, ...old]);
    get_dynamite_products_list();
    setSearchQuery("");
    if (textFieldRef.current) {
      textFieldRef.current.blur();
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSearchQuery(value);
  };

  const handleSearchUser = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSearchText(value);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleIncrease = (item) => {
    setSelectedProducts((old) =>
      old.map((product) => {
        if (product._id == item._id) {
          return {
            ...product,
            selected_quantity: +product.selected_quantity + 1,
          };
        } else {
          return product;
        }
      })
    );
  };

  const handleDecrease = (item) => {
    if (item.selected_quantity > 1) {
      setSelectedProducts((old) =>
        old.map((product) => {
          if (product._id == item._id) {
            return {
              ...product,
              selected_quantity: +product.selected_quantity - 1,
            };
          } else {
            return product;
          }
        })
      );
    }
  };

  const handleChangeQuantity = (event, item) => {
    let value = event.target.value;
    if (value >= 1) {
      setSelectedProducts((old) =>
        old.map((product) => {
          if (product._id == item._id) {
            return {
              ...product,
              selected_quantity: value,
            };
          } else {
            return product;
          }
        })
      );
    }
  };

  const handleChangeCountry = (value) => {
    let find_tax = taxRates.filter(
      (tax) => tax.country == value?.label && tax.status == true
    );
    setCountry(value);
    setSelectedTaxRates(find_tax);
    setInputs((values) => ({ ...values, country: value, selected_tax: null }));
  };

  const get_search_members = async () => {
    const result = await memberInCalendarGroupsListApi(searchText);
    if (result.code === 200) {
      let members = result.members.map((member) => {
        return {
          ...member,
          user_name: get_user_name(member),
        };
      });
      setMembersList(members);
      setIsLoading(false);
      setIsLoadingFirst(false);
    } else {
      setIsLoading(false);
    }
  };

  const get_prodcut_options = (list, selected) => {
    let data_list = [];
    let all_list = [...list];
    all_list.map((item) => {
      let find = selected.find((selected) => selected._id == item._id);
      if (!find) {
        return data_list.push(item);
      }
    });
    return data_list;
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    for (const product of selectedProducts) {
      const price = product.price || 0;
      const selectedQuantity = product.selected_quantity || 0;
      const productTotal = price * selectedQuantity;
      totalAmount += productTotal;
    }
    return totalAmount.toFixed(2);
  };

  const totalAmount = +calculateTotalAmount();
  let tax_percentage = 0;
  if (inputs.selected_tax) {
    tax_percentage = inputs.selected_tax.percentage;
  }

  const taxRate = tax_percentage / 100;
  const taxAmount = totalAmount * taxRate;
  const overallTotal = totalAmount + taxAmount;

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    let selected_products = selectedProducts.map((product) => {
      return {
        product_id: product._id,
        price: product.price.toFixed(2),
        quantity: product.selected_quantity,
      };
    });
    let shipping_info = {
      street: billingObject.street,
      city: billingObject.city,
      zip_code: billingObject.zip_code,
      state: billingObject.state,
      country: billingObject.country?.value,
    };
    let billing_info = {
      street: inputs.street,
      city: inputs.city,
      zip_code: inputs.zip_code,
      state: inputs.state,
      country: inputs.country.value,
    };
    let postData = {
      user_id: selectedMember._id,
      shipping_charges: customDutyTax,
      currency: "gbp",
      is_create_transaction: inputs.is_create_transaction,
      is_create_mint_soft_order: inputs.is_create_mint_soft_order,
      tax_rate_id: inputs.selected_tax?._id,
      products: selected_products,
      billing_object: {
        ...billing_info,
        first_name: inputs.first_name,
        last_name: inputs.last_name,
        email: inputs.email,
        contact_number: inputs.contact_number,
      },
      shipping_object: sameShipping ? billing_info : shipping_info,
    };

    const result = await add_order_api_mintsoft(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleRemoveValues();
      navigate(-1);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const hanldeSelectedMember = (value) => {
    let find_country = options.find(
      (c) => c.value == value.country || c.label == value.country
    );
    let find_tax = taxRates.filter(
      (tax) => tax.country == find_country?.label && tax.status == true
    );
    setSelectedTaxRates(find_tax);
    setSelectedMember(value);
    setSearchText("");
  };

  const handleShipping = (place) => {
    const address = handle_format_address(place);
    let postData = {
      shipping_object: {
        state: address.state,
        country: address.country?.value,
      },
    };
    calculate_custom_duty(postData);
    setBillingObject({ ...billingObject, ...address });
  };

  // ------------------------------------------------------------------
  const calculate_custom_duty = async (data) => {
    setIsCalculating(true);
    let postData = { ...data };
    postData.currency = "gbp";
    let selected_products = selectedProducts.map((product) => {
      return {
        product_id: product._id,
        price: product.price.toFixed(2),
        quantity: product.selected_quantity,
      };
    });
    postData.products = selected_products;
    // if (order_id) {
    //   postData.order = order_id;
    // }
    const result = await calculate_custom_duty_api(postData);
    if (result.code === 200) {
      setCustomDutyTax(result.total_custom_duty);
      setIsCalculating(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsCalculating(false);
    }
  };
  const handle_format_address = (place) => {
    let city = "";
    let country = null;
    let state = "";
    let street2 = "";
    let zip_code = "";

    if (place.city) {
      city = place.city;
    }
    if (place.country) {
      country = options.find(
        (c) => c.value == place.country || c.label == place.country
      );
    }
    if (place.state) {
      state = place.state;
    }
    if (place.street2) {
      street2 = place.street2;
    }
    if (place.zip_code) {
      zip_code = place.zip_code;
    }

    return {
      city: city,
      country: country,
      state: state,
      street2: street2,
      zip_code: zip_code,
      street: place.formated_address,
    };
  };

  const handlePlaceSelect = (place) => {
    const address = handle_format_address(place);
    let find_tax = taxRates.filter(
      (tax) => tax.country == address.country?.label && tax.status == true
    );
    setSelectedTaxRates(find_tax);

    let postData = {
      shipping_object: {
        state: address.state,
        country: address.country?.value,
      },
    };

    if (sameShipping) {
      calculate_custom_duty(postData);
    }
    setInputs({ ...inputs, ...address, selected_tax: null });
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleCheckBox = (type) => {
    if (type == "same") {
      setSameShipping(true);
    } else {
      setSameShipping(false);
    }
  };

  const handleChangeShippingStreet = (value) => {
    setBillingObject({ ...billingObject, street: value });
  };

  const handleChangeBilling = (e) => {
    const { name, value } = e.target;
    setBillingObject({ ...billingObject, [name]: value });
  };
  const handleChangeBillingCountry = (value) => {
    if (value) {
      let postData = {
        shipping_object: {
          state: billingObject.state,
          country: value.value,
        },
      };
      calculate_custom_duty(postData);
    } else {
      setCustomDutyTax(0);
    }
    setBillingObject((values) => ({ ...values, country: value }));
  };
  const handleChangeData = (event, valueName) => {
    const value = event.target.checked;
    setInputs((values) => ({ ...values, [valueName]: value }));
  };

  const handleChangePhone = (value, selected) => {
    setInputs((values) => ({
      ...values,
      contact_number: value,
      country: selected.countryCode.toUpperCase(),
    }));
    if (!country) {
      setCountry({
        code: selected.countryCode.toUpperCase(),
        label: selected.name,
      });
    }
  };
  // --------------------------------------------------------------------------

  useEffect(() => {
    get_dynamite_products_list();
  }, [searchQuery]);

  useEffect(() => {
    get_search_members();
  }, [searchText]);

  useEffect(() => {
    get_list_text_rates();
  }, []);

  useEffect(() => {
    setSelectedMember(defaultMember);
  }, [inputs.user_type]);
  useEffect(() => {
    if (!!selectedMember) {
      let memberCountry = null;
      if (selectedMember.country) {
        memberCountry = options.find(
          (c) =>
            c.value == selectedMember.country ||
            c.label == selectedMember.country
        );
      }
      if (selectedMember?.contact_number) {
        setPhoneData(selectedMember?.contact_number);
      }
      setInputs({
        ...inputs,
        ["first_name"]: selectedMember?.first_name,
        ["last_name"]: selectedMember?.last_name,
        ["email"]: selectedMember?.email,
        ["contact_number"]: selectedMember?.contact_number,
        ["zip_code"]: selectedMember?.zip_code,
        ["street"]: selectedMember?.street,
        ["state"]: selectedMember?.state,
        ["city"]: selectedMember?.city,
        ["country"]: memberCountry,
      });
    } else {
      setInputs({
        ...inputs,
        ["first_name"]: "",
        ["last_name"]: "",
        ["email"]: "",
        ["contact_number"]: "",
        ["zip_code"]: "",
        ["street"]: "",
        ["state"]: "",
        ["city"]: "",
        ["country"]: null,
      });
    }
  }, [selectedMember]);
  useEffect(() => {
    if (
      selectedMember &&
      inputs.state &&
      inputs?.country &&
      selectedProducts.length > 0 &&
      sameShipping
    ) {
      let postData = {
        shipping_object: {
          state: inputs.state,
          country: inputs.country.value,
        },
      };
      console.log("from member");
      calculate_custom_duty(postData);
    }
  }, [
    selectedMember,
    selectedProducts,
    inputs.country,
    inputs.state,
    sameShipping,
  ]);
  useEffect(() => {
    if (
      selectedMember &&
      billingObject.state &&
      billingObject?.country &&
      selectedProducts.length > 0 &&
      !sameShipping
    ) {
      let postData = {
        shipping_object: {
          state: billingObject.state,
          country: billingObject.country.value,
        },
      };
      console.log("from member billing object");
      calculate_custom_duty(postData);
    }
  }, [
    selectedMember,
    selectedProducts,
    billingObject.country,
    billingObject.state,
    sameShipping,
  ]);

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-1">Create Order</h2>
        </div>
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-12 ">
          <div className="modal-theme p-3" style={{ height: "100%" }}>
            <h3 className="mb-4 address-heading">Order Summary</h3>
            <div className="row">
              <div className="col-12 chat-search-box mb-3">
                <Autocomplete
                  id="tags-outlined"
                  options={get_prodcut_options(productsList, selectedProducts)}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  value={null}
                  onChange={(event, newValue) => {
                    hanldeAddProduct(newValue);
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Products"
                      placeholder="Search Products"
                      value={searchText}
                      onChange={handleSearch}
                      inputRef={textFieldRef}
                    />
                  )}
                />
              </div>
            </div>
            <div
              className="row product-container"
              style={{
                height: sameShipping ? "313px" : "554px",
                maxHeight: sameShipping ? "313px" : "554px",
                display: "block",
              }}
            >
              {selectedProducts.length > 0 ? (
                selectedProducts.map((item, index) => {
                  let image = s3baseUrl + item.image[0].image;

                  return (
                    <div className="col-12">
                      <div className="row">
                        <div className="col-2 imgDetail">
                          <div>
                            <img
                              src={image}
                              alt={item.name}
                              className="detail-img"
                            />
                          </div>
                        </div>
                        <div className="col-9 orderdes">
                          <h4>{item.name}</h4>
                          <p>
                            {convertCurrencyToSign(item.currency) +
                              item.price.toFixed(2)}
                          </p>
                        </div>
                        <div className="col-1 position-relative">
                          <span
                            className="icon-container-card icon-postion"
                            onClick={() => handleRemove(item._id)}
                          >
                            x
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8">
                          <div className="item-quantity ms-auto">
                            <span
                              className={`cursor-pointer px-2 ${
                                item.selected_quantity <= 1
                                  ? "item-quantity-diabled"
                                  : ""
                              }`}
                              onClick={() => {
                                handleDecrease(item);
                              }}
                            >
                              -
                            </span>
                            <input
                              type="number"
                              min={1}
                              value={item.selected_quantity}
                              style={{
                                width: `${
                                  25 + String(item.selected_quantity).length * 5
                                }px`,
                              }}
                              onChange={(e) => {
                                handleChangeQuantity(e, item);
                              }}
                            />
                            <span
                              className="cursor-pointer px-2"
                              onClick={() => {
                                handleIncrease(item);
                              }}
                            >
                              +
                            </span>
                          </div>
                        </div>
                        <div className="col-4 text-end">
                          <p className="mb-0">
                            {`${convertCurrencyToSign(item.currency)}${(
                              item.price * item.selected_quantity
                            ).toFixed(2)}`}
                          </p>
                        </div>
                      </div>
                      <hr></hr>
                    </div>
                  );
                })
              ) : (
                <RecordNotFound title="Products" />
              )}
            </div>
            {selectedProducts.length > 0 && (
              <div className="PriceBox mt-2">
                <div className="d-flex justify-content-between mt-3">
                  <p>Subtotal</p>
                  <span>£{`${totalAmount.toFixed(2)}`}</span>
                </div>
                <div className="d-flex justify-content-between">
                  {inputs.selected_tax && (
                    <>
                      <p>
                        {inputs.selected_tax.tax_type_title +
                          " (" +
                          inputs.selected_tax.percentage +
                          "%)"}
                      </p>
                      <span>£{`${taxAmount.toFixed(2)}`}</span>
                    </>
                  )}
                </div>
                {/* <hr></hr>{" "} */}
                <div className="d-flex justify-content-between ">
                  <p>Shipping Fee</p>
                  <span>
                    {" "}
                    <>
                      {isCalculating ? (
                        <CircularProgress className="calculating-custom-duty" />
                      ) : (
                        <>£{customDutyTax?.toFixed(2)}</>
                      )}
                    </>
                  </span>
                </div>
                <hr></hr>
                <div className="d-flex justify-content-between ">
                  <p className="finalPrice"> Grand Total</p>
                  <span style={{ color: "white" }}>
                    £{`${(customDutyTax + overallTotal).toFixed(2)}`}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="modal-theme p-3 card">
            <h3 className="address-heading ">Personal Information</h3>
            <div className="shop-modal-box shop-modal-box-cart">
              <>
                <div className="row mb-4">
                  {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                    <FormControl fullWidth required size="small">
                      <InputLabel required id="demo-simple-select-label">
                        User Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="user_type"
                        size="small"
                        value={inputs.user_type}
                        label="User Type"
                        onChange={handleChange}
                      >
                        <MenuItem value="existing">Existing User</MenuItem>
                        <MenuItem value="new">New User</MenuItem>
                      </Select>
                    </FormControl>
                  </div> */}
                  <div className="col-md-6 col-sm-6 mt-3">
                    <Autocomplete
                      id="tags-outlined"
                      options={membersList}
                      getOptionLabel={(option) => option.user_name}
                      filterSelectedOptions
                      value={selectedMember}
                      onChange={(event, newValue) => {
                        hanldeSelectedMember(newValue);
                      }}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Member"
                          placeholder="Member"
                          onChange={handleSearchUser}
                          required
                        />
                      )}
                    />
                  </div>
                  <>
                    {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        name="first_name"
                        value={inputs.first_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        required
                        size="small"
                        name="last_name"
                        value={inputs.last_name}
                        onChange={handleChange}
                      />
                    </div>{" "}
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        size="small"
                        type="email"
                        fullWidth
                        required
                        name="email"
                        value={inputs.email}
                        onChange={handleChange}
                      />
                    </div> */}
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <PhoneInput
                        country={"gb"}
                        inputClass="react-phone-input"
                        dropdownClass="flag-input"
                        inputProps={{
                          name: "contact_number",
                          required: true,
                          autoComplete: "off",
                        }}
                        value={phoneData}
                        enableSearch
                        disableSearchIcon
                        countryCodeEditable={false}
                        buttonStyle={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                        placeholder="Phone Number: *"
                        onChange={(value) => {
                          handleChangeOthers("contact_number", value);
                        }}
                      />
                    </div>
                  </>
                  {/* <div className="col-lg-6 col-md-12 col-sm-12 mt-4 ">
                    <Autocomplete
                      id="country-select-demo"
                      options={countries}
                      autoHighlight
                      value={country}
                      onChange={(event, newValue) => {
                        handleChangeCountry(newValue);
                      }}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label} ({option.code}) +{option.phone}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose a country"
                          size="small"
                          name="country"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div> */}
                </div>
              </>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-6 col-sm-12">
                <GPlacesAutocomplete
                  onSelect={handlePlaceSelect}
                  street={inputs.street}
                  // disabled={isOrderPlaced}
                  handleChaAddress={(value) => {
                    handleChangeOthers("street", value);
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="City"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  name="city"
                  value={inputs?.city}
                  onChange={handleChange}
                />
              </div>{" "}
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Zip Code"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  name="zip_code"
                  value={inputs?.zip_code}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="State"
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  name="state"
                  value={inputs?.state}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 mt-4 ">
                <MUIAutocomplete
                  inputLabel="Country"
                  selectedOption={inputs.country}
                  setSelectedOption={handleChangeCountry}
                  optionsList={options}
                  autoComplete="new-password"
                  name="label"
                  size="small"
                  required={true}
                />
              </div>
              <div className="col-12 mt-4">
                <MUIAutocomplete
                  inputLabel="Tax Type"
                  selectedOption={inputs.selected_tax}
                  setSelectedOption={(value) => {
                    handleChangeOthers("selected_tax", value);
                  }}
                  optionsList={selectedTaxRates}
                  name="tax_type_title"
                  size="small"
                  autoComplete="new-password"
                />
              </div>
            </div>
            <h3 className="pt-3 address-heading ">Shipping Detail</h3>
            <div className="col-12 mt-3">
              <div className="d-flex flex-column flex-md-row">
                <div
                  className="d-flex me-3 cursor-pointer"
                  onClick={() => handleCheckBox("same")}
                >
                  <div className="custome-radio-box me-2">
                    {sameShipping && <div className="selected"></div>}
                  </div>
                  <div className={`custome-radio-label `}>
                    Same as Billing Detail
                  </div>
                </div>
                <div
                  className="d-flex cursor-pointer mt-2 mt-md-0"
                  onClick={() => handleCheckBox("friend")}
                >
                  <div className="custome-radio-box me-2">
                    {!sameShipping && <div className="selected"></div>}
                  </div>
                  <div className={`custome-radio-label `}>
                    Different Shipping Address
                  </div>
                </div>
              </div>
              {!sameShipping && (
                <div className="row">
                  <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
                    <GPlacesAutocomplete
                      onSelect={handleShipping}
                      street={billingObject.street}
                      handleChaAddress={handleChangeShippingStreet}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="City"
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      name="city"
                      value={billingObject?.city}
                      onChange={handleChangeBilling}
                    />
                  </div>{" "}
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Zip Code"
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      name="zip_code"
                      value={billingObject?.zip_code}
                      onChange={handleChangeBilling}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="State"
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      name="state"
                      value={billingObject?.state}
                      onChange={handleChangeBilling}
                    />
                  </div>
                  <div className="col-12 mt-4 ">
                    <MUIAutocomplete
                      inputLabel="Country"
                      selectedOption={billingObject.country}
                      setSelectedOption={handleChangeBillingCountry}
                      optionsList={options}
                      autoComplete="new-password"
                      name="label"
                      size="small"
                      required={true}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              style={{ marginLeft: "3px", marginTop: "1px", color: "white" }}
            >
              <FormControlLabel
                className="mt-2"
                control={
                  <Checkbox
                    checked={inputs.is_create_mint_soft_order}
                    onChange={(e) =>
                      handleChangeData(e, "is_create_mint_soft_order")
                    }
                    name="change_for_members"
                  />
                }
                label="Would you like to place an order on Mintsoft?"
              />
            </div>{" "}
            <div
              style={{ marginLeft: "3px", marginTop: "-16px", color: "white" }}
            >
              <FormControlLabel
                className="mt-2"
                control={
                  <Checkbox
                    checked={inputs.is_create_transaction}
                    onChange={(e) =>
                      handleChangeData(e, "is_create_transaction")
                    }
                    name="change_for_members"
                  />
                }
                label="Would you like to add a transaction? "
              />
            </div>
            <div className="subtotal text-center pb-3 mt-2">
              <button
                style={{ width: "100%" }}
                className="small-contained-button  add-to-cart-button mt-3"
              >
                {isLoading ? (
                  "Submitting..."
                ) : (
                  <>
                    <ShoppingCartIcon />
                    &nbsp; Submit
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
