import * as React from "react";
import { DeleteCommentApi } from "src/DAL/member/member";
import { useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { CircularProgress } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { AnswerHistory } from "src/pages/Members/AnswerHistory";
import GoalGallerySlider from "src/pages/Members/GoalGallerySlider";
import { useRef } from "react";
import { useEffect } from "react";
import { QuestionsReply } from "./QuestionsReply";
import AddKimsReply from "./AddKimReply";
import RecordNotFound from "src/components/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const MemberGoalstatementList = ({ tabData, setTabData }) => {
  const params = useParams();
  const classes = useStyles();
  const titleRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [gallery, setGallery] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [replies, setReplies] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openAnswerHistory, setOpenAnswerHistory] = useState(false);
  const [openReply, setOpenReply] = useState(false);
  const [answerHistoryData, setAnswerHistoryData] = useState();
  const [replyData, setReplyData] = useState();
  const [expanded, setExpanded] = useState(0);
  const [userInfo, setUserInfo] = useState("");

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClick = () => {
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleOpenAnswerDrawer = (value) => {
    setAnswerHistoryData(value);
    setOpenAnswerHistory(true);
  };

  const handleCloseAnswerDrawer = () => {
    setOpenAnswerHistory(false);
  };
  const handleOpenReplyDrawer = (value) => {
    setReplyData(value);
    setOpenReply(true);
  };
  const handleCloseReplyDrawer = () => {
    setOpenReply(false);
  };

  const detailQuestionHistory = () => {
    setIsLoading(true);
    setQuestions(tabData?.all_question_array);
    setReplies(tabData?.reply);
    let galleryArray = [];
    let galleryObject = {};
    tabData?.goal_statement_gallery?.map((gallery) => {
      galleryObject = {
        original: s3baseUrl + gallery.thumbnail_1,
        thumbnail: s3baseUrl + gallery.thumbnail_2,
      };
      galleryArray.push(galleryObject);
    });
    setGallery(galleryArray);
    setIsLoading(false);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteCommentApi(deleteDoc._id);
    if (result.code === 200) {
      setReplies((replies) => {
        return replies.filter((reply) => reply._id !== deleteDoc._id);
      });
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    detailQuestionHistory();
  }, [tabData]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="row mt-3">
        <div className="col-12 text-end">
          <a
            className="small-contained-button float-end mt-1 anchor-style"
            onClick={handleClick}
          >
            {"Dynamite's" + " Reply"}
          </a>
        </div>
        <div className="col-12 mt-3 mb-5 ">
          {
            <>
              {gallery.length == 0 ? (
                <RecordNotFound heading="Member has not added any goal statement gallery yet" />
              ) : (
                <GoalGallerySlider data={gallery} />
              )}
            </>
          }
        </div>
        {questions?.map((question, index) => {
          return (
            <div className="col-12 mb-1" key={index}>
              <Accordion
                expanded={expanded === index}
                onChange={handleChangeAccordion(index)}
                className="question-background"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="goal-statement-question">
                    {question.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="">
                      <div className="row">
                        <div className="col-12 mb-4 text-muted">
                          {question.answer == "" ? "" : question.answer}
                        </div>
                        <div className="col-6 mb-3 text-muted text-start">
                          <button
                            className="small-contained-button"
                            onClick={() => {
                              handleOpenAnswerDrawer(question);
                            }}
                          >
                            Answer History
                          </button>
                        </div>
                        <div className="col-6 mb-3 text-muted text-end">
                          <button
                            className="small-contained-button me-auto"
                            onClick={() => handleOpenReplyDrawer(question)}
                          >
                            Reply {" (" + question?.comment?.length + ")"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <h4 ref={titleRef} className="mb-4">
            {replies?.length == 0 ? "" : userInfo && userInfo + "'s" + " Reply"}
          </h4>
        </div>
        {replies?.map((reply) => {
          return (
            <>
              <div className="col-12 mb-3 d-flex">
                <CircleIcon />
                <div
                  className="ms-2"
                  dangerouslySetInnerHTML={{
                    __html: reply.message,
                  }}
                ></div>
              </div>
              <div className="col-12 mb-1">
                {reply?.audio_file && (
                  <audio
                    className="w-100"
                    src={s3baseUrl + reply?.audio_file}
                    controls
                  />
                )}
              </div>
              <div className="col-6 mb-5 text-muted">
                {moment(reply.createdAt).format("DD-MM-YYYY")}
              </div>
              <div className="col-6 text-end">
                <button
                  className="small-contained-button"
                  onClick={() => handleAgreeDelete(reply)}
                >
                  Delete
                </button>
              </div>
            </>
          );
        })}
      </div>
      <AddKimsReply setReplies={setReplies} />
      <CustomDrawer
        isOpenDrawer={openAnswerHistory}
        onOpenDrawer={handleOpenAnswerDrawer}
        onCloseDrawer={handleCloseAnswerDrawer}
        pageTitle={`Answer History`}
        componentToPassDown={
          <AnswerHistory
            data={answerHistoryData}
            member_id={params.id}
            detailQuestionHistory={detailQuestionHistory}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={openReply}
        onOpenDrawer={handleOpenReplyDrawer}
        onCloseDrawer={handleCloseReplyDrawer}
        pageTitle={`Replies`}
        componentToPassDown={
          <QuestionsReply
            data={replyData}
            detailQuestionHistory={detailQuestionHistory}
            onCloseDrawer={handleCloseReplyDrawer}
            setTabData={setTabData}
          />
        }
      />
    </div>
  );
};

export default MemberGoalstatementList;
