import { invokeApi } from "../../bl_libs/invokeApi";

// export const meditationsListing = async (data) => {
//   const requestObj = {
//     path: `/api/meditation_category/list_meditation_category_active`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };
export const list_all_mediatation_category_api = async () => {
  const requestObj = {
    path: `/api/mentorship_category/list_all_mediatation_category`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const active_list_all_mediatation_category_api = async () => {
  const requestObj = {
    path: `/api/mentorship_category/list_mentorship_category_active`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
// export const meditationCategoryActiveListing = async (data) => {
//   const requestObj = {
//     path: `/api/meditation_category/list_meditation_category_active`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };

export const mentorship_category_detail_api = async (id) => {
  const requestObj = {
    path: `/api/mentorship_category/mentorship_category_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const mentorship_video_detail_api = async (id) => {
  const requestObj = {
    path: `/api/mentorship_video/mentorship_video_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_mentorship_category_api = async (data) => {
  const requestObj = {
    path: `/api/mentorship_category/add_mentorship_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_mentorship_category_api = async (slug) => {
  const requestObj = {
    path: `/api/mentorship_category/delete_mentorship_category/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_mentorship_category_api = async (data, slug) => {
  const requestObj = {
    path: `/api/mentorship_category/update_mentorship_category/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const all_mentorship_video_list_api = async (data) => {
  const requestObj = {
    path: `/api/mentorship_video/list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_mentorship_video_api = async (data) => {
  const requestObj = {
    path: `/api/mentorship_video/add_mentorship_video`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const mentorship_video_update_api = async (data, slug) => {
  const requestObj = {
    path: `/api/mentorship_video/mentorship_video_update/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_mentorship_video_api = async (slug) => {
  const requestObj = {
    path: `/api/mentorship_video/delete_mentorship_video/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
