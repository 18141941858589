import { Icon } from "@iconify/react";
import appleFilled from "@iconify/icons-ant-design/apple-filled";
// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import { get_root_value } from "src/utils/domUtils";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(2, 0),
  color: get_root_value("--input-text-color"),
  backgroundColor: get_root_value("--sidebars-background-color"),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.info.dark,
    0
  )} 0%, ${alpha(theme.palette.info.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

const TOTAL = 131;

export default function AppNewUsers({ members, currency, title }) {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <AssignmentIcon />
      </IconWrapperStyle>
      <Typography variant="h3">
        {currency === false ? members : members}
      </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title ? title : "Members"}
      </Typography>
    </RootStyle>
  );
}
