import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { google_analytics_report_country } from "src/DAL/Analytics/Analytics";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const formatDuration = (seconds) => {
  if (!seconds || seconds <= 0) {
    return `0m 0s`;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}m ${remainingSeconds}s`;
};

const FunnelStepEvent = ({ data }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);

  let currentDate = new Date();
  let startDate = new Date();
  startDate.setMonth(currentDate.getMonth() - 1);

  const getCategoriesList = async (text) => {
    let postData = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(currentDate).format("YYYY-MM-DD"),
      dimensions: ["eventName"],
      metrics: [
        "newUsers",
        "totalUsers",
        "engagedSessions",
        "engagementRate",
        "averageSessionDuration",
      ],
      search: text,
    };
    setIsLoading(true);
    const result = await google_analytics_report_country(postData);
    if (result.code == 200) {
      setUserList(result?.report);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "eventName",
      label: "Event Name",
      renderData: (row) => {
        return <div>{show_proper_words(row.eventName)}</div>;
      },
    },
    { id: "totalUsers", label: "Total User" },
    { id: "newUsers", label: "New User" },
    { id: "engagedSessions", label: "Engaged Sessions" },
    {
      id: "engagementRate",
      label: "Engagement Rate",
      renderData: (row) => {
        const formattedRate = Number(row.engagementRate).toFixed(2);
        return <>{formattedRate}%</>;
      },
    },
    {
      id: "averageSessionDuration",
      label: "Average Engagement Time",
      renderData: (row) => {
        return formatDuration(row?.averageSessionDuration);
      },
    },
  ];

  useEffect(() => {
    getCategoriesList(data);
  }, [data]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="p-3">
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={userList}
        className="card-with-background"
        is_hide={true}
      />
    </div>
  );
};

export default FunnelStepEvent;
