import React, { useState } from "react";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import ManageNavItemsAccessNew from "../NavItems/ManageNavItemsAccessNew";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

export default function MemberNavItems() {
  const [moduleData, setModuleData] = useState({});
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS_OPTIONS = [
    {
      title: "Nav Items",
      component: (
        <ManageNavItemsAccessNew type="member" setModuleData={setModuleData} />
      ),
    },
    {
      title: "Excluded Nav Items",
      component: (
        <ManageNavItemsAccessNew
          type="member_excluded"
          setModuleData={setModuleData}
        />
      ),
    },
  ];

  let breadCrumbMenu = [
    {
      title: "Members",
      navigation: "/member",
      active: false,
    },
    {
      title:
        moduleData?.first_name +
        " " +
        moduleData?.last_name +
        " (" +
        moduleData?.email +
        ")",
      active: true,
    },
  ];

  return (
    <div className="container-fluid">
      {moduleData?.first_name && (
        <div className="row">
          <div className="col-12 mb-3">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
        </div>
      )}
      <div className="col-12 mt-3">
        <h2>Manage Nav Items Access</h2>
      </div>
      <div className="mt-3">
        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
    </div>
  );
}
