import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { addChallengeCoinConfigurationApi } from "src/DAL/challenges/challenges";
import { useSnackbar } from "notistack";

const CoinConfiguration = () => {
  const [addArray, setaddArray] = useState([{ reward: "", coins: "" }]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const { state, search } = useLocation();
  const type = new URLSearchParams(search).get("type");

  const handleAdd = () => {
    const addedElement = [...addArray, { reward: "", coins: "" }];
    setaddArray(addedElement);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };
  const challeneInfo = async () => {
    const result = await challengesDetailApi(params.id);
    if (result.code == 200) {
      if (result?.challenge_coin_reward.length > 0) {
        setaddArray(result?.challenge_coin_reward);
      }
      setTitle(result.title);
    }
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index][name] = value;
    setaddArray(list);
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    let postData = {
      challenge_coin_reward: addArray,
    };
    // console.log(postData, "challenge_coin_reward");
    const result = await addChallengeCoinConfigurationApi(postData, params.id);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (state) {
      if (state.challenge_coin_reward.length > 0) {
        setaddArray(state.challenge_coin_reward);
      }
      setTitle(state.title);
    } else {
      challeneInfo();
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 d-flex">
          <IconButton
            className="back-screen-button"
            onClick={() =>
              navigate(`/challenge?type=${type}`, {
                state: state,
              })
            }
          >
            <ArrowBackIcon />
          </IconButton>
          <p className="pt-2">{title ? title : ""}</p>
        </div>
        <div className="row">
          <div className="col-6 mb-3">
            <h2>Coin Configuration</h2>
          </div>
        </div>

        <>
          {addArray?.map((data, index) => {
            // console.log(data, "data");
            return (
              <div className="d-flex mb-3">
                <div className="col-lg-5 me-2">
                  <TextField
                    id="outlined-basic"
                    label="Reward for Upto "
                    variant="outlined"
                    name="reward"
                    fullWidth
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: { min: 0, max: 100 },
                    }}
                    // InputProps={{ inputProps: { min: 0, max: 100 } }}
                    value={data.reward}
                    onChange={(e) => handleChange(e, index)}
                    sx={{ background: "#141717", borderRadius: "5px" }}
                  />
                </div>
                <div className="col-lg-5 me-2">
                  <TextField
                    id="outlined-basic"
                    label="Coins"
                    variant="outlined"
                    name="coins"
                    fullWidth
                    value={data.coins}
                    onChange={(e) => handleChange(e, index)}
                    sx={{ background: "#141717", borderRadius: "5px" }}
                  />
                </div>
                <span className="cross-icon">
                  {addArray.length > 1 ? (
                    <Tooltip title="Remove">
                      <RemoveCircleOutlineIcon
                        onClick={() => handleDelete(index)}
                        className="diary-icon-remove"
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  <Tooltip title="Add">
                    <AddCircleOutlineIcon
                      onClick={() => handleAdd()}
                      className="diary-icon-add"
                    />
                  </Tooltip>
                </span>
              </div>
            );
          })}
        </>
      </div>
      <div className="text-end mt-4">
        <button onClick={handleSubmit} className="small-contained-button">
          {isLoading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default CoinConfiguration;
