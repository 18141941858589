import { Dialog } from "@mui/material";
import React from "react";

export default function ConfirmationWithHtml(props) {
  const { componentToPassDown, open, setOpen } = props;
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <div className="responce-messages">{componentToPassDown}</div>
      </Dialog>
    </>
  );
}
