import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Label from "src/components/Label";
import { affirmations_list_api } from "src/DAL/affirmation/affirmationApi";
import RecordNotFound from "src/components/RecordNotFound";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AffirmationsList() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [affirmations, setAffirmations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAffirmationsList = async () => {
    const result = await affirmations_list_api();
    if (result.code == 200) {
      setIsLoading(false);
      setAffirmations(result.general_affirmation);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateDetail = (value) => {
    navigate(`/affirmation/${value._id}`, {
      state: value,
    });
  };

  useEffect(() => {
    getAffirmationsList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-7 col-sm-12">
          <h2>Affirmation </h2>
        </div>
        <div className="col-lg-5 col-sm-12 text-end">
          <button
            onClick={() => navigate(`/affirmation/add-affirmation`)}
            className="small-contained-button"
          >
            Add Affirmation
          </button>
        </div>
        {affirmations.length == 0 ? (
          <RecordNotFound title="Affirmations" />
        ) : (
          affirmations.map((value, index) => {
            return (
              <>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                  <div className="card mt-4 pods-cards-shadow cursor h-100">
                    <img
                      src={
                        value?.affirmation_image?.thumbnail_1
                          ? s3baseUrl + value.affirmation_image.thumbnail_1
                          : ""
                      }
                      className="card-img-top pods-image"
                      alt="affirmations"
                      onClick={() => handleNavigateDetail(value)}
                    />
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <p>{htmlDecode(value.description)}</p>
                        </div>
                      </div>

                      <div className="row recording-card-date">
                        <div className="col-8 text-end ms-auto">
                          <Label
                            variant="ghost"
                            color={value.status === false ? "error" : "success"}
                          >
                            {value.status === false ? "InActive" : "Active"}
                          </Label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        )}
      </div>
    </div>
  );
}
