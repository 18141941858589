import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { DatePicker } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import {
  Ga4_stream_list,
  transactionListFilterPageRequestApi,
} from "src/DAL/Transaction/transactionApi";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

export default function AnalyticFilter({
  filterData,
  handleSelectOther,
  searchFunction,
  handleClearFilter,
}) {
  const currentDate = new Date();
  const [streamList, setStreamList] = useState([]);
  const get_listing = async (page_type) => {
    let result = await Ga4_stream_list(page_type);
    if (result.code == 200) {
      console.log(result, "resultresultresult");
      setStreamList(
        result.streams.map((item) => {
          return {
            ...item,
            chip_label: item.name,
            chip_value: item.id,
          };
        })
      );
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
    }
  };
  useEffect(() => {
    get_listing();
  }, []);

  return (
    <div className="container-fluid new-memories px-4 transaction-filter">
      <>
        <div className="col-12 mt-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="From Date"
              name="date_from"
              inputFormat="dd-MM-yyyy"
              value={filterData.date_from}
              onChange={(e) => {
                handleSelectOther("date_from", e);
              }}
              renderInput={(params) => (
                <TextField {...params} className="inputs-fields" fullWidth />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12 mt-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="To Date"
              name="date_to"
              inputFormat="dd-MM-yyyy"
              value={filterData.date_to}
              onChange={(e) => {
                handleSelectOther("date_to", e);
              }}
              maxDate={currentDate}
              renderInput={(params) => (
                <TextField {...params} className="inputs-fields" fullWidth />
              )}
            />
          </LocalizationProvider>
        </div>
      </>
      {/* <div className="col-12 mt-3">
        <MUIAutocomplete
          inputLabel={"Select Stream"}
          selectedOption={filterData.stream_id}
          setSelectedOption={(e) => {
            handleSelectOther("stream_id", e);
          }}
          optionsList={streamList}
          name="name"
        />
      </div> */}

      <div className="col-lg-12 col-sm-12 text-end d-flex justify-content-end mt-3">
        <div>
          <button
            className="small-contained-button  mt-1 mb-2"
            onClick={searchFunction}
          >
            Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div>
      </div>
    </div>
  );
}
