import { CircularProgress, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSnackbar } from "notistack";
import { updateCoinConfiguration } from "src/DAL/ClientSetting/ClientSetting";
import { _get_attitude_accessment_setting } from "src/DAL/AttitudeAccessmentQuestions/AttitudeAccessmentSetting";
import { makeStyles } from "@mui/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ManageNavItems from "./ManageNavItems";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const CoinConfigurations = ({ level }) => {
  const [configArray, setConfigArray] = useState([
    { reward: "", nav_items: [] },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const itemsRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReaload, setIsReaload] = useState(false);
  const classes = useStyles();

  const handleAdd = (i) => {
    const addedElement = [
      ...configArray,
      { id: "coin" + +i + 1, reward: "", nav_items: [] },
    ];
    setConfigArray(addedElement);
  };

  const handleDelete = (i) => {
    const delValue = [...configArray];
    delValue.splice(i, 1);
    setConfigArray(delValue);
  };

  const get_accessment_setting = async () => {
    setIsReaload(true);
    const result = await _get_attitude_accessment_setting();
    if (result.code == 200) {
      if (result.assessment_setting[level]?.length > 0) {
        const config_array = result.assessment_setting[level]?.map(
          (config, i) => ({ ...config, id: "coin" + i })
        );
        setConfigArray(config_array);
      }
      setIsReaload(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsReaload(false);
    }
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...configArray];
    list[index][name] = value;
    setConfigArray(list);
  };

  function handleOnDragEnd(result) {
    const items = Array.from(configArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setConfigArray(items);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {
      [level]: configArray,
    };

    const result = await updateCoinConfiguration(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClickItem = (e, index, data) => {
    e.preventDefault();
    setSelectedItem(data);
    setSelectedIndex(index);
    setTimeout(() => {
      itemsRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  useEffect(() => {
    get_accessment_setting();
  }, [level]);

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div>
      <div className="row mt-3">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <div
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <form onSubmit={handleSubmit}>
                  {configArray?.map((data, index) => {
                    return (
                      <Draggable
                        key={data.id}
                        draggableId={data.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="d-flex mb-3 coin-card"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <div className="col-lg-3 me-2">
                              <TextField
                                id="outlined-basic"
                                label="Coins Count Start"
                                variant="outlined"
                                name="reward"
                                fullWidth
                                type="number"
                                disabled
                                value={
                                  index == 0
                                    ? 0
                                    : +configArray[index - 1].reward + 1
                                }
                                sx={{
                                  background: "#141717",
                                  borderRadius: "5px",
                                }}
                              />
                            </div>
                            <div className="col-lg-3 me-2">
                              <TextField
                                id="outlined-basic"
                                label="Coins Count End"
                                variant="outlined"
                                name="reward"
                                fullWidth
                                type="number"
                                value={data.reward}
                                onChange={(e) => handleChange(e, index)}
                                sx={{
                                  background: "#141717",
                                  borderRadius: "5px",
                                }}
                              />
                            </div>
                            <div
                              className="col-lg-5"
                              onClick={(e) => handleClickItem(e, index, data)}
                            >
                              <button
                                className={`manage-nav-items-config ${
                                  index === selectedIndex ? "colored-item" : ""
                                }`}
                              >
                                Manage Nav Items
                              </button>
                            </div>
                            <span className="cross-icon">
                              {configArray.length > 1 ? (
                                <>
                                  <Tooltip title="Remove">
                                    <RemoveCircleOutlineIcon
                                      onClick={() => handleDelete(index)}
                                      className="diary-icon-remove"
                                    />
                                  </Tooltip>
                                </>
                              ) : (
                                ""
                              )}
                              <Tooltip title="Add">
                                <AddCircleOutlineIcon
                                  onClick={() => handleAdd(index)}
                                  className="diary-icon-add"
                                />
                              </Tooltip>
                            </span>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  <div className="text-end mt-4">
                    <button className="small-contained-button">
                      {isLoading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div ref={itemsRef}>
        {selectedItem && (
          <ManageNavItems
            selected_items={selectedItem.nav_items}
            index={selectedIndex}
            config_array={configArray}
            setConfigArray={setConfigArray}
          />
        )}
      </div>
    </div>
  );
};
export default CoinConfigurations;
