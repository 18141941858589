import { Chip } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
export default function TestCustomMUITable() {
  const [totalCount, setTotalCount] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleEdit = (row) => {
    // console.log(row, "rowrowrowrowrow");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
  ];
  const handleClick = (row, index) => {
    // console.log(row, "row");
    // console.log(index, "index");
  };

  const TABLE_HEAD = [
    {
      id: "number",
      label: "#",
      alignRight: false,
      type: "number",
    },
    {
      id: "image",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
    },
    {
      id: "title",
      label: "Page Title",
      alignRight: false,
      handleClick: handleClick,
      className: "",
    },
    {
      id: "description",
      label: "Description",
      renderData: (row) => {
        return (
          <Chip
            width="140px"
            label={row.description}
            variant="contained"
            color={"success"}
            size="small"
          />
        );
      },
    },
    {
      id: "category_status",
      label: "Status",
      type: "row_status",
    },
    {
      id: "preview_link_data",
      label: "URL",
      type: "link",
      alignRight: false,
    },
    { id: "action", label: "Action", alignRight: false, type: "action" },
  ];

  const getData = async () => {
    fetch(`data.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setData(result.data);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="table-container">
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={data}
        MENU_OPTIONS={MENU_OPTIONS}
        className="card-with-background" // if we want background gray
        custom_pagination={{
          //if use custom pagination see FeedsList Component
          total_count: totalCount,
          rows_per_page: rowsPerPage,
          page: page,
          handleChangePage: handleChangePage,
        }}
        //if use selection checkbox see ManageTemplateAccess
        selected={selected}
        setSelected={setSelected}
        checkbox_selection={true}
        selected_by="title" // of want select by specific parameter see ManageModuleAccess
      />
    </div>
  );
}
