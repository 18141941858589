import { invokeApi } from "../../bl_libs/invokeApi";

export const add_quarter_api = async (data) => {
  const requestObj = {
    path: `/api/quarter/add`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_quarter_api = async (data, slug) => {
  const requestObj = {
    path: `/api/quarter/update/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const quarters_list_api = async (page, limit, searchText) => {
  const requestObj = {
    path: `/api/quarter/list?page=${page}&limit=${limit}&search_text=${searchText}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_quarter_api = async (slug) => {
  const requestObj = {
    path: `/api/quarter/delete/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const quarter_detail_api = async (slug) => {
  const requestObj = {
    path: `/api/quarter/detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const quarter_duplicate_api = async (slug) => {
  const requestObj = {
    path: `/api/quarter/duplicate/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
