import { invokeApi } from "../../bl_libs/invokeApi";

export const saleDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/sales_section/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const SaleSectionList = async (data) => {
  const requestObj = {
    path: `/api/sales_section`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delegateAddDetailApi = async (data) => {
  const requestObj = {
    path: `/api/delegates_room/delegates_and_groups_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addSalesApi = async (data) => {
  const requestObj = {
    path: `/api/sales_section/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditsaleApi = async (data, slug) => {
  const requestObj = {
    path: `/api/sales_section/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteSaleApi = async (slug) => {
  const requestObj = {
    path: `/api/sales_section/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _mark_default_api = async (slug) => {
  const requestObj = {
    path: `/api/sales_section/make_section_default/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _change_status = async (postData, id) => {
  const requestObj = {
    path: `/api/sales_section/change_section_status`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const changeSaleTypeApi = async (data) => {
  const requestObj = {
    path: `/api/sales_section/sale_section_type_change`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const free_plan_popup_setting_api = async (data, page_id) => {
  const requestObj = {
    path: `/api/sale_page/popup_setting/update/${page_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
