import { invokeApi } from "../../bl_libs/invokeApi";

export const delegatePodsDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/delegates_room/detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delegatePodsListingApi = async (data) => {
  const requestObj = {
    path: `/api/delegates_room/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delegateAddDetailApi = async (data) => {
  const requestObj = {
    path: `/api/delegates_room/delegates_and_groups_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addDelegatePodApi = async (data) => {
  const requestObj = {
    path: `/api/delegates_room/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditDelegatePodApi = async (data, slug) => {
  const requestObj = {
    path: `/api/delegates_room/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteDelegatePodsApi = async (slug) => {
  const requestObj = {
    path: `/api/delegates_room/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
