import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { dd_date_format } from "src/utils/constant";
export default function ManageNotificationInfo({
  handleClick,
  handleClose,
  anchorEl,
  id,
  open,
  text,
  navigatePage,
}) {
  const navigate = useNavigate();
  const handleChangeProgramDetail = (value) => {
    navigate(`/programmes/programmes-detail/${value._id.program_slug}`, {
      state: value._id,
    });
  };

  return (
    <div style={{ width: "400px" }}>
      <Popover
        id={id}
        className="popover-notification"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ heigth: 100 }}
        PaperProps={{
          style: {
            width: "400px",
            borderRadius: "10px",

            border: "1px solid white",
          },
        }}
      >
        <div className="sticky-top card">
          <p className="heading_programs mb-0">{text.title + " " + "Info"}</p>
          <span className="icon-container" onClick={() => handleClose()}>
            <CancelOutlinedIcon fontSize="small" />
          </span>
        </div>
        <div
          className="mb-0"
          dangerouslySetInnerHTML={{
            __html: text.notification_action_description,
          }}
          style={{
            padding: "10px 10px 10px 10px",
          }}
        ></div>
      </Popover>
    </div>
  );
}
