import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { all_questions_listing_api } from "src/DAL/GeneralQuestions/GeneralQuestions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { dd_date_format, replace_created_for } from "src/utils/constant";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import QuestionAnswerFilter from "./QuestionAnswerFilter";
import { download_csv_file } from "src/utils/convertHtml";
import FilteredChip from "src/components/FilteredChip";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function QuestionsAnswersList({ user_type, heading }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [answersData, setAnswersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [searchText, setSearchText] = useState("");

  const EMPTY_FILTER = {
    created_for: null,
    programs: null,
    created_for_ids: [],
    from_date: null,
    to_date: null,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [upadatedFilterData, setUpadatedFilterData] = useState(EMPTY_FILTER);

  let filter_name = "question_answers_filter_data";
  if (user_type == "delegate") {
    filter_name = `delegate_${filter_name}`;
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleClearFilter = () => {
    localStorage.removeItem(filter_name);
    setFilterData(EMPTY_FILTER);
    getAnswersListing(EMPTY_FILTER);
    setFilterDrawerState(false);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    if (!data.date) {
      delete data.date;
      data.from_date = null;
      data.to_date = null;
    }
    getAnswersListing(data);
    setFilterData(data);
    handleCloseFilterDrawer();
    localStorage.setItem(filter_name, JSON.stringify(data));
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getAnswersListing(filterData);
    setUpadatedFilterData(filterData);
    localStorage.setItem(filter_name, JSON.stringify(filterData));
    handleCloseFilterDrawer();
  };

  const getAnswersListing = async (data) => {
    setIsLoading(true);

    let postData = { ...data };
    if (postData.created_for) {
      postData.created_for = data.created_for.created_for;
      if (
        postData?.created_for?.chip_value === "page" ||
        postData?.created_for?.chip_value === "programme"
      ) {
        postData.created_for_ids = data.created_for_ids.map((item) => item._id);
      } else if (data?.created_for?.chip_value === "lesson") {
        if (data?.programs) {
          if (data.created_for_ids?.length > 0) {
            postData.created_for_ids = data.created_for_ids.map(
              (item) => item._id
            );
          } else {
            if (data?.programs?.lessons?.length > 0) {
              postData.created_for_ids = data?.programs?.lessons?.map(
                (item) => item._id
              );
            } else {
              postData.created_for_ids = [];
            }
          }
        } else {
          postData.created_for_ids = [];
        }
      } else if (data?.created_for?.chip_value === "dynamite_event_video") {
        if (data?.programs) {
          if (data.created_for_ids?.length > 0) {
            postData.created_for_ids = data.created_for_ids.map(
              (item) => item._id
            );
          } else {
            if (data?.programs?.dynamite_event_category_video?.length > 0) {
              postData.created_for_ids =
                data?.programs?.dynamite_event_category_video?.map(
                  (item) => item._id
                );
            } else {
              postData.created_for_ids = [];
            }
          }
        } else {
          postData.created_for_ids = [];
        }
      }
    } else {
      postData.created_for_ids = [];
    }

    if (postData.from_date) {
      postData.from_date = moment(postData.from_date).format("YYYY-MM-DD");
    }
    if (postData.to_date) {
      postData.to_date = moment(postData.to_date).format("YYYY-MM-DD");
    }

    delete postData.programs;
    const result = await all_questions_listing_api(
      page,
      rowsPerPage,
      user_type,
      postData
    );

    if (result.code == 200) {
      const tempData = { ...data };
      if (tempData.search_text) {
        delete tempData.search_text;
      }
      if (!tempData.created_for) {
        delete tempData.created_for_ids;
        delete tempData.programs;
      }

      if (
        tempData?.created_for?.chip_value != "programme" &&
        tempData?.created_for?.chip_value != "page"
      ) {
        if (!tempData.programs) {
          delete tempData.created_for_ids;
        }
      }
      if (tempData.from_date) {
        let date = `Start Date : ${dd_date_format(
          tempData.from_date
        )} End Date :  ${dd_date_format(tempData.to_date)}`;
        tempData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete tempData.from_date;
        delete tempData.to_date;
      }

      setUpadatedFilterData(tempData);
      const answers = result?.members?.map((answer, index) => {
        return {
          ...answer,
          user_name: answer?.first_name + " " + answer?.last_name,
          user_email: answer?.email,
          title: answer.title ? answer.title : "N/A",
          answered_date: moment(answer.reply_date).format("DD-MM-YYYY"),
          created_for_string: replace_created_for(answer.created_for),
          table_avatar: {
            src:
              user_type == "member"
                ? s3baseUrl + answer?.profile_image
                : s3baseUrl + answer?.image?.thumbnail_1,
            alt: answer.first_name,
          },
        };
      });

      setAnswersData(answers);
      setTotalCount(result.toal_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleExportClick = () => {
    download_csv_file(headers, selected);
  };
  const handleNavigate = (value) => {
    let check_user = false;
    if (value.created_for == "self_image") {
      check_user = true;
    }
    navigate(
      `${window.location.pathname}/answers-detail?created_for_id=${
        value.created_for_id ? value.created_for_id : ""
      }&member_id=${value.member_id}&created_for=${
        value.created_for
      }&check_user=${check_user}`
    );
  };

  const MENU_OPTIONS = [
    {
      label: "Answers Detail",
      icon: "akar-icons:edit",
      handleClick: handleNavigate,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeCreatedFor = (value) => {
    setFilterData((values) => ({ ...values, created_for: value }));
    setFilterData((values) => ({ ...values, created_for_ids: [] }));
    setFilterData((values) => ({ ...values, programs: null }));
  };
  const handleChangePrograms = (value) => {
    setFilterData((values) => ({ ...values, programs: value }));
    setFilterData((values) => ({ ...values, created_for_ids: [] }));
  };
  const handleChangeOthers = (value, key) => {
    setFilterData((values) => ({ ...values, [key]: value }));
  };
  const handleChangeDateRange = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    setPage(0);
    setPageCount(1);
  }, [user_type]);

  useEffect(() => {
    setSelected([]);
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem(filter_name);
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setUpadatedFilterData(filter_data);
    getAnswersListing(filter_data);
  }, [rowsPerPage, page, user_type]);

  const headers = [
    { key: "created_for_string", label: "Questions Created For" },
    { key: "title", label: "Module Title" },
    { key: "user_name", label: "User Name" },
    { key: "user_email", label: "User Email" },

    { key: "answered_date", label: "Answered Date" },
  ];
  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },

    {
      id: "created_for_string",
      label: "Questions Created For",
      alignRight: false,
      className: "text-capitalize cursor-pointer",
      handleClick: handleNavigate,
    },
    { id: "title", label: "Module Title", alignRight: false },
    {
      id: "table_avatar",
      label: "User Profile",
      alignRight: false,
      type: "thumbnail",
    },
    { id: "user_name", label: "User Name", alignRight: false },
    {
      id: "user_email",
      label: "User Email",
      calignRight: false,
    },
    {
      id: "answered_date",
      label: "Answered Date",
      calignRight: false,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 mb-3">
            <h2>{heading ? heading : "Questions Answers List"}</h2>
          </div>
          <div className="col-12 col-md-6 mb-3 text-end">
            {selected.length > 0 ? (
              <button
                className="small-contained-button me-3"
                onClick={handleExportClick}
              >
                Export Csv &nbsp;&nbsp;{" "}
                <Icon icon={arrowCircleDownFill} height={20} />
              </button>
            ) : (
              ""
            )}
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        <FilteredChip
          data={upadatedFilterData}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={answersData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          selected={selected}
          setSelected={setSelected}
          checkbox_selection={true}
          selected_by="reply_id" // of want select by specific parameter see ManageModuleAccess
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <QuestionAnswerFilter
            filterData={filterData}
            handleChangeOthers={handleChangeOthers}
            user_type={user_type}
            handleChange={handleChangeCreatedFor}
            handleChangePrograms={handleChangePrograms}
            searchSubmitFilter={searchFunction}
            handleChangeDateRange={handleChangeDateRange}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
