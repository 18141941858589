import { invokeApi } from "../../bl_libs/invokeApi";

export const addCoupen = async (data) => {
  const requestObj = {
    path: `/coupon/create_coupon`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateCoupen = async (id, data) => {
  const requestObj = {
    path: `/coupon/update_coupon/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const getCoupenListing = async () => {
  const requestObj = {
    path: `/coupon/list_coupon`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteCoupenApi = async (id) => {
  const requestObj = {
    path: `/coupon/delete_coupon/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
