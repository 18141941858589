import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { changeMemberPassword } from "src/DAL/member/member";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import OutlinedInput from "@mui/material/OutlinedInput";
import Autocomplete from "@mui/material/Autocomplete";
import {
  check_in_by_admin_api,
  check_in_member_api,
} from "src/DAL/Transaction/transactionApi";
import {
  sale_page_list_with_all_plans_api,
  sale_page_list_with_all_plans_api_for_check_in,
} from "src/DAL/WebsitePages/WebsitePages";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

export default function CheckInpopup({
  selectedObject,
  setOpen,
  setSelectedObject,
  reload,
}) {
  const [inputs, setInputs] = useState({
    password: "",
    confirm_password: "",
  });
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const [pagesList, setPagesList] = useState([]);

  const getPagesList = async () => {
    const result = await sale_page_list_with_all_plans_api_for_check_in();
    if (result.code === 200) {
      console.log(result, "result for checkin");
      let page_list = result.sale_page.map((page) => {
        let page_title = page.sale_page_title;
        if (page.type == "template") {
          page_title = page.sale_page_title + " (Template)";
        }
        return {
          ...page,
          page_title: page_title,
        };
      });
      if (selectedPage) {
        setPlansList(selectedPage.plans);
        setSelectedPlan(selectedPlan);
      }
      setPagesList(page_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await check_in_by_admin_api(selectedObject._id);
    if (result.code == 200) {
      reload("stop");
      setSelectedObject();
      enqueueSnackbar(result.message, { variant: "success" });
      setOpen(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpen(false);
    }
  };

  const handleSelectPage = (value) => {
    setSelectedPage(value);
    setPlansList(value.plans);
    setSelectedPlan(null);
  };

  const handleCheckinMembers = async (search) => {
    let plan_id = selectedPlan ? selectedPlan._id : "";
    let page_id = selectedPage._id ? selectedPage._id : "";
    const result = await check_in_member_api(searchText, page_id, plan_id);
    if (result.code === 200) {
      setPersonName(result.billing_ticket);
      // setOpen(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSearchText(value);
  };

  useEffect(() => {
    getPagesList();
  }, []);
  useEffect(() => {
    handleCheckinMembers();
  }, [selectedPage, selectedPlan, searchText]);

  return (
    <div className="container-fluid">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-12 mt-3 mb-4">
          <MUIAutocomplete
            inputLabel="Sale Page"
            selectedOption={selectedPage}
            setSelectedOption={handleSelectPage}
            optionsList={pagesList}
            autoComplete="new-password"
            name="page_title"
            // required
          />
        </div>
        <div className="col-12">
          <MUIAutocomplete
            inputLabel="Choose Plan"
            selectedOption={selectedPage == null ? null : selectedPlan}
            setSelectedOption={setSelectedPlan}
            optionsList={selectedPage == null ? [] : plansList}
            autoComplete="new-password"
            name="plan_title"
          />
        </div>
        <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
          <>
            <Autocomplete
              // multiple
              id="tags-outlined"
              options={personName}
              getOptionLabel={(option) =>
                option.first_name +
                " " +
                option.last_name +
                " (" +
                option.email +
                ")"
              }
              filterSelectedOptions
              value={selectedObject}
              onChange={(event, newValue) => {
                setSelectedObject(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Members"
                  placeholder="Members"
                  onChange={handleSearch}
                />
              )}
            />
          </>
        </div>

        <div class="col-12 mt-3">
          <button class="small-contained-button w-100">
            {isLoading ? "Checking-In..." : "Check-in"}
          </button>
        </div>
      </form>
    </div>
  );
}
