import React, { useCallback, useEffect, useRef, useState } from "react";
import Message from "src/components/Message";
// import { GoalStatementList } from "../../components/GoalStatement";
// import GoalGallerySlider from "../../components/GoalStatement/GoalGallerySlider";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { delegateQuestionsListing } from "src/DAL/consultant/consultant";
import MessageeForAllSales from "src/components/MessageeForAllSales";
import { saleSectionLeadsListApi } from "src/DAL/SaleSectionLeads/saleSectionLeadsApi";
import SearchIcon from "@mui/icons-material/Search";
import UserListToolbars from "src/components/_dashboard/user/UserListToolbars";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import LeadsFilterDrawer from "./LeadsFilterDrawer";
// import PageDescription from "src/components/GeneralComponents/PageDescription";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function AllSalesPage(props) {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [leadList, setLeadList] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [goalStatementAnswers, setGoalStatementAnswers] = useState([]);
  const [goalStatementGallery, setGoalStatementGallery] = useState([]);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [query, setQuery] = useState("");
  // load more   states
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [cout, setcout] = useState(0);
  const [totlePages, setTotlePages] = useState(0);
  const [feedsList, setFeedsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [sectionInfo, setsectionInfo] = useState("");
  const [SectionCount, setSectionCount] = useState("");
  const [loadMore, setLoadMore] = useState(
    `/api/sales_section/all_leads?page=0&limit=50`
  );

  const getFilteredData = (query, data) => {
    let dataToFilter = [...data];
    const filtered = dataToFilter.map((value, i) => {
      if (query) {
        let resultFilter = value.leads.filter((post) => {
          if (
            post.user_info.first_name
              .toLowerCase()
              .includes(query.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
        return { ...value, leads: resultFilter };
      } else {
        return value;
      }
    });
    return filtered;
  };
  let paramsid = params.id;

  const getSectionLeadList = async (value) => {
    let path =
      value == "search" && filterName.length > 0
        ? "/api/sales_section/all_leads"
        : value == "filters"
        ? "/api/sales_section/all_leads"
        : loadMore;
    if (value == "loadmore") {
    } else {
      setIsLoading(true);
    }

    let postData =
      selectedValue != ""
        ? {
            search_text: filterName,
            sales_section: selectedValue,
          }
        : {
            search_text: filterName,
            sales_section: paramsid,
          };

    const result = await saleSectionLeadsListApi(postData, path);

    if (result.code === 200) {
      const new_feeds = result.leads.map((feed) => {
        return { ...feed, is_show_all: false };
      });

      var newArray =
        value == "search" && filterName.length > 0
          ? result.leads
          : value === "filter"
          ? result.leads
          : value === "filters"
          ? result.leads
          : value == "search" && filterName.length < 1
          ? result.leads
          : leadList.concat(new_feeds);
      setLeadList(newArray);
      if (value == "search" && filterName.length < 1) {
        setPageNumber(0);
      } else if (value === "filters") {
        setPageNumber(0);
      } else {
        setPageNumber(pageNumber + 1);
      }

      setTotlePages(result.total_pages);
      setsectionInfo(result.sections);

      if (cout < 1) {
        if (paramsid) {
          setSelectedValue(result.sections[0]._id);
        } else {
          setSelectedValue(result.sections._id);
        }
      }
      setSectionCount(result.total_leads_count);
      setLoadMorePath("/" + result.load_more);
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  // load more functions start
  const loadMoreData = () => {
    setLoadMore(loadMorePath);
    setIsLoadingMore(true);
  };
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totlePages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totlePages, pageNumber, loadMorePath, observer]
  );

  // load more functions end
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleSearchName = () => {
    setTotlePages(0);
    setPageNumber(0);
    getSectionLeadList("search");
  };
  useEffect(() => {
    getSectionLeadList();
    setcout(cout + 1);
    if (sectionInfo) {
      setSelectedValue(sectionInfo._id);
    }
  }, []);

  useEffect(() => {
    getSectionLeadList("loadmore");
  }, [loadMore]);

  // useEffect(() => {
  //   let filtered = getFilteredData(query, leadList);
  //   setLeadList(filtered);
  // }, [query]);
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Sale Sections"
        componentToPassDown={
          <LeadsFilterDrawer
            value={selectedValue}
            handleChange={handleChange}
            dataList={getSectionLeadList}
            setSelectedValue={setSelectedValue}
            onCloseDrawer={handleCloseFilterDrawer}
          />
        }
      />
      {/* <PageDescription
        parameter="goal_statement_description"
        else_title="Goal Statement"
      /> */}
      <div className="row">
        <div className="col-12">
          {paramsid && (
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
        </div>
        <div className="col-3 d-flex">
          <h2 className="mt-4">Leads</h2>
        </div>
        <div className="col-7 leads-search text-end pe-0">
          <div style={{ position: "absolute", right: "144px" }}>
            <UserListToolbars
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onSearch={handleSearchName}
            />
          </div>
        </div>
        <div className="col-2 mt-4 ps-0" style={{ textAlign: "end" }}>
          <button
            className="small-contained-button me-2 mt-1 mb-4 "
            onClick={handleOpenFilterDrawer}
          >
            Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div>
        {/* <div className="col-12 text-end">
          {" "}
          <button
            className="small-contained-button me-2 mt-1 mb-4 "
            onClick={handleOpenFilterDrawer}
          >
            Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div> */}
      </div>

      <br></br>

      <div className="row ">
        <div className="col-12">
          <MessageeForAllSales
            // replies={replies}
            data={getFilteredData(query, leadList)}
            dataReload={getSectionLeadList}
            lastBookElementRef={lastBookElementRef}
            loadMoreData={loadMoreData}
            isLoadingMore={isLoadingMore}
            setPageNumber={setPageNumber}
            sectionInfo={sectionInfo}
            totlePages={totlePages}
            SectionCount={SectionCount}
            pageNumber={pageNumber}
            // answers={goalStatementAnswers}
            // goalStatementCompletedStatus={goalStatementCompletedStatus}
            // goalStatementCompletedDate={goalStatementCompletedDate}
          />
        </div>
      </div>
    </div>
  );
}

export default AllSalesPage;
