import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  deleteWebsitePlanPackege,
  deleteWebsiteTestimonial,
  pagePLanList,
  salePageTestimonialListApi,
} from "src/DAL/WebsitePages/testimonial";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  detailPageApi,
  page_detail_by_id,
} from "src/DAL/WebsitePages/WebsitePages";
import { s3baseUrl } from "src/config/config";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import PackagePlanList from "./PackagePlanList";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function WebPagePlanPackege() {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [packagePlanList, setPackagePlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [moduleData, setModuleData] = useState({});
  const [pageData, setPageData] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const { state } = useLocation();
  const [referredpop, setReferredpop] = useState(false);
  const [PlanArray, setPlanArray] = useState([]);

  const module_actual_name = "testimonial";
  const is_website_pages = window.location.pathname.includes("website-pages");
  const handleOPen = (value) => {
    setReferredpop(true);
    setPlanArray(value);
  };
  const getWebPageTestimonials = async () => {
    setIsLoading(true);
    const result = await pagePLanList(params.page_slug ? params.page_slug : "");
    if (result.code === 200) {
      console.log(result, "resultresultresult");

      setPackagePlan(result.data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getPageDetail = async () => {
    setIsLoading(true);
    const result = await page_detail_by_id(params.page_slug);
    if (result.code == 200) {
      handlePageDetail(result.sale_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  console.log(params, "----params");
  const handleEdit = (value) => {
    if (params.page_slug) {
      if (is_website_pages) {
        navigate(
          `/website-pages/${params.page_slug}/plan-packege/edit-plan-packege/${value._id}`,
          {
            state: {
              data: value,
              page_info: pageData,
            },
          }
        );
      } else {
        navigate(
          `/template-pages/${params.page_slug}/plan-packege/edit-plan-packege/${value._id}`,
          {
            state: {
              data: value,
              page_info: pageData,
            },
          }
        );
      }
    } else {
      navigate(`/website-testimonials/plan-packege/${value._id}`, {
        state: { data: value },
      });
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteWebsitePlanPackege(deleteDoc._id);
    if (result.code === 200) {
      getWebPageTestimonials();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    if (params.page_slug) {
      if (is_website_pages) {
        navigate(
          `/website-pages/${params.page_slug}/plan-packege/add-plan-packege`,
          {
            state: pageData,
          }
        );
      } else {
        navigate(
          `/template-pages/${params.page_slug}/plan-packege/add-plan-packege`,
          {
            state: pageData,
          }
        );
      }
    } else {
      navigate(`/website-plan-packege/add-plan-packege`);
    }
  };

  const handlePageDetail = (data) => {
    setPageData(data);
    // const get_module_info = data.module_info.filter(
    //   (item) => item.module_actual_name == module_actual_name
    // )[0];
    // setModuleData(get_module_info.module_replica_info);
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "package_name", label: " Package Name", alignRight: false },

    {
      id: "order",
      label: "No. Of Plans",
      renderData: (row) => {
        return (
          <>
            <Tooltip title="View Package Plans">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleOPen(row.package_plans)}
              >
                {row.package_plans.length}
              </span>
            </Tooltip>
          </>
        );
      },
    },
    { id: "order", label: "Order", alignRight: false },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
  ];

  useEffect(() => {
    getWebPageTestimonials();
    if (params.page_slug) {
      if (state) {
        handlePageDetail(state);
      } else {
        getPageDetail();
      }
    }
  }, [params.page_slug]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        {params.page_slug && (
          <div className="row">
            <div className="col-12 display-flex mb-4">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
              <span className="sale-page-title">
                {pageData.sale_page_title}
              </span>
            </div>
          </div>
        )}
        <div className="row mb-5">
          <div className="col-lg-8 col-sm-12">
            <h2>Plan Package</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              {"Add Plan Package"}
            </button>
          </div>
        </div>
        <WhatsappPopUpModel
          open={referredpop}
          setOpen={setReferredpop}
          title={"Package Plans"}
          show_date_and_income={true}
          componentToPassDown={
            <PackagePlanList setOpen={setReferredpop} PlanArray={PlanArray} />
          }
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={packagePlanList}
          className="card-with-background"
          MENU_OPTIONS={MENU_OPTIONS}
          sortBy="order"
          pagePagination={true}
        />
      </div>
    </>
  );
}
