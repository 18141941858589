import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import React from "react";

function QRCodeDetail({
  openDelete,
  setOpenDelete,
  handleAgree,
  title,
  open,
  setOpen,
  title_html,
  data,
}) {
  let value = data?.data?.billing_ticket;
  const handleCloseDialog = () => {
    setOpenDelete(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="preview">
      <Dialog
        open={openDelete}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "white",
            boxShadow: "none",
            minWidth: "40%",
          },
        }}
      >
        <div className="cross-QR modalIcon" onClick={handleCloseDialog}>
          <span>x</span>
        </div>
        <DialogTitle className="text-center">New Ticket Detail</DialogTitle>

        <div className="container-fluid">
          <div className="row d-flex p-3">
            <div className="col-4">Name</div>
            <div className="col-8">
              {value?.first_name + " " + value?.last_name}
            </div>
          </div>
          <div className="row d-flex p-3">
            <div className="col-4">Email</div>
            <div className="col-8">{value?.email}</div>
          </div>
          <div className="row d-flex p-3">
            <div className="col-4">Product</div>
            <div className="col-8">
              {value?.sale_page?.sale_page_title +
                " (" +
                value?.plan.plan_title +
                ")"}
            </div>
          </div>

          <div className="row d-flex p-3">
            <div className="col-4">Check In Time</div>
            <div className="col-8">
              {moment(value?.checkin_date_time).format("DD-MM-YYYY hh:mm A")}
            </div>
          </div>
        </div>
        <DialogActions>
          {/* <Button className="model-button-hover" onClick={handleCloseDialog}>
            Close
          </Button> */}
          {/* <Button className="model-button-hover" onClick={handleAgree}>
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default QRCodeDetail;
