import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  _change_status,
  _mark_default_api,
  deleteSaleApi,
  SaleSectionList,
} from "src/DAL/SaleSections/saleSection";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import ChangeStatusPopUp from "./ChangeStatusPopUp";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AllSales() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [MarkDEfaultDoc, setMarkDEfaultDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [ChangeStatusValue, setChangeStatusValue] = useState("");
  const [openChangeStatus, setopenChangeStatus] = useState(false);
  const [OpenMarkDefault, setOpenMarkDefault] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    section_status: "",
  });
  const handleClickOpenStatus = (event) => {
    setAnchorEl(true);
  };
  {
    anchorEl, setAnchorEl, handleClickOpenStatus;
  }
  const getConsultantListing = async () => {
    const result = await SaleSectionList();
    if (result.code === 200) {
      setUserList(result.sales_section);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (value) => {
    navigate(`/all-sales/edit-sales/${value._id}`, {
      state: value,
    });
  };
  const handleChangeStatus = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handlechangeAffiliateID = (value) => {
    setDeleteDoc(value);
    setChangeStatusValue(value);
    setInputs((inputs) => ({
      ...inputs,
      ["title"]: value?.title ? value?.title : "",
      ["section_status"]: value?.sale_section_type
        ? value?.sale_section_type
        : "",
    }));
    setopenChangeStatus(true);
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleAgreeMarkDefault = (value) => {
    setMarkDEfaultDoc(value);
    setOpenMarkDefault(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteSaleApi(deleteDoc._id);
    if (result.code === 200) {
      getConsultantListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleMarkDefaultApi = async () => {
    setOpenMarkDefault(false);
    setIsLoading(true);
    const result = await _mark_default_api(MarkDEfaultDoc._id);
    if (result.code === 200) {
      getConsultantListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handlechangeStatusApi = async () => {
    setopenChangeStatus(false);
    setIsLoading(true);
    const postData = {
      section_status: inputs.section_status,
      section_id: deleteDoc._id,
    };
    const result = await _change_status(postData);
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setopenChangeStatus(true);
    }
  };
  const handleNavigateSakesCount = (value) => {
    navigate(`/all-leads/${value._id}`, {
      state: value,
    });
  };
  const handleNavigate = () => {
    navigate(`/all-sales/add-sales`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Change Status",
      icon: "fe:loop",
      handleClick: handlechangeAffiliateID,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Title",
      alignRight: false,
      handleClick: handleNavigateSakesCount,
      className: "cursor-pointer",
    },
    {
      id: "sales_lead_count",
      label: "Sale Lead Count",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <div
              className="text-center"
              onClick={() => handleNavigateSakesCount(row)}
              style={{ marginRight: "26px" }}
            >
              <div className="text-center count-color">
                {row.sales_lead_count}
              </div>
            </div>
          </>
        );
      },
    },
    {
      id: "sale_section_type",
      label: "Sale Type",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <div
              className={
                row.sale_section_type == "paid"
                  ? "paid"
                  : row.sale_section_type == "free"
                  ? "free"
                  : "normal"
              }
            >
              {row?.sale_section_type}
            </div>
          </>
        );
      },
    },
    { id: "order", label: "Order", alignRight: false },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
  ];

  useEffect(() => {
    getConsultantListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={OpenMarkDefault}
        setOpen={setOpenMarkDefault}
        title={"Are you sure you want to make this section default?"}
        handleAgree={handleMarkDefaultApi}
      />
      <ChangeStatusPopUp
        open={openChangeStatus}
        setOpen={setopenChangeStatus}
        value={ChangeStatusValue}
        title={"Are you sure you want to Affliate id?"}
        handleChange={handleChangeStatus}
        handleAgree={handlechangeStatusApi}
        inputs={inputs}
      />
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-lg-8 col-sm-12">
            <h2>Sale Sections</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Sale Section
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
