import { Tooltip } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";

function BadgesHistory({ tabData, memberDetail }) {
  const [totalBadges, setTotalBadges] = useState([]);

  const get_badges_list = async () => {
    let tab_data = [];
    if (tabData.length > 0) {
      tab_data = tabData.map((data) => {
        return {
          ...data,
          title: data.challange_info?.challange_title,
          completed_challenges: data.badge_configration?.number_of_challenges,
        };
      });
    }
    setTotalBadges(tab_data);
  };

  let TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Challange Title",
    },
    {
      id: "completed_challenges",
      label: "Completed Challenges",
    },
    {
      id: "selected_badges",
      label: "Rewards Assigned",
      renderData: (row) => {
        let selected_badges = row.badge_configration.selected_badges;
        return (
          <div className="d-flex">
            {selected_badges.map((badge) => {
              return (
                <div className="rewards-assigned-badges ms-3">
                  <div className="badges-count">{badge.no_of_badges}</div>
                  <Tooltip title={badge.title}>
                    <img src={s3baseUrl + badge.icon.thumbnail_2} alt="" />
                  </Tooltip>
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    get_badges_list();
  }, [tabData]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="mb-3 mt-4">
            <h2>Badges History</h2>
          </div>
          {memberDetail.badges.map((badge) => {
            return (
              <div className="col-12 col-md-3 mb-3">
                <div className="profile-card">
                  <img
                    src={s3baseUrl + badge.icon.thumbnail_2}
                    alt=""
                    className="profile-badges-icon"
                  />
                  <h3>{badge.no_of_badges}</h3>
                  <h6 className="text-muted">{badge.title}</h6>
                </div>
              </div>
            );
          })}
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={totalBadges}
              className="card-with-background"
              hide_search={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BadgesHistory;
