import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import { show_proper_words } from "src/utils/constant";

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  { id: "title", label: " Title" },
  { id: "table_avatar", label: "Image", type: "thumbnail" },
  {
    id: "room_type",
    label: "Type",
    className: "text-capitalize",
  },
  {
    id: "start_datatime",
    label: "Start Date Time",
  },
  {
    id: "recurringType",
    label: "Type",
  },
  { id: "status", label: "Status", type: "row_status" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MemberPods({ tabData }) {
  const classes = useStyles();
  const [communityList, setCommunityList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const podsListing = () => {
    setIsLoading(true);

    const data = tabData?.map((rooms) => {
      let start_datatime = "N/A";
      let recurringType = "";
      if (rooms.start_date_time) {
        start_datatime = moment(rooms.start_date_time).format(
          "DD-MM-YYYY hh:mm A"
        );
      }

      if (rooms.recurring_type) {
        recurringType = show_proper_words(rooms.recurring_type);
      }

      return {
        ...rooms,
        table_avatar: {
          src: s3baseUrl + rooms.room_image?.thumbnail_1,
          alt: rooms.title,
        },
        start_datatime,
        recurringType,
      };
    });
    setCommunityList(data);
    setIsLoading(false);
  };

  useEffect(() => {
    podsListing();
  }, [tabData]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="mt-3">
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={communityList}
        className="card-with-background"
      />
    </div>
  );
}
