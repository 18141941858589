import React from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { s3baseUrl } from "src/config/config";
import { CircularProgress } from "@mui/material";
function random_id() {
  return Math.floor(Math.random() * 90000) + 10000;
}

export default function UploadImageBox(props) {
  const { item, handleChange, handleRemove } = props;
  let image_id = `${item.path}${random_id()}`;
  return (
    <>
      {!item?.icon ? (
        <span className={`${"upload-button"} mb-1`}>
          <input
            color="primary"
            accept="image/*"
            type="file"
            id={image_id}
            style={{ display: "none" }}
            name="icon"
            onChange={handleChange}
          />
          <label htmlFor={image_id} className="">
            {item.is_loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <CloudUploadIcon />
            )}
          </label>
        </span>
      ) : (
        <div className="preview">
          <span className="track-image-cross" onClick={handleRemove}>
            x
          </span>
          <img
            src={s3baseUrl + item?.icon}
            style={{
              padding: "10px",
              width: "120px",
              height: "auto",
              maxHeight: "110px",
              cursor: "pointer",
            }}
            className="main"
          />
        </div>
      )}
    </>
  );
}
