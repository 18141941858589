import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

function EmailLogsPopUp({
  openDelete,

  open,
  setOpen,
  emailBody,
}) {
  const handleCloseDialog = () => {
    console.log("clicked");
    setOpen(false);
  };
  const handleCloseDialog1 = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={openDelete}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
            width: "50%",
          },
        }}
      >
        <DialogActions>
          <Button className="model-button-hover" onClick={handleCloseDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* New to solve delete issue  */}
      <Dialog
        open={open}
        onClose={handleCloseDialog1}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
            width: "50%",
          },
        }}
      >
        <span
          className="icon-container icon-containers"
          onClick={() => handleCloseDialog()}
        >
          <CancelOutlinedIcon color="primary" fontSize="large" />
        </span>

        <iframe
          className="responsive-iframe"
          srcdoc={emailBody}
          width="1000px"
          height="1600px"
        ></iframe>
      </Dialog>
    </>
  );
}

export default EmailLogsPopUp;
