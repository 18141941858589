import {
  Avatar,
  Card,
  CircularProgress,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import Label from "src/components/Label";
import {
  AppBugReports,
  AppItemOrders,
  AppNewUsers,
  AppWeeklySales,
} from "src/components/_dashboard/app";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import CircleIcon from "@mui/icons-material/Circle";
import { makeStyles } from "@mui/styles";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useState } from "react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import {
  history_api_api,
  road_map_levels_list_api,
} from "src/DAL/RoadMapLevelsApi/RoadMapLevelsApi";
import { useEffect } from "react";
import MemberHistory from "./MemberHistory";
import moment from "moment";
import MemberSubscriptionsHistory from "./MemberSubscriptionsHistory";
import MemberTransactionsHoistory from "./MemberTransactionsHoistory";
import BookingHistory from "./BookingHistory";
import { useSnackbar } from "notistack";
import MemberSubscribersHistory from "./MemberSubscribersHistory";
import OrderHistory from "./OrderHistory";
import AppOrderSales from "src/components/_dashboard/app/AppOrderSales";
import { project_mode } from "src/config/config";
import MemberTransactionsFilter from "../MembersList/MemberTransactionsFilter";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import MemberTransactionsFilterHistory from "./MemberTransactionsFilterHistory";
import OrderHistoryFilter from "../Shop/OrderHistoryFilter";
import BookingFilter from "../Booking/BookingFilter";
import SubscriptionsFilterHistory from "./SubscriptionsFilterHistory";
import SubscribersFilterHistory from "./SubscribersFilterHistory";
import { dd_date_format } from "src/utils/constant";
import AppIncomeSales from "src/components/_dashboard/app/AppIncomeSales";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { Delete } from "@mui/icons-material";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const ALTER_FILTER = {
  transaction_status: "",
  payment_made_by: "",
};
const ALTER_FILTER_SUBSCRIPTIONS = {
  transaction_type: "all",
  expired: "",
};
let filterDate = {
  from_date: moment(Date.now()).format("YYYY-MM-DD"),
  to_date: moment(Date.now()).format("YYYY-MM-DD"),
};
const EMPTY_FILTER = {
  payment_made_by: "all",
  sale_page: null,
  payment_plan: null,
  filter_by_plateform: null,
  payment_request: [],
  transaction_type: "all",
  transaction_for: "all",
  transaction_mode: project_mode == "dev" ? ["sandbox"] : ["live"],
};
const EMPTY_FILTER_SUBSCRIPTIONS = {
  sale_page: null,
  payment_plan: null,
  payment_request: [],
  transaction_type: "all",
  expired: "active",
  expiry_in: "",
  next_invoice_date_from: new Date(),
  next_invoice_date_to: new Date(),
};
const EMPTY_FILTER_SUBSCRIBERS = {
  sale_page: null,
  payment_plan: null,
};
const EMPTY_FILTER_ORDER = {
  order_status: [{ chip_label: "All", chip_value: "all" }],
};

const EMPTY_FILTER_BOOKSING = {
  booking_status: null,
  consultant_id: null,
  search_text: "",
  sale_page: [],
};
export default function TodayHistory() {
  const [tabValue, setTabValue] = useState(0);
  const { defaultTimeZone } = usePGIMode();
  const [tabData, setTabData] = useState([]);
  const [Stats, setStats] = useState();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [isAllDates, setIsAllDates] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterDataOrder, setFilterDataOrder] = useState(EMPTY_FILTER_ORDER);
  const [filterQuery, setFilterQuery] = useState({});
  const [filterDataSubscriptions, setFilterDataSubscriptions] = useState(
    EMPTY_FILTER_SUBSCRIPTIONS
  );
  const [filterDataSubscribers, setFilterDataSubscribers] = useState(
    EMPTY_FILTER_SUBSCRIBERS
  );

  const [filterDataSubscribersUpdate, setFilterDataSubscribersUpdate] =
    useState(EMPTY_FILTER_SUBSCRIBERS);
  const [filterDataSubscriptionsUpdated, setFilterDataSubscriptionsUpdated] =
    useState(EMPTY_FILTER_SUBSCRIPTIONS);
  const [filterBooking, setFilterDataBooking] = useState(EMPTY_FILTER_BOOKSING);
  const [filterStateUpdatedBooking, setFilterStateUpdatedBooking] = useState(
    EMPTY_FILTER_BOOKSING
  );
  const [filterDates, setFilterDates] = useState({
    from_date: moment(Date.now())
      .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
      .format("YYYY-MM-DD"),
    to_date: moment(Date.now())
      .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
      .format("YYYY-MM-DD"),
  });
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);
  const [filterStateUpdatedOrder, setFilterStateUpdatedOrder] =
    useState(EMPTY_FILTER_ORDER);
  const handleChaneBookingStatus = (name, value) => {
    setFilterDataBooking((values) => ({ ...values, [name]: value }));
  };
  const handleSelectOtherBookingStatus = (name, value) => {
    setFilterDataBooking((values) => ({ ...values, [name]: value }));
  };
  const handleChangeOthers = (event, name) => {
    setFilterDates((values) => ({ ...values, [name]: event.$d }));
  };
  const handleChangeData = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };
  const handleChangeDataSubscriptions = (name, value) => {
    setFilterDataSubscriptions((values) => ({ ...values, [name]: value }));
  };
  const handleChangeDataSubsribers = (name, value) => {
    setFilterDataSubscribers((values) => ({ ...values, [name]: value }));
  };
  const handleChangeValues = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setFilterDataOrder((values) => ({ ...values, [name]: value }));
  };
  const handleChange = (event, newValue) => {
    setTabData([]);
    setPage(0);
    setPageCount(1);
    setSearchText("");
    setTabValue(newValue);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const handleChangeOtherForExpire = (name, value) => {
    setFilterDataSubscriptions((values) => ({ ...values, [name]: value }));
  };
  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    // filterData.search_text = searchText;
    getRoadMapList(
      tabValue == 5
        ? filterDataOrder
        : tabValue == 4
        ? filterBooking
        : tabValue == 2
        ? filterDataSubscriptions
        : tabValue == 3
        ? filterDataSubscribers
        : filterData
    );
  };
  const handleClearFilter = () => {
    let clear_data;
    if (tabValue == 5) {
      clear_data = {
        ...EMPTY_FILTER_ORDER,
        order_status: [{ chip_label: "All", chip_value: "all" }],
      };
      setFilterDataOrder(clear_data);
    } else if (tabValue == 1) {
      clear_data = {
        ...EMPTY_FILTER,
        transaction_mode: [],
      };
      setFilterData(clear_data);
    } else if (tabValue == 4) {
      clear_data = {
        ...EMPTY_FILTER_BOOKSING,
      };
      setFilterDataBooking(clear_data);
    } else if (tabValue == 2) {
      clear_data = {
        ...EMPTY_FILTER_SUBSCRIPTIONS,
      };
      setFilterDataSubscriptions(clear_data);
    } else if (tabValue == 3) {
      clear_data = {
        ...EMPTY_FILTER_SUBSCRIBERS,
      };
      setFilterDataSubscribers(clear_data);
    }

    getRoadMapList(clear_data);
    handleCloseFilterDrawer();
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handleDeleteChip = (data) => {
    if (tabValue == 5) {
      if (data.order_status.length == 0) {
        data.order_status = [{ chip_label: "All", chip_value: "all" }];
      }
      setFilterStateUpdatedOrder(data);
      setFilterDataOrder(data);
    } else if (tabValue == 1) {
      setFilterStateUpdated(data);
      setFilterData(data);
    } else if (tabValue == 4) {
      setFilterStateUpdatedBooking(data);
      setFilterDataBooking(data);
    } else if (tabValue == 2) {
      if (!data.expiry_name) {
        data.expiry_in = "";
        data.expired = "";
        data.next_invoice_date_from = new Date();
        data.next_invoice_date_to = new Date();
      }
      setFilterDataSubscriptions(data);
      setFilterDataSubscriptionsUpdated(data);
    } else if (tabValue == 3) {
      setFilterDataSubscribers(data);
      setFilterDataSubscribersUpdate(data);
    } else {
    }
    getRoadMapList(data, "epetyyy");
  };
  const filterDataButton = () => {
    getRoadMapList(
      tabValue == 5
        ? filterDataOrder
        : tabValue == 4
        ? filterBooking
        : tabValue == 2
        ? filterDataSubscriptions
        : tabValue == 3
        ? filterDataSubscribers
        : filterData
    );
  };
  const filterDataButtonReset = () => {
    setFilterData(EMPTY_FILTER);
    let tab_name = status_object[tabValue];
    setFilterDates({
      from_date: moment(Date.now())
        .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
        .format("YYYY-MM-DD"),
      to_date: moment(Date.now())
        .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
        .format("YYYY-MM-DD"),
    });
    getRoadMapList(
      tabValue == 5
        ? EMPTY_FILTER_ORDER
        : tabValue == 4
        ? EMPTY_FILTER_BOOKSING
        : tabValue == 2
        ? EMPTY_FILTER_SUBSCRIPTIONS
        : tabValue == 3
        ? EMPTY_FILTER_SUBSCRIBERS
        : EMPTY_FILTER,
      "reset"
    );
  };
  let status_object = [
    "member",
    "transaction",
    "subscriptions",
    "subscribers",
    "bookings",
    "orders",
  ];
  const getRoadMapList = async (data, reset) => {
    setIsLoading(true);
    let tab_name = status_object[tabValue];
    let postData;
    postData = data ? { ...data } : { ...filterData };
    if (tab_name == "transaction") {
      if (filterData.transaction_type == "sale_page") {
        postData.sale_page = filterData.sale_page
          ? [filterData.sale_page._id]
          : [];
      } else {
        postData.sale_page = [];
      }

      if (postData.payment_plan) {
        postData.payment_plan = filterData.payment_plan._id;
      }

      if (postData.transaction_type == "payment_request") {
        postData.payment_request = filterData.payment_request.map((item) => {
          return item._id;
        });
      }

      if (postData.filter_by_plateform) {
        postData.filter_by_plateform =
          filterData.filter_by_plateform.chip_value;
      }

      if (postData.transaction_type !== "sale_page") {
        postData.sale_page = null;
        postData.payment_plan = null;
      }
      if (postData.transaction_type !== "payment_request") {
        postData.payment_request = [];
      }
      if (postData.payment_made_by == "all") {
        postData.payment_made_by = "";
      }
      if (reset == "reset") {
        postData.start_date = moment(Date.now())
          .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
          .format("YYYY-MM-DD");
        postData.end_date = moment(Date.now())
          .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
          .format("YYYY-MM-DD");
      } else {
        postData.start_date = moment(filterDates.from_date).format(
          "YYYY-MM-DD"
        );
        postData.end_date = moment(filterDates.to_date).format("YYYY-MM-DD");
      }
      postData.record_type = tab_name;
    } else if (tab_name == "orders") {
      if (postData.order_status.length > 0) {
        postData.order_status = postData.order_status.map(
          (item) => item.chip_value
        );
      }
      postData.record_type = tab_name;

      if (reset == "reset") {
        postData.start_date = moment(Date.now())
          .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
          .format("YYYY-MM-DD");
        postData.end_date = moment(Date.now())
          .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
          .format("YYYY-MM-DD");
      } else {
        postData.start_date = moment(filterDates.from_date).format(
          "YYYY-MM-DD"
        );
        postData.end_date = moment(filterDates.to_date).format("YYYY-MM-DD");
      }
    } else if (tab_name == "bookings") {
      if (postData.consultant_id) {
        postData.consultant_id = postData.consultant_id._id;
      }

      if (postData.sale_page) {
        postData.sale_page = postData.sale_page.map((item) => {
          return item._id;
        });
      }
      if (postData.booking_status) {
        postData.booking_status = postData.booking_status._id;
      }
      postData.record_type = tab_name;

      if (reset == "reset") {
        postData.start_date = moment(Date.now())
          .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
          .format("YYYY-MM-DD");
        postData.end_date = moment(Date.now())
          .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
          .format("YYYY-MM-DD");
      } else {
        postData.start_date = moment(filterDates.from_date).format(
          "YYYY-MM-DD"
        );
        postData.end_date = moment(filterDates.to_date).format("YYYY-MM-DD");
      }
    } else if (tab_name == "subscriptions") {
      if (postData.transaction_type == "sale_page") {
        postData.page_id = postData.sale_page ? postData.sale_page._id : "";
      } else {
        postData.sale_page = "";
      }

      if (postData.payment_plan) {
        postData.plan_id = postData.payment_plan._id;
      }

      if (postData.transaction_type == "payment_request") {
        postData.payment_request = postData.payment_request.map((item) => {
          return item._id;
        });
      }
      if (postData.expired == "expired") {
        postData.expired = true;
      } else {
        postData.expired = false;
      }

      if (postData.expiry_in) {
        postData.next_invoice_date_from = moment(
          postData?.next_invoice_date_from
        ).format("YYYY-MM-DD");
        postData.next_invoice_date_to = moment(
          postData?.next_invoice_date_to
        ).format("YYYY-MM-DD");
        if (postData.expiry_in !== "custom") {
          postData.next_invoice_date_to = moment(
            postData.next_invoice_date_from,
            "YYYY-MM-DD"
          )
            .add(postData.expiry_in, "days")
            .format("YYYY-MM-DD");
        }
      } else {
        delete postData.next_invoice_date_from;
        delete postData.next_invoice_date_to;
      }
      postData.filter_by_plateform = postData.transaction_type;

      postData.record_type = tab_name;

      if (reset == "reset") {
        postData.start_date = moment(Date.now())
          .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
          .format("YYYY-MM-DD");
        postData.end_date = moment(Date.now())
          .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
          .format("YYYY-MM-DD");
      } else {
        postData.start_date = moment(filterDates.from_date).format(
          "YYYY-MM-DD"
        );
        postData.end_date = moment(filterDates.to_date).format("YYYY-MM-DD");
      }
    } else if (tab_name == "subscribers") {
      postData.page_id = postData.sale_page ? postData.sale_page._id : "";
      if (postData.payment_plan) {
        postData.plan_id = postData.payment_plan._id;
      }
      delete postData.sale_page;
      postData.record_type = tab_name;

      if (reset == "reset") {
        postData.start_date = moment(Date.now())
          .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
          .format("YYYY-MM-DD");
        postData.end_date = moment(Date.now())
          .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
          .format("YYYY-MM-DD");
      } else {
        postData.start_date = moment(filterDates.from_date).format(
          "YYYY-MM-DD"
        );
        postData.end_date = moment(filterDates.to_date).format("YYYY-MM-DD");
      }
    } else {
      if (reset == "reset") {
        postData = {
          start_date: moment(Date.now())
            .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
            .format("YYYY-MM-DD"),
          end_date: moment(Date.now())
            .tz(defaultTimeZone ? defaultTimeZone : "Europe/Dublin")
            .format("YYYY-MM-DD"),
          record_type: tab_name,
        };
      } else {
        postData = {
          start_date: moment(filterDates.from_date).format("YYYY-MM-DD"),
          end_date: moment(filterDates.to_date).format("YYYY-MM-DD"),
          record_type: tab_name,
        };
      }
    }
    const result = await history_api_api(
      postData,
      searchText,
      page,
      rowsPerPage
    );
    if (result.code == 200) {
      let chipData = {};
      chipData = { ...data };
      if (tab_name == "transaction") {
        if (chipData.transaction_type == "all") {
          delete chipData.transaction_type;
        }
        if (chipData.transaction_for == "all") {
          delete chipData.transaction_for;
          delete chipData.user_id;
        }
        if (chipData.transaction_for == "public") {
          chipData.transaction_for = "Public User";
        }
        if (chipData.transaction_for == "delegates") {
          chipData.transaction_for = "Delegates User";
        }
        if (chipData.transaction_type !== "sale_page") {
          delete chipData.sale_page;
          delete chipData.payment_plan;
        }
        if (chipData.transaction_type !== "payment_request") {
          delete chipData.payment_request;
        }
        delete chipData.record_type;
        setFilterStateUpdated(chipData);
      } else if (tab_name == "orders") {
        setFilterStateUpdatedOrder(chipData);
      } else if (tab_name == "bookings") {
        setFilterStateUpdatedBooking(chipData);
      } else if (tab_name == "subscriptions") {
        if (chipData.transaction_type !== "sale_page") {
          delete chipData.sale_page;
          delete chipData.payment_plan;
          delete chipData.plan_id;
          delete chipData.page_id;
        }
        if (chipData.transaction_type !== "payment_request") {
          delete chipData.payment_request;
        }
        if (chipData.expired !== "expired") {
          let expiry_name = "";
          if (chipData.expiry_in !== "custom") {
            if (chipData.expiry_in) {
              expiry_name = `Expiry In ${chipData.expiry_in} Days`;
            }
          } else {
            expiry_name = `Start Date : ${dd_date_format(
              chipData.next_invoice_date_from
            )} End Date : ${dd_date_format(chipData.next_invoice_date_to)}`;
          }

          chipData.expiry_name = expiry_name;
        }
        delete chipData.next_invoice_date_from;
        delete chipData.next_invoice_date_to;

        setFilterDataSubscriptionsUpdated(chipData);
      } else if (tab_name == "subscribers") {
        setFilterDataSubscribersUpdate(chipData);
      } else {
      }
      setTabData(result.data);
      setStats(result.stats);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_records);

      setFilterQuery(result.query);

      setFilterDrawerState(false);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABS_OPTIONS = [
    {
      title: "Users",
      component: (
        <div>
          <MemberHistory
            tabData={tabData}
            searchFunction={searchFunction}
            searchText={searchText}
            setSearchText={setSearchText}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            filterQuery={filterQuery}
          />
        </div>
      ),
    },
    {
      title: "transactions",
      component: (
        <div>
          <MemberTransactionsHoistory
            tabData={tabData}
            searchFunction={searchFunction}
            searchText={searchText}
            setSearchText={setSearchText}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            filterStateUpdated={filterStateUpdated}
            filterData={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            ALTER_FILTER={ALTER_FILTER}
            handleDeleteChip={handleDeleteChip}
            handleClearFilter={handleClearFilter}
            handleOpenFilterDrawer={handleOpenFilterDrawer}
            filterQuery={filterQuery}
          />
        </div>
      ),
    },
    {
      title: "Subscriptions",
      component: (
        <div>
          <MemberSubscriptionsHistory
            tabData={tabData}
            searchFunction={searchFunction}
            searchText={searchText}
            setSearchText={setSearchText}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            filterStateUpdated={filterDataSubscriptionsUpdated}
            filterData={filterDataSubscriptions}
            EMPTY_FILTER={EMPTY_FILTER_SUBSCRIPTIONS}
            ALTER_FILTER={ALTER_FILTER_SUBSCRIPTIONS}
            handleDeleteChip={handleDeleteChip}
            handleClearFilter={handleClearFilter}
            handleOpenFilterDrawer={handleOpenFilterDrawer}
            filterQuery={filterQuery}
          />
        </div>
      ),
    },
    {
      title: "Subscribers",
      component: (
        <div>
          <MemberSubscribersHistory
            tabData={tabData}
            searchFunction={searchFunction}
            searchText={searchText}
            setSearchText={setSearchText}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            filterStateUpdated={filterDataSubscribersUpdate}
            filterData={filterDataSubscribers}
            EMPTY_FILTER={EMPTY_FILTER_SUBSCRIBERS}
            ALTER_FILTER={ALTER_FILTER_SUBSCRIPTIONS}
            handleDeleteChip={handleDeleteChip}
            handleClearFilter={handleClearFilter}
            handleOpenFilterDrawer={handleOpenFilterDrawer}
            filterQuery={filterQuery}
            setFilterQuery={setFilterQuery}
          />
        </div>
      ),
    },
    {
      title: "bookings",
      component: (
        <div>
          <BookingHistory
            tabData={tabData}
            searchFunction={searchFunction}
            searchText={searchText}
            setSearchText={setSearchText}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            filterStateUpdated={filterStateUpdatedBooking}
            filterData={filterBooking}
            EMPTY_FILTER={EMPTY_FILTER_BOOKSING}
            handleDeleteChip={handleDeleteChip}
            handleClearFilter={handleClearFilter}
            handleOpenFilterDrawer={handleOpenFilterDrawer}
            filterQuery={filterQuery}
          />
        </div>
      ),
    },

    {
      title: "orders",
      component: (
        <div>
          <OrderHistory
            tabData={tabData}
            searchFunction={searchFunction}
            searchText={searchText}
            setSearchText={setSearchText}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            filterStateUpdated={filterStateUpdatedOrder}
            filterData={filterDataOrder}
            EMPTY_FILTER={EMPTY_FILTER_ORDER}
            handleDeleteChip={handleDeleteChip}
            handleClearFilter={handleClearFilter}
            handleOpenFilterDrawer={handleOpenFilterDrawer}
          />
        </div>
      ),
    },
  ];
  useEffect(() => {
    getRoadMapList(
      tabValue == 5
        ? filterDataOrder
        : tabValue == 4
        ? filterBooking
        : tabValue == 2
        ? filterDataSubscriptions
        : tabValue == 3
        ? filterDataSubscribers
        : filterData
    );
  }, [tabValue, rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-5">
          <h2>History</h2>
        </div>{" "}
        <div className="col-lg-3 pe-0">
          <div className="">
            <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
              <DatePicker
                label="From Date"
                name="to_date"
                format="DD-MM-YYYY"
                inputFormat="DD-MM-YYYY"
                value={filterDates.from_date}
                onChange={(e) => handleChangeOthers(e, "from_date")}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    className="mt-3 inputs-fields"
                    required
                  />
                )}
              />
            </LocalizationProvider>
          </div>{" "}
        </div>{" "}
        <div className="col-lg-4">
          <div className="d-flex">
            <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
              <DatePicker
                label="To Date"
                name="to_date"
                format="DD-MM-YYYY"
                inputFormat="DD-MM-YYYY"
                value={filterDates.to_date}
                onChange={(e) => handleChangeOthers(e, "to_date")}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    className="mt-3 inputs-fields"
                    required
                  />
                )}
              />
            </LocalizationProvider>
            <Tooltip title="Filter By Dates">
              <button
                className="small-contained-button-filter ms-2 mt-3 "
                onClick={filterDataButton}
              >
                <Icon icon={roundFilterList} />
              </button>
            </Tooltip>{" "}
            <Tooltip title="Reset Dates Filter">
              <button
                className="small-contained-button-filter ms-2 mt-3 "
                onClick={filterDataButtonReset}
              >
                <RotateLeftIcon />
              </button>
            </Tooltip>
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={Stats.member_count}
              currency={false}
              title=" Users"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers
              currency={false}
              members={Stats.subscription_count}
              title={"Subscriptions"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders programs={Stats.total_bookings} title="bookings" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports
              lesson={Stats.transaction_count}
              title="Transactions"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers
              currency={false}
              members={Stats.event_subscriber_count}
              title={"Subscribers"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppOrderSales
              total={Stats.order_count}
              currency={false}
              title=" Orders"
            />
          </Grid>{" "}
          <Grid item xs={12} sm={6} md={3}>
            <AppIncomeSales
              total={Stats.total_income}
              currency={true}
              title=" Generated Income"
            />
          </Grid>
        </Grid>
        <div className="mt-4">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          tabValue == 5 ? (
            <OrderHistoryFilter
              handleChange={handleChangeValues}
              inputs={filterDataOrder}
              setInputs={setFilterDataOrder}
              searchSubmitFilter={searchFunction}
              handleClearFilter={handleClearFilter}
            />
          ) : tabValue == 4 ? (
            <BookingFilter
              handleChaneOthers={handleChaneBookingStatus}
              filterData={filterBooking}
              handleSelectOther={handleSelectOtherBookingStatus}
              searchFunction={searchFunction}
              handleClearFilter={handleClearFilter}
              Filter_from="history"
            />
          ) : tabValue == 2 ? (
            <SubscriptionsFilterHistory
              filterData={filterDataSubscriptions}
              // isAllDates={isAllDates}
              // setIsAllDates={setIsAllDates}
              handleClearFilter={handleClearFilter}
              handleChangeOthers={handleChangeDataSubscriptions}
              handleChangeOther={handleChangeOtherForExpire}
              searchSubmitFilter={searchFunction}
            />
          ) : tabValue == 3 ? (
            <SubscribersFilterHistory
              filterData={filterDataSubscribers}
              // isAllDates={isAllDates}
              // setIsAllDates={setIsAllDates}
              handleClearFilter={handleClearFilter}
              handleChangeOthers={handleChangeDataSubsribers}
              searchSubmitFilter={searchFunction}
            />
          ) : (
            <MemberTransactionsFilterHistory
              filterData={filterData}
              isAllDates={isAllDates}
              setIsAllDates={setIsAllDates}
              handleClearFilter={handleClearFilter}
              handleChangeOthers={handleChangeData}
              searchSubmitFilter={searchFunction}
            />
          )
        }
      />
    </div>
  );
}
