import * as React from "react";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress, TextField } from "@mui/material";
import {
  add_Commission_configuration,
  add_agreeement_configuration,
} from "src/DAL/WebsitePages/WebsitePages";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  detailPaymentPlanApi,
  detailPaymentPlanConfigurationApi,
} from "src/DAL/WebsitePages/paymentPlan";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CommissionConfigurationPaymentPlan() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [planTitle, setPlanTitle] = useState("");
  const [inputs, setInputs] = useState({
    payment_access: "onetime",
    plan_price: "",
    initial_amount: "",
    commission_info: {
      public_user_commission_amount: 0,
      public_user_commission_amount_for_first_transaction: 0,
      public_user_commission_amount_for_next_transaction: 0,
      associate_user_commission_amount: 0,
      associate_user_commission_amount_for_first_transaction: 0,
      associate_user_commission_amount_for_next_transaction: 0,
      delegate_user_commission_amount: 0,
      delegate_user_commission_amount_for_first_transaction: 0,
      delegate_user_commission_amount_for_next_transaction: 0,
      dynamite_digital_user_commission_amount: 0,
      dynamite_digital_user_commission_amount_for_first_transaction: 0,
      dynamite_digital_user_commission_amount_for_next_transaction: 0,
      inner_circle_commission_amount: 0,
      marketing_team_commission_amount: 0,
      inner_circle_commission_amount_for_first_transaction: 0,
      inner_circle_commission_amount_for_next_transaction: 0,
      marketing_team_commission_amount_for_first_transaction: 0,
      marketing_team_commission_amount_for_next_transaction: 0,
    },
  });
  const handleChangeCommission = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    value = parseFloat(value);

    if (value < 0) {
      value = 0;
    }

    setInputs((values) => ({
      ...values,
      commission_info: { ...values.commission_info, [name]: value },
    }));
  };
  const getPlanDetail = async () => {
    setIsLoading(true);
    const result = await detailPaymentPlanConfigurationApi(params.plan_id);
    if (result.code == 200) {
      console.log(result, "resultresultresult");
      setPlanTitle(result?.commission_info.plan_title);
      let commission_info = result?.commission_info?.commission_info;
      if (commission_info && Object.keys(commission_info).length > 0) {
        setInputs({
          ...inputs,
          ["payment_access"]: result?.commission_info?.payment_access,
          ["plan_price"]: result?.commission_info?.plan_price,
          ["initial_amount"]: result?.commission_info?.initial_amount,
          ["commission_info"]: result?.commission_info?.commission_info,
        });
      } else {
        setInputs({
          ...inputs,
          ["payment_access"]: result?.commission_info?.payment_access,
          ["plan_price"]: result?.commission_info?.plan_price,
          ["initial_amount"]: result?.commission_info?.initial_amount,
        });
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.payment_access == "recursion") {
      var maxCommission = Math.max(
        inputs.commission_info
          .public_user_commission_amount_for_first_transaction,
        inputs.commission_info
          .associate_user_commission_amount_for_first_transaction,
        inputs.commission_info
          .delegate_user_commission_amount_for_first_transaction,
        inputs.commission_info
          .inner_circle_commission_amount_for_first_transaction,
        inputs.commission_info
          .marketing_team_commission_amount_for_first_transaction
      );
      var maxCommissionForNext = Math.max(
        inputs.commission_info
          .public_user_commission_amount_for_next_transaction,
        inputs.commission_info
          .associate_user_commission_amount_for_next_transaction,
        inputs.commission_info
          .delegate_user_commission_amount_for_next_transaction,
        inputs.commission_info
          .inner_circle_commission_amount_for_next_transaction,
        inputs.commission_info
          .marketing_team_commission_amount_for_next_transaction
      );

      let totalCommission =
        parseInt(maxCommission) +
        parseInt(
          inputs.commission_info
            .dynamite_digital_user_commission_amount_for_first_transaction
        );
      let totalCommissionForNext =
        parseInt(maxCommissionForNext) +
        parseInt(
          inputs.commission_info
            .dynamite_digital_user_commission_amount_for_next_transaction
        );

      if (totalCommission > inputs.initial_amount) {
        enqueueSnackbar(
          "Commission amount cannot be greater than initial amount",
          { variant: "error" }
        );
        setIsLoading(false);
        return;
      }

      if (totalCommissionForNext > parseInt(inputs.installment_amount)) {
        enqueueSnackbar(
          "Commission amount for next transaction cannot be greater than installment amount",
          { variant: "error" }
        );
        setIsLoading(false);
        return;
      }
    }

    if (inputs.payment_access == "onetime") {
      var maxCommission = Math.max(
        inputs.commission_info.public_user_commission_amount,
        inputs.commission_info.associate_user_commission_amount,
        inputs.commission_info.delegate_user_commission_amount,
        inputs.commission_info.inner_circle_commission_amount,
        inputs.commission_info.marketing_team_commission_amount
      );
      let totalCommission =
        maxCommission +
        parseInt(
          inputs.commission_info.dynamite_digital_user_commission_amount
        );

      if (totalCommission > parseInt(inputs.plan_price)) {
        enqueueSnackbar("Commission amount cannot be greater than plan price", {
          variant: "error",
        });
        setIsLoading(false);
        return;
      }
    }
    setIsLoadingForm(true);
    let postData = {
      commission_info: inputs.commission_info,
    };

    const result = await add_Commission_configuration(params.plan_id, postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    getPlanDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const websitePages = window.location.pathname.includes("/website-pages");

  let breadCrumbMenu = [
    {
      title: websitePages ? "Pages" : "Template Pages",
      navigation: `${websitePages ? `/website-pages` : `/template-pages`}`,
      active: false,
    },
    {
      title: "Payment Plans",
      navigation: `${
        websitePages
          ? `/website-pages/${params?.page_id}/payment-plans`
          : `/template-pages/${params?.page_id}/payment-plans`
      }`,
      active: false,
    },
    {
      title: planTitle,
      active: true,
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Commission Configuration</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {inputs.payment_access == "recursion" ? (
            <>
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Inner Circle Commission Amount for first recursion"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="inner_circle_commission_amount_for_first_transaction"
                      value={
                        inputs.commission_info
                          .inner_circle_commission_amount_for_first_transaction
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Inner Circle Commission Amount for next recursion"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="inner_circle_commission_amount_for_next_transaction"
                      value={
                        inputs.commission_info
                          .inner_circle_commission_amount_for_next_transaction
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>{" "}
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Consultant User Commission Amount for first recursion"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="associate_user_commission_amount_for_first_transaction"
                      value={
                        inputs.commission_info
                          .associate_user_commission_amount_for_first_transaction
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Consultant User Commission Amount for next recursion"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="associate_user_commission_amount_for_next_transaction"
                      value={
                        inputs.commission_info
                          .associate_user_commission_amount_for_next_transaction
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Delegate User Commission Amount for first recursion"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="delegate_user_commission_amount_for_first_transaction"
                      value={
                        inputs.commission_info
                          .delegate_user_commission_amount_for_first_transaction
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Delegate User Commission Amount for next recursion"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="delegate_user_commission_amount_for_next_transaction"
                      value={
                        inputs.commission_info
                          .delegate_user_commission_amount_for_next_transaction
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>{" "}
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Marketing Team Commission Amount for first recursion"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="marketing_team_commission_amount_for_first_transaction"
                      value={
                        inputs.commission_info
                          .marketing_team_commission_amount_for_first_transaction
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Marketing Team Commission Amount for next recursion"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="marketing_team_commission_amount_for_next_transaction"
                      value={
                        inputs.commission_info
                          .marketing_team_commission_amount_for_next_transaction
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Team Diego  Commission Amount for first recursion"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="dynamite_digital_user_commission_amount_for_first_transaction"
                      value={
                        inputs.commission_info
                          .dynamite_digital_user_commission_amount_for_first_transaction
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Team Diego  Commission Amount for next recursion"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="dynamite_digital_user_commission_amount_for_next_transaction"
                      value={
                        inputs.commission_info
                          .dynamite_digital_user_commission_amount_for_next_transaction
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Public User Commission Amount for first recursion"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="public_user_commission_amount_for_first_transaction"
                      value={
                        inputs.commission_info
                          .public_user_commission_amount_for_first_transaction
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Public User Commission Amount for next recursion"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="public_user_commission_amount_for_next_transaction"
                      value={
                        inputs.commission_info
                          .public_user_commission_amount_for_next_transaction
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* <div className="col-6 d-none d-md-block mt-4"></div> */}
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Inner Circle Commission Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="inner_circle_commission_amount"
                  value={inputs.commission_info.inner_circle_commission_amount}
                  onChange={handleChangeCommission}
                />
              </div>{" "}
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Consultant User Commission Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="associate_user_commission_amount"
                  value={
                    inputs.commission_info.associate_user_commission_amount
                  }
                  onChange={handleChangeCommission}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Delegate User Commission Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="delegate_user_commission_amount"
                  value={inputs.commission_info.delegate_user_commission_amount}
                  onChange={handleChangeCommission}
                />
              </div>{" "}
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Marketing Team Commission Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="marketing_team_commission_amount"
                  value={
                    inputs.commission_info.marketing_team_commission_amount
                  }
                  onChange={handleChangeCommission}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Team Diego  Commission Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="dynamite_digital_user_commission_amount"
                  value={
                    inputs.commission_info
                      .dynamite_digital_user_commission_amount
                  }
                  onChange={handleChangeCommission}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Public User Commission Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="public_user_commission_amount"
                  value={inputs.commission_info.public_user_commission_amount}
                  onChange={handleChangeCommission}
                />
              </div>
            </>
          )}
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
