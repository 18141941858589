import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  checkiInTicketsApi,
  delete_checked_in_api,
  giftDelieverdApi,
} from "src/DAL/Transaction/transactionApi";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import { download_csv_file } from "src/utils/convertHtml";
import moment from "moment";
import BillingFilter from "./BillingFilter";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import CheckInpopup from "./CheckInpopup";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import FilteredChip from "src/components/FilteredChip";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";

const headers = [
  { key: "Name", label: "Name" },
  { key: "email", label: "Email" },
  { key: "contact_number", label: "Phone" },
  { key: "venue_title", label: "Venue" },
  { key: "member", label: "Purchased By" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
let member_options = [
  {
    label: "First name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Last name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true, is_disabled: true },
  {
    label: "Contact Number",
    value: "contact_number",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Sale Page",
    value: "sale_page",
    is_checked: false,
    is_disabled: false,
  },
  {
    label: "Checked In Time",
    value: "checkin_date_time",
    is_checked: false,
    is_disabled: false,
  },
];

export default function BillingCheckedIn() {
  const classes = useStyles();
  const [ticketsList, setTicketsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchText, setSearchText] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [checkInOpen, setCheckInOpen] = useState(false);
  const [selectedObject, setSelectedObject] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };

  const EMPTY_FILTER = {
    plan: null,
    sale_page: null,
    checkin_date_time: null,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("checkin_tickets_data", JSON.stringify(filterData));
    getTicketsListing(filterData);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    setFilterData(data);
    getTicketsListing(data);
    localStorage.setItem("checkin_tickets_data", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    getTicketsListing(EMPTY_FILTER);
    localStorage.removeItem("checkin_tickets_data");
  };

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handle_click_check_box = async (e, row, index) => {
    let postData = {
      billing_ticket_id: row._id,
      gift_pack_deliverd: !row.gift_pack_deliverd,
    };
    const result = await giftDelieverdApi(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getTicketsListing("stop");
    }
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      alignRight: false,
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    {
      id: "gift_pack_deliverd",
      label: "Gift Delivered ",
      type: "checkbox",
      handleClick: (e, row, index) => {
        handle_click_check_box(e, row, index);
      },
    },
    { id: "number", label: "#", type: "number" },
    { id: "Name", label: "Name" },
    { id: "PageName", label: "Sale Page" },
    {
      id: "CheckInTime",
      label: "Check In Time",
    },
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCheckInPopup = (value) => {
    setCheckInOpen(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const getTicketsListing = async (filter_data) => {
    setIsLoading(true);
    let postData = { ...filter_data };

    if (postData.sale_page) {
      postData.sale_page = filter_data.sale_page._id;
    }
    if (postData.plan) {
      postData.plan = filter_data.plan._id;
    }
    if (postData.checkin_date_time) {
      postData.checkin_date_time = moment(filter_data.checkin_date_time).format(
        "YYYY-MM-DD"
      );
    }

    handleCloseFilterDrawer();
    const result = await checkiInTicketsApi(
      page,
      rowsPerPage,
      postData,
      searchText
    );
    if (result.code === 200) {
      let chipData = { ...filter_data };
      if (chipData.sale_page == null) {
        delete chipData.plan;
      }

      setUpdateFilterData(chipData);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages - 1);
      let data = [];
      if (result.checkin_stats.length > 0) {
        data = result.checkin_stats.map((user) => {
          let user_name = "N/A";
          let main_user = "N/A";
          if (user.firstName) {
            user_name =
              user.ticket_info.firstName + " " + user.ticket_info.lastName;
          } else if (user.ticket_info.first_name) {
            user_name =
              user.ticket_info.first_name + " " + user.ticket_info.last_name;
          }

          if (user.ticket_info.purchase_by) {
            main_user =
              user?.ticket_info?.purchase_by?.first_name +
              " " +
              user?.ticket_info?.purchase_by?.last_name +
              " " +
              "(" +
              user?.ticket_info?.purchase_by?.email +
              ")";
          }
          return {
            ...user,
            venue_title: user.ticket_info?.venue_id?.title
              ? user?.ticket_info?.venue_id?.title
              : "N/A",
            Name: user_name + " (" + user?.ticket_info?.email + ")",
            contact_number: user?.ticket_info?.contact_number
              ? user?.ticket_info?.contact_number
              : "N/A",
            CheckInTime: user?.checkin_date_time
              ? moment(user?.checkin_date_time).format("DD-MM-YYYY hh:mm A")
              : "N/A",
            PageName:
              user?.ticket_info?.sale_page?.sale_page_title +
              " (" +
              user?.ticket_info?.plan?.plan_title +
              ")",
            member: main_user,
            MENU_OPTIONS: MENU_OPTIONS,
          };
        });
      }
      setFilterQuery(result.query);
      setTicketsList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleAgreeDelete = (value) => {
    setSelectedObject(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_checked_in_api(selectedObject._id);
    if (result.code === 200) {
      setTicketsList((prev) => {
        return prev.filter((data) => data._id !== selectedObject._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleExportClick = () => {
    download_csv_file(headers, ticketsList);
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("checkin_tickets_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getTicketsListing(filter_data);
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-lg-6 col-sm-12">
            <h2>Check In</h2>
          </div>
          <div className="col-lg-6 mb-3 text-end">
            <button
              className="small-contained-button me-3"
              onClick={handleCheckInPopup}
            >
              Check-In
            </button>
            <button
              className="small-contained-button me-3"
              onClick={showPopUPcsv}
            >
              Request For CSV &nbsp;&nbsp;
              <Icon icon={arrowCircleDownFill} height={20} />
            </button>
            <button
              className="small-contained-button me-3"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        <FilteredChip
          data={updateFilterData}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={ticketsList}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
      <GeneralPopUpModel
        open={checkInOpen}
        setOpen={setCheckInOpen}
        title={"Check-In"}
        componentToPassDown={
          <CheckInpopup
            selectedObject={selectedObject}
            setSelectedObject={setSelectedObject}
            setOpen={setCheckInOpen}
            reload={getTicketsListing}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <BillingFilter
            datePickerField={true}
            filterData={filterData}
            searchFunction={searchFunction}
            handleSelectOther={handleSelectOther}
            handleClearFilter={handleClearFilter}
          />
        }
      />
      <GeneralPopUpModel
        open={showExportcsvFile}
        setOpen={setShowExportcsvFile}
        title={"Export CSV file request  "}
        componentToPassDown={
          <GeneralRequestForCsv
            options_array={member_options}
            collection_name={"checked_in"}
            filter_data={filterQuery}
            setShowExportcsvFile={setShowExportcsvFile}
          />
        }
      />
    </>
  );
}
