import React, { useEffect, useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { member_listing_by_consultant } from "src/DAL/consultant/consultant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DelegateMembers({ tabData }) {
  const { enqueueSnackbar } = useSnackbar();
  const parames = useParams();

  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [membersList, setMembersList] = useState([]);
  const [consultantData, setConsultantData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };
  const getMemberListing = async () => {
    const members = tabData?.map((member, index) => {
      return {
        ...member,
        id: member._id,
        name: member.first_name + " " + member.last_name,
        status: member.status,
        email: member.email,
        contact_number: member.contact_number,
        table_avatar: {
          src: s3baseUrl + member.profile_image,
          alt: member.first_name,
        },
      };
    });

    setMembersList(members);
  };
  const getMemberListingSearch = async () => {
    setIsLoading(true);
    const result = await member_listing_by_consultant(
      0,
      rowsPerPage,
      searchText,
      parames.id
    );

    if (result.code == 200) {
      setConsultantData(result.consultant);
      setTotalPages(result.total_pages);
      const members = result.members?.map((member, index) => {
        return {
          ...member,
          id: member._id,
          name: member.first_name + " " + member.last_name,
          status: member.status,
          email: member.email,
          contact_number: member.contact_number,
          table_avatar: {
            src: s3baseUrl + member.profile_image,
            alt: member.first_name,
          },
        };
      });

      setMembersList(members);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  useEffect(() => {
    getMemberListing();
  }, [tabData]);

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Profile",
      alignRight: false,
      type: "thumbnail",
    },
    { id: "name", label: "Name", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
    { id: "contact_number", label: "Phone", alignRight: false },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
    { id: "register_url", label: "Register Url", alignRight: false },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row"></div>
        <div className="row">
          <div className="col-lg-8 col-sm-8 mb-3 mt-3">
            <h2>Affiliated Members </h2>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={membersList}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
