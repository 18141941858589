import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { event_listing_by_consultant } from "src/DAL/consultant/consultant";
import GeneralCalendar from "src/components/GeneralComponents/GeneralCalendar";
import {
  difference_between_two_dates,
  get_view_dates,
} from "src/utils/constant";
import { event_listing_by_member } from "src/DAL/member/member";

export default function () {
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [useInfo, setuseInfo] = useState();
  const navigate = useNavigate();
  const [defaultTimeZone, setDefaultTimeZone] = useState("");
  const params = useParams();
  const getEventListing = async () => {
    let api_start_date = moment(currentDate.start_date).format("YYYY-MM-DD");
    let api_end_date = moment(currentDate.end_date).format("YYYY-MM-DD");
    const difference = difference_between_two_dates(
      api_start_date,
      api_end_date,
      "days"
    );

    if (difference < 10) {
      setIsLoading(true);
    }

    const result = await event_listing_by_member(
      api_start_date,
      api_end_date,
      params.id
    );
    if (result.code === 200) {
      setuseInfo(result?.member);
      setEvents(result.events);
      setDefaultTimeZone(result.time_zone);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEventListing();
  }, [currentDate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-12 mb-3 reply-anchor">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            {useInfo && (
              <span className="sale-page-title">
                {useInfo?.first_name +
                  " " +
                  useInfo?.last_name +
                  " (" +
                  useInfo?.email +
                  ")"}
              </span>
            )}
          </div>
        </div>
      </div>
      <GeneralCalendar
        eventList={events}
        setCurrentDate={setCurrentDate}
        showAddEvent={false}
        time_zone={defaultTimeZone}
        currentDate={currentDate}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        user_type="delegate"
        reloadList={getEventListing}
      />
    </>
  );
}
