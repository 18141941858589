import { invokeApi } from "../../bl_libs/invokeApi";

export const email_logs_list_api = async (page, limit, search) => {
  const requestObj = {
    path: `/api/email_logs?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
