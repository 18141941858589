import { invokeApi } from "../../bl_libs/invokeApi";

export const source_session_recording_listing_api = async (
  data,
  search,
  page,
  limit
) => {
  const requestObj = {
    path: `/api/source_session_recording/source_session_recording_list?search_text=${
      search ? search : "all"
    }&page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_source_session_recording_api = async (slug) => {
  const requestObj = {
    path: `/api/source_session_recording/delete_source_session_recording/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_source_session_recording_api = async (data) => {
  const requestObj = {
    path: `/api/source_session_recording/add_source_session_recording`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_source_session_recording_api = async (data, slug) => {
  const requestObj = {
    path: `/api/source_session_recording/source_session_recording_update/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const source_session_recording_detail = async (recording_slug) => {
  const requestObj = {
    path: `/api/source_session_recording/source_session_recording_detail/${recording_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
