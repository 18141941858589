import { filter } from "lodash";
import { Icon } from "@iconify/react";
import CircleIcon from "@mui/icons-material/Circle";

import { useEffect, useState } from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
// import { get_root_value } from "src/utils/domUtils";
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Pagination,
  Chip,
  Tooltip,
} from "@mui/material";

import SearchNotFound from "src/components/SearchNotFound";
import { UserListHead } from "src/components/_dashboard/user";

import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import UserListToolbars from "src/components/_dashboard/user/UserListToolbars";
import { get_root_value } from "src/utils/domUtils";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { convertCurrencyToSign } from "src/utils/constant";
import { transactionListApiNew } from "src/DAL/consultant/consultant";

//
// import USERLIST from "../_mocks_/user";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  //   { id: "name", label: " Name", alignRight: false },

  { id: "amount", label: "Paid Amount", alignRight: false },
  { id: "transactionDate", label: "Transaction Date", alignRight: false },

  // { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.transaction_type.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _user.email?.member?.email
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function CommissionHistoryPaid() {
  const navigate = useNavigate();

  const classes = useStyles();
  const params = useParams();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [affiliate, setAffiliate] = useState();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [member, setData] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [UserId, setUserId] = useState(false);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    title: "All",
    value: "all",
  });
  const [selectedValueUpdated, setSelectedValueUpdated] = useState({
    title: "All",
    value: "all",
  });
  const handleOpen = (e) => {
    setUserId(e);
    setOpen(true);
  };

  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };

  const getConsultantListing = async (value) => {
    setIsLoading(true);

    try {
      setIsLoading(true);
      let postData = {
        type: "paid",
        search_text: filterName ? filterName : "",
      };

      const result = await transactionListApiNew(
        params.id,
        page,
        rowsPerPage,
        postData
      );

      if (result.code == 200) {
        const data = result.transaction?.map((transaction, index) => {
          console.log("here 0");
          return {
            ...transaction,
            id: transaction?._id,
            amount: transaction?.amount,

            transactionDate: transaction?.transaction_date
              ? moment(transaction?.transaction_date).format(
                  "DD-MM-YYYY hh:mm A"
                )
              : "N/A",
            currency: transaction?.currency,

            count: index + 1 + rowsPerPage * page,
          };
        });
        console.log("here 1");
        setUserList(data);
        console.log("here 2");
        setData(result.transaction);
        console.log("here 3");
        setQuestionData(result.transaction);
        console.log("here 4");
        // setAffiliate(result.affiliate);
        setTotalMembers(result.total_member_count);
        setTotalPages(result.total_pages);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleEdit = (value) => {
    navigate(`/consultant/edit-consultant/${value.id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteDoc.id;
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("transaction_page_number", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleSearchName = () => {
    getConsultantListing();
    setPage(0);
    setPageCount(1);
  };
  const handleNavigate = () => {
    navigate(`/consultant/add-consultant`);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  // functions for filter
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleDeleteProgramChips = (value) => {
    setPage(0);
    setPageCount(1);
    value.value = "all";
    value.title = "All";
    setSelectedValue(value);
    setSelectedValueUpdated(value);
    getConsultantListing("empty");
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handleClearFilterall = (value) => {
    setPage(0);
    setPageCount(1);
    value.value = "all";
    value.title = "All";
    setSelectedValue(value);
    setSelectedValueUpdated(value);
    getConsultantListing("empty");
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  useEffect(() => {
    getConsultantListing();
  }, [page, rowsPerPage]);
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid mt-3">
        <Card style={{ overflowX: "auto" }}>
          {/* <div className="row">
            <div className="col-sm-12 col-md-6 d-flex flex-column align-items-start mt-1">
              <TablePagination
                rowsPerPageOptions={[50, 100, 150]}
                component="div"
                count={totalMembers}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Pagination
                count={totalPages + 1}
                page={pageCount}
                defaultPage={0}
                onChange={handleChangePages}
                className="pagination-style"
              />
            </div>
            <div className="col-sm-12 col-md-6 ">
              <UserListToolbars
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                onSearch={handleSearchName}
              />
            </div>
          </div> */}
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.map((row, i) => {
                  const { id, amount, transactionDate, currency, count } = row;
                  const isItemSelected = selected.indexOf(name) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell align="left">{count}</TableCell>
                      <TableCell component="th" scope="row">
                        {convertCurrencyToSign(currency) + amount}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        className="white-space-nowrap"
                      >
                        {transactionDate}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={totalMembers}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Pagination
            count={totalPages + 1}
            page={pageCount}
            defaultPage={0}
            onChange={handleChangePages}
            className="pagination-style"
          />
        </Card>
      </div>
    </>
  );
}
