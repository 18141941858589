import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import {
  badge_list_api,
  delete_bedge_api,
  delete_category_api,
  help_video_categories_list_api,
} from "src/DAL/HelpVideos/Categories";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { memberListingActivity } from "src/DAL/member/member";
import moment from "moment";
import { dd_date_format, show_proper_words } from "src/utils/constant";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function CoinsHistory({ type }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const member_id = useParams();
  const getCategoriesListing = async (text) => {
    setIsLoading(true);
    const result = await memberListingActivity(member_id.id, page, rowsPerPage);
    console.log(result, "resultresultresult");
    if (result.code == 200) {
      const categories = result.coins_history.map((item) => {
        return {
          ...item,

          createdAt: item.createdAt
            ? moment.utc(item.createdAt).format("DD-MM-YYYY hh:mm A")
            : "N/A",
          coins_count_by_action: item.coins_count_by_action
            ? item.coins_count_by_action
            : "N/A",
        };
      });
      setCategoriesData(categories);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_bedge_api(deleteDoc._id);
    if (result.code === 200) {
      setCategoriesData((categoriesData) => {
        return categoriesData.filter((data) => data._id !== deleteDoc._id);
      });
      // getCategoriesListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleEdit = (value) => {
    navigate(`/badges/edit-badge/${value._id}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/badges/add-badge`);
  };
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("bedge_search", searchText);

    getCategoriesListing(searchText);
  };
  useEffect(() => {
    let search_text = localStorage.getItem("bedge_search");
    if (search_text) {
      setSearchText(search_text);
      getCategoriesListing(search_text);
    } else {
      setSearchText("");
      getCategoriesListing("");
    }
  }, [page, rowsPerPage]);
  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },

    {
      id: "activity_type",
      label: "Activity",
      renderData: (row) => {
        return (
          <>
            {row.activity_type == "completed_challenge_reward" ||
            row.activity_type == "daily_challenge_reward" ? (
              <>
                {row.challenge && Object.keys(row.challenge).length > 0 ? (
                  <span>
                    <span>{show_proper_words(row.activity_type)}</span>
                    <span> </span>
                    <span style={{ color: "#f6bd4b" }}>(</span>
                    <span style={{ color: "#f6bd4b" }}>
                      {row.challenge.title}
                    </span>
                    <span style={{ color: "#f6bd4b" }}>)</span>
                  </span>
                ) : (
                  <span>{show_proper_words(row.activity_type)}</span>
                )}
              </>
            ) : (
              <span>{show_proper_words(row.activity_type)}</span>
            )}
          </>
        );
      },
    },
    {
      id: "coins_count_by_action",
      label: "Coins Attracted",
      alignRight: false,
    },
    {
      id: "createdAt",
      label: "Activity Date",
      alignRight: false,
    },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoriesData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          // custom_search={{
          //   searchText: searchText,
          //   setSearchText: setSearchText,
          //   handleSubmit: searchFunction,
          // }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          is_hide={true}
        />
      </div>
    </>
  );
}
