import { invokeApi } from "../../bl_libs/invokeApi";

export const eventListApi = async (slug) => {
  const requestObj = {
    path: `/api/event/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const events_list_api = async (slug) => {
  const requestObj = {
    path: `/api/event/?created_for=${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const events_list_api_with_pagination = async (
  page,
  limit,
  search,
  slug
) => {
  const requestObj = {
    path: `/api/event/event_list_with/pagination_for_admin?page=${page}&limit=${limit}&search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const new_event_list_api = async (data) => {
  const requestObj = {
    path: `/api/event/list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const event_iteration_members_api = async (data) => {
  const requestObj = {
    path: `/api/event/update/event_iteration_members`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const calendar_event_members_api = async (page, limit, search, data) => {
  const requestObj = {
    path: `/api/event/event_member_list?search_text=${search}&page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addEventApi = async (data) => {
  const requestObj = {
    path: `/api/event/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_new_event_api = async (data) => {
  const requestObj = {
    path: `/api/event/add/by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editEventApi = async (data, slug) => {
  const requestObj = {
    path: `/api/event/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteEventApi = async (slug) => {
  const requestObj = {
    path: `/api/event/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delegate_event = async (slug) => {
  const requestObj = {
    path: `/api/delegate_event/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_event_api = async (data) => {
  const requestObj = {
    path: `/api/event/delete`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_member_event_api = async (data, event_slug) => {
  const requestObj = {
    path: `/api/event/update/by_admin/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_event_iteration_api = async (data, event_slug) => {
  const requestObj = {
    path: `/api/event/event_iteration/update/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const eventDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/event/detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const event_detail_list_api_v1 = async (
  slug,
  type,
  page,
  limit,
  search
) => {
  const requestObj = {
    path: `/api/event/detail/v1/${slug}?type=${type}&page=${page}&limit=${limit}&search_text=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
