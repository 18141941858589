import React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { add_funnel_api, edit_funnel_api } from "src/DAL/FunnelPage/FunnelPage";

export default function AddOrUpdateFunnel({
  setOpenfilter,
  notificationArray,
  setNotificationArray,
  funnelTitle,
  setFunnelTitle,
  formType,
  setUserList,
  userList,
  edit_id,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveNotification = (index) => {
    const updatedItems = [...notificationArray];
    updatedItems.splice(index, 1);
    setNotificationArray(updatedItems);
  };

  const handleAddNotification = () => {
    const newNotification = {
      title: "",
      fieldName: "pageTitle",
      matchType: "contains",
      page_name: "",
    };
    setNotificationArray((old_array) => [...old_array, newNotification]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data_array = notificationArray.map((item) => {
      return {
        name: item.title,
        filterExpression: {
          andGroup: {
            expressions: [
              {
                funnelFieldFilter: {
                  fieldName: item.fieldName,
                  stringFilter: {
                    matchType: item.matchType,
                    value: item.page_name,
                  },
                },
              },
            ],
          },
        },
      };
    });
    let postData = {
      title: funnelTitle,
      funnelSteps: data_array,
    };
    setIsLoading(true);
    const result =
      formType == "ADD"
        ? await add_funnel_api(postData)
        : await edit_funnel_api(postData, edit_id);
    if (result.code === 200) {
      if (formType == "ADD") {
        setUserList([result.report, ...userList]);
      } else {
        setUserList(
          userList.map((item) => (item._id === edit_id ? result.report : item))
        );
      }
      setOpenfilter(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeNotification = (event, index) => {
    const { name, value } = event.target;
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[index] };
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setNotificationArray(temp_state);
  };

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 card">
            <div
              className="col-12 col-md-6 mt-3"
              style={{
                marginLeft: "20px",
              }}
            >
              <TextField
                className="w-100"
                id="outlined-basic"
                label="Funnel Title"
                variant="outlined"
                name="title"
                value={funnelTitle}
                required={true}
                onChange={(e) => {
                  setFunnelTitle(e.target.value);
                }}
              />
            </div>
            {notificationArray.length > 0 &&
              notificationArray.map((questions, index) => {
                return (
                  <>
                    <div className="row my-3 px-0 px-md-2">
                      <div className="col-6 mb-3 index_style d-flex align-items-center">
                        <span>{index + 1}</span>
                        <h4 className="mb-0 ms-2 text-white">
                          Step {index + 1}
                        </h4>
                      </div>
                      <div className="col-6 text-end mb-3 d-flex justify-content-end">
                        {notificationArray.length > 2 && (
                          <RemoveCircleOutlineIcon
                            className="duplicate-icon"
                            onClick={() => handleRemoveNotification(index)}
                          />
                        )}
                        <AddCircleOutlineIcon
                          className="duplicate-icon ms-2"
                          onClick={handleAddNotification}
                        />
                      </div>
                      <div className="col-12">
                        <div className="row border_left ">
                          <div className="col-12 col-md-6 ">
                            <TextField
                              className="w-100"
                              id="outlined-basic"
                              label="Step Title"
                              variant="outlined"
                              name="title"
                              value={questions.title}
                              required={true}
                              onChange={(e) => {
                                handleChangeNotification(e, index);
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <FormControl fullWidth required>
                              <InputLabel id="demo-simple-select-label">
                                Page / Screen
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="fieldName"
                                label="Page / Screen"
                                value={questions.fieldName}
                                onChange={(e) => {
                                  handleChangeNotification(e, index);
                                }}
                              >
                                <MenuItem value="pageTitle">
                                  Page Title
                                </MenuItem>
                                <MenuItem value="pageLocation">
                                  Page Location
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-12 col-md-6 mt-3">
                            <FormControl fullWidth required>
                              <InputLabel id="demo-simple-select-label">
                                Condition
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="matchType"
                                label="Condition"
                                value={questions.matchType}
                                onChange={(e) => {
                                  handleChangeNotification(e, index);
                                }}
                              >
                                <MenuItem value="contains">Contains</MenuItem>
                                <MenuItem value="EXACT">
                                  Exactly Matches (=)
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-12 col-md-6 mt-3">
                            <TextField
                              className="w-100"
                              id="outlined-basic"
                              label={
                                questions.fieldName == "pageLocation"
                                  ? "Page Url"
                                  : "Page Title"
                              }
                              type={
                                questions.fieldName == "pageLocation"
                                  ? "url"
                                  : "text"
                              }
                              variant="outlined"
                              name="page_name"
                              value={questions.page_name}
                              required={true}
                              onChange={(e) => {
                                handleChangeNotification(e, index);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          <div className="text-end mt-4 mb-3">
            <button className="small-contained-button">
              {isLoading
                ? "Loading..."
                : formType == "ADD"
                ? "Apply"
                : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
