import React, { useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
// import { invokeApi, s3baseUrl } from "../../bl_libs/invokeApi";
import { invokeApi } from "src/bl_libs/invokeApi";
import { s3baseUrl } from "src/config/config";

export default function TinyEditor({
  setDetailDescription,
  handleSubmit,
  detailDescriptionCk,
  editorHeight,
}) {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      setDetailDescription(editorRef.current.getContent());
    }
  };
  function example_image_upload_handler(blobInfo, success, failure, progress) {
    // console.log("inside image upoad function");
    // console.log(blobInfo.blob());
    let requestObj = {
      path: "/app/update_image_on_s3/",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let _formData = new FormData();
    _formData.append("image", blobInfo.blob());
    _formData.append("width", "2200");
    requestObj["postData"] = _formData;
    // console.log(..._formData);

    invokeApi(requestObj).then((res) => {
      // console.log(res, "IMAGE UPLOADER");
      if (res.code === 200) {
        success(s3baseUrl + res.image_path);
      } else {
        failure(res.message);
      }
    });
    // console.log(requestObj, "requestObj");
  }
  const textToHtml = (text) => {
    const elem = document.createElement("div");
    return text
      .split(/\n\n+/)
      .map((paragraph) => {
        return (
          "<p>" +
          paragraph
            .split(/\n+/)
            .map((line) => {
              elem.textContent = line;
              return elem.innerHTML;
            })
            .join("<br/>") +
          "</p>"
        );
      })
      .join("");
  };

  return (
    <>
      <Editor
        // apiKey={"hm3t5iw3lscjhs7arh2cnw2u2pm956ma89yqtt96pzjr18wl"}
        // apiKey="962mqhud1p9g91fm8y3pqb59hwrr3fsm10wpaj705zfgywy5"
        apiKey="5xjmu294547idygquer2hqitmlm7drpq3stxc3cs5vkp0gso"
        onChange={log}
        value={detailDescriptionCk}
        onInit={(evt, editor) => (editorRef.current = editor)}
        onEditorChange={(newValue, editor) => {
          //   setDetailDescription(newValue);
          setDetailDescription(editor.getContent());
        }}
        init={{
          images_upload_handler: example_image_upload_handler,
          height: editorHeight ? editorHeight : 500,
          menubar: true,
          // skin: "oxide-dark",
          // content_css: "dark",
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      {/* <button onClick={log}>Log editor content</button> */}
    </>
  );
}
