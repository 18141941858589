import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Autocomplete from "@mui/material/Autocomplete";

import { useNavigate, Link as RouterLink } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  AddDelegateGroupApi,
  delegateAddDataApi,
} from "src/DAL/delegateGroups/delegategroupApi";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AddDelegateGroup() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [member, setMember] = useState([]);
  const [programmeListing, setProgramListing] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [eventsListing, setEventsListing] = useState([]);
  const [eventsName, setEventsName] = useState([]);

  const [inputs, setInputs] = useState({
    title: "",
    status: "true",
    room_type: "general",
    image: {},
    short_description: "",
    detailed_description: "",
    zoom_link: "",
    password: "",
    groupBy: "program",
  });

  const getAddListingForProgramsDelegates = async () => {
    setIsLoading(true);
    const result = await delegateAddDataApi();
    if (result.code === 200) {
      setProgramListing(result.program);
      setPersonName(result.delegates);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeEvents = (event) => {
    const {
      target: { value },
    } = event;
    setEventsName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeGroup = (value) => {
    setGroupsName(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //program manipulation
    let program_array = [];
    if (groupsName.length > 0) {
      program_array = groupsName.map((group) => {
        return {
          _id: group._id,
        };
      });
    }

    //delegate manipulation

    let selected_delegate_array = [];
    let selected_delegate_object = {};
    member.map((member) => {
      selected_delegate_object = {
        _id: member._id,
      };
      selected_delegate_array.push(selected_delegate_object);
    });

    let postData = {
      title: inputs.title,
      status: inputs.status,
      program: program_array,
      delegate: selected_delegate_array,
    };
    setIsLoading(true);
    const result = await AddDelegateGroupApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    getAddListingForProgramsDelegates();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>Add Delegate Group</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Group Name"
            variant="outlined"
            fullWidth
            required
            name="title"
            value={inputs.title}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Group Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Pod Status*"
              onChange={handleChange}
            >
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        {inputs.groupBy == "event" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <FormControl className="mt-3" fullWidth>
              <InputLabel id="demo-multiple-name-label">Events</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={eventsName}
                onChange={handleChangeEvents}
                input={<OutlinedInput label="Events" />}
                MenuProps={MenuProps}
              >
                {eventsListing.map((name, i) => (
                  <MenuItem
                    key={i}
                    value={name.event_slug}
                    style={getStyles(name, eventsName, theme)}
                  >
                    {name.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {inputs.groupBy == "program" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Programme"
              selectedOption={groupsName}
              setSelectedOption={handleChangeGroup}
              optionsList={programmeListing}
              name="title"
              multiple
            />
          </div>
        )}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <Autocomplete
            multiple
            id="tags-outlined"
            options={personName}
            getOptionLabel={(option) =>
              option.first_name + " (" + option.email + ")"
            }
            filterSelectedOptions
            value={member}
            onChange={(event, newValue) => {
              setMember(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Delegates" placeholder="Delegate" />
            )}
          />
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
