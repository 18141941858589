import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// material
import { IconButton, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import {
  DeleteStudySessionRecordingApi,
  studySessionRecordingListing,
} from "src/DAL/studySession/studySessionApi";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  {
    id: "table_avatar",
    label: "Image",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "study_session_title", label: "Study Session", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SessionsRecordingsList() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recordingData, setRecordingData] = useState([]);
  const classes = useStyles();
  const [deleteDoc, setDeleteDoc] = useState("");

  const getRecordingListing = async () => {
    setIsLoading(true);
    const result = await studySessionRecordingListing();
    if (result.code == 200) {
      const recordings = result.study_session_recording.map((recording) => {
        return {
          ...recording,
          study_session_title: recording.study_session?.title
            ? recording.study_session?.title
            : "N/A",
          table_avatar: {
            src: s3baseUrl + recording.session_recording_images.thumbnail_2,
            alt: recording.title,
          },
        };
      });

      setRecordingData(recordings);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleEdit = (value) => {
    navigate(
      `/session-recording/edit-study-session-recording/${value.study_session_recording_slug}`,
      {
        state: value,
      }
    );
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteStudySessionRecordingApi(
      deleteDoc.study_session_recording_slug
    );
    if (result.code === 200) {
      setRecordingData((prev) => {
        return prev.filter(
          (data) =>
            data.study_session_recording_slug !==
            deleteDoc.study_session_recording_slug
        );
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    getRecordingListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 mb-3">
            <h2>Study Sessions Recording</h2>
          </div>
          <div className="col-6 mb-3 text-end">
            <button
              className="small-contained-button float-end mt-1"
              onClick={() =>
                navigate(`/session-recording/add-study-session-recording`)
              }
            >
              Add Study Sessions Recording
            </button>
          </div>
        </div>
        <div className="mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            MENU_OPTIONS={MENU_OPTIONS}
            data={recordingData}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
