import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";

export default function IsOpenNewTab(props) {
  const { item, handleChange } = props;
  const [isField, setIsField] = useState(false);

  return (
    <div>
      {isField ? (
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">Is Open New Tab</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Is Open New Tab"
            name="is_open_new_tab"
            autoFocus
            open={true}
            onClose={() => setIsField(false)}
            value={item?.is_open_new_tab}
            onChange={handleChange}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <div
          className="static-textfield cursor-pointer"
          onClick={() => setIsField(true)}
        >
          <div className="static-textfield-label px-1">Is Open New Tab</div>
          <div className="field-value">
            {item?.is_open_new_tab ? "Yes" : "No"}
          </div>
          <div className="dropdown-arrow">
            <ArrowDropDownIcon />
          </div>
        </div>
      )}
    </div>
  );
}
