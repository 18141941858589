import React, { useState } from "react";
import { s3baseUrl } from "src/config/config";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import DownloadIcon from "@mui/icons-material/Download";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import { CircularProgress, Tooltip } from "@mui/material";
export default function DocumentCard({ value, MENU_OPTIONS }) {
  const [isLoading, setIsLoading] = useState(false);

  const imageLink = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };

  const getResourceImage = (doc) => {
    if (doc.document_thumbnail) {
      return s3baseUrl + doc.document_thumbnail;
    } else if (doc.document_type == "image") {
      return s3baseUrl + doc?.document_images_url?.thumbnail_1;
    } else {
      const ext = doc.document_file_url?.split(".").pop();
      if (imageLink[ext]) {
        return imageLink[ext];
      } else {
        return imageLink.other;
      }
    }
  };

  const handleDownload = async (path) => {
    setIsLoading(true);
    const file_path = `${s3baseUrl}${path}`;
    fetch(file_path)
      .then(async (res) => {
        if (res.status === 200) {
          let blob = await res.blob();
          saveAs(blob, path);
          setIsLoading(false);
        } else {
          enqueueSnackbar(res.statusText, { variant: "error" });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <div className="col-sm-12 col-md-6 col-lg-6 mb-4" key={value._id}>
      <div className="card mt-4 pods-cards-shadow cursor h-100 ">
        <div className="row">
          <div className="col-md-3">
            <img
              src={getResourceImage(value)}
              className="pt-3 ps-3"
              height="80px"
              alt="Programme"
            />
          </div>
          <div className="col-md-9 ps-0">
            <div className="card-body">
              <div className="row">
                <div className="col-10">
                  <h3 className="h2-heading">{htmlDecode(value.title)}</h3>
                </div>

                <div className="col-2 menu-option text-end">
                  <CustomPopoverSection menu={MENU_OPTIONS} data={value} />
                </div>
              </div>
              <p className="programme-card-desc mb-3">
                {htmlDecode(value.detailed_description)}
              </p>

              <div className="col-12 text-end download-icon">
                {isLoading ? (
                  <CircularProgress className="icon-style" />
                ) : (
                  <Tooltip title="Download">
                    <DownloadIcon
                      onClick={() => {
                        let path = "";
                        if (value.document_type === "image") {
                          path = value.document_images_url.thumbnail_1;
                        } else {
                          path = value.document_file_url;
                        }
                        handleDownload(path);
                      }}
                      className="icon-style"
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
