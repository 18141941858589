import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { update_transaction_status_api } from "src/DAL/Transaction/transactionApi";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { boolean } from "yup";
import { convertCurrencyToSign } from "src/utils/constant";

export default function RefundTransaction({
  getTransactionsList,
  verifyTransaction,
  setChangeStatus,
  filterData,
  setRefund,
  selectedObject,
  refund,
  handleAgree,
  loadingPopUp,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [showWarning, setShowWarning] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (refund.amount <= 0) {
      enqueueSnackbar("Refunded amount must be greater than 0", {
        variant: "error",
      });
      return;
    }

    if (selectedObject.refund_amount > 0) {
      const maxRefundAmount = (
        selectedObject.amount - selectedObject.refund_amount
      ).toFixed(2);

      if (refund.amount > maxRefundAmount) {
        enqueueSnackbar(
          `Refunded amount must be less than or equal to ${convertCurrencyToSign(
            selectedObject.currency
          )}   ${maxRefundAmount}`,
          {
            variant: "error",
          }
        );
        setShowWarning(true);
        return;
      } else {
        handleAgree();
      }
    } else if (refund.amount > selectedObject.amount) {
      enqueueSnackbar(
        `Refunded amount must be less than or equal to ${convertCurrencyToSign(
          selectedObject.currency
        )}   ${selectedObject.amount}`,
        {
          variant: "error",
        }
      );
      setShowWarning(true);
      return;
    } else {
      setShowWarning(false);
      handleAgree();
    }
  };

  const handleChange = (event) => {
    setShowWarning(false);
    const name = event.target.name;
    const value = event.target.value;
    setRefund((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (selectedObject.refund_amount > 0) {
      setRefund({
        ...refund,
        ["amount"]: (
          selectedObject.amount - selectedObject.refund_amount
        ).toFixed(2),
        ["refund_type"]: "partial_refunded",
      });
    } else {
      setRefund({
        ...refund,
        ["amount"]: selectedObject.amount.toFixed(2),
      });
    }
  }, [selectedObject, refund.refund_type]);
  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit}>
        <div className="row notification-statement">
          {showWarning && (
            <div className="col-12 ">
              <Alert
                severity="warning"
                style={{
                  backgroundColor: "rgb(43 32 9 / 38%)",
                  colo: "rgb(150 122 64)",
                }}
              >
                Refunded amount must be less than or equal
                {convertCurrencyToSign(selectedObject.currency)}
                {selectedObject.refund_amount > 0
                  ? selectedObject.amount - selectedObject.refund_amount
                  : selectedObject.amount.toFixed(2)}
                .
              </Alert>
            </div>
          )}
          <div className="col-12 ">
            <FormControl fullWidth required className="mt-3">
              <InputLabel id="demo-simple-select-label1">
                Refund Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label1"
                id="demo-simple-select1"
                value={refund.refund_type}
                name="refund_type"
                label="Notification type"
                disabled={selectedObject.refund_amount > 0 ? true : false}
                onChange={handleChange}
              >
                <MenuItem value="full">Full Amount</MenuItem>
                <MenuItem value="partial_refunded">Partial Amount</MenuItem>
              </Select>
            </FormControl>
          </div>{" "}
          {refund.refund_type == "partial_refunded" && (
            <div className="col-12 mt-3">
              <TextField
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                fullWidth
                required
                name="amount"
                InputProps={{
                  inputProps: { min: 1, max: selectedObject.amount },
                }}
                value={refund.amount}
                onChange={handleChange}
              />
              <div className="col-12 mt-2">
                <p class="note">
                  <span class="note-label">Note:</span> A partial refund on
                  transaction will not impact the commission. Please note that
                  this adjustment needs to be made manually.
                </p>
              </div>
            </div>
          )}
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {loadingPopUp ? "Please wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
