import * as React from "react";
import Popover from "@mui/material/Popover";
import { Tooltip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { dd_date_format } from "src/utils/constant";
export default function MemberProgramPopover({
  handleClose,
  anchorEl,
  id,
  open,
  program,
}) {
  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ heigth: 100 }}>
        <div className="sticky-top card">
          <p className="heading_programs">Programmes</p>
          <span className="icon-container" onClick={() => handleClose()}>
            <CancelOutlinedIcon fontSize="small" />
          </span>
        </div>
        {program?.map((item, index) => {
          return (
            <div className="d-flex">
              <CircleIcon
                style={{
                  color: item?._id?.status ? "green" : "red",
                  fill: item?._id?.status ? "green" : "red",
                }}
                className="transation-status-icon ms-2 mt-2"
              />
              <Tooltip
                key={index}
                title={
                  item.expiry_date ? (
                    <div>
                      Start Date: {dd_date_format(item?.purchase_date_time)}
                      <br />
                      Expiry: {dd_date_format(item?.expiry_date)}
                      <br />
                      Status:{" "}
                      {item?._id?.status === true ? "Active" : "InActive"}
                    </div>
                  ) : (
                    <div>
                      Start Date: {dd_date_format(item?.purchase_date_time)}
                      <br />
                      No Expiry
                      <br />
                      Status:{" "}
                      {item?._id?.status === true ? "Active" : "InActive"}
                    </div>
                  )
                }
                arrow>
                <p
                  className="mb-1 program-hover"
                  style={{ padding: "2px 5px 2px 8px" }}>
                  {item?._id?.title}
                </p>
              </Tooltip>
            </div>
          );
        })}
      </Popover>
    </div>
  );
}
