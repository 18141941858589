import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  Avatar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { lesson_listing_api } from "src/DAL/Programme/Lessons/Lessons";
import { useEffect, useState } from "react";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "src/components/RecordNotFound";
import {
  delete_program_module_api,
  program_module_listing_api,
} from "src/DAL/Programme/Lessons/ProgramModule";
import { programme_detail_api } from "src/DAL/Programme/Programme";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import ListIcon from "@mui/icons-material/List";
import GridViewIcon from "@mui/icons-material/GridView";
import CircleIcon from "@mui/icons-material/Circle";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const Progrmme_status = [
  {
    name: "Active",
    value: true,
    class: "active_bg_program",
  },
  {
    name: "InActive",
    value: false,
    class: "inactive_bg_program",
  },
];

export default function ProgramModuleListing() {
  const { programme_slug } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [lesson, setLesson] = useState([]);
  const [program, setProgram] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { viewModeProgramModule, setViewModeProgramModule } = usePGIMode();
  const [query, setQuery] = useState("");
  const [programName, setProgramName] = useState("");
  const { state } = useLocation();
  const [programmeDetailInfo, setProgrammeDetailInfo] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleOpenDelete = (value) => {
    setProgrammeDetailInfo(value);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);
  const getFilteredData = (query) => {
    let dataToFilter = [...lesson];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };

  const toggleView = () => {
    setViewModeProgramModule((prevMode) =>
      prevMode === "grid" ? "table" : "grid"
    );
  };
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };
  const handleNavigateDetail = (value) => {
    navigate(
      `/programmes/modules-detail/${programme_slug}/${value.program_module_slug}`,
      {
        state: value,
      }
    );
  };
  const getModulesList = async () => {
    let result = await program_module_listing_api(programme_slug);
    if (result.code == 200) {
      setIsLoading(false);
      setLesson(result.program);
      setProgram(result.program);
    }
  };
  const getProgrammeDetail = async () => {
    setIsLoading(true);
    const result = await programme_detail_api(programme_slug);
    if (result.code === 200) {
      setProgramName(result?.program?.title);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const hanldeDetail = (value) => {
    navigate(`/lessons/lesson-detail/${value.lesson_slug}`, {
      state: value,
    });
  };

  const hanldeAddModule = () => {
    navigate(`/programmes/modules/${programme_slug}/add`);
  };
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },

    {
      title: state ? state?.title : programName ? programName : "",
      navigation: `/programmes/programmes-detail/${programme_slug}`,
      active: false,
    },
    {
      title: "Programme Modules",
      navigation: ``,
      active: true,
    },
  ];
  const handleNavigateEdit = (value) => {
    console.log(value, "valuevaluevaluevaluevalue");
    navigate(
      `/programmes/modules/${programme_slug}/edit/${value.program_module_slug}`,
      {
        state: value,
      }
    );
  };
  let MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
  ];
  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },

    {
      id: "title",
      label: "Image",
      alignRight: false,
      renderData: (row) => {
        console.log(row, "rowrow");
        return (
          <>
            {
              <Avatar
                src={s3baseUrl + row.program_images.thumbnail_1}
                alt={row.title}
              />
            }
          </>
        );
      },
    },
    {
      id: "title",
      label: "Title",
      alignRight: false,
      renderData: (row) => {
        console.log(row, "rowrowrow");
        let find_status = Progrmme_status.find(
          (status) => status.value == row.status
        );
        const iconStyle = {
          color: "blue !important",
        };

        return (
          <div className="d-flex pointer">
            <Tooltip title={find_status?.name} className="pointer">
              <CircleIcon
                style={iconStyle}
                className={`transation-status-icon ${find_status?.class}`}
              />
            </Tooltip>
            <p
              className="mb-0 "
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigateDetail(row)}
            >
              {" "}
              {row.title}
            </p>
          </div>
        );
      },
    },

    { id: "order", label: "Order", alignRight: false },
  ];

  const handleDelete = async () => {
    handleCloseDelete();
    let result = await delete_program_module_api(
      programmeDetailInfo.program_module_slug
    );
    if (result.code === 200) {
      getModulesList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    getModulesList();
    getProgrammeDetail();
  }, []);
  useEffect(() => {
    if (programme_slug) {
      if (state) {
      } else {
        getProgrammeDetail();
      }
    }
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-4">
            <span className="">
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-5 col-md-8 ">
            <h2>Programme Modules</h2>
          </div>
          <div
            className="col-lg-7 col-md-4 text-end"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <div>
              <FormControl variant="outlined" style={{ marginTop: "2px" }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  variant="small"
                  onChange={handleChangeSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>
            <button
              onClick={hanldeAddModule}
              className="ms-3 mt-1 small-contained-button"
            >
              Add Module
            </button>
            <button
              onClick={toggleView}
              className="ms-1 mt-1 small-contained-button"
            >
              {viewModeProgramModule == "grid" ? (
                <>
                  <GridViewIcon />
                </>
              ) : (
                <>
                  <ListIcon />
                </>
              )}
            </button>
          </div>
          {viewModeProgramModule == "grid" ? (
            <>
              {getFilteredData(query)?.map((value, index) => {
                return (
                  <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                    <div className="card mt-4 pods-cards-shadow cursor h-100">
                      <img
                        src={s3baseUrl + value.program_images.thumbnail_1}
                        className="card-img-top pods-image"
                        alt="Programme"
                        onClick={() => handleNavigateDetail(value)}
                      />
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <h3
                              className="h2-heading"
                              onClick={() => handleNavigateDetail(value)}
                            >
                              {htmlDecode(value.title)}
                            </h3>
                          </div>
                        </div>
                        <p className="programme-card-desc">
                          {htmlDecode(value.short_description)}
                        </p>
                        {/* <div className="row recording-card-date">
                      <div className="col-5 card-button recording-card-date-position">
                        <p className="pods-active-members">
                          <span> Module</span>
                        </p>
                      </div>
                      <div className="col-7 text-end ms-auto">{"Zubair"}</div>
                    </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="mt-4">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                MENU_OPTIONS={MENU_OPTIONS}
                data={getFilteredData(query)}
                className="card-with-background"
                pagePagination={true}
                is_hide={true}
              />
            </div>
          )}
          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete this programme?"}
            handleAgree={handleDelete}
          />
        </div>
      </div>
    </>
  );
}
