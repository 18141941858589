import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { Challenge_user_api } from "src/DAL/Pods/pods";
import { useParams } from "react-router-dom";
import { add_challenge_exclude_member } from "src/DAL/consultant/consultant";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function RoomUserList({ type, checkbox }) {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [openExclude, setOpenExclude] = useState(false);
  const [selected, setSelected] = useState([]);

  const getDetail = async () => {
    setIsLoading(true);

    let search_keyword =
      localStorage.getItem("pods_search_text") == null
        ? searchText
        : localStorage.getItem("pods_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const result = await Challenge_user_api(
      params?.challenge_id,
      page,
      rowsPerPage,
      search_keyword,
      type
    );
    if (result.code === 200) {
      const data = result.challenge_users?.map((room) => {
        let full_name = "N/A";
        let user_email = "N/A";
        if (room?.first_name) {
          full_name = room.first_name + " " + room.last_name;
        }
        if (room?.email) {
          user_email = room.email;
        }
        return {
          ...room,
          full_name,
          user_email,
          table_avatar: {
            src: s3baseUrl + room?.profile_image,
            alt: room?.first_name,
          },
        };
      });
      setUserList(data);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getDetail();
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
    },
    { id: "full_name", label: " Name", alignRight: false },

    { id: "user_email", label: "Email" },
  ];

  const handleEdit = () => {
    setOpenExclude(true);
  };

  const handleSubmitExclude = async () => {
    let members = selected.map((data) => {
      return { _id: data._id };
    });

    let memberIds = members.map((member) => member._id);

    let postData = {
      slug: params.challenge_id,
      members: members,
      type: "challenge",
    };
    setOpenExclude(false);
    const result = await add_challenge_exclude_member(postData);
    if (result.code === 200) {
      setUserList((prev) => {
        return prev.filter((data) => !memberIds.includes(data._id));
      });
      setSelected([]);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getDetail();
  }, [rowsPerPage, page]);

  useEffect(() => {
    localStorage.setItem("pods_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("pods_search_text", "");
    } else {
      localStorage.setItem("pods_search_text", searchText);
    }
  }, [searchText]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openExclude}
        setOpen={setOpenExclude}
        title={"Are you sure you want to exclude this members ?"}
        handleAgree={handleSubmitExclude}
      />
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            setSelected={setSelected}
            selected={selected}
            checkbox_selection={checkbox}
            data={userList}
            className="card-with-background"
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            pagePagination={true}
          />
        </div>
        {selected.length > 0 && (
          <div class="text-end mt-4" id="fixedbutton">
            <button class="small-contained-button" onClick={handleEdit}>
              Exclude This Members
            </button>
          </div>
        )}
      </div>
    </>
  );
}
