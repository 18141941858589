import { useNavigate } from "react-router-dom";
import CalendarPodsList from "./CalendarPodsList";
import { useState } from "react";
import { useEffect } from "react";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import CalendarPodsListCallPods from "./CalendarPodsListCallPods";

const CalenderPodsMianList = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const expire = window.location.pathname.includes("/calendar-pod/call-pods");
  const handleNavigate = () => {
    navigate(`/calendar-pods/add-pod`);
  };
  const handleTabClick = (e, row) => {
    if (row.tab_slug == "expire") {
      navigate(`/calendar-pod/call-pods`);
    } else {
      navigate(`/calendar-pods`);
    }
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const TABS_OPTIONS = [
    {
      title: "Source Pods",
      tab_slug: "pending",
      onClick: handleTabClick,
      component: <CalendarPodsList />,
    },
    {
      title: "Book Call Pods",
      tab_slug: "expire",
      onClick: handleTabClick,
      component: <CalendarPodsListCallPods />,
    },
  ];
  useEffect(() => {
    if (expire) setTabValue(1);
  }, [TABS_OPTIONS]);
  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h2>Pods</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Pods
            </button>
          </div>
        </div>
        <div className="row section-space">
          <div className="col-12 section-space">
            <MUICustomTabs
              data={TABS_OPTIONS}
              value={tabValue}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CalenderPodsMianList;
