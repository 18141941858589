import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  Autocomplete,
  Button,
  FormHelperText,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { ADDReminderUser } from "src/DAL/AdminUserApi/AdminUserApi";
import moment from "moment/moment";
import { memberInCalendarGroupsListApi } from "src/DAL/member/member";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddReminderUser() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [searchText, setSearchText] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [membersList, setMembersList] = useState([]);
  const [nurtureList, setNurtureList] = useState("");
  const [file, setProfileImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    member_id: "",
    notify_time: "00:00",
    date: null,
    message_type: "general",
    image: {},
    embed_code: "",
    reminder_message: "",
  });

  const get_user_name = (member) => {
    return (
      member.first_name + " " + member.last_name + " (" + member.email + ")"
    );
  };

  const get_search_members = async () => {
    const result = await memberInCalendarGroupsListApi(searchText);
    if (result.code === 200) {
      let members = result.members.map((member) => {
        return {
          ...member,
          user_name: get_user_name(member),
        };
      });
      setMembersList(members);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("member_id", selectedMember?._id);
    formData.append("message_type", inputs.message_type);
    formData.append("embed_code", inputs.embed_code);
    formData.append("reminder_message", inputs.reminder_message);
    formData.append("notify_date", moment(inputs.date).format("YYYY-MM-DD"));
    formData.append("notify_time", inputs.notify_time);
    if (file) {
      formData.append("image", inputs.image);
    }
    const result = await ADDReminderUser(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/reminder-user`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSearchUser = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSearchText(value);
  };

  const hanldeSelectedMember = (value) => {
    setSearchText("");
    setSelectedMember(value);
    let nurture_Data = value.nurture;
    let nurtureData =
      nurture_Data.first_name +
      " " +
      nurture_Data.last_name +
      "(" +
      nurture_Data.email +
      ")";

    setNurtureList(nurtureData);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  useEffect(() => {
    get_search_members();
  }, [searchText]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-1">Add Reminder</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <Autocomplete
              id="tags-outlined"
              options={membersList}
              getOptionLabel={(option) => option.user_name}
              filterSelectedOptions
              value={selectedMember}
              onChange={(event, newValue) => {
                hanldeSelectedMember(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Members"
                  placeholder="Members"
                  onChange={handleSearchUser}
                  required
                />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <TextField
              fullWidth
              variant="outlined"
              label="Nurture"
              disabled
              className="inputs-fields"
              value={nurtureList}
            />
          </div>
          <div className="col-6">
            <TextField
              fullWidth
              variant="outlined"
              id="time"
              label="Notify Time"
              type="time"
              required
              className="mt-3 inputs-fields"
              name="notify_time"
              value={inputs.notify_time}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 mt-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Notify Date"
                  inputFormat="dd-MM-yyyy"
                  name="date"
                  required
                  value={inputs.date}
                  onChange={(e) => {
                    handleChangeOthers("date", e);
                  }}
                  renderInput={(params) => <TextField required {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div className="col-6 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Message Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="message_type"
                value={inputs.message_type}
                label="Message Type"
                onChange={handleChange}
              >
                <MenuItem value="general">General</MenuItem>
                <MenuItem value="image">Image</MenuItem>
                <MenuItem value="video">Video</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.message_type == "image" && (
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <div className="row w-100 div-style ms-0 pt-0">
                <div className="col-5">
                  <p className="">Upload Image *</p>
                  <FormHelperText className="pt-0">
                    Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {file && <img src={file} height="50" />}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={(e) => {
                        fileChangedHandler(e, "image");
                      }}
                    />

                    <Button
                      className="small-contained-button"
                      startIcon={<FileUploadIcon />}
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
              {inputs.image?.name == "" ? (
                ""
              ) : (
                <p className="text-secondary">{inputs.image?.name}</p>
              )}
            </div>
          )}
          {inputs.message_type == "video" && (
            <div className="col-12 mt-3">
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Embed Code"
                  multiline
                  rows={6}
                  name="embed_code"
                  value={inputs.embed_code}
                  onChange={handleChange}
                  required
                />
              </FormControl>
            </div>
          )}
          <div className="col-12 mt-4">
            <h4>Reminder Message *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="reminder_message"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
