import { invokeApi } from "../../bl_libs/invokeApi";

export const menuList = async (data) => {
  const requestObj = {
    path: `/app/get_menu_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const defaultMenuListApi = async (data) => {
  const requestObj = {
    path: `/app/get_menu_list/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const client_menu_list_api = async (data) => {
  const requestObj = {
    path: `/app/client_menu_list/get`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getNavListApi = async (type) => {
  const requestObj = {
    path: `/api/default_setting/get/nav_items/${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_nav_item_details_api = async (user_id, data) => {
  const requestObj = {
    path: `/admin_users/get_nav_item_details/${user_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_sales_team_navitems = async (type) => {
  const requestObj = {
    path: `/api/default_setting/get/nav_items/${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const navItemApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update/nav_items`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_nav_items_access = async (user_id, data) => {
  const requestObj = {
    path: `/admin_users/update_nav_items/${user_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_nav_items_access_sales_team = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update/nav_items`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
