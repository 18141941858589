import React from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { dateDifferenceInDays } from "src/utils/constant";
import { get_root_value } from "src/utils/domUtils";

const ChartJs = ({
  memberEarning,
  targetToAchieve,
  totalAmount,
  startDate,
}) => {
  let days = [];
  for (let index = 1; index <= 90; index++) {
    days.push(index);
  }

  let demoXYValues = [];
  let counting = 0;

  if (
    memberEarning.length > 0 &&
    moment(memberEarning[0].date).format("YYYY-MM-DD") > startDate
  ) {
    [...memberEarning].reverse().forEach((earning) => {
      const date = moment(earning.date).format("MM/DD/YYYY");
      counting = counting + earning.earning;
      const dateCheck = dateDifferenceInDays(startDate, date);
      demoXYValues.push({
        x: dateCheck < 0 ? 1 : dateCheck + 1,
        y: counting,
      });
    });
  }

  // const labels = chartDay;
  const labels = days;
  const maxAmount =
    targetToAchieve < totalAmount
      ? totalAmount + 5000
      : targetToAchieve > 100000
      ? targetToAchieve + 10000
      : targetToAchieve + 5000;

  const maxAmountValue = Number(maxAmount);

  const options = {
    backgroundColor: get_root_value("--sidebars-background-color"),
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Chart",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          fontColor: get_root_value("--input-text-color"),
          color: get_root_value("--input-text-color"),
          borderColor: get_root_value("--input-text-color"),
        },

        // autoSkip: true,
        maxTicksLimit: 24,
        max: 90,
        ticks: {
          color: get_root_value("--input-text-color"),
        },
      },
      y: {
        grid: {
          color: get_root_value("--input-text-color"),
          // color: get_root_value("--sidebars-background-color"),
        },

        min: 0,
        max: maxAmountValue === 0 ? 1000 : maxAmountValue,
        ticks: {
          // forces step size to be 50 units
          stepSize: 1,
          color: get_root_value("--input-text-color"),
          // minTicksLimit: 5,
          maxTicksLimit: 6,
          // max: 6,
          // min: 3,
          // padding: 20,
          // labelOffset: 20,
          precision: 0,
          userCallback: function (label, index, labels) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label;
            }
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: `a`,
        // label: `${convertCurrencyToSign(
        //   user_profile.nineteen_day_plan_currency
        // )}`,
        data: demoXYValues, //this is in the form of x:, y:
        borderColor: get_root_value("--portal-theme-primary"),
        backgroundColor: get_root_value("--portal-theme-primary"),
        pointRadius: 5,
        pointHoverRadius: 5,
      },
    ],
  };
  return (
    <div id="chartContainer">
      <div className="row">
        <div className="col-md-12 text-center">
          <Line options={options} data={data} />
        </div>
      </div>
    </div>
  );
};
export default ChartJs;
