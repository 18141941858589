import React from "react";
import { CircularProgress } from "@mui/material";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import { dd_date_format } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function BonusContentAccess({ tabData }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [bonusContentList, setBonusContentList] = useState([]);

  const getBonusContentList = () => {
    setIsLoading(true);
    let data = tabData?.map((item) => {
      let date_expiry = "No Expiry";
      let purchase_date = "";

      if (item.expiry_date) {
        date_expiry = dd_date_format(item?.expiry_date);
      }
      if (item.start_date) {
        purchase_date = dd_date_format(item.start_date);
      }
      return {
        ...item,
        table_avatar: {
          src: s3baseUrl + item?.image?.thumbnail_2,
          alt: item?.title,
        },
        purchase_date,
        date_expiry,
      };
    });
    setBonusContentList(data);
    setIsLoading(false);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    {
      id: "title",
      label: "Title",
    },
    { id: "access_type", label: "Access Type", className: "text-capitalize" },
    { id: "purchase_date", label: "Start Date" },
    { id: "date_expiry", label: "Expiry Date" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  useEffect(() => {
    getBonusContentList();
  }, [tabData]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={bonusContentList}
          className="card-with-background"
        />
      </div>
    </>
  );
}
