import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import "react-tagsinput/react-tagsinput.css";
import { Icon } from "@iconify/react";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import LinkPopup from "./LinkPopup";
import { AddUpdateNotificationActionApi } from "src/DAL/NotificationAction/NotificationSetting";
import { TextareaAutosize } from "@mui/material";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PushNotifySetting({ onCloseDrawer, data, listing }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [openFormatter, setOpenFormatter] = useState(false);
  const [inputs, setInputs] = useState({
    notification_title: "",
    description: "",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const [message, setMessage] = useState("");
  const [openLink, setOpenLink] = useState(false);
  const handleGetSelectedText = () => {
    const inputField = document.getElementById("inputField");
    if (inputField) {
      const selectedText = inputField.value.substring(
        inputField.selectionStart,
        inputField.selectionEnd
      );
      return selectedText;
    }
  };
  const handleOnBold = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "**" + seleted_text + "**"
      );
      setMessage(newText);
    }
  };
  const handleOpenLinkPopup = () => {
    setOpenLink(true);
  };
  const handleOnItalic = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "*" + seleted_text + "*"
      );
      setMessage(newText);
    }
  };
  const handleAddLink = (values) => {
    let make_link = ` [${values.display_text}](${values.link_address}) `;
    const inputField = document.getElementById("inputField");
    const start = inputField.selectionStart;
    const end = inputField.selectionEnd;
    // Insert the text at the cursor position
    setMessage(message?.slice(0, start) + make_link + message?.slice(end));
    // Move the cursor after the inserted text
    inputField.setSelectionRange(
      start + make_link.length,
      start + make_link.length
    );
    setOpenLink(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {
      action: "push_notification_access",
      action_object: {
        notification_title: inputs.notification_title,
        description: inputs.description,
      },
    };
    const result = await AddUpdateNotificationActionApi(
      data.notification_action_slug,
      postData
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      listing();
      onCloseDrawer();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  function handleEditorInit() {
    setTimeout(() => {
      let containerWarning = document.getElementById("inputField");
      console.log(containerWarning, "containerWarningcontainerWarning");
      if (containerWarning) {
        containerWarning.style.height = "150px";
      }
    }, 100);
  }
  React.useEffect(() => {
    handleEditorInit();
  }, []);
  React.useEffect(() => {
    setInputs(data.push_notification_info);
    setInputs((items) => ({
      ...items,
      ["notification_title"]: data.push_notification_info
        ? data.push_notification_info.notification_title
        : "",
      ["description"]: data.push_notification_info
        ? data.push_notification_info.description
        : "",
    }));
  }, [data]);
  return (
    <div className="container-fluid new-memories">
      <GeneralPopUpModel
        open={openLink}
        setOpen={setOpenLink}
        title={"Insert Link"}
        componentToPassDown={<LinkPopup handleAddLink={handleAddLink} />}
      />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <TextField
              className="mt-3"
              id="outlined-basic"
              label="Notification Title"
              variant="outlined"
              name="notification_title"
              value={inputs?.notification_title}
              required={true}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <p className="tracking_code_heading">Notification Statement*</p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Notification Statement"
              required
              name="description"
              value={inputs?.description}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "200px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="text-end mt-4">
            <button type="submit" className="small-contained-button">
              {isLoading ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
