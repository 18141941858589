import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  addSeriesVideosApi,
  editSeriesVideosApi,
  series_video_detail,
} from "src/DAL/SeriesVideos/seriesVideos";
import { seriesCategoryListing } from "src/DAL/seriesCategoryApi/seriesCategoryApi";
import { urlPatternValidation } from "src/utils/constant";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateSeriesVideos() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [oldImages, setOldImages] = React.useState({
    thumbnail: "",
  });

  const [images, setImages] = React.useState({
    thumbnail: "",
  });

  const [seriesCategory, setSeriesCategory] = useState([]);
  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    date: null,
    thumbnail: {},
    trailer_video_url: "",
    video_url: "",
    series_category: "",
    is_feature: true,
    season_statement: "",
    short_description: "",
    detail_description: "",
    order: 0,
  });

  const getSeriesCategories = async () => {
    const result = await seriesCategoryListing();
    if (result.code === 200) {
      setSeriesCategory(result.series_category);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getSeriesVideoDetail = async () => {
    const result = await series_video_detail(params.video_id);
    if (result.code === 200) {
      formatData(result.seriesvideos);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fileChangedHandler = (e) => {
    const { name, files } = e.target;
    setInputs({
      ...inputs,
      [name]: files[0],
    });
    setImages({
      ...images,
      [name]: URL.createObjectURL(files[0]),
    });
  };

  const handleChangeDate = (newValue) => {
    setInputs({
      ...inputs,
      ["date"]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      urlPatternValidation(inputs.trailer_video_url) == false ||
      urlPatternValidation(inputs.video_url) == false
    ) {
      enqueueSnackbar("Enter Valid URLs", { variant: "error" });
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("status", inputs.status);
      formData.append("trailer_video_url", inputs.trailer_video_url);
      formData.append("video_url", inputs.video_url);
      formData.append("title", inputs.title);
      formData.append("series_category", inputs.series_category);
      formData.append(
        "date",
        inputs.date ? moment(inputs.date).format("YYYY-MM-DD") : ""
      );

      if (formType === "EDIT") {
        formData.append("order", inputs.order);
        if (images.thumbnail) {
          formData.append("thumbnail", inputs.thumbnail);
        }
      } else {
        formData.append("thumbnail", inputs.thumbnail);
      }

      formData.append("is_feature", inputs.is_feature);
      formData.append("season_statement", inputs.season_statement);
      formData.append("detail_description", inputs.detail_description);
      formData.append("short_description", inputs.short_description);

      const result =
        formType == "ADD"
          ? await addSeriesVideosApi(formData)
          : await editSeriesVideosApi(formData, params.video_id);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(`/series-videos`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formatData = (data) => {
    setFormType("EDIT");
    setInputs(data);
    setOldImages({
      thumbnail: data.thumbnail.thumbnail_1,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getSeriesCategories();
    if (params.video_id) {
      if (state) {
        formatData(state);
      } else {
        getSeriesVideoDetail();
      }
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/series-videos`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Series Video`}</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Video URL"
              variant="outlined"
              fullWidth
              type="url"
              required
              name="video_url"
              value={inputs.video_url}
              onChange={handleChange}
            />
          </div>
          {formType === "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Trailer Video URL "
              variant="outlined"
              fullWidth
              type="url"
              required
              name="trailer_video_url"
              value={inputs.trailer_video_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Season Statement"
              variant="outlined"
              fullWidth
              required
              name="season_statement"
              value={inputs.season_statement}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Date"
                  inputFormat="dd-MM-yyyy"
                  value={inputs.date}
                  onChange={handleChangeDate}
                  required
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="series_category"
                value={inputs.series_category}
                label="Category"
                onChange={handleChange}
              >
                {seriesCategory.map((category) => {
                  return (
                    <MenuItem value={category._id}>{category.title}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Is Feature *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_feature"
                value={inputs.is_feature}
                label="Is Feature *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Thumbnail *</p>
                <FormHelperText className="pt-0">
                  Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.thumbnail ? (
                  <img src={images.thumbnail} height="50" />
                ) : oldImages.thumbnail ? (
                  <img src={s3baseUrl + oldImages.thumbnail} height="50" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="thumbnail"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.thumbnail?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.thumbnail?.name}</p>
            )}
          </div>
          <div className="col-12 mt-5">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                multiline
                rows={6}
                required
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>

          <div className="col-12 mt-4">
            <h4>Detailed Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detail_description"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
