import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ConsultantProgrammeList({ groupPrograms }) {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getConsultantListing = async () => {
    setIsLoading(true);
    const data = groupPrograms.map((group) => {
      return {
        ...group,
        table_avatar: {
          src: s3baseUrl + group?.program_images?.thumbnail_1,
          alt: group?.title,
        },
      };
    });
    setUserList(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getConsultantListing();
  }, []);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: " Name" },
    { id: "table_avatar", label: "Picture", type: "thumbnail" },
    { id: "short_description", label: "Description " },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={userList}
        className="card-with-background"
        pagePagination={true}
      />
    </>
  );
}
