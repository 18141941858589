import { Alert, Checkbox, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { Csv_request_api } from "src/DAL/Transaction/transactionApi";
import { show_proper_words } from "src/utils/constant";

const GeneralRequestForCsv = ({
  options_array,
  collection_name,
  filter_data,
  setShowExportcsvFile,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [option, setOptions] = useState([...options_array]);
  const [loading, setIsLoading] = useState(false);
  const [IsAllChecked, setIsAllChecked] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
  });
  const handleCheckSelected = (index) => {
    if (index == "all") {
      const newOptions = option.map((opt, i) => {
        if (opt.is_disabled) {
          return { ...opt, is_checked: true };
        } else {
          return { ...opt, is_checked: !IsAllChecked };
        }
      });
      setOptions(newOptions);
      setIsAllChecked(!IsAllChecked);
    } else {
      const newOptions = option.map((opt, i) =>
        i === index ? { ...opt, is_checked: !opt.is_checked } : opt
      );
      setOptions(newOptions);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (inputs.title.length == 0) {
      enqueueSnackbar("CSV file name is not allowed to be empty", {
        variant: "error",
      });
    } else {
      const checkedOptions = option
        .filter((option) => option.is_checked)
        .map((option) => option.value);

      let postData = {
        csv_name: inputs.title,
        columns_to_export: checkedOptions,
        csv_query: filter_data,
        collection_name: collection_name,
      };

      const result = await Csv_request_api(postData);

      if (result.code === 200) {
        setOptions(false);
        setIsLoading(false);
        setShowExportcsvFile(false);
        setIsAllChecked(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (option && option.length > 0) {
      let isAllChecked = option.every((item) => item.is_checked == true);
      setIsAllChecked(isAllChecked);
    }
  }, [option]);

  return (
    <>
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
              <Alert
                severity="warning"
                style={{
                  backgroundColor: "rgb(43 32 9 / 38%)",
                  colo: "rgb(150 122 64)",
                }}
              >
                Generated CSV result will be according to current applied filter
              </Alert>
            </div>{" "}
            <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
              <TextField
                id="outlined-basic"
                label="CSV file name"
                variant="outlined"
                fullWidth
                name="title"
                value={inputs.title}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
              <div
                className=" card  p-2   ps-0 cursor-pointer "
                onClick={() => handleCheckSelected("all")}
                style={{ border: "1px solid rgb(126 95 33 / 38%)" }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ color: "#fff", textTransform: "capitalize" }}
                >
                  <Checkbox checked={IsAllChecked} />
                  Selects All
                </div>
              </div>
            </div>
            {option &&
              option.map((item, index) => {
                return (
                  <>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                      <div
                        className=" card  p-2   ps-0 cursor-pointer "
                        onClick={() => {
                          if (!item.is_disabled) {
                            handleCheckSelected(index);
                          }
                        }}
                        style={{ border: "1px solid rgb(126 95 33 / 38%)" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ color: "#fff", textTransform: "capitalize" }}
                        >
                          {item.is_disabled ? (
                            <Checkbox checked={true} readOnly />
                          ) : (
                            <Checkbox checked={item.is_checked} />
                          )}
                          {show_proper_words(item.label)}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            <div className="text-end mt-4">
              <button className="small-contained-button">
                {loading ? "Please Wait..." : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default GeneralRequestForCsv;
