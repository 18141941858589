import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { eventDelegateListing } from "src/DAL/Programme/Programme";
import { s3baseUrl } from "src/config/config";
import { manageConsultantEventAccess } from "src/DAL/consultant/consultant";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageEventAccess({ heading }) {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [memberDetail, setMemberDetail] = useState({});

  const getProgramsList = async () => {
    setIsLoading(true);
    const result = await eventDelegateListing(params.id);
    if (result.code == 200) {
      setMemberDetail(result.consultant);
      setSelected(result.consultant.dynamite_event);
      var programArray = [];
      result.dynamite_event.map((item) => {
        item.table_avatar = {
          src: s3baseUrl + item.banner1_image?.thumbnail_1,
          alt: item.title,
        };
        item.scheduled_feed = false;
        programArray.push(item);
      });
      setProgramsList(programArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    let event = [];
    selected.map((item) => {
      var program_object = {
        _id: item._id,
        scheduled_feed: item.scheduled_feed,
      };
      event.push(program_object);
    });

    let programObject = {
      dynamite_event: event,
      type: "general",
    };

    const result = await manageConsultantEventAccess(params.id, programObject);
    if (result.code == 200) {
      navigate(-1);
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handle_update_values = (name, value, id) => {
    setProgramsList((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };

  const handleChangeType = (event, name, id) => {
    const value = event.target.value;
    handle_update_values(name, value, id);
  };

  const TABLE_HEAD = [
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "p-0",
    },
    { id: "title", label: "Programme Title", alignRight: false },
    {
      id: "scheduled_feed",
      label: "Enable Scheduled Feed",
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);
        let scheduled_feed = row.scheduled_feed;
        if (is_selected) {
          scheduled_feed = is_selected.scheduled_feed;
        }

        return (
          <div>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="scheduled_feed"
                className="bonus_type_input"
                value={scheduled_feed}
                label=""
                onChange={(e) => {
                  handleChangeType(e, "scheduled_feed", row._id);
                }}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];

  let breadCrumbMenu = [
    {
      title: heading,
      navigation: -1,
      active: false,
    },
    {
      title: memberDetail
        ? memberDetail.first_name +
          " " +
          memberDetail.last_name +
          " (" +
          memberDetail.email +
          ")"
        : "Manage Event Access",
      active: true,
    },
  ];

  useEffect(() => {
    getProgramsList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div>
          <h2>Manage Event Access</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={programsList}
            selected={selected}
            setSelected={setSelected}
            checkbox_selection={true}
            pagePagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
