import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import jsPDF from "jspdf";
import Label from "src/components/Label";
import ReactDOMServer from "react-dom/server";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { orderDetailList } from "src/DAL/Transaction/transactionApi";
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { Icon } from "@iconify/react";
import { convertCurrencyToSign, replace_created_for } from "src/utils/constant";
import moment from "moment";
import { download_csv_file } from "src/utils/convertHtml";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { historyListingApi } from "src/DAL/member/member";
import ClassNameGenerator from "@mui/utils/ClassNameGenerator";
import RecordNotFound from "src/components/RecordNotFound";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },

  {
    id: "table_avatar",
    label: "Product Image",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "quantity", label: "Quantity", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
];
const TABLE_HEAD_tickets = [
  { id: "number", label: "#", type: "number" },
  {
    id: "name",
    label: "Name",
    renderData: (row) => {
      return <p className="mb-0">{row.first_name + " " + row.last_name}</p>;
    },
  },

  { id: "email", label: "Email" },
  { id: "contact_number", label: "Phone" },
  {
    id: "venue_title",
    label: "Venue",
    renderData: (row) => {
      return (
        <p className="mb-0">{row.venue_id ? row.venue_id.title : "N/A"}</p>
      );
    },
  },
];
const headers = [
  { label: "Title", key: "title" },
  { label: "Quantity", key: "quantity" },
  { label: "Price", key: "price" },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function TransactionHistory() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const [UserDetail, setUserDetail] = useState({});
  const [ProductList, setProductList] = useState([]);
  const [ProductListDetail, setProductListDetail] = useState([]);
  const [orderDeatail, setOrderDeatail] = useState([]);

  const transactionHistory = async () => {
    setIsLoading(true);
    const result = await historyListingApi(params.id, "transaction");
    if (result.code == 200) {
      setOrderDeatail(result.portal_history);
      setUserDetail(result.member);
      console.log(result, "transection transection transection ");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  function removeHyphensAndReplaceWithSpace(inputString) {
    // Use the replace() method with a regular expression to remove hyphens and replace them with spaces
    var modifiedString = inputString.replace(/_/g, " ");
    return modifiedString;
  }
  const get_transaction_type = (row) => {
    let t_type = "N/A";
    let transaction_type = row.transaction_type;
    let sale_page = row?.sale_page?.sale_page_title;
    let plan_title = row?.plan?.plan_title;
    let request_title = row?.payment_request?.request_title;

    if (transaction_type == "payment_plan" && sale_page) {
      t_type =
        "Sale Page (" +
        sale_page +
        " | " +
        plan_title +
        " | " +
        row.transaction_request_type +
        ")";
    } else if (transaction_type == "dynamite_product_purchase") {
      t_type = "Dynamite Shop";
    } else if (transaction_type == "payment_request") {
      t_type = "Payment Request ";
      if (request_title) {
        t_type = t_type + "(" + request_title + ")";
      }
    }
    return t_type;
  };
  const get_affliliate_info = (row) => {
    let affiliate_user_name = "Master Link";
    let refUser = row.affliliate;
    let affiliate_user = refUser?.affiliate_user_info;
    if (affiliate_user) {
      affiliate_user_name =
        affiliate_user.first_name + " " + affiliate_user.last_name;
    }
    return referralName(affiliate_user_name, refUser?.affiliate_url_name);
  };

  const get_t_ref_info = (row) => {
    let affiliate_user_name = "N/A";
    let affiliate_user = row?.affiliate_user_info;
    if (affiliate_user) {
      affiliate_user_name =
        affiliate_user.first_name + " " + affiliate_user.last_name;
    }
    return referralName(affiliate_user_name, row?.affiliate_url_name);
  };

  const referralName = (refferal_name, affiliate_url_name) => {
    return (
      <div>
        {`${refferal_name} `}
        {affiliate_url_name && (
          <span className="affiliate_url_name">({affiliate_url_name})</span>
        )}
      </div>
    );
  };
  let breadCrumbMenu = [
    {
      title: "Transaction",
      navigation: -1,
      active: false,
    },
    {
      title: UserDetail?.member
        ? UserDetail?.member.first_name +
          " " +
          UserDetail?.member.last_name +
          "(" +
          UserDetail?.member.email +
          ")"
        : UserDetail?.user_name,
      active: true,
    },
  ];

  useEffect(() => {
    transactionHistory();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12 display-flex mt-1">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
        </div>
        <div className="card mb-3">
          {UserDetail.transaction_type == "dynamite_product_purchase" ? (
            <Card
              className={`payment-card ${
                UserDetail.tickets.length > 0 ? "mb-3" : "mb-0"
              } `}
            >
              <div className="row p-3">
                <div className="col-12">
                  <div className="row">
                    <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                      Member:
                    </div>
                    {UserDetail?.member ? (
                      <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                        {UserDetail.user_name +
                          "(" +
                          UserDetail?.member?.email +
                          ")"}
                      </div>
                    ) : (
                      <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                        {UserDetail.user_name}
                      </div>
                    )}
                    <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                      <p className="finalPrice">Total Amount:</p>
                    </div>
                    <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                      {convertCurrencyToSign(UserDetail.currency)}
                      {UserDetail.amount.toFixed(2)}
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                      Transaction Date:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                      <Icon icon="uiw:date" className="finalPrice" />
                      <span className="ms-1 mt-1">
                        {moment(UserDetail.transaction_date).format(
                          "DD-MM-YYYY hh:mm A"
                        )}
                      </span>
                    </div>{" "}
                    <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                      Payment Made By:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3  mt-5 mb-3 mt-lg-0 text-muted">
                      {UserDetail.payment_made_by
                        ? UserDetail.payment_made_by
                        : "N/A"}
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mt-5  mb-3 mt-lg-0 request-type">
                      Transaction Mode:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted">
                      {UserDetail.transaction_mode}
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mb-3 mt-5 mt-lg-0 request-type">
                      Transaction Type:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                      {get_transaction_type(UserDetail)}
                    </div>
                    {UserDetail?.transaction_note && (
                      <>
                        <div className="col-5 col-md-6 col-lg-3 mb-3 mt-5 mt-lg-0 request-type">
                          Transaction Note:
                        </div>
                        <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                          {UserDetail?.transaction_note}
                        </div>
                      </>
                    )}
                    <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                      Transaction Status:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 ">
                      <Label
                        variant="ghost"
                        color={
                          UserDetail.transaction_status === "succeeded"
                            ? "success"
                            : "error"
                        }
                      >
                        {replace_created_for(UserDetail.transaction_status)}
                      </Label>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ) : (
            <Card
              className={`payment-card ${
                UserDetail.tickets.length > 0 ? "mb-3" : "mb-0"
              } `}
            >
              <div className="row p-3">
                <div className="col-12">
                  <div className="row">
                    <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                      Member:
                    </div>
                    {UserDetail?.member ? (
                      <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                        {UserDetail.user_name +
                          "(" +
                          UserDetail?.member?.email +
                          ")"}
                      </div>
                    ) : (
                      <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                        {UserDetail.user_name}
                      </div>
                    )}
                    <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                      <p className="finalPrice">Total Amount:</p>
                    </div>
                    <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                      {convertCurrencyToSign(UserDetail.currency)}
                      {UserDetail.amount.toFixed(2)}
                    </div>
                    <div className="col-5 col-md-6 col-lg-2 mb-3 request-type">
                      Transaction Date:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                      <Icon icon="uiw:date" className="finalPrice" />
                      <span className="ms-1 mt-1">
                        {" "}
                        {moment(UserDetail.transaction_date).format(
                          "DD-MM-YYYY hh:mm A"
                        )}
                      </span>
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                      Payment Made By:
                    </div>
                    <div className="col-7 col-md-6 col-lg-4  mt-5 mb-3 mt-lg-0 text-muted">
                      {UserDetail.payment_made_by
                        ? UserDetail.payment_made_by
                        : "N/A"}
                    </div>{" "}
                    <div className="col-5 col-md-6 col-lg-3 mt-5  mb-3 mt-lg-0 request-type">
                      Transaction Mode:
                    </div>
                    <div className="col-7 col-md-6 col-lg-2 mb-3  mt-5 mt-lg-0 text-muted">
                      {UserDetail.transaction_mode}
                    </div>{" "}
                    <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                      Referral User:
                    </div>
                    <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                      {get_affliliate_info(UserDetail)}
                    </div>{" "}
                    <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                      Transaction Status:
                    </div>
                    <div className="col-7 col-md-6 col-lg-2  mt-5 mt-lg-0 ">
                      <Label
                        variant="ghost"
                        color={
                          UserDetail.transaction_status === "succeeded"
                            ? "success"
                            : "error"
                        }
                      >
                        {replace_created_for(UserDetail.transaction_status)}
                      </Label>
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                      Refferal Commission:
                    </div>
                    <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                      {convertCurrencyToSign(UserDetail.currency)}
                      {UserDetail.referral_commission
                        ? UserDetail.referral_commission
                        : 0}
                    </div>{" "}
                    <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                      Dynamite Commission:
                    </div>
                    <div className="col-7 col-md-6 col-lg-2  mt-5 mt-lg-0 text-muted">
                      {convertCurrencyToSign(UserDetail.currency)}
                      {UserDetail.dynamite_commission
                        ? UserDetail.dynamite_commission
                        : 0}
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                      Transaction Referral:
                    </div>
                    <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                      {get_t_ref_info(UserDetail.transaction_referral_info)}
                    </div>{" "}
                    <div className="col-5 col-md-6 col-lg-3 mt-5  mb-3 mt-lg-0 request-type">
                      Transaction Referral Commission:
                    </div>
                    <div className="col-7 col-md-6 col-lg-2 mb-3  mt-5 mt-lg-0 text-muted">
                      {convertCurrencyToSign(UserDetail.currency)}
                      {UserDetail.transaction_referral_commission
                        ? UserDetail.transaction_referral_commission
                        : 0}
                    </div>{" "}
                    <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                      Transaction Type:
                    </div>
                    <div className="col-7 col-md-6 col-lg-9 text-muted ">
                      {get_transaction_type(UserDetail)}
                    </div>
                    {UserDetail?.transaction_note && (
                      <>
                        <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                          Transaction Note:
                        </div>
                        <div className="col-7 col-md-6 col-lg-9 text-muted ">
                          {UserDetail?.transaction_note}
                        </div>
                      </>
                    )}
                    {UserDetail.other_info && (
                      <>
                        <div className="mt-3 mb-3">
                          <h3 className="text-center">Other Information</h3>
                        </div>
                        {UserDetail.other_info.dessert_menu && (
                          <>
                            <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                              Dessert Menu :
                            </div>
                            <div className="col-7 col-md-6 col-lg-9 text-muted ">
                              {UserDetail.other_info.dessert_menu}
                            </div>
                          </>
                        )}
                        {UserDetail.other_info.dietary_requirements && (
                          <>
                            <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                              Dietary Requirements :
                            </div>
                            <div className="col-7 col-md-6 col-lg-9 text-muted ">
                              {UserDetail.other_info.dietary_requirements}
                            </div>
                          </>
                        )}{" "}
                        {UserDetail.other_info.main_menu && (
                          <>
                            <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                              Main Menu :
                            </div>
                            <div className="col-7 col-md-6 col-lg-9 text-muted ">
                              {UserDetail.other_info.main_menu}
                            </div>
                          </>
                        )}{" "}
                        {UserDetail.other_info.starter_menu && (
                          <>
                            <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                              Starter Menu :
                            </div>
                            <div className="col-7 col-md-6 col-lg-9 text-muted ">
                              {UserDetail.other_info.starter_menu}
                            </div>
                          </>
                        )}{" "}
                        {UserDetail.other_info.tea_and_coffee_menu && (
                          <>
                            <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                              Tea And Coffee Menu :
                            </div>
                            <div className="col-7 col-md-6 col-lg-9 text-muted ">
                              {UserDetail.other_info.tea_and_coffee_menu}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          )}
          {UserDetail.tickets && UserDetail.tickets.length > 0 ? (
            <div className="pb-5">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4 col-sm-12 mt-3">
                    <h2 style={{ color: "white" }}>Tickets</h2>
                  </div>
                </div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD_tickets}
                data={UserDetail.tickets}
                is_hide={true}
                className="card-with-background shadows"
                pagePagination={true}
              />
            </div>
          ) : (
            <span>
              {UserDetail.transaction_type == "dynamite_product_purchase" ? (
                <div>
                  <div className="container-fluid">
                    <div className="row ">
                      <div className="col-lg-4 col-sm-12">
                        <h3>Billing Information</h3>
                      </div>
                    </div>
                    <div
                      className="row mt-3 p-3 card-with-background shadow"
                      style={{ borderRadius: "16px" }}
                    >
                      <table class="table table-nowrap ">
                        <thead>
                          <tr>
                            <th scope="col" className="size-table">
                              #
                            </th>
                            <th scope="col" className="size-table fixed-width">
                              Title
                            </th>

                            <th scope="col" className="size-table fixed-width">
                              Quantity
                            </th>
                            <th scope="col" className="size-table fixed-width">
                              Unit Price
                            </th>

                            <th scope="col" className="size-table fixed-width">
                              Total Price
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <tr>
                              <th className="size-table" scope="row"></th>
                              <td className="size-table"></td>
                              <td className="size-table"></td>
                              <td className="size-table"></td>
                              <td className="size-table">
                                <CircularProgress color="primary" />
                              </td>
                              <td className="size-table"></td>
                              <td className="size-table"></td>
                              <td className="size-table"></td>
                            </tr>
                          ) : (
                            <>
                              {UserDetail.product_order_id.products.length >
                                0 &&
                                UserDetail.product_order_id.products?.map(
                                  (items, index) => {
                                    console.log(
                                      items,
                                      "items.currencyitems.currency"
                                    );
                                    return (
                                      <tr>
                                        <th className="size-table" scope="row">
                                          {index + 1}
                                        </th>
                                        <td className="size-table">
                                          {items.product_id.name}
                                        </td>

                                        <td className="size-table">
                                          {`${items.quantity}`}
                                        </td>
                                        <td className="size-table">
                                          {convertCurrencyToSign(
                                            items.product_id.currency
                                          )}
                                          {items.price?.toFixed(2)}
                                        </td>
                                        <td className="size-table">
                                          {convertCurrencyToSign(
                                            items.product_id.currency
                                          )}
                                          {(
                                            items.quantity * items.price
                                          )?.toFixed(2)}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* bottom prices box */}
                  <div className="container-fluid">
                    <div class="row  mt-1 mb-4">
                      <div className="col-6"></div>
                      <div
                        className="col-6 border-div p-3  card text-end px-4 mb-2"
                        style={{ borderRadius: "16px" }}
                      >
                        <div className="justify-content-between d-flex">
                          <div>
                            <Typography sx={{ fontSize: "16px" }}>
                              Sub Total:{" "}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              sx={{ fontSize: "16px" }}
                              className="text-muted"
                            >
                              {convertCurrencyToSign(UserDetail.currency)}
                              {UserDetail.amount.toFixed(2)}
                            </Typography>
                          </div>
                        </div>
                        {ProductListDetail.tax_info && (
                          <div className="mt-2 justify-content-between d-flex">
                            <div>
                              <Typography sx={{ fontSize: "16px" }}>
                                {ProductListDetail.tax_info.tax_type_title
                                  ? ProductListDetail.tax_info.tax_type_title
                                  : ""}
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                sx={{ fontSize: "16px" }}
                                className="text-muted"
                              >
                                {convertCurrencyToSign(
                                  ProductListDetail.currency
                                )}
                                {ProductListDetail.tax_charges.toFixed(2)}
                              </Typography>
                            </div>
                          </div>
                        )}

                        <hr></hr>
                        <div className="mt-2 justify-content-between d-flex">
                          <div>
                            <Typography
                              sx={{
                                mr: 12,
                                fontSize: "16px",
                              }}
                              className="finalPrice"
                            >
                              Grand Total:
                            </Typography>
                          </div>
                          <div>
                            <Typography sx={{ fontSize: "14px" }}>
                              {convertCurrencyToSign(UserDetail.currency)}
                              {UserDetail.amount.toFixed(2)}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </span>
          )}
        </div>
        <h2 className="mb-3 mt-5">History</h2>
        {orderDeatail && orderDeatail.length > 0 ? (
          orderDeatail.map((item, index) => {
            console.log(item, "item.created_atitem.created_at");
            return (
              <>
                <Accordion
                  expanded={item.isExpanded}
                  className="question-background mb-2"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="svg-color"
                  >
                    <div className="d-flex justify-content-between w-100">
                      <div className="d-flex justify-content-between">
                        <div className=" finalPrice request-type">
                          Action By :
                        </div>
                        <div className="ms-3 text-muted">
                          {item.action_info.name +
                            " " +
                            "(" +
                            item?.action_info?.email +
                            " " +
                            "|" +
                            " " +
                            removeHyphensAndReplaceWithSpace(
                              item.action_info.action_user_type
                            ) +
                            ")"}
                        </div>
                      </div>
                      <div className="me-3 text-muted">
                        {item.createdAt
                          ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A")
                          : "N/A"}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="card mb-3">
                      {item.other_info.transaction_type ==
                      "dynamite_product_purchase" ? (
                        <Card
                          className={`payment-card ${
                            item.other_info.tickets.length > 0 ? "mb-3" : "mb-0"
                          } `}
                        >
                          <div className="row p-3">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                                  Member:
                                </div>
                                {item.other_info?.member ? (
                                  <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                                    {item.other_info.user_name +
                                      "(" +
                                      item.other_info?.member?.email +
                                      ")"}
                                  </div>
                                ) : (
                                  <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                                    {item.other_info.user_name}
                                  </div>
                                )}
                                <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                                  <p className="finalPrice">Total Amount:</p>
                                </div>
                                <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                                  {convertCurrencyToSign(
                                    item.other_info.currency
                                  )}
                                  {item.other_info.amount.toFixed(2)}
                                </div>
                                <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                                  Transaction Date:
                                </div>
                                <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                                  <Icon
                                    icon="uiw:date"
                                    className="finalPrice"
                                  />
                                  <span className="ms-1 mt-1">
                                    {moment(
                                      item.other_info.transaction_date
                                    ).format("DD-MM-YYYY hh:mm A")}
                                  </span>
                                </div>{" "}
                                <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                                  Payment Made By:
                                </div>
                                <div className="col-7 col-md-6 col-lg-3  mt-5 mb-3 mt-lg-0 text-muted">
                                  {item.other_info.payment_made_by
                                    ? item.other_info.payment_made_by
                                    : "N/A"}
                                </div>
                                <div className="col-5 col-md-6 col-lg-3 mt-5  mb-3 mt-lg-0 request-type">
                                  Transaction Mode:
                                </div>
                                <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted">
                                  {item.other_info.transaction_mode}
                                </div>
                                <div className="col-5 col-md-6 col-lg-3 mb-3 mt-5 mt-lg-0 request-type">
                                  Transaction Type:
                                </div>
                                <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                                  {get_transaction_type(item.other_info)}
                                </div>
                                {item.other_info?.transaction_note && (
                                  <>
                                    <div className="col-5 col-md-6 col-lg-3 mb-3 mt-5 mt-lg-0 request-type">
                                      Transaction Note:
                                    </div>
                                    <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                                      {item.other_info?.transaction_note}
                                    </div>
                                  </>
                                )}
                                <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                                  Transaction Status:
                                </div>
                                <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 ">
                                  <Label
                                    variant="ghost"
                                    color={
                                      item.other_info.transaction_status ===
                                      "succeeded"
                                        ? "success"
                                        : "error"
                                    }
                                  >
                                    {replace_created_for(
                                      item.other_info.transaction_status
                                    )}
                                  </Label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      ) : (
                        <Card
                          className={`payment-card ${
                            item.other_info.tickets.length > 0 ? "mb-3" : "mb-0"
                          } `}
                        >
                          <div className="row p-3">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                                  Member:
                                </div>
                                {item.other_info?.member ? (
                                  <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                                    {item.other_info.user_name +
                                      "(" +
                                      item.other_info?.member?.email +
                                      ")"}
                                  </div>
                                ) : (
                                  <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                                    {item.other_info.user_name}
                                  </div>
                                )}
                                <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                                  <p className="finalPrice">Total Amount:</p>
                                </div>
                                <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                                  {convertCurrencyToSign(
                                    item.other_info.currency
                                  )}
                                  {item.other_info.amount.toFixed(2)}
                                </div>
                                <div className="col-5 col-md-6 col-lg-2 mb-3 request-type">
                                  Transaction Date:
                                </div>
                                <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                                  <Icon
                                    icon="uiw:date"
                                    className="finalPrice"
                                  />
                                  <span className="ms-1 mt-1">
                                    {" "}
                                    {moment(
                                      item.other_info.transaction_date
                                    ).format("DD-MM-YYYY hh:mm A")}
                                  </span>
                                </div>
                                <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                                  Payment Made By:
                                </div>
                                <div className="col-7 col-md-6 col-lg-4  mt-5 mb-3 mt-lg-0 text-muted">
                                  {item.other_info.payment_made_by
                                    ? item.other_info.payment_made_by
                                    : "N/A"}
                                </div>{" "}
                                <div className="col-5 col-md-6 col-lg-3 mt-5  mb-3 mt-lg-0 request-type">
                                  Transaction Mode:
                                </div>
                                <div className="col-7 col-md-6 col-lg-2 mb-3  mt-5 mt-lg-0 text-muted">
                                  {item.other_info.transaction_mode}
                                </div>{" "}
                                <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                                  Referral User:
                                </div>
                                <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                                  {get_affliliate_info(item.other_info)}
                                </div>{" "}
                                <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                                  Transaction Status:
                                </div>
                                <div className="col-7 col-md-6 col-lg-2  mt-5 mt-lg-0 ">
                                  <Label
                                    variant="ghost"
                                    color={
                                      item.other_info.transaction_status ===
                                      "succeeded"
                                        ? "success"
                                        : "error"
                                    }
                                  >
                                    {replace_created_for(
                                      item.other_info.transaction_status
                                    )}
                                  </Label>
                                </div>
                                <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                                  Refferal Commission:
                                </div>
                                <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                                  {convertCurrencyToSign(
                                    item.other_info.currency
                                  )}
                                  {item.other_info.referral_commission
                                    ? item.other_info.referral_commission
                                    : 0}
                                </div>{" "}
                                <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                                  Dynamite Commission:
                                </div>
                                <div className="col-7 col-md-6 col-lg-2  mt-5 mt-lg-0 text-muted">
                                  {convertCurrencyToSign(
                                    item.other_info.currency
                                  )}
                                  {item.other_info.dynamite_commission
                                    ? item.other_info.dynamite_commission
                                    : 0}
                                </div>
                                <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                                  Transaction Referral:
                                </div>
                                <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                                  {get_t_ref_info(
                                    item?.other_info?.transaction_referral_info
                                  )}
                                </div>{" "}
                                <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                                  Transaction Referral Commission:
                                </div>
                                <div className="col-7 col-md-6 col-lg-2  mt-5 mt-lg-0 text-muted">
                                  {convertCurrencyToSign(
                                    item.other_info.currency
                                  )}
                                  {item.other_info
                                    .transaction_referral_commission
                                    ? item.other_info
                                        .transaction_referral_commission
                                    : 0}
                                </div>
                                <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                                  Transaction Type:
                                </div>
                                <div className="col-7 col-md-6 col-lg-9 text-muted ">
                                  {get_transaction_type(item.other_info)}
                                </div>
                                {item.other_info?.transaction_note && (
                                  <>
                                    <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                                      Transaction Note:
                                    </div>
                                    <div className="col-7 col-md-6 col-lg-9 text-muted ">
                                      {item.other_info?.transaction_note}
                                    </div>
                                  </>
                                )}
                                {item.other_info.other_info && (
                                  <>
                                    <div className="mt-3 mb-3">
                                      <h3 className="text-center">
                                        Other Information
                                      </h3>
                                    </div>
                                    {item.other_info.other_info
                                      .dessert_menu && (
                                      <>
                                        <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                                          Dessert Menu :
                                        </div>
                                        <div className="col-7 col-md-6 col-lg-9 text-muted ">
                                          {
                                            item.other_info.other_info
                                              .dessert_menu
                                          }
                                        </div>
                                      </>
                                    )}
                                    {item.other_info.other_info
                                      .dietary_requirements && (
                                      <>
                                        <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                                          Dietary Requirements :
                                        </div>
                                        <div className="col-7 col-md-6 col-lg-9 text-muted ">
                                          {
                                            item.other_info.other_info
                                              .dietary_requirements
                                          }
                                        </div>
                                      </>
                                    )}{" "}
                                    {item.other_info.other_info.main_menu && (
                                      <>
                                        <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                                          Main Menu :
                                        </div>
                                        <div className="col-7 col-md-6 col-lg-9 text-muted ">
                                          {item.other_info.other_info.main_menu}
                                        </div>
                                      </>
                                    )}{" "}
                                    {item.other_info.other_info
                                      .starter_menu && (
                                      <>
                                        <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                                          Starter Menu :
                                        </div>
                                        <div className="col-7 col-md-6 col-lg-9 text-muted ">
                                          {
                                            item.other_info.other_info
                                              .starter_menu
                                          }
                                        </div>
                                      </>
                                    )}{" "}
                                    {item.other_info.other_info
                                      .tea_and_coffee_menu && (
                                      <>
                                        <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                                          Tea And Coffee Menu :
                                        </div>
                                        <div className="col-7 col-md-6 col-lg-9 text-muted ">
                                          {
                                            item.other_info.other_info
                                              .tea_and_coffee_menu
                                          }
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card>
                      )}
                      {item.other_info.tickets &&
                      item.other_info.tickets.length > 0 ? (
                        <div className="pb-5">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-lg-4 col-sm-12 mt-3">
                                <h2 style={{ color: "White" }}>Tickets</h2>
                              </div>
                            </div>
                          </div>
                          <CustomMUITable
                            TABLE_HEAD={TABLE_HEAD_tickets}
                            data={item.other_info.tickets}
                            is_hide={true}
                            className="card-with-background shadows"
                            pagePagination={true}
                          />
                        </div>
                      ) : (
                        <span>
                          {item.other_info.transaction_type ==
                          "dynamite_product_purchase" ? (
                            <div>
                              <div className="container-fluid">
                                <div className="row ">
                                  <div className="col-lg-4 col-sm-12">
                                    <h3>Billing Information</h3>
                                  </div>
                                </div>
                                <div
                                  className="row mt-3 p-3 card-with-background "
                                  style={{ borderRadius: "16px" }}
                                >
                                  <table class="table table-nowrap ">
                                    <thead>
                                      <tr>
                                        <th scope="col" className="size-table">
                                          #
                                        </th>
                                        <th
                                          scope="col"
                                          className="size-table fixed-width"
                                        >
                                          Title
                                        </th>

                                        <th
                                          scope="col"
                                          className="size-table fixed-width"
                                        >
                                          Quantity
                                        </th>
                                        <th
                                          scope="col"
                                          className="size-table fixed-width"
                                        >
                                          Unit Price
                                        </th>

                                        <th
                                          scope="col"
                                          className="size-table fixed-width"
                                        >
                                          Total Price
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {isLoading ? (
                                        <tr>
                                          <th
                                            className="size-table"
                                            scope="row"
                                          ></th>
                                          <td className="size-table"></td>
                                          <td className="size-table"></td>
                                          <td className="size-table"></td>
                                          <td className="size-table">
                                            <CircularProgress color="primary" />
                                          </td>
                                          <td className="size-table"></td>
                                          <td className="size-table"></td>
                                          <td className="size-table"></td>
                                        </tr>
                                      ) : (
                                        <>
                                          {item.other_info.product_order_id
                                            .length > 0 &&
                                            item.other_info.product_order_id.ProductList?.map(
                                              (items, index) => {
                                                return (
                                                  <tr>
                                                    <th
                                                      className="size-table"
                                                      scope="row"
                                                    >
                                                      {index + 1}
                                                    </th>
                                                    <td className="size-table">
                                                      {items.title}
                                                    </td>

                                                    <td className="size-table">
                                                      {`${items.quantity}`}
                                                    </td>
                                                    <td className="size-table">
                                                      {convertCurrencyToSign(
                                                        ProductListDetail.currency
                                                      )}
                                                      {items.price.toFixed(2)}
                                                    </td>
                                                    <td className="size-table">
                                                      {convertCurrencyToSign(
                                                        ProductListDetail.currency
                                                      )}
                                                      {(
                                                        items.quantity *
                                                        items.price
                                                      ).toFixed(2)}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* bottom prices box */}
                              <div className="container-fluid">
                                <div class="row  mt-1 mb-4">
                                  <div className="col-6"></div>
                                  <div
                                    className="col-6 border-div p-3  card text-end px-4 mb-2"
                                    style={{ borderRadius: "16px" }}
                                  >
                                    <div className="justify-content-between d-flex">
                                      <div>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          Sub Total:{" "}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography
                                          sx={{ fontSize: "16px" }}
                                          className="text-muted"
                                        >
                                          {convertCurrencyToSign(
                                            ProductListDetail.currency
                                          )}
                                          {ProductListDetail.sub_total?.toFixed(
                                            2
                                          )}
                                        </Typography>
                                      </div>
                                    </div>
                                    {ProductListDetail.tax_info && (
                                      <div className="mt-2 justify-content-between d-flex">
                                        <div>
                                          <Typography sx={{ fontSize: "16px" }}>
                                            {ProductListDetail.tax_info
                                              .tax_type_title
                                              ? ProductListDetail.tax_info
                                                  .tax_type_title
                                              : ""}
                                          </Typography>
                                        </div>
                                        <div>
                                          <Typography
                                            sx={{ fontSize: "16px" }}
                                            className="text-muted"
                                          >
                                            {convertCurrencyToSign(
                                              ProductListDetail.currency
                                            )}
                                            {ProductListDetail.tax_charges.toFixed(
                                              2
                                            )}
                                          </Typography>
                                        </div>
                                      </div>
                                    )}

                                    <hr></hr>
                                    <div className="mt-2 justify-content-between d-flex">
                                      <div>
                                        <Typography
                                          sx={{
                                            mr: 12,
                                            fontSize: "16px",
                                          }}
                                          className="finalPrice"
                                        >
                                          Grand Total:
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography sx={{ fontSize: "14px" }}>
                                          {convertCurrencyToSign(
                                            ProductListDetail.currency
                                          )}
                                          {ProductListDetail?.total?.toFixed(2)}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </span>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })
        ) : (
          <div className="mt-5">
            <RecordNotFound title="History" />
          </div>
        )}
      </div>
    </>
  );
}
