import React from "react";
import { TextField } from "@mui/material";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function AnalyticsFilter({
  filterData,
  handleSelectOther,
  searchFunction,
  handleClearFilter,
}) {
  return (
    <div className="container-fluid new-memories">
      <div className="row">
        <div className="col-12 col-md-6 mt-3">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD-MM-YYYY"
              mask="__/__/____"
              inputFormat="DD-MM-YYYY"
              label="Start Date"
              name="start_date"
              value={filterData.start_date}
              onChange={(e) => handleSelectOther("start_date", e?.$d)}
              renderInput={(params) => (
                <TextField {...params} className="ms-2" required={true} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12 col-md-6 mt-3">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD-MM-YYYY"
              mask="__/__/____"
              inputFormat="DD-MM-YYYY"
              label="End Date"
              name="end_date"
              value={filterData.end_date}
              onChange={(e) => handleSelectOther("end_date", e?.$d)}
              renderInput={(params) => (
                <TextField {...params} className="ms-2" required={true} />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="col-12 text-end d-flex justify-content-end mt-3">
        <div>
          <button
            className="small-contained-button me-2"
            onClick={handleClearFilter}
          >
            Clear Filter
          </button>
          <button
            className="small-contained-button  mt-1 mb-2"
            onClick={searchFunction}
          >
            Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div>
      </div>
    </div>
  );
}
