import React, { useEffect, useState } from "react";
import { Card, Chip, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { clientPortalUrl, project_mode, s3baseUrl } from "src/config/config";
import Label from "src/components/Label";
import {
  memberDetailProfileApi,
  member_login_api,
  reset_assement_api,
} from "src/DAL/member/member";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { dummyImage, wheelIcon } from "src/assets";
import {
  COMMUNITY_LEVELS,
  dd_date_format,
  show_proper_words,
} from "src/utils/constant";
import moment from "moment";
import { fShortenNumber, get_kmb_number } from "src/utils/formatNumber";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import ManageProgrammeAccess from "./ProfileTabs/ManageProgrammeAccess";
import MemberDiaryList from "./ProfileTabs/MemberDiaryList";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import ChangeLeadStatus from "./ChangeLeadStatus";
import MemberPods from "./ProfileTabs/MemberPods";
import ManageLevelAccess from "./ProfileTabs/ManageLevelAccess";
import ManageRewardAccess from "./ProfileTabs/ManageRewardAccess";
import MemberCalender from "./ProfileTabs/MemberCalender";
import MemberSubscriptions from "./ProfileTabs/MemberSubscriptions";
import MemberTransactions from "./ProfileTabs/MemberTransactions";
import MemberPersonalNote from "./ProfileTabs/MemberPersonalNote";
import QuestionsAnswersForUser from "./ProfileTabs/QuestionsAnswersForUser";
import MembersGroupList from "./ProfileTabs/MembersGroupList";
import ToDoTracker from "./ProfileTabs/ToDoTracker";
import ChangePassword from "src/components/MembersList/ChangePassword";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import ChangeAffiliateID from "./ProfileTabs/ChangeAffiliateID";
import HistoryIcon from "@mui/icons-material/History";
import History from "./History";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import LeadStatusHistory from "./LeadStatusHistory";
import Events from "./ProfileTabs/Events";
import { htmlDecode } from "src/utils/convertHtml";
import MemberSubscribers from "./ProfileTabs/MemberSubscribers";
import MemberGoalstatementList from "./ProfileTabs/MemberGoalstatementList";
import DetailQuestion from "./ProfileTabs/DetailQuestion";
import MemberTransactionsFilter from "./MemberTransactionsFilter";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import Navitems from "./ProfileTabs/Navitems";
import CoinsHistory from "./CoinsHistory";
import BookingListTab from "./ProfileTabs/BookingListTab";
import OrderHistoryProfile from "./ViewBillings/OrderHistoryProfile";
import BadgesHistory from "./ProfileTabs/BadgesHistory";
import ResourceHistory from "./ProfileTabs/ResourceHistory";
import BonusContentAccess from "./ProfileTabs/BonusContentAccess";
import CommissionTabs from "./ProfileTabs/CommissionTabs";
import DelegateMembers from "./ProfileTabs/DelegateMembers";
import ChllengesMembers from "./ProfileTabs/ChllengesMembers";
import WellcomeReminderUser from "./ViewBillings/WellcomeReminderUser";
import NinetyDayHistory from "./NinetyDayHistory";
import LoginSession from "./ProfileTabs/LoginSession";
import SelfImageHistory from "src/components/MembersList/SelfImageHistory";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

let status_object = [
  "programmes",
  "events",
  "transactions",
  "subscriptions",
  "member_subscriptions",
  "booking_list",
  "levels",
  "bonus_content_category",
  "rewards",
  "groups",
  "calendar_events",
  "pods",
  "diary",
  "client_notes",
  "to_do_tracker",
  "question_answers",
  "nav_items",
  "coins_history",
  "order_history",
  "badges_history",
  "resource_access_history",
  "sales",
  "members",
  "challenge",
  "welcome_reminder",
  "ninty_day_plan",
  "self_image_history",
  "login_sessions",
];

const MemberProfileNew = () => {
  const member_id = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isTabLoading, setIsTabLoading] = useState(false);
  const [memberDetail, setMemberDetail] = useState({});
  const [cardDetail, setCardDetail] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [changeStatus, setChangeStatus] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [leadStatusData, setleadStatusData] = useState({});
  const [tabData, setTabData] = useState([]);
  const [selectedObject, setSelectedObject] = useState("");
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [changeAffiliateOpen, setChangeAffiliateOpen] = useState(false);
  const [openResetAssessment, setOpenResetAssessment] = useState(false);
  const [referredpop, setReferredpop] = useState(false);
  const [ChangeDelegateOpen, setChangeDelegateOpen] = useState(false);
  const [openMarketingAffliliate, setOpenMarketingAffliliate] = useState(false);
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);
  const [ChangeLeadstatus, setChangeLeadstatus] = useState(false);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [isAllDates, setIsAllDates] = useState(false);
  const ALTER_FILTER = {
    transaction_status: "",
    payment_made_by: "",
  };
  const EMPTY_FILTER = {
    start_date: null,
    end_date: null,
    date: null,
    payment_made_by: "all",
    sale_page: null,
    payment_plan: null,
    filter_by_plateform: null,
    payment_request: [],
    transaction_type: "all",
    transaction_for: "all",
    user_id: null,
    transaction_status: "succeeded",
    transaction_mode: project_mode == "dev" ? ["sandbox"] : ["live"],
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);
  const [defaultTimeZone, setDefaultTimeZone] = useState("");
  const done_member = window.location.pathname.includes("/done-listing");
  const [value, setValue] = React.useState("1");

  let route_name = "/member/";
  if (done_member) {
    route_name = "/done-listing/";
  }
  // ------------------------ filter start
  const searchFunction = () => {
    if (filterData.transaction_for === "all") {
      filterData.user_id = null;
    }
    memberProfileInfo(filterData);
    localStorage.setItem(
      "member_payment_transactions_filter",
      JSON.stringify(filterData)
    );
    handleCloseFilterDrawer();
  };
  const handleChangeOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };
  const handleClearFilter = () => {
    let clear_data = {
      ...EMPTY_FILTER,
      transaction_mode: [],
      transaction_status: "",
    };
    setFilterData(clear_data);
    memberProfileInfo(clear_data);
    localStorage.removeItem("member_payment_transactions_filter");
    handleCloseFilterDrawer();
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handleDeleteChip = (data) => {
    if (!data.date) {
      delete data.date;
      data.start_date = null;
      data.end_date = null;
      setIsAllDates(false);
    }
    if (data.transaction_for === "all") {
      data.user_id = null;
    }
    memberProfileInfo(data);
    setFilterData(data);
    setFilterStateUpdated(data);
    localStorage.setItem(
      "member_payment_transactions_filter",
      JSON.stringify(data)
    );
  };

  // --------------------------end
  const memberProfileInfo = async (filter_data) => {
    setIsTabLoading(true);
    let tab_name = status_object[tabValue];
    if (tab_name == "programmes") {
      tab_name = "";
    }
    let postData = {};
    if (tab_name == "transactions") {
      postData = { ...filter_data };

      if (postData.transaction_type == "sale_page") {
        postData.sale_page = filter_data.sale_page
          ? [filter_data.sale_page._id]
          : [];
      } else {
        postData.sale_page = [];
      }

      if (postData.payment_plan) {
        postData.payment_plan = filter_data.payment_plan._id;
      }

      if (postData.transaction_type == "payment_request") {
        postData.payment_request = filter_data.payment_request.map((item) => {
          return item._id;
        });
      }

      if (postData.start_date) {
        postData.start_date = moment(filter_data.start_date).format(
          "YYYY-MM-DD"
        );
      }

      if (postData.end_date) {
        postData.end_date = moment(filter_data.end_date).format("YYYY-MM-DD");
      }

      if (postData.filter_by_plateform) {
        postData.filter_by_plateform =
          filter_data.filter_by_plateform.chip_value;
      }

      if (postData.transaction_type !== "sale_page") {
        postData.sale_page = null;
        postData.payment_plan = null;
      }
      if (postData.transaction_type !== "payment_request") {
        postData.payment_request = [];
      }
      if (postData.payment_made_by == "all") {
        postData.payment_made_by = "";
      }

      if (postData.transaction_for == "delegates") {
        postData.user_type = "delegate_user";
      } else if (postData.transaction_for == "public") {
        postData.user_type = "public_user";
      } else {
        postData.user_type = postData.transaction_for;
      }

      if (postData.user_id) {
        postData.user_id = postData.user_id?._id;
      }

      if (postData.transaction_for == "all") {
        postData.user_id = null;
      }
    } else if (tab_name == "sales") {
      postData.type = value == "1" ? "credit" : "paid";
    }
    const result = await memberDetailProfileApi(
      member_id.id,
      tab_name,
      postData
    );
    if (result.code == 200) {
      let chipData = {};
      if (tab_name == "transactions") {
        chipData = { ...filter_data };
        if (chipData.start_date) {
          let date = `Start Date : ${dd_date_format(
            chipData.start_date
          )} End Date :  ${dd_date_format(chipData.end_date)}`;
          chipData.date = {
            chip_label: date,
            chip_value: date,
          };
          delete chipData.start_date;
          delete chipData.end_date;
        }
        if (chipData.transaction_type == "all") {
          delete chipData.transaction_type;
        }
        if (chipData.transaction_for == "all") {
          delete chipData.transaction_for;
          delete chipData.user_id;
        }
        if (chipData.transaction_for == "public") {
          chipData.transaction_for = "Public User";
        }
        if (chipData.transaction_for == "delegates") {
          chipData.transaction_for = "Delegates User";
        }
        if (chipData.transaction_type !== "sale_page") {
          delete chipData.sale_page;
          delete chipData.payment_plan;
        }
        if (chipData.transaction_type !== "payment_request") {
          delete chipData.payment_request;
        }

        setFilterStateUpdated(chipData);
      }
      if (!tab_name) {
        setCardDetail(result.commission);
        setMemberDetail(result.member_info);
      }
      setTabData(result.tab_data);

      setDefaultTimeZone(result?.time_zone);
      setIsLoading(false);

      setIsTabLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsTabLoading(false);
    }
  };

  const handleResetAssement = async () => {
    setOpenResetAssessment(false);
    const result = await reset_assement_api(selectedObject._id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event, newValue) => {
    setTabData([]);
    setTabValue(newValue);
  };

  const handleChangePasswordOpen = (value) => {
    setSelectedObject(value);
    setChangePasswordOpen(true);
  };

  const handleChangeAffiliateOpen = (value) => {
    setSelectedObject(value);
    setChangeAffiliateOpen(true);
  };

  const handleAgreeResetAssessment = (value) => {
    setSelectedObject(value);
    setOpenResetAssessment(true);
  };

  const handleUpdateclientNote = (values) => {
    setTabData(values.personal_note);
  };

  const TABS_OPTIONS = [
    {
      title: "Programmes",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <ManageProgrammeAccess tabData={tabData} memberDetail={memberDetail} />
      ),
    },
    {
      title: "Portals",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <Events tabData={tabData} />
      ),
    },
    {
      title: "Transactions",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <MemberTransactions
          tabData={tabData}
          filterStateUpdated={filterStateUpdated}
          filterData={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          ALTER_FILTER={ALTER_FILTER}
          handleDeleteChip={handleDeleteChip}
          handleClearFilter={handleClearFilter}
          handleOpenFilterDrawer={handleOpenFilterDrawer}
        />
      ),
    },
    {
      title: "Subscribers",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <MemberSubscribers tabData={tabData} />
      ),
    },
    {
      title: "Subscriptions ",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <MemberSubscriptions tabData={tabData} />
      ),
    },
    {
      title: "Bookings",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <BookingListTab tabData={tabData} />
      ),
    },
    {
      title: "Levels",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <ManageLevelAccess tabData={tabData} />
      ),
    },
    {
      title: "Bonus Content",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <BonusContentAccess tabData={tabData} />
      ),
    },
    {
      title: "Rewards",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <ManageRewardAccess tabData={tabData} />
      ),
    },
    {
      title: "Groups",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <MembersGroupList tabData={tabData} />
      ),
    },
    {
      title: "Calender Events",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <MemberCalender
          memberProfileInfo={memberProfileInfo}
          defaultTimeZone={defaultTimeZone}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          tabData={tabData}
        />
      ),
    },
    {
      title: "Pods",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <MemberPods tabData={tabData} />
      ),
    },
    {
      title: "Diary",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <MemberDiaryList tabData={tabData} />
      ),
    },
    {
      title: "Client Notes",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <MemberPersonalNote
          tabData={tabData}
          handleUpdateclientNote={handleUpdateclientNote}
        />
      ),
    },
    {
      title: "To Do Tracker",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <ToDoTracker tabData={tabData} />
      ),
    },
    {
      title: "Questions Answers",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <QuestionsAnswersForUser tabData={tabData} />
      ),
    },
    {
      title: "Nav Items",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <Navitems tabData={tabData} />
      ),
    },
    {
      title: "Coins History",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CoinsHistory />
      ),
    },
    {
      title: "Order History",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <OrderHistoryProfile tabData={tabData} />
      ),
    },
    {
      title: "Badges History",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <BadgesHistory tabData={tabData} memberDetail={memberDetail} />
      ),
    },
    {
      title: "Resource History",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <ResourceHistory tabData={tabData} memberDetail={memberDetail} />
      ),
    },
    {
      title: "Commission Detail",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CommissionTabs
          tabData={tabData}
          memberDetail={memberDetail}
          value={value}
          setValue={setValue}
        />
      ),
    },
    {
      title: "Affiliated Members",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <DelegateMembers tabData={tabData} memberDetail={memberDetail} />
      ),
    },
    {
      title: "challenges",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <ChllengesMembers tabData={tabData} />
      ),
    },
    {
      title: "Welcome Reminders message ",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <WellcomeReminderUser tabData={tabData} />
      ),
    },
    {
      title: "90 Day History",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <NinetyDayHistory tabData={tabData} userInfo={memberDetail} />
      ),
    },
    {
      title: "Self Image History",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <SelfImageHistory tabData={tabData} userInfo={memberDetail} />
      ),
    },
    {
      title: "Login Sessions",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <LoginSession tabData={tabData} />
      ),
    },
  ];

  const handleCopiedUrl = () => {
    enqueueSnackbar("Referral Id Copied to clipboard", { variant: "success" });
  };

  const change_lead_status = (value) => {
    setSelectedMember(value);
    setChangeStatus(true);
  };

  const LoginAsMember = async () => {
    setIsLoading(true);
    const data = {
      email: memberDetail.email,
      user_type: "client",
    };
    const result = await member_login_api(data);
    if (result.code === 200) {
      window.open(`${clientPortalUrl}login-by-admin/${result.token}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handle_change_affiliate_name = (values) => {
    setMemberDetail((old) => {
      return {
        ...old,
        affiliate_url_name: values.affiliate_url_name,
      };
    });
  };

  const handleUpdateStatus = (values) => {
    setMemberDetail((old) => {
      return {
        ...old,
        lead_status: values.lead_status,
      };
    });
  };

  const get_short_string = (str, limit = 30) => {
    return str && str.length < limit ? str : str?.slice(0, limit) + "...";
  };

  const show_history_reffered = (value) => {
    setleadStatusData(value);
    setReferredpop(true);
  };

  const show_marketing_history_reffered = (value) => {
    setleadStatusData(value);
    setOpenMarketingAffliliate(true);
  };

  const show_history = (value) => {
    setleadStatusData(value);
    setChangeStatusOpen(true);
  };

  const show_history_delegate = (value) => {
    setleadStatusData(value);
    setChangeDelegateOpen(true);
  };

  const show_history_lead_status = (value) => {
    setleadStatusData(value);
    setChangeLeadstatus(true);
  };

  const get_nurture_member = (data) => {
    if (data.nurture) {
      return data.nurture.first_name + " " + data.nurture.last_name;
    }
  };

  const affiliate_info = (data) => {
    const affiliate_info = data?.affiliate_user_info;
    if (affiliate_info) {
      return affiliate_info.first_name + " " + affiliate_info.last_name;
    }
    return "Master Link";
  };

  const goal_statement_text = (data) => {
    let goal_statement_status = data.goal_statement_completed_status;
    if (goal_statement_status && data.save_and_close_status) {
      return "Responded (" + dd_date_format(data.save_and_close_date) + ")";
    } else if (goal_statement_status && !data.save_and_close_status) {
      return (
        "Completed (" + dd_date_format(data.goal_statement_completed_date) + ")"
      );
    }
    return "Incomplete";
  };

  const replace_user_type = (data) => {
    let replace_user_type = "IOS";
    if (data.member_created_by_platform !== "ios") {
      return show_proper_words(data.member_created_by_platform);
    }
    return replace_user_type;
  };

  const lead_status_change = (data) => {
    let lead_status = data.lead_status;
    if (lead_status) {
      return (
        <Tooltip title={lead_status.title}>
          <Chip
            label={get_short_string(htmlDecode(lead_status.title), 18)}
            style={{
              backgroundColor: lead_status.background_color,
              color: lead_status.text_color,
              height: "25px",
            }}
          />
        </Tooltip>
      );
    }
    return "N/A";
  };

  const membership_expiry = (data) => {
    if (data.membership_purchase_expiry) {
      return data.membership_purchase_expiry;
    }
    return "N/A";
  };

  const get_delegate_member = (data) => {
    if (data.consultant) {
      return data.consultant.first_name + " " + data.consultant.last_name;
    } else {
      return "N/A";
    }
  };
  const community_level = (data) => {
    let find_level = COMMUNITY_LEVELS.find(
      (level) => level.name == data.community_level
    );
    return find_level?.title ? find_level?.title : "N/A";
  };

  const is_wheel_of_life = (data) => {
    if (data.is_wheel_of_life) {
      return (
        <span className="me-2">
          <Tooltip title={"Wheel of life is completed"}>
            <img
              src={wheelIcon}
              style={{ maxWidth: "8%", display: "inline-block" }}
            />
          </Tooltip>
        </span>
      );
    }
    return "N/A";
  };

  let breadCrumbMenu = [
    {
      title: "Members",
      navigation: `/member`,
      active: false,
    },
    {
      title:
        memberDetail?.first_name +
        " " +
        memberDetail?.last_name +
        " (" +
        memberDetail?.email +
        ")",
      active: true,
    },
  ];

  const handle_goal_unlock_string = (data) => {
    const { program_info, accees_from_info, goal_statement_unlock_date } = data;

    let return_string = "Unlock Date: " + goal_statement_unlock_date;
    return_string += " || Program: " + program_info?.title;

    if (accees_from_info.type === "payment_request") {
      return_string += " || Payment Request: " + accees_from_info.request_title;
    } else if (accees_from_info.type === "plan_subscription") {
      return_string += " || Sale Page: " + accees_from_info.sale_page_title;
      return_string += " || Plan: " + accees_from_info.plan_title;
    } else if (accees_from_info.type === "program_assigned_from_admin_portal") {
      return_string += " || Admin: " + accees_from_info.name;
      return_string += " (" + accees_from_info.email + ")";
    }

    return return_string;
  };

  const handleEdit = (value) => {
    navigate(`${route_name}edit-member/${value._id}`, {
      state: value,
    });
  };

  const handleChangeAccess = (value) => {
    navigate(`${route_name}programme-access/${value._id}`, {
      state: value,
    });
  };
  const handleBonusContentAccess = (value) => {
    navigate(`${route_name}bonus-content-access/${value._id}`, {
      state: value,
    });
  };
  const handleChangeLevelAccess = (value) => {
    navigate(`${route_name}level-access/${value._id}`, {
      state: value,
    });
  };

  const handleChangeRewardAccess = (value) => {
    navigate(`${route_name}reward-access/${value._id}`, {
      state: value,
    });
  };
  const ManageNavItemsAccess = (value) => {
    navigate(`${route_name}manage-navitems-access/${value._id}`, {
      state: value,
    });
  };

  const handleEventAccess = (value) => {
    navigate(`${route_name}event-access/${value._id}`, {
      state: value,
    });
  };

  useEffect(() => {
    if (tabValue !== 15) {
      if (tabValue == 2) {
        let filter_data = filterData;
        let find_filter = localStorage.getItem(
          "member_payment_transactions_filter"
        );
        if (find_filter) {
          filter_data = JSON.parse(find_filter);
        }
        setFilterData(filter_data);
        setFilterStateUpdated(filter_data);
        memberProfileInfo(filter_data);
      } else {
        memberProfileInfo();
      }
    }
  }, [tabValue, value]);

  if (memberDetail?.is_wheel_of_life_enable && memberDetail?.is_wheel_of_life) {
    status_object.push("wheel_of_life");
    TABS_OPTIONS.push({
      title: "Wheel of Life",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <DetailQuestion tabData={tabData} setTabData={setTabData} />
      ),
    });
  }
  if (memberDetail?.goal_statement_status == true) {
    status_object.push("goal_statement");
    TABS_OPTIONS.push({
      title: "Goal Statement",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <MemberGoalstatementList tabData={tabData} setTabData={setTabData} />
      ),
    });
  }

  const MENU_OPTIONS = [
    {
      label: "Edit Profile",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Login As Member",
      icon: "ant-design:lock",
      handleClick: LoginAsMember,
    },
    {
      label: "Manage Programme Access",
      icon: "ant-design:key",
      handleClick: handleChangeAccess,
    },
    {
      label: "Manage Bonus Content Access",
      icon: "ant-design:key",
      handleClick: handleBonusContentAccess,
    },
    {
      label: "Manage Dynamite Event Access",
      icon: "ant-design:key",
      handleClick: handleEventAccess,
    },
    {
      label: "Manage Level Access",
      icon: "ant-design:key",
      handleClick: handleChangeLevelAccess,
    },
    {
      label: "Manage Reward Access",
      icon: "streamline:shopping-gift-reward-box-social-present-gift-media-rating-bow",
      handleClick: handleChangeRewardAccess,
    },
    {
      label: "Manage Nav Items Access",
      icon: "fluent:video-recording-20-regular",
      handleClick: ManageNavItemsAccess,
    },
    {
      label: "Change Lead Status",
      icon: "akar-icons:edit",
      handleClick: change_lead_status,
    },
    {
      label: "Change Password",
      icon: "ant-design:lock",
      handleClick: handleChangePasswordOpen,
    },
    {
      label: "Change Affiliate ID",
      icon: "ant-design:lock",
      handleClick: handleChangeAffiliateOpen,
    },
    {
      label: "Reset Attitude Assessment",
      icon: "ant-design:lock",
      handleClick: handleAgreeResetAssessment,
    },
  ];

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const completed_challenges = memberDetail.challange?.reduce(
    (count, challenge) => {
      return challenge.is_complete ? count + 1 : count;
    },
    0
  );

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-12">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-12 mt-3">
          <h2>Member Profile</h2>
        </div>
        <div className="col-12 col-md-3 mt-3">
          <div className="profile-card">
            <h3>£{fShortenNumber(cardDetail.total_amount_paid)}</h3>
            <h6 className="text-muted">Total Paid Amount</h6>
          </div>
        </div>
        <div className="col-12 col-md-3 mt-3">
          <div className="profile-card">
            <h3>£{fShortenNumber(cardDetail.total_commission)}</h3>
            <h6 className="text-muted">Total Referral Commission</h6>
          </div>
        </div>
        <div className="col-12 col-md-3 mt-3">
          <div className="profile-card">
            <h3>£{fShortenNumber(cardDetail.total_paid_commission_amount)}</h3>
            <h6 className="text-muted">Paid Referral Commission</h6>
          </div>
        </div>
        <div className="col-12 col-md-3 mt-3">
          <div className="profile-card">
            <h3>£{fShortenNumber(cardDetail.remaining_commission)}</h3>
            <h6 className="text-muted">Pending Referral Commission</h6>
          </div>
        </div>
        <div className="col-12 mt-3 user-profile-detail">
          <Card>
            <div className="row mb-4 mx-3 mt-3 justify-content-center align-items-center user-profile-data">
              <div className="col-12 col-md-7 d-flex align-items-center">
                <img
                  className="rounded-circle"
                  height="75px"
                  width="75px"
                  src={
                    memberDetail.profile_image === undefined ||
                    memberDetail.profile_image === ""
                      ? dummyImage
                      : s3baseUrl + memberDetail.profile_image
                  }
                />
                <div className="ms-3">
                  <h2>
                    {memberDetail.first_name + " " + memberDetail.last_name}
                  </h2>
                  <div className="text-muted">{memberDetail.email}</div>
                </div>
              </div>
              <div className="col-12 col-md-5 d-flex justify-content-end">
                <div className="d-flex me-1 text-muted">
                  <div>Created At:&nbsp;</div>
                  {dd_date_format(memberDetail.createdAt)}
                </div>
                <CustomPopoverSection menu={MENU_OPTIONS} data={memberDetail} />
              </div>
            </div>
            <div className="row mx-3 mt-3 mb-5 user-profile-data">
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Last Login Activity:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {memberDetail?.last_login_activity
                  ? moment(memberDetail?.last_login_activity).format(
                      "DD-MM-YYYY hh:mm A"
                    )
                  : "N/A"}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Upcoming Assessment Date:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {memberDetail?.upcomming_attitude_assessment_date
                  ? moment(
                      memberDetail?.upcomming_attitude_assessment_date
                    ).format("DD-MM-YYYY")
                  : "N/A"}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Phone Number:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {memberDetail.contact_number}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Referred User:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                <span
                  className="me-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => show_history_reffered(memberDetail)}
                >
                  <Tooltip title="View Referred History">
                    <HistoryIcon />
                  </Tooltip>
                </span>
                {affiliate_info(memberDetail.affliliate)}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Marketing Affliliate User:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                <span
                  className="me-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => show_marketing_history_reffered(memberDetail)}
                >
                  <Tooltip title="View Marketing Affliliate History">
                    <HistoryIcon />
                  </Tooltip>
                </span>
                {affiliate_info(memberDetail.marketing_affliliate)}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Nurture:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                <span
                  className="me-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => show_history(memberDetail)}
                >
                  <Tooltip title="View Nurture History">
                    <HistoryIcon />
                  </Tooltip>
                </span>
                {get_nurture_member(memberDetail)}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Delegate:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                <span
                  className="me-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => show_history_delegate(memberDetail)}
                >
                  <Tooltip title="View Delegate History">
                    <HistoryIcon />
                  </Tooltip>
                </span>
                {get_delegate_member(memberDetail)}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Lead Status:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {memberDetail?.lead_status && (
                  <span
                    className="me-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => show_history_lead_status(memberDetail)}
                  >
                    <Tooltip title="View Lead Status History">
                      <HistoryIcon />
                    </Tooltip>
                  </span>
                )}
                {lead_status_change(memberDetail)}
              </div>

              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Membership Expire:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {membership_expiry(memberDetail)}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Community Level:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {community_level(memberDetail)}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Wheel Of Life Enable:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {memberDetail.is_wheel_of_life_enable ? "Yes" : "No"}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Wheel Of Life Complete:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {is_wheel_of_life(memberDetail)}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Status:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                <Label
                  variant="ghost"
                  color={memberDetail.status === false ? "error" : "success"}
                >
                  {memberDetail.status === false ? "Inactive" : "Active"}
                </Label>
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Goal:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                <Label
                  variant="ghost"
                  color={
                    memberDetail.goal_statement_status === true
                      ? "success"
                      : "error"
                  }
                >
                  {memberDetail.goal_statement_status === true
                    ? "Unlocked"
                    : "Locked"}
                </Label>
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Goal Statement:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {goal_statement_text(memberDetail)}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Created By Platform:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {replace_user_type(memberDetail)}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Total Coins:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {get_kmb_number(memberDetail.coins_count)}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Daily Intention Coins:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {get_kmb_number(memberDetail.dynamite_diary_coins_count)}
              </div>

              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Gratitude Coins:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {get_kmb_number(memberDetail.dynamite_gratitude_coins_count)}
              </div>

              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Assessment Coins:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {get_kmb_number(memberDetail.attitude_assessment_coins_count)}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>Meditation Coins:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {get_kmb_number(memberDetail.meditation_coins_count)}
              </div>
              <div className="col-5 col-md-6 col-lg-3 mb-3">
                <h6>No. of Completed Challenges:</h6>
              </div>
              <div className="col-7 col-md-6 col-lg-3 text-muted">
                {get_kmb_number(completed_challenges)}
              </div>
              {memberDetail.affiliate_url_name && (
                <>
                  <div className="col-5 col-md-6 col-lg-3 mb-3">
                    <h6>Copy Referral ID: </h6>
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 text-muted ">
                    <CopyToClipboard
                      className="pointer me-2"
                      text={memberDetail.affiliate_url_name}
                      onCopy={() => handleCopiedUrl(true)}
                    >
                      <Tooltip title="Copy Referral ID">
                        <ContentCopyIcon
                          style={{
                            fontSize: "20px",
                          }}
                        />
                      </Tooltip>
                    </CopyToClipboard>
                    {memberDetail.affiliate_url_name}
                  </div>
                </>
              )}
              {memberDetail?.register_url && (
                <div className="col-12 d-flex mb-3">
                  <h6 className="profile_url">Registration Url:</h6>
                  <div className="text-muted ">
                    {memberDetail?.register_url}
                  </div>
                </div>
              )}
              {memberDetail.goal_statement_unlock_obj && (
                <>
                  <div className="col-12 d-flex">
                    <h6>Goal Statement Unlock History:</h6>
                    <div className="text-muted">
                      {handle_goal_unlock_string(
                        memberDetail.goal_statement_unlock_obj.other_info
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </Card>
        </div>
        <div className="col-12 mt-5">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
      <CustomConfirmation
        open={openResetAssessment}
        setOpen={setOpenResetAssessment}
        title={"Are you sure you want to reset Assessment History?"}
        handleAgree={handleResetAssement}
      />
      <GeneralPopUpModel
        open={changeStatus}
        setOpen={setChangeStatus}
        title={"Change Lead Status"}
        componentToPassDown={
          <ChangeLeadStatus
            selectedObject={selectedMember}
            handleUpdateStatus={handleUpdateStatus}
            setOpen={setChangeStatus}
          />
        }
      />
      <GeneralPopUpModel
        open={changePasswordOpen}
        setOpen={setChangePasswordOpen}
        title={"Change Password"}
        componentToPassDown={
          <ChangePassword
            selectedObject={selectedObject}
            setOpen={setChangePasswordOpen}
          />
        }
      />
      <GeneralPopUpModel
        open={changeAffiliateOpen}
        setOpen={setChangeAffiliateOpen}
        title={"Change Affiliate ID"}
        componentToPassDown={
          <ChangeAffiliateID
            selectedObject={selectedObject}
            memberDetailInfo={handle_change_affiliate_name}
            setOpen={setChangeAffiliateOpen}
          />
        }
      />
      <WhatsappPopUpModel
        open={referredpop}
        setOpen={setReferredpop}
        title={"Referred History"}
        show_date_and_income={true}
        componentToPassDown={
          <History
            setOpen={setReferredpop}
            history={history}
            type="affiliate"
            leadStatusData={leadStatusData}
          />
        }
      />
      <WhatsappPopUpModel
        open={changeStatusOpen}
        setOpen={setChangeStatusOpen}
        title={"Nurture History"}
        show_date_and_income={true}
        componentToPassDown={
          <History
            setOpen={setChangeStatusOpen}
            history={history}
            type="nurture"
            leadStatusData={leadStatusData}
          />
        }
      />
      <WhatsappPopUpModel
        open={ChangeDelegateOpen}
        setOpen={setChangeDelegateOpen}
        title={"Delegate History"}
        show_date_and_income={true}
        componentToPassDown={
          <History
            setOpen={setChangeDelegateOpen}
            history={history}
            type="consultant"
            leadStatusData={leadStatusData}
          />
        }
      />
      <WhatsappPopUpModel
        open={openMarketingAffliliate}
        setOpen={setOpenMarketingAffliliate}
        title={"Marketing Affliliate History"}
        show_date_and_income={true}
        componentToPassDown={
          <History
            setOpen={setOpenMarketingAffliliate}
            history={history}
            type="marketing_affliliate"
            leadStatusData={leadStatusData}
          />
        }
      />
      <WhatsappPopUpModel
        open={ChangeLeadstatus}
        setOpen={setChangeLeadstatus}
        title={"Lead Status History"}
        show_date_and_income={true}
        componentToPassDown={
          <LeadStatusHistory
            setOpen={setChangeLeadstatus}
            history={history}
            leadStatusData={leadStatusData}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <MemberTransactionsFilter
            filterData={filterData}
            isAllDates={isAllDates}
            setIsAllDates={setIsAllDates}
            handleClearFilter={handleClearFilter}
            handleChangeOthers={handleChangeOthers}
            searchSubmitFilter={searchFunction}
          />
        }
      />
    </div>
  );
};

export default MemberProfileNew;
