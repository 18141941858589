import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Chip, IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { memberTransactionListApi } from "src/DAL/member/member";
import { dd_date_format } from "src/utils/constant";
import { convertCurrencyToSign } from "src/utils/constant";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "member_name", label: "Member", alignRight: false },
  { id: "totat_amount", label: "Amount", alignRight: false },
  { id: "transactionDate", label: "Transaction Date", alignRight: false },
  { id: "referral_name", label: "Referral User", alignRight: false },
  { id: "transactionType", label: "Transaction Type", alignRight: false },
  {
    id: "transactionType",
    label: "Status",
    alignRight: false,
    renderData: (row) => {
      return (
        <Chip
          width="140px"
          label={
            row.transaction_status === "succeeded"
              ? "Successful"
              : "Unsuccessful"
          }
          variant="contained"
          color={row.transaction_status === "succeeded" ? "success" : "error"}
          size="small"
        />
      );
    },
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EventVideoCategories() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { member_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [transactionsData, setTransactionsData] = useState([]);

  const getTransactionsListing = async () => {
    const result = await memberTransactionListApi(member_id);
    if (result.code == 200) {
      const transactions = result.transaction.map((transaction) => {
        let member_name = "";
        let referral_name = "No Referred User";
        let member_info = transaction.member_info;
        member_name =
          member_info.first_name +
          " " +
          member_info.last_name +
          " (" +
          member_info.email +
          ")";

        let transactionType = "N/A";
        if (
          transaction.transaction_type == "payment_plan" &&
          transaction.sale_page?.sale_page_title
        ) {
          transactionType =
            "Sale Page (" + transaction.sale_page.sale_page_title + ")";
        } else if (
          transaction.transaction_type == "payment_request" &&
          transaction.payment_request_info?.request_title
        ) {
          transactionType =
            "Payment Request (" +
            transaction.payment_request_info.request_title +
            ")";
        } else if (
          transaction.transaction_type == "dynamite_product_purchase"
        ) {
          transactionType = "Dynamite Shop";
        }

        let affliliate = transaction.affiliate_info;
        let affiliate_info = affliliate?.affiliate_user_info;
        if (affiliate_info) {
          referral_name =
            affiliate_info.first_name + " " + affiliate_info.last_name;
        }
        if (affliliate?.affiliate_url_name) {
          referral_name =
            referral_name + "(" + affliliate.affiliate_url_name + ")";
        }

        return {
          ...transaction,
          member_name: member_name,
          transactionType: transactionType,
          totat_amount:
            convertCurrencyToSign(transaction.currency) + transaction.amount,
          referral_name: referral_name,
          transactionDate: dd_date_format(transaction.transaction_date),
        };
      });
      setTransactionsData(transactions);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTransactionsListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="row mb-3">
          <div className="col-lg-6 col-sm-12">
            <h2>All Transactions</h2>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={transactionsData}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
