import * as React from "react";
import Popover from "@mui/material/Popover";
import { Tooltip } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { dd_date_format } from "src/utils/constant";
import CircleIcon from "@mui/icons-material/Circle";

export default function MemberEventPopOverHistory({
  handleClose,
  anchorEl,
  id,
  open,
  event,
}) {
  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ heigth: 100 }}
      >
        <div className="sticky-top card">
          <p className="heading_programs">Portals</p>
          <span className="icon-container" onClick={() => handleClose()}>
            <CancelOutlinedIcon fontSize="small" />
          </span>
        </div>
        {event?.map((item, index) => {
          return (
            <div className="d-flex">
              <Tooltip
                key={index}
                title={
                  item.expiry_date ? (
                    <div>
                      {item?.start_date ? (
                        <>
                          Start Date: {dd_date_format(item?.start_date)}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      Expiry: {dd_date_format(item?.expiry_date)}
                    </div>
                  ) : (
                    <div>
                      {item?.start_date ? (
                        <>
                          Start Date: {dd_date_format(item?.start_date)}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      No Expiry
                    </div>
                  )
                }
                arrow
              >
                <p
                  className="mb-1 program-hover"
                  style={{ padding: "2px 5px 2px 8px" }}
                >
                  {item?.title}
                </p>
              </Tooltip>
            </div>
          );
        })}
      </Popover>
    </div>
  );
}
