import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";

export default function IsLinkField(props) {
  const { item, handleChange } = props;
  const [isField, setIsField] = useState(false);

  return (
    <div>
      {isField ? (
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">Is Link</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Is Link"
            name="is_link"
            autoFocus
            open={true}
            onClose={() => setIsField(false)}
            value={item?.is_link}
            onChange={handleChange}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <div
          className="static-textfield cursor-pointer"
          onClick={() => setIsField(true)}
        >
          <div className="static-textfield-label px-1">Is Link</div>
          <div className="field-value">{item?.is_link ? "Yes" : "No"}</div>
          <div className="dropdown-arrow">
            <ArrowDropDownIcon />
          </div>
        </div>
      )}
    </div>
  );
}
