import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { DeleteGroupApi, GroupListingApi } from "src/DAL/group/group";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { show_proper_words } from "src/utils/constant";
import MemberProgramPopover from "../MembersList/memberProgramPopOver";
import PopOverViewMore from "src/components/PopOverViewMore";
import PopOverViewMoreSalePage from "src/components/PopOverViewMoreSalePage";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CalendarGroupList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElEvnet, setAnchorElEvnet] = useState(null);
  const [anchorElSalePage, setAnchorElSalePage] = useState(null);
  const [program, setprogram] = useState([]);
  const [event, setEvent] = useState([]);
  const [salePage, setSalePage] = useState([]);
  const [paymentPlan, setPaymentPlan] = useState([]);
  const [anchorElPaymentPlan, setAnchorElPaymentPlan] = useState(null);
  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;
  const opensEvent = Boolean(anchorElEvnet);
  const idevent = opensEvent ? "simple-popover" : undefined;
  const opensSalePage = Boolean(anchorElSalePage);
  const idSalePage = opensSalePage ? "simple-popover" : undefined;
  const opensPaymentPlan = Boolean(anchorElPaymentPlan);
  const idPaymentPlan = opensPaymentPlan ? "simple-popover" : undefined;

  const getConsultantListing = async () => {
    setIsLoading(true);
    const result = await GroupListingApi();
    if (result.code === 200) {
      const data = result.group.map((group) => {
        let group_by_type = "Event";
        if (group.group_by === "program") {
          group_by_type = "Programme";
        } else if (group.group_by === "event") {
          group_by_type = "Event";
        } else if (group.group_by === "sale_page") {
          group_by_type = "Sale Page";
        }

        let member_list = "N/A";
        if (group.member) {
          member_list = group.member.length;
        }
        return { ...group, member_list, group_by_type: group_by_type };
      });
      setUserList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };

  const handleClickPopUPEvent = (event, row) => {
    setEvent(row.event);
    setAnchorElEvnet(event.currentTarget);
  };

  const handleClickPopUpSalePage = (event, row) => {
    setSalePage(row.sale_pages);
    setAnchorElSalePage(event.currentTarget);
  };

  const handleClickPopUpPaymentPlan = (event, row) => {
    setPaymentPlan(row.plans);
    setAnchorElPaymentPlan(event.currentTarget);
  };

  const handleClosePopUpPaymentPlan = () => {
    setAnchorElPaymentPlan(null);
  };

  const handleClosePopUpSalePage = () => {
    setAnchorElSalePage(null);
  };

  const handleClosePopUpEvent = () => {
    setAnchorElEvnet(null);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const handleEdit = (value) => {
    navigate(`/calendar-groups/edit-group/${value.group_slug}`, {
      state: value,
    });
  };

  const handleViewDetail = (value) => {
    navigate(`/calendar-groups/group-detail/${value.group_slug}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteGroupApi(deleteDoc.group_slug);
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/calendar-groups/add-group`);
  };

  useEffect(() => {
    getConsultantListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleViewDetail,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: "Name",
      handleClick: handleViewDetail,
      className: "pointer_cursor",
    },
    {
      id: "program",
      label: "Programme / Event / Sale Page",
      renderData: (row) => {
        const firstFivePrograms = row.program?.slice(0, 5);
        const firstFiveEvents = row.event?.slice(0, 5);
        const firstFiveSalePages = row.sale_pages?.slice(0, 5);

        if (firstFivePrograms && firstFivePrograms.length > 0) {
          return (
            <>
              {firstFivePrograms.map(
                (program, i) =>
                  program._id && (
                    <React.Fragment key={i}>
                      <div>
                        <p>{program._id.title + ","}</p>
                      </div>
                      {i === 4 && (
                        <p
                          className="mb-1 mt-1 view-more"
                          aria-describedby={id}
                          variant="contained"
                          onClick={(e) => handleClickPopUP(e, row)}
                        >
                          view more
                        </p>
                      )}
                    </React.Fragment>
                  )
              )}
            </>
          );
        } else if (firstFiveEvents && firstFiveEvents.length > 0) {
          return (
            <>
              {firstFiveEvents.map(
                (event, i) =>
                  event._id && (
                    <React.Fragment key={i}>
                      <div>
                        <p>{event._id.title + ","}</p>
                      </div>
                      {i === 4 && (
                        <p
                          className="mb-1 mt-1 view-more"
                          aria-describedby={idevent}
                          variant="contained"
                          onClick={(e) => handleClickPopUPEvent(e, row)}
                        >
                          view more
                        </p>
                      )}
                    </React.Fragment>
                  )
              )}
            </>
          );
        } else if (firstFiveSalePages && firstFiveSalePages.length > 0) {
          return (
            <>
              {firstFiveSalePages.map(
                (sale_page, i) =>
                  sale_page._id && (
                    <React.Fragment key={i}>
                      <div>
                        <p>{sale_page._id.sale_page_title + ","}</p>
                      </div>
                      {i === 4 && (
                        <p
                          className="mb-1 mt-1 view-more"
                          aria-describedby={idSalePage}
                          variant="contained"
                          onClick={(e) => handleClickPopUpSalePage(e, row)}
                        >
                          view more
                        </p>
                      )}
                    </React.Fragment>
                  )
              )}
            </>
          );
        }
      },
    },
    {
      id: "payment_plan",
      label: "Payment Plan",
      renderData: (row) => {
        const firstFivePaymentPlan = row?.plans?.slice(0, 5);
        return (
          <>
            {firstFivePaymentPlan &&
              firstFivePaymentPlan.length > 0 &&
              firstFivePaymentPlan.map((payment_plan, i) => (
                <React.Fragment key={i}>
                  <div>
                    <p>{payment_plan?._id?.plan_title + ","}</p>
                  </div>
                  {i === 4 && (
                    <p
                      className="mb-1 mt-1 view-more"
                      aria-describedby={idPaymentPlan}
                      variant="contained"
                      onClick={(e) => handleClickPopUpPaymentPlan(e, row)}
                    >
                      view more
                    </p>
                  )}
                </React.Fragment>
              ))}
          </>
        );
      },
    },
    {
      id: "group_type",
      label: "Type",
      renderData: (row) => {
        return <p>{show_proper_words(row.group_type)}</p>;
      },
    },
    {
      id: "group_by_type",
      label: "Group By",
    },
    { id: "member_list", label: "Members" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 col-sm-12 mb-3">
            <h2>Groups</h2>
          </div>
          <div className="col-lg-4 col-sm-12 mb-3 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Group
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              MENU_OPTIONS={MENU_OPTIONS}
              data={userList}
              className="card-with-background"
              pagePagination={true}
              localSearchName="calender_group_searh"
            />
          </div>
        </div>
      </div>
      <MemberProgramPopover
        handleClick={handleClickPopUP}
        handleClose={handleClosePopUp}
        anchorEl={anchorEl}
        id={id}
        navigatePage={true}
        open={opens}
        program={program}
      />
      <PopOverViewMore
        handleClick={handleClickPopUPEvent}
        handleClose={handleClosePopUpEvent}
        anchorEl={anchorElEvnet}
        id={idevent}
        open={opensEvent}
        program={event}
        title="Events"
      />
      <PopOverViewMoreSalePage
        handleClick={handleClickPopUpSalePage}
        handleClose={handleClosePopUpSalePage}
        anchorEl={anchorElSalePage}
        id={idSalePage}
        open={opensSalePage}
        program={salePage}
        title="Sale Pages"
      />
      <PopOverViewMoreSalePage
        handleClick={handleClickPopUpPaymentPlan}
        handleClose={handleClosePopUpPaymentPlan}
        anchorEl={anchorElPaymentPlan}
        id={idPaymentPlan}
        open={opensPaymentPlan}
        program={paymentPlan}
        title="Payment Plans"
      />
    </>
  );
}
