import { invokeApi } from "../../bl_libs/invokeApi";

export const short_links_list_api = async (path, search_keyword) => {
  const requestObj = {
    path: `/${path}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: { search: search_keyword },
  };
  return invokeApi(requestObj);
};

export const delete_detail_short_link_api = async (id) => {
  const requestObj = {
    path: `/short_links/delete_short_link/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_short_url_api = async (data) => {
  const requestObj = {
    path: `/short_links/add_short_link`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_update_short_link_api = async (data, id) => {
  const requestObj = {
    path: `/short_links/update_short_link/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
