import React from "react";
import { convertCurrencyToSign } from "src/utils/constant";

export default function DiscountInfo({ item }) {
  const { currency, discount_info } = item;
  let Currency = convertCurrencyToSign(currency);

  return (
    <>
      {discount_info ? (
        <>
          <div>
            <span className="main-menu-heading fw-normal">
              Original Amount:{" "}
            </span>
            <span>{Currency + discount_info.original_amount}</span>
          </div>
          <div>
            <span className="main-menu-heading fw-normal">Discount: </span>
            <span>{Currency + discount_info.discount_amount}</span>
          </div>
          <div>
            <span className="main-menu-heading fw-normal">Discount Code: </span>
            <span>{discount_info.id}</span>
          </div>
        </>
      ) : (
        "N/A"
      )}
    </>
  );
}
