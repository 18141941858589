import {
  Accordion,
  AccordionSummary,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RecordNotFound from "../RecordNotFound";
import ChartJs from "./ChartJs";
import HistoryCard from "./HistoryCard";
import { convertCurrencyToSign, dd_date_format } from "src/utils/constant";

export default function TrackerHistory({ tabData, userInfo }) {
  const [memberHistories, setMemberHistories] = useState([]);
  const contentSettings = tabData.ninety_day_content_settings;

  const expandArea = (index) => {
    let temp_state = [...memberHistories];
    let temp_element = { ...temp_state[index] };
    temp_element.isExpanded = !temp_element.isExpanded;
    temp_state[index] = temp_element;
    setMemberHistories(temp_state);
  };

  const replacedString = (item) => {
    let { start_date, target_date, completed_date } = item;
    start_date = dd_date_format(start_date);
    target_date = dd_date_format(target_date);
    completed_date = dd_date_format(completed_date);
    let string = contentSettings.tracker_history_title;
    let reslut = string.replace(/{start_date}/g, start_date);
    let new_result = reslut.replace(/{target_date}/g, target_date);
    return new_result.replace(/{completed_date}/g, completed_date);
  };

  useEffect(() => {
    setMemberHistories(tabData.ninety_day_plans);
  }, [tabData]);

  return (
    <div className="mt-2">
      <div className="row">
        {memberHistories.length > 0 ? (
          memberHistories.map((item, index) => {
            return (
              <div className="col-12 mt-2 ninety-day-history" key={item._id}>
                <Accordion expanded={item.isExpanded}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={() => expandArea(index)}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="svg-color"
                  >
                    <div className="d-flex justify-content-between w-100">
                      <div className="d-flex align-items-center">
                        <Typography>{replacedString(item)}</Typography>
                        {item.plan_status === "in_progress" && (
                          <Tooltip title="Current Plan">
                            <div className="history-status-dot"></div>
                          </Tooltip>
                        )}
                      </div>
                      <Typography className="total-earning-history">
                        {`${convertCurrencyToSign(
                          userInfo.nineteen_day_plan_currency
                        )}${item.target_amount || 0}`}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <div className="px-3 pb-2">
                    <Typography className="theme-color">
                      {contentSettings?.history_graph_heading}
                    </Typography>
                    <div className="mb-3 mt-2">
                      <ChartJs
                        memberEarning={item.ninety_day_tracker}
                        targetToAchieve={item.target_amount}
                        totalAmount={item.target_amount}
                        startDate={item.start_date}
                      />
                    </div>
                    {item.ninety_day_tracker.length > 0 && (
                      <>
                        <div className="ms-2 mb-1">
                          <Typography className="theme-color">
                            {contentSettings?.history_earnings_heading}
                          </Typography>
                        </div>
                        <HistoryCard
                          earnings={item.ninety_day_tracker}
                          userInfo={userInfo}
                        />
                      </>
                    )}
                  </div>
                </Accordion>
              </div>
            );
          })
        ) : (
          <div className="mt-5">
            <RecordNotFound heading="History does not exists" />
          </div>
        )}
      </div>
    </div>
  );
}
