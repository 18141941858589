import { IconButton } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { event_listing_by_consultant } from "src/DAL/consultant/consultant";
import GeneralCalendar from "src/components/GeneralComponents/GeneralCalendar";
import {
  difference_between_two_dates,
  get_view_dates,
} from "src/utils/constant";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

export default function () {
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [useInfo, setuseInfo] = useState();
  const navigate = useNavigate();
  const [defaultTimeZone, setDefaultTimeZone] = useState("");
  const params = useParams();
  const getEventListing = async () => {
    let api_start_date = moment(currentDate.start_date).format("YYYY-MM-DD");
    let api_end_date = moment(currentDate.end_date).format("YYYY-MM-DD");
    const difference = difference_between_two_dates(
      api_start_date,
      api_end_date,
      "days"
    );

    if (difference < 10) {
      setIsLoading(true);
    }

    const result = await event_listing_by_consultant(
      api_start_date,
      api_end_date,
      params.id
    );
    if (result.code === 200) {
      setuseInfo(result?.consultant);
      setEvents(result.event);
      setDefaultTimeZone(result.time_zone);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: `/consultant`,
      active: false,
    },
    {
      title: useInfo
        ? useInfo?.first_name +
          " " +
          useInfo?.last_name +
          " (" +
          useInfo?.email +
          ")"
        : "Events",
      active: true,
    },
  ];

  useEffect(() => {
    getEventListing();
  }, [currentDate]);

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-12">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
        </div>
      </div>
      <GeneralCalendar
        eventList={events}
        setCurrentDate={setCurrentDate}
        showAddEvent={false}
        setIsLoading={setIsLoading}
        time_zone={defaultTimeZone}
        currentDate={currentDate}
        isLoading={isLoading}
        user_type="delegate"
        reloadList={getEventListing}
      />
    </>
  );
}
