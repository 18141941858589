import {
  Accordion,
  AccordionSummary,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RecordNotFound from "../RecordNotFound";
import AnswersList from "../GeneralComponents/GeneralQuestions/AnswersList";
import moment from "moment";

export default function SelfImageHistory({ tabData }) {
  console.log(tabData, "SelfImageHistory");
  const [memberHistories, setMemberHistories] = useState([]);

  const expandArea = (index) => {
    let temp_state = [...memberHistories];
    let temp_element = { ...temp_state[index] };
    temp_element.isExpanded = !temp_element.isExpanded;
    temp_state[index] = temp_element;
    setMemberHistories(temp_state);
  };

  const replacedString = (item) => {
    let { completed_date } = item;
    completed_date = moment(completed_date).format("DD MMM YYYY");
    return `Self-Image Completion: ${completed_date}`;
  };

  useEffect(() => {
    setMemberHistories(tabData.self_image_plans);
  }, []);

  console.log(memberHistories, "memberHistoriesmemberHistories");

  return (
    <div className="container-fluid">
      <div class="row">
        <div class="col-12">
          <div className="mt-2">
            <div className="row">
              {memberHistories.length > 0 ? (
                memberHistories.map((item, index) => {
                  return (
                    <div
                      className="col-12 mt-2 ninety-day-history"
                      key={item._id}
                    >
                      <Accordion expanded={item.isExpanded}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          onClick={() => expandArea(index)}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="svg-color"
                        >
                          <div className="d-flex align-items-center">
                            <Typography>{replacedString(item)}</Typography>
                            {item.plan_status === "in_progress" && (
                              <Tooltip title="Current Plan">
                                <div className="history-status-dot"></div>
                              </Tooltip>
                            )}
                          </div>
                        </AccordionSummary>
                        <div className="px-3 pb-2">
                          <AnswersList
                            questions={item.ninety_day_formula.questions}
                          />
                        </div>
                      </Accordion>
                    </div>
                  );
                })
              ) : (
                <div className="mt-5">
                  <RecordNotFound heading="History does not exists" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
