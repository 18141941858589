import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Chip, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  // { id: "name", label: "Member With Booked Calls" },
  { id: "consultant_name", label: "Consultant In Booked Calls" },
  { id: "sale_page", label: "Sale Page" },
  { id: "booking_date", label: "Booking Date" },
  { id: "booking_time", label: "Duration" },
  { id: "created_at", label: "Created" },
  {
    id: "status",
    label: "Booking Status",
    renderData: (row) => {
      let find_status = row.booking_status_info;
      if (find_status) {
        return (
          <Tooltip title={find_status?.title}>
            <Chip
              label={find_status?.title}
              variant="contained"
              className="booking-status-chip"
              style={{
                backgroundColor: find_status.background_color,
                color: find_status.text_color,
                height: "22px",
              }}
              size="small"
            />
          </Tooltip>
        );
      }
    },
  },
  { id: "register_url", label: "Booking Url" },
];

export default function BookingListTab({ tabData }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [updateDrawerState, setUpdateDrawerState] = useState(false);
  const [bookingId, setBookingId] = useState();
  const [DelegateData, setDelegateData] = useState();
  const [selectedDelegate, setSelectedDelegate] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState({});
  const [changeStatus, setChangeStatus] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const EMPTY_FILTER = {
    start_date: null,
    end_date: null,
    booking_status: null,
    consultant_id: null,
    search_text: "",
    filter_by_dates: false,
    sale_page: [],
    date: null,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleOpenFilterDrawer = (data) => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleOpenDrawerUpdate = (data) => {
    setBookingId(data._id);
    setSelectedDelegate(data.consultant);
    setDelegateData(data);
    setUpdateDrawerState(true);
  };

  const handleCloseDrawerUpdate = () => {
    setUpdateDrawerState(false);
  };

  const getBookingListing = async () => {
    console.log(tabData);
    const members = tabData.map((member, index) => {
      const dateTime = moment().format("YYYY-MM-DD") + " " + member.time;
      let name = "N/A";
      let email = "N/A";
      let consultant_name = "N/A";
      let consultant_email = "N/A";
      let sale_page = "N/A";
      let booking_date = "N/A";
      let booking_time = "N/A";
      let created_at = "N/A";

      if (member.consultant) {
        consultant_name =
          member.consultant.first_name +
          " " +
          member.consultant.last_name +
          " (" +
          member.consultant.email +
          ")";
      }

      if (member.page) {
        sale_page = member.page.sale_page_title;
      }

      if (member.date) {
        booking_date = moment(member.date).format("DD-MM-YYYY");
      }
      if (member.time) {
        booking_time =
          member.time +
          " to " +
          moment(dateTime, "DD-MM-YYYY hh:mm A")
            .add(member.slot_duration, "minutes")
            .format("hh:mm A");
      }
      if (member.createdAt) {
        created_at = moment(member.createdAt).format("DD-MM-YYYY");
      }

      return {
        ...member,

        email,
        consultant_name,
        consultant_email,
        sale_page,
        booking_date,
        booking_time,
        created_at,
        MENU_OPTIONS: handleMenu(member),
      };
    });
    setData(members);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getBookingListing(filterData);
    localStorage.setItem("booking_list_filter", JSON.stringify(filterData));
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    if (!data.date) {
      delete data.date;
      data.start_date = null;
      data.filter_by_dates = false;
      data.end_date = null;
    }
    localStorage.setItem("booking_list_filter", JSON.stringify(data));
    getBookingListing(data);
    setUpdateFilterData(data);
    setFilterData(data);
  };

  const handleClearFilter = () => {
    setPage(0);
    setPageCount(1);
    getBookingListing(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("booking_list_filter");
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteBookingApi(deleteDoc._id);
    if (result.code === 200) {
      getBookingListing(filterData);
      setIsLoading(false);
      getBookingListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChaneOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeStatus = (value) => {
    setSelectedBooking(value);
    setChangeStatus(true);
  };

  const handlePersonelNotes = (value) => {
    delete value.MENU_OPTIONS;

    navigate(`/bookings/notes/${value._id}`, { state: value });
  };

  const handleDetail = (value) => {
    value.MENU_OPTIONS;
    if (value.is_question_available) {
      navigate(
        `/bookings/answers-detail/?created_for_id=${value.page._id}&member_id=${value.user_info._id}`
      );
    }
  };

  const handleMenu = (row) => {
    let MENU_OPTIONS = [
      {
        label: "Update",
        icon: "akar-icons:edit",
        handleClick: handleOpenDrawerUpdate,
      },
      {
        label: "Booking Notes",
        icon: "gridicons:pages",
        handleClick: handlePersonelNotes,
      },
      {
        label: "Change Status",
        icon: "akar-icons:edit",
        handleClick: handleChangeStatus,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      },
    ];
    if (row.is_question_available) {
      MENU_OPTIONS.push({
        label: "Question Answers Detail",
        icon: "akar-icons:edit",
        handleClick: handleDetail,
      });
    }
    return MENU_OPTIONS;
  };

  useEffect(() => {
    getBookingListing();
  }, [tabData]);

  return (
    <>
      <div className="mt-4">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={data}
          className="card-with-background"
          pagePagination={true}
          is_hide={true}
        />
      </div>
    </>
  );
}
