import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  clientSettingApi,
  updateClientGeneralSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { s3baseUrl } from "src/config/config";
import { useEffect } from "react";
import { get_active_dynamite_events_list } from "src/DAL/DynamiteEvents/Categories/Categories";
import { consultantListing } from "src/DAL/consultant/consultant";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function UpdateGeneralSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [menuLists, setMenuList] = useState([]);
  const [selectedDelegate, setSelectedDelegate] = useState([]);
  const [selectedDelegatePassBooking, setSelectedDelegatePassBooking] =
    useState([]);
  const [eventList, setEventList] = useState([]);
  const [images, setImages] = useState({
    brand_logo: "",
    brand_favicon: "",
    footer_logo: "",
    program_lock_icon: "",
    road_map_start_icon: "",
  });

  const [oldImages, setOldImages] = useState({
    brand_logo: "",
    brand_favicon: "",
    footer_logo: "",
    program_lock_icon: "",
    road_map_start_icon: "",
  });

  const [inputs, setInputs] = useState({
    meta_title: "",
    meta_description: "",
    brand_logo: {},
    footer_logo: {},
    brand_favicon: {},
    brand_name: "",
    copyright_text: "",
    privacy_policy_text: "",
    terms_of_use_text: "",
    facebook_link: "",
    pinterest_link: "",
    instagram_link: "",
    youtube_link: "",
    mailbox_link: "",
    pixel_code_header: "",
    pixel_code_body: "",
    analytic_google_code: "",
    dynamite_event: null,
    snap_chat_link: "",
    twitter_link: "",
    linkedin_link: "",
    tiktok_link: "",
    show_project_logs: false,
    program_lock_statement: "",
    road_map_start_title: "",
    move_after_login_by_email: "",
  });

  const getEventsList = async () => {
    const result = await get_active_dynamite_events_list();
    if (result.code === 200) {
      setEventList(result.dynamite_event);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const consultantListData = async () => {
    setIsLoading(true);
    let result = await consultantListing();
    if (result.code == 200) {
      setMenuList(result.consultant_list);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const clientDetail = async () => {
    setIsLoading(true);
    const result = await clientSettingApi();
    if (result.code == 200) {
      let generalSetting = result?.content_setting?.client_general_setting;
      let dynamite_event = null;
      if (result.dynamite_event_detail) {
        dynamite_event = result.dynamite_event_detail;
      }
      setInputs({
        ...generalSetting,
        dynamite_event,
      });

      let nurture_members_array = [];
      let nurture_members =
        result?.content_setting?.client_general_setting
          ?.nurtures_for_master_link;
      if (nurture_members.length > 0) {
        nurture_members.map((member) => {
          nurture_members_array.push(member._id);
        });
      }
      let pass_booking_delegates_array = [];
      let pass_booking_delegates_new =
        result?.content_setting?.client_general_setting?.pass_booking_delegates;
      if (pass_booking_delegates_new.length > 0) {
        pass_booking_delegates_new.map((member) => {
          pass_booking_delegates_array.push(member._id);
        });
      }
      setSelectedDelegate(nurture_members_array);
      setSelectedDelegatePassBooking(pass_booking_delegates_array);
      setOldImages({
        brand_logo: generalSetting.brand_logo,
        brand_favicon: generalSetting.brand_favicon,
        footer_logo: generalSetting.footer_logo,
        program_lock_icon: generalSetting.program_lock_icon,
        road_map_start_icon: generalSetting.road_map_start_icon,
      });
      getEventsList();
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const fileChangedHandler = (e) => {
    const name = e.target.name;
    const value = e.target.files[0];
    setInputs({
      ...inputs,
      [name]: value,
    });

    setImages({
      ...images,
      [name]: URL.createObjectURL(value),
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let new_array = [];
    selectedDelegate.map((items, index) => {
      new_array.push({ _id: items });
    });
    let new_array_passBooking = [];
    selectedDelegatePassBooking.map((items, index) => {
      new_array_passBooking.push({ _id: items });
    });
    setIsLoadingForm(true);
    const formData = new FormData();
    formData.append("meta_title", inputs.meta_title);
    formData.append("meta_description", inputs.meta_description);
    formData.append("brand_name", inputs.brand_name);
    formData.append("copyright_text", inputs.copyright_text);
    formData.append("privacy_policy_text", inputs.privacy_policy_text);
    formData.append("terms_of_use_text", inputs.terms_of_use_text);
    formData.append("facebook_link", inputs.facebook_link);
    formData.append("instagram_link", inputs.instagram_link);
    formData.append("youtube_link", inputs.youtube_link);
    formData.append("mailbox_link", inputs.mailbox_link);
    formData.append("pinterest_link", inputs.pinterest_link);
    formData.append("pixel_code_header", inputs.pixel_code_header);
    formData.append("pixel_code_body", inputs.pixel_code_body);
    formData.append("analytic_google_code", inputs.analytic_google_code);
    formData.append(
      "show_project_logs",
      inputs.show_project_logs ? true : false
    );
    // added by Zubair
    formData.append("snap_chat_link", inputs.snap_chat_link);
    formData.append("twitter_link", inputs.twitter_link);
    formData.append("linkedin_link", inputs.linkedin_link);
    formData.append("tiktok_link", inputs.tiktok_link);
    formData.append("program_lock_statement", inputs.program_lock_statement);
    formData.append("nurtures_for_master_link", JSON.stringify(new_array));
    formData.append(
      "pass_booking_delegates",
      JSON.stringify(new_array_passBooking)
    );
    formData.append(
      "dynamite_event",
      inputs.dynamite_event ? inputs.dynamite_event._id : ""
    );
    formData.append("road_map_start_title", inputs.road_map_start_title);
    formData.append(
      "move_after_login_by_email",
      inputs.move_after_login_by_email
    );
    if (images.brand_logo) {
      formData.append("brand_logo", inputs.brand_logo);
    }
    if (images.program_lock_icon) {
      formData.append("program_lock_icon", inputs.program_lock_icon);
    }
    if (images.brand_favicon) {
      formData.append("brand_favicon", inputs.brand_favicon);
    }
    if (images.footer_logo) {
      formData.append("footer_logo", inputs.footer_logo);
    }
    if (images.road_map_start_icon) {
      formData.append("road_map_start_icon", inputs.road_map_start_icon);
    }

    const result = await updateClientGeneralSettingApi(formData);
    if (result.code === 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeEvent = (value) => {
    setInputs((values) => ({ ...values, dynamite_event: value }));
  };

  useEffect(() => {
    clientDetail();
    consultantListData();
  }, []);
  let finalArray = [];
  for (let i = 0; i < menuLists.length; i++) {
    let count = 0;
    for (let j = 0; j < selectedDelegate.length; j++) {
      if (menuLists[i]._id == selectedDelegate[j]._id) {
        count++;
      }
    }
    if (count == 0) {
      finalArray.push(menuLists[i]);
    }
  }
  let finalArray_new = [];
  for (let i = 0; i < menuLists.length; i++) {
    let count = 0;
    for (let j = 0; j < selectedDelegatePassBooking.length; j++) {
      if (menuLists[i]._id == selectedDelegatePassBooking[j]._id) {
        count++;
      }
    }
    if (count == 0) {
      finalArray_new.push(menuLists[i]);
    }
  }
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-6">
          <h2>General Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Select Live Event"
              selectedOption={inputs.dynamite_event}
              setSelectedOption={handleChangeEvent}
              optionsList={eventList}
              name="title"
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Meta title for client"
              variant="outlined"
              fullWidth
              required
              name="meta_title"
              value={inputs.meta_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Meta description for client"
              variant="outlined"
              fullWidth
              required
              name="meta_description"
              value={inputs.meta_description}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Copyright Text"
              variant="outlined"
              fullWidth
              required
              name="copyright_text"
              value={inputs.copyright_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Terms Of Use Text "
              variant="outlined"
              fullWidth
              required
              name="terms_of_use_text"
              value={inputs.terms_of_use_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Privacy Policy Text"
              variant="outlined"
              fullWidth
              required
              name="privacy_policy_text"
              value={inputs.privacy_policy_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <FormControl fullWidth required className="">
              <InputLabel id="demo-simple-select-label1">
                Show Project Logs
              </InputLabel>
              <Select
                labelId="demo-simple-select-label1"
                id="demo-simple-select1"
                value={inputs.show_project_logs ? true : false}
                name="show_project_logs"
                label="Show Project Logs"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4 multiple-select">
            <Autocomplete
              multiple
              limitTags={3}
              id="multiple-limit-tags"
              options={finalArray}
              filterSelectedOptions={true}
              getOptionLabel={(option) =>
                option.first_name + " (" + option.email + ")"
              }
              value={selectedDelegate}
              onChange={(event, newValue) => {
                setSelectedDelegate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nurture For Master Link"
                  placeholder="Nurture For Master Link"
                  // onChange={handleSearch}
                />
              )}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4 multiple-select">
            <Autocomplete
              multiple
              limitTags={3}
              id="multiple-limit-tags"
              options={finalArray_new}
              filterSelectedOptions={true}
              getOptionLabel={(option) =>
                option.first_name + " (" + option.email + ")"
              }
              value={selectedDelegatePassBooking}
              onChange={(event, newValue) => {
                setSelectedDelegatePassBooking(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pass Booking Call Persons"
                  placeholder="Pass Booking Call Persons"
                  // onChange={handleSearch}
                />
              )}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Pixel Code Header"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              name="pixel_code_header"
              value={inputs.pixel_code_header}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Pixel Code Body"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              name="pixel_code_body"
              value={inputs.pixel_code_body}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Google Analytic Code"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              name="analytic_google_code"
              value={inputs.analytic_google_code}
              onChange={handleChange}
            />
          </div>
          <h3 className="mt-3">General Information</h3>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Brand Name"
              variant="outlined"
              fullWidth
              required
              name="brand_name"
              value={inputs.brand_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Program Lock Statement"
              variant="outlined"
              fullWidth
              name="program_lock_statement"
              value={inputs.program_lock_statement}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Road Map Start Title"
              variant="outlined"
              fullWidth
              name="road_map_start_title"
              value={inputs.road_map_start_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Move After Login By Email"
              variant="outlined"
              fullWidth
              name="move_after_login_by_email"
              value={inputs.move_after_login_by_email}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Brand Logo</p>
                <FormHelperText className="pt-0">
                  (Recommended Size 150 X 22)
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.brand_logo ? (
                  <img
                    src={images.brand_logo ? images.brand_logo : ""}
                    height="50"
                  />
                ) : (
                  <img
                    src={
                      oldImages.brand_logo
                        ? s3baseUrl + oldImages.brand_logo
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="brand_logo"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.brand_logo?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.brand_logo?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Footer Logo</p>
                <FormHelperText className="pt-0">
                  (Recommended Size 150 X 22)
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.footer_logo ? (
                  <img src={images.footer_logo} height="50" />
                ) : (
                  <img
                    src={
                      oldImages.footer_logo
                        ? s3baseUrl + oldImages.footer_logo
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-fileFooter">
                  <Input
                    accept="image/*"
                    id="contained-button-fileFooter"
                    multiple
                    type="file"
                    name="footer_logo"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.footer_logo?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.footer_logo?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Brand FavIcon</p>
                <FormHelperText className="pt-0">
                  (Recommended Size 32 X 32)
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.brand_favicon ? (
                  <img src={images.brand_favicon} height="50" />
                ) : (
                  <img
                    src={
                      oldImages.brand_favicon
                        ? s3baseUrl + oldImages.brand_favicon
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-fileFavIcon">
                  <Input
                    accept="image/*"
                    id="contained-button-fileFavIcon"
                    multiple
                    type="file"
                    name="brand_favicon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.brand_favicon?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.brand_favicon?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Program Lock Icon</p>
                <FormHelperText className="pt-0">
                  (Recommended Size 32 X 32)
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.program_lock_icon ? (
                  <img src={images.program_lock_icon} height="50" />
                ) : (
                  <img
                    src={
                      oldImages.program_lock_icon
                        ? s3baseUrl + oldImages.program_lock_icon
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-program_lock_icon">
                  <Input
                    accept="image/*"
                    id="contained-button-program_lock_icon"
                    multiple
                    type="file"
                    name="program_lock_icon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.program_lock_icon?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">
                {inputs?.program_lock_icon?.name}
              </p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Road Map Start Icon</p>
                <FormHelperText className="pt-0">
                  (Recommended Size 32 X 32)
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.road_map_start_icon ? (
                  <img src={images.road_map_start_icon} height="50" />
                ) : (
                  <img
                    src={
                      oldImages.road_map_start_icon
                        ? s3baseUrl + oldImages.road_map_start_icon
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-road_map_start_icon">
                  <Input
                    accept="image/*"
                    id="contained-button-road_map_start_icon"
                    multiple
                    type="file"
                    name="road_map_start_icon"
                    onChange={fileChangedHandler}
                  />
                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>
          <h3 className="mt-3">Social Link</h3>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Facebook link"
              variant="outlined"
              fullWidth
              type="url"
              name="facebook_link"
              value={inputs.facebook_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Pinterest link"
              variant="outlined"
              fullWidth
              type="url"
              name="pinterest_link"
              value={inputs.pinterest_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Instagram link"
              variant="outlined"
              fullWidth
              type="url"
              name="instagram_link"
              value={inputs.instagram_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Youtube link"
              variant="outlined"
              fullWidth
              type="url"
              name="youtube_link"
              value={inputs.youtube_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Mailbox link"
              variant="outlined"
              fullWidth
              type="email"
              name="mailbox_link"
              value={inputs.mailbox_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="SnapChat Link"
              variant="outlined"
              fullWidth
              type="url"
              name="snap_chat_link"
              value={inputs.snap_chat_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Twitter Link"
              variant="outlined"
              fullWidth
              type="url"
              name="twitter_link"
              value={inputs.twitter_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Linkedin Link"
              variant="outlined"
              fullWidth
              type="url"
              name="linkedin_link"
              value={inputs.linkedin_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Tiktok Link"
              variant="outlined"
              fullWidth
              type="url"
              name="tiktok_link"
              value={inputs.tiktok_link}
              onChange={handleChange}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
