import React, { useEffect, useState } from "react";
import { Button, Box, TextField } from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { CREATED_FOR, show_proper_words } from "src/utils/constant";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { all_questions_module_listing_api } from "src/DAL/GeneralQuestions/GeneralQuestions";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";

export default function QuestionAnswerFilter({
  filterData,
  handleChange,
  handleChangeOthers,
  handleChangePrograms,
  searchSubmitFilter,
  handleClearFilter,
  handleChangeDateRange,
  user_type,
}) {
  const [moduleDataArray, setModuleDataArray] = useState([]);
  const [searchText, setSearchText] = useState("");
  const CREATED_FOR_DELEGATE = [
    {
      created_for: "programme",
      title: "Delegate Questions",
    },

    {
      created_for: "delegate-90-day-questions",
      title: "90 Day Questions For Delegate",
    },
  ];

  let created_for = CREATED_FOR.map((level) => {
    return {
      ...level,
      chip_label: level.title,
      chip_value: level.created_for,
    };
  });

  let created_for_delegate = CREATED_FOR_DELEGATE.map((level) => {
    return {
      ...level,
      chip_label: level.title,
      chip_value: level.created_for,
    };
  });

  const getMOduleList = async () => {
    let postData = {
      module_type: filterData?.created_for?.chip_value,
      search_text: searchText,
    };

    const result = await all_questions_module_listing_api(postData);

    if (result.code == 200) {
      let ModuleData = result.module_data.map((item) => {
        return {
          ...item,
          chip_label:
            filterData?.created_for?.chip_value == "page"
              ? item.sale_page_title
              : item.title,
          chip_value: item._id,
        };
      });
      setModuleDataArray(ModuleData);
    } else {
    }
  };

  useEffect(() => {
    if (
      filterData?.created_for?.chip_value == "lesson" ||
      filterData?.created_for?.chip_value == "page" ||
      filterData?.created_for?.chip_value == "dynamite_event_video" ||
      filterData?.created_for?.chip_value == "programme"
    ) {
      const timeout = setTimeout(() => {
        getMOduleList();
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [filterData?.created_for, searchText]);

  return (
    <>
      <form>
        <div className="container-fluid new-memories mt-4">
          <MUIAutocomplete
            inputLabel="Created For"
            selectedOption={filterData.created_for}
            setSelectedOption={handleChange}
            optionsList={
              user_type == "delegate" ? created_for_delegate : created_for
            }
            name="title"
          />
          {(filterData?.created_for?.chip_value == "lesson" ||
            filterData?.created_for?.chip_value == "dynamite_event_video") && (
            <>
              <div className="mt-3">
                <MUIAutocomplete
                  inputLabel={
                    filterData.created_for?.chip_value == "lesson"
                      ? "Select Program"
                      : "Dynamite Event"
                  }
                  selectedOption={filterData.programs}
                  setSelectedOption={handleChangePrograms}
                  optionsList={moduleDataArray}
                  name="chip_label"
                />
              </div>
              {filterData?.created_for?.chip_value == "lesson" &&
                filterData?.programs &&
                filterData.programs.lessons.length > 0 && (
                  <div className="mt-3">
                    <MUIAutocomplete
                      inputLabel={`Select Lessons`}
                      selectedOption={filterData.created_for_ids}
                      setSelectedOption={(e) => {
                        handleChangeOthers(e, "created_for_ids");
                      }}
                      optionsList={filterData.programs.lessons.map((item) => {
                        return {
                          ...item,
                          chip_label: item.title,
                          chip_value: item._id,
                        };
                      })}
                      autoComplete="new-password"
                      setCustomSearch={setSearchText}
                      name="chip_label"
                      multiple
                    />
                  </div>
                )}
              {filterData?.created_for?.chip_value == "dynamite_event_video" &&
                filterData?.programs &&
                filterData.programs.dynamite_event_category_video.length >
                  0 && (
                  <div className="mt-3">
                    <MUIAutocomplete
                      inputLabel={`Select Dynamite Event Vedios`}
                      selectedOption={filterData.created_for_ids}
                      setSelectedOption={(e) => {
                        handleChangeOthers(e, "created_for_ids");
                      }}
                      optionsList={filterData.programs.dynamite_event_category_video.map(
                        (item) => {
                          return {
                            ...item,
                            chip_label: item.title,
                            chip_value: item._id,
                          };
                        }
                      )}
                      autoComplete="new-password"
                      setCustomSearch={setSearchText}
                      name="chip_label"
                      multiple
                    />
                  </div>
                )}
            </>
          )}

          {(filterData?.created_for?.chip_value == "page" ||
            filterData?.created_for?.chip_value == "programme") && (
            <div className="mt-3">
              <MUIAutocomplete
                inputLabel={`Select ${show_proper_words(
                  filterData?.created_for?.chip_value
                )}`}
                selectedOption={filterData.created_for_ids}
                setSelectedOption={(e) => {
                  handleChangeOthers(e, "created_for_ids");
                }}
                optionsList={moduleDataArray}
                autoComplete="new-password"
                setCustomSearch={setSearchText}
                name="chip_label"
                multiple
              />
            </div>
          )}
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                name="from_date"
                value={filterData.from_date}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(e) => handleChangeDateRange(e, "from_date")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mt-3 inputs-fields"
                    required={Boolean(filterData.to_date)}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                name="to_date"
                value={filterData.to_date}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(e) => handleChangeDateRange(e, "to_date")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mt-3 inputs-fields"
                    required={Boolean(filterData.from_date)}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <Box sx={{ py: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              // onClick={searchSubmitFilter}
              startIcon={
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                    fill={get_root_value("--portal-theme-primary")}
                  />
                </svg>
              }
            >
              Filter
            </Button>
          </Box>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            className="mb-3"
            onClick={handleClearFilter}
            startIcon={<Icon icon={roundClearAll} />}
          >
            Clear Filter
          </Button>
        </div>
      </form>
    </>
  );
}
