import { invokeApi } from "../../bl_libs/invokeApi";

export const gratitudes_list_api = async (data) => {
  const requestObj = {
    path: `/api/general_gratitude`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const gratitude_detail_api = async (id) => {
  const requestObj = {
    path: `/api/general_gratitude/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_general_gratitude_api = async (id) => {
  const requestObj = {
    path: `/api/general_gratitude/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const add_general_gratitude_api = async (data) => {
  const requestObj = {
    path: `/api/general_gratitude`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_general_gratitude_api = async (data, id) => {
  const requestObj = {
    path: `/api/general_gratitude/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
