import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import { add_users_by_csv } from "src/DAL/member/member";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export default function ImportCsvWithOptions({
  selectedObject,
  setOpen,
  bookingsData,
}) {
  const [inputs, setInputs] = useState({
    access_type: "free",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [bookingStatus, setBookingStatus] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  console.log(...selectedObject, "selectedObjectselectedObjectselectedObject");
  const handleChangeStatus = async () => {
    setOpenStatus(false);
    setIsLoading(true);
    const result = await add_users_by_csv(selectedObject, inputs.access_type);
    if (result.code == 200) {
      setIsLoading(false);
      setOpen(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);

      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleChangeStatus();
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <div className="container-fluid">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12  col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Access Type </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="access_type"
              value={inputs.access_type}
              label="Access Type"
              onChange={handleChange}
              required
            >
              <MenuItem value={"free"}>Free</MenuItem>
              <MenuItem value={"paid"}>Paid</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div class="col-12 mt-3">
          <button class="small-contained-button w-100">
            {isLoading ? (
              "please Wait..."
            ) : (
              <span>
                Import Csv &nbsp;&nbsp; <CloudUploadIcon />
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
