import React from "react";
import { convertCurrencyToSign } from "src/utils/constant";

export default function TransactionsCommissionCard({
  count,
  title,
  className,
  currency,
}) {
  return (
    <div className={`px-3 ${className ?? ""}`}>
      <div className="stats-card stats-card-commission mt-3">
        <h3 style={{ color: "#fff" }}>
          {convertCurrencyToSign(currency) + count}
        </h3>
        <h6 style={{ color: "#fff" }}>{title}</h6>
      </div>
    </div>
  );
}
