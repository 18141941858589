import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useSnackbar } from "notistack";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  _auto_responded_message,
  delete_auto_responded_message,
} from "src/DAL/AutoRespondedMessage/AutoRespondedMessage";

// import {
//   DeleteLeadStatusHistory,
//   LeadStatusListing,
// } from "src/DAL/member/Member";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { dd_date_format } from "src/utils/constant";
import { historyListingApi } from "src/DAL/member/member";
import { s3baseUrl } from "src/config/config";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function HistoryGoalStatus({ leadStatusData, type }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [first, setfirst] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [leadStatusHistory, setleadStatusHistory] = useState([]);

  const LeadStatusList = async () => {
    setIsLoading(true);
    const result = await historyListingApi(leadStatusData._id, type);
    if (result.code == 200) {
      setfirst(
        result.member.first_name +
          " " +
          result.member.last_name +
          " (" +
          result.member?.email +
          ") "
      );
      const data = result.portal_history.map((item) => {
        return {
          ...item,
          table_avatar: {
            src: s3baseUrl + item.action_info.image,
            alt: item.action_info.name,
          },
          title: item.action_info.name + "(" + item.action_info.email + ")",
          // email: item.action_info.email,
          action_type: item.other_info.action_type,
          change_time: dd_date_format(item.createdAt),
        };
      });
      setleadStatusHistory(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    LeadStatusList();
  }, []);
  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
    },
    {
      id: "title",
      label: "Action Info",
    },

    {
      id: "action_type",
      label: "Action Type",
    },

    {
      id: "change_time",
      label: "Action Date",
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div style={{ zIndex: "0" }}>
      <div className="container-fluid" style={{ zIndex: "0" }}>
        <div className="row mt-4">
          <div className="col-12 d-flex">
            <div className="set-heading">
              <h4 style={{ color: "#f6bd4b" }}>{first ? first : " "}</h4>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-12 ">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={leadStatusHistory}
              className="card-with-background mt-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
