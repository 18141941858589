import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { addMonths } from "date-fns";
import { DatePicker } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { get_user_list_for_affiliate_transaction } from "src/DAL/AffiliateTransactions/AffiliateTransactions";
import { transactionListFilterPageRequestApi } from "src/DAL/Transaction/transactionApi";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SaleCommisssionFilter({
  filterData,
  handleSelectOther,
  searchFunction,
  handleClearFilter,
  handleChange,
  Filter_from,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [personName, setPersonName] = useState([]);
  const [userList, setUserList] = useState([]);
  const [serchText, setSerchText] = useState("");
  const [salePageList, setSalePageList] = useState([]);
  const [bookingStatus, setBookingStatus] = useState([]);
  const getPagesRequestForFilter = async (page_type) => {
    let result = await transactionListFilterPageRequestApi(page_type);
    if (result.code == 200) {
      setSalePageList(
        result.pages.map((page) => {
          let userType =
            page.action_by == "admin_user" ? "Admin " : "Delegate ";
          return {
            ...page,
            chip_label:
              page.type == "template"
                ? page?.sale_page_title + " " + `| ${userType + "-"}Template`
                : page?.sale_page_title + `(${userType})`,
            chip_value: page?.sale_page_title_slug,
            sale_page_title_type:
              page.type == "template"
                ? page?.sale_page_title + " " + `| ${userType + "-"}Template `
                : page?.sale_page_title + `| ${userType}`,
          };
        })
      );
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
    }
  };
  let list_for = "transaction";
  const getUserList = async (serchText) => {
    const result = await get_user_list_for_affiliate_transaction(
      "delegates",
      serchText,
      "",
      list_for
    );
    if (result.code === 200) {
      setUserList(
        result.users.map((item) => {
          let fullName =
            item.first_name +
            " " +
            item.last_name +
            " (" +
            item.email +
            ")" +
            " " +
            "|" +
            " " +
            show_proper_words(item.team_type);
          return {
            ...item,
            fullName,
            chip_label: fullName,
            chip_value: fullName,
          };
        })
      );
    }
  };

  useEffect(() => {
    getUserList("");
    getPagesRequestForFilter(filterData.page_type);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getUserList(serchText);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [serchText]);

  // if (isLoading === true) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }

  return (
    <div className="container-fluid new-memories px-4 transaction-filter">
      <>
        <div className="col-12 mt-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="From Date"
              name="date_from"
              inputFormat="dd-MM-yyyy"
              value={filterData.date_from}
              required
              onChange={(e) => {
                handleSelectOther("date_from", e);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="inputs-fields"
                  fullWidth
                  required
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12 mt-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="To Date"
              name="date_to"
              inputFormat="dd-MM-yyyy"
              required
              value={filterData.date_to}
              onChange={(e) => {
                handleSelectOther("date_to", e);
              }}
              minDate={filterData.date_from}
              maxDate={
                filterData.date_from
                  ? addMonths(new Date(filterData.date_from), 1)
                  : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="inputs-fields"
                  fullWidth
                  required
                />
              )}
            />
          </LocalizationProvider>
        </div>
      </>
      <div className="col-12 mt-3 mb-2">
        <div className="col-12 mt-3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Page type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="page_type"
              value={filterData.page_type}
              label="Page Type"
              onChange={handleChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="specific">Specific Product</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {filterData.page_type == "specific" && (
        <div className="mt-4">
          <MUIAutocomplete
            inputLabel="Select Products"
            selectedOption={filterData.pages}
            setSelectedOption={(e) => {
              handleSelectOther("pages", e);
            }}
            optionsList={salePageList}
            setCustomSearch={setSerchText}
            required={true}
            autoComplete="new-password"
            name="sale_page_title_type"
            multiple
          />
        </div>
      )}
      <div className="col-12 mt-3 mb-2">
        <div className="col-12 mt-3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Users type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="user_type"
              value={filterData.user_type}
              label="Users type"
              onChange={handleChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="public_user">Public Users</MenuItem>
              <MenuItem value="delegate_user">Delegate Users</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {filterData.user_type == "delegate_user" && (
        <div className="col-12 mt-3">
          <MUIAutocomplete
            inputLabel={"Delegate User"}
            selectedOption={filterData.user_id}
            setSelectedOption={(e) => {
              handleSelectOther("user_id", e);
            }}
            optionsList={userList}
            setCustomSearch={setSerchText}
            name="fullName"
          />
        </div>
      )}
      <div className="col-12 mt-3 notification-statement">
        <p class="note">
          <span class="note-label">Note:</span> Please select the criteria based
          on which you would like the sales report
        </p>
      </div>
      <div className="col-lg-12 col-sm-12 text-end d-flex justify-content-end mt-3">
        <div>
          <button
            className="small-contained-button me-2"
            onClick={handleClearFilter}
          >
            Clear Filter
          </button>
          <button
            className="small-contained-button  mt-1 mb-2"
            onClick={searchFunction}
          >
            Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div>
      </div>
    </div>
  );
}
