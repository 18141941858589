import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function TotalMemberList({ members }) {
  const [membersList, setMembersList] = useState([]);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const MemberValue = () => {
    setIsLoading(true);
    const data = members.map((member) => {
      let full_name = "N/A";
      let user_email = "N/A";
      if (member?.first_name) {
        full_name = member.first_name + " " + member.last_name;
      }
      if (member?.email) {
        user_email = member.email;
      }
      return {
        ...member,
        full_name,
        user_email,
        table_avatar: {
          src: s3baseUrl + member?.image?.thumbnail_1,
          alt: member?.first_name,
        },
      };
    });
    setMembersList(data);
    setIsLoading(false);
  };

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "table_avatar", label: "Profile", type: "thumbnail" },
    { id: "full_name", label: "Name" },
    { id: "user_email", label: "Email" },
  ];

  useEffect(() => {
    MemberValue();
  }, [members]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={membersList}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
