import { Icon } from "@iconify/react";
import { Button, Checkbox } from "@mui/material";
import React, { useEffect } from "react";
import { get_root_value } from "src/utils/domUtils";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { dynamite_events_list_api } from "src/DAL/DynamiteEvents/DynamiteEvents";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { useState } from "react";

export const FeedFilter = ({
  setInputs,
  inputs,
  handleClearFilter,
  searchFunction,
}) => {
  const [dynamiteEvent, setDynamiteEvent] = useState([]);

  const handleChangeChecked = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  console.log(dynamiteEvent, "dynamiteEvent");

  const getEvents = async () => {
    const result = await dynamite_events_list_api();
    setDynamiteEvent(
      result.dynamite_event.map((item) => {
        return {
          ...item,
          chip_label: item?.title,
          chip_value: item?._id,
        };
      })
    );
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div className="container-fluid new-memories">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <Checkbox
            checked={inputs.is_cosmos}
            onChange={(e) => {
              handleChangeChecked("is_cosmos", e.target.checked);
            }}
            name="is_cosmos"
            inputProps={{ "aria-label": "controlled" }}
          />
          Filter By Cosmos
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <Checkbox
            checked={inputs.is_source}
            onChange={(e) => {
              handleChangeChecked("is_source", e.target.checked);
            }}
            name="is_source"
            inputProps={{ "aria-label": "controlled" }}
          />
          Filter By Source
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <Checkbox
            checked={inputs.is_event}
            onChange={(e) => {
              handleChangeChecked("is_event", e.target.checked);
            }}
            name="is_event"
            inputProps={{ "aria-label": "controlled" }}
          />
          Filter By Event
        </div>
        {(inputs.is_event == "true" || inputs.is_event == true) && (
          <>
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <Checkbox
                checked={inputs.is_all_event}
                onChange={(e) => {
                  handleChangeChecked("is_all_event", e.target.checked);
                }}
                name="is_all_event"
                inputProps={{ "aria-label": "controlled" }}
              />
              Filter By All Events
            </div>
          </>
        )}
        {(inputs.is_all_event == false || inputs.is_all_event == "false") && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Choose Events"
              selectedOption={inputs.dynamite_event}
              setSelectedOption={(e) => {
                handleChangeChecked("dynamite_event", e);
              }}
              optionsList={dynamiteEvent}
              name="title"
              multiple
            />
          </div>
        )}
      </div>
      <div className="col-12 mt-4">
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={searchFunction}
          startIcon={
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 5 }}
            >
              <path
                d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                fill={get_root_value("--portal-theme-primary")}
              />
            </svg>
          }
        >
          Filter
        </Button>
        <Button
          fullWidth
          className="mt-2"
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={handleClearFilter}
          startIcon={<Icon icon={roundClearAll} />}
        >
          Clear All
        </Button>
      </div>
    </div>
  );
};
