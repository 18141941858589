import React, { useEffect, useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { memberDetailApi, member_diary_api } from "src/DAL/member/member";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    marginTop: "20%",
  },
}));

export default function MemberDiaryList() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [diaryData, setDiaryData] = useState([]);
  const [memberData, setMemberData] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const getMainDiaryListing = async () => {
    setIsLoading(true);
    const result = await member_diary_api(params.member_id, page, rowsPerPage);
    if (result.code == 200) {
      const diaries = result?.dynamite_diaries?.map((diary, index) => {
        return {
          ...diary,
          name: diary?.title,
          date: diary.date ? diary?.date : "N/A",
          statement: diary?.statement_array.map((value) => value.option + ", "),
        };
      });

      setDiaryData(diaries);
      setTotalCount(result?.total_count);
      if (state) {
        setIsLoading(false);
      } else {
        get_member_detail();
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_member_detail = async () => {
    const result = await memberDetailApi(params.member_id);
    if (result.code == 200) {
      setMemberData(result.member);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "name", label: "Title", alignRight: false },
    { id: "note", label: "Note", alignRight: false },
    { id: "date", label: "Date", alignRight: false },
    { id: "statement", label: "Statement Option", alignRight: false },
  ];

  useEffect(() => {
    getMainDiaryListing();
    if (state) {
      setMemberData(state);
    }
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <span className="sale-page-title">
              {memberData.first_name +
                " " +
                memberData.last_name +
                " (" +
                memberData.email +
                ")"}
            </span>
          </div>
          <div className="col-lg-8 col-sm-8 mb-3">
            <h2>Member Diary</h2>
          </div>
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={diaryData}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
          }}
          pagePagination={true}
        />
      </div>
    </>
  );
}
