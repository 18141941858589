import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import moment from "moment";
import { show_proper_words } from "src/utils/constant";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { delete_member_session_api } from "src/DAL/AdminUserApi/AdminUserApi";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function LoginSession({ tabData }) {
  const classes = useStyles();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);

  const getProductCategories = async () => {
    setIsLoading(true);
    const ListData = tabData.map((items) => {
      let sessionDataTime = "N/A";
      let sessionPlatform = "N/A";

      if (items.createdAt) {
        sessionDataTime = moment(items.createdAt).format(
          "DD-MMMM-YYYY hh:mm:ss A"
        );
      }
      if (items.platform) {
        sessionPlatform = show_proper_words(items.platform);
      }
      return {
        ...items,
        sessionDataTime,
        sessionPlatform,
      };
    });
    setCategoryList(ListData);
    setIsLoading(false);
  };

  const handleDeleteSession = (id) => {
    setOpenDelete(true);
    setDeleteDoc(id);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "sessionDataTime", label: "Session Data Time" },
    { id: "sessionPlatform", label: "Platform" },
    {
      id: "removeSession",
      label: "Remove Session",
      renderData: (row) => {
        return (
          <span
            onClick={() => handleDeleteSession(row._id)}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#f6bd4b",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            Click To Remove
          </span>
        );
      },
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_member_session_api(deleteDoc);
    if (result.code === 200) {
      setCategoryList((categoriesData) => {
        return categoriesData.filter((data) => data._id !== deleteDoc);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getProductCategories();
  }, [tabData]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this session ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 col-sm-8 mb-2 mt-3">
            <h2>Login Sessions</h2>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoryList}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
