import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import {
  _dublicate_sale_page_api,
  changePageAccessApi,
  deleteSalePageApi,
  import_template_data_api,
  pages_isting_with_pagination_api,
} from "src/DAL/WebsitePages/WebsitePages";
import { client_Url, project_mode, s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CopyToClipboard from "react-copy-to-clipboard";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import TemplateFilter from "./TemplateFilter";
import FilteredChip from "src/components/FilteredChip";
import { dd_date_format, show_proper_words } from "src/utils/constant";
import { set } from "lodash";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function TemplatePages() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [templateData, setTemplateData] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const is_website_pages = window.location.pathname.includes("website-pages");
  const [openModel, setOpenModel] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [pageSlug, setPageSlug] = useState("");
  const [changeAccessData, setChangeAccessData] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [dublicatePage, setDublicatePage] = useState(false);
  const [pageId, setPageId] = useState("");

  let filter_name = "template_pages_filter_data";
  let route_name = "/template-pages";
  if (is_website_pages) {
    filter_name = "general_pages_filter_data";
    route_name = "/website-pages";
  }

  const ALTER_FILTER = {
    list_by: "none",
  };

  const EMPTY_FILTER = {
    type: is_website_pages ? "general" : "template",
    list_by: "admin",
    delegate_id: null,
    template_id: null,
    type_of_page: "none",
    payment_mode: "none",
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterData((values) => ({ ...values, [name]: value }));
  };
  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };
  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      MENU_OPTIONS: "MENU_OPTIONS",
      type: "action",
    },
    { id: "number", label: "#", type: "number" },
    { id: "sale_page_title", label: "Page Title" },
    { id: "PaymentPage", label: "Payment Page" },
    { id: "thanksPage", label: "Thanks Page" },
    {
      id: "type_of_page",
      label: "Page Type",
      renderData: (row) => show_proper_words(row.type_of_page),
    },
    { id: "payment_mode", label: "Payment Mode" },
    {
      label: "Created At",
      alignRight: false,
      renderData: (row) => {
        let Created_date = "N/A";
        if (row.createdAt) {
          Created_date = dd_date_format(row.createdAt);
        }
        return (
          <>
            <p>{Created_date}</p>
          </>
        );
      },
    },
  ];

  if (is_website_pages) {
    TABLE_HEAD.splice(3, 0, {
      id: "action_user",
      label: "Created By",
    });
  }

  const handleCopiedUrl = () => {
    enqueueSnackbar("Preview Url copied to clipboard", { variant: "success" });
  };

  const handleDownload = async (path) => {
    const file_path = `${s3baseUrl}${path}`;
    fetch(file_path)
      .then(async (res) => {
        if (res.status === 200) {
          let blob = await res.blob();
          saveAs(blob, path);
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  if (is_website_pages) {
    TABLE_HEAD.splice(7, 0, {
      id: "sale_page_qrcode",
      label: "QR Code",
      renderData: (row) => {
        return (
          <div href={s3baseUrl + row.sale_page_qrcode}>
            {row.sale_page_qrcode ? (
              <img
                style={{ height: "64px" }}
                src={s3baseUrl + row.sale_page_qrcode}
                onClick={() => {
                  handleDownload(row.sale_page_qrcode);
                }}
                className="cursor-pointer"
              />
            ) : (
              <p>N/A</p>
            )}
          </div>
        );
      },
    });
  }

  TABLE_HEAD.splice(3, 0, {
    label: "URL",
    renderData: (row) => {
      if (
        row.type_of_page == "payment_page" ||
        row.type_of_page == "thanks_page"
      ) {
        return <></>;
      } else {
        if (
          is_website_pages &&
          row.is_page_template_available &&
          !row.is_template_data_imported
        ) {
          return (
            <Tooltip title="Click to import template data">
              <Chip
                label="Import Template Data"
                color="primary"
                variant="outlined"
                onClick={() => handleAgreeImportData(row)}
              />
            </Tooltip>
          );
        } else {
          return (
            <a
              href={row.preview_link_data.to}
              className="anchor-style tex-center"
              target="_blank"
            >
              {row.status == true ? "Preview" : ""}
            </a>
          );
        }
      }
    },
  });

  TABLE_HEAD.splice(4, 0, {
    label: "Copy URL",
    type: "url",
    renderData: (row) => {
      if (
        row.type_of_page == "payment_page" ||
        row.type_of_page == "thanks_page"
      ) {
        return <></>;
      } else {
        if (
          is_website_pages &&
          row.is_page_template_available &&
          !row.is_template_data_imported
        ) {
          return <></>;
        } else {
          return (
            <>
              <CopyToClipboard
                text={
                  row.page_type == "landing"
                    ? client_Url
                    : client_Url + row.sale_page_title_slug
                }
                onCopy={() => handleCopiedUrl(true)}
              >
                <Tooltip title="Click to copy Preview URL">
                  <Chip label="Copy URL" color="primary" variant="outlined" />
                </Tooltip>
              </CopyToClipboard>
              <br></br>
              {row.type_of_page == "book_a_call_page" && (
                <CopyToClipboard
                  text={client_Url + row.sale_page_title_slug + "/appointment"}
                  onCopy={() =>
                    handleCopyMessage("Appointment URL Copied to clipboard")
                  }
                >
                  <Tooltip title="Click to copy Appointment URL">
                    <Chip
                      label="Copy Appointment URL"
                      color="primary"
                      className="mt-2"
                      variant="outlined"
                    />
                  </Tooltip>
                </CopyToClipboard>
              )}
            </>
          );
        }
      }
    },
  });

  TABLE_HEAD.splice(5, 0, {
    id: "page_access",
    label: "Page Access",

    renderData: (row) => {
      return (
        <Tooltip title="Change Page Access">
          <Switch
            checked={row.page_access}
            onChange={() => handleConfirmation(row)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Tooltip>
      );
    },
  });

  TABLE_HEAD.push({
    id: "page_id",
    label: "Copy Page ID",
    renderData: (row) => (
      <CopyToClipboard
        text={row._id}
        onCopy={() => handleCopyMessage("Page ID Copied to clipboard")}
      >
        <Tooltip title="Click to copy Page ID">
          <Chip
            label="Copy Page ID"
            color="primary"
            className="mt-2"
            variant="outlined"
          />
        </Tooltip>
      </CopyToClipboard>
    ),
  });

  const handleChangePageAccess = async (value) => {
    let postData = {
      sale_page_id: changeAccessData._id,
      page_access: changeAccessData.page_access
        ? !changeAccessData.page_access
        : true,
    };
    const result = await changePageAccessApi(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenConfirmation(false);
      searchFunction();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenConfirmation(false);
    }
  };

  const handleConfirmation = (value) => {
    setChangeAccessData(value);
    setOpenConfirmation(true);
  };

  const getTemplatePagesListing = async (filterData) => {
    setIsLoading(true);

    let postData = { ...filterData };
    if (postData.list_by == "none") {
      postData.list_by = "";
    }
    if (postData.type_of_page == "none") {
      postData.type_of_page = "";
    }
    if (is_website_pages && postData.delegate_id) {
      postData.delegate_id = filterData.delegate_id._id;
    }
    if (postData.template_id) {
      postData.template_id = filterData.template_id.template;
    }
    if (postData.payment_mode == "none") {
      postData.payment_mode = "";
    }

    const result = await pages_isting_with_pagination_api(
      postData,
      postData.page ? postData.page : page,
      postData.RowsNumber ? postData.RowsNumber : rowsPerPage
    );
    if (result.code === 200) {
      let result_array = [];
      result.Sale_page.map((template) => {
        let thanksPage = "N/A";
        let PaymentPage = "N/A";
        if (template.thanks_page) {
          thanksPage = template.thanks_page.sale_page_title;
        }
        if (template.payment_page) {
          PaymentPage = template.payment_page.sale_page_title;
        }
        result_array.push({
          ...template,
          thanksPage: thanksPage,
          PaymentPage: PaymentPage,
          preview_link_data: {
            to:
              template.page_type == "landing"
                ? client_Url
                : client_Url + template.sale_page_title_slug, // yaha pr check lgaana ha agr page landing ha to slug ni attach krna
            target: "_blank",
            show_text: "Preview",
            className: "anchor-style",
          },
          MENU_OPTIONS: menuHandling(template, result.default_pages),
        });
      });
      setTemplateData(result_array);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_sale_pages);

      setFilterData({ ...filterData });
      let chip_date = { ...filterData };
      delete chip_date.type;
      if (chip_date.payment_mode == "none") {
        delete chip_date.payment_mode;
      }
      if (chip_date.type_of_page == "none") {
        delete chip_date.type_of_page;
      }

      if (chip_date.list_by !== "none") {
        chip_date.list_by = `Created By ${chip_date.list_by}`;
      } else {
        delete chip_date.list_by;
      }

      if (!is_website_pages) {
        delete chip_date.list_by;
      }
      delete chip_date.search_text;

      setFilterStateUpdated(chip_date);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleManagePixelEvents = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `${route_name}/manage-pixel-events/${value.sale_page_title_slug}`,
      {
        state: value,
      }
    );
  };

  const handleEdit = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${route_name}/edit-page/${value.sale_page_title_slug}`, {
      state: value,
    });
  };
  const handlePlanPackage = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${route_name}/plan-packege/${value.sale_page_title_slug}`, {
      state: value,
    });
  };

  const handlePlanChart = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${route_name}/plan-charts/${value._id}`, {
      state: value,
    });
  };

  const handleThanksPageManagePixelEvents = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `${route_name}/manage-pixel-events/${value.thanks_page.sale_page_title_slug}`,
      {
        state: value,
      }
    );
  };

  const handleEditThanksPage = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `${route_name}/edit-page/${value.thanks_page.sale_page_title_slug}`,
      {
        state: value.thanks_page,
      }
    );
  };

  const handlePaymentPageManagePixelEvents = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `${route_name}/manage-pixel-events/${value.payment_page.sale_page_title_slug}`,
      {
        state: value,
      }
    );
  };

  const handleEditPaymentPage = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `${route_name}/edit-page/${value.payment_page.sale_page_title_slug}`,
      {
        state: value.payment_page,
      }
    );
  };

  const handleSocialSharing = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `${route_name}/social-sharing-setting/${value.sale_page_title_slug}`,
      {
        state: value,
      }
    );
  };

  const handleUpdatePageContent = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `${route_name}/update-page-content/${value.sale_page_title_slug}`,
      {
        state: value,
      }
    );
  };

  const handleQuestionAnswers = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${route_name}/answers?created_for_id=${value._id}`, {
      state: value,
    });
  };

  const handleUpdateThanksPageContent = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `${route_name}/update-page-content/${value.thanks_page.sale_page_title_slug}`,
      {
        state: value.thanks_page,
      }
    );
  };

  const handleUpdatePaymentPageContent = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `${route_name}/update-page-content/${value.payment_page.sale_page_title_slug}`,
      { state: value }
    );
  };

  const handleUpdatePaymentPageTheme = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `${route_name}/theme-setting/${value.payment_page.sale_page_title_slug}`,
      { state: value }
    );
  };

  const handleManageCommission = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${route_name}/manage-commission-access/${value._id}`, {
      state: value,
    });
  };

  const handleAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `${route_name}/manage-consultant-access/${value.sale_page_title_slug}`,
      {
        state: value,
      }
    );
  };

  const handlePageSubscriber = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${route_name}/page-subscriber/${value._id}`, {
      state: value,
    });
  };

  const freePlanPopupSetting = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `${route_name}/free-plan-popup-setting/${value.sale_page_title_slug}`,
      {
        state: value,
      }
    );
  };

  const handleUnpublishSetting = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${route_name}/unpublish-setting/${value._id}`, {
      state: value,
    });
  };

  const handleModuleAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `${route_name}/manage-module-access/${value.sale_page_title_slug}`,
      {
        state: value,
      }
    );
  };
  const handlePageOPtion = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${route_name}/manage-page-option/${value.sale_page_title_slug}`, {
      state: value,
    });
  };

  const handleRedirect = (module, value, redirect) => {
    if (redirect == "thanks_page") {
      value = value.thanks_page;
    } else if (redirect == "payment_page") {
      value = value.payment_page;
    }

    switch (module.module_actual_name) {
      case "testimonial":
        navigate(`${route_name}/${value._id}/testimonials`, {
          state: value,
        });
        break;

      case "payment_plans":
        navigate(`${route_name}/${value._id}/payment-plans`, {
          state: value,
        });
        break;
      case "website_programmes":
        navigate(`${route_name}/${value._id}/website-programmes`, {
          state: value,
        });
        break;
      case "buisness_strategy":
        navigate(`${route_name}/${value._id}/business-strategy`, {
          state: value,
        });
        break;
      case "food_menu":
        navigate(`${route_name}/${value._id}/food-menu`, {
          state: value,
        });
        break;
      case "website_faq":
        navigate(`${route_name}/${value._id}/website-faq`, {
          state: value,
        });
        break;
      case "wheel_of_life":
        navigate(`${route_name}/${value._id}/questions`, {
          state: value,
        });
        break;
      case "banner_slider":
        navigate(`${route_name}/${value._id}/banner-slider`, {
          state: value,
        });
        break;
      case "success_schedule":
        navigate(`${route_name}/${value._id}/success-schedule`, {
          state: value,
        });
        break;
      case "vanues":
        navigate(`${route_name}/${value._id}/venues`, {
          state: value,
        });
        break;
      case "website_questions":
        navigate(`${route_name}/${value._id}/website-questions`, {
          state: value,
        });
        break;
      case "general_questions":
        navigate(
          `${route_name}/general-questions?created_for_id=${value._id}`,
          {
            state: value,
          }
        );
        break;
      case "benefits":
        navigate(`${route_name}/${value._id}/benifits`, {
          state: value,
        });
        break;
      default:
      // code block
    }
  };

  const handleAgreeImportData = (value) => {
    setSelectedObject(value);
    setOpenModel(true);
  };

  const handleDeletePages = (e) => {
    setOpenDelete(true);
    setPageSlug(e.sale_page_title_slug);
  };

  const handleDubplicatePage = (value) => {
    setDublicatePage(true);
    setPageId(value._id);
  };

  const handleAgreeDeletePage = async (e) => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteSalePageApi(pageSlug);
    if (result.code == 200) {
      getTemplatePagesListing(filterData);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDubplicatePage = async () => {
    setDublicatePage(false);
    setIsLoading(true);
    const result = await _dublicate_sale_page_api(pageId);
    if (result.code == 200) {
      getTemplatePagesListing(filterData);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenModel(false);
    setIsLoading(true);
    const dataObject = {
      sale_page_id: selectedObject._id,
    };

    const result = await import_template_data_api(dataObject);
    if (result.code === 200) {
      getTemplatePagesListing(filterData);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleCopyMessage = async (message) => {
    enqueueSnackbar(message, { variant: "success" });
  };

  const menuHandling = (value, pagesDefault) => {
    const MENU_OPTIONS = [
      {
        label: "Edit Page Setting",
        icon: "akar-icons:edit",
        handleClick: (e) => {
          handleEdit(e);
        },
      },

      {
        label: "Update Page Content",
        icon: "akar-icons:edit",
        handleClick: handleUpdatePageContent,
      },
      {
        label: "Social Sharing Setting",
        icon: "akar-icons:edit",
        handleClick: (e) => {
          handleSocialSharing(e);
        },
      },
      {
        label: "Page Unpublish Setting",
        icon: "akar-icons:edit",
        handleClick: handleUnpublishSetting,
      },
      {
        label: "View Subscribers",
        icon: "eva:eye-fill",
        handleClick: handlePageSubscriber,
      },
      {
        label: "Login Signup Popup Setting",
        icon: "eva:eye-fill",
        handleClick: freePlanPopupSetting,
      },
    ];
    if (value.use_comparison_chart) {
      MENU_OPTIONS.push({
        label: "Feature Chart Settings",
        icon: "akar-icons:edit",
        handleClick: (e) => {
          handlePlanChart(e);
        },
      });
    }

    if (value.is_use_plan_package) {
      MENU_OPTIONS.push({
        label: "Plan Package",
        icon: "akar-icons:edit",
        handleClick: (e) => {
          handlePlanPackage(e);
        },
      });
    }
    if (value.payment_page) {
      let child_menu_options = [
        {
          label: "Edit Page Setting",
          icon: "akar-icons:edit",
          handleClick: (e) => {
            handleEditPaymentPage(e);
          },
        },
        {
          label: "Update Page Content",
          icon: "akar-icons:edit",
          handleClick: handleUpdatePaymentPageContent,
        },
        {
          label: "Theme Setting",
          icon: "akar-icons:edit",
          handleClick: handleUpdatePaymentPageTheme,
        },
      ];
      if (project_mode == "dev") {
        child_menu_options.push({
          label: "Manage Pixel Events",
          icon: "akar-icons:edit",
          handleClick: (e) => {
            handlePaymentPageManagePixelEvents(e);
          },
        });
      }

      value.payment_page?.module_info?.map((module) => {
        child_menu_options.push({
          label: module.module_label_text,
          icon: "akar-icons:edit",
          handleClick: () => {
            handleRedirect(module, value, "payment_page");
          },
        });
      });
      MENU_OPTIONS.push({
        label: "Payment Page",
        icon: "akar-icons:edit",
        child_options: child_menu_options,
      });
    }
    if (value.thanks_page) {
      let child_menu_options = [
        {
          label: "Edit Page Setting",
          icon: "akar-icons:edit",
          handleClick: (e) => {
            handleEditThanksPage(e);
          },
        },
        {
          label: "Update Page Content",
          icon: "akar-icons:edit",
          handleClick: handleUpdateThanksPageContent,
        },
      ];
      if (project_mode == "dev") {
        child_menu_options.push({
          label: "Manage Pixel Events",
          icon: "akar-icons:edit",
          handleClick: (e) => {
            handlePaymentPageManagePixelEvents(e);
          },
        });
      }
      value.thanks_page?.module_info?.map((module) => {
        child_menu_options.push({
          label: module.module_label_text,
          icon: "akar-icons:edit",
          handleClick: () => {
            handleRedirect(module, value, "thanks_page");
          },
        });
      });
      MENU_OPTIONS.push({
        label: "Thanks Page",
        icon: "akar-icons:edit",
        child_options: child_menu_options,
      });
    }
    if (value.type_of_page == "book_a_call_page") {
      MENU_OPTIONS.splice(4, 0, {
        label: "Question Answers",
        icon: "eva:eye-fill",
        handleClick: handleQuestionAnswers,
      });
    }
    if (is_website_pages) {
      MENU_OPTIONS.push({
        label: "Manage Commission Access",
        icon: "akar-icons:edit",
        handleClick: handleManageCommission,
      });
    }
    if (project_mode == "dev") {
      MENU_OPTIONS.push({
        label: "Manage Pixel Events",
        icon: "akar-icons:edit",
        handleClick: (e) => {
          handleManagePixelEvents(e);
        },
      });
    }

    if (!is_website_pages) {
      MENU_OPTIONS.push(
        {
          label: "Manage Delegates Access",
          icon: "akar-icons:edit",
          handleClick: handleAccess,
        },
        {
          label: "Manage Module Access",
          icon: "akar-icons:edit",
          handleClick: handleModuleAccess,
        },
        {
          label: "Manage Page Options Access",
          icon: "akar-icons:edit",
          handleClick: handlePageOPtion,
        }
      );
    }
    if (is_website_pages && value.action_by == "consultant_user") {
      MENU_OPTIONS.push(
        {
          label: "Manage Module Access",
          icon: "akar-icons:edit",
          handleClick: handleModuleAccess,
        },
        {
          label: "Manage Page Options Access",
          icon: "akar-icons:edit",
          handleClick: handlePageOPtion,
        }
      );
    }
    if (
      is_website_pages &&
      value.is_page_template_available &&
      !value.is_template_data_imported
    ) {
      MENU_OPTIONS.push({
        label: "Import Template Data",
        icon: "akar-icons:edit",
        handleClick: handleAgreeImportData,
      });
    }

    value.module_info.map((module) => {
      MENU_OPTIONS.push({
        label: module.module_label_text,
        icon: "akar-icons:edit",
        handleClick: () => {
          handleRedirect(module, value, "sale_page");
        },
      });
    });

    if (is_website_pages) {
      MENU_OPTIONS.push({
        label: "Duplicate Page",
        icon: "akar-icons:edit",
        handleClick: handleDubplicatePage,
      });
    }
    let menu123 = pagesDefault.some((page) => page._id == value._id);
    if (is_website_pages) {
      if (!menu123) {
        MENU_OPTIONS.push({
          label: "Delete Page",
          icon: "ant-design:delete-twotone",
          handleClick: (e) => {
            handleDeletePages(e);
          },
        });
      }
    }
    return MENU_OPTIONS;
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    localStorage.setItem(
      is_website_pages ? "webpages_page_number" : "template_page_number",
      0
    );
    localStorage.setItem("member_rows", 50);
    filterData.page = 0;
    filterData.RowsNumber = 50;
    getTemplatePagesListing(filterData);
    localStorage.setItem(filter_name, JSON.stringify(filterData));
    handleCloseFilterDrawer();
  };

  const handleClearFilter = () => {
    let data_object = {
      ...EMPTY_FILTER,
      list_by: "none",
    };
    localStorage.setItem(filter_name, JSON.stringify(data_object));
    getTemplatePagesListing(data_object);
    setFilterDrawerState(false);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    data.search_text = searchText;
    if (data.type_of_page == "none") {
      data.template_id = null;
    }
    if (data.list_by !== "delegate") {
      data.delegate_id = null;
    }
    setFilterStateUpdated(data);
    setFilterData(data);
    getTemplatePagesListing(data);

    localStorage.setItem(filter_name, JSON.stringify(data));
    handleCloseFilterDrawer();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
      localStorage.setItem(
        is_website_pages ? "webpages_page_number" : "template_page_number",
        newPage
      );
    } else {
      setPageCount(newPage + 1);
      localStorage.setItem(
        is_website_pages ? "webpages_page_number" : "template_page_number",
        newPage + 1
      );
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem(
      is_website_pages ? "webpages_page_number" : "template_page_number",
      newPage
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("member_rows", event.target.value);
    localStorage.setItem(
      is_website_pages ? "webpages_page_number" : "template_page_number",
      0
    );
    setPageCount(1);
    setPage(0);
  };
  useEffect(() => {
    setPageCount(1);
    setPage(0);
  }, [is_website_pages]);
  useEffect(() => {
    let PageFromLocalStorage = localStorage.getItem(
      is_website_pages ? "webpages_page_number" : "template_page_number"
    );
    let rowsFromLocalStorage = localStorage.getItem("member_rows");
    const pageNumber = Number(PageFromLocalStorage) || 1;
    const RowsNumber = Number(rowsFromLocalStorage) || 50;
    setRowsPerPage(RowsNumber);
    setPage(PageFromLocalStorage <= 0 ? 0 : PageFromLocalStorage - 1);
    setPageCount(pageNumber <= 0 ? 1 : pageNumber);
    let filter_data = EMPTY_FILTER;
    setSearchText("");
    let find_filter = localStorage.getItem(filter_name);
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setFilterStateUpdated(filter_data);
    if (PageFromLocalStorage) {
      filter_data.page =
        PageFromLocalStorage <= 0 ? 0 : PageFromLocalStorage - 1;
    }
    if (RowsNumber) {
      filter_data.RowsNumber = RowsNumber;
    }
    getTemplatePagesListing(filter_data);
  }, [rowsPerPage, page, is_website_pages]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const handleNavigate = () => {
    navigate(`${route_name}/add-page`);
  };

  return (
    <>
      <CustomConfirmation
        open={openModel}
        setOpen={setOpenModel}
        title_html={
          <div className="confirmation-popup-title">
            <h2>Are you sure you want to import template data?</h2>
            <p>
              Importing template data will update page content and copy other
              modules data.
            </p>
          </div>
        }
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={dublicatePage}
        setOpen={setDublicatePage}
        title={"Are you sure you want to duplicate this page ?"}
        handleAgree={handleAgreeDubplicatePage}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this page?"}
        handleAgree={handleAgreeDeletePage}
      />
      <CustomConfirmation
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        title={"Are you sure you want to change type ?"}
        handleAgree={handleChangePageAccess}
      />
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-lg-8 col-sm-12">
            <h2>{is_website_pages ? "Pages" : "Template Pages"}</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
            {is_website_pages && (
              <button
                onClick={handleNavigate}
                className="small-contained-button"
              >
                Add New Page
              </button>
            )}
          </div>
          <FilteredChip
            data={filterStateUpdated}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            ALTER_FILTER={ALTER_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={templateData}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pagePagination={true}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <TemplateFilter
            filterData={filterData}
            handleChange={handleChange}
            handleSelectOther={handleSelectOther}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
            is_website_pages={is_website_pages}
          />
        }
      />
    </>
  );
}
