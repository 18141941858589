import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import {
  product_list_with_plan_api,
  programme_list_with_plan_api,
  update_porgrams_for_plan_api,
} from "src/DAL/WebsitePages/paymentPlan";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ProgrammeAccessPaymentPlan() {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [planDetail, setPlanDetail] = useState({});
  const [rowPerPage, setrowPerPage] = useState("");
  console.log(selected, "selectedselected");
  const getProgramsList = async () => {
    const result = await product_list_with_plan_api(params.plan_id);
    if (result.code == 200) {
      setPlanDetail(result.payment_plan);
      let selected_programs = [];
      result.payment_plan.program.map((prog) => {
        selected_programs.push({
          ...prog,
          _id: prog.program_id,
        });
      });
      setSelected(selected_programs);
      var programArray = [];
      setrowPerPage(result.programs.length);
      result.programs.map((item) => {
        item.table_avatar = {
          src: s3baseUrl + item.program_images.thumbnail_3,
          alt: item.title,
        };
        item.type = "day";
        item.start = new Date();
        item.expiry_date = new Date();
        programArray.push(item);
      });
      setProgramsList(programArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    let programme = [];
    selected.map((item) => {
      var program_object = {
        program_id: item._id,
        title: item.title,
        program_access_type: item.program_access_type,
        type: item.type,
      };
      if (item.type == "day") {
        program_object.no_of_start_days = parseInt(item.no_of_start_days, 10);
        program_object.no_of_limited_days = parseInt(
          item.no_of_limited_days,
          10
        );
      } else {
        program_object.expiry_date = moment(item.expiry_date).format(
          "YYYY-MM-DD"
        );
        program_object.start_date = moment(item.start_date).format(
          "YYYY-MM-DD"
        );
      }
      programme.push(program_object);
    });

    let programObject = {
      program: programme,
    };

    const result = await update_porgrams_for_plan_api(
      params.plan_id,
      programObject
    );
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event, name) => {
    const value = event.target.value;
    const program_id = event.target.name;

    setProgramsList((data) =>
      data.map((obj) => {
        if (obj._id === program_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );

    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === program_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };

  const handleChangeType = (event, name, id) => {
    const value = event.target.value;
    setProgramsList((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };
  const handleChangeExpiryDate = (date, row) => {
    let find_index = programsList.findIndex((prog) => prog._id == row._id);
    let temp_state = [...programsList];
    let temp_element = { ...temp_state[find_index] };
    temp_element.expiry_date = date;
    temp_element.type = "date";
    temp_state[find_index] = temp_element;
    console.log(temp_element, "temp_elementtemp_elementtemp_element");
    setProgramsList(temp_state);

    let find_index_selected = selected.findIndex((prog) => prog._id == row._id);
    let temp_state_selected = [...selected];
    let temp_element_selected = { ...temp_state_selected[find_index_selected] };
    temp_element_selected.expiry_date = date;
    temp_state_selected[find_index_selected] = temp_element_selected;

    setSelected(temp_state_selected);
  };
  const handleChangeStartDate = (date, row) => {
    let find_index = programsList.findIndex((prog) => prog._id == row._id);
    let temp_state = [...programsList];
    let temp_element = { ...temp_state[find_index] };
    temp_element.start_date = date;
    temp_element.type = "date";
    temp_state[find_index] = temp_element;

    setProgramsList(temp_state);

    let find_index_selected = selected.findIndex((prog) => prog._id == row._id);
    let temp_state_selected = [...selected];
    let temp_element_selected = { ...temp_state_selected[find_index_selected] };
    temp_element_selected.start_date = date;
    temp_state_selected[find_index_selected] = temp_element_selected;
    setSelected(temp_state_selected);
  };
  const TABLE_HEAD = [
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "p-0",
    },
    { id: "title", label: "Programme Title", alignRight: false },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
    {
      id: "no_of_start_days",
      label: " Access Interval Type",
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);
        let type =
          is_selected && is_selected.type ? is_selected.type : row.type;
        if (row.program_access_type == "limited") {
          return (
            <div className="col-12">
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="type"
                  value={type}
                  label=""
                  onChange={(e) => {
                    handleChangeType(e, "type", row._id);
                  }}
                >
                  <MenuItem value="day">Days</MenuItem>
                  <MenuItem value="date">Date</MenuItem>
                </Select>
              </FormControl>
            </div>
          );
        }
      },
    },
    {
      id: "no_of_start_days",
      label: "No Of Start Days",
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);
        let no_of_start_days =
          is_selected && is_selected.no_of_start_days
            ? is_selected.no_of_start_days
            : row.no_of_start_days;
        let start_date =
          is_selected && is_selected.start_date
            ? is_selected.start_date
            : row.start_date ?? null;
        let type =
          is_selected && is_selected.type ? is_selected.type : row.type;
        if (row.program_access_type == "limited") {
          return (
            <>
              {type == "day" ? (
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  size="small"
                  type="number"
                  required
                  name={row._id}
                  className="default_consultant_commission_input"
                  value={no_of_start_days}
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  onChange={(e) => {
                    handleChange(e, "no_of_start_days");
                  }}
                />
              ) : (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MM-yyyy"
                    onChange={(e) => {
                      handleChangeStartDate(e, row);
                    }}
                    value={start_date ? start_date : new Date()}
                    // minDate={new Date(row.start_date)}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
              )}
            </>
          );
        }
      },
    },
    {
      id: "no_of_limited_days",
      label: "No Of Limit End Days",
      renderData: (row) => {
        let is_selected = selected.find((object) => row._id === object._id);
        let no_of_limited_days =
          is_selected && is_selected.no_of_limited_days
            ? is_selected.no_of_limited_days
            : row.no_of_limited_days;
        let expiry_date =
          is_selected && is_selected.expiry_date
            ? is_selected.expiry_date
            : row.expiry_date;
        let start_date =
          is_selected && is_selected.start_date
            ? is_selected.start_date
            : row.start_date;
        let type =
          is_selected && is_selected.type ? is_selected.type : row.type;

        if (row.program_access_type == "limited") {
          return (
            <>
              {type == "day" ? (
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  size="small"
                  type="number"
                  required
                  name={row._id}
                  className="default_consultant_commission_input"
                  value={no_of_limited_days}
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  onChange={(e) => {
                    handleChange(e, "no_of_limited_days");
                  }}
                />
              ) : (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MM-yyyy"
                    onChange={(e) => {
                      handleChangeExpiryDate(e, row);
                    }}
                    value={expiry_date}
                    minDate={new Date(start_date)}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
              )}
            </>
          );
        }
      },
    },
  ];

  useEffect(() => {
    getProgramsList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const websitePages = window.location.pathname.includes("/website-pages");

  let breadCrumbMenu = [
    {
      title: websitePages ? "Pages" : "Template Pages",
      navigation: `${websitePages ? `/website-pages` : `/template-pages`}`,
      active: false,
    },
    {
      title: "Payment Plans",
      navigation: `${
        websitePages
          ? `/website-pages/${params?.page_id}/payment-plans`
          : `/template-pages/${params?.page_id}/payment-plans`
      }`,
      active: false,
    },
    {
      title: planDetail?.plan_title,
      active: true,
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-6">
          <h2>Manage Programme Access</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={programsList}
            selected={selected}
            setSelected={setSelected}
            checkbox_selection={true}
            pagePagination={false}
            rows_per_page_count={rowPerPage}
            hide_footer_pagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
