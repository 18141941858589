import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  DelegatesNurtureList,
  programmeListing,
} from "src/DAL/Programme/Programme";
import { productListApi } from "src/DAL/member/member";
import {
  AddPaymentTemplateApi,
  EditPaymentTemplateApi,
  paymentTemplateDetailApi,
} from "src/DAL/PaymentTemplate/paymentTemplateApi";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { useEffect } from "react";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddOrUpdatePaymentTemplate() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { template_id } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  const [installmentAmount, setInstallmentAmount] = useState("");
  const [delegates, setDelegate] = useState([]);
  const [Search, setSearch] = useState("");
  const delegateList = async (Search, user) => {
    let result = await DelegatesNurtureList(Search, user);
    if (result.code == 200) {
      setDelegate(
        result.consultant.map((item) => {
          return {
            ...item,
            Delegate_title:
              item.first_name +
              " " +
              item.last_name +
              " " +
              "(" +
              item.email +
              ")",
          };
        })
      );
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const [inputs, setInputs] = useState({
    title: "",
    currency: "gbp",
    total_amount: "",
    vat_number: "",
    short_description: "",
    template_type: "onetime",
    product: null,
    show_on_consultant: "no",
    program: null,
    initial_amount: "",
    month: "",
    interval_type: "month",
    number_of_days: "",
    installment_amount: "",
    no_of_installment: "",
    basic_membership_days: "",
    is_basic_membership: false,
    access_for_consultant: "all",
    access_type: "all",
    consaltants_ids: [],
  });

  const get_template_info = async () => {
    let result = await paymentTemplateDetailApi(template_id);
    if (result.code == 200) {
      handleFormatData(result.payment_template);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const productList = async () => {
    let result = await productListApi();
    if (result.code == 200) {
      setProducts(result.product);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const programmeList = async () => {
    let result = await programmeListing();
    if (result.code == 200) {
      setProgrammes(result.program);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {
      title: inputs.title,
      template_type: inputs.template_type,
      total_amount: inputs.total_amount,
      vat_number: inputs.vat_number,
      currency: inputs.currency,
      short_description: inputs.short_description,
      show_on_consultant: inputs.show_on_consultant,
    };
    if (inputs.show_on_consultant == "list") {
      postData.access_for_consultant = inputs.access_for_consultant;
      if (inputs.access_for_consultant !== "all") {
        postData.access_type = inputs.access_type;
        if (inputs.access_type == "specific") {
          if (inputs.consaltants_ids.length <= 0) {
            const error =
              "please select atleast one " +
              show_proper_words(
                inputs.access_for_consultant == "inner_circle"
                  ? inputs.access_for_consultant
                  : inputs.access_for_consultant == "consultant"
                  ? "Consultant User"
                  : inputs.access_for_consultant == "delegate"
                  ? "Delegate User"
                  : "Marketing User"
              );
            enqueueSnackbar(error, {
              variant: "error",
            });
            setIsLoading(false);
            return;
          }
          const delegare_list = inputs.consaltants_ids.map((item) => ({
            _id: item._id,
            email: item.email,
            first_name: item.first_name,
            last_name: item.last_name,
            team_type: item.team_type,
          }));
          postData.consaltants_ids = delegare_list;
        }
      }
    }

    if (inputs.program) {
      postData.program = inputs.program._id;
    }
    if (inputs.product) {
      postData.product = inputs.product._id;
    }

    if (inputs.template_type === "onetime") {
      postData.is_basic_membership = inputs.is_basic_membership;
      if (inputs.is_basic_membership) {
        postData.basic_membership_days = inputs.basic_membership_days;
      }
    } else {
      postData.interval_type = inputs.interval_type;
      postData.number_of_days = inputs.number_of_days;
      postData.initial_amount = inputs.initial_amount;
      postData.no_of_installment = inputs.no_of_installment;
    }
    console.log(postData, "----postData");

    const result = template_id
      ? await EditPaymentTemplateApi(postData, template_id)
      : await AddPaymentTemplateApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/payment-template`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (name == "access_for_consultant") {
      if (value !== "all") {
        delegateList(Search, value);
      }
      setInputs((values) => ({
        ...values,
        ["access_type"]: "all",
        ["consaltants_ids"]: [],
      }));
    }
    if (name == "access_type") {
      setInputs((values) => ({
        ...values,

        ["consaltants_ids"]: [],
      }));
    }
  };

  const handleChangeOther = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFormatData = (data) => {
    console.log(data, "----data");
    if (data.is_basic_membership == undefined) {
      data.is_basic_membership = false;
    }
    if (
      data.access_for_consultant !== "all" &&
      data.access_for_consultant !== undefined
    ) {
      delegateList(Search, data.access_for_consultant);
    }
    const delegate_ids = data?.consaltants_ids?.map((item) => {
      return {
        ...item,
        Delegate_title:
          item.first_name + " " + item.last_name + " " + "(" + item.email + ")",
      };
    });

    setInputs({
      ...data,
      consaltants_ids: delegate_ids,
      access_for_consultant: data.access_for_consultant
        ? data.access_for_consultant
        : "all",
      access_type: data.access_type ? data.access_type : "all",
    });

    setIsLoading(false);
    window.history.replaceState({}, document.title);
  };

  useEffect(() => {
    productList();
    programmeList();
    if (template_id) {
      setIsLoading(true);
      if (state) {
        handleFormatData(state);
      } else {
        get_template_info();
      }
    }
  }, []);

  useEffect(() => {
    let installment =
      (inputs.total_amount - inputs.initial_amount) / inputs.no_of_installment;
    setInstallmentAmount(installment);
  }, [inputs.total_amount, inputs.no_of_installment, inputs.initial_amount]);
  useEffect(() => {
    if (
      inputs.access_for_consultant !== "all" &&
      inputs.access_for_consultant !== undefined
    ) {
      const timeoutId = setTimeout(() => {
        delegateList(Search, inputs.access_for_consultant);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [Search]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/payment-template`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">{`${
            template_id ? "Edit" : "Add"
          } Payment Template`}</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-0">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-0">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Currency</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="currency"
                value={inputs.currency}
                label="Currency"
                onChange={handleChange}
                required
              >
                <MenuItem value="usd">Dollar</MenuItem>
                <MenuItem value="gbp">UK Pounds</MenuItem>
                <MenuItem value="eur">Euro</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Delegates Access
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_on_consultant"
                value={inputs.show_on_consultant}
                label="Delegates Access"
                onChange={handleChange}
                required
              >
                <MenuItem value="no">No Access</MenuItem>
                <MenuItem value="list">Listing Access</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.show_on_consultant == "list" && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Access for *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="access_for_consultant"
                    value={inputs.access_for_consultant}
                    label="Access for *"
                    onChange={handleChange}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="inner_circle">Inner Circle</MenuItem>
                    <MenuItem value="consultant">Consultant User</MenuItem>
                    <MenuItem value="delegate">Delegate User</MenuItem>
                    <MenuItem value="marketing">Marketing Team</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.access_for_consultant !== "all" && (
                <>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Access type *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="access_type"
                        value={inputs.access_type}
                        label="Access type *"
                        onChange={handleChange}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="specific">Specific</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {inputs.access_type == "specific" && (
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                      <MUIAutocomplete
                        inputLabel={`Select ${show_proper_words(
                          inputs.access_for_consultant
                        )}*`}
                        selectedOption={inputs.consaltants_ids}
                        setSelectedOption={(value) => {
                          handleChangeOther("consaltants_ids", value);
                        }}
                        optionsList={delegates}
                        limitTags={1}
                        setCustomSearch={setSearch}
                        name="Delegate_title"
                        multiple
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Product"
              selectedOption={inputs.product}
              setSelectedOption={(e) => handleChangeOther("product", e)}
              optionsList={products}
              name="name"
              required
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Programme"
              selectedOption={inputs.program}
              setSelectedOption={(e) => handleChangeOther("program", e)}
              optionsList={programmes}
              name="title"
              required
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Payment request Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="template_type"
                value={inputs.template_type}
                label="Payment Request Type"
                onChange={handleChange}
                required
              >
                <MenuItem value="onetime">One Time</MenuItem>
                <MenuItem value="recurring">Recurring</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Total Amount"
              variant="outlined"
              fullWidth
              type="number"
              required
              name="total_amount"
              value={inputs.total_amount}
              onChange={handleChange}
            />
          </div>
          {inputs.template_type === "recurring" ? (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label1">
                    Plan Payment Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label1"
                    id="demo-simple-select"
                    name="interval_type"
                    value={inputs.interval_type}
                    label="Plan Payment Type"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="month">Monthly</MenuItem>
                    <MenuItem value="week">Weekly</MenuItem>
                    <MenuItem value="year">Yearly</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.interval_type == "custom" && (
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="No of days"
                    variant="outlined"
                    fullWidth
                    required
                    type="number"
                    name="number_of_days"
                    value={inputs.number_of_days}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Initial Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  name="initial_amount"
                  value={inputs.initial_amount}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="No Of Installments"
                  variant="outlined"
                  fullWidth
                  required
                  name="no_of_installment"
                  value={inputs.no_of_installment}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Installment Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  aria-readonly
                  name="installment_amount"
                  value={installmentAmount}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Is Basic Membership
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="is_basic_membership"
                    value={inputs.is_basic_membership}
                    label="Is Basic Membership"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.is_basic_membership && (
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Basic Membership Days"
                    variant="outlined"
                    fullWidth
                    type="number"
                    required
                    name="basic_membership_days"
                    value={inputs.basic_membership_days}
                    onChange={handleChange}
                  />
                </div>
              )}
            </>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Vat Number"
              variant="outlined"
              fullWidth
              name="vat_number"
              value={inputs.vat_number}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                multiline
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {template_id ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
