import { invokeApi } from "../../bl_libs/invokeApi";

export const AddNotificationActionApi = async (data) => {
  const requestObj = {
    path: `/api/notification_actions/add_notification_action`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const NotificationActionListingApi = async (data) => {
  const requestObj = {
    path: `/api/notification_actions/list_notification_actions`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteNotificationActionListingApi = async (slug) => {
  const requestObj = {
    path: `/api/notification_actions/delete_notification_actions/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddUpdateNotificationActionApi = async (slug, data) => {
  const requestObj = {
    path: `/api/notification_actions/update_notification_action_object/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UpdateNotificationActionApi = async (slug, data) => {
  const requestObj = {
    path: `/api/notification_actions/update_notification_actions/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddOrRemoveNotificationActionApi = async (slug, data) => {
  const requestObj = {
    path: `/api/notification_actions/add_remove_notification_action/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailEmailTemplateApiList = async (id) => {
  const requestObj = {
    path: `/api/notification_actions/preview_email_notification_action/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const EmailTemplateList = async (id) => {
  const requestObj = {
    path: `/api/whatsapp_chat/list_templates`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
