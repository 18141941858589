import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import { deleteLessonQuestion } from "src/DAL/GeneralQuestions/GeneralQuestions";
import CustomPopover from "src/components/MenuOption/CustomPopover";
import { useState } from "react";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import RecordNotFound from "src/components/RecordNotFound";
import { s3baseUrl } from "src/config/config";
import { DeleteSelfImageReplyApi } from "src/DAL/member/member";
import { useSnackbar } from "notistack";

const SlefImageReplies = ({ replies, created_for, get_questions_list }) => {
  const [openDeleteReply, setOpenDeleteReply] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const is_self_image = window.location.pathname.includes(
    "self-image-questions"
  );
  const handleAgreeDeleteReply = (value) => {
    setDeleteId(value._id);
    setOpenDeleteReply(true);
  };
  const handleDeleteReply = async () => {
    setOpenDeleteReply(false);
    let postData = {
      message_id: deleteId,
      created_for: "self_image",
    };
    const result =
      is_self_image && created_for == "self_image"
        ? await DeleteSelfImageReplyApi(postData)
        : "";
    if (result.code === 200) {
      get_questions_list();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDeleteReply,
    },
  ];
  console.log(replies, "repliesrepliesreplies");

  return (
    <>
      <CustomConfirmation
        open={openDeleteReply}
        setOpen={setOpenDeleteReply}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDeleteReply}
      />
      <div className="container-fluid">
        <div className="row">
          {replies.length > 0
            ? replies.map((reply, index) => (
                <div className="col-12 mt-2" key={reply._id}>
                  <div className="card">
                    <div className="card-body notes-input set-display-inline">
                      <CustomPopover menu={MENU_OPTIONS} data={reply} />
                      <p className="mb-0 normal-text" style={{ width: "97%" }}>
                        <div
                          // className="ms-2"
                          dangerouslySetInnerHTML={{
                            __html: reply.message,
                          }}
                        ></div>
                        {reply?.audio_file && (
                          <audio
                            className="w-100"
                            src={s3baseUrl + reply?.audio_file}
                            controls
                          />
                        )}
                      </p>
                      <div className="d-flex justify-content-between mt-3">
                        <p className="lesson-notes-title mt-2 mb-1 date-color">
                          {moment(reply.message_date_time).format(
                            "DD-MM-YYYY hh mm A"
                          )}
                        </p>
                        <p className="color" style={{ textTransform: "none" }}>
                          {reply.action_by_info && reply.action_by_info}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
    </>
  );
};

export default SlefImageReplies;
