import {
  CircularProgress,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { s3baseUrl } from "src/config/config";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import {
  AddCommentOnQuestion,
  AddCommentOnSelfImageQuestion,
  editCommentOnQuestion,
  editCommentOnSelfImageQuestion,
} from "src/DAL/goalstatement/goalStatement";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  DeleteQuestionCommentApi,
  DeleteSelfImageQuestionCommentApi,
} from "src/DAL/member/member";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export const SelfImageQuestionsReply = ({ data, reload, type }) => {
  const params = useLocation();
  const id = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [replies, setReplies] = useState([]);
  const [repliesArrays, setRepliesArray] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteDoc, setDeleteDoc] = React.useState("");
  const [editComment, setEditComment] = React.useState("");
  const [editState, setEditState] = React.useState(false);
  const [detailDescriptionCk, setDetailDescriptionCk] = React.useState("");
  const member_id = new URLSearchParams(location.search).get("member_id");

  const handleSubmit = async () => {
    let postData = {
      question_id: data._id,
      member: member_id,
      comment: detailDescriptionCk,
    };

    const result = await AddCommentOnSelfImageQuestion(postData);
    if (result.code == 200) {
      setReplies((replies) => [...replies, result.comment]);
      // console.log(data.comment, "inside");
      reload();
      enqueueSnackbar(result.message, { variant: "success" });
      setDetailDescriptionCk("");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSubmitEdit = async () => {
    let postData = {
      question_id: data._id,
      member: member_id,
      comment: detailDescriptionCk,
      comment_id: editComment._id,
    };
    const result = await editCommentOnSelfImageQuestion(postData);
    if (result.code == 200) {
      setReplies((replies) => {
        return replies.map((reply) => {
          let reply_obj = {};
          if (reply._id == editComment._id) {
            // console.log("kokokooko");
            return (reply_obj = {
              ...reply,
              comment: detailDescriptionCk,
            });
          } else {
            return reply;
          }
        });
      });
      setDetailDescriptionCk("");
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      // console.log(postData, "postData");
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    let postData = {
      question_id: data._id,
      member: member_id,
      comment_id: deleteDoc._id,
    };
    const result = await DeleteSelfImageQuestionCommentApi(postData);
    if (result.code === 200) {
      setReplies((replies) => {
        return replies.filter((reply) => reply._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  console.log(editComment, "editCommenteditComment");
  const handleEdit = (value) => {
    console.log(value, "value of the comment");
    setEditComment(value);
    setEditState(true);
    setDetailDescriptionCk(value.comment);

    // navigate(`/quotesList/edit-quotes`, {
    //   state: value,
    // });
  };
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  useEffect(() => {
    setReplies(data?.answer?.comments ? data?.answer?.comments : []);
  }, [data]);
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  return (
    <div className="container-fluid">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="row answer-history">
        <div className="col-12 mt-3">
          <p className="heading">Question :</p>
          <p className="mb-4">{htmlDecode(data.question_statement)}</p>
        </div>
        {replies?.length < 1 ? (
          <h6>No Comment</h6>
        ) : (
          <div className="custome-height">
            <p className="heading">Comments :</p>
            {replies?.map((reply) => {
              //console.log(reply);
              return (
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: reply.comment,
                      }}
                    ></div>
                  </div>

                  <div className="ms-auto text-end">
                    {/* <button
                      className="small-contained-button"
                      onClick={() => handleAgreeDelete(reply)}
                    >
                      Delete
                    </button> */}
                    <CustomPopoverSection menu={MENU_OPTIONS} data={reply} />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {type !== "responded" && (
          <div className="col-12 mt-3">
            <div className="col-12 mt-2 mb-2">
              <h4>Reply :</h4>
              <TinyEditor
                setDetailDescription={setDetailDescriptionCk}
                detailDescriptionCk={detailDescriptionCk}
                editorHeight={245}
              />
            </div>
            <div className="col-12 mb-3 text-end">
              <button
                onClick={editState ? handleSubmitEdit : handleSubmit}
                className="small-contained-button"
              >
                {editState ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
