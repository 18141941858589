import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import "react-tagsinput/react-tagsinput.css";
import { TextareaAutosize } from "@mui/material";
import { useEffect } from "react";

export default function PushNotifySetting(props) {
  const { onCloseDrawer, data, drawerState } = props;
  const [state, setState] = useState({
    notification_title: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((old) => ({ ...old, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = { ...data, push_notification_info: state };
    onCloseDrawer(obj, drawerState);
  };

  useEffect(() => {
    if (data.push_notification_info) {
      setState(data.push_notification_info);
    }
  }, [drawerState]);

  return (
    <div className="container-fluid new-memories">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <TextField
              className="mt-3"
              id="outlined-basic"
              label="Notification Title"
              variant="outlined"
              name="notification_title"
              value={state.notification_title}
              required={true}
              onChange={handleChange}
            />
          </div>
          <div className="col-12">
            <p className="tracking_code_heading">Notification Statement *</p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Notification Statement"
              required
              name="description"
              value={state.description}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "200px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="text-end mt-4">
            <button type="submit" className="small-contained-button">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
