import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  clientSettingApi,
  updateWheelOfLifeSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function UpdateWheelOfLifeSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    assessment_video_url: "",
    assessment_description: "",
    assessment_button_text: "",
    assessment_button_link: "",
    assessment_submit_button_text: "",
    menu_text_on_lock_modules: "",
    assessment_questions_description: "",

    wheel_of_life_description: "",
    wheel_of_life_video_description: "",
    wheel_of_life_description_after_rating: "",
    wheel_of_life_score_description: "",
    wheel_of_life_submit_button_text: "",
    intention_statement_field_placeholder: "",
    wheel_of_life_intention_statement: "",
    wheel_of_life_video_url: "",
    coins_on_complete_wheel_of_life: "",

    thank_you_video_url: "",
    thank_you_description: "",
    thank_you_heading: "",
    plan_heading_description: "",
    is_wheel_of_life_enable: false,
    plan_1_title_and_amount: "",
    plan_1_description: "",
    plan_2_title_and_amount: "",
    plan_2_description: "",
    understand_science_description: "",
    thank_you_button_text: "",
    plan_1_button_text: "",
    plan_1_button_link: "",
    plan_2_button_text: "",
    plan_2_button_link: "",
    understand_science_button_text: "",
    understand_science_button_link: "",
    thank_you_button_link: "",
  });

  const clientDetail = async () => {
    setIsLoading(true);
    const result = await clientSettingApi();
    if (result.code == 200) {
      setInputs(
        result?.content_setting?.wheel_of_life_setting
          ? result?.content_setting?.wheel_of_life_setting
          : ""
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    let data = {
      assessment_video_url: inputs.assessment_video_url,
      assessment_description: inputs.assessment_description,
      assessment_button_text: inputs.assessment_button_text,
      assessment_button_link: inputs.assessment_button_link,
      assessment_submit_button_text: inputs.assessment_submit_button_text,
      menu_text_on_lock_modules: inputs.menu_text_on_lock_modules,
      assessment_questions_description: inputs.assessment_questions_description,

      intention_statement_field_placeholder:
        inputs.intention_statement_field_placeholder,
      wheel_of_life_description: inputs.wheel_of_life_description,
      wheel_of_life_description_after_rating:
        inputs.wheel_of_life_description_after_rating,
      wheel_of_life_score_description: inputs.wheel_of_life_score_description,
      wheel_of_life_submit_button_text: inputs.wheel_of_life_submit_button_text,
      is_wheel_of_life_enable: inputs.is_wheel_of_life_enable,
      wheel_of_life_intention_statement:
        inputs.wheel_of_life_intention_statement,
      wheel_of_life_video_url: inputs.wheel_of_life_video_url,
      coins_on_complete_wheel_of_life: inputs.coins_on_complete_wheel_of_life,
      wheel_of_life_video_description: inputs.wheel_of_life_video_description,

      thank_you_video_url: inputs.thank_you_video_url,
      thank_you_description: inputs.thank_you_description,
      thank_you_heading: inputs.thank_you_heading,
      plan_heading_description: inputs.plan_heading_description,
      plan_1_title_and_amount: inputs.plan_1_title_and_amount,
      plan_1_description: inputs.plan_1_description,
      plan_2_title_and_amount: inputs.plan_2_title_and_amount,
      plan_2_description: inputs.plan_2_description,
      understand_science_description: inputs.understand_science_description,
      thank_you_button_text: inputs.thank_you_button_text,
      plan_1_button_text: inputs.plan_1_button_text,
      plan_1_button_link: inputs.plan_1_button_link,
      plan_2_button_text: inputs.plan_2_button_text,
      plan_2_button_link: inputs.plan_2_button_link,
      understand_science_button_text: inputs.understand_science_button_text,
      understand_science_button_link: inputs.understand_science_button_link,
      thank_you_button_link: inputs.thank_you_button_link,
    };
    let formData = {
      wheel_of_life_setting: data,
    };

    const result = await updateWheelOfLifeSettingApi(formData);
    if (result.code === 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    clientDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-6">
          <h2>Wheel Of Life Setting</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div class="col-12 wheel-of-life-setting-section-heading">
            <h2 className="mt-4">Assessment</h2>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Assessment Video Url"
              variant="outlined"
              fullWidth
              type="url"
              name="assessment_video_url"
              value={inputs?.assessment_video_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Assessment Button Text"
              variant="outlined"
              fullWidth
              name="assessment_button_text"
              value={inputs?.assessment_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Assessment Button Link"
              variant="outlined"
              fullWidth
              name="assessment_button_link"
              value={inputs?.assessment_button_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Assessment Submit Button Text"
              variant="outlined"
              fullWidth
              name="assessment_submit_button_text"
              value={inputs?.assessment_submit_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Menu Description On Lock Modules"
              variant="outlined"
              fullWidth
              name="menu_text_on_lock_modules"
              value={inputs?.menu_text_on_lock_modules}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Assessment Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="assessment_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Assessment Questions Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="assessment_questions_description"
              editorHeight={320}
            />
          </div>
          <div class="col-12 wheel-of-life-setting-section-heading">
            <h2 className="mt-4">Wheel Of Life</h2>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Wheel Of Life Submit Button Text"
              variant="outlined"
              fullWidth
              name="wheel_of_life_submit_button_text"
              value={inputs?.wheel_of_life_submit_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Wheel Of Life Score Description"
              variant="outlined"
              fullWidth
              name="wheel_of_life_score_description"
              value={inputs?.wheel_of_life_score_description}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="intention Statement Field Placeholder"
              variant="outlined"
              fullWidth
              name="intention_statement_field_placeholder"
              value={inputs?.intention_statement_field_placeholder}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Is Wheel Of Life Enable ?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_wheel_of_life_enable"
                required
                value={inputs.is_wheel_of_life_enable}
                label=" Is Wheel Of Life Enable ?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Wheel Of Life Video Url"
              variant="outlined"
              fullWidth
              type="url"
              name="wheel_of_life_video_url"
              value={inputs?.wheel_of_life_video_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Coins On Complete Wheel Of Life"
              variant="outlined"
              fullWidth
              type="number"
              name="coins_on_complete_wheel_of_life"
              value={inputs?.coins_on_complete_wheel_of_life}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Wheel Of Life Video Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="wheel_of_life_video_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Wheel Of Life Description Before Rating Questions</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="wheel_of_life_description"
              editorHeight={320}
            />
          </div>

          <div className="col-12 mt-4">
            <h4>Wheel Of Life Description After Rating Questions</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="wheel_of_life_description_after_rating"
              editorHeight={320}
            />
          </div>
          {/* <div className="col-12 mt-4">
            <h4>Wheel Of Life Score Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="wheel_of_life_score_description"
              editorHeight={320}
            />
          </div> */}
          <div className="col-12 mt-4">
            <h4>Wheel Of Life Intention Statement</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="wheel_of_life_intention_statement"
              editorHeight={320}
            />
          </div>
          <div class="col-12 wheel-of-life-setting-section-heading">
            <h2 className="mt-4">Thank You Page</h2>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Thank You Video Url"
              variant="outlined"
              fullWidth
              type="url"
              name="thank_you_video_url"
              value={inputs?.thank_you_video_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Thank You Button Text"
              variant="outlined"
              fullWidth
              name="thank_you_button_text"
              value={inputs?.thank_you_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Thank You Button Link"
              variant="outlined"
              fullWidth
              name="thank_you_button_link"
              value={inputs?.thank_you_button_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Plan 1 Button Text"
              variant="outlined"
              fullWidth
              name="plan_1_button_text"
              value={inputs?.plan_1_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Plan 1 Button Link"
              variant="outlined"
              fullWidth
              name="plan_1_button_link"
              value={inputs?.plan_1_button_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Plan 2 Button Text"
              variant="outlined"
              fullWidth
              name="plan_2_button_text"
              value={inputs?.plan_2_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Plan 2 Button Link"
              variant="outlined"
              fullWidth
              name="plan_2_button_link"
              value={inputs?.plan_2_button_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Understand Science Button Text"
              variant="outlined"
              fullWidth
              name="understand_science_button_text"
              value={inputs?.understand_science_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Understand Science Button Link"
              variant="outlined"
              fullWidth
              name="understand_science_button_link"
              value={inputs?.understand_science_button_link}
              onChange={handleChange}
            />
          </div>

          <div className="col-12 mt-4">
            <h4>Thank You Page Heading </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="thank_you_heading"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Thank You Page Description </h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="thank_you_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Plan Heading Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="plan_heading_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Plan 1 Title and Amount</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="plan_1_title_and_amount"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Plan 1 Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="plan_1_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Plan 2 Title and Amount</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="plan_2_title_and_amount"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Plan 2 Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="plan_2_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Understand Science Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="understand_science_description"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
