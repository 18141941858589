import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  active_booking_status_api,
  update_booking_status_api,
} from "src/DAL/booking/bookingApi";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";

export default function ChangeBookingStatus({
  selectedObject,
  setOpen,
  bookingsData,
}) {
  const [inputs, setInputs] = useState({ booking_status_info: null, note: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [bookingStatus, setBookingStatus] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeStatus = async () => {
    setOpenStatus(false);
    setIsLoading(true);
    const postData = {
      status: inputs.booking_status_info,
      note: inputs.note,
    };
    const result = await update_booking_status_api(inputs._id, postData);
    if (result.code === 200) {
      bookingsData();
      setOpen(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_active_booking_status = async () => {
    const result = await active_booking_status_api();
    if (result.code === 200) {
      setBookingStatus(result.active_booking_status);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenStatus(true);
  };

  useEffect(() => {
    get_active_booking_status();
    setInputs(selectedObject);
  }, [selectedObject]);

  return (
    <div className="container-fluid">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
          <MUIAutocomplete
            inputLabel="Booking Status"
            selectedOption={inputs.booking_status_info}
            setSelectedOption={(v) => {
              handleChangeOthers("booking_status_info", v);
            }}
            optionsList={bookingStatus}
            autoComplete="new-password"
            name="title"
          />
        </div>
        <div className="col-12 ">
          <p className="tracking_code_heading text-white">Note </p>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="note"
            editorHeight={220}
          />
        </div>
        <CustomConfirmation
          open={openStatus}
          setOpen={setOpenStatus}
          title={"Are you sure you want to update booking status?"}
          handleAgree={handleChangeStatus}
        />
        <div class="col-12 mt-3">
          <button class="small-contained-button w-100">
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
