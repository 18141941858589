import { invokeApi } from "../../bl_libs/invokeApi";

export const addFoodMEnu = async (data) => {
  const requestObj = {
    path: `/api/food_menu/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editFoodMenu = async (data, id) => {
  const requestObj = {
    path: `/api/food_menu/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteFoodMenu = async (id) => {
  const requestObj = {
    path: `/api/food_menu/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const foodListListApi = async (id) => {
  let api_path = `/api/food_menu/general_food_menu`;
  if (id) {
    api_path = `/api/food_menu/sale_page/${id}`;
  }
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const FoodMenuDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/food_menu/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
