import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import {
  DelegatesNurtureList,
  programmeListingForDelegate,
} from "src/DAL/Programme/Programme";
import { useState } from "react";
import { useEffect } from "react";
const useStyles = makeStyles(() => ({
  loading: {
    // marginLeft: "50%",
    // marginTop: "20%",
    width: "20px !important",
    height: "auto !important",
  },
}));
function ChangeNurtureCustomConfirmationLoad({
  open,
  setOpen,
  handleAgree,
  title,
  loadingPopUp,
  setloadingPopUp,
  handleChangeData,
  input,
  handleChangeOthersChange,
}) {
  const [delegates, setDelegate] = useState([]);
  const [Search, setSearch] = useState("");
  const classes = useStyles();
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const delegateList = async (Search) => {
    let result = await DelegatesNurtureList(Search, "all");
    if (result.code == 200) {
      setDelegate(
        result.consultant.map((item) => {
          return {
            ...item,
            Delegate_title:
              item.first_name +
              " " +
              item.last_name +
              " " +
              "(" +
              item.email +
              ")" +
              "/" +
              item.team_type,
          };
        })
      );
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      delegateList(Search);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [Search]);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: get_root_value("--popup-background-color"),
            color: "green",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>
          {title ? title : "Are you sure you want to take this action?"}
        </DialogTitle>
        <div className="select-box mb-3 ">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Switch Nurture To{" "}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="switch_to"
              value={input.switch_to}
              label="Switch Nurture To"
              onChange={(e) => handleChangeData(e, "switch_to")}
              required
            >
              <MenuItem value={"self"}>Self</MenuItem>
              <MenuItem value={"other"}>Other</MenuItem>
            </Select>
          </FormControl>
        </div>
        {input.switch_to == "other" && (
          <div className="select-box  mb-3">
            <MUIAutocomplete
              inputLabel="Select Nurture*"
              selectedOption={input.other_delegates}
              setSelectedOption={(e) => {
                handleChangeOthersChange("other_delegates", e);
              }}
              optionsList={delegates}
              setCustomSearch={setSearch}
              name="Delegate_title"
              multiple
            />
          </div>
        )}
        <div style={{ marginLeft: "27px", marginTop: "-8px", color: "white" }}>
          <FormControlLabel
            className="mt-2"
            control={
              <Checkbox
                checked={input.change_for_members}
                onChange={(e) => handleChangeData(e, "change_for_members")}
                name="change_for_members"
              />
            }
            label="Would you like to switch the nurture for those members who already have it delegated?"
          />
        </div>{" "}
        {input.switch_to == "other" && (
          <div style={{ marginLeft: "27px", marginTop: "0px", color: "white" }}>
            <FormControlLabel
              className="mt-2"
              control={
                <Checkbox
                  checked={input.change_for_nurture_members}
                  onChange={(e) =>
                    handleChangeData(e, "change_for_nurture_members")
                  }
                  name="change_for_members"
                />
              }
              label="Would you like to switch the nurture for those members who already have it nurtured?"
            />
          </div>
        )}
        <div style={{ marginLeft: "27px", marginTop: "0px", color: "white" }}>
          <FormControlLabel
            className="mt-2"
            control={
              <Checkbox
                checked={input.change_for_call_type}
                onChange={(e) => handleChangeData(e, "change_for_call_type")}
                name="change_for_call_type"
              />
            }
            label="Do you want to switch those call types that you've chosen for nurturing to this member? "
          />
        </div>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>

          <Button onClick={handleAgree} autoFocus>
            {loadingPopUp ? (
              <CircularProgress className={classes.loading} color="primary" />
            ) : (
              "Agree"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ChangeNurtureCustomConfirmationLoad;
