import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  consultantGoalStatementListApi,
  DeleteGoalStatementQuestionApi,
} from "src/DAL/consultant/consultant";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { show_proper_words } from "src/utils/constant";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ConsultantGoalStatementList() {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getMemberListing = async () => {
    setIsLoading(true);
    const result = await consultantGoalStatementListApi(params.id);
    if (result.code === 200) {
      const data = result.goal_statement.map((goal) => {
        return {
          ...goal,
        };
      });
      setUserList(data);
      setUserData(result.delegate);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  localStorage.setItem("consultant_goalstatement_id", params.id);

  const handleProfile = (value) => {
    navigate(`/consultant/goal-statement-detail/${value._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteGoalStatementQuestionApi(deleteDoc._id);
    if (result.code === 200) {
      getMemberListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMemberListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View detail",
      icon: "iconoir:profile-circled",
      handleClick: handleProfile,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "question_statement", label: "Question" },
    {
      id: "question_type",
      label: "Type",
      renderData: (row) => {
        return show_proper_words(row?.question_type);
      },
    },
    {
      id: "question_order",
      label: "Order",
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title: userData
        ? userData?.first_name +
          userData?.last_name +
          " (" +
          userData?.email +
          ")"
        : "Delegate Goal Statement",
      active: true,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-3">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <h2>Delegate Goal Statement</h2>
          </div>
          <div className="mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={userList}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
              pagePagination={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
