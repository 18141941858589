import { invokeApi } from "../../bl_libs/invokeApi";
export const lessonQuestionListingApi = async (created_for, created_for_id) => {
  const requestObj = {
    path: `/api/questionnaire/list/by_module?created_for=${created_for}&created_for_id=${
      created_for_id ? created_for_id : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const question_detail_api = async (question_id) => {
  const requestObj = {
    path: `/api/questionnaire/questionare_detail/${question_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const questionare_detail_self_image_api = async (
  id,
  type,
  page,
  limmit,
  search
) => {
  const requestObj = {
    path: `/api/questionnaire/questionare_detail_self_image/${id}?type=${type}&page=${page}&limit=${limmit}&search_text=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteLessonQuestion = async (id) => {
  const requestObj = {
    path: `/api/questionnaire/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addLessonQuestionApi = async (data) => {
  const requestObj = {
    path: `/api/questionnaire`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editLessonQuestionApi = async (data, id) => {
  const requestObj = {
    path: `/api/questionnaire/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const all_questions_listing_api = async (
  page,
  limit,
  user_type,
  data
) => {
  const requestObj = {
    path: `/api/questionnaire/list_question_reply/member?page=${page}&limit=${limit}&type=${user_type}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const all_questions_module_listing_api = async (data) => {
  const requestObj = {
    path: `/api/questionnaire/list_modules_data`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const sale_team_on_status = async (page, limit, user_type, data) => {
  const requestObj = {
    path: `/api/sales_team/list_team_for_admin?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const sale_team_change_status = async (id, data) => {
  const requestObj = {
    path: `/api/sales_team/approve_disapprove_team_member/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delegate_search = async (search) => {
  const requestObj = {
    path: `/api/consultant/consultant_list_for/filter?search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const list_member_replies_against_module = async (page, limit, data) => {
  const requestObj = {
    path: `/api/questionnaire/list_member_replies_against_module?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const list_member_replies_against_member = async (
  page,
  limit,
  member_id,
  user_type
) => {
  const requestObj = {
    path: `/api/questionnaire/list_member_question_and_answers/${member_id}?page=${page}&limit=${limit}&type=${user_type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const answers_detail_by_user_and_question_api = async (data) => {
  const requestObj = {
    path: `/api/questionnaire/list_question_reply_against/lesson`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
