import * as React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";

import RecordNotFound from "src/components/RecordNotFound";
import { countries } from "src/utils/country";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { add_order_api, dynamite_products_list_api } from "src/DAL/order/order";
import { useState } from "react";
import { s3baseUrl } from "src/config/config";
import { convertCurrencyToSign } from "src/utils/constant";
import { tax_rate_and_stripe_readers_api } from "src/DAL/Tax/TaxApi";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import PhoneInput from "react-phone-input-2";
import { memberInCalendarGroupsListApi } from "src/DAL/member/member";

const get_user_name = (member) => {
  return member.first_name + " " + member.last_name + " (" + member.email + ")";
};

const default_country = {
  code: "GB",
  label: "United Kingdom",
  phone: "44",
};

export default function Order() {
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFirst, setIsLoadingFirst] = useState(true);
  const [productsList, setProductsList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [taxRates, setTaxRates] = useState([]);
  const [selectedTaxRates, setSelectedTaxRates] = useState([]);
  const [readersList, setReadersList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [searchText, setSearchText] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedReader, setSelectedReader] = useState(null);
  const [defaultMember, setDefaultMember] = useState(null);
  const [country, setCountry] = useState(default_country);

  const EMPTY_VALUES = {
    first_name: "Shop",
    last_name: "Purchase",
    email: "shop@dynamitelifestyle.com",
    contact_number: "440000000000",
    selected_tax: null,
    user_type: "existing",
    payment_method: "stripe_reader",
    selected_reader: selectedReader,
  };

  const [inputs, setInputs] = useState(EMPTY_VALUES);

  const handleRemoveValues = () => {
    setInputs(EMPTY_VALUES);
    setSelectedProducts([]);
    setSelectedMember(defaultMember);
    setCountry(default_country);
  };

  const handleRemove = (itemId) => {
    setSelectedProducts((data) => data.filter((item) => item._id !== itemId));
  };

  const get_dynamite_products_list = async () => {
    let postData = {
      search_text: searchQuery,
    };
    const result = await dynamite_products_list_api(postData);
    if (result.code == 200) {
      setProductsList(result.product);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_list_text_rates = async () => {
    const result = await tax_rate_and_stripe_readers_api();
    if (result.code == 200) {
      setTaxRates(result.data);
      setReadersList(result.stripe_readers);

      if (result.assigned_stripe_readers.length > 0) {
        const idSet = new Set(
          result.assigned_stripe_readers.map((obj) => obj._id)
        );
        const find_reader = result.stripe_readers.find((obj) =>
          idSet.has(obj._id)
        );
        if (find_reader) {
          setSelectedReader(find_reader);
          setInputs((old) => {
            return { ...old, selected_reader: find_reader };
          });
        }
      }

      let find_tax = result.data.filter(
        (tax) => tax.country == country?.label && tax.status == true
      );
      setSelectedTaxRates(find_tax);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const hanldeAddProduct = (value) => {
    let new_obj = {
      ...value,
      selected_quantity: 1,
    };
    setSelectedProducts((old) => [new_obj, ...old]);
    get_dynamite_products_list();
    setSearchQuery("");
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSearchQuery(value);
  };

  const handleSearchUser = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSearchText(value);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleIncrease = (item) => {
    setSelectedProducts((old) =>
      old.map((product) => {
        if (product._id == item._id) {
          return {
            ...product,
            selected_quantity: +product.selected_quantity + 1,
          };
        } else {
          return product;
        }
      })
    );
  };

  const handleDecrease = (item) => {
    if (item.selected_quantity > 1) {
      setSelectedProducts((old) =>
        old.map((product) => {
          if (product._id == item._id) {
            return {
              ...product,
              selected_quantity: +product.selected_quantity - 1,
            };
          } else {
            return product;
          }
        })
      );
    }
  };

  const handleChangeQuantity = (event, item) => {
    let value = event.target.value;
    if (value >= 1) {
      setSelectedProducts((old) =>
        old.map((product) => {
          if (product._id == item._id) {
            return {
              ...product,
              selected_quantity: value,
            };
          } else {
            return product;
          }
        })
      );
    }
  };

  const handleChangeCountry = (value) => {
    let find_tax = taxRates.filter(
      (tax) => tax.country == value?.label && tax.status == true
    );
    setCountry(value);
    setSelectedTaxRates(find_tax);
    setInputs((values) => ({ ...values, selected_tax: null }));
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const get_search_members = async () => {
    const result = await memberInCalendarGroupsListApi(searchText);
    if (result.code === 200) {
      let members = result.members.map((member) => {
        return {
          ...member,
          user_name: get_user_name(member),
        };
      });
      if (isLoadingFirst) {
        let user = {
          ...result.default_member,
          user_name: get_user_name(result.default_member),
        };
        setSelectedMember(user);
        setDefaultMember(user);
      }
      setMembersList(members);
      setIsLoading(false);
      setIsLoadingFirst(false);
    } else {
      setIsLoading(false);
    }
  };

  const get_prodcut_options = (list, selected) => {
    let data_list = [];
    let all_list = [...list];
    all_list.map((item) => {
      let find = selected.find((selected) => selected._id == item._id);
      if (!find) {
        return data_list.push(item);
      }
    });
    return data_list;
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    for (const product of selectedProducts) {
      const price = product.price || 0;
      const selectedQuantity = product.selected_quantity || 0;
      const productTotal = price * selectedQuantity;
      totalAmount += productTotal;
    }
    return totalAmount.toFixed(2);
  };

  const totalAmount = +calculateTotalAmount();
  let tax_percentage = 0;
  if (inputs.selected_tax) {
    tax_percentage = inputs.selected_tax.percentage;
  }

  const taxRate = tax_percentage / 100;
  const taxAmount = totalAmount * taxRate;
  const overallTotal = totalAmount + taxAmount;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedProducts.length < 1) {
      enqueueSnackbar("Select at least one product", { variant: "error" });
      return;
    }
    if (inputs.user_type == "existing") {
      if (!selectedMember) {
        enqueueSnackbar("Please select user", { variant: "error" });
        return;
      }
    }
    let phone_length = inputs.contact_number.toString().length;
    if (inputs.user_type == "new" && +phone_length < 11) {
      enqueueSnackbar("Please enter valid phone number", { variant: "error" });
      return;
    }

    setIsLoading(true);
    let selected_products = selectedProducts.map((product) => {
      return {
        product_id: product._id,
        price: product.price.toFixed(2),
        quantity: product.selected_quantity,
      };
    });

    let postData = {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email: inputs.email,
      contact_number: inputs.contact_number,
      payment_method: inputs.payment_method,
      sub_total: totalAmount.toFixed(2),
      tax: taxAmount.toFixed(2),
      total: overallTotal.toFixed(2),
      tax_rate_id: inputs.selected_tax?._id,
      currency: "gbp",
      products: selected_products,
      reader_id: inputs.selected_reader?.reader_id,
    };

    if (inputs.user_type == "existing") {
      postData.first_name = selectedMember.first_name;
      postData.last_name = selectedMember.last_name;
      postData.email = selectedMember.email;
      postData.contact_number = selectedMember.contact_number;
    }

    const result = await add_order_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleRemoveValues();
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const hanldeSelectedMember = (value) => {
    setSelectedMember(value);
    setSearchText("");
  };

  useEffect(() => {
    get_dynamite_products_list();
  }, [searchQuery]);

  useEffect(() => {
    get_search_members();
  }, [searchText]);

  useEffect(() => {
    get_list_text_rates();
  }, []);

  useEffect(() => {
    setSelectedMember(defaultMember);
  }, [inputs.user_type]);

  return (
    <div className="container-fluid">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-12 ">
          <div className="modal-theme p-3">
            <h3 className="mb-4">Order Summary</h3>
            <div className="row">
              <div className="col-12 chat-search-box mb-3">
                <Autocomplete
                  id="tags-outlined"
                  options={get_prodcut_options(productsList, selectedProducts)}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  value={null}
                  onChange={(event, newValue) => {
                    hanldeAddProduct(newValue);
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Products"
                      placeholder="Search Products"
                      onChange={handleSearch}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row product-container">
              {selectedProducts.length > 0 ? (
                selectedProducts.map((item, index) => {
                  let image = s3baseUrl + item.image[0].image;

                  return (
                    <div className="col-12">
                      <div className="row">
                        <div className="col-2 imgDetail">
                          <div>
                            <img
                              src={image}
                              alt={item.name}
                              className="detail-img"
                            />
                          </div>
                        </div>
                        <div className="col-9 orderdes">
                          <h4>{item.name}</h4>
                          <p>
                            {convertCurrencyToSign(item.currency) +
                              item.price.toFixed(2)}
                          </p>
                        </div>
                        <div className="col-1 position-relative">
                          <span
                            className="icon-container-card icon-postion"
                            onClick={() => handleRemove(item._id)}
                          >
                            x
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8">
                          <div className="item-quantity ms-auto">
                            <span
                              className={`cursor-pointer px-2 ${
                                item.selected_quantity <= 1
                                  ? "item-quantity-diabled"
                                  : ""
                              }`}
                              onClick={() => {
                                handleDecrease(item);
                              }}
                            >
                              -
                            </span>
                            <input
                              type="number"
                              min={1}
                              value={item.selected_quantity}
                              style={{
                                width: `${
                                  25 + String(item.selected_quantity).length * 5
                                }px`,
                              }}
                              onChange={(e) => {
                                handleChangeQuantity(e, item);
                              }}
                            />
                            <span
                              className="cursor-pointer px-2"
                              onClick={() => {
                                handleIncrease(item);
                              }}
                            >
                              +
                            </span>
                          </div>
                        </div>
                        <div className="col-4 text-end">
                          <p className="mb-0">
                            {`${convertCurrencyToSign(item.currency)}${(
                              item.price * item.selected_quantity
                            ).toFixed(2)}`}
                          </p>
                        </div>
                      </div>
                      <hr></hr>
                    </div>
                  );
                })
              ) : (
                <RecordNotFound title="Products" />
              )}
            </div>
            {selectedProducts.length > 0 && (
              <div className="PriceBox mt-2">
                <div className="d-flex justify-content-between mt-3">
                  <p>Subtotal</p>
                  <span>£{`${totalAmount.toFixed(2)}`}</span>
                </div>
                <div className="d-flex justify-content-between">
                  {inputs.selected_tax && (
                    <>
                      <p>
                        {inputs.selected_tax.tax_type_title +
                          " (" +
                          inputs.selected_tax.percentage +
                          "%)"}
                      </p>
                      <span>£{`${taxAmount.toFixed(2)}`}</span>
                    </>
                  )}
                </div>
                <hr></hr>
                <div className="d-flex justify-content-between mt-3">
                  <p className="finalPrice">Total</p>
                  <span style={{ color: "white" }}>
                    £{`${overallTotal.toFixed(2)}`}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="modal-theme p-3 card">
            <h3 className="pt-3">Personal Information</h3>
            <div className="shop-modal-box shop-modal-box-cart">
              <>
                <div className="row mb-4">
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                    <FormControl fullWidth required size="small">
                      <InputLabel required id="demo-simple-select-label">
                        User Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="user_type"
                        size="small"
                        value={inputs.user_type}
                        label="User Type"
                        onChange={handleChange}
                      >
                        <MenuItem value="existing">Existing User</MenuItem>
                        <MenuItem value="new">New User</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {inputs.user_type == "existing" ? (
                    <div className="col-md-6 col-sm-12 mt-3">
                      <Autocomplete
                        id="tags-outlined"
                        options={membersList}
                        getOptionLabel={(option) => option.user_name}
                        filterSelectedOptions
                        value={selectedMember}
                        onChange={(event, newValue) => {
                          hanldeSelectedMember(newValue);
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Members"
                            placeholder="Members"
                            onChange={handleSearchUser}
                            required
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                        <TextField
                          id="outlined-basic"
                          label="First Name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          name="first_name"
                          value={inputs.first_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Last Name"
                          variant="outlined"
                          fullWidth
                          required
                          size="small"
                          name="last_name"
                          value={inputs.last_name}
                          onChange={handleChange}
                        />
                      </div>{" "}
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          size="small"
                          type="email"
                          fullWidth
                          required
                          name="email"
                          value={inputs.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4 ">
                        <PhoneInput
                          country={"gb"}
                          inputClass="react-phone-input"
                          dropdownClass="flag-input"
                          inputProps={{
                            name: "contact_number",
                            required: true,
                            autoComplete: "off",
                          }}
                          value="440000000000000"
                          enableSearch
                          disableSearchIcon
                          countryCodeEditable={false}
                          buttonStyle={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          placeholder="Phone Number: *"
                          onChange={(value) => {
                            handleChangeOthers("contact_number", value);
                          }}
                        />
                      </div>
                    </>
                  )}
                  <div className="col-lg-6 col-md-12 col-sm-12 mt-4 ">
                    <Autocomplete
                      id="country-select-demo"
                      options={countries}
                      autoHighlight
                      value={country}
                      onChange={(event, newValue) => {
                        handleChangeCountry(newValue);
                      }}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label} ({option.code}) +{option.phone}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose a country"
                          size="small"
                          name="country"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 mt-4 ">
                    <MUIAutocomplete
                      inputLabel="Tax Type"
                      selectedOption={inputs.selected_tax}
                      setSelectedOption={(value) => {
                        handleChangeOthers("selected_tax", value);
                      }}
                      optionsList={selectedTaxRates}
                      name="tax_type_title"
                      size="small"
                      autoComplete="new-password"
                    />
                  </div>
                </div>
              </>
            </div>
            <div className="item-title">
              <h3>Payment Method</h3>
            </div>
            <div className="shop-modal-box shop-modal-box-cart">
              <form>
                <div className="row">
                  <RadioGroup
                    aria-label="payment_method"
                    name="payment_method"
                    value={inputs.payment_method}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value="cash"
                      control={<Radio />}
                      label="Cash"
                    />
                    <FormControlLabel
                      value="stripe_reader"
                      control={<Radio />}
                      label="Stripe Reader"
                    />
                  </RadioGroup>
                </div>
              </form>
            </div>
            {inputs.payment_method == "stripe_reader" && (
              <div className="col-md-12 col-sm-12 mt-3">
                <MUIAutocomplete
                  inputLabel="Stripe Reader"
                  selectedOption={inputs.selected_reader}
                  setSelectedOption={(value) => {
                    handleChangeOthers("selected_reader", value);
                  }}
                  optionsList={readersList}
                  name="reader_title"
                  size="small"
                  autoComplete="new-password"
                  required
                />
              </div>
            )}
            <div className="subtotal text-center pb-3 mt-2">
              <button
                style={{ width: "100%" }}
                className="small-contained-button  add-to-cart-button mt-3"
              >
                {isLoading ? (
                  "Submitting..."
                ) : (
                  <>
                    <ShoppingCartIcon />
                    &nbsp; Submit
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
