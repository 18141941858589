import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  delegateGroupListingApi,
  deleteDelegateGroupApi,
} from "src/DAL/delegateGroups/delegategroupApi";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DelegateGroupList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getDelegateGroupData = async () => {
    setIsLoading(true);
    const result = await delegateGroupListingApi();
    if (result.code === 200) {
      const data = result.group.map((group) => {
        return {
          ...group,
        };
      });
      setUserList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate(`/delegate-groups/edit-group/${value.delegate_group_slug}`, {
      state: value,
    });
  };

  const handleViewDetail = (value) => {
    navigate(`/delegate-groups/group-detail/${value.delegate_group_slug}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteDelegateGroupApi(deleteDoc.delegate_group_slug);
    if (result.code === 200) {
      getDelegateGroupData();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/delegate-groups/add-group`);
  };

  useEffect(() => {
    getDelegateGroupData();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleViewDetail,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: " Name",
      renderData: (row) => {
        return (
          <Tooltip title={"View Detail"}>
            <p
              className="mb-0"
              style={{ cursor: "pointer" }}
              onClick={() => handleViewDetail(row)}
            >
              {row.title}
            </p>
          </Tooltip>
        );
      },
    },
    {
      id: "group_type",
      label: "Type",
      renderData: (row) => {
        return <p>{show_proper_words(row.group_type)}</p>;
      },
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h2>Delegate Groups</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Group
            </button>
          </div>
        </div>
        <div className="mt-5">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            MENU_OPTIONS={MENU_OPTIONS}
            data={userList}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
