import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { dd_date_format, show_proper_words } from "src/utils/constant";

export default function SalePageInfoPopoverBouns({
  handleClose,
  anchorEl,
  id,
  open,
  data,
  title,
}) {
  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ heigth: 100 }}
      >
        <div className="sticky-top card">
          <p className="heading_programs">{title}</p>
          <span className="icon-container" onClick={() => handleClose()}>
            <CancelOutlinedIcon fontSize="small" />
          </span>
        </div>
        {data?.map((item, index) => {
          return (
            <Tooltip
              key={index}
              title={
                <>
                  <div>
                    {`Access Type: ${show_proper_words(item?.access_type)}`}
                  </div>
                  {item?.type == "days" ? (
                    <div>
                      No. of Start Days: {item?.no_of_start_days}
                      <br />
                      No. of Limited Days: {item?.no_of_limited_days}
                    </div>
                  ) : (
                    <div>
                      Start Date: {dd_date_format(item?.start_date)}
                      <br />
                      Expiry Date: {dd_date_format(item?.expiry_date)}
                    </div>
                  )}
                </>
              }
            >
              <p
                className="mb-1 program-hover"
                style={{ padding: "2px 5px 2px 8px" }}
              >
                {item?.title}
              </p>
            </Tooltip>
          );
        })}
      </Popover>
    </div>
  );
}
