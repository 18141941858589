import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  get_ninety_day_content_settings_api,
  update_ninety_day_content_setting_api,
} from "src/DAL/NinetyDayQuestion/NinetyDayQuestion";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function NinetyDayContentSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    formula_history_button_text: "",
    goal_will_be_achieved_text: "",
    tooltip_text_for_unset_date: "",
    history_graph_heading: "",
    history_earnings_heading: "",
    history_questions_list_heading: "",
    close_plan_button_text: "",
    tracker_history_button_text: "",
    tracker_graphs_button_text: "",
    tracker_history_page_description: "",
    close_plan_popup_description: "",
    start_plan_popup_description: "",
    save_target_button_text: "",
    tracker_history_title: "",
    formula_history_page_description: "",
    formula_history_title: "",
  });

  const get_content_setting = async () => {
    const result = await get_ninety_day_content_settings_api();
    if (result.code == 200) {
      if (result.ninety_day_content_setting) {
        setInputs(result.ninety_day_content_setting);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let postData = {
      ninety_day_content_setting: inputs,
    };
    const result = await update_ninety_day_content_setting_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    get_content_setting();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit} className="row">
        <div className="col-12">
          <h2>90 Day Content Setting</h2>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Formula History Button Text"
            variant="outlined"
            fullWidth
            name="formula_history_button_text"
            value={inputs.formula_history_button_text}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Tracker History Button Text"
            variant="outlined"
            fullWidth
            name="tracker_history_button_text"
            value={inputs.tracker_history_button_text}
            onChange={handleChange}
          />
        </div>{" "}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Tracker Graphs Button Text"
            variant="outlined"
            fullWidth
            name="tracker_graphs_button_text"
            value={inputs.tracker_graphs_button_text}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Save Target Button Text"
            variant="outlined"
            fullWidth
            name="save_target_button_text"
            value={inputs.save_target_button_text}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Close Plan Button Text"
            variant="outlined"
            fullWidth
            name="close_plan_button_text"
            value={inputs.close_plan_button_text}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Formula History Title for creating history"
            variant="outlined"
            fullWidth
            name="formula_history_title"
            value={inputs.formula_history_title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Tracker History Title for creating history"
            variant="outlined"
            fullWidth
            name="tracker_history_title"
            value={inputs.tracker_history_title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Goal Will Be Achieved Text"
            variant="outlined"
            fullWidth
            name="goal_will_be_achieved_text"
            value={inputs.goal_will_be_achieved_text}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Tooltip Text if start date is not set"
            variant="outlined"
            fullWidth
            name="tooltip_text_for_unset_date"
            value={inputs.tooltip_text_for_unset_date}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="History Graph Heading"
            variant="outlined"
            fullWidth
            name="history_graph_heading"
            value={inputs.history_graph_heading}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="History Earnings Heading"
            variant="outlined"
            fullWidth
            name="history_earnings_heading"
            value={inputs.history_earnings_heading}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="History Questions List Heading"
            variant="outlined"
            fullWidth
            name="history_questions_list_heading"
            value={inputs.history_questions_list_heading}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Close Plan Popup Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="close_plan_popup_description"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Start Plan Popup Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="start_plan_popup_description"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Formula History Page Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="formula_history_page_description"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Tracker History Page Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="tracker_history_page_description"
            editorHeight={320}
          />
        </div>
        <div className="text-end mt-4" id="fixedbutton">
          <button className="small-contained-button" disabled={isLoadingForm}>
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
