import { invokeApi } from "../../bl_libs/invokeApi";

export const addProgressCategoryApi = async (data) => {
  const requestObj = {
    path: `/api/progress_report_cateogry`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addCategoryApi = async (data) => {
  const requestObj = {
    path: `/api/facebook_template_category/add_facebook_template_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateProgressCategoryApi = async (data, id) => {
  const requestObj = {
    path: `/api/progress_report_cateogry/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateTemplateApi = async (data, id) => {
  const requestObj = {
    path: `/api/facebook_template/update_status_or_info/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const progressCategoryListApi = async (page, limit, search) => {
  const requestObj = {
    path: `/api/progress_report_cateogry?page=${page}&limit=${limit}&search_text=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const CategoryListApi = async (page, limit, search) => {
  const requestObj = {
    path: `/api/facebook_template_category/list_facebook_template_category?page=${page}&limit=${limit}&search_text=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const facebookTemplatesListApi = async () => {
  const requestObj = {
    path: `/api/facebook_template/facebook_template_listing`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const facebookTemplatesFetchApi = async () => {
  const requestObj = {
    path: `/api/facebook_template/fetch_templates_from_facebook`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const teamDetail = async (data) => {
  const requestObj = {
    path: `api/team/team_detail.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editTeam = async (data) => {
  const requestObj = {
    path: `api/team/edit_team.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteProgressReportApi = async (id) => {
  const requestObj = {
    path: `/api/progress_report_cateogry/delete/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const teamChangePassword = async (data) => {
  const requestObj = {
    path: `api/update_password/team_change_password_by_manager.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
