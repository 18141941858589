import ImageGallery from "react-image-gallery";
const GoalGallerySlider = ({ data }) => {
  // const images = [
  //   {
  //     original: "https://picsum.photos/id/1018/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1018/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1015/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1015/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1019/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1019/250/150/",
  //   },
  // ];
  return (
    <div className="">
      <ImageGallery
        items={data}
        autoPlay={true}
        showFullscreenButton={false}
        showPlayButton={false}
        slideInterval={3000}
      />
    </div>
  );
};

export default GoalGallerySlider;
