import React, { useEffect, useState } from "react";
import { CircularProgress, Switch, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useNavigate } from "react-router-dom";
import {
  deleteProgressReportApi,
  facebookTemplatesFetchApi,
  facebookTemplatesListApi,
  progressCategoryListApi,
  updateTemplateApi,
} from "src/DAL/progressCategoryApi/progressCategoryApi";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function FacebookTemplates() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [changeAccessData, setChangeAccessData] = useState();
  const [membersList, setMembersList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openConfirmationFetchData, setOpenConfirmationFetchData] =
    useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [deleteId, setDeleteId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const handleNavigate = () => {
    navigate(`/facebook-catagories/add-catagory`);
  };

  const getProgressList = async () => {
    let search_keyword =
      localStorage.getItem("Progress_search_text") == null
        ? searchText
        : localStorage.getItem("Progress_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    setIsLoading(true);

    const result = await facebookTemplatesListApi();
    if (result.code == 200) {
      console.log(result, "result");
      // setTotalPages(result?.total_pages);
      const members = result?.templates?.map((member) => {
        return {
          ...member,
          facebookTemplateName: show_proper_words(
            member.facebook_template_name
          ),
          systemTemplateName: show_proper_words(member.system_name),
          systemCategory: member.system_category
            ? member.system_category
            : "N/A",
        };
      });

      setMembersList(members);
      // setTotalCount(result.total_progress_report_category_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getProgressList();
  };
  const handleEdit = (value) => {
    navigate(`/facebook-catagories/${value._id}`, {
      state: value,
    });
  };
  const handleOpenDelete = (value) => {
    setDeleteId(value._id);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await deleteProgressReportApi(deleteId);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getProgressList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
  ];

  useEffect(() => {
    getProgressList();
  }, [rowsPerPage, page]);

  useEffect(() => {
    localStorage.setItem("Progress_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("Progress_search_text", "");
    } else {
      localStorage.setItem("Progress_search_text", searchText);
    }
  }, [searchText]);

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },

    {
      id: "systemTemplateName",
      label: "System Template Name",
      alignRight: false,
    },
    {
      id: "facebookTemplateName",
      label: "FB  Template Name",
      alignRight: false,
    },

    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        return (
          <Tooltip title="Change Template status">
            <Switch
              checked={row.status}
              onChange={() => handleConfirmation(row)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Tooltip>
        );
      },
    },
    {
      id: "systemCategory",
      label: "System Category Name",
      alignRight: false,
    },
  ];

  const handleChangePageAccess = async () => {
    let postData = {
      update_type: "status",
      status: !changeAccessData.status,
    };
    const result = await updateTemplateApi(postData, changeAccessData._id);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenConfirmation(false);
      getProgressList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenConfirmation(false);
    }
  };
  const handleFetchFacbookTemplateData = async (value) => {
    const result = await facebookTemplatesFetchApi();
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getProgressList();
      setOpenConfirmationFetchData(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenConfirmationFetchData(false);
    }
  };
  const handleConfirmation = (value) => {
    setChangeAccessData(value);
    setOpenConfirmation(true);
  };
  const handleConfirmationFetch = () => {
    setOpenConfirmationFetchData(true);
  };

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        title={"Are you sure you want to change status ?"}
        handleAgree={handleChangePageAccess}
      />{" "}
      <CustomConfirmation
        open={openConfirmationFetchData}
        setOpen={setOpenConfirmationFetchData}
        title={"Are you sure you want to fetch  facebook templates?"}
        handleAgree={handleFetchFacbookTemplateData}
      />
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-lg-8 col-sm-8 ">
            <h2>Templates</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button
              onClick={handleConfirmationFetch}
              className="small-contained-button"
            >
              Fetch Templates
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={membersList}
          className="card-with-background"
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
}
