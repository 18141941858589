import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { Icon } from "@iconify/react";
import {
  CircularProgress,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { get_root_value } from "src/utils/domUtils";
import { programmeListingForDelegate } from "src/DAL/Programme/Programme";
import { useEffect } from "react";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DelegateFilterDateBase({
  setProgramsList,
  programsList,
  filterData,
  searchSubmitFilter,
  handleChangeOthers,
  handleClearFilter,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const getProgrammesList = async () => {
    let result = await programmeListingForDelegate();
    if (result.code == 200) {
      setProgramsList(
        result.program.map((item) => {
          return {
            ...item,
            chip_label: item.title,
            chip_value: item._id,
          };
        })
      );
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const roleList = [
    { title: "Both", value: "both" },
    { title: "Delegate", value: "delegate" },
    { title: "Consultant", value: "consultant" },
  ];

  let role_array = roleList.map((role) => {
    return {
      ...role,
      chip_label: role.title,
      chip_value: role.value,
    };
  });

  useEffect(() => {
    getProgrammesList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(filterData, "from programmm");

  return (
    <div className="container-fluid new-memories">
      <div className="row">
        <div className="col-12 mt-3">
          <MUIAutocomplete
            inputLabel="Programme"
            selectedOption={filterData.program}
            setSelectedOption={(e) => {
              handleChangeOthers("program", e);
            }}
            optionsList={programsList}
            name="title"
            multiple
          />
        </div>
        <div className="col-12 mt-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="Start Commission Date"
                inputFormat="dd-MM-yyyy"
                name="start_date"
                value={filterData.start_date}
                onChange={(e) => {
                  handleChangeOthers("start_date", e);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </div>
        <div className="col-12 mt-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="End Commission Date"
                inputFormat="dd-MM-yyyy"
                name="end_date"
                value={filterData.end_date}
                onChange={(e) => {
                  handleChangeOthers("end_date", e);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </div>
        {/* <div className="col-12 mt-3">
          <MUIAutocomplete
            inputLabel="Filter For"
            selectedOption={filterData.role}
            setSelectedOption={(e) => {
              handleChangeOthers("role", e);
            }}
            optionsList={role_array}
            name="title"
          />
        </div> */}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
          <FormControl fullWidth className="mt-3">
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              value={filterData.status}
              className="status-field"
              name="status"
              onChange={(e) => {
                handleChangeOthers("status", e.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>;
              <MenuItem value={true}>Active</MenuItem>;
              <MenuItem value={false}>Inactive</MenuItem>;
            </Select>
          </FormControl>
        </div>
        <FormControlLabel
          className="mt-2"
          control={
            <Checkbox
              checked={filterData.search_by_commission}
              onChange={(e) => {
                handleChangeOthers("search_by_commission", e.target.checked);
              }}
            />
          }
          label="Search By Commission Due"
        />
        {filterData.search_by_commission && (
          <>
            <div className="col-6">
              <TextField
                className="mt-3 inputs-fields"
                type="number"
                id="commission_from"
                label="Commission From"
                variant="outlined"
                name="commission_from"
                value={filterData.commission_from}
                required
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => {
                  handleChangeOthers("commission_from", e.target.value);
                }}
              />
            </div>
            <div className="col-6">
              <TextField
                className="mt-3 inputs-fields"
                type="number"
                id="commission_to"
                label="Commission To"
                variant="outlined"
                name="commission_to"
                value={filterData.commission_to}
                required
                InputProps={{ inputProps: { min: filterData.coins_from } }}
                onChange={(e) => {
                  handleChangeOthers("commission_to", e.target.value);
                }}
              />
            </div>
          </>
        )}

        <div className="col-12">
          <Box sx={{ py: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              onClick={searchSubmitFilter}
              startIcon={
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                    fill={get_root_value("--portal-theme-primary")}
                  />
                </svg>
              }
            >
              Filter
            </Button>
          </Box>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={handleClearFilter}
            startIcon={<Icon icon={roundClearAll} />}
          >
            Clear All
          </Button>
        </div>
      </div>
    </div>
  );
}
