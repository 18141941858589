import { invokeApi } from "../../bl_libs/invokeApi";

export const affirmations_list_api = async () => {
  const requestObj = {
    path: `/api/general_affirmation`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_affirmation_api = async (data) => {
  const requestObj = {
    path: `/api/general_affirmation/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_affirmation_api = async (data, id) => {
  const requestObj = {
    path: `/api/general_affirmation/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const affirmation_detail_api = async (id) => {
  const requestObj = {
    path: `/api/general_affirmation/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_affirmation_api = async (id) => {
  const requestObj = {
    path: `/api/general_affirmation/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
