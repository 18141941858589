import { useEffect, useState } from "react";
import { Chip, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useSnackbar } from "notistack";
import {
  unsubscribed_members_list,
  update_email_subscription_status,
} from "src/DAL/member/member";
import moment from "moment";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function UnsubscribeMembers() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const [page, setPage] = useState(0);

  const getUnsubscribedMembersListing = async () => {
    setIsLoading(true);

    let postData = {
      search: searchText,
    };

    const result = await unsubscribed_members_list(page, rowsPerPage, postData);
    if (result.code == 200) {
      let data = result.members.map((item) => {
        let full_name = `${item.first_name} ${item.last_name} (${item.email})`;
        return {
          ...item,
          full_name,
          created_at: moment(item.created_at).format("DD-MM-YYYY"),
          table_avatar: {
            src: item.profile_image ? s3baseUrl + item.profile_image : "",
            alt: item.first_name,
          },
        };
      });

      setListData(data);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_member_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleResubscribe = async () => {
    let postData = {
      member_id: selectedMember,
      email_notification: true,
    };
    setOpenConfirmation(false);
    const result = await update_email_subscription_status(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setListData(listData.filter((item) => item._id !== selectedMember));
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleConfirmation = (id) => {
    setOpenConfirmation(true);
    setSelectedMember(id);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getUnsubscribedMembersListing();
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "table_avatar", label: "Profile", type: "thumbnail" },
    { id: "full_name", label: "Name" },
    { id: "created_at", label: "Created At" },
    { id: "status", label: "Status", type: "row_status" },
    {
      id: "action",
      label: "Action",
      renderData: (row) => {
        return (
          <div onClick={() => handleConfirmation(row._id)}>
            <span
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "#f6bd4b",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              Click to Remove
            </span>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getUnsubscribedMembersListing();
  }, [page, rowsPerPage]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        title={"Are you sure you want to remove this member?"}
        handleAgree={handleResubscribe}
      />
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-6">
            <h2>Unsubscribed Members</h2>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={listData}
          className="card-with-background"
          pagePagination={true}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      </div>
    </>
  );
}
