import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  billingTicketsApi,
  check_in_by_admin_api,
  delete_checked_in,
  sent_billing_email,
} from "src/DAL/Transaction/transactionApi";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import { download_csv_file } from "src/utils/convertHtml";
import moment from "moment";
import BillingFilter from "./BillingFilter";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import FilteredChip from "src/components/FilteredChip";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";

const headers = [
  { key: "Name", label: "Name" },
  { key: "email", label: "Email" },
  { key: "contact_number", label: "Phone" },
  { key: "venue_title", label: "Venue" },
  { key: "member", label: "Purchased By" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const csv_array_options = [
  {
    label: "First Name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Last Name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true, is_disabled: true },
  {
    label: "Contact Number",
    value: "contact_number",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Venue", value: "venue", is_checked: false },
  { label: "Purchase by", value: "purchase_by", is_checked: false },
];

export default function BillingTicketsList() {
  const classes = useStyles();
  const [ticketsList, setTicketsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchText, setSearchText] = useState("");
  const [loadingIndex, setLoadingIndex] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [openSign, setOpenSign] = useState(false);
  const [checkInRow, setCheckInRow] = useState();
  const [rowIndex, setRowIndex] = useState();
  const [selected, setSelected] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState("");
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };
  const EMPTY_FILTER = {
    plan: null,
    sale_page: null,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("billing_tickets_data", JSON.stringify(filterData));
    getTicketsListing(filterData);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    setFilterData(data);
    getTicketsListing(data);
    localStorage.setItem("billing_tickets_data", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    getTicketsListing(EMPTY_FILTER);
    localStorage.removeItem("billing_tickets_data");
  };

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleAddPaidTickets = () => {
    navigate(`/billing-tickets/add-paid-tickets`);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const getTicketsListing = async (filter_data) => {
    setIsLoading(true);
    let postData = { ...filter_data };

    if (postData.sale_page) {
      postData.sale_page = filter_data.sale_page._id;
    }
    if (postData.plan) {
      postData.plan = filter_data.plan._id;
    }

    handleCloseFilterDrawer();
    const result = await billingTicketsApi(
      page,
      rowsPerPage,
      postData,
      searchText
    );
    if (result.code === 200) {
      let chipData = { ...filter_data };
      if (chipData.sale_page == null) {
        delete chipData.plan;
      }
      setUpdateFilterData(chipData);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages - 1);
      let data = [];
      if (result.transaction_tickets.length > 0) {
        data = result.transaction_tickets.map((user) => {
          let created_date = moment(user.createdAt).format("DD-MM-YYYY");
          let user_name = "N/A";
          let main_user = "N/A";
          if (user.firstName) {
            user_name = user.firstName + " " + user.lastName;
          } else if (user.first_name) {
            user_name = user.first_name + " " + user.last_name;
          }
          if (user.purchase_by) {
            main_user =
              user?.purchase_by?.first_name +
              " " +
              user?.purchase_by?.last_name +
              " " +
              "(" +
              user?.purchase_by?.email +
              ")";
          }

          return {
            ...user,
            venue_title: user.venue_id?.title ? user.venue_id?.title : "N/A",
            Name: user_name + " (" + user.email + ")",
            CheckInTime: user.checkin_date_time
              ? moment(user.checkin_date_time).format("DD-MM-YYYY hh:mm A")
              : "N/A",
            PageName:
              user?.sale_page?.sale_page_title +
              " (" +
              user?.plan?.plan_title +
              ")",
            member: main_user,
            created_date: created_date ? created_date : "",
            MENU_OPTIONS: MENU_OPTIONS,
          };
        });
      }
      setTicketsList(data);
      setFilterQuery(result.query);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgree = (value) => {
    setOpenSign(false);
    handle_checkin_confirm(checkInRow, rowIndex);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_checked_in(selectedObject._id);
    if (result.code === 200) {
      setTicketsList((prev) => {
        return prev.filter((data) => data._id !== selectedObject._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handle_checkin_confirm = async (row, index) => {
    setLoadingIndex(index);
    const result = await check_in_by_admin_api(row._id);
    if (result.code === 200) {
      let temp_state = [...ticketsList];
      let index = temp_state.findIndex((ticket) => ticket._id == row._id);
      let temp_element = { ...temp_state[index] };
      temp_element.checkin_history = result.billing_ticket.checkin_history;
      temp_state[index] = temp_element;
      setTicketsList(temp_state);
      setLoadingIndex("");
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoadingIndex("");
    }
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      alignRight: false,
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    { id: "Name", label: "Name" },
    { id: "PageName", label: "Sale Page" },
    { id: "contact_number", label: "Phone" },
    { id: "venue_title", label: "Venue" },
    { id: "member", label: "Purchased By" },
    { id: "created_date", label: "Created Date" },
  ];
  const handleExportClick = () => {
    download_csv_file(headers, ticketsList);
  };
  let MENU_OPTIONS_MAIN = [
    // {
    //   label: "Export Csv",
    //   icon: "ic:round-cloud-download",
    //   handleClick: handleExportClick,
    // },
    {
      label: "Request For Csv",
      icon: "ic:round-cloud-download",
      handleClick: showPopUPcsv,
    },
  ];

  const handleSendEmail = async () => {
    setIsLoadingEmail(true);
    let postData = {
      billing_ticket_id: selected,
    };
    const result = await sent_billing_email(postData);
    if (result.code == 200) {
      setSelected([]);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingEmail(false);
    } else {
      setIsLoadingEmail(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleAgreeDelete = (value) => {
    setSelectedObject(value);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("billing_tickets_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getTicketsListing(filter_data);
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        openDelete={openSign}
        setOpenDelete={setOpenSign}
        title={"Are you sure you want to signin?"}
        handleAgree={handleAgree}
      />
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-lg-4 col-sm-12">
            <h2>Billing Tickets</h2>
          </div>
          <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
            <div>
              <button
                className="small-contained-button me-3"
                onClick={handleOpenFilterDrawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
              <button
                className="small-contained-button"
                onClick={handleAddPaidTickets}
              >
                Add Ticket
              </button>
            </div>
            <CustomPopoverSection menu={MENU_OPTIONS_MAIN} />
          </div>
        </div>

        <FilteredChip
          data={updateFilterData}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={ticketsList}
          className="card-with-background"
          checkbox_selection={true}
          selected={selected}
          setSelected={setSelected}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
        {selected.length > 0 && (
          <div className="text-end mt-4 me-2" id="fixedbutton">
            <button
              className="small-contained-button"
              onClick={handleSendEmail}
            >
              {isLoadingEmail ? "Sending..." : "Send Email"}
            </button>
          </div>
        )}
      </div>
      <GeneralPopUpModel
        open={showExportcsvFile}
        setOpen={setShowExportcsvFile}
        title={"Export CSV file request  "}
        componentToPassDown={
          <GeneralRequestForCsv
            options_array={csv_array_options}
            collection_name={"billing_tickets"}
            filter_data={filterQuery}
            setShowExportcsvFile={setShowExportcsvFile}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <BillingFilter
            filterData={filterData}
            searchFunction={searchFunction}
            handleSelectOther={handleSelectOther}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
