import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { afflicateChangeid } from "src/DAL/member/member";

export default function ChangeAffiliateID({
  selectedObject,
  setOpen,
  memberDetailInfo,
}) {
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handlechangeAffiliate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const postData = {
      affiliate_url_name: inputs.affiliate_url_name,
      user_type: "member",
    };
    const result = await afflicateChangeid(postData, inputs._id);
    if (result.code === 200) {
      memberDetailInfo(inputs);
      setOpen(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setInputs(selectedObject);
  }, [selectedObject]);

  return (
    <div className="container-fluid">
      <form className="row" onSubmit={handlechangeAffiliate}>
        <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            fullWidth
            name="name"
            disabled
            required
            value={inputs.first_name}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
          <TextField
            id="outlined-basic"
            label="Affiliate Id"
            variant="outlined"
            fullWidth
            name="affiliate_url_name"
            required
            value={inputs.affiliate_url_name}
            onChange={handleChange}
          />
        </div>
        <div class="col-12 mt-3">
          <button class="small-contained-button w-100">
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
