import { SnackbarProvider } from "notistack";
import { Button, Slide, StyledEngineProvider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// routes
import Routers from "./routes";
// font
import "src/assets/fonts/Montserrat-Italic-VariableFont_wght.ttf";
import "src/assets/fonts/Montserrat-VariableFont_wght.ttf";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";

// import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/index.css";
import "./assets/css/style.css";
import "./assets/javascript/timcemin.js";
import "react-phone-input-2/lib/style.css";

// import "./assets/css/dark.css";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import { ContextPGIMode } from "./Hooks/PGIModeContext";
import { Pods } from "./pages";
import { Helmet } from "react-helmet-async";
import { project_mode, s3baseUrl } from "./config/config";
import { createRef, useEffect, useState } from "react";
import { ContentSettingState } from "./Hooks/ContentSettingState";

// ----------------------------------------------------------------------

export default function App() {
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [favIcon, setFavIcon] = useState("");
  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  if (project_mode !== "dev" && window.location.hostname !== "localhost") {
    console.log = () => {};
  }
  useEffect(() => {
    setMetaTitle(localStorage.getItem("metaTitle"));
    setMetaDescription(localStorage.getItem("metaDescription"));
    setFavIcon(localStorage.getItem("favIcon"));
  }, [localStorage]);

  return (
    <ContextPGIMode>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />

        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          ref={notistackRef}
          action={(key) => (
            <Button
              className="snackbar-cross-icon"
              onClick={onClickDismiss(key)}
            >
              <CloseIcon />
            </Button>
          )}
          TransitionComponent={Slide}
          maxSnack={3}
        >
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
            <link
              rel="icon"
              type="image/x-icon"
              href={favIcon ? s3baseUrl + favIcon : ""}
            />
          </Helmet>
          <ContentSettingState>
            <Routers />
          </ContentSettingState>
        </SnackbarProvider>
      </ThemeConfig>
    </ContextPGIMode>
  );
}
