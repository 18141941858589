import React from "react";
import {
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker } from "@mui/lab";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

export default function ReminderFilter({
  handleChaneOthers,
  filterData,
  searchFunction,
  handleClearFilter,
}) {
  return (
    <div className="container-fluid new-memories transaction-filter">
      <div className="col-12 mt-3 mb-2">
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-multiple-name-label1">Status</InputLabel>
          <Select
            labelId="demo-multiple-name-label1"
            id="demo-multiple-name"
            name="user_status"
            value={filterData.user_status}
            onChange={(e) => {
              handleChaneOthers("user_status", e.target.value);
            }}
            input={<OutlinedInput label="Status" />}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="sent">Sent</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="col-12 mt-3">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date"
            name="date"
            inputFormat="dd-MM-yyyy"
            value={filterData.date}
            onChange={(e) => {
              handleChaneOthers("date", e);
            }}
            format="DD-MM-YYYY"
            renderInput={(params) => (
              <TextField {...params} className="inputs-fields" fullWidth />
            )}
          />
        </LocalizationProvider>
      </div>
      <Box sx={{ py: 2 }}>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={searchFunction}
          startIcon={
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 5 }}
            >
              <path
                d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                fill={get_root_value("--portal-theme-primary")}
              />
            </svg>
          }
        >
          Filter
        </Button>
      </Box>
      <Button
        fullWidth
        size="large"
        type="submit"
        color="inherit"
        variant="outlined"
        onClick={handleClearFilter}
        startIcon={<Icon icon={roundClearAll} />}
      >
        Clear All
      </Button>
    </div>
  );
}
