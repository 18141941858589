import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import { DeleteRoomUserApi } from "src/DAL/consultant/consultant";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ConsultantPodsRoomUser({ groupDetail, roomData }) {
  const params = useParams();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getConsultantListing = async () => {
    setIsLoading(true);
    const data = roomData.room_user.map((roomUser) => {
      let full_name = "N/A";
      if (roomUser._id.first_name) {
        full_name = roomUser._id.first_name + " " + roomUser._id.last_name;
      }
      return {
        ...roomUser,
        full_name,
        table_avatar: {
          src: s3baseUrl + roomUser?._id?.profile_image,
          alt: roomUser?._id?.first_name,
        },
        email: roomUser._id.email,
      };
    });
    setUserList(data);
    setIsLoading(false);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteRoomUserApi(params.id, deleteDoc?._id?._id);
    if (result.code === 200) {
      getConsultantListing();
      groupDetail();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getConsultantListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "full_name", label: "Name" },
    { id: "table_avatar", label: "Profile", type: "thumbnail" },
    { id: "email", label: "Email" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        MENU_OPTIONS={MENU_OPTIONS}
        data={userList}
        className="card-with-background"
        pagePagination={true}
      />
    </>
  );
}
