import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import { TrendingUpRounded } from "@mui/icons-material";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ConsultantPodsMemberList({ podMembers }) {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getConsultantListing = async () => {
    setIsLoading(TrendingUpRounded);
    const data = podMembers.map((pods) => {
      let full_name = "N/A";
      if (pods.first_name) {
        full_name = pods.first_name + " " + pods.last_name;
      }
      return {
        ...pods,
        full_name,
        table_avatar: {
          src: s3baseUrl + pods.profile_image,
          alt: pods?.first_name,
        },
      };
    });
    setUserList(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getConsultantListing();
  }, []);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "full_name", label: " Name" },
    { id: "table_avatar", label: "Profile", type: "thumbnail" },
    { id: "email", label: "Email" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={userList}
        className="card-with-background"
        pagePagination={true}
      />
    </>
  );
}
