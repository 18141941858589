import {
  Avatar,
  Card,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import RecordNotFound from "src/components/RecordNotFound";

import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  DeleteBookingNote,
  EditBookingNote,
  addBookingNote,
  noteBookingListing,
} from "src/DAL/BookingNotes/BookingNotes";
import { dd_date_format } from "src/utils/constant";
import CustomPopover from "src/components/MenuOption/CustomPopover";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import { defaultSettingApi } from "src/DAL/SiteSetting/siteSetting";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function BookingNotes() {
  const [isLoading, setIsLoading] = useState(false);
  const [inputLessonNote, setInputLessonNote] = useState({ notes: "" });
  const [NoteListing, setNoteListing] = useState([]);
  const [noteId, setNoteId] = useState(0);
  const classes = useStyles();
  const ref = useRef(null);
  const navigate = useNavigate();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [memberData, setmemberData] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [defaultTimeZone, setDefaultTimeZone] = useState("");
  const [useId, setuseId] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { state } = useLocation();
  const [showButtons, setShowButtons] = useState({
    addButton: true,
    editButton: false,
  });
  const [template, setTemplate] = useState([
    {
      label: `Hi`,
      message: `Hi How are you?`,
    },
    {
      label: "Greetings",
      message: "Thanks for reaching out to our support team, ",
    },
    { label: "Regards", message: "Regards\nDynamite Lifestyle Support Team" },
    {
      label: "Ending Signature",
      message: "Regards Dynamite Lifestyle Support Team",
    },
    {
      label: "Thanks & Regards",
      message: "Thanks & Regards\nDynamite Lifestyle Support Team",
    },
    {
      label: "Feedback",
      message: "Please Let us know if this helps you",
    },
    {
      label: "Reminder",
      message:
        "Hi,  we hope your issue has been resolved kindly give your feedback\nRegards Dynamite Lifestyle Support Team",
    },
  ]);
  const handleMessage = (value) => {
    const messageTemplate = value;
    setInputLessonNote((inputs) => ({
      ...inputs,
      ["notes"]: inputLessonNote.notes + messageTemplate,
    }));
  };
  const getDefaultSetting = async () => {
    setIsLoading(true);
    const result = await defaultSettingApi();
    if (result.code === 200) {
      moment.tz.setDefault(result?.default_setting?.time_zone);
      setDefaultTimeZone(result?.default_setting?.time_zone);
      setIsLoading(false);
    }
  };
  //Changing Textarea values with onchange
  const handleNoteChange = (event) => {
    const value = event.target.value;
    setInputLessonNote(value);
  };
  //Adding Note
  const handleSubmit = async (e) => {
    e.preventDefault();

    let postData = {
      note: inputLessonNote.notes,
      booking_id: params.id,
    };

    setIsLoading(true);
    const result = await addBookingNote(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getNoteListing();
      setIsOpenForm(false);
      setInputLessonNote("");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  //Getting Note In textarea
  const handleEdit = (note) => {
    setInputLessonNote(note.note);
    setInputLessonNote((prevState) => ({
      ...prevState,
      ["notes"]: note.note,
    }));

    setNoteId(note._id);
    setIsOpenForm(true);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
    setShowButtons({
      addButton: false,
      editButton: true,
    });

    const notes = document.getElementById("notes-input");
    setTimeout(() => {
      notes.scrollIntoView();
      notes.focus();
    }, 500);
  };

  const handleCancelUpdate = () => {
    setInputLessonNote("");
    setNoteId("");
    setIsOpenForm(false);
    setShowButtons({
      addButton: true,
      editButton: false,
    });
  };
  const handleCancelAdd = () => {
    setIsOpenForm(false);
  };
  // let get_user = _get_user_from_localStorage();
  let get_user = localStorage.getItem("_id");
  const getNoteListing = async () => {
    setIsLoading(true);
    try {
      const result = await noteBookingListing(params.id);
      if (result.code === 200) {
        setNoteListing(result.booking_notes);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleAgreeDelete = (note) => {
    setNoteId(note._id);
    setOpenDelete(true);
  };

  //Updating Note
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      note: inputLessonNote.notes,
    };
    const result = await EditBookingNote(postData, noteId);
    if (result.code === 200) {
      setInputLessonNote("");
      setNoteId(0);
      setShowButtons({
        addButton: true,
        editButton: false,
      });
      getNoteListing();
      setIsOpenForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  //Deleting Note
  const handleDelete = async () => {
    setOpenDelete(false);
    let postData = {
      note_id: noteId,
      member_id: params.id,
    };
    const result = await DeleteBookingNote(noteId);
    if (result.code === 200) {
      getNoteListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleClick = () => {
    setIsOpenForm(true);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleBack = () => {
    navigate("/bookings");
  };

  useEffect(() => {
    setmemberData(state.user_info);

    getDefaultSetting();
    getNoteListing();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let breadCrumbMenu = [
    {
      title: "Booking",
      navigation: `/bookings`,
      active: false,
    },
    {
      title: "Booking Note",
      active: false,
    },
    {
      title:
        memberData?.first_name +
        " " +
        memberData?.last_name +
        " (" +
        memberData?.email +
        ") ",
      active: true,
    },
  ];

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this note?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-9 d-flex">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-12 col-md-4 col-lg-3">
            <button
              className="small-contained-button float-end mt-1"
              onClick={handleClick}
            >
              Add Note
            </button>
          </div>
        </div>
      </div>

      <div className="">
        {isLoading == true ? (
          <CircularProgress className={classes.loading} color="primary" />
        ) : (
          <div className="row p-2">
            {NoteListing?.length > 0 ? (
              NoteListing?.map((note, index) => (
                <>
                  <div className="card incoming-message section-space w-100  ps-2 pe-3">
                    <div className="chat-message-body pt-1 pb-4 mt-2">
                      <div className="d-flex ">
                        <Avatar
                          alt={
                            note.user_info?.first_name &&
                            note.user_info?.first_name
                          }
                          src={
                            s3baseUrl +
                            note?.user_info.profile_image?.thumbnail_1
                          }
                        />
                        <div className="description w-100 custom-popover-box ps-2">
                          <div className="set-title-width d-flex justify-content-between w-100 pe-3">
                            <p className="card-title color pt-2 mb-0">
                              {note.user_info?.first_name &&
                              note?.user_info.last_name
                                ? note.user_info?.first_name +
                                  " " +
                                  note?.user_info.last_name +
                                  " " +
                                  "(" +
                                  " " +
                                  note?.user_info?.action_by?.replace(
                                    /_user/,
                                    " "
                                  ) +
                                  ")"
                                : note.user_info?.first_name +
                                  " " +
                                  "(" +
                                  " " +
                                  note?.user_info?.action_by?.replace(
                                    /_user/,
                                    " "
                                  ) +
                                  ")"}
                            </p>
                            <span className="date-color pt-2 mb-0">
                              {moment(
                                moment.tz(note.date, defaultTimeZone),
                                "YYYY-mm-dd HH:mm:ss"
                              ).fromNow()}
                            </span>
                          </div>
                          <p className="mt-2 pe-3">
                            {
                              <div
                                className="dashboard_description"
                                dangerouslySetInnerHTML={{
                                  __html: note.note,
                                }}
                              ></div>
                            }
                          </p>
                        </div>
                        {note.user_info.action_id == get_user && (
                          <CustomPopover menu={MENU_OPTIONS} data={note} />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <div className="mt-5">
                <RecordNotFound title="Notes" />
              </div>
            )}
          </div>
        )}
      </div>

      <div ref={ref} className={`row text-area-container mt-3`}>
        <div className="col-12">
          <form onSubmit={showButtons.addButton ? handleSubmit : handleUpdate}>
            <span
              htmlFor="exampleFormControlInput1"
              className="form-label-lesson"
            >
              Notes:
            </span>
            <GeneralCkeditor
              setInputs={setInputLessonNote}
              inputs={inputLessonNote}
              name="notes"
              editorHeight={320}
            />
            {/* <div className="col-12 mb-3 px-3 mt-2">
              <Divider className="mb-2" />
              {template.map((message) => {
                return (
                  <Chip
                    label={message.label}
                    className="me-1 mb-1"
                    onClick={() => handleMessage(message.message)}
                  />
                );
              })}

              <Divider className="mt-2" />
            </div> */}
            {/* <textarea
                required
                rows="5"
                id="notes-input"
                className="form-control text-area-task mt-2"
                value={inputLessonNote}
                onChange={handleNoteChange}
              /> */}
            {showButtons.addButton === true && (
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="mt-2 float-end small-contained-button"
                  disabled={isLoading}
                >
                  {isLoading ? "Saving..." : "Save"}
                </button>
              </div>
            )}
            {showButtons.editButton === true && (
              <div className="d-flex justify-content-end">
                <button
                  className="mt-2 me-2 small-contained-button"
                  onClick={handleCancelUpdate}
                >
                  Cancel
                </button>
                <button
                  className="mt-2 small-contained-button"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              </div>
            )}{" "}
          </form>
        </div>
      </div>
    </>
  );
}
