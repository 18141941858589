import React, { useEffect, useState } from "react";
import {
  FormControl,
  Button,
  Box,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { departmentListingApi } from "src/DAL/Department/Department";
import { makeStyles } from "@mui/styles";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SupportTicketFilterDepartment({
  handleChange,
  inputs,
  searchSubmitFilter,
  handleClearFilter,
  setInputs,
}) {
  //Adding Department
  const [department, setDepartment] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();

  const getDepartmentList = async () => {
    const result = await departmentListingApi();
    if (result.code === 200) {
      setDepartment(
        result.department.map((item) => {
          return {
            ...item,
            chip_label: item.title,
            chip_value: item._id,
          };
        })
      );
      setIsLoading(false);
    }
  };

  const handleChangeDepartment = (value) => {
    setInputs((values) => ({ ...values, department: value }));
  };

  useEffect(() => {
    getDepartmentList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid new-memories">
      <div className="col-12 mt-4">
        <MUIAutocomplete
          inputLabel="Department"
          selectedOption={inputs?.department}
          setSelectedOption={handleChangeDepartment}
          optionsList={department}
          required
          name="title"
        />
      </div>
      <div className="col-12 mt-4">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Ticket Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="filter_by"
            value={inputs.filter_by}
            label="Ticket Type"
            onChange={handleChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="waiting">Waiting</MenuItem>
            <MenuItem value="answered">Answered</MenuItem>
            <MenuItem value="solved">Solved</MenuItem>
            <MenuItem value="trash">Trash</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Box sx={{ py: 2 }}>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={searchSubmitFilter}
          startIcon={
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 5 }}
            >
              <path
                d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                fill={get_root_value("--portal-theme-primary")}
              />
            </svg>
          }
        >
          Filter
        </Button>
      </Box>
      <Button
        fullWidth
        size="large"
        type="submit"
        color="inherit"
        variant="outlined"
        onClick={handleClearFilter}
        startIcon={<Icon icon={roundClearAll} />}
      >
        Clear All
      </Button>
    </div>
  );
}
