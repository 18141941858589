import { invokeApi } from "src/bl_libs/invokeApi";
export const lesson_recording_list_api = async (slug) => {
  const requestObj = {
    path: `/api/lesson_recording/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const lesson_recording_detail_api = async (slug) => {
  const requestObj = {
    path: `/api/lesson_recording/recording_detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_lesson_recording_api = async (data) => {
  const requestObj = {
    path: `/api/lesson_recording/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_lesson_recording_api = async (data, slug) => {
  const requestObj = {
    path: `/api/lesson_recording/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_lesson_recording_api = async (slug) => {
  const requestObj = {
    path: `/api/lesson_recording/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_lesson_recording_audio = async (data, slug) => {
  const requestObj = {
    path: `/api/lesson_recording/delete_recording_date/${slug}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
