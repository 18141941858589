import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import { useState } from "react";
import { affirmation_detail_api } from "src/DAL/affirmation/affirmationApi";
import { htmlDecode } from "src/utils/convertHtml";
import { delete_affirmation_api } from "src/DAL/affirmation/affirmationApi";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function AffirmationDetail() {
  const { affirmation_id } = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [affirmationInfo, setAffirmationInfo] = useState({});
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const handleNavigateEdit = () => {
    navigate(`/affirmation/edit-affirmation/${affirmationInfo._id}`, {
      state: affirmationInfo,
    });
  };

  const handleDelete = async () => {
    handleCloseDelete();
    setIsLoading(true);
    let result = await delete_affirmation_api(affirmation_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleNavigateBack();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDetail = async () => {
    setIsLoading(true);
    let result = await affirmation_detail_api(affirmation_id);
    if (result.code === 200) {
      setAffirmationInfo(result.general_affirmation);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateBack = () => {
    navigate(`/affirmation`);
  };

  let MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
  ];

  let breadCrumbMenu = [
    {
      title: "Affirmation",
      navigation: `/affirmation`,
      active: false,
    },
    {
      title: state?.description,
      active: true,
    },
  ];

  useEffect(() => {
    if (state) {
      setAffirmationInfo(state);
    } else {
      handleDetail();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this affirmation?"}
        handleAgree={handleDelete}
      />
      <div className="row mobile-margin display-flex mt-3">
        <div className="col-12  d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-2"
              onClick={() => navigate(`/affirmation`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <div className="w-100 d-flex justify-content-between">
            <h2>Affirmation Detail</h2>
            <CustomPopoverSection menu={MENU_OPTIONS} data={affirmationInfo} />
          </div>
        </div>
      </div>

      <div className="row media-margin">
        <div className="col-12">
          <img
            src={
              affirmationInfo?.affirmation_image
                ? s3baseUrl + affirmationInfo.affirmation_image.thumbnail_1
                : ""
            }
            className="w-100"
          />
        </div>
        <div className="col-12 section-space">
          <p>{htmlDecode(affirmationInfo?.description)}</p>
        </div>
      </div>
    </div>
  );
}

export default AffirmationDetail;
