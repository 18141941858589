import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton, List } from "@mui/material";
import { MHidden } from "../../components/@material-extend";
import AccountPopover from "./AccountPopover";
import { usePGIMode } from "../../Hooks/PGIModeContext";
import { useEffect, useRef, useState } from "react";
import NavSectionItem from "src/components/NavSectionItem";
import sidebarConfig from "./SidebarConfig";
import { useLocation } from "react-router-dom";

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 42;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP + 20,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({
  onOpenSidebar,
  drawerWidth,
  handlePermanentDrawer,
}) {
  const { mode, handleChangeMode, Navlist } = usePGIMode();
  const [searchInput, setSearchInput] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const { pathname } = useLocation();
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  let nav_items = sidebarConfig(Navlist);

  const getNavItemsList = (array, query) => {
    if (query) {
      const _nav_list = array
        .map((data) => {
          const is_main =
            data.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
          const match_child = data.child_options?.filter(
            (item) =>
              item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
          );

          if (is_main) {
            return data;
          } else if (match_child?.length > 0) {
            return {
              ...data,
              child_options: match_child,
            };
          }
          return null;
        })
        .filter(Boolean);
      return _nav_list;
    }
    return array;
  };

  useEffect(() => {
    const handleClickInside = (event) => {
      if (
        inputRef.current?.contains(event.target) ||
        dropdownRef.current?.contains(event.target)
      ) {
        return;
      }
      setShowDropdown(false);
    };

    document.addEventListener("click", handleClickInside);
    return () => {
      document.removeEventListener("click", handleClickInside);
    };
  }, [showDropdown]);

  const handleClose = () => {
    setSearchInput("");
  };

  useEffect(() => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  }, [pathname]);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
      className="app-header"
    >
      {/* <ToolbarStyle> */}
      {/* <IconButton
        onClick={onOpenSidebar}
        sx={{ mr: 1, color: "text.primary", marginRight: "auto" }}
      >
        <Icon icon={menu2Fill} />
      </IconButton> */}

      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={onOpenSidebar}
        sx={{ mr: 2, display: { xs: "block", sm: "none" } }}
      >
        <Icon icon={menu2Fill} />
      </IconButton>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handlePermanentDrawer}
        sx={{ mr: 2, display: { xs: "none", sm: "block" } }}
      >
        <Icon icon={menu2Fill} />
      </IconButton>
      {drawerWidth == 0 && (
        <div className="my-1 p-2 mt-2 d-none d-md-block">
          <div className="sidebar-search-box" ref={inputRef}>
            <Icon
              fontSize={20}
              className="appbar-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="appbar-search-input"
              type="text"
              placeholder="Search Menu"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onFocus={() => setShowDropdown(true)}
            />
            {searchInput.length > 0 && (
              <Icon
                fontSize={20}
                className="appbar-close-icon"
                icon={closeFill}
                onClick={handleClose}
              />
            )}
          </div>
        </div>
      )}
      {showDropdown && (
        <div className="search_popover" ref={dropdownRef}>
          <List disablePadding>
            {getNavItemsList(nav_items, searchInput).length > 0 ? (
              <>
                {getNavItemsList(nav_items, searchInput).map((item, i) => {
                  return (
                    <NavSectionItem
                      key={i}
                      data={item}
                      searchInput={searchInput}
                    />
                  );
                })}
              </>
            ) : (
              <div className="text-center p-2">Data Not Found</div>
            )}
          </List>
        </div>
      )}
      <Box sx={{ flexGrow: 1 }} />
      <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
        <AccountPopover />
      </Stack>
      {/* </ToolbarStyle> */}
    </AppBar>
  );
}
