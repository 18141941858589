import { filter } from "lodash";

import React, { useEffect, useState } from "react";

// material
import { CircularProgress } from "@mui/material";
// components

import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
// import { get_commission_detail_api } from "src/DAL/commissionDetail/commissionDetail";

import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { convertCurrencyToSign, dd_date_format } from "src/utils/constant";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  //console.log(array, "arrays of data");
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    //console.log(query, "query ");
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const USERLIST = [
  {
    id: 1,
    name: "Jhon",
    email: "jhon@gmail.com",
    date: "12-11-2022",
    time: "12:30 AM",
    credit: "$100",
    remaining: "$107",
    description: "lorem ipsum",
  },
  {
    id: 2,
    name: "Enola",
    email: "enola@gmail.com",
    date: "12-11-2022",
    time: "12:30 AM",
    credit: "$150",
    remaining: "$180",
    description: "lorem ipsum",
  },
  {
    id: 3,
    name: "Jhon",
    email: "jhon@gmail.com",
    date: "12-11-2022",
    time: "12:30 AM",
    credit: "$100",
    remaining: "$107",
    description: "lorem ipsum",
  },
  {
    id: 4,
    name: "Enola",
    email: "enola@gmail.com",
    date: "12-11-2022",
    time: "12:30 AM",
    credit: "$150",
    remaining: "$180",
    description: "lorem ipsum",
  },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AffiliateTransactions({ type, tabData }) {
  const [userList, setUserList] = useState([]);

  const get_badges_list = async () => {
    let tab_data = [];
    if (type == "credit") {
      if (tabData.length > 0) {
        tab_data = tabData.map((data) => {
          let transaction_type = data.transaction_type;
          let product = "N/A";
          if (transaction_type == "payment_request") {
            if (data.payment_request_info && data.payment_request_info._id) {
              product =
                "Payment Request" +
                " " +
                "(" +
                data.payment_request_info.request_title +
                " " +
                "|" +
                " " +
                data.payment_request_info.request_type +
                ")";
            }
          } else if (transaction_type == "payment_plan") {
            if (data.sale_page_info && data.sale_page_info._id) {
              product =
                "Sale Page" +
                " " +
                "|" +
                " " +
                data.sale_page_info.sale_page_title +
                " (" +
                data.plan_info.plan_title +
                " " +
                "|" +
                " " +
                data.plan_info.payment_access +
                ")";
            }
          }
          return {
            user_name:
              data.member_info.first_name +
              " " +
              data.member_info.last_name +
              "(" +
              data.member_info.email +
              ")",
            product,
            transaction_date: data.transaction_date
              ? dd_date_format(data.transaction_date)
              : "N/A",
            ...data,
          };
        });
      }
    } else {
      if (tabData.length > 0) {
        tab_data = tabData.map((data) => {
          return {
            transaction_date: data.transaction_date
              ? dd_date_format(data.transaction_date)
              : "N/A",
            ...data,
          };
        });
      }
    }
    console.log(tab_data, "tab_datatab_data");
    setUserList(tab_data);
  };
  useEffect(() => {
    get_badges_list();
  }, [tabData]);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "user_name", label: "Name" },
    {
      id: "amount",
      label: "Amount",
      renderData: (row) => {
        return (
          <>{convertCurrencyToSign(row?.currency) + row?.amount.toFixed(2)}</>
        );
      },
    },
    { id: "product", label: "Product" },
    {
      id: "transaction_date",
      label: "Transaction Date",
      renderData: (row) => {
        return (
          <>
            {row.transaction_date
              ? dd_date_format(row.transaction_date)
              : "N/A"}
          </>
        );
      },
    },
    {
      id: "amount",
      label: "Commission Amount",
      renderData: (row) => {
        return (
          <>
            {type == "credit"
              ? convertCurrencyToSign(row?.currency) +
                row?.referral_commission.toFixed(2)
              : convertCurrencyToSign(row?.currency) + row?.amount.toFixed(2)}
          </>
        );
      },
    },
  ];
  const TABLE_HEAD1 = [
    { id: "number", label: "#", type: "number" },
    {
      id: "transaction_date",
      label: "Transaction Date",
      renderData: (row) => {
        return (
          <>
            {row.transaction_date
              ? dd_date_format(row.transaction_date)
              : "N/A"}
          </>
        );
      },
    },
    {
      id: "amount",
      label: "Paid",
      renderData: (row) => {
        return (
          <>
            {type == "credit"
              ? convertCurrencyToSign(row?.currency) +
                row?.referral_commission.toFixed(2)
              : convertCurrencyToSign(row?.currency) + row?.amount.toFixed(2)}
          </>
        );
      },
    },
  ];

  return (
    <>
      <CustomMUITable
        TABLE_HEAD={type == "credit" ? TABLE_HEAD : TABLE_HEAD1}
        data={userList}
        className="card-with-background"
        pagePagination={true}
      />
    </>
  );
}
