import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import {
  productCategoryDeleteApi,
  productCategoryListApi,
} from "src/DAL/ShopProductCategoryList/ProductCategory";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ShopCategoryList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getProductCategories = async () => {
    setIsLoading(true);
    const result = await productCategoryListApi();
    if (result.code == 200) {
      const ListData = result.category.map((category) => {
        let created_at = "N/A";
        if (category.createdAt) {
          created_at = moment(category.createdAt).format("DD-MM-YYYY");
        }
        return {
          ...category,
          table_avatar: {
            src: s3baseUrl + category.image.thumbnail_1,
            alt: category.title,
          },
          status: category.status,
          created_at,
        };
      });
      setCategoryList(ListData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate(`/shop-category/edit-category/${value?.product_category_slug}`, {
      state: value,
    });
  };

  const handleProductDetail = (value) => {
    navigate(`/shop-category/${value?.product_category_slug}/products`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await productCategoryDeleteApi(
      deleteDoc?.product_category_slug
    );

    if (result.code === 200) {
      getProductCategories();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/shop-category/add-category`);
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "table_avatar", label: "Image", type: "thumbnail" },
    {
      id: "title",
      label: "Title",
      className: "pointer",
      handleClick: handleProductDetail,
    },
    { id: "created_at", label: "Created At" },
    { id: "order", label: "Order" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Products",
      icon: "akar-icons:edit",
      handleClick: handleProductDetail,
    },
  ];

  useEffect(() => {
    getProductCategories();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-8 col-sm-12">
            <h2>Shop Category</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Shop Category
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoryList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
