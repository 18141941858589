import React, { useContext, useEffect, useState } from "react";

import io from "socket.io-client";
import { socketBaseUri } from "src/config/config";
import { _get_user_id_from_localStorage } from "./localStorage";
const CreateContentSetting = React.createContext();

let socket = {};
let user_id = _get_user_id_from_localStorage();
// socket-initialization
socket = io(socketBaseUri + `?user_id=${user_id}&role=admin`);
//----------------------
export const useContentSetting = () => useContext(CreateContentSetting);
export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const [isConnected, setIsConnected] = useState(socket.connected);

  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const START_SOCKET_FORCEFULLY = (user_id) => {
    socket = io(socketBaseUri + `?user_id=${user_id}&role=admin`);
  };

  const STOP_SOCKET_FORCEFULLY = (user_id) => {
    socket.disconnect();
  };

  useEffect(() => {
    if (!isConnected) {
      socket.emit("live_event_room", user_id);
      setIsConnected(true);
    }
    return () => {};
  }, [isConnected]);

  let data = {
    user_id: "61fca916dfb6667be3ca8834",
  };

  const collection = {
    // socket instant
    isConnected,
    socket,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,
  };

  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
