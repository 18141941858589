import { invokeApi } from "../../bl_libs/invokeApi";

export const dynamite_products_list_api = async (data) => {
  const requestObj = {
    path: `/api/dynamite_product/list/search?page=0&limit=50`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const calculate_custom_duty_api = async (data) => {
  const requestObj = {
    path: `/app/calculate_custom_duty/for_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_order_api = async (data) => {
  const requestObj = {
    path: `/admin_users/dynamite_product/purchase`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_order_api_mintsoft = async (data) => {
  const requestObj = {
    path: `/admin_users/dynamite_product_order/add`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
