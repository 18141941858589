import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { dd_date_format } from "src/utils/constant";

const TODAY_DATE = new Date();

export default function ProgramPopOver({
  handleClick,
  handleClose,
  anchorEl,
  id,
  open,
  program,
  navigatePage,
}) {
  const navigate = useNavigate();
  const handleChangeProgramDetail = (value) => {
    navigate(`/programmes/programmes-detail/${value._id.program_slug}`, {
      state: value._id,
    });
  };

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ heigth: 100 }}
      >
        <div className="sticky-top card px-2">
          <p className="heading_programs">Programmes</p>
          <span className="icon-container" onClick={() => handleClose()}>
            <CancelOutlinedIcon fontSize="small" />
          </span>
        </div>
        {program?.map((item, index) => {
          let is_active = false;
          let start_date = dd_date_format(item?.assign_date_time);
          let expiry_date = dd_date_format(item?.expiry_date);
          if (
            !item?.expiry_date ||
            (TODAY_DATE >= new Date(item?.assign_date_time) &&
              TODAY_DATE <= new Date(item?.expiry_date))
          ) {
            is_active = true;
          }
          return (
            <div
              key={index}
              className="d-flex align-items-center px-2"
              onClick={() => handleChangeProgramDetail(item)}
            >
              <Tooltip title="Currenty Active">
                <div
                  className={`program-dot ${is_active ? "active-program" : ""}`}
                ></div>
              </Tooltip>
              <Tooltip
                key={index}
                title={
                  item.expiry_date ? (
                    <div>
                      Start Date: {start_date}
                      <br />
                      Expiry: {expiry_date}
                      <br />
                      Status:{" "}
                      {item._id?.status === true ? "Active" : "InActive"}
                    </div>
                  ) : (
                    <div>
                      Start Date: {start_date}
                      <br />
                      No Expiry
                      <br />
                      Status:{" "}
                      {item._id?.status === true ? "Active" : "InActive"}
                    </div>
                  )
                }
                arrow
              >
                <p className="mb-1 program-hover">{item._id?.title}</p>
              </Tooltip>
            </div>
          );
        })}
      </Popover>
    </div>
  );
}
