import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import {
  add_stripe_reader,
  update_stripe_reader,
} from "src/DAL/StripeReader/StripeReader";

export default function AddOrUpdateStripe({
  formType,
  handleCloseDrawer,
  editData,
  getStripeReader,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    location_name: "",
    location_id: "",
    reader_id: "",
    reader_title: "",
    status: true,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      location_name: data.location_name,
      location_id: data.location_id,
      reader_id: data.reader_id,
      reader_title: data.reader_title,
      status: data.status,
    };

    const result =
      formType === "ADD"
        ? await add_stripe_reader(postData)
        : await update_stripe_reader(postData, editData._id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleCloseDrawer();
      getStripeReader();
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (formType == "EDIT") {
      setData(editData);
    }
  }, []);

  return (
    <div className="container-fluid new-memories">
      <form onSubmit={handleSubmit}>
        <TextField
          className="mt-4"
          id="outlined-basic"
          label="Location Name"
          variant="outlined"
          name="location_name"
          value={data.location_name}
          required={true}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          className="mt-3"
          id="outlined-basic"
          label="Location Id"
          variant="outlined"
          name="location_id"
          value={data.location_id}
          required={true}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          className="mt-3"
          id="outlined-basic"
          label="Reader Name"
          variant="outlined"
          name="reader_title"
          value={data.reader_title}
          required={true}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          className="mt-3"
          id="outlined-basic"
          label="Reader Id"
          variant="outlined"
          name="reader_id"
          value={data.reader_id}
          required={true}
          onChange={(e) => handleChange(e)}
        />
        <FormControl variant="outlined" className="mt-3">
          <InputLabel id="demo-simple-select-outlined-label">
            Status *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={data.status}
            onChange={(e) => handleChange(e)}
            label="Status"
            name="status"
            required={true}
            className="svg-color"
          >
            <MenuItem value="true">Active</MenuItem>
            <MenuItem value="false">Inactive</MenuItem>
          </Select>
        </FormControl>
        <div className="text-end mt-3">
          <button className="small-contained-button">
            {formType == "ADD" ? (
              <>{isLoading ? "Submitting..." : "Submit"}</>
            ) : (
              <>{isLoading ? "Updating..." : "Update"}</>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
