import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import CalendarDetailGroupList from "./GroupList";
import CalendarDetailMemberList from "./MemberList";
import { delegatePodsDetailApi } from "src/DAL/delegatesPods/delegatePods";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { useEffect } from "react";
import { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DelegatePodsDetail() {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [memberList, setMemberList] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleNavigateAddUser = () => {
    navigate(`/calendar-pods/add-room-user/${state.room_slug}`, {
      state: state,
    });
  };

  const getDetail = async () => {
    setIsLoading(true);
    const result = await delegatePodsDetailApi(state?.room_slug);
    setGroupList(result.room_groups);
    setMemberList(result?.room_members);
    setIsLoading(false);
  };

  useEffect(() => {
    getDetail();
  }, []);

  let breadCrumbMenu = [
    {
      title: "Delegate Pods",
      navigation: `/delegate-pods`,
      active: false,
    },
    {
      title: state?.title,
      active: true,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
          {state?.room_type == "automated" && (
            <button
              className="small-contained-button float-end mt-1"
              onClick={handleNavigateAddUser}
            >
              Add Room User
            </button>
          )}
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label=" Group List" {...a11yProps(0)} />
              <Tab label="Delegate List" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <CalendarDetailGroupList groupList={groupList} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CalendarDetailMemberList memberList={memberList} />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
