import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MemberList from "./MemberListing";
import GroupList from "./GroupListing";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import TotalMemberList from "./TotalMember";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import {
  eventDetailApi,
  event_detail_list_api_v1,
} from "src/DAL/Events/events";
import { useEffect } from "react";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import MemberProgramPopover from "../MembersList/memberProgramPopOver";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const event_type = ["group", "individual", "all"];

export default function DelegateEventDetailList() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const params = useParams();
  const [value, setValue] = useState(0);
  const [groups, setGroups] = useState([]);
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totoalMembers, setTotalMembers] = useState([]);
  const [questionStatement, setQuestionStatement] = useState("");
  const [program, setprogram] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchText, setSearchText] = useState("");
  const [isTabLoading, setIsTabLoading] = useState(false);

  const [anchorElMember, setAnchorElMember] = useState(null);
  const memberopen = Boolean(anchorElMember);
  const memberId = memberopen ? "simple-popover" : undefined;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    setPage(0);
    setPageCount(1);
    setSearchText("");
    setRowsPerPage(50);
    setTabValue(newValue);
  };

  const DetailEvent = async () => {
    setIsTabLoading(true);
    let tab_value = event_type[tabValue];
    const result = await event_detail_list_api_v1(
      params.event_slug,
      tab_value,
      page,
      rowsPerPage,
      searchText
    );
    if (result.code === 200) {
      setGroups(result.event_groups);
      const data = result.event_members.map((member) => {
        let full_name = "N/A";
        let user_email = "N/A";
        if (member.first_name) {
          full_name = member.first_name + " " + member.last_name;
        }
        if (member.email) {
          user_email = member.email;
        }
        return {
          ...member,
          full_name,
          user_email,
          table_avatar: {
            src: s3baseUrl + member?.image?.thumbnail_1,
            alt: member?.first_name,
          },
        };
      });
      setMembers(data);
      setQuestionStatement(result.event.title);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
      setIsTabLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsTabLoading(false);
    }
  };

  const handleClickMemebr = (event, row) => {
    setprogram(row.program);
    setAnchorElMember(event.currentTarget);
  };

  const handleCloseMember = () => {
    setAnchorElMember(null);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    DetailEvent(searchText);
  };

  let breadCrumbMenu = [
    {
      title: "Delegate Events",
      navigation: `/delegate-events`,
      active: false,
    },
    {
      title: "Events List",
      navigation: `/delegate-events/event-list`,
      active: false,
    },
    {
      title: questionStatement,
      active: true,
    },
  ];

  const other_values = {
    className: "card-with-background",
    custom_pagination: {
      total_count: totalCount,
      rows_per_page: rowsPerPage,
      page: page,
      handleChangePage: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
    custom_search: {
      searchText: searchText,
      setSearchText: setSearchText,
      handleSubmit: searchFunction,
    },
    pageCount: pageCount,
    totalPages: totalPages,
    handleChangePages: handleChangePages,
  };

  useEffect(() => {
    DetailEvent();
  }, [page, rowsPerPage, tabValue]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "table_avatar", label: "Profile", type: "thumbnail" },
    { id: "full_name", label: "Name" },
    { id: "user_email", label: "Email" },
  ];

  const TABLE_HEAD_MEMBER = [
    { id: "number", label: "#", type: "number" },
    { id: "table_avatar", label: "Profile", type: "thumbnail" },
    { id: "full_name", label: "Name" },
    { id: "user_email", label: "Email" },
    {
      id: "program",
      label: "Programmes",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        return (
          <div>
            {firstFiveprograms?.map((program, i) => {
              if (program._id !== null) {
                return (
                  <>
                    <div key={i}>
                      <p>
                        {program?._id?.title ? program?._id?.title + "," : ""}
                      </p>
                    </div>
                    {i == 4 && (
                      <p
                        className="mb-1 mt-1 view-more"
                        aria-describedby={memberId}
                        variant="contained"
                        onClick={(e) => handleClickMemebr(e, row)}
                      >
                        view more
                      </p>
                    )}
                  </>
                );
              }
            })}
          </div>
        );
      },
    },
  ];

  const TABS_OPTIONS = [
    {
      title: "Group List",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <GroupList groups={groups} />
      ),
    },
    {
      title: "Delegate List",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD_MEMBER}
          data={members}
          pagePagination={true}
          {...other_values}
        />
      ),
    },
    {
      title: "Total Delegate",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={members}
          pagePagination={true}
          {...other_values}
        />
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-lg-8 mb-4">
          <h2>Delegate Event Detail</h2>
        </div>
      </div>
      <MUICustomTabs
        data={TABS_OPTIONS}
        value={tabValue}
        handleChange={handleChange}
      />
      <MemberProgramPopover
        handleClick={handleClickMemebr}
        handleClose={handleCloseMember}
        anchorElMember={anchorElMember}
        id={memberId}
        open={memberopen}
        program={program}
        navigatePage={true}
      />
    </div>
  );
}
