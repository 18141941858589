import { invokeApi } from "../../bl_libs/invokeApi";

export const MissionControlGraphicList = async (page, limit, search) => {
  const requestObj = {
    path: `/api/graphic_url/graphic_url_list?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const productCategoryDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/product_category/product_category_detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addGraphic = async (data) => {
  const requestObj = {
    path: `/api/graphic_url/add_graphic_url`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editGraphic = async (data, slug) => {
  const requestObj = {
    path: `/api/graphic_url/update_graphic_url/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const graphicDeleteApi = async (id) => {
  const requestObj = {
    path: `/api/graphic_url/delete_graphic_url/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const affirmationCategoryAddApi = async (data) => {
  const requestObj = {
    path: `/api/general_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const affirmationCategoryEditApi = async (data, id) => {
  const requestObj = {
    path: `/api/general_category/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
