import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { afflicateChangeid, member_login_api } from "src/DAL/member/member";
import { delegate_Url, s3baseUrl } from "src/config/config";
import {
  consultant_listing_support_team_with_pagination,
  consultant_listing_with_filter,
  consultant_listing_with_pagination,
  DeleteConsultantApi,
} from "src/DAL/consultant/consultant";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import AffliateConfirmationPopUp from "src/components/ModalPopover/AffliateConfirmationPopUp";
import { CSVLink } from "react-csv";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import roundFilterList from "@iconify/icons-ic/round-filter-list";

import moment from "moment";
import { get_root_value } from "src/utils/domUtils";
import DelegateFilterDateBase from "../consultant/ConsultantFilterDateBase";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

let member_options = [
  {
    label: "First name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Last name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true, is_disabled: true },
  {
    label: "Contact Number",
    value: "contact_number",
    is_checked: true,
    is_disabled: true,
  },
];

const headers = [
  { label: "Name", alignRight: false, key: "first_name" },
  { label: "Email", alignRight: false, key: "email" },

  // { label: "Total Commission", alignRight: false, key: "total_commission" },
  // {
  //   label: "Commission Paid",
  //   alignRight: false,
  //   key: "commission_paid",
  // },
  // {
  //   label: "Commission Due",
  //   alignRight: false,
  //   key: "commission_due",
  // },
  // {
  //   label: "Total Leads",
  //   alignRight: false,
  //   key: "total_leads",
  // },
  { label: "Type", alignRight: false, key: "type" },
  // { label: "Referral User", alignRight: false, key: "refUser" },
  // { label: "Request Title", alignRight: false, key: "payment_request" },
  // { label: "Transaction Type", alignRight: false, key: "transaction_type" },
];
export default function SupportTeam() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [inputs, setInputs] = useState({
    name: "",
    affiliate_url_name: "",
  });
  const [csvData, setCsvData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(500);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openAffliatePopup, setopenAffliatePopup] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [affliateValue, setaffliateValue] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [filterFor, setFilterFor] = useState("both");
  const [isFilter, setIsFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [programsList, setProgramsList] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [selectedFilterPrograms, setSelectedFilterPrograms] = useState([]);
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const submit = (e) => {
    e.preventDefault();
  };

  const handleChangeAffiliate = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getConsultantListing = async (filter) => {
    setIsLoading(true);
    let search_keyword =
      localStorage.getItem("consultant_search_text_team") == null
        ? searchText
        : localStorage.getItem("consultant_search_text_team");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    let postData = {};

    const result = await consultant_listing_support_team_with_pagination(
      page,
      rowsPerPage,
      search_keyword
    );
    if (result.code === 200) {
      setTotalPages(result.total_pages);
      const data = result.consultant_list.map((consultant) => {
        console.log(consultant.department_head, "department_head");
        return {
          ...consultant,
          name: consultant.first_name + " " + consultant.last_name,
          table_avatar: {
            src: s3baseUrl + consultant.image.thumbnail_1,
            alt: consultant.first_name,
          },
          member: "View Members",
          subscriber: "View Subscriber",
          MENU_OPTIONS: handleMenu(consultant),
          id: consultant._id,
          lastName: consultant.last_name,
          status: consultant.status,
          profile: consultant.image.thumbnail_1,
          type: consultant.team_type,
          total_leads: consultant.total_leads,
          memberCount: consultant.member_count,
          HeadOfDePartment: consultant.department_head,
          teamOfDePartment: consultant.department_member,
          selected_address: consultant.address,
          selected_city: consultant.city,
          selected_contact_number: consultant.contact_number,
          main_heading: consultant.main_heading,
          selected_state: consultant.state,
          total_leads: consultant.total_leads,
          selected_team_type: consultant.team_type,
          selected_time_zone: consultant.time_zone,
          contactNumber: consultant.contact_number,
          refUser: consultant.affiliate_url_name,
          consultant_object: consultant,
          total_commission: "£" + consultant.total_commission?.toFixed(2),
          commission_due: "£" + consultant.commission_due?.toFixed(2),
          commission_paid: "£" + consultant.commission_paid?.toFixed(2),
        };
      });
      setFilterQuery(result.query);
      setUserList(data);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getConsultantListingFilter = async (filter, type) => {
    setIsLoading(true);
    let search_keyword =
      localStorage.getItem("consultant_search_text_team") == null
        ? searchText
        : localStorage.getItem("consultant_search_text_team");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }

    let postData = {};
    if (filter == true) {
      let a = [];
      selectedPrograms.map((val) => a.push(val._id));

      setIsFilter(true);
      postData = {
        start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        role: type == "notype" ? "" : filterFor,
        program: selectedPrograms,
      };
    } else {
      setStartDate(null);
      setEndDate(null);
      setPage(0);
      setPageCount(1);
      setIsFilter(false);
      setFilterFor("both");
      setSelectedPrograms([]);
    }

    const result = await consultant_listing_support_team_with_pagination(
      page,
      rowsPerPage,
      search_keyword,
      postData
    );
    if (result.code === 200) {
      setTotalPages(result.total_pages);
      const data = result.consultant_list.map((consultant) => {
        return {
          ...consultant,
          name: consultant.first_name + " " + consultant.last_name,
          table_avatar: {
            src: s3baseUrl + consultant.image.thumbnail_1,
            alt: consultant.first_name,
          },
          member: "View Members",
          subscriber: "View Subscriber",
          MENU_OPTIONS: handleMenu(consultant),
          id: consultant._id,
          lastName: consultant.last_name,
          status: consultant.status,
          profile: consultant.image.thumbnail_1,
          type: consultant.team_type,
          total_leads: consultant.total_leads,
          memberCount: consultant.member_count,
          HeadOfDePartment: consultant.department_head,
          teamOfDePartment: consultant.department_member,
          selected_address: consultant.address,
          selected_city: consultant.city,
          selected_contact_number: consultant.contact_number,
          main_heading: consultant.main_heading,
          selected_state: consultant.state,
          total_leads: consultant.total_leads,
          selected_team_type: consultant.team_type,
          selected_time_zone: consultant.time_zone,
          contactNumber: consultant.contact_number,
          refUser: consultant.affiliate_url_name,
          consultant_object: consultant,
          total_commission: "£" + consultant.total_commission,
          commission_due: "£" + consultant.commission_due,
          commission_paid: "£" + consultant.commission_paid,
        };
      });

      setUserList(data);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getConsultantListingSearch = async () => {
    setIsLoading(true);
    let search_keyword =
      localStorage.getItem("consultant_search_text_team") == null
        ? searchText
        : localStorage.getItem("consultant_search_text_team");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    let postData = {};
    if (isFilter == true) {
      postData = {
        start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        role: filterFor,
        program: selectedPrograms,
      };
    }
    const result = await consultant_listing_support_team_with_pagination(
      0,
      rowsPerPage,
      search_keyword,
      postData
    );
    if (result.code === 200) {
      setTotalPages(result.total_pages);
      const data = result.consultant_list.map((consultant) => {
        return {
          ...consultant,
          name: consultant.first_name + " " + consultant.last_name,
          table_avatar: {
            src: s3baseUrl + consultant.image.thumbnail_1,
            alt: consultant.first_name,
          },
          member: "View Members",
          subscriber: "View Subscriber",
          MENU_OPTIONS: handleMenu(consultant),
          id: consultant._id,
          lastName: consultant.last_name,
          status: consultant.status,
          profile: consultant.image.thumbnail_1,
          type: consultant.team_type,
          total_leads: consultant.total_leads,
          memberCount: consultant.member_count,
          selected_address: consultant.address,
          selected_city: consultant.city,
          selected_contact_number: consultant.contact_number,
          main_heading: consultant.main_heading,
          selected_state: consultant.state,
          HeadOfDePartment: consultant.department_head,
          teamOfDePartment: consultant.department_member,
          selected_team_type: consultant.team_type,
          selected_time_zone: consultant.time_zone,
          contactNumber: consultant.contact_number,
          refUser: consultant.affiliate_url_name,
          consultant_object: consultant,
          total_commission: "£" + consultant.total_commission,
          commission_due: "£" + consultant.commission_due,
          commission_paid: "£" + consultant.commission_paid,
          rank: consultant.rank,
        };
      });

      setUserList(data);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getConsultantListingSearch();
  };

  const handleMembersList = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/members-list/${value._id}`);
  };
  const handleSubscriber = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/subscriber/${value._id}`);
  };

  const handleEdit = (value) => {
    delete value.MENU_OPTIONS;
    value.from = "supportTeam";
    navigate(`/support-team/edit-support-team/${value._id}`, {
      state: value,
    });
  };
  const handleChangePassword = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/support-team/change-password/${value._id}`, {
      state: value,
    });
  };
  const handleDelegates90DaysQuestions = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/delegate-90-days-questions/${value._id}`, {
      state: value,
    });
  };

  const handleToDoTracker = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/to-do-tracker/${value._id}`, {
      state: value,
    });
  };
  const handleChangeGroup = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/groups/${value._id}`, {
      state: value,
    });
  };
  const handleChangeEvents = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/events/${value._id}`, {
      state: value,
    });
  };
  const handleChangePods = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/pods-list/${value._id}`, {
      state: value,
    });
  };
  const handleChangeMember = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/member-list/${value._id}`, {
      state: value,
    });
  };
  const handleChangeGoalStatement = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/goal-statement-list/${value._id}`, {
      state: value,
    });
  };
  const ManageTemplateAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/manage-template-access/${value._id}`, {
      state: value,
    });
  };
  const ManageCommissionAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/manage-commission-access/${value._id}`, {
      state: value,
    });
  };
  const ManageAssessmentQuestion = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/assessment-study/${value._id}`, {
      state: value,
    });
  };

  const LoginAsDelegate = async (value) => {
    setIsLoading(true);
    const data = {
      email: value.email,
      user_type: "consultant",
    };
    const result = await member_login_api(data);
    if (result.code === 200) {
      window.open(`${delegate_Url}login-by-admin/${result.token}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handlechangeAffiliate = async () => {
    setopenAffliatePopup(false);
    setIsLoading(true);
    const postData = {
      affiliate_url_name: inputs.affiliate_url_name,
      // status: inputs.status,
      user_type: "consultant",
    };
    const handlechangeAffiliateID = (value) => {
      setDeleteDoc(value);
      setaffliateValue(value);
      setInputs((inputs) => ({
        ...inputs,
        ["affiliate_url_name"]: value?.affiliate_url_name
          ? value?.affiliate_url_name
          : "",
        ["name"]: value?.first_name + " " + value?.last_name,
      }));
      setopenAffliatePopup(true);
    };
    const result = await afflicateChangeid(postData, deleteDoc.id);
    if (result.code === 200) {
      // getMemberListing();
      setIsLoading(false);
      getConsultantListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setopenAffliatePopup(true);
    }
  };
  const handlechangeAffiliateID = (value) => {
    setDeleteDoc(value);
    setaffliateValue(value);
    setInputs((inputs) => ({
      ...inputs,
      ["affiliate_url_name"]: value?.affiliate_url_name
        ? value?.affiliate_url_name
        : "",
      ["name"]: value?.first_name + " " + value?.last_name,
    }));
    setopenAffliatePopup(true);
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteConsultantApi(deleteDoc.id);
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleClickChip = () => {
    console.info("You clicked the Chip.");
  };

  const handleDeleteChip = () => {
    getConsultantListingFilter(false);
  };
  const handleDeleteChipFilterFor = () => {
    setFilterFor("");
    getConsultantListingFilter(true, "notype");
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleNavigate = () => {
    navigate(`/support-team/add-support-team`, {
      state: "supportTeam",
    });
  };
  const handlePayNow = (value) => {
    // console.log(value, "value");
    navigate(`/affiliate-transactions/add-transaction/${value._id}`);
  };

  const handleManageProgramAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/programme-access/${value._id}`, {
      state: value,
    });
  };
  const handleManageEventAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/event-access/${value._id}`, {
      state: value,
    });
  };

  const handleCopyReferralId = async (e) => {
    await navigator.clipboard.writeText(e.affiliate_url_name);
    enqueueSnackbar("Referral Id Copied to clipboard", { variant: "success" });
  };

  const handleQuestionsAnswers = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/consultant/questions-answers?member_id=${value.id}`, {
      state: value,
    });
  };

  const ManageNavItemsAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/support-team/manage-navitems-access/${value._id}`, {
      state: value,
    });
  };

  const handleMenu = (row) => {
    let MENU_OPTIONS = [
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      },
      {
        label: "Login As Delegate",
        icon: "ant-design:lock",
        handleClick: LoginAsDelegate,
      },
      // {
      //   label: "Questions Answers",
      //   icon: "ant-design:lock",
      //   handleClick: handleQuestionsAnswers,
      // },
      {
        label: "Manage Nav Items Access",
        icon: "fluent:video-recording-20-regular",
        handleClick: ManageNavItemsAccess,
      },
      {
        label: "Change Password",
        icon: "ant-design:lock",
        handleClick: handleChangePassword,
      },
      // {
      //   label: "Change Affiliate Id",
      //   icon: "ant-design:lock",
      //   handleClick: handlechangeAffiliateID,
      // },
      // {
      //   label: "Pay Now",
      //   icon: "ant-design:dollar-circle-outlined",
      //   handleClick: handlePayNow,
      // },
      // {
      //   label: "Delegates 90 Days Questions",
      //   icon: "akar-icons:edit",
      //   handleClick: handleDelegates90DaysQuestions,
      // },
      // {
      //   label: "Delegates Assessment/Study",
      //   icon: "akar-icons:edit",
      //   handleClick: ManageAssessmentQuestion,
      // },
      // {
      //   label: "To Do Tracker",
      //   icon: "akar-icons:edit",
      //   handleClick: handleToDoTracker,
      // },
      // {
      //   label: "Manage Program Access",
      //   icon: "akar-icons:edit",
      //   handleClick: handleManageProgramAccess,
      // },
      // {
      //   label: "Manage Event Access",
      //   icon: "akar-icons:edit",
      //   handleClick: handleManageEventAccess,
      // },
    ];
    // if (row.team_type !== "team") {
    //   MENU_OPTIONS.push(
    //     {
    //       label: "View Groups",
    //       icon: "fluent:video-recording-20-regular",
    //       handleClick: handleChangeGroup,
    //     },
    //     {
    //       label: "View Pods",
    //       icon: "fluent:video-recording-20-regular",
    //       handleClick: handleChangePods,
    //     },
    //     {
    //       label: "View Members",
    //       icon: "fluent:video-recording-20-regular",
    //       handleClick: handleChangeMember,
    //     },
    //     {
    //       label: "View Events",
    //       icon: "fluent:video-recording-20-regular",
    //       handleClick: handleChangeEvents,
    //     },
    //     {
    //       label: "View Goal Statement",
    //       icon: "fluent:video-recording-20-regular",
    //       handleClick: handleChangeGoalStatement,
    //     },
    //     {
    //       label: "Manage Template Access",
    //       icon: "fluent:video-recording-20-regular",
    //       handleClick: ManageTemplateAccess,
    //     }
    //   );
    // }

    MENU_OPTIONS.push(
      // {
      //   label: "Manage Commission Access",
      //   icon: "fluent:video-recording-20-regular",
      //   handleClick: ManageCommissionAccess,
      // },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      }
    );

    // if (row.affiliate_url_name) {
    //   if (row.affiliate_url_name) {
    //     MENU_OPTIONS.push({
    //       label: "Copy Referral Id",
    //       icon: "eva:eye-fill",
    //       handleClick: handleCopyReferralId,
    //     });
    //   }
    // }

    return MENU_OPTIONS;
  };
  // console.log(selectedPrograms, "selectedPrograms");
  useEffect(() => {
    const csvExportData = userList?.map((member) => {
      return {
        ...member,
        first_name: member.name,
        email: member.email,
        total_commission: member.total_commission,
        commission_paid: member.commission_paid,
        commission_due: member.commission_due,
        total_leads: member.total_leads,
        type: member.team_type,
      };
    });

    setCsvData(csvExportData);
  }, [userList]);
  useEffect(() => {
    getConsultantListing();
  }, [rowsPerPage, page]);
  // useEffect(() => {
  //   if ((startDate || endDate == null) && filterFor == "") {
  //     console.log("ooko ");
  //     setIsFilter(false);
  //   }
  // }, [startDate, endDate, filterFor]);
  useEffect(() => {
    localStorage.setItem("consultant_search_text_team", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("consultant_search_text_team", "");
    } else {
      localStorage.setItem("consultant_search_text_team", searchText);
    }
  }, [searchText]);
  // console.log(selectedFilterPrograms, "selectedFilterPrograms");

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      alignRight: false,
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Profile",
      alignRight: false,
      type: "thumbnail",
    },
    { id: "name", label: " Name", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
    // { id: "total_commission", label: "Total Commission", alignRight: false },
    // { id: "commission_paid", label: "Paid Commission", alignRight: false },
    // { id: "commission_due", label: "Due Commission", alignRight: false },
    // { id: "total_leads", label: "Total Leads", alignRight: false },
    // { id: "address", label: "Address", alignRight: false },
    {
      id: "team_type",
      label: "Type",
      alignRight: false,
      className: "text-capitalize",
    },
    {
      id: "HeadOfDePartment",
      label: "Head Of Department",
      alignRight: false,
      renderData: (row) => {
        return (
          <div>
            {row.department_head.length > 0
              ? row.department_head?.map((group) => {
                  return <p className="mb-0">{group.title} </p>;
                })
              : "N/A"}
          </div>
        );
      },
    },
    {
      id: "teamOfDePartment",
      label: "Team Member Of Department",
      alignRight: false,
      renderData: (row) => {
        return (
          <div>
            {row.department_member.length > 0
              ? row.department_member?.map((group) => {
                  return <p className="mb-0">{group.title} </p>;
                })
              : "N/A"}
          </div>
        );
      },
    },
    // {
    //   id: "member",
    //   label: "Member",
    //   alignRight: false,
    //   className: "view-delegates-members",
    //   handleClick: handleMembersList,
    // },
    // {
    //   id: "subscriber",
    //   label: "View Subscribers",
    //   alignRight: false,
    //   className: "view-delegates-members",
    //   handleClick: handleSubscriber,
    // },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
  ];

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <AffliateConfirmationPopUp
        open={openAffliatePopup}
        setOpen={setopenAffliatePopup}
        value={affliateValue}
        title={"Are you sure you want to Affliate id?"}
        handleChange={handleChangeAffiliate}
        handleAgree={handlechangeAffiliate}
        inputs={inputs}
      />
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Support Team</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end">
            <button
              className="small-contained-button me-2"
              onClick={showPopUPcsv}
            >
              Request For Csv &nbsp;&nbsp;{" "}
              <Icon icon={arrowCircleDownFill} height={20} />
            </button>

            {/* <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button> */}
            <button onClick={handleNavigate} className="small-contained-button">
              Add Support Team
            </button>
          </div>
        </div>
        <div className="row mb-2">
          {isFilter && (
            <div className="col-lg-12 col-sm-12">
              <b className="me-3 pt-1 ms-2">Filtered By:</b>
              {!!startDate && (
                <Chip
                  label={
                    startDate
                      ? "Start Date: " +
                        moment(startDate ? startDate : new Date()).format(
                          "DD-MM-YYYY"
                        ) +
                        " " +
                        "End Date: " +
                        moment(endDate ? endDate : new Date()).format(
                          "DD-MM-YYYY"
                        )
                      : ""
                  }
                  onClick={handleClickChip}
                  onDelete={handleDeleteChip}
                  className="mb-2"
                  sx={{
                    color: "white",
                    // backgroundColor: get_root_value("--portal-theme-primary"),
                  }}
                />
              )}
              {selectedFilterPrograms.length > 0 &&
                selectedFilterPrograms.map((val) => {
                  return (
                    <Chip
                      label={val.title}
                      onClick={handleClickChip}
                      onDelete={handleDeleteChip}
                      className="mb-2"
                      sx={{
                        color: "white",
                        // backgroundColor: get_root_value("--portal-theme-primary"),
                      }}
                    />
                  );
                })}
              {!!filterFor && (
                <Chip
                  label={filterFor}
                  onClick={handleClickChip}
                  onDelete={handleDeleteChipFilterFor}
                  className="mb-2"
                  sx={{
                    color: "white",
                    // backgroundColor: get_root_value("--portal-theme-primary"),
                  }}
                />
              )}
              <span
                className="anchor-style ms-2 pt-1"
                onClick={() => getConsultantListingFilter(false)}
                style={{ color: get_root_value("--portal-theme-primary") }}
              >
                Clear All
              </span>
            </div>
          )}
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <DelegateFilterDateBase
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setFilterFor={setFilterFor}
            filterFor={filterFor}
            setEndDate={setEndDate}
            handleSubmit={getConsultantListingFilter}
            onCloseDrawer={handleCloseFilterDrawer}
            programsList={programsList}
            setProgramsList={setProgramsList}
            selectedPrograms={selectedPrograms}
            setSelectedPrograms={setSelectedPrograms}
            selectedFilterPrograms={selectedFilterPrograms}
            setSelectedFilterPrograms={setSelectedFilterPrograms}
          />
        }
      />
      <GeneralPopUpModel
        open={showExportcsvFile}
        setOpen={setShowExportcsvFile}
        title={"Export CSV file request  "}
        componentToPassDown={
          <GeneralRequestForCsv
            options_array={member_options}
            collection_name={"support_team"}
            filter_data={filterQuery}
            setShowExportcsvFile={setShowExportcsvFile}
          />
        }
      />
    </>
  );
}
