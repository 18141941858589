import { invokeApi } from "../../../bl_libs/invokeApi";

export const lesson_listing_api = async (slug) => {
  const requestObj = {
    path: `/api/lesson/lesson_list_by_program/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const module_lesson_listing_api = async (slug) => {
  const requestObj = {
    path: `/api/lesson/lesson_list_by_program_module/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_lesson_api = async (data) => {
  const requestObj = {
    path: `/api/lesson`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_lesson_api = async (data, slug) => {
  const requestObj = {
    path: `/api/lesson/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const lesson_detail_api = async (slug) => {
  const requestObj = {
    path: `/api/lesson/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_lesson_api = async (slug) => {
  const requestObj = {
    path: `/api/lesson/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_order_api = async (id) => {
  const requestObj = {
    path: `/app/delete_dynamite/order/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const change_order_status_order_api = async (id) => {
  const requestObj = {
    path: `/admin_users/delivered_order/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_lesson_data_api = async (slug) => {
  const requestObj = {
    path: `/api/lesson/delete_lesson_data/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_module_audio_api = async (slug) => {
  const requestObj = {
    path: `/api/program_module/delete_data/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const question_configuration = async (postData, slug) => {
  const requestObj = {
    path: `/api/lesson/question_configration/update/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const update_lesson_tab_configuration_api = async (data, slug) => {
  const requestObj = {
    path: `/api/lesson//update_lesson_tabs_configration/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
