import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { useEffect } from "react";
import {
  add_affiliate_transaction,
  affiliate_transaction_detail,
  get_user_list_for_affiliate_transaction,
  update_affiliate_transaction,
} from "src/DAL/AffiliateTransactions/AffiliateTransactions";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddDynamitePayment({ type }) {
  const navigate = useNavigate();
  const params = useParams();
  const { state, location } = useLocation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState("ADD");
  const [serchText, setSerchText] = useState("");
  const [paidAmount, setPaidAmount] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userList, setUserList] = useState([]);
  const [inputs, setInputs] = useState({
    amount: 0,
    payment_id: "",
    transaction_for:
      type == "Digital Payment" ? "digital-payment" : "dynamite-payment",
    transaction_note: "",
    currency: "gbp",
    method: "cash",
  });

  const getTransactionData = async () => {
    setIsLoading(true);
    let result = await affiliate_transaction_detail(params.transaction_id);
    if (result.code == 200) {
      setFormType("EDIT");
      setInputs(result.affiliatte_transaction);
      setPaidAmount(result.affiliatte_transaction.amount);
      setSelectedUser(result.user_info);
      setIsLoading(false);
    } else {
      navigate(`/affiliate-transactions`);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      user_id: selectedUser._id,
      amount: inputs.amount,
      transaction_note: inputs.transaction_note,
      currency: inputs.currency,
      transaction_for: inputs.transaction_for,
    };
    if (inputs.transaction_for == "delegates") {
      formData.method = inputs.method;
    }
    if (inputs.amount >= 1) {
      const result =
        formType === "ADD"
          ? await add_affiliate_transaction(formData)
          : await update_affiliate_transaction(formData, params.transaction_id);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        if (params.delegate_id) {
          navigate(-1);
        } else {
          navigate(`/affiliate-transactions`);
        }
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar("payable amount must be greater 0", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    if (name === "transaction_for") {
      setSelectedUser(null);
      setSerchText("");
    }
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getUserList = async () => {
    let id = serchText.length < 1 ? params?.delegate_id : "";
    const result = await get_user_list_for_affiliate_transaction(
      inputs.transaction_for,
      serchText,
      id
    );
    if (result.code === 200) {
      setUserList(result.users);
      if (serchText.length < 1) {
        if (params.delegate_id && inputs.transaction_for == "delegates") {
          let selected = result.users.find(
            (val) => val._id == params.delegate_id
          );
          setSelectedUser(selected);
          setIsLoading(false);
        }
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: `/consultant`,
      active: false,
    },
    {
      title: state
        ? state?.first_name + " " + state?.last_name + " (" + state?.email + ")"
        : formType === "ADD"
        ? "Add Affiliate Transaction"
        : "Edit Affiliate Transaction",
      active: true,
    },
  ];

  useEffect(() => {
    if (params.delegate_id) {
      setInputs((values) => ({
        ...values,
        ["transaction_for"]: "delegates",
      }));
    }
  }, [params]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() =>
              navigate(
                type == "Digital Payment"
                  ? "/dynamite-digital-payment"
                  : "/dynamite-payment"
              )
            }
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>{type}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Pay To *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="transaction_for"
                required
                disabled
                value={inputs.transaction_for}
                label="Pay To *"
                onChange={handleChange}
              >
                <MenuItem value="dynamite-payment">Dyanmite Payment</MenuItem>
                <MenuItem value="digital-payment">Digital Payment</MenuItem>
              </Select>
            </FormControl>
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Amount"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="amount"
              value={inputs.amount}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Currency*</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="currency"
                value={inputs.currency}
                label="currency*"
                onChange={handleChange}
              >
                <MenuItem value="gbp">UK Pounds</MenuItem>
                <MenuItem value="eur">Euro</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Payment Method *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="method"
                required
                value={inputs.method}
                label="Payment Method *"
                onChange={handleChange}
              >
                <MenuItem value="bank">Bank</MenuItem>
                <MenuItem value="cash">Cash</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.method == "bank" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Pay Id"
                variant="outlined"
                fullWidth
                required
                type="text"
                name="payment_id"
                value={inputs.payment_id}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Note"
                multiline
                rows={6}
                name="transaction_note"
                value={inputs.transaction_note}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
