import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  get_app_settings_api,
  update_app_settings_api,
} from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { s3baseUrl } from "src/config/config";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});
export default function MobileAppSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [pagesList, setPagesList] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [freePagesList, setFreePagesList] = useState([]);
  const [freePlansList, setFreePlansList] = useState([]);

  const [inputs, setInputs] = useState({
    login_background_image_for_app: "",
    signup_background_image_for_app: "",
    forgot_password_background_image_for_app: "",
    default_sale_page: null,
    free_default_sale_page: null,
    default_plan: null,
    free_default_plan: null,
    basic_membership_app_msg_for_subscription: "",
    basic_membership_app_msg_for_already_subscribed: "",
    privacy_policy_link: "",
    terms_and_condition_link: "",
    login_page_text: "",
    signup_page_text: "",
    account_deletion_description: "",
    app_update_description: "",
    is_wheel_of_life_enable: true,
  });

  const getDefaultSetting = async () => {
    const result = await get_app_settings_api();
    if (result.code === 200) {
      delete result.default_setting?._id;
      let setting = result.default_setting;
      setPagesList(result.sale_pages);
      if (setting.default_sale_page) {
        let find_page = result.sale_pages.find(
          (page) => page._id == setting.default_sale_page
        );
        setting.default_sale_page = find_page;
        setPlansList(find_page.payment_plans);
        if (setting.default_plan) {
          let find_plan = find_page.payment_plans.find(
            (plan) => plan._id == setting.default_plan
          );
          setting.default_plan = find_plan;
        }
      }
      if (setting.free_default_sale_page) {
        let find_free_page = result.sale_pages.find(
          (page) => page._id == setting.free_default_sale_page
        );
        setting.free_default_sale_page = find_free_page;
        setFreePlansList(find_free_page.free_payment_plans);
        if (setting.free_default_plan) {
          let find_plan = find_free_page.free_payment_plans.find(
            (plan) => plan._id == setting.free_default_plan
          );
          setting.free_default_plan = find_plan;
        }
      }
      setInputs(setting);
      setIsLoading(false);
    }
  };
  const fileChangedHandler = async (e, i) => {
    const { name } = e.target;
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "600");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        [name]: imageUpload.image_path,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let postData = { ...inputs };
    if (postData.default_sale_page) {
      postData.default_sale_page = postData.default_sale_page?._id;
    }
    if (postData.free_default_sale_page) {
      postData.free_default_sale_page = postData.free_default_sale_page?._id;
    }
    if (postData.default_plan) {
      postData.default_plan = postData.default_plan?._id;
    }
    if (postData.free_default_plan) {
      postData.free_default_plan = postData.free_default_plan?._id;
    }
    console.log(postData, "postDatapostDatapostDatapostData");
    const result = await update_app_settings_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    getDefaultSetting();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const handleSelectPage = (value) => {
    setPlansList(value.payment_plans);
    setInputs((old) => {
      return { ...old, default_plan: null, default_sale_page: value };
    });
  };
  const handleSelectFreePage = (value) => {
    setFreePlansList(value.free_payment_plans);
    setInputs((old) => {
      return { ...old, free_default_plan: null, free_default_sale_page: value };
    });
  };

  const handleSelectedPlan = (name, value) => {
    setInputs((old) => {
      return { ...old, [name]: value };
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6">
          <h2>App Setting</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Basic Membership Sale Page For App"
            selectedOption={inputs.default_sale_page}
            setSelectedOption={handleSelectPage}
            optionsList={pagesList}
            autoComplete="new-password"
            name="sale_page_title"
            required
          />
        </div>
        {plansList.length > 0 ? (
          <div className="col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Basic Membership Plan For App"
              selectedOption={inputs.default_plan}
              setSelectedOption={(e) => {
                handleSelectedPlan("default_plan", e);
              }}
              optionsList={plansList}
              autoComplete="new-password"
              name="plan_title"
              required
            />
          </div>
        ) : (
          ""
        )}
        <div className="col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Free Sale Page For App"
            selectedOption={inputs.free_default_sale_page}
            setSelectedOption={handleSelectFreePage}
            optionsList={pagesList}
            autoComplete="new-password"
            name="sale_page_title"
            required
          />
        </div>
        {freePlansList.length > 0 ? (
          <div className="col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Free Plan For App"
              selectedOption={inputs.free_default_plan}
              setSelectedOption={(e) => {
                handleSelectedPlan("free_default_plan", e);
              }}
              optionsList={freePlansList}
              autoComplete="new-password"
              name="plan_title"
              required
            />
          </div>
        ) : (
          ""
        )}
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Privacy Policy Link"
            variant="outlined"
            fullWidth
            name="privacy_policy_link"
            value={inputs.privacy_policy_link}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Terms And Condition Link"
            variant="outlined"
            fullWidth
            name="terms_and_condition_link"
            value={inputs.terms_and_condition_link}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Is Wheel of Life Enable For Free User *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_wheel_of_life_enable"
              value={inputs.is_wheel_of_life_enable}
              label="Is Wheel of Life Enable For Free User"
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 mb-2">
            <div className="col-5">
              <p className="">Login Page Background image *</p>
              <FormHelperText className="pt-0">
                Recommended Size (470 X 1000) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              <img
                src={
                  inputs?.login_background_image_for_app
                    ? s3baseUrl + inputs?.login_background_image_for_app
                    : ""
                }
                height="50"
              />
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file2">
                <Input
                  accept="image/*"
                  id="contained-button-file2"
                  multiple
                  type="file"
                  name="login_background_image_for_app"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>{" "}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 mb-2">
            <div className="col-5">
              <p className="">Signup Page Background image *</p>
              <FormHelperText className="pt-0">
                Recommended Size (470 X 1000) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              <img
                src={
                  inputs?.signup_background_image_for_app
                    ? s3baseUrl + inputs?.signup_background_image_for_app
                    : ""
                }
                height="50"
              />
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file2su">
                <Input
                  accept="image/*"
                  id="contained-button-file2su"
                  multiple
                  type="file"
                  name="signup_background_image_for_app"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>{" "}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 mb-2">
            <div className="col-5">
              <p className="">Forgot Page Password Background Image *</p>
              <FormHelperText className="pt-0">
                Recommended Size (470 X 1000) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              <img
                src={
                  inputs?.forgot_password_background_image_for_app
                    ? s3baseUrl +
                      inputs?.forgot_password_background_image_for_app
                    : ""
                }
                height="50"
              />
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file2fg">
                <Input
                  accept="image/*"
                  id="contained-button-file2fg"
                  multiple
                  type="file"
                  name="forgot_password_background_image_for_app"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Signup Image</p>
              <FormHelperText className="pt-0">
                Recommended Size 32 X 32
              </FormHelperText>
            </div>
            <div className="col-2">
              {signupImage.signup_image ? (
                <img src={signupImage.signup_image} height="50" />
              ) : (
                <img
                  src={
                    inputs.old_signup_image
                      ? s3baseUrl + inputs.old_signup_image
                      : ""
                  }
                  height="50"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-filesu">
                <Input
                  accept="image/*"
                  id="contained-button-filesu"
                  multiple
                  type="file"
                  name="signup_image"
                  onChange={fileChangedHandlersiginup}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.signup_image?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs?.signup_image?.name}</p>
          )}
        </div> */}
        <div className="col-12 mt-4">
          <h4>Basic Membership App Message For Create Subscription *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="basic_membership_app_msg_for_subscription"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Basic Membership App Message For Already Subscribed *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="basic_membership_app_msg_for_already_subscribed"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Login Page Text *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="login_page_text"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Signup Page Text *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="signup_page_text"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Account Deletion Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="account_deletion_description"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>App Update Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="app_update_description"
            editorHeight={320}
          />
        </div>
        <div className="text-end mt-4">
          <button
            onClick={handleSubmit}
            className="small-contained-button"
            id="fixedbutton"
          >
            {isLoadingForm ? "Updating" : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
}
