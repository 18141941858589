import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { adminInitApi } from "src/DAL/Login/Login";
import { usePGIMode } from "src/Hooks/PGIModeContext";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const Welcome = () => {
  const { wellcomeMessage } = usePGIMode();

  return (
    <>
      <div className="container-fluid">
        {wellcomeMessage && (
          <div className="row">
            <div
              className="dashboard_description"
              dangerouslySetInnerHTML={{
                __html: wellcomeMessage,
              }}
            ></div>
          </div>
        )}
      </div>
    </>
  );
};

export default Welcome;
