import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import {
  deleteProductApi,
  shopProductListApi,
} from "src/DAL/ShopProduct/shopProduct";
import { convertCurrencyToSign, show_proper_words } from "src/utils/constant";
import moment from "moment";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ShopProductList() {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [productsList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getProductsList = async () => {
    setIsLoading(true);
    const result = await shopProductListApi(params.category_id);
    if (result.code == 200) {
      const productsListData = result.product.map((product) => {
        let amount = convertCurrencyToSign(product.currency) + product.price;
        let created_at = "N/A";
        if (product.createdAt) {
          created_at = moment(product.createdAt).format("DD-MM-YYYY");
        }
        return {
          ...product,
          table_avatar: {
            src: s3baseUrl + product?.image[0]?.image,
            alt: product.name,
          },
          status: product.status,
          amount: amount,
          created_at,
        };
      });
      setProductList(productsListData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate(
      `/shop-category/${params.category_id}/products/edit-product/${value._id}`,
      {
        state: value,
      }
    );
  };
  const handleMeasureConfiguration = (value) => {
    navigate(
      `/shop-category/${params.category_id}/products/measure-configuration/${value._id}`,
      {
        state: value,
      }
    );
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteProductApi(deleteDoc._id);

    if (result.code === 200) {
      getProductsList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/shop-category/${params.category_id}/products/add-product`);
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "name", label: "Title" },
    { id: "product_sku", label: "Product Sku" },
    { id: "table_avatar", label: "Image", type: "thumbnail" },
    { id: "amount", label: "Price" },
    { id: "sold_quantity", label: "Sold Quantity" },
    {
      id: "remaining_quantity",
      label: "Remaining Quantity",
      renderData: (row) => {
        return <p className="mb-0">{row.remaining_quantity}</p>;
      },
    },
    { id: "quantity", label: "Total Quantity" },
    { id: "created_at", label: "Created At" },
    { id: "order", label: "Order" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Measurement Configuration",
      icon: "akar-icons:edit",
      handleClick: handleMeasureConfiguration,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  let breadCrumbMenu = [
    {
      title: "Shop Category",
      navigation: `/shop-category`,
      active: false,
    },
    {
      title: show_proper_words(params?.category_id),
      active: true,
    },
  ];

  useEffect(() => {
    getProductsList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-3 ">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-lg-8 col-sm-12">
            <h2>Shop Product List</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Shop Product
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={productsList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
