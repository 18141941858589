import { invokeApi } from "../../bl_libs/invokeApi";
export const _add_wheel_of_life = async (data) => {
  const requestObj = {
    path: `/api/wheel_of_life`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_wheel_of_life = async (wheel_id, data) => {
  const requestObj = {
    path: `/api/wheel_of_life/${wheel_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_wheel_of_life = async (page_id) => {
  let api_path = "/api/wheel_of_life/";
  if (page_id) {
    api_path = `/api/wheel_of_life/list_wheel_of_life_by_sale_page/${page_id}`;
  }
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_wheel_of_life_by_type = async (type) => {
  const requestObj = {
    path: `/api/wheel_of_life/list/by_type?type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_wheel_of_life_by_type_assessment = async (data) => {
  const requestObj = {
    path: `/api/wheel_of_life/list_by_type`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_wheel_of_life_members_api = async (page, limit, search) => {
  const requestObj = {
    path: `/api/wheel_of_life/list_true_wheel_of_life?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getMemberDetailQuestion = async (page, limit) => {
  const requestObj = {
    path: `/api/wheel_of_life/list_true_wheel_of_life?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_wheel_of_life = async (id) => {
  const requestObj = {
    path: `/api/wheel_of_life/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
