import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  DeleteStudySessionApi,
  studySessionListing,
} from "src/DAL/studySession/studySessionApi";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  { id: "table_avatar", label: "Image", alignRight: false, type: "thumbnail" },
  { id: "program_title", label: "Programme", alignRight: false },
  { id: "order", label: "Order", alignRight: false },
  {
    id: "session_status",
    label: "Status",
    type: "row_status",
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SessionsList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [sessionsData, setSessionsData] = useState([]);

  const getSessionsListing = async () => {
    const result = await studySessionListing();
    if (result.code == 200) {
      const sessions = result.study_session.map((session) => {
        return {
          ...session,
          table_avatar: {
            src: s3baseUrl + session.image.thumbnail_2,
            alt: session.title,
          },
          session_status: session.status,
          program_title: session.program?.title,
        };
      });
      setSessionsData(sessions);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteStudySessionApi(deleteDoc.study_session_slug);
    if (result.code === 200) {
      setSessionsData((sessionsData) => {
        return sessionsData.filter((data) => data._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(`/sessions/edit-session/${value.study_session_slug}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/sessions/add-session`);
  };

  useEffect(() => {
    getSessionsListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this session?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-sm-12 mb-3">
            <h2>Study Sessions</h2>
          </div>
          <div className="col-lg-6 col-sm-12 mb-3 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Study Sessions
            </button>
          </div>
        </div>
        <div className="mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={sessionsData}
            MENU_OPTIONS={MENU_OPTIONS}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
