import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import {
  ReminderUSerList,
  adminUserDeleteApi,
} from "src/DAL/AdminUserApi/AdminUserApi";
import { Icon } from "@iconify/react";
import moment from "moment";
import { dd_date_format } from "src/utils/constant";
import ReminderFilter from "./ReminderFilter";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import FilteredChip from "src/components/FilteredChip";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ReminderUser() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [searchText, setSearchText] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);

  const EMPTY_FILTER = {
    user_status: "pending",
    date: null,
    search_text: "",
  };

  const ALTER_FILTER = {
    user_status: "",
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const getProductCategories = async (filter_data) => {
    setIsLoading(true);
    let postData = { ...filter_data };

    if (postData.user_status) {
      postData.status = filter_data.user_status;
    } else {
      postData.status = "";
    }

    if (postData.date) {
      postData.date = moment(postData.date).format("YYYY-MM-DD");
    }

    handleCloseFilterDrawer();
    const result = await ReminderUSerList(page, rowsPerPage, postData);
    if (result.code == 200) {
      let chipData = { ...filter_data };
      delete chipData.search_text;
      setUpdateFilterData(chipData);
      const ListData = result.users.map((items) => {
        return {
          ...items,
          name: items.users[0]?.first_name + " " + items?.users[0]?.last_name,
          email: items.users[0]?.email,
          MENU_OPTIONS: handleMenu(items),
        };
      });
      setCategoryList(ListData);
      setTotalCount(result.total_records);
      setTotalPages(result.total_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/reminder-user/edit-reminder-user/${value?._id}`, {
      state: value,
    });
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await adminUserDeleteApi(deleteDoc?._id);
    if (result.code === 200) {
      getProductCategories();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClearFilter = () => {
    let clear_data = {
      ...EMPTY_FILTER,
      user_status: "",
    };
    getProductCategories(clear_data);
    setFilterData(clear_data);
    localStorage.setItem("reminder_user", JSON.stringify(clear_data));
  };

  const handleDeleteChip = (data) => {
    localStorage.setItem("reminder_user", JSON.stringify(data));
    getProductCategories(data);
    setFilterData(data);
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const searchFunction = () => {
    filterData.search_text = searchText;
    setPage(0);
    setPageCount(1);
    getProductCategories(filterData);
    localStorage.setItem("reminder_user", JSON.stringify(filterData));
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("Reminder_number", newPage);
  };
  const handleNavigate = () => {
    navigate(`/reminder-user/add-reminder-user`);
  };

  const handleMenu = (row) => {
    let MENU_OPTIONS = [];
    if (row.is_notification_send === false) {
      MENU_OPTIONS.push({
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      });
    }

    return MENU_OPTIONS;
  };
  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    {
      id: "nurture",
      label: "Nurture",
      renderData: (row) => {
        let nurtureData = "N/A";
        if (row.nurture) {
          nurtureData =
            row.nurture.first_name +
            " " +
            row.nurture.last_name +
            " (" +
            row.nurture.email +
            ")";
        }
        return nurtureData;
      },
    },
    {
      id: "time",
      label: "Notify Date Time",
      renderData: (row) => {
        let time;
        if (row.notify_schedule.length > 0) {
          time = moment(row.notify_schedule[0].notify_time, "HH:mm");
        }
        return (
          <>
            {row.notify_schedule.length > 0 ? (
              <div className="d-flex">
                <p style={{ marginBottom: "0px" }}>
                  {dd_date_format(row.notify_schedule[0].notify_date_time)}
                </p>

                <div className="ms-2">
                  <p style={{ marginBottom: "0px" }}>{time.format("h:mm A")}</p>
                </div>
              </div>
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        return (
          <>
            {row.is_notification_send === true ? (
              <Chip
                label="Send"
                className="booking-status-chip"
                color="success"
              />
            ) : (
              <Chip
                label="pending"
                className="booking-status-chip"
                style={{ backgroundColor: "rgb(24, 144, 255)" }}
              />
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("reminder_user");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getProductCategories(filter_data);
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Welcome Message Reminders</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end d-flex justify-content-end">
            <div>
              <button
                className="small-contained-button me-2 mt-1 mb-4"
                onClick={handleOpenFilterDrawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
              <button
                className="small-contained-button me-2"
                onClick={handleNavigate}
              >
                Add Reminder
              </button>
            </div>
          </div>
        </div>
        <FilteredChip
          data={updateFilterData}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          ALTER_FILTER={ALTER_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoryList}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          className="card-with-background"
          pagePagination={true}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <ReminderFilter
            handleChaneOthers={handleSelectOther}
            filterData={filterData}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
