import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import { activeMemberListing } from "../../DAL/member/member";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress, Autocomplete } from "@mui/material";

import TinyEditor from "src/components/ckeditor/Ckeditor";
import {
  activeGroupListingApi,
  GroupAutomatedListingApi,
} from "src/DAL/group/group";
import { AddPodsNewApi } from "src/DAL/Pods/pods";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useEffect } from "react";
import moment from "moment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  AddChallengeApi,
  EditChallengeApi,
} from "src/DAL/challenges/challenges";
import { s3baseUrl } from "src/config/config";
import {
  addProductApi,
  editProductApi,
  UploadShopImageOnS3,
} from "src/DAL/ShopProduct/shopProduct";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function AddShopProduct() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { state } = useLocation();
  const params = useParams();
  const [personName, setPersonName] = useState([]);
  const [member, setMember] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupListing, setGroupListing] = useState([]);
  const [groupAutomatedListing, setGroupAutomatedListing] = useState([]);
  const [groupsAutomatedName, setGroupsAutomatedName] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [image, setImage] = useState({});
  const [oldImage, setOldImage] = useState();
  const [oldImageArray, setOldImageArray] = useState();
  const [detailDescriptionCk, setDetailDescriptionCk] = useState("");
  const [shortDescriptionCk, setShortDescriptionCk] = useState("");
  const [startDateMin, setStartDateMin] = useState(new Date());
  const [endDateMin, setEndDateMin] = useState(new Date());
  const [endDateMax, setEndDateMax] = useState(new Date());
  const [imagesArray, setImagesArray] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [thumbnailImage, setThumbnailImage] = useState("");

  const [inputs, setInputs] = useState({
    name: "",
    status: "true",
    Thumbnail_image: {},
    detailed_description: "",
    order: "",
    price: "",
    quantity: "",
    currency: "gbp",
    is_video: false,
    video_url: "",
  });

  const fileChangedHandler = async (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    const result = await UploadShopImageOnS3(formData);
    // console.log(result, "okokokokookoko img");
    if (result.code == 200) {
      // console.log(result, "uploaded");
      setThumbnailImage(result.image_path);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(imagesArray, "from submit");
    if (imagesArray.length < 1) {
      setIsLoading(false);
      enqueueSnackbar("Please select at least 1 image", { variant: "error" });
    } else {
      const results = imagesArray.map((image, index) => {
        const formData = new FormData();
        formData.append("image", image.path);
        const result = UploadShopImageOnS3(formData);
        return result;
      });

      Promise.all(results).then(async (img_results) => {
        let setImageArray = [];
        img_results.map((images, index) => {
          let imageObj = { image: images.image_path };
          setImageArray.push(imageObj);
        });

        let postData = {
          name: inputs.name,
          image: setImageArray,
          status: inputs.status,
          currency: inputs.currency,
          quantity: inputs.quantity,
          short_description: shortDescriptionCk,
          detailed_description: detailDescriptionCk,
          is_video: inputs.is_video,
          video_url: inputs.video_url,
          price: inputs.price,
          product_category_slug: params.category_id,
          video_thumbnail: thumbnailImage,
        };

        const result = params.product_id
          ? await editProductApi(postData, params.product_id)
          : await addProductApi(postData);
        if (result.code === 200) {
          enqueueSnackbar(result.message, { variant: "success" });
          navigate(-1);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      });
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (imagesArray.length < 1) {
      setIsLoading(false);
      enqueueSnackbar("Please select at least 1 image", { variant: "error" });
    } else {
      var filterArray = oldImageArray.filter((img) => img.type == "file");
      // console.log(filterArray, "filterArrayfilterArrayfilterArray");
      const results = filterArray.map((image, index) => {
        const formData = new FormData();
        formData.append("image", image.path);
        const result = UploadShopImageOnS3(formData);
        return result;
      });
      // console.log(results, "image upload function");
      Promise.all(results).then(async (img_results) => {
        let setImageArray = [];
        img_results.map((images, index) => {
          let imageObj = { image: images.image_path };
          oldImageArray.push(imageObj);
        });
        let sendArray = oldImageArray.map((item, index) => {
          if (item.image) {
            setImageArray.push(item);
          }
        });
        // console.log(setImageArray, "setImageArraysetImageArray", oldImageArray);
        // var imagesObject = {};
        // setImageArray.map((images, i) => {
        //   console.log("thumbnail_" + i, "thumnail_");
        //   let thumbnailValue = "thumbnail_" + i;
        //   imagesObject["thumbnail_" + (i + 1)] = images;
        // });
        // console.log(imagesObject, "imagesObjectimagesObject");
        let postData = {
          name: inputs.name,
          image: setImageArray,
          status: inputs.status,
          currency: inputs.currency,
          quantity: inputs.quantity,
          short_description: shortDescriptionCk,
          detailed_description: detailDescriptionCk,
          is_video: inputs.is_video,
          video_url: inputs.video_url,
          video_thumbnail: thumbnailImage ? thumbnailImage : oldImage,
          price: inputs.price,
          product_category_slug: params.category_id,
          order: inputs.order,
        };
        // console.log(postData, "postDatapostDatapostData");

        const result = params.product_id
          ? await editProductApi(postData, params.product_id)
          : await addProductApi(postData);
        if (result.code === 200) {
          enqueueSnackbar(result.message, { variant: "success" });
          navigate(-1);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleUpload = (event) => {
    let setImageObject = {};
    const fileList = event.target.files[0];
    setImageObject = {
      path: fileList,
      type: "file",
    };
    setImagesArray((prevPreviews) => [...prevPreviews, setImageObject]);
    if (params.product_id) {
      setOldImageArray((prevPreviewsss) => [...prevPreviewsss, setImageObject]);
    }
  };
  const handleRemove = (index) => {
    imagesArray.splice(index, 1);
    setImagesArray([...imagesArray]);
    setOldImageArray([...imagesArray]);
  };

  const handleChangeTime = (event) => {
    const { name, value } = event.target;

    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeDate = (name, value) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const get_dropdown_menu = (limit) => {
    let arr = [];
    for (let index = 0; index <= limit; index++) {
      arr.push(index);
    }

    return arr;
  };

  useEffect(() => {
    if (inputs.recurring_type === "daily") {
      let myDate = new Date(inputs.start_date);
      myDate.setDate(myDate.getDate() + 30);
      setEndDateMin(() => inputs.start_date);
      setEndDateMax(() => myDate);
    } else if (inputs.recurring_type === "weekly") {
      let myDate = new Date(inputs.start_date);
      myDate.setDate(myDate.getDate() + 168);
      setEndDateMin(() => inputs.start_date);
      setEndDateMax(() => myDate);
    } else if (inputs.recurring_type === "monthly") {
      let myDate = new Date(inputs.start_date);
      myDate.setDate(myDate.getDate() + 180);
      setEndDateMin(() => inputs.start_date);
      setEndDateMax(() => myDate);
    }
    setInputs((prev) => {
      return { ...prev, end_date: inputs.start_date };
    });
  }, [inputs.start_date, inputs.recurring_type]);

  useEffect(() => {}, []);
  useEffect(() => {
    if (params?.product_id) {
      let OldImageObject = state.image;
      let OldImageArrayList = [];

      let OldImageObject1 = Object.keys(OldImageObject).map((key) => [
        OldImageObject[key],
        OldImageArrayList.push(OldImageObject[key]),
      ]);

      setInputs(state);

      setDetailDescriptionCk(state?.detailed_description);
      setShortDescriptionCk(state?.short_description);
      setOldImage(state?.video_thumbnail);
      setOldImageArray(state.image);
      setImagesArray(state.image);
    }
  }, []);
  let finalArray = [];
  for (let i = 0; i < personName.length; i++) {
    let count = 0;
    for (let j = 0; j < member.length; j++) {
      if (personName[i]._id == member[j]._id) {
        count++;
      }
    }
    if (count == 0) {
      finalArray.push(personName[i]);
    }
  }

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  // console.log(params, "paramsparams");
  // console.log(state, "statestatestate");
  console.log(imagesArray, "imagesArray");
  console.log(oldImageArray, "oldImageArrayoldImageArray");

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">
            {params?.product_id ? "Edit Product" : "Add Product"}
          </h2>
        </div>
      </div>

      <form onSubmit={params.product_id ? handleUpdate : handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="name"
              value={inputs.name}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Price"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="price"
              value={inputs.price}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Currency *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="currency"
                value={inputs.currency}
                label="Currency"
                onChange={handleChange}
              >
                {/* <MenuItem value="usd">Dollar</MenuItem> */}
                <MenuItem value="gbp">UK Pounds</MenuItem>
                {/* <MenuItem value="eur">Euro</MenuItem> */}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Quantity"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="quantity"
              value={inputs.quantity}
              onChange={handleChange}
            />
          </div>
          {params?.product_id && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                type="number"
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Is Video</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_video"
                value={inputs.is_video}
                label="Is Video"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.is_video == true && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Video Url"
                  variant="outlined"
                  fullWidth
                  required
                  type="url"
                  name="video_url"
                  value={inputs.video_url}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-5">
                    <p className="">Upload Video Thumbnail *</p>
                    <FormHelperText className="pt-0">
                      Image Size(350 X 100) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-2">
                    {image.length > 0 ? (
                      <img src={image} height="50" />
                    ) : (
                      oldImage && <img src={s3baseUrl + oldImage} height="50" />
                    )}
                  </div>

                  <div className="col-5 text-end pt-2">
                    <label htmlFor="contained-button-file-logo11">
                      <Input
                        accept="image/*"
                        id="contained-button-file-logo11"
                        multiple
                        type="file"
                        name="logo"
                        hidden
                        onChange={fileChangedHandler}
                      />

                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs?.image?.name == "" ? (
                  ""
                ) : (
                  <p className="text-secondary">{inputs?.image?.name}</p>
                )}
              </div>
            </>
          )}

          <>
            <div className="col-md-12 mt-4 d-flex  div-style">
              <div className="row w-100 add-memories-preview">
                <div className="col-lg-11 col-md-11 col-sm-12">
                  <div className="row">
                    {imagesArray.length > 0 ? (
                      imagesArray.map((file, index) => (
                        <div
                          className="col-1 mt-2 product-image-width"
                          key={index}
                        >
                          <span className="preview mt-2">
                            <span onClick={() => handleRemove(index)}>x</span>
                            {file.type === "file" ? (
                              <img
                                src={
                                  file.path
                                    ? URL.createObjectURL(file.path)
                                    : ""
                                }
                              />
                            ) : (
                              <img src={s3baseUrl + file.image} />
                            )}
                          </span>
                        </div>
                      ))
                    ) : (
                      <p className="mt-5">Upload Images</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-1 col-md-1 col-sm-12 mt-2">
                  <span className="upload-button mt-2">
                    <input
                      color="primary"
                      accept="image/*"
                      type="file"
                      id="icon-button-file"
                      style={{ display: "none" }}
                      name="affirmationImage"
                      onChange={handleUpload}
                    />
                    <label className="" htmlFor="icon-button-file">
                      <CloudUploadIcon />
                    </label>
                  </span>
                </div>
              </div>
            </div>
            <p className="text-muted">
              Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP") *
            </p>
          </>
          <div className="col-12 mt-4">
            <h4>Short Description *</h4>
            <TinyEditor
              setDetailDescription={setShortDescriptionCk}
              detailDescriptionCk={shortDescriptionCk}
              editorHeight={300}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Detailed Description</h4>
            <TinyEditor
              setDetailDescription={setDetailDescriptionCk}
              detailDescriptionCk={detailDescriptionCk}
              editorHeight={300}
            />
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
