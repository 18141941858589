import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useNavigate } from "react-router-dom";
import { IconButton, CircularProgress, Autocomplete } from "@mui/material";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import { useEffect } from "react";
import {
  addDelegatePodApi,
  delegateAddDetailApi,
} from "src/DAL/delegatesPods/delegatePods";

import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AddDelegatePods() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [excludeMembers, setExcludeMembers] = useState([]);
  const [member, setMember] = useState([]);
  const [selectedExclude, setSelectedExclude] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupListing, setGroupListing] = useState([]);
  const [groupAutomatedListing, setGroupAutomatedListing] = useState([]);
  const [groupsAutomatedName, setGroupsAutomatedName] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [image, setImage] = useState({});
  const [detailDescriptionCk, setDetailDescriptionCk] = useState("");
  const [shortDescriptionCk, setShortDescriptionCk] = useState("");

  const [inputs, setInputs] = useState({
    title: "",
    status: "true",
    room_type: "general",
    image: {},
    short_description: "",
    detailed_description: "",
    zoom_link: "",
    password: "",
    start_date: new Date(),
    start_time: "00:00",
    end_date: new Date(),
    duration_hour: 1,
    duration_minute: 0,
    is_recurring: false,
    recurring_type: "weekly",
  });

  const fileChangedHandler = (e) => {
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const getAddMemberData = async () => {
    setIsLoading(true);
    const result = await delegateAddDetailApi();
    if (result.code === 200) {
      let delegates = result.delegates.map((item) => {
        return {
          ...item,
          full_name: item.first_name + " (" + item.email + ")",
        };
      });
      setPersonName(delegates);
      setExcludeMembers(delegates);
      setGroupListing(result.groups);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeGroup = (value) => {
    setGroupsName(value);
  };
  const handleChangeAutomatedGroup = (event) => {
    const {
      target: { value },
    } = event;
    setGroupsAutomatedName(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let group_array_automated = [];
    let group_object_automated = {};
    groupsAutomatedName.map((group) => {
      group_object_automated = {
        group_slug: group,
      };
      group_array_automated.push(group_object_automated);
    });
    let group_array = [];
    if (groupsName.length > 0) {
      group_array = groupsName.map((group) => {
        return {
          group_slug: group.delegate_group_slug,
        };
      });
    }

    let selected_member_array = [];
    let selected_member_object = {};
    member.map((member) => {
      selected_member_object = {
        consultant_id: member._id,
      };
      selected_member_array.push(selected_member_object);
    });

    let selected_exclude_array = [];
    let selected_exclude_object = {};
    selectedExclude.map((member) => {
      selected_exclude_object = {
        _id: member._id,
      };
      selected_exclude_array.push(selected_exclude_object);
    });

    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("image", inputs.image);
    formData.append("short_description", shortDescriptionCk);
    formData.append("detail_description", detailDescriptionCk);
    formData.append("status", inputs.status);
    formData.append("zoom_link", inputs.zoom_link);
    formData.append("password", inputs.password);
    formData.append(
      "group",
      JSON.stringify(
        inputs.room_type == "general" ? group_array : group_array_automated
      )
    );
    if (inputs.room_type == "general") {
      formData.append("consultant", JSON.stringify(selected_member_array));
      formData.append(
        "exclude_members",
        JSON.stringify(selected_exclude_array)
      );
    }

    const result = await addDelegatePodApi(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/delegate-pods`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    getAddMemberData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(`/delegate-pods`)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>Add Delegate Pod</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Pod Status *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Pod Status*"
                onChange={handleChange}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Zoom Link"
              variant="outlined"
              fullWidth
              name="zoom_link"
              value={inputs.zoom_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              fullWidth
              name="password"
              value={inputs.password}
              onChange={handleChange}
            />
          </div>
          {inputs.room_type == "general" && (
            <>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <MUIAutocomplete
                  inputLabel="Groups"
                  selectedOption={groupsName}
                  setSelectedOption={handleChangeGroup}
                  optionsList={groupListing}
                  multiple
                  name="title"
                />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <MUIAutocomplete
                  inputLabel="Delegates"
                  selectedOption={member}
                  setSelectedOption={setMember}
                  optionsList={personName}
                  multiple
                  name="full_name"
                />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <MUIAutocomplete
                  inputLabel="Exclude Delegates"
                  selectedOption={selectedExclude}
                  setSelectedOption={setSelectedExclude}
                  optionsList={excludeMembers}
                  multiple
                  name="full_name"
                />
              </div>
            </>
          )}
          {inputs.room_type == "automated" && (
            <>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-name-label">
                    Automated Groups
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={groupsAutomatedName}
                    onChange={handleChangeAutomatedGroup}
                    input={<OutlinedInput label="Automated Groups" />}
                    MenuProps={MenuProps}
                  >
                    {groupAutomatedListing.map((name) => (
                      <MenuItem
                        key={name}
                        value={name.group_slug}
                        style={getStyles(name, groupsAutomatedName, theme)}
                      >
                        {name.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </>
          )}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(350 X 100) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {image.length > 0 && <img src={image} height="50" />}
              </div>

              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file-logo">
                  <Input
                    accept="image/*"
                    id="contained-button-file-logo"
                    multiple
                    type="file"
                    name="logo"
                    hidden
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <div className="col-12 mt-4">
            <h4>Short Description *</h4>
            <TinyEditor
              setDetailDescription={setShortDescriptionCk}
              detailDescriptionCk={shortDescriptionCk}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Detailed Description *</h4>
            <TinyEditor
              setDetailDescription={setDetailDescriptionCk}
              detailDescriptionCk={detailDescriptionCk}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
