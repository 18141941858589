import { CircularProgress } from "@mui/material";
import React from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageLevelAccess({ tabData }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [programsList, setProgramsList] = useState([]);

  const getProgramsList = () => {
    setIsLoading(true);
    let levels = tabData?.map((item) => {
      let isDefault = "";
      if (item.is_default == true) {
        isDefault = "(Default)";
      }
      return {
        ...item,
        table_avatar: {
          src: s3baseUrl + item.icon,
          alt: item.title,
        },
        isDefault,
      };
    });
    setProgramsList(levels);
    setIsLoading(false);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    { id: "title", label: "Title" },
    { id: "isDefault", label: "Default" },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];

  useEffect(() => {
    getProgramsList();
  }, [tabData]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={programsList}
          className="card-with-background"
        />
      </div>
    </>
  );
}
