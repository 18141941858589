import * as React from "react";
import {
  challengesListingApi,
  DeleteChallengeApi,
  duplicate_challenge_api,
} from "src/DAL/challenges/challenges";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useEffect } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useState } from "react";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useSnackbar } from "notistack";
import { COMMUNITY_LEVELS, dd_date_format } from "src/utils/constant";
import ChallangeCustomConfirmationLoad from "./ChallangeCustomConfirmationLoad";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import CompletedUser from "./CompletedUser";
import AcceptedCompletedUser from "./AcceptedCompletedUser";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const tabs = ["visioneering", "mediation", "lifeScript"];

export default function ChallengeTabs() {
  const { state, search } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openPopUp, setOpenPopup] = useState(false);
  const [selectedDate, setSelectedData] = useState({});
  const [openPopUpActive, setOpenPopupActive] = useState(false);
  const [challengeListVisioneering, setChallengeListVisioneering] = useState(
    []
  );
  const [challengeListMeditation, setChallengeListMeditation] = useState([]);
  const [challengeListLifeScript, setChallengeListLifeScript] = useState([]);
  const [input, setInput] = useState({ is_duplicate: false });
  const [isLoading, setIsLoading] = useState(false);
  const type = new URLSearchParams(search).get("type");
  let value_index = 0;
  if (type) {
    value_index = tabs.findIndex((item) => item === type);
  }
  const [tabValue, setTabValue] = useState(value_index);
  console.log(type, "typetypetypetypetype");
  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getChallengeLists = async () => {
    setIsLoading(true);
    const result = await challengesListingApi();
    if (result.code === 200) {
      setChallengeListVisioneering(result?.visioneering);
      setChallengeListMeditation(result?.mediation);
      setChallengeListLifeScript(result?.lifeScript);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    console.log(tabValue, "tabValuetabValuetabValue");
    navigate(`/challenge/add-challenge?type=${tabs[tabValue]}`, {
      state: { tabValue: tabValue },
    });
  };
  const show_completed_users = (value) => {
    setSelectedData(value);
    setOpenPopup(true);
  };
  const show_Active_users = (value) => {
    setSelectedData(value);
    setOpenPopupActive(true);
  };

  const handleEdit = (value) => {
    navigate(
      `/challenge/edit-challenge/${value.challenge_slug}?type=${tabs[tabValue]}`,
      {
        state: { ...value, tabValue: tabValue },
      }
    );
  };

  const handleViewDetail = (value) => {
    navigate(
      `/challenge/challenge-detail/${value?.challenge_slug}?type=${tabs[tabValue]}`,
      {
        state: { ...value, tabValue: tabValue },
      }
    );
  };
  const handleCoinConfig = (value) => {
    navigate(
      `/challenge/coin-configuration/${value?.challenge_slug}?type=${tabs[tabValue]}`,
      {
        state: { ...value, tabValue: tabValue },
      }
    );
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleAgreeDuplicate = (value) => {
    setDeleteDoc(value);
    setOpenDuplicate(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteChallengeApi(deleteDoc.challenge_slug);
    if (result.code === 200) {
      getChallengeLists();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDuplicate = async () => {
    setOpenDuplicate(false);
    const result = await duplicate_challenge_api(
      deleteDoc._id,
      input.is_duplicate
    );
    if (result.code === 200) {
      if (result.challenge.challange_type === "visioneering") {
        setChallengeListVisioneering([
          ...challengeListVisioneering,
          result.challenge,
        ]);
      } else if (result.challenge.challange_type === "mediation") {
        setChallengeListMeditation([
          ...challengeListMeditation,
          result.challenge,
        ]);
      } else if (result.challenge.challange_type === "lifeScript") {
        setChallengeListLifeScript([
          ...challengeListLifeScript,
          result.challenge,
        ]);
      }
      setInput((values) => ({ ...values, is_duplicate: false }));
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Title",
      renderData: (row) => {
        return (
          <div
            className="pointer_cursor"
            onClick={() => {
              handleViewDetail(row);
            }}
          >
            {row.title}
          </div>
        );
      },
    },

    {
      id: "challange_intro_video_url",
      label: "Intro Video Url",
      alignRight: false,
    },

    {
      id: "total_count_of_members",
      label: "Active Challenge Members",
      renderData: (row) => {
        return (
          <>
            <p
              className={`${
                row.total_count_of_members ? "cursor-pointer" : "mb-0"
              }`}
              onClick={() =>
                row.total_count_of_members ? show_Active_users(row) : ""
              }
            >
              {row.total_count_of_members}
            </p>
          </>
        );
      },
    },
    {
      label: "Completed Challenge Members",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <p
              className={`${
                row.total_count_of_members_completed ? "cursor-pointer" : "mb-0"
              }`}
              onClick={() =>
                row.total_count_of_members_completed
                  ? show_completed_users(row)
                  : ""
              }
            >
              {row.total_count_of_members_completed}
            </p>
          </>
        );
      },
    },
    {
      label: "Community Level",
      alignRight: false,
      renderData: (row) => {
        let find_level = COMMUNITY_LEVELS.find(
          (level) => level.name == row.community_level
        );
        return find_level.title;
      },
    },

    { id: "challenge_enable_coins_count", label: "Coins", alignRight: false },
    { id: "order", label: "Order", alignRight: false },
    {
      label: "Created At",
      alignRight: false,
      renderData: (row) => {
        let Created_date = "N/A";
        if (row.createdAt) {
          Created_date = dd_date_format(row.createdAt);
        }
        return (
          <>
            <p>{Created_date}</p>
          </>
        );
      },
    },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Details",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleViewDetail,
    },
    {
      label: "Coins Configurtion",
      icon: "akar-icons:edit",
      handleClick: handleCoinConfig,
    },
    {
      label: "Duplicate Challenge",
      icon: "akar-icons:edit",
      handleClick: handleAgreeDuplicate,
    },
  ];

  useEffect(() => {
    getChallengeLists();
  }, []);

  useEffect(() => {
    if (!openDuplicate) {
      setInput((values) => ({ ...values, is_duplicate: false }));
    }
  }, [openDuplicate]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABS_OPTIONS = [
    {
      title: "Visioneering",
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={challengeListVisioneering}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background mt-3"
          pagePagination={true}
        />
      ),
    },
    {
      title: "Meditation",
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={challengeListMeditation}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background mt-3"
          pagePagination={true}
        />
      ),
    },
    {
      title: "Script Writing",
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={challengeListLifeScript}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background mt-3"
          pagePagination={true}
        />
      ),
    },
  ];
  const handleChangeData = (event) => {
    const value = event.target.checked;
    setInput((values) => ({ ...values, is_duplicate: value }));
  };

  return (
    <>
      <ChallangeCustomConfirmationLoad
        open={openDuplicate}
        setOpen={setOpenDuplicate}
        title={"Are you sure you want to duplicate this challenge?"}
        handleAgree={handleDuplicate}
        handleChangeData={handleChangeData}
        input={input}
      />
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="row mb-5">
        <div className="col-lg-8 col-sm-12">
          <h2>Challenges</h2>
        </div>
        <div className="col-lg-4 col-sm-12 text-end">
          <button onClick={handleNavigate} className="small-contained-button">
            {"Add Challenge"}
          </button>
        </div>
      </div>
      <MUICustomTabs
        data={TABS_OPTIONS}
        value={tabValue}
        handleChange={handleChange}
      />
      <WhatsappPopUpModel
        open={openPopUp}
        setOpen={setOpenPopup}
        title={"Completed Challange Members"}
        show_date_and_income={true}
        componentToPassDown={
          <CompletedUser setOpen={setOpenPopup} challenge={selectedDate} />
        }
      />{" "}
      <WhatsappPopUpModel
        open={openPopUpActive}
        setOpen={setOpenPopupActive}
        title={"Active Challenge Members"}
        show_date_and_income={true}
        componentToPassDown={
          <AcceptedCompletedUser
            setOpen={setOpenPopupActive}
            challenge={selectedDate}
          />
        }
      />
    </>
  );
}
