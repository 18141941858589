import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    marginTop: "20%",
  },
}));

export default function MemberDiaryList({ tabData }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [diaryData, setDiaryData] = useState([]);

  const getMainDiaryListing = () => {
    setIsLoading(true);
    const diaries = tabData?.map((diary) => {
      return {
        ...diary,
        name: diary?.title,
        date: diary.date ? diary?.date : "N/A",
        statement: diary?.statement_array?.map((value) => value.option + ", "),
      };
    });

    setDiaryData(diaries);
    setIsLoading(false);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "statement", label: "Statements" },
    { id: "note", label: "Note" },
    { id: "date", label: "Date" },
  ];

  useEffect(() => {
    getMainDiaryListing();
  }, [tabData]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="mt-3">
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={diaryData}
        className="card-with-background"
      />
    </div>
  );
}
