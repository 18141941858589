import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress, Stack } from "@mui/material";
import { useEffect } from "react";

import {
  lesson_detail_api,
  update_lesson_tab_configuration_api,
} from "src/DAL/Programme/Lessons/Lessons";
import { useTheme } from "@mui/material/styles";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function TabConfiguration() {
  const navigate = useNavigate();
  const { programme_slug, lesson_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState("ADD");
  const [lessonName, setlessonName] = useState("");
  const [lessonDetailInfo, setLessonsDetailInfo] = useState();
  const { state } = useLocation();
  let programName = lessonDetailInfo?.program[0]?._id?.title;
  let program_slug_navgation = lessonDetailInfo?.program[0]?._id?.program_slug;
  const [inputs, setInputs] = useState([
    {
      recordings_tab_title: "recordings",
      recordings_tab_title_status: true,
      order: 1,
    },
    {
      resources_tab_title: "resources",
      resources_tab_title_status: true,
      order: 2,
    },
    {
      notes_tab_title: "notes",

      notes_tab_title_status: true,
      order: 3,
    },
  ]);
  function checkOrderNumbers(postData) {
    const orderNumbers = new Set();

    for (const data of postData) {
      if (orderNumbers.has(data.order)) {
        return false; // Found a duplicate order number
      }
      orderNumbers.add(data.order);
    }

    return true; // No duplicate order numbers found
  }
  const handleDetail = async () => {
    setIsLoading(true);
    let result = await lesson_detail_api(lesson_slug);
    if (result.code === 200) {
      setlessonName(result.lesson.title);
      setLessonsDetailInfo(result.lesson);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let postData = [
      {
        recordings_tab_title: inputs[0].recordings_tab_title,
        recordings_tab_title_status: inputs[0].recordings_tab_title_status,
        order: +inputs[0].order,
      },
      {
        resources_tab_title: inputs[1].resources_tab_title,
        resources_tab_title_status: inputs[1].resources_tab_title_status,
        order: +inputs[1].order,
      },
      {
        notes_tab_title: inputs[2].notes_tab_title,
        notes_tab_title_status: inputs[2].notes_tab_title_status,
        order: +inputs[2].order,
      },
    ];
    const isOrderValid = checkOrderNumbers(postData);
    if (!isOrderValid) {
      enqueueSnackbar("Order numbers cannot be the same", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }
    let isValidOrder = true;

    postData.forEach((file, index) => {
      const currentOrder = file.order;

      if (currentOrder < 1 || currentOrder > 3) {
        // console.error(`Error: Invalid order value found at index ${index}!`);
        isValidOrder = false;
        enqueueSnackbar("Order cannot be less then 1 or greater then 3", {
          variant: "error",
        });
        setIsLoading(false);
        return;
      }
    });
    if (isValidOrder) {
      let data = {
        lesson_configration: postData,
      };
      // console.log(data, "i am data");
      const result = await update_lesson_tab_configuration_api(
        data,
        lesson_slug
      );
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        handleNavigate();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event, index) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      const inputToUpdate = updatedInputs[index];
      inputToUpdate[name] = value;
      return updatedInputs;
    });
  };
  const handleFormat = (data) => {
    // console.log(data.lesson_configration, "datadatadatadatadatadata");
    if (data?.lesson_configration.length > 0) {
      setInputs(data?.lesson_configration);
    }
    setIsLoading(false);
  };

  const handleNavigate = () => {
    navigate(`/lessons/lesson-detail/${lesson_slug}`);
  };
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },
    {
      title: programName,
      navigation: `/programmes/programmes-detail/${program_slug_navgation}`,
      active: false,
    },
    {
      title: "Lessons",
      navigation: `/lessons/${program_slug_navgation}`,
      active: false,
    },
    {
      title: lessonDetailInfo?.title,
      navigation: `/lessons/lesson-detail/${lesson_slug}`,
      active: false,
    },
    {
      title: "Tabs Configuration",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    if (lesson_slug) {
      if (state) {
        handleFormat(state);
      }
    }
  }, []);
  useEffect(() => {
    if (state) {
      setLessonsDetailInfo(state);
    } else {
      handleDetail();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  // console.log(inputs.program_module, "prooooooooooooo");

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 mb-3 d-flex">
          <span className="mt-2">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Tabs Configuration</h2>
        </div>
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Recordings Tab Title"
            variant="outlined"
            fullWidth
            name="recordings_tab_title"
            value={inputs[0].recordings_tab_title}
            onChange={(event) => handleChange(event, 0)}
            required
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Recordings Tab Title Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="recordings_tab_title_status"
              label="Is Recordings Tab Title Status "
              value={inputs[0].recordings_tab_title_status}
              onChange={(event) => handleChange(event, 0)}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label=" Recordings Tab Order"
            variant="outlined"
            fullWidth
            required
            name="order"
            type="number"
            value={inputs[0].order}
            onChange={(event) => handleChange(event, 0)}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic1"
            label="Resources Tab Title"
            variant="outlined"
            fullWidth
            name="resources_tab_title"
            value={inputs[1].resources_tab_title}
            onChange={(event) => handleChange(event, 1)}
            required
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label1">
              Resources Tab Title Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label1"
              id="demo-simple-select1"
              name="resources_tab_title_status"
              label=" Resources Tab Title Status "
              value={inputs[1].resources_tab_title_status}
              onChange={(event) => handleChange(event, 1)}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label=" Resources Tab Order"
            variant="outlined"
            fullWidth
            required
            name="order"
            type="number"
            value={inputs[1].order}
            onChange={(event) => handleChange(event, 1)}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic2"
            label="Notes Tab Title"
            variant="outlined"
            fullWidth
            name="notes_tab_title"
            value={inputs[2].notes_tab_title}
            onChange={(event) => handleChange(event, 2)}
            required
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-labe2">
              Notes Tab Title Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label2"
              id="demo-simple-select2"
              name="notes_tab_title_status"
              label="Notes Tab Title Status"
              value={inputs[2].notes_tab_title_status}
              onChange={(event) => handleChange(event, 2)}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label=" Notes Tab Order"
            variant="outlined"
            fullWidth
            required
            name="order"
            type="number"
            value={inputs[2].order}
            onChange={(event) => handleChange(event, 2)}
          />
        </div>
        <div className="text-end mt-4">
          <button className="small-contained-button">
            {formType == "ADD" ? "Submit" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
