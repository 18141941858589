import React from "react";
import TransactionsStatCard from "./TransactionsStatCard";

export default function TransactionsStats({ stats }) {
  let stats_array = Object.keys(stats);
  return (
    <div className="row ">
      {stats_array?.length > 0 &&
        stats_array?.map((stat) => {
          return <TransactionsStatCard count={stats[stat]} title={stat} />;
        })}
    </div>
  );
}
