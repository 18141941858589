import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  road_Delete_Api,
  _bonus_listApi,
} from "src/DAL/BonusRoadMAp/BonusRoadMApApi";
import { s3baseUrl } from "src/config/config";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { COMMUNITY_LEVELS } from "src/utils/constant";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  {
    id: "table_avatar",
    label: "Reached Status",
    alignRight: false,
    type: "thumbnail",
  },
  {
    id: "without_border_icon",
    label: "Before Status",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "coins", label: "Coins", alignRight: false },
  { id: "order", label: "order", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const BonusRoadMap = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [levelsList, setLevelsList] = useState([]);
  const classes = useStyles();
  let selected_index = 0;
  if (search) {
    const level = new URLSearchParams(search).get("level");
    selected_index = COMMUNITY_LEVELS.findIndex(
      (community) => community.name == level
    );
  }
  const [tabValue, setTabValue] = useState(selected_index);

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleEdit = (value) => {
    let find_leve = COMMUNITY_LEVELS[tabValue];
    navigate(
      `/bonus-road-map/edit-bonus-road-map/${value._id}?level=${find_leve.name}`,
      {
        state: { editValues: value },
      }
    );
  };
  const roadMapListing = async () => {
    const result = await _bonus_listApi();
    if (result.code === 200) {
      let levels_array = [];
      COMMUNITY_LEVELS.map((level) => {
        levels_array.push({
          name: level.name,
          title: level.title,
          road_map_list: result[`${level.name}_lavel_list`]?.map((category) => {
            return {
              ...category,
              table_avatar: {
                src: s3baseUrl + category.images.thumbnail_1,
                alt: category.title,
              },
              without_border_icon: {
                src: s3baseUrl + category?.without_border_icon?.thumbnail_1,
                alt: category.title,
              },
            };
          }),
        });
      });
      setLevelsList(levels_array);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await road_Delete_Api(deleteValue);
    if (result.code === 200) {
      setIsLoading(false);
      roadMapListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigate = () => {
    let find_leve = COMMUNITY_LEVELS[tabValue];
    navigate(`/bonus-road-map/add-bonus-road-map?level=${find_leve.name}`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    roadMapListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABS_OPTIONS = levelsList.map((level) => {
    return {
      title: level.title,
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={level.road_map_list}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background mt-3"
          pagePagination={true}
        />
      ),
    };
  });

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        {params.page_id && (
          <div className="row">
            <div className="col-12 display-flex mb-4">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
              <span className="sale-page-title">Bonus Road Map</span>
            </div>
          </div>
        )}
        <div className="row mb-4">
          <div className="col-lg-8 col-sm-12">
            <h2>Bonus Road Map</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Bonus Road Map
            </button>
          </div>
        </div>

        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default BonusRoadMap;
