import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { Icon } from "@iconify/react";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import {
  InputLabel,
  IconButton,
  CircularProgress,
  Button,
  Box,
  Select,
  FormControl,
  MenuItem,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { get_root_value } from "src/utils/domUtils";
import {
  programmeListing,
  programmeListingForDelegate,
} from "src/DAL/Programme/Programme";
import { get_page_plan_filter_list_delegate } from "src/DAL/consultant/consultant";
// import { AddProgram } from "src/DAL/Programmes/Programmes";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ConsultantSubscriberFilter({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleSubmit,
  onCloseDrawer,
  selectedPlan,
  setSelectedPlan,
  checked,
  setChecked,
  selectedPrograms,
  setSelectedProgram,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [plan, setPlan] = React.useState([]);

  // console.log(params.id, "params.idparams.id");
  const id = params.start_date_event
    ? params.start_date_event
    : params.expiry_date_event;

  const handleSubmitFilter = (value) => {
    // console.log(value, "oka value for filter");
    handleSubmit(value);
    onCloseDrawer();
  };

  const getProgrammesList = async () => {
    let result = await get_page_plan_filter_list_delegate();
    if (result.code == 200) {
      setProgramsList(result.sale_page);
      let a = result.sale_page.find((value) => value._id === selectedPrograms);
      // console.log(a, "aaaaaaaaaaa");
      setPlan(a.plans);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };
  const handleChangeDate = (newValue) => {
    setStartDate(newValue);
  };
  const handleChangeEndDate = (newValue) => {
    setEndDate(newValue);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSelectedProgram(value);
  };
  const handleChangePlan = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSelectedPlan(value);
  };
  React.useEffect(() => {
    getProgrammesList();
  }, []);
  React.useEffect(() => {
    let a = programsList.find((value) => value._id === selectedPrograms);
    if (a) {
      setPlan(a?.plans);
    }
  }, [selectedPrograms]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  // console.log(selectedPlan, "selectedPlan");
  return (
    <div className="container-fluid new-memories">
      <form onSubmit={handleSubmitFilter} className="mb-5">
        <div className="row">
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label1">Pages</InputLabel>
              <Select
                labelId="demo-multiple-name-label1"
                id="demo-multiple-name"
                name="pages"
                value={selectedPrograms}
                onChange={handleChange}
                input={<OutlinedInput label="Pages" />}
                MenuProps={MenuProps}
              >
                {programsList?.map((name) => (
                  <MenuItem key={name._id} value={name._id}>
                    {name.sale_page_title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label1">Plan</InputLabel>
              <Select
                labelId="demo-multiple-name-label1"
                id="demo-multiple-name"
                name="plan"
                value={selectedPlan ? selectedPlan : ""}
                onChange={handleChangePlan}
                input={<OutlinedInput label="Plan" />}
                MenuProps={MenuProps}
              >
                {plan?.map((name) => (
                  <MenuItem key={name._id} value={name._id}>
                    {name.plan_title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-12 mt-2">
            {/* <Checkbox
              checked={checked}
              onChange={(e) => setChecked(event.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
            Seacrch By Date Range */}
            <FormControlLabel
              className="mt-2"
              control={
                <Checkbox
                  defaultChecked
                  checked={checked}
                  onChange={(e) => setChecked(event.target.checked)}
                />
              }
              label="Filter By Date Range"
            />
          </div>
          {checked && (
            <>
              <div className="col-12 mt-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      label="Start Date"
                      inputFormat="dd-MM-yyyy"
                      name="start_date"
                      value={startDate}
                      onChange={handleChangeDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </div>
              <div className="col-12 mt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      label="End Date"
                      inputFormat="dd-MM-yyyy"
                      name="end_date"
                      value={endDate}
                      onChange={handleChangeEndDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </div>
            </>
          )}

          {/* <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div> */}
          <Box sx={{ py: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              onClick={() => handleSubmitFilter(true)}
              startIcon={
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                    fill={get_root_value("--portal-theme-primary")}
                  />
                </svg>
              }
            >
              Filter
            </Button>
          </Box>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={() => handleSubmitFilter(false)}
            startIcon={<Icon icon={roundClearAll} />}
          >
            Clear All
          </Button>
        </div>
      </form>
    </div>
  );
}
