import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { s3baseUrl } from "src/config/config";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  customDialog: {
    // maxWidth: "100%",
  },
});

export default function CustomImagePopover({
  open,
  setOpen,
  setViewImage,
  viewImage,
}) {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    setViewImage("");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.customDialog }}
    >
      <div className="open_image_view pointer">
        <span onClick={handleClose}>
          <CloseIcon />
        </span>
      </div>
      <DialogContent>
        <img src={s3baseUrl + viewImage} className="img-fluid p-md-3 p-2 " />
      </DialogContent>
    </Dialog>
  );
}
