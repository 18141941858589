import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import {
  add_dynamite_event_against_member,
  get_client_dynamite_events_api,
} from "src/DAL/member/member";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageMemberEventAccess() {
  const { member_id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [memberDetail, setMemberDetail] = useState({});

  const getting_dates = (object, member) => {
    let purchased_event = member.event.find(
      (old_event) => old_event._id === object._id
    );
    if (purchased_event) {
      //event has already access
      return purchased_event;
    }

    let start_date = new Date(object.start_date);
    // if start date is less than today then consider today date as start date
    var date_to_compare = moment(start_date);
    var today_date = moment();
    if (date_to_compare.isBefore(today_date)) {
      start_date = new Date();
    }
    let expiry_date = new Date();
    // make expiry date by adding no_of_days in start_date
    expiry_date.setDate(start_date.getDate() + object.no_of_days);
    return { start_date, expiry_date };
  };

  const getEventsList = async () => {
    setIsLoading(true);
    const result = await get_client_dynamite_events_api(member_id);
    if (result.code == 200) {
      setMemberDetail(result.member);
      setSelected(result.member.event);
      var eventArray = [];

      result.events.map((item) => {
        let { expiry_date, start_date } = getting_dates(item, result.member);
        item.expiry_date = expiry_date;
        item.start_date = start_date;
        item.table_avatar = {
          src: s3baseUrl + item.images.thumbnail_3,
          alt: item.title,
        };
        item.access_type = item?.access_type ? item?.access_type : "free";
        item.is_show_celendar = true;
        eventArray.push(item);
      });
      setEventsList(eventArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    let events = selected.map((item) => {
      return {
        _id: item._id,
        start_date: moment(item.start_date).format("YYYY-MM-DD"),
        expiry_date: moment(item.expiry_date).format("YYYY-MM-DD"),
        access_type: item.access_type,
      };
    });
    let postData = { event: events };
    const result = await add_dynamite_event_against_member(member_id, postData);
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar("Member added against events successfully", {
        variant: "success",
      });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangeDates = (row, name, date) => {
    const update_item = (old) => {
      return old.map((item) => {
        if (item._id == row._id) {
          return { ...item, [name]: date };
        }
        return item;
      });
    };
    setEventsList((old) => update_item(old));
    setSelected((old) => update_item(old));
  };

  const handleChangeDate = (date, index, row) => {
    handleChangeDates(row, "expiry_date", date);
  };

  const handleChangeStartDate = (date, index, row) => {
    handleChangeDates(row, "start_date", date);
  };

  const handleChangeType = (event, name, id) => {
    const value = event.target.value;
    setEventsList((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };
  const TABLE_HEAD = [
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "p-0",
    },
    { id: "title", label: "Event Title" },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
    {
      id: "access_type",
      label: "Access Type",
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);
        let access_type =
          is_selected && is_selected.access_type
            ? is_selected.access_type
            : row.access_type;

        return (
          <div className="col-12">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="access_type"
                value={access_type}
                className="access_type_field"
                label=""
                onChange={(e) => {
                  handleChangeType(e, "access_type", row._id);
                }}
              >
                <MenuItem value="free">Free</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      id: "start_date",
      label: "Start Date",
      type: "row_calendar",
      handleChangeDate: handleChangeStartDate,
      className: "event_calendar_box",
    },
    {
      id: "expiry_date",
      label: "Expiry Date",
      type: "row_calendar",
      handleChangeDate: handleChangeDate,
      className: "event_calendar_box",
    },
  ];

  useEffect(() => {
    getEventsList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3 reply-anchor">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">
            {memberDetail.first_name +
              " " +
              memberDetail.last_name +
              " (" +
              memberDetail.email +
              ")"}
          </span>
        </div>
        <div className="col-6">
          <h2>Manage Dynamite Event Access</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={eventsList}
            selected={selected}
            setSelected={setSelected}
            checkbox_selection={true}
            pagePagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
