import { TextField } from "@mui/material";
import React, { useState } from "react";

export default function LinkPopup({ handleAddLink }) {
  const [inputs, setInputs] = useState({ display_text: "", link_address: "" });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddLink(inputs);
  };

  return (
    <>
      <div className="container-fluid">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Text to Display"
              variant="outlined"
              fullWidth
              required
              name="display_text"
              value={inputs.display_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-3">
            <TextField
              type="url"
              id="outlined-basic"
              label="Link Address"
              variant="outlined"
              fullWidth
              required
              name="link_address"
              value={inputs.link_address}
              onChange={handleChange}
            />
          </div>

          <div class="col-12 mt-3">
            <button class="small-contained-button w-100">Insert</button>
          </div>
        </form>
      </div>
    </>
  );
}
