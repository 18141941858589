import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { delegateAssessmentQuestionsListing } from "src/DAL/consultant/consultant";
import AssessmentQuestion from "src/components/AssessmentQuestion";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import jsPDF from "jspdf";
import { appLogoIcon } from "src/assets";
import ReactDOMServer from "react-dom/server";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function DelegateAssessmentStudy() {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [assessmentStatementQuestions, setAssessmentStatementQuestions] =
    useState([]);
  const [isDonwloadingPdf, setIsDonwloadingPdf] = useState(false);

  const [userData, setUserData] = useState();

  const getGoalStatementList = async () => {
    const result = await delegateAssessmentQuestionsListing(params.id);
    if (result.code === 200) {
      const get_answer = (ansArray, question_id) => {
        return ansArray?.filter((answer) => answer._id == question_id)[0]
          ?.answer_statement;
      };
      let questionList;

      setUserData(result.delegate);
      setAssessmentStatementQuestions(result.delegate_questions_and_replies);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title: userData
        ? userData?.first_name +
          userData?.last_name +
          " (" +
          userData?.email +
          ")"
        : "Delegate Assessment Study",
      active: true,
    },
  ];

  function convertHtmlToString(html) {
    return ReactDOMServer.renderToString(html);
  }

  console.log(
    assessmentStatementQuestions,
    "assessmentStatementQuestionsassessmentStatementQuestions"
  );

  const get_answer = (ansArray, question_id) => {
    return ansArray?.filter((answer) => answer._id == question_id)[0]
      ?.answer_statement;
  };

  const handleGeneratePdf = () => {
    console.log("aaaa");
    setIsDonwloadingPdf(true);
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    const html = (
      <div className="text-dark generating-pdf">
        <div className="d-flex justify-content-center">
          <img src={appLogoIcon} alt="" srcset="" className="logo-dd" />
        </div>
        <div className="text-center font-weight-bold mb-4">
          {userData.first_name +
            " " +
            userData.last_name +
            " (" +
            userData.email +
            ")"}
        </div>
        {assessmentStatementQuestions.map((item) => {
          return (
            <>
              <h3 className="text-center mb-4">{item.title}</h3>
              {item.delegate_questions.map((question, index) => {
                let answer_statement = get_answer(item.answers, question._id);

                return (
                  <>
                    <h3
                      className={`question-statement-for-pdf font-weight-bold ${
                        index == 0 ? "mt-0" : "mt-2"
                      }`}
                    >
                      Question Statement:
                    </h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question.question_statement,
                      }}
                      className="question-statement-for-pdf-text"
                    />
                    <h3 className="question-statement-for-pdf font-weight-bold">
                      Answer:
                    </h3>
                    <div className="text-area-answer">
                      <p>
                        {answer_statement
                          ? answer_statement
                          : "No Answer Given"}
                      </p>
                    </div>
                  </>
                );
              })}
            </>
          );
        })}
      </div>
    );

    console.log(html, "htmlhtmlhtmlhtmlhtmlhtmlhtmlhtmlhtml");
    const html2 = convertHtmlToString(html);
    console.log(html2, "html2html2html2html2html2html2html2");
    doc.html(html2, {
      margin: [15, 25, 20, 20],
      autoPaging: "text",
      callback: (doc) => {
        doc.save();
        setIsDonwloadingPdf(false);
      },
    });
  };

  useEffect(() => {
    getGoalStatementList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-6">
          <h2>Delegate Assessment Study</h2>
        </div>
        {assessmentStatementQuestions.length > 0 && (
          <div className="col-6 text-end">
            <button
              className="small-contained-button"
              onClick={handleGeneratePdf}
              disabled={isDonwloadingPdf}
            >
              {isDonwloadingPdf ? "Downloading..." : "Download PDF"}
            </button>
          </div>
        )}
      </div>
      <br></br>
      {assessmentStatementQuestions.length > 0
        ? assessmentStatementQuestions.map((value) => {
            return (
              <div className="row">
                <h3>{value?.title}</h3>
                <div className="col-12">
                  <AssessmentQuestion
                    questionStatement={value.delegate_questions}
                    answers={value?.answers}
                  />
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
}

export default DelegateAssessmentStudy;
