import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";
import {
  addDefaultSettingApi,
  defaultSettingApi,
} from "src/DAL/SiteSetting/siteSetting";
import { s3baseUrl } from "src/config/config";
import {
  TimeZones,
  NOTIFICATIONS_ARRAY,
  NOTIFICATIONS_ARRAY_WITH_SMS,
} from "../../utils/constant";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function DefaultSettings() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [navItems, setNavitems] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [pagesName, setPagesName] = useState([]);
  const [timeZoneValue, setTimeZoneValue] = useState();
  const [inputValue, setInputValue] = useState("");
  const [images, setImages] = useState({
    admin_logo: "",
    admin_favicon: "",
    import_member_sample_file: "",
    import_event_user_sample_file: "",
  });
  const [inputs, setInputs] = useState({
    admin_logo: {},
    admin_favicon: {},
    import_member_sample_file: {},
    import_event_user_sample_file: {},
    old_admin_logo: {},
    old_admin_favicon: {},
    old_import_member_sample_file: {},
    old_import_event_user_sample_file: {},
    welcome_msg_for_admin: "",
    meta_title: "",
    meta_description: "",
    default_sale_page_for_web: {},
    time_zone: "",
    default_program: null,
    program_list: [],
    notification_send_type_for_admin: [],
    notification_send_type_for_delegate: [],
    default_access_program: [],
    affiliate_switch_off_tracking_days: "",
    hours_for_pass_booking: "",
    time_for_slot_booking: "",
    client_portal_css_code: "",
    client_portal_js_code: "",
    website_portal_css_code: "",
    website_portal_js_code: "",
    sale_pages: [],
    default_pages: [],
    tinymce_key: "",
    show_project_logs: false,
    pixel_code_header: "",
    pixel_code_body: "",
    analytic_google_code: "",
  });

  const getDefaultSetting = async () => {
    setIsLoading(true);
    const result = await defaultSettingApi();
    if (result.code === 200) {
      let settings = result.default_setting;
      settings.program_list = result.program;
      settings.sale_pages = result.sale_page;

      if (settings.default_program) {
        settings.default_program = result.program.find(
          (program) => program._id == settings.default_program
        );
      }
      if (settings.default_sale_page_for_web) {
        settings.default_sale_page_for_web = result.sale_page.find(
          (page) => page._id == settings.default_sale_page_for_web
        );
      }

      if (settings.default_access_program) {
        const default_access_program_array =
          settings.default_access_program.map((default_program) =>
            result.program.find((program) => program._id == default_program._id)
          );
        if (
          default_access_program_array &&
          default_access_program_array.length > 0 &&
          default_access_program_array[0] !== undefined
        ) {
          console.log(
            default_access_program_array,
            "---default_access_program_array"
          );
          settings.default_access_program = default_access_program_array;
        }
      }

      if (settings.default_pages) {
        settings.default_pages = settings.default_pages.map((default_page) => {
          return result.sale_page.find((page) => page._id == default_page._id);
        });
      }

      if (settings.admin_logo) {
        settings.old_admin_logo = settings.admin_logo;
      }
      if (settings.admin_favicon) {
        settings.old_admin_favicon = settings.admin_favicon;
      }
      if (settings.import_member_sample_file) {
        settings.old_import_member_sample_file =
          settings.import_member_sample_file;
      }
      if (settings.import_event_user_sample_file) {
        settings.old_import_event_user_sample_file =
          settings.import_event_user_sample_file;
      }

      setInputs(settings);
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (event) => {
    const { name, files } = event.target;
    setImages({
      ...images,
      [name]: URL.createObjectURL(files[0]),
    });
    setInputs({
      ...inputs,
      [name]: files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let group_array1 = [];
    let group_object = {};
    groupsName.map((group) => {
      group_object = {
        program_slug: group,
      };
      group_array1.push(group_object);
    });
    let pages_array = [];
    let pages_object = {};
    pagesName.map((pages) => {
      pages_object = {
        page_id: pages,
      };
      pages_array.push(pages_object);
    });
    let navArray = [];
    navArray.push(navItems);

    const formData = new FormData();
    formData.append("meta_title", inputs.meta_title);
    formData.append("meta_description", inputs.meta_description);
    formData.append(
      "affiliate_switch_off_tracking_days",
      inputs.affiliate_switch_off_tracking_days
    );
    formData.append("hours_for_pass_booking", inputs.hours_for_pass_booking);
    formData.append("tinymce_key", inputs.tinymce_key);
    formData.append(
      "show_project_logs",
      inputs.show_project_logs ? true : false
    );
    formData.append("pixel_code_header", inputs.pixel_code_header);
    formData.append("pixel_code_body", inputs.pixel_code_body);
    formData.append("analytic_google_code", inputs.analytic_google_code);
    formData.append("time_for_slot_booking", inputs.time_for_slot_booking);
    if (inputs.default_program) {
      formData.append("default_program", inputs.default_program?._id);
    }
    formData.append("time_zone", timeZoneValue);
    formData.append("contact_us_support_email", "DLX@gmail.com");
    if (inputs.default_sale_page_for_web) {
      formData.append(
        "default_sale_page_for_web",
        inputs.default_sale_page_for_web._id
      );
    }
    formData.append("client_portal_css_code", inputs.client_portal_css_code);
    formData.append("client_portal_js_code", inputs.client_portal_js_code);
    formData.append("welcome_msg_for_admin", inputs.welcome_msg_for_admin);
    formData.append("website_portal_css_code", inputs.website_portal_css_code);
    formData.append("website_portal_js_code", inputs.website_portal_js_code);

    if (inputs.default_pages?.length > 0) {
      let default_pages = inputs.default_pages.map((page) => {
        return {
          page_id: page._id,
        };
      });
      formData.append("default_pages", JSON.stringify(default_pages));
    }
    if (inputs.default_access_program?.length > 0) {
      let default_programs = inputs.default_access_program.map((program) => {
        return {
          program_slug: program._id,
        };
      });
      formData.append(
        "default_access_program",
        JSON.stringify(default_programs)
      );
    }
    if (inputs.notification_send_type_for_admin?.length > 0) {
      let notification_send_admin = inputs.notification_send_type_for_admin;
      formData.append(
        "notification_send_type_for_admin",
        JSON.stringify(notification_send_admin)
      );
    }
    if (inputs.notification_send_type_for_delegate?.length > 0) {
      let notification_send_delegte =
        inputs.notification_send_type_for_delegate;
      formData.append(
        "notification_send_type_for_delegate",
        JSON.stringify(notification_send_delegte)
      );
    }

    if (images.import_member_sample_file) {
      formData.append(
        "import_member_sample_file",
        inputs.import_member_sample_file
      );
    }

    if (images.import_event_user_sample_file) {
      formData.append(
        "import_event_user_sample_file",
        inputs.import_event_user_sample_file
      );
    }

    if (images.admin_favicon) {
      formData.append("admin_favicon", inputs.admin_favicon);
    }

    if (images.admin_logo) {
      formData.append("admin_logo", inputs.admin_logo);
    }

    const result = await addDefaultSettingApi(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    getDefaultSetting();
  }, []);

  useEffect(() => {
    setTimeZoneValue(inputs.time_zone);
  }, [inputs.time_zone]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6">
          <h2>Default Setting</h2>
        </div>
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Default Programme"
            selectedOption={inputs?.default_program}
            setSelectedOption={(e) => {
              handleChangeOthers("default_program", e);
            }}
            optionsList={inputs?.program_list}
            autoComplete="new-password"
            name="title"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="mt-4">
            <Autocomplete
              value={timeZoneValue}
              onChange={(event, newValue) => {
                setTimeZoneValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={TimeZones}
              renderInput={(params) => (
                <TextField {...params} label="Time Zone" />
              )}
            />
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Logo</p>
              <FormHelperText className="pt-0">
                Recommended Size 778 X 430
              </FormHelperText>
            </div>
            <div className="col-2">
              {images.admin_logo ? (
                <img src={images.admin_logo} height="50" />
              ) : (
                <img
                  src={
                    inputs.old_admin_logo
                      ? s3baseUrl + inputs.old_admin_logo
                      : ""
                  }
                  height="50"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="admin_logo"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.admin_logo?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.admin_logo?.name}</p>
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Favicon</p>
              <FormHelperText className="pt-0">
                Recommended Size 32 X 32
              </FormHelperText>
            </div>
            <div className="col-2">
              {images.admin_favicon ? (
                <img src={images.admin_favicon} height="50" />
              ) : (
                <img
                  src={
                    inputs.old_admin_favicon
                      ? s3baseUrl + inputs.old_admin_favicon
                      : ""
                  }
                  height="50"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-fileFb">
                <Input
                  accept="image/*"
                  id="contained-button-fileFb"
                  multiple
                  type="file"
                  name="admin_favicon"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.admin_favicon?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.admin_favicon?.name}</p>
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className=""> Import Member Sample file</p>
              <FormHelperText className="pt-0">
                Import Member Sample file *("CSV","csv")
              </FormHelperText>
            </div>
            <div className="col-2"></div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file-other">
                <Input
                  accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.png,.csv"
                  id="contained-button-file-other"
                  multiple
                  type="file"
                  name="import_member_sample_file"
                  onChange={fileChangedHandler}
                />
                {inputs.old_import_member_sample_file !== "" && (
                  <a
                    href={
                      inputs.old_import_member_sample_file
                        ? s3baseUrl + inputs.old_import_member_sample_file
                        : ""
                    }
                    target="_blank"
                    className="me-2 small-contained-button small-contained-button-2"
                  >
                    View File
                  </a>
                )}
                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.import_member_sample_file?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">
              {inputs?.import_member_sample_file?.name}
            </p>
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className=""> Import Event Users Sample File</p>
              <FormHelperText className="pt-0">
                Import Event Users Sample File *("CSV","csv")
              </FormHelperText>
            </div>
            <div className="col-2"></div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file-other-sample-file">
                <Input
                  accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.png,.csv"
                  id="contained-button-file-other-sample-file"
                  multiple
                  type="file"
                  name="import_event_user_sample_file"
                  onChange={fileChangedHandler}
                />
                {inputs.old_import_event_user_sample_file !== "" && (
                  <a
                    href={
                      inputs.old_import_event_user_sample_file
                        ? s3baseUrl + inputs.old_import_event_user_sample_file
                        : ""
                    }
                    target="_blank"
                    className="me-2 small-contained-button small-contained-button-2"
                  >
                    View File
                  </a>
                )}
                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.import_event_user_sample_file?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">
              {inputs?.import_event_user_sample_file?.name}
            </p>
          )}
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Meta Title"
            variant="outlined"
            fullWidth
            name="meta_title"
            required
            value={inputs.meta_title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Meta Description"
            variant="outlined"
            fullWidth
            name="meta_description"
            required
            value={inputs.meta_description}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Affilate Switch Off Tracking Days"
            variant="outlined"
            fullWidth
            type="number"
            name="affiliate_switch_off_tracking_days"
            value={inputs.affiliate_switch_off_tracking_days}
            onChange={handleChange}
            inputProps={{
              min: 0,
            }}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Hours For Pass Booking"
            variant="outlined"
            fullWidth
            type="number"
            name="hours_for_pass_booking"
            value={inputs.hours_for_pass_booking}
            onChange={handleChange}
            inputProps={{
              min: 0,
            }}
          />
        </div>{" "}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Hours For Slot Booking"
            variant="outlined"
            fullWidth
            type="number"
            name="time_for_slot_booking"
            value={inputs.time_for_slot_booking}
            onChange={handleChange}
            inputProps={{
              min: 0,
            }}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="TinyMCE key"
            variant="outlined"
            fullWidth
            required
            name="tinymce_key"
            value={inputs.tinymce_key}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required className="">
            <InputLabel id="demo-simple-select-label1">
              Show Project Logs
            </InputLabel>
            <Select
              labelId="demo-simple-select-label1"
              id="demo-simple-select1"
              value={inputs.show_project_logs ? true : false}
              name="show_project_logs"
              label="Show Project Logs"
              onChange={handleChange}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-md-12 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Default Pages Access"
            selectedOption={inputs?.default_pages}
            setSelectedOption={(e) => {
              handleChangeOthers("default_pages", e);
            }}
            optionsList={inputs.sale_pages}
            autoComplete="new-password"
            name="sale_page_title"
            multiple={true}
          />
        </div>
        <div className="col-md-12 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Default Programmes Access"
            selectedOption={inputs?.default_access_program}
            setSelectedOption={(e) => {
              handleChangeOthers("default_access_program", e);
            }}
            optionsList={inputs?.program_list}
            autoComplete="new-password"
            multiple={true}
            name="title"
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Notification Send Type For Admin"
            selectedOption={inputs?.notification_send_type_for_admin}
            setSelectedOption={(e) => {
              handleChangeOthers("notification_send_type_for_admin", e);
            }}
            optionsList={NOTIFICATIONS_ARRAY_WITH_SMS}
            autoComplete="new-password"
            multiple={true}
            name="label"
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Notification Send Type For Delegate"
            selectedOption={inputs?.notification_send_type_for_delegate}
            setSelectedOption={(e) => {
              handleChangeOthers("notification_send_type_for_delegate", e);
            }}
            optionsList={NOTIFICATIONS_ARRAY_WITH_SMS}
            autoComplete="new-password"
            multiple={true}
            name="label"
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Basic Membership Sale Page For Web"
            selectedOption={inputs?.default_sale_page_for_web}
            setSelectedOption={(e) => {
              handleChangeOthers("default_sale_page_for_web", e);
            }}
            optionsList={inputs.sale_pages}
            autoComplete="new-password"
            name="sale_page_title"
            multiple={false}
          />
        </div>{" "}
        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl className="mt-3" fullWidth required>
            <InputLabel id="demo-multiple-name-label">
              Basic Membership Sale Page For Web
            </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={salepageWeb}
              onChange={handleChangeSalePagesForWeb}
              input={
                <OutlinedInput label="Basic Membership Sale Page For Web" />
              }
              MenuProps={MenuProps}
            >
              {inputs.sale_pages.map((name) => (
                <MenuItem
                  key={name}
                  value={name._id}
                  style={getStyles(name, groupsName, theme)}
                >
                  {name.sale_page_title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div> */}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Pixel Code Header"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            name="pixel_code_header"
            value={inputs.pixel_code_header}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Pixel Code Body"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            name="pixel_code_body"
            value={inputs.pixel_code_body}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Google Analytic Code"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            name="analytic_google_code"
            value={inputs.analytic_google_code}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 ">
          <p className="tracking_code_heading">Client Portal CSS code</p>
          <TextareaAutosize
            aria-label="empty textarea"
            className="new-textarea-autosize"
            value={inputs.client_portal_css_code}
            name="client_portal_css_code"
            onChange={handleChange}
            placeholder="Client Portal CSS code"
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 ">
          <p className="tracking_code_heading">Client Portal JS code</p>
          <TextareaAutosize
            aria-label="empty textarea"
            value={inputs.client_portal_js_code}
            name="client_portal_js_code"
            className="new-textarea-autosize"
            onChange={handleChange}
            placeholder="Client Portal JS code"
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 ">
          <p className="tracking_code_heading">Website Portal CSS code</p>
          <TextareaAutosize
            aria-label="empty textarea"
            value={inputs.website_portal_css_code}
            name="website_portal_css_code"
            className="new-textarea-autosize"
            onChange={handleChange}
            placeholder="Website Portal CSS code"
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 ">
          <p className="tracking_code_heading">Website Portal JS code</p>
          <TextareaAutosize
            aria-label="empty textarea"
            value={inputs.website_portal_js_code}
            name="website_portal_js_code"
            className="new-textarea-autosize"
            onChange={handleChange}
            placeholder="Website Portal JS code"
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Welcome Message For Admin *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="welcome_msg_for_admin"
            editorHeight={320}
          />
        </div>
        <div className="text-end mt-4">
          <button className="small-contained-button" id="fixedbutton">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
