import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import "react-tagsinput/react-tagsinput.css";
import TextareaAutosize from "react-textarea-autosize";
import { AddUpdateNotificationActionApi } from "src/DAL/NotificationAction/NotificationSetting";
import { useEffect } from "react";

export default function SMSSetting({ onCloseDrawer, data, listing }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {
      action: "sms_notification_access",
      action_object: {
        message: message,
      },
    };

    const result = await AddUpdateNotificationActionApi(
      data.notification_action_slug,
      postData
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      listing();
      onCloseDrawer();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  function handleEditorInit() {
    setTimeout(() => {
      let containerWarning = document.getElementById("inputField");
      if (containerWarning) {
        containerWarning.style.height = "150px";
      }
    }, 100);
  }

  useEffect(() => {
    handleEditorInit();
  }, []);

  useEffect(() => {
    const message = data?.sms_notification_info?.message;
    if (message) {
      setMessage(message);
    }
    handleEditorInit();
  }, [data]);

  return (
    <div className="container-fluid new-memories">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-12 mt-3">
          <div className="w-100">
            <h4>Write SMS Message*</h4>
            <div className="position-relative parent">
              <TextareaAutosize
                id="inputField"
                className={`chat-send-input chat-formatters-border `}
                style={{
                  height: "300px !important",
                  backgroundColor: "var(--background-secondary-color)",
                }}
                type="text"
                value={message}
                maxRows={6}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Write Your SMS Message..."
                required
                autoFocus
              />
            </div>
          </div>
        </div>
        <div className="text-end mt-4">
          <button type="submit" className="small-contained-button">
            {isLoading ? "Updating" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
