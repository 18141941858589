import { invokeApi } from "../../bl_libs/invokeApi";

export const active_lead_status_api = async () => {
  const requestObj = {
    path: `/api/lead_status/active_lead_status`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_lead_status_for_member_api = async (data) => {
  const requestObj = {
    path: `/api/lead_status/change/for_member`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
