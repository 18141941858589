import React, { useState } from "react";
import GeneralCalendar from "src/components/GeneralComponents/GeneralCalendar";
import { get_view_dates } from "src/utils/constant";

export default function ({
  tabData,
  defaultTimeZone,
  memberProfileInfo,
  isLoading,
  setIsLoading,
}) {
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));

  return (
    <>
      <GeneralCalendar
        eventList={tabData}
        setCurrentDate={setCurrentDate}
        showAddEvent={false}
        time_zone={defaultTimeZone}
        currentDate={currentDate}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        user_type="delegate"
        reloadList={memberProfileInfo}
        hideArrowsAndButtons={true}
        notreload={true}
      />
    </>
  );
}
