import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  departmentDeleteApi,
  departmentListingApi,
} from "src/DAL/Department/Department";
import { show_proper_words } from "src/utils/constant";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DepartmentsList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [departmentsData, setDepartmentsData] = useState([]);

  const getDepartmentsListing = async () => {
    setIsLoading(true);
    const result = await departmentListingApi();
    if (result.code == 200) {
      const data = result.department.map((depart) => {
        let head_of_depart = "N/A";
        if (depart.support_team_id.first_name) {
          head_of_depart =
            depart.support_team_id.first_name +
            " " +
            depart.support_team_id.last_name +
            " (" +
            depart.support_team_id.email +
            ")" +
            " " +
            "/" +
            show_proper_words(depart.support_team_id.team_type);
        }
        return {
          ...depart,
          head_of_depart,
        };
      });
      setDepartmentsData(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await departmentDeleteApi(deleteDoc._id);
    if (result.code === 200) {
      setDepartmentsData((departmentsData) => {
        return departmentsData.filter((data) => data._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(`/departments/edit-department/${value._id}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/departments/add-department`);
  };

  useEffect(() => {
    getDepartmentsListing();
  }, []);

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Name",
      alignRight: false,
    },
    {
      id: "head_of_depart",
      label: "Head Of Department",
    },
    {
      id: "support_team",
      label: "Support Team",
      alignRight: false,
      renderData: (row) => {
        return (
          <div>
            {row.support_team.length > 0
              ? row.support_team?.map((team) => {
                  let name = "N/a";
                  if (team._id) {
                    name =
                      team._id.first_name +
                      " " +
                      team._id.last_name +
                      " (" +
                      team._id.email +
                      ")" +
                      " " +
                      "/" +
                      show_proper_words(team._id.team_type);
                  }
                  return <p className="mb-0">{name}</p>;
                })
              : "N/A"}
          </div>
        );
      },
    },
    {
      id: "created_for",
      label: "Created For",
      renderData: (row) => {
        return (
          <>
            {row.created_for == "delegate" ? (
              <p
                style={{ textTransform: "capitalize" }}
              >{`Mission Control | ${row.created_for_user_type}`}</p>
            ) : (
              <p style={{ textTransform: "capitalize" }}>{row.created_for}</p>
            )}
          </>
        );
      },
    },
    { id: "order", label: "Order", alignRight: false },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this department?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-lg-6 col-sm-12">
            <h2>Departments</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Department
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={departmentsData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
