import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { changeMemberPassword } from "src/DAL/member/member";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";

export default function ChangePassword({ selectedObject, setOpen }) {
  const [inputs, setInputs] = useState({
    password: "",
    confirm_password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handlechangeAffiliate = async (e) => {
    e.preventDefault();
    if (inputs.password !== inputs.confirm_password) {
      enqueueSnackbar("New password and confirm password does not match", {
        variant: "error",
      });
      return;
    }
    setIsLoading(true);
    const result = await changeMemberPassword(inputs, selectedObject._id);
    if (result.code === 200) {
      setOpen(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <form className="row" onSubmit={handlechangeAffiliate}>
        <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
          <TextField
            id="outlined-basic"
            label="New Password"
            variant="outlined"
            fullWidth
            name="password"
            type={showPassword ? "text" : "password"}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={inputs.password}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
          <TextField
            id="outlined-basic"
            label="Confirm Password"
            variant="outlined"
            fullWidth
            type={showPassword ? "text" : "password"}
            name="confirm_password"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={inputs.confirm_password}
            onChange={handleChange}
          />
        </div>
        <div class="col-12 mt-3">
          <button class="small-contained-button w-100">
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
