import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect } from "react";
import {
  CommissionConfigurationTemplateApi,
  get_list_list_without_team,
} from "src/DAL/PaymentRequest/paymentRequestApi";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  ReminderConfigapi,
  measurementConfigapi,
  productDetai,
  programmeDetailReminderapi,
} from "src/DAL/Programme/Programme";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ProductMeasureConfiguration({ type }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState(false);

  const [inputs, setInputs] = useState({
    weight: "",
    length: "",
    height: "",
    width: "",
    country_of_origin: "",
    hs_code: "",
    product_type: "",
    material: "",
    cost_without_vat: "",
    cost_with_vat: "",
    weightUnits: "kg",
    lengthUnits: "cm",
  });

  const handleSubmit = async (e) => {
    setIsLoading(true);
    let object = inputs;
    let postData = {
      measurement_configration: object,
    };
    const result = await measurementConfigapi(postData, state?._id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getList = async () => {
    setIsLoading(true);
    const result = await productDetai(state?._id);
    if (result.code === 200) {
      if (result?.product?.measurement_configration) {
        setInputs(result?.product?.measurement_configration);
      }
      setDetail(result?.product);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    getList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let breadCrumbMenu = [
    {
      title: detail?.product_category?.title,
      navigation: `/shop-category`,
      active: false,
    },
    {
      title: detail?.name,
      navigation: `/shop-category/${detail?.product_category?.product_category_slug}/products`,
      active: false,
    },
    {
      title: "Measurement Configuration",
      active: true,
    },
  ];
  let breadCrumbMenu2 = [
    {
      title: detail?.name,
      navigation: `/shop-products`,
      active: false,
    },
    {
      title: "Measurement Configuration",
      active: true,
    },
  ];
  const lengthUnits = [
    { label: "Centimeter", value: "cm" },
    { label: "Meter", value: "m" },

    { label: "Millimeter", value: "mm" },
    { label: "Feet", value: "ft" },

    { label: "Inch", value: "in" },
  ];
  const weightUnits = [
    { label: "Kilogram", value: "kg" },
    { label: "Gram", value: "g" },
    { label: "Milligram", value: "mg" },
    { label: "Ounce", value: "oz" },
    { label: "Pound", value: "lb" },
    { label: "Ton", value: "ton" },
  ];
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          {detail && (
            <span>
              <ActiveLastBreadcrumb
                breadCrumbMenu={
                  type == "main" ? breadCrumbMenu2 : breadCrumbMenu
                }
              />
            </span>
          )}
        </div>
        <div className="col-12 mt-3">
          <h2>Measurement Configuration</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Weight Measure Unit
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="weightUnits"
                value={inputs.weightUnits}
                label="Weight Measure Unit"
                onChange={handleChange}
              >
                {weightUnits.map((item, index) => {
                  return <MenuItem value={item.value}>{item.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Size Measure Unit
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="lengthUnits"
                value={inputs.lengthUnits}
                label=" Size Measure Unit"
                onChange={handleChange}
              >
                {lengthUnits.map((item, index) => {
                  return <MenuItem value={item.value}>{item.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Weight"
              variant="outlined"
              fullWidth
              type="number"
              required
              InputProps={{
                endAdornment: (
                  <span className="units">{inputs.weightUnits}</span>
                ),
              }}
              inputProps={{
                min: 0, // Minimum value allowed
                step: "any", // Allows the input of any floating point number
              }}
              name="weight"
              value={inputs.weight}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Height"
              variant="outlined"
              fullWidth
              type="number"
              required
              inputProps={{
                min: 0, // Minimum value allowed
                step: "any", // Allows the input of any floating point number
              }}
              InputProps={{
                endAdornment: (
                  <span className="units">{inputs.lengthUnits}</span>
                ),
              }}
              name="height"
              value={inputs.height}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Length"
              variant="outlined"
              fullWidth
              type="number"
              required
              inputProps={{
                min: 0, // Minimum value allowed
                step: "any", // Allows the input of any floating point number
              }}
              InputProps={{
                endAdornment: (
                  <span className="units">{inputs.lengthUnits}</span>
                ),
              }}
              name="length"
              value={inputs.length}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Width"
              variant="outlined"
              fullWidth
              type="number"
              required
              inputProps={{
                min: 0, // Minimum value allowed
                step: "any", // Allows the input of any floating point number
              }}
              InputProps={{
                endAdornment: (
                  <span className="units">{inputs.lengthUnits}</span>
                ),
              }}
              name="width"
              value={inputs.width}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Country of Origin"
              variant="outlined"
              fullWidth
              required
              name="country_of_origin"
              value={inputs.country_of_origin}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="HS Code"
              variant="outlined"
              fullWidth
              required
              name="hs_code"
              value={inputs.hs_code}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Product Type"
              variant="outlined"
              fullWidth
              required
              name="product_type"
              value={inputs.product_type}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Material"
              variant="outlined"
              fullWidth
              required
              name="material"
              value={inputs.material}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Cost without VAT"
              variant="outlined"
              fullWidth
              required
              type="number"
              inputProps={{
                min: 0, // Minimum value allowed
                step: "any", // Allows the input of any floating point number
              }}
              name="cost_without_vat"
              value={inputs.cost_without_vat}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Cost with VAT"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="cost_with_vat"
              inputProps={{
                min: 0, // Minimum value allowed
                step: "any", // Allows the input of any floating point number
              }}
              value={inputs.cost_with_vat}
              onChange={handleChange}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
