import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  AddEmailSettingApi,
  emailSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { project_mode, s3baseUrl } from "src/config/config";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function EmailSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [oldIcons, setOldIcons] = useState({
    instagram_icon: "",
    linkedin_icon: "",
    twitter_icon: "",
    facebook_icon: "",
    email_brand_logo: "",
  });

  const [socialIcons, setSocialcons] = useState({
    instagram_icon: "",
    linkedin_icon: "",
    twitter_icon: "",
    facebook_icon: "",
    email_brand_logo: "",
  });

  const [inputs, setInputs] = useState({
    email_brand_logo: {},
    instagram_icon: {},
    facebook_icon: {},
    twitter_icon: {},
    linkedin_icon: {},
    facebook_link: "",
    support_email: "",
    contact_us_link: "",
    office_address: "",
    name: "",
    email_mode: project_mode == "dev" ? "sandbox" : "live",
    faq_link: "",
    copy_right_text: "",
    team_name: "",
    twitter_link: "",
    instagram_link: "",
    linkedin_link: "",
    support_name: "",
  });

  const siteSettingData = async () => {
    const result = await emailSettingApi();
    if (result.code == 200) {
      setInputs(result.email_setting);
      setOldIcons({
        ["instagram_icon"]: result.email_setting?.instagram_icon?.thumbnail_1,
        ["linkedin_icon"]: result.email_setting?.linkedin_icon?.thumbnail_1,
        ["twitter_icon"]: result.email_setting?.twitter_icon?.thumbnail_1,
        ["facebook_icon"]: result.email_setting?.facebook_icon?.thumbnail_1,
        ["email_brand_logo"]: result?.email_setting?.email_brand_logo,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (event) => {
    const name = event.target.name;
    setInputs({
      ...inputs,
      [name]: event.target.files[0],
    });
    setSocialcons({
      ...socialIcons,
      [name]: URL.createObjectURL(event.target.files[0]),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    const formData = new FormData();
    formData.append("support_email", inputs.support_email);
    formData.append("support_name", inputs.support_name);
    formData.append("contact_us_link", inputs.contact_us_link);
    formData.append("faq_link", inputs.faq_link);
    formData.append("copy_right_text", inputs.copy_right_text);
    formData.append("email_mode", inputs.email_mode);
    formData.append("office_address", inputs.office_address);
    formData.append("team_name", inputs.team_name);
    formData.append("facebook_link", inputs.facebook_link);
    formData.append("instagram_link", inputs.instagram_link);
    formData.append("twitter_link", inputs.twitter_link);
    formData.append("linkedin_link", inputs.linkedin_link);

    if (socialIcons.facebook_icon) {
      formData.append("facebook_icon", inputs.facebook_icon);
    }
    if (socialIcons.instagram_icon) {
      formData.append("instagram_icon", inputs.instagram_icon);
    }
    if (socialIcons.twitter_icon) {
      formData.append("twitter_icon", inputs.twitter_icon);
    }
    if (socialIcons.linkedin_icon) {
      formData.append("linkedin_icon", inputs.linkedin_icon);
    }
    if (socialIcons.email_brand_logo) {
      formData.append("email_brand_logo", inputs.email_brand_logo);
    }

    const result = await AddEmailSettingApi(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    siteSettingData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(inputs?.email_mode, "inputs?.email_modeinputs?.email_mode");
  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-6">
          <h2>Email Setting</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
              name="support_email"
              required
              value={inputs.support_email}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Contact Link"
              variant="outlined"
              fullWidth
              required
              name="contact_us_link"
              value={inputs.contact_us_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Office Address"
              variant="outlined"
              fullWidth
              required
              name="office_address"
              value={inputs.office_address}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Support Name"
              variant="outlined"
              fullWidth
              name="support_name"
              value={inputs.support_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Faq Link"
              variant="outlined"
              fullWidth
              name="faq_link"
              value={inputs.faq_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Copy Right Text"
              variant="outlined"
              fullWidth
              name="copy_right_text"
              value={inputs.copy_right_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Team Name"
              variant="outlined"
              fullWidth
              name="team_name"
              value={inputs.team_name}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Email Mode *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="email_mode"
                required
                value={inputs?.email_mode}
                label="Email Mode *"
                onChange={handleChange}
              >
                <MenuItem value="sandbox">Sandbox</MenuItem>
                <MenuItem value="live">Live</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Email Brand Logo *</p>
                <FormHelperText className="pt-0">
                  ("PNG",300 X 300)
                </FormHelperText>
              </div>
              <div className="col-2">
                {socialIcons.email_brand_logo ? (
                  <img src={socialIcons.email_brand_logo} height="50" />
                ) : (
                  <img
                    src={s3baseUrl + oldIcons.email_brand_logo}
                    height="50"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="email_brand_logo"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <h3 className="mt-5">Social Media</h3>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Facebook link"
              variant="outlined"
              fullWidth
              name="facebook_link"
              value={inputs.facebook_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Twitter Link"
              variant="outlined"
              fullWidth
              name="twitter_link"
              value={inputs.twitter_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Instagram link"
              variant="outlined"
              fullWidth
              name="instagram_link"
              value={inputs.instagram_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Linkedin Link"
              variant="outlined"
              fullWidth
              name="linkedin_link"
              value={inputs.linkedin_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Facebook icon</p>
                <FormHelperText className="pt-0">
                  ( "PNG",778 X 430)
                </FormHelperText>
              </div>
              <div className="col-2">
                {socialIcons.facebook_icon ? (
                  <img src={socialIcons.facebook_icon} height="50" />
                ) : (
                  <img src={s3baseUrl + oldIcons.facebook_icon} height="50" />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-fileFb">
                  <Input
                    accept="image/*"
                    id="contained-button-fileFb"
                    multiple
                    type="file"
                    name="facebook_icon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Instagram icon</p>
                <FormHelperText className="pt-0">
                  ( "PNG",778 X 430)
                </FormHelperText>
              </div>
              <div className="col-2">
                {socialIcons.instagram_icon ? (
                  <img src={socialIcons.instagram_icon} height="50" />
                ) : (
                  <img src={s3baseUrl + oldIcons.instagram_icon} height="50" />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-fileInstagram">
                  <Input
                    accept="image/*"
                    id="contained-button-fileInstagram"
                    multiple
                    type="file"
                    name="instagram_icon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Twitter icon</p>
                <FormHelperText className="pt-0">
                  ( "PNG",778 X 430)
                </FormHelperText>
              </div>
              <div className="col-2">
                {socialIcons.twitter_icon ? (
                  <img src={socialIcons.twitter_icon} height="50" />
                ) : (
                  <img src={s3baseUrl + oldIcons.twitter_icon} height="50" />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-fileTwitter">
                  <Input
                    accept="image/*"
                    id="contained-button-fileTwitter"
                    multiple
                    type="file"
                    name="twitter_icon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">LinkedIn icon</p>
                <FormHelperText className="pt-0">
                  ( "PNG",778 X 430)
                </FormHelperText>
              </div>
              <div className="col-2">
                {socialIcons.linkedin_icon ? (
                  <img src={socialIcons.linkedin_icon} height="50" />
                ) : (
                  <img src={s3baseUrl + oldIcons.linkedin_icon} height="50" />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-fileLinkedIn">
                  <Input
                    accept="image/*"
                    id="contained-button-fileLinkedIn"
                    multiple
                    type="file"
                    name="linkedin_icon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button" id="fixedbutton">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
