import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { addSandGrid, siteSettingApi } from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddSendGrid() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    sendgrid_key: "",
    sendgrid_from_email: "",
  });
  const siteSettingData = async () => {
    setIsLoading(true);
    const result = await siteSettingApi();
    if (result.code == 200) {
      setInputs(result?.site_setting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let postData = {
      sendgrid_key: inputs.sendgrid_key,
      sendgrid_from_email: inputs.sendgrid_from_email,
    };

    const result = await addSandGrid(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    siteSettingData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-6">
          <h2>SendGrid Setting </h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="SendGrid Key"
              variant="outlined"
              fullWidth
              required
              name="sendgrid_key"
              value={inputs.sendgrid_key}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="SendGrid From Email"
              variant="outlined"
              fullWidth
              required
              name="sendgrid_from_email"
              value={inputs.sendgrid_from_email}
              onChange={handleChange}
            />
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
