import { invokeApi } from "../../bl_libs/invokeApi";

export const galleryImageListing = async (id) => {
  const requestObj = {
    path: `/api/content_setting/list_dynamite_gallery`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addGalleryImageApi = async (data) => {
  const requestObj = {
    path: `/api/content_setting/upload_gallery_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editGalleryImageApi = async (data, slug) => {
  const requestObj = {
    path: `/api/group/update_automated_group/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteGalleryImageApi = async (data) => {
  const requestObj = {
    path: `/api/content_setting/delete_galery_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
