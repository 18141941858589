import React, { useEffect, useState } from "react";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import AssessmentSetting from "./AssessmentSetting";
const AssessmentSettingTabs = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const TABS_OPTIONS = [
    {
      title: <div className="d-flex">Dynamite</div>,
      component: <AssessmentSetting settings={"dynamite_assessment_setting"} />,
    },
    {
      title: <div className="d-flex">PTA</div>,
      component: <AssessmentSetting settings={"pta_assessment_setting"} />,
    },
    {
      title: <div className="d-flex">Elite</div>,
      component: <AssessmentSetting settings={"elite_assessment_setting"} />,
    },
    {
      title: <div className="d-flex">VIP Gold</div>,
      component: <AssessmentSetting settings={"mastery_assessment_setting"} />,
    },
  ];

  return (
    <div className="container">
      <div className="row section-space">
        <div className="col-sm-12 col-md-6 mb-2">
          <h2>Assessment Setting</h2>
        </div>
        <div className="col-12">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AssessmentSettingTabs;
