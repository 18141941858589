import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";

import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import {
  GetwhatappApi,
  WhatsappSettingApi,
} from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function WhatsAppSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [inputs, setInputs] = useState({
    phone_no: "",
    whatsapp_business_account_id: "",
    authorization_token: "",
  });
  const GetwhatappApiList = async () => {
    setIsLoading(true);
    const result = await GetwhatappApi();
    if (result.code == 200) {
      setInputs(result?.whatssapp_setting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let data = {
      phone_no: inputs.phone_no,
      whatsapp_business_account_id: inputs.whatsapp_business_account_id,
      authorization_token: inputs.authorization_token,
    };
    let postData = {
      whatsapp_setting: data,
    };
    const result = await WhatsappSettingApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    GetwhatappApiList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-6">
          <h2>WhatsApp Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Phone No."
              variant="outlined"
              type="number"
              fullWidth
              required
              aria-readonly
              name="phone_no"
              value={inputs?.phone_no}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Whatsapp Business Account ID"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="whatsapp_business_account_id"
              value={inputs?.whatsapp_business_account_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Authorization Token"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="authorization_token"
              value={inputs?.authorization_token}
              onChange={handleChange}
            />
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
