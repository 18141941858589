import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, CircularProgress, Autocomplete } from "@mui/material";
import { programmeActiveListing } from "src/DAL/Programme/Programme";
import {
  delegateAddDataApi,
  groupDetailApi,
  updateDelegateGroupApi,
} from "src/DAL/delegateGroups/delegategroupApi";
import { dynamite_events_list_api } from "src/DAL/DynamiteEvents/DynamiteEvents";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function EditDelegateGroup() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageStatus, setImageStatus] = useState(false);
  const [programmeListing, setProgramListing] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [members, setMember] = useState([]);
  const [eventsListing, setEventsListing] = useState([]);
  const [eventsName, setEventsName] = useState([]);

  const [inputs, setInputs] = useState({
    title: "",
    zoomLink: "",
    status: "",
    order: "",
    // image: {},
    password: "",
    roomType: "general",
    short_description: "",
    detailed_description: "",
    groupBy: "program",
  });
  const { id, name, status, delegate, program, object, group_by } = state;

  const getDetail = async () => {
    setIsLoading(true);
    const result = await groupDetailApi(params?.id);
    if (result.code === 200) {
      setInputs((inputs) => ({
        ...inputs,
        ["title"]: result.group?.title,
        ["status"]: result.group?.status,

        // ["areaCode"]: areaCode,
      }));
      let groups_slug = result.programs;
      let selected_group = [];
      groups_slug?.map((group) => {
        selected_group.push(group);
      });
      let delegate_id = result.delegates;
      let delegate_object = [];
      delegate_id?.map((member) => {
        delegate_object.push(member);
      });

      //event data manipulate
      let event_slug = state?.object?.event;
      let events_array = [];
      event_slug?.map((event) => {
        events_array.push(event?._id?.event_slug);
      });
      setGroupsName(selected_group);
      setMember(delegate_object);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeEvents = (event) => {
    const {
      target: { value },
    } = event;
    setEventsName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeGroup = (value) => {
    setGroupsName(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let program_array = [];
    if (groupsName.length > 0) {
      program_array = groupsName.map((group) => {
        return {
          _id: group._id,
        };
      });
    }
    //delegate manipulation
    let selected_delegate_array = [];
    let selected_delegate_object = {};
    members.map((member) => {
      selected_delegate_object = {
        _id: member._id,
      };
      selected_delegate_array.push(selected_delegate_object);
    });

    let postData = {
      title: inputs.title,
      status: inputs.status,
      program: program_array,
      delegate: selected_delegate_array,
    };
    setIsLoading(true);
    const result = await updateDelegateGroupApi(postData, params.id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/delegate-groups");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getAddListingForProgramsDelegates = async () => {
    setIsLoading(true);
    const result = await delegateAddDataApi();
    if (result.code === 200) {
      setProgramListing(result.program);
      setPersonName(result.delegates);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    getAddListingForProgramsDelegates();
    getDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let finalArray = [];
  for (let i = 0; i < personName.length; i++) {
    let count = 0;
    for (let j = 0; j < delegate.length; j++) {
      if (personName[i]._id == delegate[j]._id) {
        count++;
      }
    }
    if (count == 0) {
      finalArray.push(personName[i]);
    }
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>Edit Delegate Group</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            fullWidth
            name="title"
            value={inputs.title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Group Status*</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="pod status*"
              onChange={handleChange}
            >
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        {inputs.groupBy == "event" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <FormControl className="mt-3" fullWidth>
              <InputLabel id="demo-multiple-name-label">Events</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={eventsName}
                onChange={handleChangeEvents}
                input={<OutlinedInput label="Events" />}
                MenuProps={MenuProps}
              >
                {eventsListing.map((name) => (
                  <MenuItem
                    key={name}
                    value={name.event_slug}
                    style={getStyles(name, eventsName, theme)}
                  >
                    {name.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {inputs.groupBy == "program" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Programme"
              selectedOption={groupsName}
              setSelectedOption={handleChangeGroup}
              optionsList={programmeListing}
              name="title"
              multiple
            />
          </div>
        )}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <Autocomplete
            multiple
            id="tags-outlined"
            options={finalArray}
            getOptionLabel={(option) =>
              option.first_name + " (" + option.email + ")"
            }
            filterSelectedOptions
            value={members}
            onChange={(event, newValue) => {
              setMember(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Delegate" placeholder="Delegate" />
            )}
          />
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
