import React, { useEffect, useState } from "react";
import { Avatar, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { list_member_replies_against_module } from "src/DAL/GeneralQuestions/GeneralQuestions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { Icon } from "@iconify/react";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import { download_csv_file } from "src/utils/convertHtml";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const headers = [
  { label: "User Name", key: "user_name" },
  { label: "User Email", key: "user_email" },
  { label: "Answered Date", key: "answered_date" },
];

export default function QuestionsAnswersAsCreatedFor({
  created_for,
  type,
  page_title,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [answersData, setAnswersData] = useState([]);
  const [moduleInfo, setModuleInfo] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  let created_for_id = new URLSearchParams(location.search).get(
    "created_for_id"
  );

  const programmes_list = window.location.pathname.includes("/programmes");

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getAnswersListing(searchText);
    localStorage.setItem(type, searchText);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  localStorage.setItem("created_for_id", created_for_id);
  localStorage.setItem("page_title_selfimage", page_title);

  const getAnswersListing = async (search_text) => {
    setIsLoading(true);
    let postData = {
      search_text: search_text ? search_text : "",
      created_for,
      type: type ? type : "",
      created_for_id: created_for_id ? created_for_id : "",
    };

    const result = await list_member_replies_against_module(
      page,
      rowsPerPage,
      postData
    );
    if (result.code == 200) {
      const answers = result?.members?.map((answer) => {
        return {
          ...answer,
          user_name: answer?.first_name + " " + answer?.last_name,
          user_email: answer?.email,
          answered_date: moment(answer.reply_date).format("DD-MM-YYYY"),
          table_avatar: {
            src: s3baseUrl + answer?.profile_image,
            alt: answer.first_name,
          },
        };
      });
      setModuleInfo(result.details);
      setAnswersData(answers);
      setTotalCount(result.toal_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleExportClick = () => {
    download_csv_file(headers, answersData, page_title);
  };

  const handleNavigate = (value) => {
    let check_user = false;
    if (value.created_for == "self_image") {
      check_user = true;
    }
    if (!created_for_id) {
      created_for_id = "";
    }
    navigate(
      `${window.location.pathname}/answers-detail?created_for_id=${created_for_id}&member_id=${value.member_id}&created_for=${value.created_for}&check_user=${check_user}`,
      {
        state: value,
      }
    );
  };

  const MENU_OPTIONS = [
    {
      label: "Answers Detail",
      icon: "akar-icons:edit",
      handleClick: handleNavigate,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    let find_search = localStorage.getItem(type);
    if (find_search) {
      setSearchText(find_search);
    } else {
      setSearchText("");
    }
    getAnswersListing(find_search);
  }, [rowsPerPage, page, type]);

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },

    {
      id: "table_avatar",
      label: "User Profile",
      renderData: (row) => {
        return (
          <span className="anchor-style" onClick={() => handleNavigate(row)}>
            <Avatar alt={row.user_name} src={row?.table_avatar?.src} />
          </span>
        );
      },
    },
    {
      id: "user_name",
      label: "User Name",
      renderData: (row) => {
        return (
          <p className="mb-0 anchor-style" onClick={() => handleNavigate(row)}>
            {row.user_name}
          </p>
        );
      },
    },
    {
      id: "user_email",
      label: "User Email",
      renderData: (row) => {
        return (
          <p className="mb-0 anchor-style" onClick={() => handleNavigate(row)}>
            {row.user_email}
          </p>
        );
      },
    },
    {
      id: "answered_date",
      label: "Answered Date",
      calignRight: false,
    },
  ];
  let program_title = localStorage.getItem("program_title");
  let program_slug_local = localStorage.getItem("program_slug");
  let lesoon_title = localStorage.getItem("lesson_title");
  let lesoon_slug = localStorage.getItem("lesson_slug");

  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },

    {
      title: program_title ? program_title : "",
      navigation: `/programmes/programmes-detail/${program_slug_local}`,
      active: false,
    },
    {
      title: "Questions Answers List",
      navigation: ``,
      active: true,
    },
  ];
  if (created_for == "lesson") {
    let lesson_object = {
      title: "Lessons",
      navigation: `/lessons/${program_slug_local}`,
      active: false,
    };
    let lesson_detail_object = {
      title: lesoon_title,
      navigation: `/lessons/lesson-detail/${lesoon_slug}`,
      active: false,
    };
    breadCrumbMenu.splice(2, 0, lesson_object);
    breadCrumbMenu.splice(3, 0, lesson_detail_object);
  }

  let event_id = localStorage.getItem("event_id");
  let event_video_id = localStorage.getItem("event_video_id");
  let event_vidoe_title = localStorage.getItem("event_vidoe_title");
  let event_title = localStorage.getItem("event_title");
  localStorage.setItem("event_videos", state?.title);

  let breadCrumbMenuNew = [
    {
      title: event_title,
      navigation: `/dynamite-events`,
      active: false,
    },
    {
      title: event_vidoe_title,
      navigation: `/dynamite-event-categories/${event_id}`,
      active: false,
    },
    {
      title: state?.title,
      navigation: `/dynamite-event-videos/${event_video_id}`,
      active: false,
    },
    {
      title: "Questions Answers List",
      navigation: ``,
      active: true,
    },
  ];

  let breadCrumbQuarter = [
    {
      title: "Quarters",
      navigation: `/quarters`,
      active: false,
    },
    {
      title: moduleInfo.title,
      navigation: ``,
      active: true,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 display-flex mb-3">
            {created_for == "programme" ||
            created_for == "lesson" ||
            created_for == "quarter" ||
            created_for == "dynamite_event_video" ? (
              <span>
                <ActiveLastBreadcrumb
                  breadCrumbMenu={
                    created_for == "dynamite_event_video"
                      ? breadCrumbMenuNew
                      : created_for == "quarter"
                      ? breadCrumbQuarter
                      : breadCrumbMenu
                  }
                />
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="col-8 mb-3">
            <h2>{page_title}</h2>
          </div>
          {/* <div className="col-4 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleExportClick}
            >
              Export Csv &nbsp;&nbsp; <Icon icon={arrowCircleDownFill} />
            </button>
          </div> */}
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={answersData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
        />
      </div>
    </>
  );
}
