import { TextField } from "@mui/material";
import React from "react";
import { useState } from "react";

export default function TitleField(props) {
  const { item, handleChange } = props;
  const [isTextField, setIsTextField] = useState(false);

  return (
    <div>
      {isTextField ? (
        <TextField
          id="outlined-basic"
          label="Title"
          size="small"
          variant="outlined"
          fullWidth
          aria-readonly
          type="text"
          name="title"
          autoFocus
          required
          value={item.title}
          onChange={handleChange}
        />
      ) : (
        <div className="static-textfield" onClick={() => setIsTextField(true)}>
          {item.title && (
            <div className="static-textfield-label px-1">Title *</div>
          )}
          <div className="field-value">
            {item.title ? (
              item.title
            ) : (
              <span className="disabled_label">Title *</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
