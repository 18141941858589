import React, { useEffect } from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress, Tooltip } from "@mui/material";
import {
  get_theme_setting_list_api,
  payment_update_theme_setting_api,
} from "src/DAL/WebsitePages/WebsitePages";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function PaymentThemeSetting() {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isImgLoading, setIsImgLoading] = useState(false);
  const [thumbnail_file, setThumbnailFile] = useState();

  const [inputs, setInputs] = useState({
    header_and_footer_bg_color: "#000",
    header_and_footer_color: "#FFFFFF",
    body_bg_color: "#FFFFFF",
    theme_color: "#a16f2a",
    footer_heading_color: "#a16f2a",
    heading_color: "#000",
    button_text_color: "#000",
    body_color: "#FFFFFF",
    input_border_color: "#000",
    body_bg_img: "",
    image: {},
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const get_theme_setting_list = async () => {
    const result = await get_theme_setting_list_api(params.id);
    if (result.code === 200) {
      setInputs(result.page_theme_setting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = async (e) => {
    const { name, files } = e.target;
    setIsImgLoading(true);
    const formData = new FormData();
    formData.append("image", files[0]);
    formData.append("width", "2200");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setThumbnailFile(URL.createObjectURL(e.target.files[0]));
      setInputs({
        ...inputs,
        [name]: imageUpload.image_path,
        ["image"]: e.target.files[0],
      });
      setIsImgLoading(false);
    } else {
      setIsImgLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const postData = {
      page_theme_setting: {
        header_and_footer_bg_color: inputs.header_and_footer_bg_color,
        header_and_footer_color: inputs.header_and_footer_color,
        body_bg_color: inputs.body_bg_color,
        theme_color: inputs.theme_color,
        body_bg_img: inputs.body_bg_img,
        heading_color: inputs.heading_color,
        body_color: inputs.body_color,
        input_border_color: inputs.input_border_color,
        button_text_color: inputs.button_text_color,
        footer_heading_color: inputs.footer_heading_color,
      },
    };
    const result = await payment_update_theme_setting_api(postData, params.id);
    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_theme_setting_list();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4 me-3"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2>Theme Setting</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Header & Footer Background Color"
              variant="outlined"
              fullWidth
              required
              type="color"
              name="header_and_footer_bg_color"
              value={inputs.header_and_footer_bg_color}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Header & Footer Color"
              variant="outlined"
              fullWidth
              required
              type="color"
              name="header_and_footer_color"
              value={inputs.header_and_footer_color}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Background Color"
              variant="outlined"
              fullWidth
              required
              type="color"
              name="body_bg_color"
              value={inputs.body_bg_color}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Color"
              variant="outlined"
              fullWidth
              required
              type="color"
              name="body_color"
              value={inputs.body_color}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Theme Color"
              variant="outlined"
              fullWidth
              required
              type="color"
              name="theme_color"
              value={inputs.theme_color}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Heading Color"
              variant="outlined"
              fullWidth
              required
              type="color"
              name="heading_color"
              value={inputs.heading_color}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Input Border Color"
              variant="outlined"
              fullWidth
              required
              type="color"
              name="input_border_color"
              value={inputs.input_border_color}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Button Text Color"
              variant="outlined"
              fullWidth
              required
              type="color"
              name="button_text_color"
              value={inputs.button_text_color}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 mt-4">
            <TextField
              id="outlined-basic"
              label="Footer Heading Color"
              variant="outlined"
              fullWidth
              required
              type="color"
              name="footer_heading_color"
              value={inputs.footer_heading_color}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Background Image</p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {isImgLoading ? (
                  <CircularProgress size="1.2rem" />
                ) : (
                  <div className="del_img_icon">
                    <img
                      src={
                        thumbnail_file
                          ? thumbnail_file
                          : inputs.body_bg_img
                          ? s3baseUrl + inputs.body_bg_img
                          : ""
                      }
                      height="50"
                    />
                    {thumbnail_file || inputs.body_bg_img ? (
                      <Tooltip title="Delete image">
                        <span
                          className="pointer_cursor"
                          onClick={() => {
                            setInputs((prevInputs) => ({
                              ...prevInputs,
                              body_bg_img: "",
                              image: {},
                            }));
                            setThumbnailFile();
                          }}
                        >
                          X
                        </span>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="body_bg_img"
                    onChange={fileChangedHandler}
                  />
                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <div className="text-end mt-4">
            <button disabled={isImgLoading} className="small-contained-button">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
