import React from "react";
import { get_kmb_number } from "src/utils/formatNumber";
import { show_proper_words } from "src/utils/constant";

export default function TransactionsStatCard({ count, title, className }) {
  function getRandomRGBColor() {
    // Generate random values for red, green, and blue channels
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    // Construct an RGB color string
    const color1 = `rgb(${red}, ${green}, ${blue}, 0)`;
    const color2 = `rgb(${red}, ${green}, ${blue}, 0.24)`;
    return `linear-gradient(135deg, ${color1} 0%, ${color2} 100%)`;
  }

  return (
    <div className={`col-12 col-md-6 col-lg-3 mb-4 ${className ?? ""}`}>
      <div className="stats-card">
        <div
          className="stats-icon-box"
          style={{
            backgroundImage: getRandomRGBColor(),
          }}
        >
          <b style={{ fontSize: "30px" }}>£</b>
        </div>
        <h3>£{get_kmb_number(count)}</h3>
        <h6>{show_proper_words(title)}</h6>
      </div>
    </div>
  );
}
